import { useTranslate } from "modules/language";
import { useState } from "react";
import EditableParamsContainer from "../../../../../editableParams/EditableParamsContainer/EditableParamsContainer";
import ElectricityEmissionIntensityEditable from "../../../../../editableParams/ElectricityEmissionIntensityEditable/ElectricityEmissionIntensityEditable";
import useEditableParamsMutate from "modules/building/hooks/useEditableParamsMutate";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import { validateParamValues } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/utils";

const ElectricityBreakdownAssumptions = () => {
  const t = useTranslate();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isHistoryEnabled, setIsHistoryEnabled] = useState(false);

  const { state, telemetries, Notifier } = useEditableParamsState();
  const { mutateAsync, isLoading } = useEditableParamsMutate();

  const handleEditSave = async () => {
    if (!validateParamValues(state, ["electricityEmissionIntensity"])) {
      return false;
    }

    const { electricityEmissionIntensity } = state;

    if (electricityEmissionIntensity.syncInitialValue()) {
      await mutateAsync([
        {
          timeseriesId: telemetries.electricityEmissionIntensity.timeseriesId,
          value: electricityEmissionIntensity.value,
        },
      ]);
    }

    return true;
  };

  return (
    <Notifier>
      <EditableParamsContainer
        title={t("label.ASSUMPTIONS")}
        isEditEnabled={isEditEnabled}
        setIsEditEnabled={setIsEditEnabled}
        isHistoryEnabled={isHistoryEnabled}
        setIsHistoryEnabled={setIsHistoryEnabled}
        onSave={handleEditSave}
      >
        <ElectricityEmissionIntensityEditable
          isEditEnabled={isEditEnabled}
          isHistoryEnabled={isHistoryEnabled}
          isHistoryExpanded
          disabled={isLoading}
        />
      </EditableParamsContainer>
    </Notifier>
  );
};

export default ElectricityBreakdownAssumptions;
