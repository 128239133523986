import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActivityIndicatorButton from "atomic-components/atoms/Buttons/ActivityIndicatorButton/ActivityIndicatorButton";
import { RawDateFilterEnum } from "atomic-components/molecules/DateSelectorPopover/DateSelectorPopover";
import useFullDateFilterState from "modules/building/hooks/useFullDateFilterState";
import { useTranslate } from "modules/language";
import { forwardRef } from "react";

import s from "./ActivityIndicatorDateSelectorButton.module.scss";

type ActivityIndicatorDateSelectorButtonProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  areOptionsShown: boolean;
};

const ActivityIndicatorDateSelectorButton = forwardRef<
  HTMLButtonElement,
  ActivityIndicatorDateSelectorButtonProps
>(({ onClick, areOptionsShown }, ref) => {
  const t = useTranslate();
  const { days, rawDateFilter } = useFullDateFilterState();

  const dateSelectorButtonText =
    rawDateFilter === RawDateFilterEnum.TODAY ? (
      t("title.TODAY")
    ) : (
      <>
        {days} {days > 1 ? t("label.DAYS") : t("label.DAY")}
      </>
    );

  return (
    <ActivityIndicatorButton
      isActive={areOptionsShown}
      ref={ref}
      onClick={onClick}
      className={s.button}
    >
      <FontAwesomeIcon icon={faCalendar} />
      {dateSelectorButtonText}
    </ActivityIndicatorButton>
  );
});

export default ActivityIndicatorDateSelectorButton;
