import { useMutation } from "react-query";
import {
  createScenario,
  updateScenario,
  deleteScenario,
  activateScenario,
  pinScenario,
} from "../api/ComplianceScenarios";
import { type Scenario } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ComplianceTrackingPanel/Certificates/LeedOmCertificate/utils";
import { invalidateComplianceScenariosQuery } from "./useComplianceScenarios";
import { ReactQueryCacheKeys, queryClient } from "reactQuery";
import { addToast } from "modules/toast";

const updateScenariosCache = (
  updater: (oldScenarios: Scenario[] | undefined) => Scenario[]
) => {
  queryClient.setQueryData([ReactQueryCacheKeys.ComplianceScenarios], updater);
};

const postScenariosMutate = (resp: any) => {
  setTimeout(() => {
    invalidateComplianceScenariosQuery();
  }, 1000);
  return resp;
};

const onError = () => {
  addToast({
    messageKey: "toast.AN_ERROR_HAPPENED",
    variant: "danger",
  });
};

const useCreateScenario = (data: Omit<Scenario, "id">) => {
  return useMutation({
    mutationFn: () => {
      // update scenarios cache before the request
      updateScenariosCache((oldScenarios) => [
        ...(oldScenarios || []),
        { ...data, id: "temp" },
      ]);
      return createScenario(data).then(postScenariosMutate).catch(onError);
    },
  });
};

const useUpdateScenario = (data: Scenario) => {
  return useMutation({
    mutationFn: () => {
      // update scenarios cache before the request
      updateScenariosCache((oldScenarios) =>
        (oldScenarios || []).map((scenario) =>
          scenario.id === data.id ? { ...data } : scenario
        )
      );
      return updateScenario(data).then(postScenariosMutate).catch(onError);
    },
  });
};

const useDeleteScenario = (id: string) => {
  return useMutation({
    mutationFn: () => {
      // update scenarios cache before the request
      updateScenariosCache((oldScenarios) =>
        (oldScenarios || []).filter((scenario) => scenario.id !== id)
      );
      return deleteScenario(id).then(postScenariosMutate).catch(onError);
    },
  });
};

const useToggleActivateScenario = (id: string) => {
  return useMutation({
    mutationFn: () => {
      // update scenarios cache before the request
      updateScenariosCache((oldScenarios) =>
        (oldScenarios || []).map((scenario) =>
          scenario.id === id
            ? { ...scenario, isActive: !scenario.isActive }
            : { ...scenario, isActive: false }
        )
      );
      return activateScenario(id).then(postScenariosMutate);
    },
  });
};

const useTogglePinScenario = (id: string) => {
  return useMutation({
    mutationFn: () => {
      // update scenarios cache before the request
      updateScenariosCache((oldScenarios) =>
        (oldScenarios || []).map((scenario) =>
          scenario.id === id
            ? { ...scenario, isPinned: !scenario.isPinned }
            : scenario
        )
      );
      return pinScenario(id).then(postScenariosMutate);
    },
  });
};

export {
  useCreateScenario,
  useUpdateScenario,
  useDeleteScenario,
  useToggleActivateScenario,
  useTogglePinScenario,
};
