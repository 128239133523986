import {
  type ReactNode,
  createContext,
  useMemo,
  useState,
  useContext,
  useCallback,
} from "react";
import type { Setter } from "types/utils";

type LayoutContextValue = {
  secondarySideMenuContent: ReactNode;
  setSecondarySideMenuContent: Setter<ReactNode>;
  hideSecondarySideMenu: () => void;
};

type LayoutProviderProps = {
  children: ReactNode;
};

const LayoutContext = createContext<LayoutContextValue>({
  secondarySideMenuContent: null,
  setSecondarySideMenuContent: () => {},
  hideSecondarySideMenu: () => {},
});

const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const [secondarySideMenuContent, setSecondarySideMenuContent] =
    useState<ReactNode>(null);

  const hideSecondarySideMenu = useCallback(() => {
    setSecondarySideMenuContent(null);
  }, []);

  const value = useMemo(() => {
    return {
      secondarySideMenuContent,
      setSecondarySideMenuContent,
      hideSecondarySideMenu,
    };
  }, [secondarySideMenuContent, hideSecondarySideMenu]);

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

const useLayoutState = () => useContext(LayoutContext);

export default LayoutProvider;
export { useLayoutState };
export type { LayoutContextValue, LayoutProviderProps };
