import Section from "atomic-components/atoms/Section";
import EvaluationWizardButton from "./EvaluationWizardButton/EvaluationWizardButton";
import CertificateInfoSummary from "./CertificateInfoSummary/CertificateInfoSummary";
import Scenarios from "./Scenarios/Scenarios";
import ComplianceCategories from "./ComplianceCategories/ComplianceCategories";
import ComplianceTrackingProgressBar from "./CompliantTrackingProgressBar/ComplianceTrackingProgressBar";
import ComplianceWarning from "./ComplianceWarning/ComplianceWarning";

const LeedOmCertificate = () => {
  return (
    <Section>
      <EvaluationWizardButton />
      <CertificateInfoSummary />
      <ComplianceTrackingProgressBar />
      <ComplianceWarning />
      <ComplianceCategories />
      <Scenarios />
    </Section>
  );
};

export default LeedOmCertificate;
