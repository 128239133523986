import Button from "atomic-components/atoms/Buttons/Button";
import { useTranslate } from "modules/language";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { Plus } from "svg-icons";
import { useModalState } from "atomic-components/molecules/Modal";
import { useAuthenticationState } from "modules/authentication/state/authentication.state";
import ScenarioDetailsModal from "modules/building/pages/SustainabilityPage/components/ScenarioDetailsModal/ScenarioDetailsModal";
import ConfirmationModalBody from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ConfirmationModalBody/ConfirmationModalBody";

import s from "./AddScenarioButton.module.scss";

const AddScenarioButton = () => {
  const t = useTranslate();
  const { roles } = useAuthenticationState();

  const isAdmin = Boolean(
    roles?.find(({ name }: { name: string }) => name === "admin")
  );

  const { setModal } = useModalState();

  const onClickAddScenario = () => {
    if (!isAdmin) {
      setModal(
        <ConfirmationModalBody
          headerMessage="message.ACCESS_NOT_AVAILABLE"
          hintMessage="message.CONTACT_ADMIN"
          hasCancelButton={false}
        />,
        {
          width: "medium",
        }
      );
    } else
      setModal(<ScenarioDetailsModal />, {
        width: "large",
      });
  };

  return (
    <Button
      skin="outline"
      className={s.wizardButton}
      onClick={onClickAddScenario}
    >
      <StrippedButton>
        <Plus color="var(--color-primary)" iconScale={2} />
      </StrippedButton>
      <span className={s.buttonText}>{t("label.ADD_SCENARIO")}</span>
    </Button>
  );
};

export default AddScenarioButton;
