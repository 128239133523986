import withIconProps from "helpers/withIconProps";

type Props = {
  fill?: string;
};

const Trash = withIconProps(({ fill = "white" }: Props) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.39926 1.37806C4.25847 1.51672 4.2 1.66945 4.2 1.77273V2.36364H7.8V1.77273C7.8 1.66945 7.74153 1.51672 7.60074 1.37806C7.45994 1.2394 7.30486 1.18182 7.2 1.18182H4.8C4.69514 1.18182 4.54006 1.2394 4.39926 1.37806ZM9 2.36364V1.77273C9 1.28509 8.75847 0.846914 8.44926 0.542391C8.14006 0.237869 7.69514 0 7.2 0H4.8C4.30486 0 3.85994 0.237869 3.55074 0.542391C3.24153 0.846914 3 1.28509 3 1.77273V2.36364H0.6C0.268629 2.36364 0 2.6282 0 2.95455C0 3.2809 0.268629 3.54545 0.6 3.54545H1.2V11.2273C1.2 11.7149 1.44153 12.1531 1.75074 12.4576C2.05994 12.7621 2.50486 13 3 13H9C9.49514 13 9.94006 12.7621 10.2493 12.4576C10.5585 12.1531 10.8 11.7149 10.8 11.2273V3.54545H11.4C11.7314 3.54545 12 3.2809 12 2.95455C12 2.6282 11.7314 2.36364 11.4 2.36364H9ZM2.4 3.54545V11.2273C2.4 11.3305 2.45847 11.4833 2.59926 11.6219C2.74006 11.7606 2.89514 11.8182 3 11.8182H9C9.10486 11.8182 9.25994 11.7606 9.40074 11.6219C9.54153 11.4833 9.6 11.3305 9.6 11.2273V3.54545H2.4Z"
        fill={fill}
      />
    </svg>
  );
});

export default Trash;
