import withIconProps from "helpers/withIconProps";
import type { SVGAttributes } from "react";

type Props = SVGAttributes<SVGElement> & {
  isHigh?: boolean;
};

const VOCIcon = withIconProps(
  ({ color, fill: passedFill, isHigh, ...props }: Props) => {
    const currentColor = color ?? "currentcolor";

    const fill =
      passedFill ||
      (isHigh ? "var(--color-red-pale)" : "var(--color-green-dark)");

    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
          fill={fill}
        />
        <path
          d="M21.7 11.6997C21.9927 11.4179 22.3497 11.2117 22.7401 11.0989C23.1304 10.9861 23.5424 10.9701 23.9403 11.0524C24.3383 11.1347 24.7101 11.3128 25.0237 11.5711C25.3373 11.8295 25.5832 12.1604 25.7401 12.5353C25.897 12.9101 25.9602 13.3175 25.9242 13.7222C25.8882 14.127 25.754 14.5168 25.5334 14.8581C25.3128 15.1993 25.0123 15.4816 24.658 15.6805C24.3037 15.8794 23.9062 15.989 23.5 15.9997H6M13.6 8.5998C13.8317 8.36351 14.1184 8.18839 14.4344 8.09016C14.7504 7.99192 15.0858 7.97363 15.4106 8.03693C15.7355 8.10023 16.0395 8.24314 16.2955 8.45284C16.5515 8.66255 16.7514 8.9325 16.8774 9.2385C17.0034 9.5445 17.0515 9.87698 17.0174 10.2061C16.9833 10.5353 16.8681 10.8509 16.682 11.1245C16.4959 11.3982 16.2449 11.6214 15.9513 11.7742C15.6578 11.9269 15.3309 12.0045 15 11.9998H6M16.6 23.3997C16.8317 23.636 17.1184 23.8111 17.4344 23.9094C17.7504 24.0076 18.0858 24.0259 18.4106 23.9626C18.7355 23.8993 19.0395 23.7564 19.2955 23.5467C19.5515 23.337 19.7514 23.067 19.8774 22.761C20.0034 22.455 20.0515 22.1225 20.0174 21.7934C19.9833 21.4642 19.8681 21.1487 19.682 20.875C19.4959 20.6013 19.2449 20.3781 18.9513 20.2254C18.6578 20.0726 18.3309 19.9951 18 19.9997H6"
          stroke={currentColor}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
);

export default VOCIcon;
