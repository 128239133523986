import UnitValue from "atomic-components/atoms/UnitValue";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import { ExternalLink } from "svg-icons";
import { useTranslate } from "modules/language";
import { Unit } from "types/unit";
import type { Nullable } from "types/utils";

type LabelByRefrigerantTypesProps = {
  values: [
    Nullable<number> | string,
    Nullable<number> | string,
    Nullable<number> | string
  ];
  label: string;
  link?: string;
  unit?: Unit;
};

const LabelByRefrigerantTypes = ({
  values,
  unit,
  label,
  link,
}: LabelByRefrigerantTypesProps) => {
  const t = useTranslate();

  return (
    <WidgetContainer multiLine>
      <KeyValue
        keyText={
          <>
            {label}{" "}
            {link ? (
              <a href={link} target="_blank" rel="noreferrer">
                <ExternalLink color="var(--color-primary)" iconScale={1.5} />
              </a>
            ) : null}
          </>
        }
        value={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <WidgetContainer size={"small"}>
              {t("label.REFRIGERANT_TYPE_1")}:{" "}
              <UnitValue value={values[0]} unit={unit} />
            </WidgetContainer>
            <WidgetContainer size={"small"}>
              {t("label.REFRIGERANT_TYPE_2")}:{" "}
              <UnitValue value={values[1]} unit={unit} />
            </WidgetContainer>
            <WidgetContainer size={"small"}>
              <>
                {t("label.REFRIGERANT_TYPE_3")}:{" "}
                <UnitValue value={values[2]} unit={unit} />
              </>
            </WidgetContainer>
          </div>
        }
        isLight
      />
    </WidgetContainer>
  );
};

export default LabelByRefrigerantTypes;
