import Table, { TableCell, TableRow } from "atomic-components/organisms/Table";
import useBuildingData from "modules/building/hooks/useBuildingData";
import { Nullable } from "types/utils";
import { useTranslate } from "modules/language";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import getRatio from "helpers/getRatio";
import FormattedValue from "atomic-components/atoms/FormattedValue";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";

type PropsType = {
  commonUtilitiesData: {
    titleKey: string;
    emissions: Nullable<number>;
  }[];
};

const CommonElectricityBreakdownTable = ({
  commonUtilitiesData,
}: PropsType) => {
  const { buildingData } = useBuildingData();
  const selectedEntity = useSelectedEntity(buildingData);

  const { resolutions, Notifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        resolutionKey: "1month",
        telemetries: [{ telemetryName: "dailyMaximumUniqueEmployees" }],
      },
    ],
    [selectedEntity.id]
  );

  const numOfDays =
    resolutions["1month"][selectedEntity.id]["dailyMaximumUniqueEmployees"]
      .days;
  const numOfUniqueEmployees =
    resolutions["1month"][selectedEntity.id]["dailyMaximumUniqueEmployees"]
      .data[0]?.value;
  const entityArea = parseFloat(selectedEntity.area ?? "0");
  const t = useTranslate();

  const totalEmissions = commonUtilitiesData.reduce(
    (acc, { emissions }) => acc + (emissions || 0),
    0
  );

  const detailedCommonUtilitiesData = [
    {
      title: "label.TOTAL",
      emissions: totalEmissions,
      emissionsPerPerson: totalEmissions / numOfUniqueEmployees,
      emissionsPerDay: totalEmissions / numOfDays,
      emissionsPerM2: totalEmissions / entityArea,
    },
    ...commonUtilitiesData.map(({ titleKey, emissions }) => ({
      title: titleKey,
      emissions,
      emissionsPerPerson: (emissions ?? 0) / numOfUniqueEmployees,
      emissionsPerDay: (emissions ?? 0) / numOfDays,
      emissionsPerM2: (emissions ?? 0) / entityArea,
    })),
  ];

  return (
    <Table>
      <thead>
        <TableRow skin="primary">
          <TableCell isHead>{t("label.COMMON_UTILITIES")}</TableCell>
          <TableCell isHead>{t("label.AVG_EMISSIONS_OVER_DAY")}</TableCell>
          <TableCell isHead>{t("label.AVG_EMISSIONS_OVER_PERS")}</TableCell>
          <TableCell isHead>
            <>
              {t("label.AVG_EMISSIONS_OVER_M")}
              <sup>2</sup>
            </>
          </TableCell>
        </TableRow>
      </thead>
      <tbody>
        {detailedCommonUtilitiesData.map((trend) => {
          const { title, emissionsPerDay, emissionsPerPerson, emissionsPerM2 } =
            trend;
          return (
            <TableRow>
              <TableCell>{t(title)}</TableCell>
              <TableCell>
                <FormattedValue value={emissionsPerDay} />
              </TableCell>
              <TableCell>
                <FormattedValue value={emissionsPerPerson} />
              </TableCell>
              <TableCell>
                <FormattedValue value={emissionsPerM2} />
              </TableCell>
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
};

export default CommonElectricityBreakdownTable;
