import FormattedValue from "../FormattedValue";
import UnitValue from "../UnitValue";

import type { Bucket } from "types/bucket";
import type { Currency, Unit } from "types/unit";

type BucketDisplayerProps = {
  bucket: Bucket;
  unit?: Unit | Currency;
};

const BucketDisplayer = ({ bucket, unit }: BucketDisplayerProps) => {
  if (unit?.isPrefix) {
    return (
      <>
        <UnitValue value={bucket.from} unit={unit} />-
        <FormattedValue value={bucket.to} />
      </>
    );
  }
  return (
    <>
      <FormattedValue value={bucket.from} />-
      <UnitValue value={bucket.to} unit={unit} />
    </>
  );
};

export default BucketDisplayer;
export type { BucketDisplayerProps };
