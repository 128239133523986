import React from "react";
import classNames from "classnames";

const Occupancy = ({
  color = "#7a7a7a",
  className,
  iconScale = 1,
  icon = true,
  fill = true,
}) => {
  color = color ?? "currentcolor";
  const color1 = color !== "red" ? "#5db057" : "#d03933";
  const color2 = "#7a7a7a";
  const props = {
    fill: color === "red" ? "none" : color2,
    stroke: color === "red" ? color2 : null,
    strokeWidth: color === "red" ? ".27px" : null,
  };
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill={color1}
        d="M1.63,9.37h7.58c.45,0,.81,.36,.81,.81h0c0,.45-.36,.81-.81,.81H1.63c-.45,0-.81-.36-.81-.81H.81c0-.45,.36-.81,.81-.81Z"
      />
      <g>
        <path
          {...props}
          d="M2.32,2.29c.52,0,.94-.42,.94-.94s-.42-.94-.94-.94-.94,.42-.94,.94,.42,.94,.94,.94Z"
        />
        <path
          {...props}
          d="M5.32,2.29c.52,0,.94-.42,.94-.94s-.42-.94-.94-.94-.94,.42-.94,.94,.42,.94,.94,.94Z"
        />
        <path
          {...props}
          d="M8.33,2.29c.52,0,.94-.42,.94-.94s-.42-.94-.94-.94-.94,.42-.94,.94,.42,.94,.94,.94Z"
        />
        <path
          {...props}
          d="M9.2,2.43h-.15c-.21,.14-.46,.22-.73,.22s-.52-.08-.73-.22h-.14c-.09,0-.17,.02-.24,.05,.1,.17,.16,.37,.16,.59v2.2c0,.13-.03,.25-.08,.36,.05,.05,.07,.11,.07,.18l.21,2.26c.02,.26,.24,.46,.5,.46h.49c.26,0,.48-.2,.5-.46l.22-2.27c0-.14,.11-.25,.25-.25,.16,0,.29-.13,.29-.29V3.06c0-.35-.28-.63-.63-.63h0Z"
        />
        <path
          {...props}
          d="M6.19,2.43h-.15c-.21,.14-.46,.22-.73,.22s-.52-.08-.73-.22h-.14c-.35,0-.63,.28-.63,.63v2.2c0,.16,.13,.29,.29,.29,.14,0,.25,.11,.25,.25l.21,2.26c.02,.26,.24,.46,.5,.46h.49c.26,0,.48-.2,.5-.46l.22-2.27c0-.14,.11-.25,.25-.25,.16,0,.29-.13,.29-.29V3.06c0-.35-.28-.63-.63-.63h0Z"
        />
        <path
          {...props}
          d="M3.27,5.27V3.07c0-.21,.06-.42,.16-.59-.07-.03-.15-.05-.24-.05h-.15c-.21,.14-.46,.22-.73,.22s-.52-.08-.73-.22h-.14c-.35,0-.63,.28-.63,.63v2.2c0,.16,.13,.29,.29,.29,.14,0,.25,.11,.25,.25l.21,2.26c.02,.26,.24,.46,.5,.46h.49c.26,0,.48-.2,.5-.46l.22-2.27c0-.07,.03-.13,.07-.18-.05-.11-.08-.23-.08-.36h0Z"
        />
      </g>
    </svg>
  );
};

export default Occupancy;
