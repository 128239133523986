import type { Nullable } from "types/utils";

enum RequisiteEnum {
  Prereq = "PREREQ",
  Credit = "CREDIT",
}

type SubComplianceScore<COMPLIANCE_SCORE_KEY extends string> = {
  key: COMPLIANCE_SCORE_KEY;
  requisite?: RequisiteEnum;
  title: string;
  minimumRequired?: number;
  maximumRequired?: number;
  target?: number;
  url?: string;
  fieldType: "number" | "boolean";
  fieldInputType?: "radio";
  fieldStep?: number;
  children?: SubComplianceScore<COMPLIANCE_SCORE_KEY>[];
};

type ComplianceScore<COMPLIANCE_SCORE_KEY extends string> = {
  key: COMPLIANCE_SCORE_KEY;
  title: string;
  minimumRequired?: number;
  maximumRequired: number;
  children?: SubComplianceScore<COMPLIANCE_SCORE_KEY>[];
  url?: string;
  isEditable: boolean;
};

type ComplianceScoreValues<COMPLIANCE_SCORE_KEY extends string> = {
  [key in COMPLIANCE_SCORE_KEY]: Nullable<number>;
};

export { RequisiteEnum };
export type { ComplianceScore, SubComplianceScore, ComplianceScoreValues };
