import React, { useEffect, useRef, useState } from "react";
import { addToast } from "../../../toast";
import { addObjects } from "../../state/viewer.context-actions";
import Loader from "../Loader";
import { useViewerDispatcher, useViewerState } from "../Provider/Provider";
import {
  computeUVCoord,
  getUVAndWorldConverterFunctions,
} from "helpers/TextureHelpers";
import { Group, MeshStandardMaterial, Object3D, Wrapping } from "three";

type MaterialMapping = { materialCode: string; objectsIds: string[] };

type IFCModelProps = {
  url: string;
  onLoad: (args: { objects: Object3D[] }) => void;
  onLoadStart: () => void;
  onUnload: () => void;
  materialsMappings: MaterialMapping[];
};
type MapTypes = Pick<
  MeshStandardMaterial,
  | "map"
  | "normalMap"
  | "roughnessMap"
  | "displacementMap"
  | "aoMap"
  | "metalnessMap"
>;
/*  */
type MaterialParams = {
  [materialCode: string]:
    | Partial<Omit<MeshStandardMaterial, keyof MapTypes | "isMaterial">> & {
        path?: string;
        excludes?: string[];
        textureParams?: {
          wrapS: Wrapping;
          wrapT: Wrapping;
          setS: number;
          setT: number;
        };
      };
};

const materialsParams: MaterialParams = {
  "Sheet Metal-Galvanized Steel": {
    path: "ducts/old-sheet-metal/",
    excludes: ["displacement"],
    textureParams: {
      wrapS: window.THREE.RepeatWrapping,
      wrapT: window.THREE.RepeatWrapping,
      setS: 20,
      setT: 20,
    },
  },
  "Grey Marble w/veining": {
    path: "floor/marble/floor_tiles_02_4k/",
    excludes: ["displacement", "metalness"],
    textureParams: {
      wrapS: window.THREE.RepeatWrapping,
      wrapT: window.THREE.RepeatWrapping,
      setS: 20,
      setT: 20,
    },
    normalScale: new window.THREE.Vector2(5, 5),
  },
  "Grey Carpet": {
    path: "floor/carpet/carpet1-bl/",
    excludes: ["displacement"],
    textureParams: {
      wrapS: window.THREE.RepeatWrapping,
      wrapT: window.THREE.RepeatWrapping,
      setS: 20,
      setT: 20,
    },
    normalScale: new window.THREE.Vector2(3, 3),
  },
  paint: {
    path: "wall/white/fiberous-plaster1-bl/",
    excludes: ["displacement"],
    textureParams: {
      wrapS: window.THREE.RepeatWrapping,
      wrapT: window.THREE.RepeatWrapping,
      setS: 20,
      setT: 20,
    },
  },
};

const getObjects = (children: Object3D[]): Object3D[] =>
  children.flatMap((a) => [a, ...getObjects(a.children)]);

const createMaterialFromParam = (
  texturesPath: string,
  params: MaterialParams
) => {
  const basicMaps = [
    "map",
    "normal",
    "ao",
    "displacement",
    "roughness",
    "metalness",
  ];
  const materials = Object.fromEntries(
    Object.entries(params).map(
      ([
        materialCode,
        { path, excludes, textureParams, ...materialparams },
      ]) => {
        const standardmaterial = new window.THREE.MeshStandardMaterial();
        if (path) {
          const includes = basicMaps.filter((map) => !excludes?.includes(map));
          includes.forEach((mapName) => {
            const mapAttribite: keyof MapTypes =
              mapName === "map"
                ? (`map` as keyof MapTypes)
                : (`${mapName}Map` as keyof MapTypes);
            standardmaterial[mapAttribite] =
              new window.THREE.TextureLoader().load(
                texturesPath + path + mapName + ".png",
                (texture) => {
                  texture.wrapS = textureParams?.wrapS ?? texture.wrapS;
                  texture.wrapT = textureParams?.wrapT ?? texture.wrapT;
                  texture.repeat.set(
                    textureParams?.setS ?? 1,
                    textureParams?.setT ?? 1
                  );
                }
              );
          });
        }

        Object.entries(materialparams).forEach(([key, value]) => {
          standardmaterial.setValues({ [key]: value });
        });
        return [materialCode, standardmaterial];
      }
    )
  );
  return materials;
};

const IFCModel = ({
  url,
  onLoadStart,
  onLoad,
  onUnload,
  materialsMappings,
}: IFCModelProps) => {
  const { initialized, scene, renderer } = useViewerState();
  const dispatchViewerAction = useViewerDispatcher();
  const mutableRef = useRef<{
    onLoad?: (args: { objects: Object3D[] }) => void;
    onLoadStart?: () => void;
    onUnload?: () => void;
  }>({});
  mutableRef.current.onLoad = onLoad;
  mutableRef.current.onLoadStart = onLoadStart;
  mutableRef.current.onUnload = onUnload;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    const texturesPath = process.env.PUBLIC_URL + "/textures/";

    const materials = createMaterialFromParam(texturesPath, materialsParams);
    const onLoadStart = mutableRef.current?.onLoadStart;
    if (onLoadStart) {
      onLoadStart();
    }
    setLoading(true);

    let toRemove: Group | null = null;
    let removed = false;
    const loader = new window.THREEHelpers.GLTFLoader();
    loader.load(
      url,
      (gltf) => {
        setLoading(false);
        if (removed) {
          return;
        }
        toRemove = gltf.scene;
        scene.add(gltf.scene);
        const objects = getObjects([gltf.scene]);

        for (let object of objects) {
          if (!(object instanceof window.THREE.Mesh) || !object.material) {
            continue;
          }
          const materialMapping = materialsMappings?.find((mapping) =>
            mapping.objectsIds.includes(object.name)
          );
          const materialCode = materialMapping
            ? materialMapping.materialCode
            : undefined;
          if (!materialCode || !materials[materialCode]) {
            continue;
          }

          object.matrixWorld = new window.THREE.Matrix4().compose(
            object.position,
            object.quaternion,
            object.scale
          );
          const { fromWorldSpaceToUvSpace, fromUvSpaceToWorldSpace } =
            getUVAndWorldConverterFunctions(object);
          computeUVCoord(object, fromWorldSpaceToUvSpace);
          object.material = materials[materialCode];
        }

        dispatchViewerAction(addObjects(objects));
        const onLoad = mutableRef.current?.onLoad;
        if (onLoad) {
          onLoad({ objects });
        }
      },
      undefined,
      (error) => {
        console.error(error);
        addToast({
          messageKey: "error.ERROR_LOADING_MODEL",
          variant: "danger",
        });
      }
    );

    return () => {
      removed = true;
      if (toRemove) {
        scene.remove(toRemove);
      }
      const onUnload = mutableRef.current?.onUnload;
      if (onUnload) {
        onUnload();
      }
    };
  }, [initialized, scene, url]);

  if (loading) {
    return <Loader />;
  }
  return null;
};

export default IFCModel;
