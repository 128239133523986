import { useState, useEffect } from "react";

import { useSelectedLanguageState } from "modules/language";
import { useWindowSize } from "modules/core/services/window-size.service";
import SizeProvider from "modules/layout/components/SizeProviders";
import MainMenu from "modules/layout/components/MainMenu/MainMenu";
import { Outlet } from "react-router-dom";
import sideMenuStyles from "modules/layout/components/MainMenu/MainMenu.module.scss";
import { withBuildingData } from "modules/building/hooks/useBuildingData";
import LayoutProvider from "../LayoutProvider/LayoutProvider";
import SecondarySideMenu from "../SecondarySideMenu/SecondarySideMenu";
import ChartPanelProvider from "modules/layout/components/ChartPanelProvider";

import "./Layout.css";

const LayoutComponent = () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [{ width, height, rotated = false }, setSize] = useState(() => ({
    width: windowWidth,
    height: windowHeight,
  }));
  useEffect(() => {
    if (windowWidth < windowHeight) {
      setSize({
        width: windowHeight,
        height: windowWidth,
        rotated: true,
      });
    } else {
      setSize({
        width: windowWidth,
        height: windowHeight,
        rotated: false,
      });
    }
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    const [html] = document.getElementsByTagName("html");
    if (rotated) {
      html.style.setProperty("width", `${width}px`);
      html.style.setProperty("height", `${height}px`);
      html.style.setProperty("transform-origin", "0 0");
      html.style.setProperty("transform", "rotate(90deg) translateY(-100%)");

      return () => {
        html.style.removeProperty("width");
        html.style.removeProperty("height");
        html.style.removeProperty("transformOrigin");
        html.style.removeProperty("transform");
      };
    }
  }, [rotated, width, height]);

  const { language: selectedLanguage } = useSelectedLanguageState();
  return (
    <div
      className="layout"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <div className="sideMenus">
        <MainMenu />
        <SecondarySideMenu />
      </div>
      <div
        className="layout__main"
        style={{
          [selectedLanguage.dir === "rtl"
            ? "marginRight"
            : "marginLeft"]: `${sideMenuStyles.sideMenuWidthCollapsed}px`,
          width: `${width - sideMenuStyles.sideMenuWidthCollapsed}px`,
          height: `${height}px`,
        }}
      >
        <SizeProvider
          width={width - sideMenuStyles.sideMenuWidthCollapsed}
          height={height}
        >
          <Outlet />
        </SizeProvider>
      </div>
    </div>
  );
};

const Layout = withBuildingData(() => {
  return (
    <ChartPanelProvider>
      <LayoutProvider>
        <LayoutComponent />
      </LayoutProvider>
    </ChartPanelProvider>
  );
});

export default Layout;
