import type { ReactNode } from "react";
import classNames from "classnames";
import KeyValue, {
  type KeyValueProps,
} from "atomic-components/molecules/KeyValue";
import WidgetContainer, {
  type WidgetContainerProps,
} from "atomic-components/atoms/WidgetContainer";

import s from "./WidgetRadioTab.module.scss";

type WidgetRadioTabProps = Omit<WidgetContainerProps, "skin"> & {
  keyText: ReactNode;
  value: ReactNode;
  skin?: "default" | "button";
  keyValueProps?: Omit<KeyValueProps, "keyText" | "value">;
} & (
    | {
        isSelectable: true;
        isSelected: boolean;
        id: string;
        controlsElementId: string;
      }
    | {
        isSelectable?: false;
        isSelected?: never;
        id?: never;
        controlsElementId?: never;
      }
  );

const WidgetRadioTab = ({
  keyText,
  value,
  isSelectable,
  isSelected,
  controlsElementId,
  className,
  skin = "default",
  keyValueProps,
  ...props
}: WidgetRadioTabProps) => {
  let tag: WidgetContainerProps["tag"] = "section";
  let role: string | undefined = undefined;
  let ariaSelected: boolean | undefined = undefined;
  let ariaControls: string | undefined = undefined;

  if (isSelectable) {
    tag = "button";
    role = "tab";
    ariaSelected = isSelected;
    ariaControls = controlsElementId;
  }

  return (
    <WidgetContainer
      tag={tag}
      size="smallY"
      className={classNames(s.tab, s[`skin--${skin}`], className)}
      role={role}
      aria-selected={ariaSelected}
      aria-controls={ariaControls}
      {...props}
    >
      <KeyValue
        keyClassName={s.key}
        keyText={
          isSelectable && skin === "default" ? (
            <span className={s.key}>
              <span className={s.tabBullet} />
              {keyText}
            </span>
          ) : (
            keyText
          )
        }
        valueClassName={s.value}
        value={value}
        {...keyValueProps}
      />
    </WidgetContainer>
  );
};

export default WidgetRadioTab;
export type { WidgetRadioTabProps };
