import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { useTranslate } from "modules/language";
import { type ReactNode } from "react";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import BackArrowIcon from "svg-icons/BackArrowIcon";
import classNames from "classnames";

import s from "./EditableParamContainer.module.scss";

type EditableParamContainerProps = {
  isEditEnabled: boolean;
  onRevert: () => void;
  children: ReactNode;
  className?: string;
};

const EditableParamContainer = ({
  isEditEnabled,
  onRevert,
  children,
  className,
}: EditableParamContainerProps) => {
  const t = useTranslate();

  return (
    <WidgetContainer className={classNames(s.container, s.editable, className)}>
      {children}
      {isEditEnabled ? (
        <StrippedButton
          type="reset"
          className={s.controlBtn}
          title={t("action.REVERT")}
          onClick={(e) => {
            e.preventDefault();
            onRevert();
          }}
        >
          <BackArrowIcon color="var(--color-primary)" />
        </StrippedButton>
      ) : null}
    </WidgetContainer>
  );
};

export default EditableParamContainer;
export type { EditableParamContainerProps };
