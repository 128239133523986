const intersectObjects = (e, objects, canvas, camera) => {
  if (
    !objects ||
    (e.target !== canvas && e.target.parentElement !== canvas.parentElement)
  ) {
    return;
  }

  const raycaster = new window.THREE.Raycaster();

  const rect = canvas.getBoundingClientRect();

  const width = canvas.offsetWidth;
  const height = canvas.offsetHeight;

  const mouse = {
    x: e.clientX - rect.left,
    y: e.clientY - rect.top,
  };
  const pointer = {
    x: (mouse.x / width) * 2 - 1,
    y: -(mouse.y / height) * 2 + 1,
  };

  raycaster.setFromCamera(pointer, camera);

  const intersections = raycaster.intersectObjects(objects);

  if (intersections?.length) {
    return {
      intersections,
      mouse,
      pointer,
    };
  } else {
    return {
      intersections,
      mouse,
      pointer,
    };
  }
};

export default intersectObjects;
