import { ChevronUpIcon } from "svg-icons";
import type { Nullable } from "types/utils";

import s from "./ScorePointer.module.scss";

type ScorePointerProps = {
  currentPosition: Nullable<string>;
  targetPosition: Nullable<string>;
};

const ScorePointer = ({
  currentPosition,
  targetPosition,
}: ScorePointerProps) => {
  return (
    <>
      {currentPosition ? (
        <div
          aria-hidden
          className={s.pointer}
          style={{
            insetInlineStart: currentPosition,
          }}
        >
          <div
            className={s.borderLine}
            style={{
              insetInlineStart: currentPosition,
            }}
          />
          <ChevronUpIcon className={s.chevronCurrent} />
        </div>
      ) : null}
      {targetPosition ? (
        <div
          aria-hidden
          className={s.pointer}
          style={{
            insetInlineStart: targetPosition,
          }}
        >
          <div
            className={s.borderHashed}
            style={{
              insetInlineStart: targetPosition,
            }}
          />
          <ChevronUpIcon className={s.chevronTarget} />
        </div>
      ) : null}
    </>
  );
};

export default ScorePointer;
