import CollapsibleContainer from "atomic-components/molecules/CollapsibleContainer/CollapsibleContainer";
import WidgetGrid from "atomic-components/molecules/WidgetGrid/WidgetGrid";
import useBuildingData from "modules/building/hooks/useBuildingData";
import { useTranslate } from "modules/language";
import BreakdownTitle from "../BreakdownTitle/BreakdownTitle";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import UnitValue from "atomic-components/atoms/UnitValue";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import DomesticWaterAssumptions from "./DomesticWaterAssumption/DomesticWaterAssumption";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";
import { useMergedNotifier } from "helpers/getNotifier";
import { useDomesticWaterEmissions } from "modules/building/hooks/useDomesticWaterEmissions";
import getRatio from "helpers/getRatio";

type PropsType = {
  scopes: string[];
};

const DomesticWaterBreackdown = ({ scopes }: PropsType) => {
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;

  const {
    totalDomesticWaterEmissions,
    totalDomesticWaterUsage,
    Notifier: ENotifier,
  } = useDomesticWaterEmissions();

  const selectedEntity = useSelectedEntity(buildingData);
  const { resolutions, Notifier: TNotifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        resolutionKey: "1month",
        telemetries: [{ telemetryName: "dailyMaximumUniqueEmployees" }],
      },
    ],
    [selectedEntity]
  );

  const numOfUniqueEmployees =
    resolutions["1month"][selectedEntity.id].dailyMaximumUniqueEmployees.data[0]
      .value;
  const entityArea = parseFloat(selectedEntity.area ?? "1");

  const avgEmissionsPerPerson = getRatio(
    totalDomesticWaterEmissions,
    numOfUniqueEmployees
  );
  const avgEmissionPerArea = getRatio(totalDomesticWaterEmissions, entityArea);

  const Notifier = useMergedNotifier(TNotifier, ENotifier);

  const t = useTranslate();

  return (
    <Notifier>
      <CollapsibleContainer
        titleFontWeight="normal"
        title={
          <BreakdownTitle label={t("label.DOMESTIC_WATER")} scopes={scopes} />
        }
        hasExtraSpacing
      >
        <WidgetGrid columnCount={1}>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.TOTAL_WATER_USAGE")}
              value={
                <UnitValue
                  value={totalDomesticWaterUsage}
                  unit={unitsByName.m3}
                />
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.TOTAL_EMISSIONS")}
              value={
                <UnitValue
                  value={totalDomesticWaterEmissions}
                  unit={unitsByName.kgco2e}
                />
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.AVG_EMISSION_PER_PERSON")}
              value={
                <>
                  <UnitValue
                    value={avgEmissionsPerPerson}
                    unit={unitsByName.kgco2e}
                  />
                  /
                  <Displayer object={unitsByName.person} />
                </>
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.AVG_EMISSION_PER_AREA")}
              value={
                <>
                  <UnitValue
                    value={avgEmissionPerArea}
                    unit={unitsByName.kgco2e}
                  />
                  /
                  <Displayer object={unitsByName.m2} />
                </>
              }
              isLight
            />
          </WidgetContainer>
        </WidgetGrid>
        <DomesticWaterAssumptions />
      </CollapsibleContainer>
    </Notifier>
  );
};
export default DomesticWaterBreackdown;
