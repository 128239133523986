import classNames from "classnames";

type AlarmActiveProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  color?: string;
};

const AlarmActive = ({
  className,
  iconScale = 1,
  icon = true,
  color = "#0040FF",
}: AlarmActiveProps) => (
  <svg
    className={classNames(className, { "para-icon": icon })}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fontSize: `${iconScale * 100}%`,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.89373 20.3513C10.252 20.1435 10.711 20.2654 10.9188 20.6237C11.0287 20.8132 11.1864 20.9704 11.3762 21.0797C11.5659 21.189 11.7811 21.2465 12.0001 21.2465C12.219 21.2465 12.4342 21.189 12.6239 21.0797C12.8137 20.9704 12.9714 20.8132 13.0813 20.6237C13.2891 20.2654 13.7481 20.1435 14.1064 20.3513C14.4647 20.5592 14.5866 21.0181 14.3788 21.3764C14.1371 21.7931 13.7901 22.139 13.3726 22.3795C12.9551 22.62 12.4818 22.7465 12.0001 22.7465C11.5183 22.7465 11.045 22.62 10.6275 22.3795C10.21 22.139 9.86305 21.7931 9.62131 21.3764C9.41347 21.0181 9.53543 20.5592 9.89373 20.3513Z"
      fill={color}
    />
    <path
      d="M18.8169 9.72006C19.0115 12.1306 19.5991 13.7404 20.1585 14.7659C20.5011 15.394 20.8371 15.8105 21.0758 16.0624C21.1953 16.1885 21.2909 16.2739 21.3513 16.3243C21.3815 16.3495 21.403 16.3659 21.4142 16.3743L21.4229 16.3806C21.6931 16.565 21.8129 16.9037 21.7179 17.2174C21.6221 17.5337 21.3306 17.7501 21.0001 17.7501H3.00006C2.66956 17.7501 2.37801 17.5337 2.28224 17.2174C2.18726 16.9037 2.30703 16.565 2.57726 16.3806L2.58589 16.3743C2.59716 16.3659 2.61861 16.3495 2.64883 16.3243C2.70925 16.2739 2.80483 16.1885 2.92434 16.0624C3.16298 15.8105 3.49904 15.394 3.84164 14.7659C4.52476 13.5135 5.25006 11.3896 5.25006 8.00006C5.25006 6.20985 5.96122 4.49296 7.22709 3.22709C8.41295 2.04123 9.99462 1.34217 11.6616 1.25854C11.4109 1.74577 11.2249 2.27177 11.115 2.82517C10.0511 3.00713 9.06118 3.51432 8.28775 4.28775C7.30318 5.27232 6.75006 6.60767 6.75006 8.00006C6.75006 11.6106 5.97536 13.9866 5.15848 15.4842C5.0014 15.7722 4.84336 16.0266 4.68988 16.2501H19.3102C19.1568 16.0266 18.9987 15.7722 18.8416 15.4842C18.1855 14.2813 17.5566 12.5117 17.3345 9.9909C17.8485 9.96266 18.3454 9.86971 18.8169 9.72006Z"
      fill={color}
    />
    <circle cx="17" cy="4" r="4" fill={color} />
  </svg>
);

export default AlarmActive;
