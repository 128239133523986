/**
 * @type {import("@floating-ui/react-dom").Placement[]}
 */
const placements = [
  "right",
  "right-start",
  "bottom",
  "left-start",
  "left",
  "left-end",
  "top",
  "right-end",
];
const getTipPlacementByAngle = (angle) => {
  angle = (360 + angle) % 360;
  const index = Math.round(angle / 45) % placements.length;
  return placements[index];
};

export default getTipPlacementByAngle;
