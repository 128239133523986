import UnitValue from "atomic-components/atoms/UnitValue";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import { Fragment } from "react";
import type { Units } from "../types";

type EditableParamHistoryContainerValueProps = {
  value: number;
  units?: Units;
};

const EditableParamHistoryContainerValue = ({
  value,
  units,
}: EditableParamHistoryContainerValueProps) => {
  const [firstUnit, ...restUnits] = units || [];

  return (
    <>
      <UnitValue value={value} unit={firstUnit?.unit} />
      {firstUnit?.separator}
      {restUnits.map(({ unit, separator }, index) => {
        return (
          <Fragment key={index}>
            <Displayer object={unit} />
            {separator}
          </Fragment>
        );
      })}
    </>
  );
};

export default EditableParamHistoryContainerValue;
export type { EditableParamHistoryContainerValueProps };
