import withIconProps from "helpers/withIconProps";
import type { SVGAttributes } from "react";

type Props = SVGAttributes<SVGElement> & {
  isHigh?: boolean;
};

const RelHumidityIcon = withIconProps(
  ({ color, fill: passedFill, isHigh, ...props }: Props) => {
    const currentColor = color ?? "currentcolor";

    const fill =
      passedFill ||
      (isHigh ? "var(--color-red-pale)" : "var(--color-green-dark)");

    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
          fill={fill}
        />
        <path
          d="M16.5601 10.5995C17.2478 9.50066 17.7354 8.28858 18.0001 7.01953C18.5001 9.51953 20.0001 11.9195 22.0001 13.5195C24.0001 15.1195 25.0001 17.0195 25.0001 19.0195C25.0058 20.4018 24.601 21.7547 23.8369 22.9066C23.0728 24.0586 21.9839 24.9577 20.7082 25.49C19.4325 26.0224 18.0275 26.1639 16.6713 25.8968C15.315 25.6297 14.0686 24.9659 13.0901 23.9895M11 20.2995C13.2 20.2995 15 18.4695 15 16.2495C15 15.0895 14.43 13.9895 13.29 13.0595C12.15 12.1295 11.29 10.7495 11 9.29953C10.71 10.7495 9.86 12.1395 8.71 13.0595C7.56 13.9795 7 15.0995 7 16.2495C7 18.4695 8.8 20.2995 11 20.2995Z"
          stroke={currentColor}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
);

export default RelHumidityIcon;
