import classNames from "classnames";
import React from "react";

const RelativeHumidity = ({ className, iconScale = 1, icon = true }) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill="#50afcc"
        d="M9.15,1.55c.44,.62,.83,1.24,1.17,1.88,.25,.49,.48,.99,.61,1.52,.11,.47,.09,.94-.07,1.4-.18,.52-.54,.84-1.07,.99-.42,.12-.87,.12-1.29,0-.67-.19-1.1-.73-1.17-1.48-.03-.3-.02-.59,.05-.89,.02-.08,.05-.11,.13-.13,.72-.13,1.16-.65,1.21-1.39,.02-.3-.02-.6-.12-.88-.03-.09-.02-.16,.03-.24,.17-.25,.33-.5,.52-.78Zm-1.23,3.89s0-.1,0-.15c0-.1-.02-.18-.14-.19-.11-.01-.17,.06-.17,.16,0,.15-.01,.3,0,.45,.06,.61,.49,1.1,1.06,1.2,.1,.02,.2,.01,.22-.11,.02-.11-.04-.17-.14-.19-.58-.12-.85-.59-.84-1.17Z"
      />
      <path
        fill="#7a7a7a"
        d="M7.78,7.43l.48,.24c-.71,1.54-2.91,2.92-5.32,2.08C.54,8.92-.66,6.22,.37,3.86,1.41,1.51,4.1,.69,6.15,1.61l-.25,.49c-1.14-.44-2.26-.41-3.33,.17-.83,.45-1.42,1.13-1.75,2.02-.67,1.78,.09,3.77,1.77,4.69,1.74,.95,4.14,.41,5.2-1.55Z"
      />
      <path
        fill="#50afcc"
        d="M7.17,.71c.21,.33,.42,.65,.6,.98,.25,.43,.48,.87,.56,1.37,.05,.28,.01,.56-.1,.82-.19,.44-.65,.67-1.2,.62-.72-.08-1.1-.54-1.06-1.28,.02-.32,.11-.63,.26-.91,.28-.51,.58-1.01,.87-1.51,.01-.02,.04-.04,.07-.09Zm-.81,2.48s.01-.03,0-.05c-.02-.05-.06-.09-.08-.14-.04,.04-.1,.07-.11,.12-.02,.07-.01,.14,0,.21,.03,.43,.31,.78,.68,.83,.05,0,.1-.04,.15-.06-.03-.04-.06-.12-.1-.13-.4-.1-.54-.43-.54-.79Z"
      />
      <path
        fill="#7a7a7a"
        d="M5.57,3.81c-.18,.34-.34,.66-.5,.98-.43,.83-.86,1.65-1.29,2.48-.06,.12-.13,.18-.27,.16-.08-.01-.17,0-.27,0,.02-.05,.04-.09,.06-.13,.59-1.13,1.17-2.26,1.76-3.39,.04-.08,.08-.12,.17-.11,.11,0,.21,0,.34,0Z"
      />
      <path
        fill="#7a7a7a"
        d="M6.24,6.67c0,.43-.34,.76-.77,.76-.42,0-.75-.34-.75-.76,0-.41,.34-.76,.76-.76,.42,0,.77,.34,.77,.77Zm-.4,0s0-.09-.03-.14c-.02-.04-.05-.08-.08-.12-.03-.03-.07-.06-.12-.08-.04-.02-.09-.03-.14-.02-.2,0-.36,.16-.35,.37,0,.09,.04,.18,.11,.25,.07,.07,.16,.1,.25,.1,.19,0,.36-.17,.36-.36Z"
      />
      <path
        fill="#7a7a7a"
        d="M3.32,5.33c-.43,0-.76-.34-.76-.77,0-.42,.35-.76,.78-.75,.42,0,.75,.35,.74,.77,0,.42-.34,.75-.77,.75Zm.36-.76c0-.07-.02-.14-.06-.2-.04-.06-.1-.1-.16-.13-.07-.03-.14-.03-.21-.02-.07,.01-.13,.05-.18,.1-.05,.05-.08,.11-.1,.18-.01,.07,0,.14,.02,.21,.03,.07,.07,.12,.13,.16,.06,.04,.13,.06,.2,.06,.05,0,.09,0,.14-.03,.04-.02,.08-.04,.12-.08s.06-.07,.08-.12c.02-.04,.03-.09,.03-.14Z"
      />
    </svg>
  );
};

export default RelativeHumidity;
