import { useMutation } from "react-query";
import {
  updateEditableParam,
  UpdateEditableParamParams,
} from "../api/editableParams";
import { invalidateEditableParamHistoryQuery } from "./useEditableParamHistory";
import { AxiosError } from "axios";

const useEditableParamsMutate = () => {
  return useMutation<void, AxiosError, UpdateEditableParamParams>({
    mutationFn: (args: UpdateEditableParamParams) =>
      updateEditableParam(args).then((resp) => {
        setTimeout(() => {
          invalidateEditableParamHistoryQuery();
        }, 1000);
        return resp;
      }),
  });
};

export default useEditableParamsMutate;
