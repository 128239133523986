import withIconProps from "helpers/withIconProps";
import type { SVGAttributes } from "react";

type Props = SVGAttributes<SVGElement> & {
  isHigh?: boolean;
};

const CO2Icon = withIconProps(
  ({ color, fill: passedFill, isHigh, ...props }: Props) => {
    const currentColor = color ?? "currentcolor";

    const fill =
      passedFill ||
      (isHigh ? "var(--color-red-pale)" : "var(--color-green-dark)");

    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
          fill={fill}
        />
        <path
          d="M26.0001 13.9998C26.0001 13.2042 25.684 12.4411 25.1214 11.8785C24.5588 11.3159 23.7957 10.9998 23.0001 10.9998H20.7931C20.4538 9.80614 19.721 8.76221 18.7136 8.03754C17.7062 7.31287 16.4835 6.95004 15.2439 7.00795C14.0043 7.06587 12.8207 7.54113 11.8853 8.35656C10.9498 9.172 10.3175 10.2797 10.0911 11.4998M21.5002 24.9998H13.0002C11.702 24.9995 10.4296 24.6382 9.32494 23.9563C8.22032 23.2744 7.32708 22.2988 6.74503 21.1384C6.16298 19.9781 5.91505 18.6788 6.02895 17.3856C6.14285 16.0925 6.61409 14.8565 7.39001 13.8158C8.16593 12.7751 9.21596 11.9706 10.4228 11.4923C11.6296 11.014 12.9456 10.8807 14.2238 11.1073C15.502 11.3339 16.692 11.9114 17.6609 12.7754C18.6297 13.6394 19.3393 14.7558 19.7102 15.9998H21.5002C22.0911 15.9998 22.6763 16.1162 23.2222 16.3424C23.7682 16.5685 24.2643 16.9 24.6821 17.3178C25.1 17.7357 25.4315 18.2318 25.6576 18.7777C25.8838 19.3237 26.0002 19.9089 26.0002 20.4998C26.0002 21.0908 25.8838 21.6759 25.6576 22.2219C25.4315 22.7679 25.1 23.2639 24.6821 23.6818C24.2643 24.0997 23.7682 24.4311 23.2222 24.6573C22.6763 24.8834 22.0911 24.9998 21.5002 24.9998Z"
          stroke={currentColor}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
);

export default CO2Icon;
