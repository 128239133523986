import { remove, sortedLastIndexBy } from "lodash";
import modifyObject from "./modifyObjects";
import { sheduleUpdate, startColorization } from "./revertColorizeObject";

const colorizeObject = (object, { key, color, opacity, priority = -1 }) => {
  modifyObject(object, (object) => {
    startColorization(object);
    remove(
      object.userData.colorStack,
      (colorStackItem) => colorStackItem.key === key
    );
    const toInsert = { key, color, opacity, priority };
    if (color == null) {
      delete toInsert.color;
    }
    if (opacity == null) {
      delete toInsert.opacity;
    }
    const insertAt = sortedLastIndexBy(
      object.userData.colorStack,
      toInsert,
      ({ priority }) => priority
    );
    object.userData.colorStack.splice(insertAt, 0, toInsert);
    sheduleUpdate(object);
  });
};

export default colorizeObject;
