import type { Nullable } from "types/utils";
import { beApiClient } from "./clients";

type ComplianceScoreKey =
  | "target"
  | "total"
  | "transportation"
  | "transportationPerformance"
  | "sustainableSites"
  | "rainwaterManagement"
  | "heatIslandReduction"
  | "lightPollutionReduction"
  | "siteManagement"
  | "waterEfficiency"
  | "waterPerformance"
  | "energyAndAtmosphere"
  | "energyAndAtmospherePractices"
  | "fundamentalRefrigerant"
  | "energyPerformance"
  | "ghgEmissionsScore"
  | "sourceEmissionsSource"
  | "enhancedRefrigerant"
  | "gridHarmonization"
  | "materialsAndResources"
  | "purchasingPolicy"
  | "facilityMainteanceAndRenovationPolicy"
  | "wastePerformance"
  | "purchasing"
  | "indoorEnvironmentalQuality"
  | "minimumIndoorAirQuality"
  | "environmentalTobaccoSmokeControl"
  | "greenCleaningPolicy"
  | "indoorEnvironmentalQualityPerformance"
  | "greenCleaning"
  | "integratedPestManagement"
  | "innovation";

type ComplianceScoreValues = {
  [key in ComplianceScoreKey]: Nullable<number>;
};

type GetComplianceScoreValuesResult = {
  isPlaceholder?: boolean;
  year: number;
  values: ComplianceScoreValues;
};

type UpdateComplianceScoreValuesParams = {
  year: number;
  values: ComplianceScoreValues;
};

const getComplianceScoreValues = async (
  { year }: { year: number },
  { signal }: { signal?: AbortSignal }
) => {
  const resp = await beApiClient.request<GetComplianceScoreValuesResult>({
    url: `/api/compliance/score-values?year=${year}`,
    method: "get",
    signal,
  });

  return resp.data;
};

const updateComplianceScoreValues = async (
  payload: UpdateComplianceScoreValuesParams
) => {
  const resp = await beApiClient.request<GetComplianceScoreValuesResult>({
    url: "/api/compliance/score-values",
    method: "post",
    data: payload,
  });

  return resp.data;
};

export { getComplianceScoreValues, updateComplianceScoreValues };
export type {
  ComplianceScoreKey,
  ComplianceScoreValues,
  GetComplianceScoreValuesResult,
  UpdateComplianceScoreValuesParams,
};
