import { AggregationKeys } from "modules/building/helpers/createLinkedTelemetriesAggregator";
import type { ReactNode } from "react";
import type { AggregationsKeys, Trend, TrendSlim } from "types/trend";
import type { Nullable, Setter } from "types/utils";

type ChartAxisKey = "primary" | "secondary";

type CustomChart = { name: string; trendKeys: string[] };

type SubChartType = "supporting" | "subzone" | "custom";

type SubChart<KEY extends string = string> = {
  key: KEY;
  text: ReactNode;
  type: SubChartType;
  trends?: SetChartTrendsTrendParam[];
};
type chartDetails = {
  lowerValue: number;
  upperValue: number;
  unit: undefined;
  primaryData: [];
  secondaryData: [];
};

type AddSubChart = <SUB_CHART_KEY extends string = string>(
  subChart: SubChart<SUB_CHART_KEY>
) => void;

type RemoveSubChart = <SUB_CHART_KEY extends string = string>(
  subChartKey: SUB_CHART_KEY
) => void;

type RemoveSubCharts = (params?: { types?: SubChartType[] }) => void;

type SetChartTrendsTrendParam = TrendSlim;

type SetChartTrends = (trends: SetChartTrendsTrendParam[]) => void;

type GetChartAxisTrendKeyType = (key: string) => ChartAxisKey | undefined;

type AddChartAxisTrendKey = (args: {
  key: string;
  axis?: ChartAxisKey;
}) => void;

type RemoveChartAxisTrendKey = (key: string) => void;

type ChartPanelProviderProps = {
  children: ReactNode;
};

type ChartType = "line" | "bar";

export enum ChartAction {
  Alarm = "alarm",
  MetricRanges = "metricRanges",
  MetricOccupancyRanges = "metricOccupancyRanges",
  AveragingTool = "averagingTool",
}

type SelectedAggregations = {
  [P in AggregationsKeys]?: AggregationKeys;
};

export enum FilterTypeEnum {
  OCCUPANCY = "occupancy",
  TIME = "time",
}

type ChartPanelContextValue<SUB_CHART_KEY extends string = string> = {
  lineChartStartTs: number;
  lineChartEndTs: number;
  setChartStartEndTs: Setter<[number, number]>;
  customChart?: CustomChart;
  setCustomChart: Setter<CustomChart | undefined>;
  subCharts: SubChart<SUB_CHART_KEY>[];
  addSubChart: AddSubChart;
  removeSubChart: RemoveSubChart;
  removeSubCharts: RemoveSubCharts;
  chartDetails: {
    upperValue: number;
    lowerValue: number;
    unit: any;
    primaryData: [];
    secondaryData: [];
  };
  setChartDetails: Setter<chartDetails>;
  /**
   * main chart
   */
  mainChartTrends: Trend[];
  setMainChartTrends: SetChartTrends;
  mainChartPrimaryAxisTrendKeys: string[];
  mainChartSecondaryAxisTrendKeys: string[];
  getMainChartAxisTrendKeyType: GetChartAxisTrendKeyType;
  addMainChartAxisTrendKey: AddChartAxisTrendKey;
  removeMainChartAxisTrendKey: RemoveChartAxisTrendKey;
  /**
   * first chart
   */
  firstSubChartTrends: Trend[];
  setFirstSubChartTrends: SetChartTrends;
  firstSubChartPrimaryAxisTrendKeys: string[];
  firstSubChartSecondaryAxisTrendKeys: string[];
  getFirstSubChartAxisTrendKeyType: GetChartAxisTrendKeyType;
  addFirstSubChartAxisTrendKey: AddChartAxisTrendKey;
  removeFirstSubChartAxisTrendKey: RemoveChartAxisTrendKey;
  /**
   * second chart
   */
  secondSubChartTrends: Trend[];
  setSecondSubChartTrends: SetChartTrends;
  secondSubChartPrimaryAxisTrendKeys: string[];
  secondSubChartSecondaryAxisTrendKeys: string[];
  getSecondSubChartAxisTrendKeyType: GetChartAxisTrendKeyType;
  addSecondSubChartAxisTrendKey: AddChartAxisTrendKey;
  removeSecondSubChartAxisTrendKey: RemoveChartAxisTrendKey;

  chartType: ChartType;
  setChartType: Setter<ChartType>;
  selectedAggregations: SelectedAggregations;
  setSelectedAggregations: Setter<SelectedAggregations>;

  /**
   * filter type selections
   */
  filterType: FilterTypeEnum | undefined;
  setFilterType: Setter<FilterTypeEnum | undefined>;

  /**
   * filter occupancy
   */
  occupancyFilterTrendKey: string | undefined;
  setOccupancyFilterTrendKey: Setter<string | undefined>;
  minFilterValue: number;
  maxFilterValue: number;
  setMinFilterValue: Setter<number>;
  setMaxFilterValue: Setter<number>;

  /**
   * bar chart time bucket selections
   */
  selectedTimeBucketsIndexes: number[];
  setSelectedTimeBucketsIndexes: Setter<number[]>;
  toggleTimeBucketIndex: (index: Nullable<number>) => void;
  selectedTimeBucketsRangesIndexes: [number, number][];

  /**
   * line footer chart action
   */
  chartAction: ChartAction | null | undefined;
  setChartAction: Setter<ChartAction | null | undefined>;
};

export type {
  CustomChart,
  SubChart,
  AddSubChart,
  RemoveSubChart,
  RemoveSubCharts,
  ChartPanelContextValue,
  ChartPanelProviderProps,
  ChartAxisKey,
  SetChartTrends,
  GetChartAxisTrendKeyType,
  AddChartAxisTrendKey,
  RemoveChartAxisTrendKey,
  SetChartTrendsTrendParam,
  ChartType,
  SubChartType,
  SelectedAggregations,
  chartDetails,
};
