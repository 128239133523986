import React from "react";

const kneadleStartAngle = -253;
const kneadleEndAngle = 16.7;
const percentToKneadleAngleRatio = (kneadleEndAngle - kneadleStartAngle) / 100;

const Gauge = ({
  colors = ["#ff2503", "#ffc006", "#66fa32", "#ffc006", "#ff2503"],
  percent = 50,
}) => {
  const angle = kneadleStartAngle + percent * percentToKneadleAngleRatio;

  return (
    <svg viewBox="0 0 20 20">
      {/* ticks */}
      <path d="M4.92,15.73l-1.48,1.48c-.05,.05-.05,.14,0,.19,.05,.05,.14,.05,.19,0l1.48-1.48c.05-.05,.05-.14,0-.19-.05-.05-.14-.05-.19,0Z" />
      <path d="M4.48,15.23l-1.09,.91c-.06,.05-.06,.13-.02,.19,.05,.06,.13,.06,.19,.02l1.09-.91c.06-.05,.06-.13,.02-.19-.05-.06-.13-.06-.19-.02Z" />
      <path d="M4.09,14.69l-1.16,.82c-.06,.04-.08,.13-.03,.19,.04,.06,.13,.08,.19,.03l1.16-.82c.06-.04,.08-.13,.03-.19-.04-.06-.13-.08-.19-.03Z" />
      <path d="M3.74,14.13l-1.23,.71c-.06,.04-.09,.12-.05,.18,.04,.06,.12,.09,.18,.05l1.23-.71c.06-.04,.09-.12,.05-.18-.04-.06-.12-.09-.18-.05Z" />
      <path d="M3.44,13.53l-1.28,.6c-.07,.03-.1,.11-.06,.18,.03,.07,.11,.1,.18,.07l1.28-.6c.07-.03,.1-.11,.06-.18-.03-.07-.11-.1-.18-.07Z" />
      <path d="M3.2,12.91l-1.33,.49c-.07,.02-.11,.1-.08,.17,.02,.07,.1,.11,.17,.08l1.33-.49c.07-.02,.11-.1,.08-.17-.02-.07-.1-.11-.17-.08Z" />
      <path d="M3.01,12.28l-1.37,.37c-.07,.02-.11,.09-.1,.17,.02,.07,.09,.12,.17,.1l1.37-.37c.07-.02,.11-.09,.1-.17-.02-.07-.09-.12-.17-.1Z" />
      <path d="M2.88,11.63l-1.4,.25c-.07,.01-.12,.08-.11,.16,.01,.07,.08,.12,.16,.11l1.4-.25c.07-.01,.12-.08,.11-.16-.01-.07-.08-.12-.16-.11Z" />
      <path d="M2.81,10.97l-1.41,.12c-.07,0-.13,.07-.12,.15,0,.07,.07,.13,.15,.12l1.41-.12c.07,0,.13-.07,.12-.15,0-.07-.07-.13-.15-.12Z" />
      <path d="M2.78,10.3H.69c-.07,0-.13,.06-.13,.14s.06,.14,.13,.14H2.78c.07,0,.13-.06,.13-.14s-.06-.14-.13-.14Z" />
      <path d="M2.83,9.64l-1.41-.12c-.07,0-.14,.05-.15,.12,0,.07,.05,.14,.12,.15l1.41,.12c.07,0,.14-.05,.15-.12,0-.07-.05-.14-.12-.15Z" />
      <path d="M2.93,8.98l-1.4-.25c-.07-.01-.14,.04-.16,.11-.01,.07,.04,.14,.11,.16l1.4,.25c.07,.01,.14-.04,.16-.11,.01-.07-.04-.14-.11-.16Z" />
      <path d="M3.08,8.34l-1.37-.37c-.07-.02-.15,.02-.17,.1-.02,.07,.02,.15,.1,.17l1.37,.37c.07,.02,.15-.02,.17-.1,.02-.07-.02-.15-.1-.17Z" />
      <path d="M3.29,7.71l-1.33-.49c-.07-.02-.15,0-.17,.08-.02,.07,0,.15,.08,.17l1.33,.49c.07,.02,.15,0,.17-.08,.02-.07,0-.15-.08-.17Z" />
      <path d="M3.56,7.1l-1.28-.6c-.07-.03-.15,0-.18,.07-.03,.07,0,.15,.06,.18l1.28,.6c.07,.03,.15,0,.18-.07,.03-.07,0-.15-.06-.18Z" />
      <path d="M3.87,6.52l-1.23-.71c-.06-.04-.15-.01-.18,.05-.04,.06-.01,.15,.05,.18l1.23,.71c.06,.04,.15,.01,.18-.05,.04-.06,.01-.15-.05-.18Z" />
      <path d="M4.24,5.96l-1.16-.82c-.06-.04-.15-.03-.19,.03-.04,.06-.03,.15,.03,.19l1.16,.82c.06,.04,.15,.03,.19-.03,.04-.06,.03-.15-.03-.19Z" />
      <path d="M4.65,5.44l-1.09-.91c-.06-.05-.14-.04-.19,.02-.05,.06-.04,.14,.02,.19l1.09,.91c.06,.05,.14,.04,.19-.02,.05-.06,.04-.14-.02-.19Z" />
      <path d="M5.1,4.95l-1.48-1.48c-.05-.05-.14-.05-.19,0-.05,.05-.05,.14,0,.19l1.48,1.48c.05,.05,.14,.05,.19,0,.05-.05,.05-.14,0-.19Z" />
      <path d="M5.61,4.52l-.91-1.09c-.05-.06-.13-.06-.19-.02-.06,.05-.06,.13-.02,.19l.91,1.09c.05,.06,.13,.06,.19,.02,.06-.05,.06-.13,.02-.19Z" />
      <path d="M6.14,4.13l-.81-1.16c-.04-.06-.13-.08-.19-.03-.06,.04-.08,.13-.03,.19l.81,1.16c.04,.06,.13,.08,.19,.03,.06-.04,.08-.13,.03-.19Z" />
      <path d="M6.71,3.78l-.71-1.23c-.04-.06-.12-.09-.18-.05-.06,.04-.09,.12-.05,.18l.71,1.23c.04,.06,.12,.09,.18,.05,.06-.04,.09-.12,.05-.18Z" />
      <path d="M7.3,3.48l-.6-1.29c-.03-.07-.11-.1-.18-.07-.07,.03-.1,.11-.06,.18l.6,1.29c.03,.07,.11,.1,.18,.07,.07-.03,.1-.11,.06-.18Z" />
      <path d="M7.92,3.24l-.48-1.34c-.02-.07-.1-.11-.17-.08-.07,.02-.11,.1-.08,.17l.48,1.34c.02,.07,.1,.11,.17,.08,.07-.02,.11-.1,.08-.17Z" />
      <path d="M8.55,3.05l-.37-1.37c-.02-.07-.09-.12-.17-.1-.07,.02-.11,.09-.1,.17l.37,1.37c.02,.07,.09,.12,.17,.1,.07-.02,.11-.09,.1-.17Z" />
      <path d="M9.2,2.92l-.25-1.4c-.01-.07-.08-.12-.16-.11-.07,.01-.12,.08-.11,.16l.25,1.4c.01,.07,.08,.12,.16,.11,.07-.01,.12-.08,.11-.16Z" />
      <path d="M9.86,2.84l-.12-1.41c0-.07-.07-.13-.15-.12-.07,0-.13,.07-.12,.15l.12,1.41c0,.07,.07,.13,.15,.12,.07,0,.13-.07,.12-.15Z" />
      <path d="M10.39,2.96c-.07,0-.13-.06-.13-.14V.73c0-.07,.06-.14,.13-.14s.13,.06,.13,.14V2.82c0,.07-.06,.14-.13,.14Z" />
      <path d="M11.18,2.87l.12-1.41c0-.07-.05-.14-.12-.15-.07,0-.14,.05-.15,.12l-.12,1.41c0,.07,.05,.14,.12,.15,.07,0,.14-.05,.15-.12Z" />
      <path d="M11.84,2.97l.25-1.4c.01-.07-.04-.14-.11-.16-.07-.01-.14,.04-.16,.11l-.25,1.4c-.01,.07,.04,.14,.11,.16,.07,.01,.14-.04,.16-.11Z" />
      <path d="M12.48,3.12l.37-1.37c.02-.07-.02-.15-.1-.17-.07-.02-.15,.02-.17,.1l-.37,1.37c-.02,.07,.02,.15,.1,.17,.07,.02,.15-.02,.17-.1Z" />
      <path d="M13.11,3.33l.48-1.34c.02-.07,0-.15-.08-.17-.07-.02-.15,0-.17,.08l-.48,1.34c-.02,.07,0,.15,.08,.17,.07,.02,.15,0,.17-.08Z" />
      <path d="M13.72,3.6l.6-1.29c.03-.07,0-.15-.06-.18-.07-.03-.15,0-.18,.07l-.6,1.29c-.03,.07,0,.15,.06,.18,.07,.03,.15,0,.18-.07Z" />
      <path d="M14.3,3.91l.71-1.23c.04-.06,.01-.15-.05-.18-.06-.04-.15-.01-.18,.05l-.71,1.23c-.04,.06-.01,.15,.05,.18,.06,.04,.15,.01,.18-.05Z" />
      <path d="M14.86,4.28l.81-1.16c.04-.06,.03-.15-.03-.19-.06-.04-.15-.03-.19,.03l-.81,1.16c-.04,.06-.03,.15,.03,.19,.06,.04,.15,.03,.19-.03Z" />
      <path d="M15.38,4.7l.91-1.09c.05-.06,.04-.14-.02-.19-.06-.05-.14-.04-.19,.02l-.91,1.09c-.05,.06-.04,.14,.02,.19,.06,.05,.14,.04,.19-.02Z" />
      <path d="M15.86,5.15l1.48-1.48c.05-.05,.05-.14,0-.19-.05-.05-.14-.05-.19,0l-1.48,1.48c-.05,.05-.05,.14,0,.19,.05,.05,.14,.05,.19,0Z" />
      <path d="M16.3,5.65l1.09-.91c.06-.05,.06-.13,.02-.19-.05-.06-.13-.06-.19-.02l-1.09,.91c-.06,.05-.06,.13-.02,.19,.05,.06,.13,.06,.19,.02Z" />
      <path d="M16.69,6.18l1.16-.82c.06-.04,.08-.13,.03-.19-.04-.06-.13-.08-.19-.03l-1.16,.82c-.06,.04-.08,.13-.03,.19,.04,.06,.13,.08,.19,.03Z" />
      <path d="M17.04,6.75l1.23-.71c.06-.04,.09-.12,.05-.18-.04-.06-.12-.09-.18-.05l-1.23,.71c-.06,.04-.09,.12-.05,.18s.12,.09,.18,.05Z" />
      <path d="M17.33,7.34l1.28-.6c.07-.03,.1-.11,.06-.18-.03-.07-.11-.1-.18-.07l-1.28,.6c-.07,.03-.1,.11-.06,.18,.03,.07,.11,.1,.18,.07Z" />
      <path d="M17.57,7.96l1.33-.49c.07-.02,.11-.1,.08-.17-.02-.07-.1-.11-.17-.08l-1.33,.49c-.07,.02-.11,.1-.08,.17,.02,.07,.1,.11,.17,.08Z" />
      <path d="M17.76,8.6l1.37-.37c.07-.02,.11-.09,.1-.17-.02-.07-.09-.12-.17-.1l-1.37,.37c-.07,.02-.11,.09-.1,.17,.02,.07,.09,.12,.17,.1Z" />
      <path d="M17.89,9.25l1.4-.25c.07-.01,.12-.08,.11-.16-.01-.07-.08-.12-.16-.11l-1.4,.25c-.07,.01-.12,.08-.11,.16,.01,.07,.08,.12,.16,.11Z" />
      <path d="M17.97,9.91l1.41-.12c.07,0,.13-.07,.12-.15,0-.07-.07-.13-.15-.12l-1.41,.12c-.07,0-.13,.07-.12,.15,0,.07,.07,.13,.15,.12Z" />
      <path d="M17.99,10.57h2.09c.07,0,.13-.06,.13-.14s-.06-.14-.13-.14h-2.09c-.07,0-.13,.06-.13,.14s.06,.14,.13,.14Z" />
      <path d="M17.95,11.24l1.41,.12c.07,0,.14-.05,.15-.12,0-.07-.05-.14-.12-.15l-1.41-.12c-.07,0-.14,.05-.15,.12,0,.07,.05,.14,.12,.15Z" />
      <path d="M17.85,11.89l1.4,.25c.07,.01,.14-.04,.16-.11,.01-.07-.04-.14-.11-.16l-1.4-.25c-.07-.01-.14,.04-.16,.11-.01,.07,.04,.14,.11,.16Z" />
      <path d="M17.69,12.54l1.37,.37c.07,.02,.15-.02,.17-.1,.02-.07-.02-.15-.1-.17l-1.37-.37c-.07-.02-.15,.02-.17,.1-.02,.07,.02,.15,.1,.17Z" />
      <path d="M17.48,13.17l1.33,.49c.07,.02,.15,0,.17-.08,.02-.07,0-.15-.08-.17l-1.33-.49c-.07-.02-.15,0-.17,.08-.02,.07,0,.15,.08,.17Z" />
      <path d="M17.22,13.78l1.28,.6c.07,.03,.15,0,.18-.07,.03-.07,0-.15-.06-.18l-1.28-.6c-.07-.03-.15,0-.18,.07-.03,.07,0,.15,.06,.18Z" />
      <path d="M16.9,14.36l1.23,.71c.06,.04,.15,.01,.18-.05s.02-.15-.05-.18l-1.23-.71c-.06-.04-.15-.01-.18,.05-.04,.06-.01,.15,.05,.18Z" />
      <path d="M16.53,14.92l1.16,.82c.06,.04,.15,.03,.19-.03,.04-.06,.03-.15-.03-.19l-1.16-.82c-.06-.04-.15-.03-.19,.03-.04,.06-.03,.15,.03,.19Z" />
      <path d="M16.12,15.44l1.09,.91c.06,.05,.14,.04,.19-.02,.05-.06,.04-.14-.02-.19l-1.09-.91c-.06-.05-.14-.04-.19,.02-.05,.06-.04,.14,.02,.19Z" />
      <path d="M15.61,15.93l1.48,1.48c.05,.05,.14,.05,.19,0,.05-.05,.05-.14,0-.19l-1.48-1.48c-.05-.05-.14-.05-.19,0-.05,.05-.05,.14,0,.19Z" />

      {/* colors */}
      {colors.length < 5 ? (
        <>
          <path
            fill={colors[0]}
            d="M5.65,14.75c-.99-1.08-1.62-2.49-1.68-4.05,0-.09,0-.18,0-.26s0-.18,0-.26c.06-1.56,.69-2.97,1.68-4.05l-.19-.19-.18-.18c-1.09,1.17-1.77,2.72-1.84,4.42,0,.09,0,.18,0,.26s0,.18,0,.26c.06,1.7,.75,3.25,1.84,4.42,.12,.13,.24,.25,.37,.37l.18-.18,.19-.19c-.13-.12-.25-.24-.37-.37Z"
          />
          <path
            fill={colors[1]}
            d="M10.6,3.53c-.09,0-.18,0-.26,0s-.18,0-.26,0c-1.7,.06-3.25,.75-4.42,1.84l.18,.18,.19,.19c1.08-.99,2.49-1.62,4.05-1.68,.09,0,.18,0,.26,0s.18,0,.26,0c1.56,.06,2.97,.69,4.05,1.68l.19-.19,.18-.18c-1.17-1.09-2.72-1.77-4.42-1.84Z"
          />
          <path
            fill={colors[2]}
            d="M17.23,10.17c-.06-1.7-.75-3.25-1.84-4.42l-.18,.18-.19,.19c.99,1.08,1.62,2.49,1.68,4.05,0,.09,0,.18,0,.26s0,.18,0,.26c-.06,1.56-.69,2.97-1.68,4.05-.12,.13-.24,.25-.37,.37l.19,.19,.18,.18c.13-.12,.25-.24,.37-.37,1.09-1.17,1.77-2.72,1.84-4.42,0-.09,0-.18,0-.26s0-.18,0-.26Z"
          />
        </>
      ) : (
        <>
          <path
            fill={colors[0]}
            d="M5.79,14.82c-.65-.68-1.14-1.49-1.44-2.38-.3-.89-.39-1.84-.27-2.77l-.56-.09c-.04,.29-.06,.58-.06,.87,0,1.78,.68,3.49,1.92,4.77l.41-.41Z"
          />
          <path
            fill={colors[1]}
            d="M4.13,9.34c.22-1.27,.83-2.44,1.75-3.36,.43-.42,.91-.79,1.44-1.07l-.26-.51c-.91,.49-1.7,1.19-2.3,2.03-.6,.84-1.01,1.81-1.19,2.82l.57,.09Z"
          />
          <path
            fill={colors[2]}
            d="M7.61,4.76c.85-.41,1.79-.62,2.74-.62s1.88,.21,2.74,.62l.26-.51c-.93-.45-1.96-.69-3-.69s-2.06,.24-3,.69l.26,.51Z"
          />
          <path
            fill={colors[3]}
            d="M13.37,4.91c.83,.45,1.55,1.08,2.1,1.85,.55,.77,.92,1.65,1.09,2.58l.57-.09c-.18-1.02-.59-1.99-1.19-2.83-.6-.84-1.39-1.53-2.3-2.03l-.26,.51Z"
          />
          <path
            fill={colors[4]}
            d="M17.18,9.58l-.57,.09c.03,.26,.05,.52,.05,.78,0,1.63-.62,3.19-1.75,4.37l.41,.41c1.24-1.28,1.93-2.99,1.92-4.77,0-.29-.02-.58-.06-.87"
          />
        </>
      )}

      {/* kneedle */}
      <path
        style={{
          transformOrigin: "0.73px 0.78px",
          transformBox: "fill-box",
          transform: `translate(-0.1px, .7px) rotate(${angle}deg)`,
        }}
        d="M10.41,8.92c.08,0,.17,0,.27,.02,.11,.02,.21,.08,.31,.14,.11,.08,.22,.15,.32,.22,.1,.07,.2,.14,.3,.21,.09,.07,.19,.14,.29,.2,.14,.1,.29,.2,.43,.3,.1,.07,.2,.14,.3,.21,.1,.07,.19,.14,.29,.21,.14,.1,.28,.19,.42,.29,.12,.08,.24,.17,.35,.25,.13,.09,.27,.19,.4,.28,.09,.07,.19,.13,.28,.2,.07,.05,.15,.09,.22,.14,.06,.04,.12,.08,.18,.13l.21,.15c.09,.06,.17,.12,.26,.18,.09,.07,.19,.13,.29,.2,.02,.02,.05,.03,.07,.05,.03,.03,.01,.11-.03,.13-.02,0-.04,.01-.05,.01-.02,0-.04,0-.05-.01-.04-.02-.09-.04-.13-.05-.05-.02-.1-.04-.15-.06-.03-.01-.07-.03-.1-.04-.07-.03-.15-.05-.22-.08-.07-.03-.15-.06-.22-.09-.07-.03-.15-.05-.22-.08-.07-.03-.14-.06-.2-.09-.03-.01-.06-.02-.09-.04-.05-.02-.09-.03-.14-.05-.04-.02-.09-.04-.13-.05-.03-.01-.07-.03-.1-.04-.07-.03-.15-.05-.22-.08-.05-.02-.09-.04-.13-.06-.03-.01-.07-.03-.1-.04-.09-.03-.17-.07-.26-.1-.08-.03-.15-.05-.23-.08-.07-.03-.14-.06-.21-.08-.05-.02-.09-.03-.14-.05-.04-.01-.07-.03-.11-.04-.07-.03-.14-.06-.21-.08-.08-.03-.15-.05-.23-.08-.07-.03-.14-.05-.21-.08-.05-.02-.1-.03-.15-.05-.05-.02-.09-.04-.13-.05-.04-.01-.08-.03-.11-.04-.07-.02-.14-.05-.21-.07-.1-.04-.21-.08-.31-.12-.08-.03-.15-.05-.23-.08-.07-.03-.14-.06-.21-.08-.05-.02-.09-.03-.14-.05-.08-.03-.17-.07-.23-.13-.05-.05-.11-.1-.15-.16-.03-.05-.06-.11-.08-.16-.02-.04-.03-.09-.04-.13,0-.1-.02-.19,0-.29,.01-.08,.05-.15,.09-.23,.03-.08,.09-.15,.16-.21,.04-.03,.08-.06,.12-.09,.06-.04,.13-.07,.2-.1,.04-.02,.08-.02,.15-.04Zm-.29,.82c.01,.18,.19,.31,.37,.28,.04,0,.09-.02,.13-.04,.04-.02,.07-.05,.1-.08,.03-.03,.05-.07,.06-.12,.01-.04,.02-.09,0-.13s-.02-.08-.04-.12-.05-.07-.09-.1c-.04-.03-.08-.04-.12-.06-.04-.01-.09-.01-.13,0-.18,.02-.32,.19-.29,.37h0Z"
      />
    </svg>
  );
};

export default Gauge;
