import { pull } from "lodash";
import { SingleEvent } from "modules/core/helpers/events";

/**
 * @typedef {Object} LinkerInstance
 * @property {LinkFunction<CallbackFunctionArg, LinkInitalizerFunctionArg>} link
 * @property {GetFunction<CallbackFunctionArg>} get
 * @property {DestroyFunction} destroy
 * @property {DestroyAllFunction} destroyAll
 * @template {any} CallbackFunctionArg
 * @template {any} LinkInitalizerFunctionArg
 */

/**
 * @callback LinkerConstructor
 * @param {LinkerParams<CallbackFunctionArg, LinkInitalizerFunctionArg>} param0
 * @returns {LinkerInstance<CallbackFunctionArg, LinkInitalizerFunctionArg>}
 * @template {any} CallbackFunctionArg
 * @template {any} LinkInitalizerFunctionArg
 */

/**
 * @typedef {Object} LinkerParams
 * @property {LinkInitalizerFunction<CallbackFunctionArg, LinkInitalizerFunctionArg>} linkInitalizerFunction
 * @property {number} idleDuration defaults to 1000 <=> 1 second
 * @property {boolean} canUnlinkedLinkRemainTheActiveLinker if the activeLinker can remain if it was unlinked. defaults false
 * @template {any} CallbackFunctionArg
 * @template {any} LinkInitalizerFunctionArg
 */

/**
 * @callback LinkInitalizerFunction
 * @param {InitializerFunctionParams<CallbackFunctionArg, LinkInitalizerFunctionArg>}
 * @template {any} CallbackFunctionArg
 * @template {any} LinkInitalizerFunctionArg
 */

/**
 * @typedef {Object} InitializerFunctionParams
 * @property {string} linkKey
 * @property {TriggerFunction<CallbackFunctionArg>} triggerEvent
 * @property {IsDestroyedFunction} isDestroyed
 * @property {LinkInitalizerFunctionArg[]} initializationArgs
 * @property {object} linkUserData
 * @template {any} CallbackFunctionArg
 * @template {any} LinkInitalizerFunctionArg
 */

/**
 * @callback TriggerFunction
 * @param {...CallbackFunctionArg}
 * @template {any} CallbackFunctionArg
 */

/**
 * @callback UnlinkFunction
 * @returns {undefined}
 */

/**
 * @callback CallbackFunction
 * @param {...CallbackFunctionArg}
 * @template {any} CallbackFunctionArg
 */

/**
 * @callback LinkFunction
 * @param {string} linkKey
 * @param {CallbackFunction<CallbackFunctionArg>} callback
 * @param {...LinkInitalizerFunctionArg} initializationArgs
 * @returns {UnlinkFunction}
 * @template {any} CallbackFunctionArg
 * @template {any} LinkInitalizerFunctionArg
 */

/**
 * @callback GetFunction
 * @param {string} linkKey
 * @returns {CallbackFunctionArg[]}
 * @template {any} CallbackFunctionArg
 */

/**
 * @callback DestroyFunction
 * @param {string} linkKey
 */

/**
 * @callback DestroyAllFunction
 */

/**
 * @class
 * @param {LinkerParams<CallbackFunctionArg, LinkInitalizerFunctionArg>} param0
 * @returns {LinkerInstance<CallbackFunctionArg, LinkInitalizerFunctionArg>}
 * @template {any} CallbackFunctionArg
 * @template {any} LinkInitalizerFunctionArg
 */
function Linker({
  defaultLinkInitalizerFunction,
  idleDuration = 1000,
  canUnlinkedLinkRemainTheActiveLinker = Boolean(defaultLinkInitalizerFunction),
}) {
  if (this == null || this === window) {
    throw new Error(
      `Linker is a class constructor and thus must be called with "new Linker({...})"`
    );
  }

  const linker = this;

  const links = {};

  linker.link = (
    {
      key: linkKey,
      callback,
      linkInitalizerFunction = defaultLinkInitalizerFunction,
    },
    ...initializationArgs
  ) => {
    const link =
      links[linkKey] ??
      (links[linkKey] = {
        linkingFunctions: [],
        event: new SingleEvent(),
        userData: {},
        destroy: () => {
          link.deactivateActiveLinkingFunction &&
            link.deactivateActiveLinkingFunction();
          link.event.destroy();
          delete links[linkKey];
        },
        addLinkingFunction: (linkingFunction) => {
          if (link.unlinkTimer) {
            clearTimeout(link.unlinkTimer);
            delete link.unlinkTimer;
          }
          const linkingFunctions = link.linkingFunctions;
          const needsLinking =
            !link.activeLinkingFunction && linkingFunctions.length === 0;
          linkingFunctions.push(linkingFunction);
          if (needsLinking) {
            link.activeLinkingFunction = linkingFunction;
            linkingFunction();
          }
        },
        removeLinkingFunction: (linkingFunction) => {
          const linkingFunctions = link.linkingFunctions;
          pull(linkingFunctions, linkingFunction);
          if (linkingFunctions.length === 0) {
            link.unlinkTimer = setTimeout(() => {
              if (link.linkingFunctions.length === 0) {
                link.destroy();
              }
            }, idleDuration);
          }
          if (
            !canUnlinkedLinkRemainTheActiveLinker &&
            linkingFunction === link.activeLinkingFunction
          ) {
            link.deactivateActiveLinkingFunction();
            const nextLinkingFunction = link.linkingFunctions[0];
            delete link.deactivateActiveLinkingFunction;
            if (nextLinkingFunction) {
              link.activeLinkingFunction = nextLinkingFunction;
              nextLinkingFunction();
            } else {
              delete link.activeLinkingFunction;
            }
          }
        },
      });

    const currentLinkingFunction = () => {
      const event = link.event;
      const removeLinkingLastArgsListener = event.addListener((...args) => {
        link.lastArgs = args;
      });
      const cleanInitialization = linkInitalizerFunction({
        linkKey,
        triggerEvent: event.triggerEvent,
        isDestroyed: () => event.destroyed,
        initializationArgs,
        linkUserData: link.userData,
      });
      link.deactivateActiveLinkingFunction = () => {
        removeLinkingLastArgsListener();
        cleanInitialization && cleanInitialization();
      };
    };

    link.addLinkingFunction(currentLinkingFunction);
    if (link.lastArgs) {
      callback(...link.lastArgs);
    }
    const removeCallbackListener = link.event.addListener(callback);

    const unlink = () => {
      removeCallbackListener();
      link.removeLinkingFunction(currentLinkingFunction);
    };

    return unlink;
  };

  linker.getLastArgs = (linkKey) => links[linkKey].lastArgs;
  linker.getLastArgsAll = () =>
    Object.fromEntries(
      Object.entries(links).map(([linkKey, link]) => [linkKey, link.lastArgs])
    );

  linker.getCount = (linkKey) => links[linkKey].linkingFunctions.length;
  linker.getCountAll = () =>
    Object.fromEntries(
      Object.entries(links).map(([linkKey, link]) => [
        linkKey,
        link.linkingFunctions.length,
      ])
    );

  linker.destroy = (linkKey) => {
    links[linkKey].destroy();
  };
  linker.destroyAll = () => {
    Object.entries(links).map(([, link]) => link.destroy());
  };

  return linker;
}

export default Linker;
