import Dexie from "dexie";
import wait from "../modules/core/helpers/wait";

const db = new Dexie("telemetries-cache");
db.version(1).stores({
  cache: `key`,
});
db.version(2).upgrade(async () => {});
db.version(3).upgrade((trans) => {
  return trans.cache
    .toCollection()
    .delete()
    .then((res) => {
      if (res > 0) {
        localStorage.removeItem("buildings");
        localStorage.removeItem("AUTH_DATA");
        wait(100).then(() => window.location.reload());
      }
    });
});
db.version(4).upgrade((trans) => {
  return trans.cache
    .toCollection()
    .delete()
    .then((res) => {
      if (res > 0) {
        wait(100).then(() => window.location.reload());
      }
    });
});
export default db;
window["indexDBInstance"] = db;
