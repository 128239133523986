import isRealNumber from "helpers/isRealNumber";
import type {
  ComplianceScoreKey,
  ComplianceScoreValues,
} from "../api/complianceScores";
import type { Nullable } from "types/utils";

const defaultComplianceScoreValues: {
  [key in ComplianceScoreKey]: Nullable<number>;
} = {
  target: null,
  total: null,
  transportation: null,
  transportationPerformance: null,
  sustainableSites: null,
  rainwaterManagement: null,
  heatIslandReduction: null,
  lightPollutionReduction: null,
  siteManagement: null,
  waterEfficiency: null,
  waterPerformance: null,
  energyAndAtmosphere: null,
  energyAndAtmospherePractices: null,
  fundamentalRefrigerant: null,
  energyPerformance: null,
  ghgEmissionsScore: null,
  sourceEmissionsSource: null,
  enhancedRefrigerant: null,
  gridHarmonization: null,
  materialsAndResources: null,
  purchasingPolicy: null,
  facilityMainteanceAndRenovationPolicy: null,
  wastePerformance: null,
  purchasing: null,
  indoorEnvironmentalQuality: null,
  minimumIndoorAirQuality: null,
  environmentalTobaccoSmokeControl: null,
  greenCleaningPolicy: null,
  indoorEnvironmentalQualityPerformance: null,
  greenCleaning: null,
  integratedPestManagement: null,
  innovation: null,
};

const minimumComplianceScoreValues: Partial<{
  [key in ComplianceScoreKey]: number;
}> = {
  transportationPerformance: 6,
  rainwaterManagement: 0,
  heatIslandReduction: 0,
  lightPollutionReduction: 0,
  siteManagement: 0,
  waterPerformance: 6,
  energyAndAtmospherePractices: 1,
  fundamentalRefrigerant: 1,
  energyPerformance: 13,
  ghgEmissionsScore: 6.5,
  sourceEmissionsSource: 6.5,
  enhancedRefrigerant: 0,
  gridHarmonization: 0,
  purchasingPolicy: 1,
  facilityMainteanceAndRenovationPolicy: 1,
  wastePerformance: 0,
  purchasing: 0,
  minimumIndoorAirQuality: 1,
  environmentalTobaccoSmokeControl: 1,
  greenCleaningPolicy: 1,
  indoorEnvironmentalQualityPerformance: 8,
  greenCleaning: 0,
  integratedPestManagement: 0,
  innovation: 0,
};

const complianceCertificates = [
  {
    key: "uncertified",
    min: 0,
    max: 39,
    label: "label.UNCERTIFIED",
    color: ["var(--color-uncertified)"],
    isTheUncertified: true,
  },
  {
    key: "certified",
    min: 40,
    max: 49,
    label: "label.CERTIFIED",
    certificateLabel: "label.LEED_OM_CERTIFIED",
    color: ["var(--color-certified-v1)", "var(--color-certified-v2"],
  },
  {
    key: "silver",
    min: 50,
    max: 59,
    label: "label.SILVER",
    certificateLabel: "label.LEED_OM_SILVER",
    color: ["var(--color-silver-v1)", "var(--color-silver-v2"],
  },
  {
    key: "gold",
    min: 60,
    max: 79,
    label: "label.GOLD",
    certificateLabel: "label.LEED_OM_GOLD",
    color: ["var(--color-gold-v1)", "var(--color-gold-v2"],
  },
  {
    key: "platinum",
    min: 80,
    max: 100,
    label: "label.PLATINUM",
    certificateLabel: "label.LEED_OM_PLATINUM",
    color: ["var(--color-platinum-v1)", "var(--color-platinum-v2"],
  },
];

const complianceScoreTotalKeys: ComplianceScoreKey[] = [
  "transportationPerformance",
  "rainwaterManagement",
  "heatIslandReduction",
  "lightPollutionReduction",
  "siteManagement",
  "waterPerformance",
  "ghgEmissionsScore",
  "sourceEmissionsSource",
  "enhancedRefrigerant",
  "gridHarmonization",
  "wastePerformance",
  "purchasing",
  "indoorEnvironmentalQualityPerformance",
  "greenCleaning",
  "integratedPestManagement",
  "innovation",
];

const complianceScoreEnergyAndAtmosphereKeys: ComplianceScoreKey[] = [
  "ghgEmissionsScore",
  "sourceEmissionsSource",
  "gridHarmonization",
  "enhancedRefrigerant",
];

const getCertificate = ({ value }: { value: Nullable<number> }) => {
  if (!isRealNumber(value)) {
    return null;
  }

  return complianceCertificates.find(({ min, max }) => {
    return value >= min && value <= max;
  });
};

const checkIsCompliant = ({
  scoreValues,
}: {
  scoreValues: ComplianceScoreValues;
}) => {
  const foundCertificate = getCertificate({ value: scoreValues.total });
  const scoreKeysWithMinimumValues = Object.keys(
    minimumComplianceScoreValues
  ) as ComplianceScoreKey[];
  const nonCompliantFound = scoreKeysWithMinimumValues.find((scoreKey) => {
    const value = scoreValues[scoreKey];
    const minValue = minimumComplianceScoreValues[scoreKey];

    return isRealNumber(minValue) && (value || 0) < minValue;
  });

  return (
    foundCertificate && !foundCertificate.isTheUncertified && !nonCompliantFound
  );
};

const sumValues = ({
  values,
  fallbackValues,
  keys,
}: {
  values: Partial<ComplianceScoreValues>;
  fallbackValues: Partial<ComplianceScoreValues>;
  keys: ComplianceScoreKey[];
}) => {
  return (
    keys.reduce((acc, curr) => {
      return acc + (values[curr] ?? fallbackValues[curr] ?? 0);
    }, 0) || null
  );
};

const calculateComplianceScoresTotal = ({
  values,
  fallbackValues,
}: {
  values: Partial<ComplianceScoreValues>;
  fallbackValues: Partial<ComplianceScoreValues>;
}) => {
  return sumValues({ values, fallbackValues, keys: complianceScoreTotalKeys });
};

const calculateComplianceScoresEnergyAndAtmosphere = ({
  values,
  fallbackValues,
}: {
  values: Partial<ComplianceScoreValues>;
  fallbackValues: Partial<ComplianceScoreValues>;
}) => {
  return sumValues({
    values,
    fallbackValues,
    keys: complianceScoreEnergyAndAtmosphereKeys,
  });
};

export {
  defaultComplianceScoreValues,
  minimumComplianceScoreValues,
  complianceCertificates,
  complianceScoreTotalKeys,
  complianceScoreEnergyAndAtmosphereKeys,
  getCertificate,
  checkIsCompliant,
  calculateComplianceScoresTotal,
  calculateComplianceScoresEnergyAndAtmosphere,
};
