import isRealNumber from "helpers/isRealNumber";
import { useRefrigerantEmissions } from "../useRefrigerantEmissions";

const useEnhancedRefrigerantManagementFallbackValue = () => {
  const { refrigerantImpact, isLoading, error } = useRefrigerantEmissions();

  return {
    isLoading,
    error,
    fallbackValue: isRealNumber(refrigerantImpact)
      ? refrigerantImpact <= 13
        ? 1
        : 0
      : null,
  };
};

export { useEnhancedRefrigerantManagementFallbackValue };
