import classNames from "classnames";
import React from "react";

const stylesByDirection = {
  right: { transform: "rotate(180deg)" },
  up: { transform: "rotate(90deg)" },
  down: { transform: "rotate(-90deg)" },
};

const Arrow = ({
  color = "#7a7a7a",
  className = null,
  iconScale = 1,
  icon = true,
  direction = "left",
}) => {
  const stroke = color ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
        transformBox: "fill-box",
        ...stylesByDirection[direction],
      }}
      viewBox="0 0 21 21"
    >
      <path
        d="M9.48911 7L6.504 10M6.504 10L9.48911 13M6.504 10H14.4643M19.4395 10C19.4395 14.9706 15.43 19 10.4841 19C5.53825 19 1.52881 14.9706 1.52881 10C1.52881 5.02944 5.53825 1 10.4841 1C15.43 1 19.4395 5.02944 19.4395 10Z"
        stroke={stroke}
        fill="none"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Arrow;
