import { useTranslate } from "modules/language";

type PropsTypes = {
  label: string;
  scopes: string[];
};

const BreakdownTitle = ({ label, scopes }: PropsTypes) => {
  const t = useTranslate();

  return (
    <>
      <b>{label}</b>
      <span>
        {" "}
        <i>({scopes.map((s) => `${t("label.SCOPE")} ${s}`).join(", ")})</i>
      </span>
    </>
  );
};

export default BreakdownTitle;
