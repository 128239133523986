import { useTranslate } from "modules/language";
import classNames from "classnames";
import { fromMillis } from "helpers/dateTimeHelper";
import useBuildingData from "modules/building/hooks/useBuildingData";
import EditableParamHistoryContainerValue from "./EditableParamHistoryContainerValue/EditableParamHistoryContainerValue";
import type { Units } from "./types";
import { getUnits } from "./utils";

import s from "./EditableParamHistoryContainer.module.scss";

type EditableParamHistoryContainerProps<VALUE extends number | number[]> = {
  historyLogs: { actionTimestamp: number; value: VALUE }[];
  isEditEnabled: boolean;
  historyLabel: string;
  setValue: (value: VALUE) => void;
  units: Units | Units[];
  className?: string;
};

const EditableParamHistoryContainer = <VALUE extends number | number[]>({
  historyLogs,
  isEditEnabled,
  historyLabel,
  setValue,
  units,
  className,
}: EditableParamHistoryContainerProps<VALUE>) => {
  const t = useTranslate();
  const {
    buildingData: { timezone },
  } = useBuildingData();

  return (
    <section
      aria-label={historyLabel}
      className={classNames(s.historyContainer, className)}
    >
      {!historyLogs.length ? (
        t("message.NO_HISTORY_FOUND")
      ) : (
        <table
          className={classNames(s.historyTable, {
            [s.historyTableSelectable]: isEditEnabled,
          })}
        >
          <thead>
            <tr>
              <th>{t("label.DATE")}</th>
              <th>{t("label.TIME")}</th>
              <th>{t("label.VALUE")}</th>
            </tr>
          </thead>
          <tbody>
            {historyLogs.map(({ actionTimestamp, value }, index) => {
              const datetime = fromMillis(actionTimestamp, {
                zone: timezone,
              });

              return (
                <tr
                  key={index}
                  onClick={() => {
                    if (isEditEnabled) {
                      setValue(value);
                    }
                  }}
                >
                  <td>{datetime.toFormat("dd/MM/yyyy")}</td>
                  <td>{datetime.toFormat("hh:mm a")}</td>
                  <td>
                    {Array.isArray(value) ? (
                      value.map((v, index) => {
                        return (
                          <div key={index}>
                            <EditableParamHistoryContainerValue
                              value={v}
                              units={getUnits(units, index)}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <EditableParamHistoryContainerValue
                        value={value}
                        units={getUnits(units)}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </section>
  );
};

export default EditableParamHistoryContainer;
export type { EditableParamHistoryContainerProps };
