import Occupancy from "./Occupancy/Occupancy";
import Summary from "./Summary/Summary";
import BreakdownBySource from "./BreakdownBySource/BreakdownBySource";
import TotalEmissions from "./TotalEmissions/TotalEmissions";

const EmissionsPanel = () => {
  return (
    <>
      <Summary />
      <Occupancy />
      <TotalEmissions />
      <BreakdownBySource />
    </>
  );
};

export default EmissionsPanel;
