import { useComplianceScoreValues } from "modules/building/hooks/useComplianceScoreValues";
import EvaluationWizardModalBody from "../../../components/EvaluationWizardModal/EvaluationWizardModalBody/EvaluationWizardModalBody";
import {
  calculateComplianceScoresTotal,
  complianceScoresContent,
} from "../../../utils";
import useComplianceScoreValuesMutate from "modules/building/hooks/useComplianceScoreValuesMutate";
import isNotEmptyObj from "helpers/isNotEmptyObj";
import { useEvaluationWizardBuildingForm } from "../useEvaluationWizardBuildingForm";
import { useEffect } from "react";
import { useComplianceScoreFallbackValues } from "modules/building/hooks/useComplianceScoreFallbackValues";

const EvaluationWizardBuildingModalBody = () => {
  const {
    valuesWithoutFallbacks: complianceScoreValues,
    isEdit,
    isLoading: isDataLoading,
    isError: hasDataError,
    isFallbackLoading,
    isFallbackError,
  } = useComplianceScoreValues();
  const { values: complianceScoreFallbackValues } =
    useComplianceScoreFallbackValues({ fetchedValues: complianceScoreValues });

  const { mutateAsync } = useComplianceScoreValuesMutate();

  const formStore = useEvaluationWizardBuildingForm();

  useEffect(() => {
    if (!isDataLoading && !hasDataError) {
      formStore.setValues(complianceScoreValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoading, hasDataError, JSON.stringify(complianceScoreValues)]);

  const handleSubmit = async () => {
    if (isNotEmptyObj(formStore.values)) {
      await mutateAsync(formStore.values);
      return;
    }

    throw new Error(
      `empty object received: ${JSON.stringify(formStore.values)}`
    );
  };

  const total = calculateComplianceScoresTotal({
    values: formStore.values,
    fallbackValues: complianceScoreFallbackValues,
  });

  return (
    <EvaluationWizardModalBody
      complianceScoresContent={complianceScoresContent}
      isDataLoading={isDataLoading || isFallbackLoading}
      hasDataError={hasDataError || isFallbackError}
      formStore={formStore}
      handleSubmit={handleSubmit}
      total={total}
      fallbackValues={complianceScoreFallbackValues}
      isFirstTime={!isEdit}
    />
  );
};

export default EvaluationWizardBuildingModalBody;
