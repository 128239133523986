import classNames from "classnames";

type AutomaticInterventionProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  color?: string;
  strokeWidth?: number;
};

const AutomaticIntervention = ({
  className,
  iconScale = 1,
  icon = true,
  color = "#1F2937",
  strokeWidth = 0.814562,
}: AutomaticInterventionProps) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      width="23"
      height="29"
      viewBox="0 0 23 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
    >
      <path
        d="M8.77069 15.3198H7.47807L11.2167 5.13801H12.4894L16.2281 15.3198H14.9355L11.8928 6.7488H11.8133L8.77069 15.3198ZM9.24796 11.3426H14.4582V12.4363H9.24796V11.3426Z"
        fill={color}
      />
      <path
        d="M11.338 20.7863C16.5562 20.7863 20.7863 16.5562 20.7863 11.338C20.7863 6.11982 16.5562 1.88965 11.338 1.88965C6.11982 1.88965 1.88965 6.11982 1.88965 11.338C1.88965 16.5562 6.11982 20.7863 11.338 20.7863Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="11.2822"
        y="13.8726"
        width="11.338"
        height="15.1174"
        fill="white"
      />
      <path
        d="M16.0729 14.9615C16.0729 15.026 16.0781 15.093 16.0781 15.1575C16.0781 18.6805 16.0781 22.206 16.0807 25.729C16.0807 25.8708 16.0471 25.9714 15.9388 26.0694C15.4823 26.4872 15.0336 26.9128 14.5823 27.3357C14.5719 27.346 14.5565 27.3538 14.541 27.3615C14.5333 27.3538 14.5229 27.3512 14.5126 27.3563C14.5126 27.297 14.5075 27.2351 14.5075 27.1758C14.5075 23.6348 14.5075 20.0938 14.5049 16.5528C14.5049 16.429 14.5384 16.3413 14.6313 16.2562C15.0439 15.8796 15.4488 15.4928 15.8563 15.1111C15.9156 15.0543 15.9801 15.0053 16.042 14.9512C16.0523 14.9538 16.0626 14.9563 16.0729 14.9589V14.9615Z"
        fill={color}
      />
      <path
        d="M11.9173 27.9167C11.9173 27.829 11.9121 27.7413 11.9121 27.6537C11.9121 24.9869 11.9121 22.3176 11.9121 19.6483C11.9121 19.5942 11.9121 19.54 11.9121 19.4781C12.2629 19.148 12.6136 18.8179 12.9669 18.4903C13.1268 18.3407 13.2893 18.1963 13.4518 18.0493L13.4621 18.0571L13.475 18.0519C13.475 18.1112 13.4802 18.1731 13.4802 18.2324C13.4802 20.9275 13.4802 23.6252 13.4827 26.3203C13.4827 26.4441 13.4466 26.5292 13.3538 26.6143C12.8973 27.0321 12.4485 27.4602 11.9972 27.8832C11.9817 27.8987 11.9611 27.909 11.9456 27.9193C11.9379 27.909 11.9302 27.9064 11.9198 27.9116L11.9173 27.9167Z"
        fill={color}
      />
      <path
        d="M20.7832 18.4835H21.299C21.3016 18.5274 21.3068 18.5712 21.3068 18.6125C21.3068 20.6473 21.3068 22.6796 21.3068 24.7145C21.3068 24.8099 21.2784 24.877 21.2088 24.9414C20.7471 25.3696 20.288 25.8028 19.829 26.2335C19.8109 26.2516 19.7877 26.2671 19.749 26.2954V19.5358H20.2571C20.2648 19.3527 20.2597 19.1799 20.2597 19.0071L20.2545 19.0122C20.4273 19.0071 20.6001 19.0019 20.7858 18.9968V18.481L20.7806 18.4861L20.7832 18.4835Z"
        fill={color}
      />
      <path
        d="M18.6873 20.6753V20.8532C18.6873 22.3104 18.6873 23.7676 18.6873 25.2273C18.6873 25.3356 18.6563 25.4104 18.579 25.4826C18.1431 25.8849 17.715 26.2899 17.2817 26.6948C17.243 26.7309 17.2017 26.767 17.145 26.8186C17.1373 26.7721 17.127 26.7386 17.127 26.7051C17.127 25.2196 17.127 23.734 17.127 22.2485C17.127 22.1737 17.1424 22.117 17.2017 22.0628C17.6892 21.6115 18.1715 21.1576 18.6847 20.6753H18.6873Z"
        fill={color}
      />
      <path
        d="M20.7835 16.3734V15.8628H21.2941V16.3683H20.7783L20.7835 16.3734Z"
        fill={color}
      />
      <path
        d="M20.7796 16.3677C20.7796 16.5456 20.7847 16.721 20.7873 16.899L20.7925 16.8938H20.2715V16.3831C20.4391 16.3806 20.6119 16.378 20.7847 16.3728L20.7796 16.3677Z"
        fill={color}
      />
      <path
        d="M20.7871 16.9002H21.2978V17.4134H20.7923C20.7923 17.2406 20.7923 17.0678 20.7923 16.895L20.7871 16.9002Z"
        fill={color}
      />
      <path
        d="M20.2494 17.9612H19.749V17.4351H20.2571C20.2571 17.6104 20.27 17.7858 20.2468 17.9638L20.2519 17.9586L20.2494 17.9612Z"
        fill={color}
      />
      <path
        d="M20.248 17.9661H20.7845C20.7845 18.1363 20.7845 18.3091 20.7845 18.4845L20.7896 18.4793C20.6117 18.4793 20.4363 18.4793 20.2584 18.4793L20.2635 18.4845C20.2609 18.3091 20.2584 18.1363 20.2532 17.9609L20.248 17.9661Z"
        fill={color}
      />
      <path
        d="M20.2623 19.0071C20.0946 19.0019 19.927 18.9942 19.749 18.989V18.4861C19.9192 18.4861 20.0895 18.4861 20.2623 18.4861L20.2571 18.481C20.2571 18.6589 20.2571 18.8343 20.2571 19.0122L20.2623 19.0071Z"
        fill={color}
      />
      <path
        d="M20.7871 15.2873V14.8179H21.2952V15.3156C21.1379 15.3363 20.978 15.3259 20.8155 15.3234C20.8052 15.3105 20.7948 15.3002 20.7871 15.2873Z"
        fill={color}
      />
    </svg>
  );
};

export default AutomaticIntervention;
