import { useTranslate } from "modules/language";
import EditableParamContainer from "../EditableParamContainer/EditableParamContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import EditableParamValue from "../../../../components/EditableParamValue/EditableParamValue";
import { type ReactNode, useEffect, useId, useMemo, useState } from "react";
import EditableParamHistoryContainer from "../EditableParamHistoryContainer/EditableParamHistoryContainer";
import useEditableParamHistory from "modules/building/hooks/useEditableParamHistory";
import EditableParamHistoryButton from "../EditableParamHistoryButton/EditableParamHistoryButton";
import { useEditableParamsState } from "../../../providers/EditableParamsProvider/EditableParamsProvider";
import { groupBy } from "lodash";
import isRealNumber from "helpers/isRealNumber";

import s from "./TransportDistributionEditable.module.scss";

type TransportDistributionEditableProps = {
  isEditEnabled: boolean;
  isHistoryEnabled: boolean;
  isHistoryExpanded?: boolean;
  disabled: boolean;
  fieldsetError: ReactNode;
  setFieldsetError: (error: ReactNode) => void;
};

const TransportDistributionEditable = ({
  isEditEnabled,
  isHistoryEnabled,
  isHistoryExpanded: isHistoryExpandedFromProps,
  disabled,
  fieldsetError,
  setFieldsetError,
}: TransportDistributionEditableProps) => {
  const t = useTranslate();
  const labelElementId = useId();
  const errorElementId = `${labelElementId}-global-error`;

  const {
    state: {
      carsTransportDistribution,
      busesTransportDistribution,
      carpoolingTransportDistribution,
    },
    telemetries,
    loading,
  } = useEditableParamsState();

  const carsHistoryLogsResp = useEditableParamHistory(
    {
      timeseriesId: telemetries.carsTransportDistribution.timeseriesId,
    },
    { enabled: isHistoryEnabled }
  );
  const busesHistoryLogsResp = useEditableParamHistory(
    {
      timeseriesId: telemetries.busesTransportDistribution.timeseriesId,
    },
    { enabled: isHistoryEnabled }
  );
  const carpoolingHistoryLogsResp = useEditableParamHistory(
    {
      timeseriesId: telemetries.carpoolingTransportDistribution.timeseriesId,
    },
    { enabled: isHistoryEnabled }
  );

  const historyIsLoading =
    carsHistoryLogsResp.isLoading ||
    busesHistoryLogsResp.isLoading ||
    carpoolingHistoryLogsResp.isLoading;

  const historyIsError =
    carsHistoryLogsResp.isError ||
    busesHistoryLogsResp.isError ||
    carpoolingHistoryLogsResp.isError;

  const historyLogs = useMemo(() => {
    const carsGroupByTimeStamp = groupBy(
      carsHistoryLogsResp.data.items,
      "actionTimestamp"
    );
    const busesGroupByTimeStamp = groupBy(
      busesHistoryLogsResp.data.items,
      "actionTimestamp"
    );
    const carpoolingGroupByTimeStamp = groupBy(
      carpoolingHistoryLogsResp.data.items,
      "actionTimestamp"
    );

    const timestamps = Array.from(
      new Set([
        ...Object.keys(carsGroupByTimeStamp),
        ...Object.keys(busesGroupByTimeStamp),
        ...Object.keys(carpoolingGroupByTimeStamp),
      ])
    );

    timestamps.sort((a, b) => +b - +a);

    let carslastLogged = 0;
    let buseslastLogged = 0;
    let carpoolinglastLogged = 0;

    return timestamps.map((timestamp) => {
      let cars = carsGroupByTimeStamp[timestamp]?.[0]?.value;
      let buses = busesGroupByTimeStamp[timestamp]?.[0]?.value;
      let carpooling = carpoolingGroupByTimeStamp[timestamp]?.[0]?.value;

      if (!isRealNumber(cars)) {
        cars = carslastLogged;
      } else {
        carslastLogged = cars;
      }

      if (!isRealNumber(buses)) {
        buses = buseslastLogged;
      } else {
        buseslastLogged = buses;
      }

      if (!isRealNumber(carpooling)) {
        carpooling = carpoolinglastLogged;
      } else {
        carpoolinglastLogged = carpooling;
      }

      return {
        actionTimestamp: +timestamp,
        value: [cars, buses, carpooling],
      };
    });
  }, [
    busesHistoryLogsResp.data.items,
    carpoolingHistoryLogsResp.data.items,
    carsHistoryLogsResp.data.items,
  ]);

  const [isHistoryExpanded, setIsHistoryExpanded] = useState(
    isHistoryExpandedFromProps
  );

  useEffect(() => {
    setIsHistoryExpanded(isHistoryExpandedFromProps);
  }, [isHistoryExpandedFromProps, isHistoryEnabled]);

  useEffect(() => {
    setFieldsetError(
      (carsTransportDistribution.value || 0) +
        (busesTransportDistribution.value || 0) +
        (carpoolingTransportDistribution.value || 0) !==
        100
        ? t("error.SUM_OF_FIELDS_SHOULD_BE_[SUM_VALUE]", {
            sumValue: 100,
          })
        : ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    busesTransportDistribution.value,
    carpoolingTransportDistribution.value,
    carsTransportDistribution.value,
    t,
  ]);

  return (
    <>
      <EditableParamContainer
        isEditEnabled={isEditEnabled}
        onRevert={() => {
          carsTransportDistribution.resetValue();
          busesTransportDistribution.resetValue();
          carpoolingTransportDistribution.resetValue();
        }}
      >
        <KeyValue
          keyText={
            <>
              {t("label.MODE_OF_TRANSPORT_DISTRIBUTION")}
              <span className={s.error} id={errorElementId}>
                {fieldsetError}
              </span>
            </>
          }
          keyTag="label"
          keyProps={{
            id: labelElementId,
          }}
          isLight
          value={
            <fieldset aria-errormessage={errorElementId}>
              <div>
                <EditableParamValue
                  disabled={disabled}
                  labelElementId={labelElementId}
                  isEditEnabled={isEditEnabled}
                  value={carsTransportDistribution.value}
                  setValue={(value) =>
                    carsTransportDistribution.setValue(value)
                  }
                  error={carsTransportDistribution.error}
                  setError={(error) =>
                    carsTransportDistribution.setError(error)
                  }
                  maxValue={100}
                  minValue={0}
                  units={[{ unit: telemetries.carsTransportDistribution.unit }]}
                  isLoading={loading}
                />{" "}
                {t("label.CARS")}
              </div>
              <div>
                <EditableParamValue
                  disabled={disabled}
                  labelElementId={labelElementId}
                  isEditEnabled={isEditEnabled}
                  value={busesTransportDistribution.value}
                  setValue={(value) =>
                    busesTransportDistribution.setValue(value)
                  }
                  error={busesTransportDistribution.error}
                  setError={(error) =>
                    busesTransportDistribution.setError(error)
                  }
                  maxValue={100}
                  minValue={0}
                  units={[
                    { unit: telemetries.busesTransportDistribution.unit },
                  ]}
                  isLoading={loading}
                />{" "}
                {t("label.BUSES")}
              </div>
              <div>
                <EditableParamValue
                  disabled={disabled}
                  labelElementId={labelElementId}
                  isEditEnabled={isEditEnabled}
                  value={carpoolingTransportDistribution.value}
                  setValue={(value) =>
                    carpoolingTransportDistribution.setValue(value)
                  }
                  error={carpoolingTransportDistribution.error}
                  setError={(error) =>
                    carpoolingTransportDistribution.setError(error)
                  }
                  maxValue={100}
                  minValue={0}
                  units={[
                    { unit: telemetries.carpoolingTransportDistribution.unit },
                  ]}
                  isLoading={loading}
                />{" "}
                {t("label.CARPOOLING")}
              </div>
            </fieldset>
          }
        />
        <EditableParamHistoryButton
          isEnabled={isHistoryEnabled}
          isLoading={historyIsLoading}
          hasError={historyIsError}
          isExpanded={!!isHistoryExpanded}
          setIsExpanded={setIsHistoryExpanded}
        />
      </EditableParamContainer>
      {isHistoryEnabled &&
      isHistoryExpanded &&
      !historyIsLoading &&
      !historyIsError ? (
        <EditableParamHistoryContainer
          historyLogs={historyLogs}
          isEditEnabled={isEditEnabled}
          historyLabel={t("label.MODE_OF_TRANSPORT_DISTRIBUTION_HISTORY")}
          setValue={(value) => {
            carsTransportDistribution.setValue(value[0]);
            busesTransportDistribution.setValue(value[1]);
            carpoolingTransportDistribution.setValue(value[2]);
          }}
          units={[
            [
              {
                unit: {
                  id: "percent-cars",
                  name: "percent-cars",
                  display: `% ${t("label.CARS")}`,
                  addSpace: false,
                  isPrefix: false,
                },
              },
            ],
            [
              {
                unit: {
                  id: "percent-buses",
                  name: "percent-buses",
                  display: `% ${t("label.BUSES")}`,
                  addSpace: false,
                  isPrefix: false,
                },
              },
            ],
            [
              {
                unit: {
                  id: "percent-car-pool",
                  name: "percent-car-pool",
                  display: `% ${t("label.CARPOOLING")}`,
                  addSpace: false,
                  isPrefix: false,
                },
              },
            ],
          ]}
        />
      ) : null}
    </>
  );
};

export default TransportDistributionEditable;
