import withIconProps from "helpers/withIconProps";

const CalendarEmptyIcon = withIconProps((props) => {
  return (
    <svg
      {...props}
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4848 1V5M6.24234 1V5M1.09082 9H19.6363M3.15143 3H17.5757C18.7137 3 19.6363 3.89543 19.6363 5V19C19.6363 20.1046 18.7137 21 17.5757 21H3.15143C2.01339 21 1.09082 20.1046 1.09082 19V5C1.09082 3.89543 2.01339 3 3.15143 3Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default CalendarEmptyIcon;
