import { createContext, useContext, useEffect, useMemo } from "react";
import type {
  EditableParamsContextValue,
  EditableParamsProviderProps,
} from "./types";
import useBuildingData from "modules/building/hooks/useBuildingData";
import {
  type LinkedTelemetry,
  useMemoedCurrentLinkedData,
} from "modules/building/hooks/useLinkedData";
import { useStore } from "zustand";
import { type ParamName, definitionsArray } from "./definitions";
import { editableParamsStore } from "./editableParamsStore";

const EditableParamsContext = createContext<EditableParamsContextValue>(
  {} as EditableParamsContextValue
);

const EditableParamsProvider = ({ children }: EditableParamsProviderProps) => {
  const {
    buildingData: {
      building: { id: buildingId },
    },
  } = useBuildingData();

  const { resolutions, Notifier, loading, error } = useMemoedCurrentLinkedData(
    () => [
      {
        entityId: buildingId,
        resolutionKey: "1month",
        telemetries: definitionsArray.map(
          ({ telemetryRequest }) => telemetryRequest
        ),
      },
    ],
    [buildingId]
  );

  const store = useStore(editableParamsStore);
  const telemetries = resolutions["1month"];

  /**
   * set initial values
   */
  useEffect(() => {
    if (!loading) {
      for (const { name, telemetryName, fallbackValue } of definitionsArray) {
        const initialValue =
          telemetries[buildingId][telemetryName].data.at(-1)?.value ??
          fallbackValue;

        store[name].setInitialValue(initialValue);
        store[name].setValue(initialValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingId, loading]);

  /**
   * override telemetries values by ones from store if set
   * and if telemetry values are not set fallback to the fallbackValue
   */
  const paramsTelemetries = useMemo(() => {
    return Object.fromEntries(
      definitionsArray.map(({ name, telemetryName, fallbackValue }) => {
        const telemetry = telemetries[buildingId][telemetryName];

        return [
          name,
          {
            ...telemetry,
            data: telemetry.data.map((datum) => {
              return {
                ...datum,
                value: store[name].value ?? datum.value ?? fallbackValue,
              };
            }),
          },
        ];
      })
    ) as { [paramName in ParamName]: LinkedTelemetry };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingId, loading]);

  const value = useMemo(() => {
    return {
      telemetries: paramsTelemetries,
      Notifier,
      loading,
      error,
    };
  }, [paramsTelemetries, Notifier, loading, error]);

  return (
    <EditableParamsContext.Provider value={value}>
      {children}
    </EditableParamsContext.Provider>
  );
};

const useEditableParamsState = () => {
  const { Notifier, loading, error, telemetries } = useContext(
    EditableParamsContext
  );

  return {
    state: useStore(editableParamsStore),
    /**
     * telemetries object keys are the **paramNames** and **NOT** the telemetry names
     */
    telemetries,
    Notifier,
    loading,
    error,
  };
};

export default EditableParamsProvider;
export { useEditableParamsState };
