import React from "react";
import classNames from "classnames";

const LineChart = ({
  color = "#ADADAD",
  color2 = "#00B3EC",
  className = undefined,
  iconScale = 1,
  icon = true,
}) => {
  color = color ?? "currentcolor";
  color2 = color2 ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 18 16"
    >
      <path
        d="M17.33 14.88H1V0"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M3.17993 13.79V14.88"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M5.35999 13.79V14.88"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M7.54004 13.79V14.88"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M9.70996 13.79V14.88"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M11.89 13.79V14.88"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M14.0701 13.79V14.88"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M16.25 13.79V14.88"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M1 12.7H2.09"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M1 10.52H2.09"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M1 8.34998H2.09"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M1 6.16992H2.09"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M1 3.98999H2.09"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M1 1.81006H2.09"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M16.9701 5.08002H14.7901L13.7001 3.99002H12.9801L11.5301 5.80002H10.0801L8.62006 3.26001H7.17007L6.45007 4.35001H4.99008L3.54007 6.17001H2.82007"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M2.82007 11.25H4.63007L6.08008 9.42999H8.26007L9.35007 10.89H11.5301L14.4301 7.97998H16.9701"
        fill="none"
        stroke={color2}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default LineChart;
