import classNames from "classnames";

type RemoveIconProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  color?: string;
  strokeWidth?: number;
};

const RemoveIcon = ({
  className,
  iconScale = 1,
  icon = true,
  color = "#484848",
  strokeWidth = 2,
}: RemoveIconProps) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
    >
      <path
        d="M1.5 4.99999H16.5M14.8333 4.99999V16.6667C14.8333 17.5 14 18.3333 13.1667 18.3333H4.83333C4 18.3333 3.16667 17.5 3.16667 16.6667V4.99999M5.66667 4.99999V3.33332C5.66667 2.49999 6.5 1.66666 7.33333 1.66666H10.6667C11.5 1.66666 12.3333 2.49999 12.3333 3.33332V4.99999M7.33333 9.16666V14.1667M10.6667 9.16666V14.1667"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RemoveIcon;
