import withIconProps from "helpers/withIconProps";

const BarGraphIcon = withIconProps((props) => {
  return (
    <svg
      {...props}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.09091 1V19H19.6364M6.24242 8H8.30303C8.87205 8 9.33333 8.44772 9.33333 9V14C9.33333 14.5523 8.87205 15 8.30303 15H6.24242C5.6734 15 5.21212 14.5523 5.21212 14V9C5.21212 8.44772 5.6734 8 6.24242 8ZM14.4848 3H16.5455C17.1145 3 17.5758 3.44772 17.5758 4V14C17.5758 14.5523 17.1145 15 16.5455 15H14.4848C13.9158 15 13.4545 14.5523 13.4545 14V4C13.4545 3.44772 13.9158 3 14.4848 3Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default BarGraphIcon;
