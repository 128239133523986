import { type ReactNode, useId, useEffect, useCallback } from "react";
import KeyValue from "atomic-components/molecules/KeyValue";
import { useTranslate } from "modules/language";
import { ExternalLink } from "svg-icons";
import RadioInput from "atomic-components/atoms/RadioInput";
import type { Nullable } from "types/utils";
import classNames from "classnames";
import EvaluationWizardInputError from "../EvaluationWizardInputError/EvaluationWizardInputError";

import s from "./EvaluationWizardRadioInput.module.scss";

type EvaluationWizardRadioInputProps = {
  title: ReactNode;
  url?: string;
  name: string;
  disabled?: boolean;
  value: Nullable<number>;
  setValue: (value: Nullable<number>) => void;
  error: ReactNode;
  setError: (error: ReactNode) => void;
  className?: string;
  required?: boolean;
};

const EvaluationWizardRadioInput = ({
  title,
  url,
  name,
  disabled,
  value,
  setValue,
  className,
  error,
  setError,
  required = true,
}: EvaluationWizardRadioInputProps) => {
  const inputElementId = useId();
  const errorElementId = `${inputElementId}-error`;
  const t = useTranslate();

  const validate = useCallback(
    (newValue?: string) => {
      if (!newValue && required) {
        setError(t("error.REQUIRED"));
        return;
      }

      setError("");
    },
    [required, t]
  );

  useEffect(() => {
    validate(value?.toString());

    return () => {
      setError("");
    };
  }, [validate, value]);

  return (
    <div>
      <KeyValue
        className={classNames(s.keyValue, className)}
        isVertical
        gap="medium"
        keyText={
          <>
            {title}{" "}
            {url ? (
              <a href={url} target="_blank" rel="noreferrer">
                <ExternalLink />
              </a>
            ) : null}
          </>
        }
        keyTag="label"
        keyProps={{
          htmlFor: inputElementId,
        }}
        keyClassName={s.key}
        valueClassName={s.value}
        isLight
        value={
          <>
            {t("message.LEED_REQUIREMENTS_MET")}
            <fieldset
              className={s.fieldset}
              id={inputElementId}
              aria-errormessage={errorElementId}
              aria-invalid={!!error}
            >
              <RadioInput
                name={name}
                required={required}
                value={1}
                disabled={disabled}
                checked={value === 1}
                onChange={(e) => {
                  const newValue = e.target.value;
                  validate(newValue);
                  setValue(+newValue);
                }}
              >
                {t("label.YES")}
              </RadioInput>
              <RadioInput
                name={name}
                required={required}
                value={0}
                disabled={disabled}
                checked={value === 0}
                onChange={(e) => {
                  const newValue = e.target.value;
                  validate(newValue);
                  setValue(+newValue);
                }}
              >
                {t("label.NO")}
              </RadioInput>
            </fieldset>
          </>
        }
      />
      {error ? (
        <EvaluationWizardInputError id={errorElementId}>
          {error}
        </EvaluationWizardInputError>
      ) : (
        ""
      )}
    </div>
  );
};

export default EvaluationWizardRadioInput;
export type { EvaluationWizardRadioInputProps };
