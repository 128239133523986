import classNames from "classnames";
import React from "react";

const AnalogClock = ({
  color = "#727070",
  className,
  iconScale = 1,
  icon = true,
}) => {
  const fill = color ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 14 14"
    >
      <path
        d="M6.61005 13.22C2.96005 13.22 0 10.25 0 6.60999C0 2.96999 2.97005 0 6.61005 0C10.25 0 13.22 2.96999 13.22 6.60999C13.22 10.25 10.25 13.22 6.61005 13.22ZM6.61005 0.519989C3.26005 0.519989 0.530029 3.25001 0.530029 6.60001C0.530029 9.95001 3.26005 12.68 6.61005 12.68C9.96005 12.68 12.69 9.95001 12.69 6.60001C12.69 3.25001 9.96005 0.519989 6.61005 0.519989Z"
        fill={fill}
      />
      <path d="M6.84015 0.80957H6.39014V2.07956H6.84015V0.80957Z" fill={fill} />
      <path
        d="M2.08008 6.37988H0.810059V6.82986H2.08008V6.37988Z"
        fill={fill}
      />
      <path d="M12.4199 6.37988H11.1499V6.82986H12.4199V6.37988Z" fill={fill} />
      <path d="M6.84015 11.1699H6.39014V12.4399H6.84015V11.1699Z" fill={fill} />
      <path
        d="M9.51992 4.49971C9.65992 4.35971 9.65992 4.13971 9.51992 3.99971C9.37992 3.85971 9.15992 3.85971 9.01992 3.99971L6.8799 6.13973C6.7999 6.10973 6.70989 6.08971 6.61989 6.08971C6.51989 6.08971 6.41991 6.10971 6.32991 6.14971L3.3799 3.19973C3.2399 3.05973 3.0199 3.05973 2.8799 3.19973C2.7399 3.33973 2.7399 3.55973 2.8799 3.69973L5.86989 6.68972C5.85989 6.73972 5.84993 6.7997 5.84993 6.8597C5.84993 7.2897 6.18989 7.62972 6.61989 7.62972C7.04989 7.62972 7.38991 7.2897 7.38991 6.8597C7.38991 6.7897 7.37994 6.71971 7.35994 6.64971C7.35994 6.64971 7.37991 6.63972 7.38991 6.62972L9.52993 4.4897L9.51992 4.49971Z"
        fill={fill}
      />
    </svg>
  );
};

export default AnalogClock;
