import withIconProps from "helpers/withIconProps";

const CloudSunRainIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M11.9996 2V4M4.92969 4.92999L6.33969 6.33999M19.9996 12H21.9996M19.0697 4.92999L17.6597 6.33999M15.9466 12.65C16.0697 11.9045 15.9791 11.1394 15.6853 10.4432C15.3915 9.74711 14.9066 9.14841 14.2866 8.71648C13.6667 8.28455 12.937 8.03705 12.1822 8.00263C11.4274 7.96821 10.6783 8.14828 10.0216 8.522M2.99957 20C2.55011 19.4001 2.24127 18.7069 2.09591 17.9715C1.95054 17.2362 1.97236 16.4776 2.15976 15.7518C2.34716 15.0261 2.69533 14.3517 3.17853 13.7787C3.66174 13.2056 4.26759 12.7486 4.95128 12.4413C5.63498 12.134 6.37901 11.9844 7.12833 12.0035C7.87766 12.0226 8.61311 12.2099 9.28026 12.5516C9.94742 12.8933 10.5292 13.3806 10.9826 13.9775C11.436 14.5744 11.7494 15.2656 11.8996 16H12.9996C13.608 15.9991 14.2024 16.1832 14.7037 16.528C15.2051 16.8727 15.5898 17.3618 15.8068 17.9302C16.0237 18.4987 16.0627 19.1197 15.9186 19.7108C15.7744 20.3019 15.4539 20.8352 14.9996 21.24M10.9996 20V22M6.99963 19V21"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default CloudSunRainIcon;
