import React from "react";
import "./ErrorMessage.css";
import { useTranslate } from "../../../modules/language";
import { getMessageKey } from "../../../modules/core/helpers/error.helper";

const ErrorMessage = ({
  error,
  className = "",
  errorByStatus = {},
  defaultMessage = "OOPS",
}) => {
  const t = useTranslate();

  return (
    <div className={"error-message" + (className ? " " + className : "")}>
      {t(getMessageKey(error, errorByStatus, defaultMessage))}
    </div>
  );
};

export default ErrorMessage;
