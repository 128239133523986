import { useEffect, Fragment, useCallback, type ReactNode } from "react";
import FormattedValue from "atomic-components/atoms/FormattedValue";
import type { Nullable } from "types/utils";
import { useFloating, autoUpdate, offset } from "@floating-ui/react-dom";
import isRealNumber from "helpers/isRealNumber";
import classNames from "classnames";
import { useTranslate } from "modules/language";
import type { Currency, Unit } from "types/unit";
import Displayer from "atomic-components/molecules/Displayer/Displayer";

import s from "./EditableParamValue.module.scss";

type EditableParamValueProps = {
  labelElementId: string;
  isEditEnabled: boolean;
  value: Nullable<number>;
  placeholderValue?: Nullable<number>;
  setValue: (value: Nullable<number>) => void;
  error: ReactNode;
  setError: (error: ReactNode) => void;
  units: {
    unit: Unit | Currency;
    separator?: string;
  }[];
  maxValue?: number;
  minValue?: number;
  step?: number;
  disabled?: boolean;
  required?: boolean;
  "aria-errormessage"?: string;
  isLoading?: boolean;
};

const EditableParamValue = ({
  labelElementId,
  isEditEnabled,
  value,
  placeholderValue,
  setValue,
  error,
  setError,
  units,
  maxValue,
  minValue,
  step = 0.001,
  disabled,
  required = true,
  isLoading,
  "aria-errormessage": ariaErrormessage,
}: EditableParamValueProps) => {
  const displayError = !ariaErrormessage;
  const errorElementId = ariaErrormessage || `${labelElementId}-error`;
  const t = useTranslate();
  const { refs, floatingStyles } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    middleware: [offset(2)],
    placement: "bottom-end",
  });

  const validate = useCallback(
    (newValue?: string) => {
      if (!newValue && required) {
        setError(t("error.REQUIRED"));
        return;
      }

      if (newValue && isRealNumber(maxValue) && +newValue > maxValue) {
        setError(t("error.MAX_ALLOWED_VALUE_IS_[MAX_VALUE]", { maxValue }));
        return;
      }

      if (newValue && isRealNumber(minValue) && +newValue < minValue) {
        setError(t("error.MIN_ALLOWED_VALUE_IS_[MIN_VALUE]", { minValue }));
        return;
      }

      const decimals = newValue?.split(".")[1];

      if (decimals && decimals.length > 3) {
        setError(t("error.MAX_ALLOWED_3_DECIMAL_PLACES"));
        return;
      }

      setError("");
    },
    [maxValue, minValue, required, t]
  );

  useEffect(() => {
    validate(value?.toString());

    return () => {
      setError("");
    };
  }, [validate, value]);

  return (
    <>
      {isEditEnabled ? (
        <input
          onKeyDown={(e) => {
            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
              e.preventDefault();
            }
          }}
          onWheel={(e) => {
            (e.target as HTMLInputElement).blur();
          }}
          disabled={disabled || isLoading}
          ref={refs.setReference}
          className={classNames(s.input, { [s.inputError]: !!error })}
          value={value ?? ""}
          type="number"
          required={required}
          max={maxValue}
          min={minValue}
          step={step}
          aria-labelledby={labelElementId}
          aria-invalid={!!error}
          aria-errormessage={errorElementId}
          placeholder={placeholderValue?.toString()}
          onBlur={(e) => {
            /**
             * on blur, remove leading and trailing zeros
             * to force the input field to display proper formatted value
             */
            const el = e.target as HTMLInputElement;
            const formattedValue = el.value.replace(
              /^0*(\d+\.\d*?\d)0*$/,
              "$1"
            );
            validate(formattedValue);
            el.value = formattedValue;
          }}
          onChange={(e) => {
            const newValue = e.target.value;
            validate(newValue);
            setValue(newValue ? +newValue : null);
          }}
        />
      ) : !isLoading &&
        (isRealNumber(value) || isRealNumber(placeholderValue)) ? (
        <FormattedValue value={value ?? placeholderValue} format="0.[000]" />
      ) : (
        "-"
      )}
      {units.map(({ unit, separator }, index) => {
        return (
          <Fragment key={index}>
            {index === 0 && unit?.addSpace ? " " : ""}
            <Displayer object={unit} />
            {separator}
          </Fragment>
        );
      })}
      {isEditEnabled && error && displayError ? (
        <div
          className={s.error}
          role="alert"
          id={errorElementId}
          ref={refs.setFloating}
          style={floatingStyles}
        >
          {error}
        </div>
      ) : null}
    </>
  );
};

export default EditableParamValue;
export type { EditableParamValueProps };
