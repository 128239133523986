import classNames from "classnames";

const Switch = ({
  on = true,
  backgroundColor,
  onBackgroundColor = backgroundColor ?? "#6EDC5C",
  offBackgroundColor = backgroundColor ?? "#AEAEAE",
  textColor = "white",
  onTextColor = textColor ?? "currentcolor",
  offTextColor = textColor ?? "currentcolor",
  knobColor = "white",
  onKnobColor = knobColor ?? "white",
  offKnobColor = knobColor ?? "white",
  aspectRatio = 2.25,
  className = "",
  iconScale = 1,
  icon = true,
  onText = "ON",
  offText = "OFF",
  onClick,
}) => {
  const w = 1000;
  const h = w / aspectRatio;
  const r = h / 2;
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
        cursor: "pointer",
      }}
      viewBox={`0 0 ${w} ${h}`}
      onClick={onClick}
    >
      <path
        fill={on ? onBackgroundColor : offBackgroundColor}
        d={`M${r},0L${w - r},0A${r},${r},0,0,1,${
          w - r
        },${h}L${r},${h}A${r},${r},0,0,1,${r},0Z`}
      />
      {on == null ? null : on ? (
        <text
          x="175"
          y="295"
          font-size="470%"
          textLength="250"
          lengthAdjust="spacingAndGlyphs"
          fill={onTextColor}
        >
          {onText}
        </text>
      ) : (
        <text
          x="500"
          y="285"
          font-size="470%"
          textLength="280"
          lengthAdjust="spacingAndGlyphs"
          fill={offTextColor}
        >
          {offText}
        </text>
      )}
      <circle
        r={r * 0.75}
        cx={on == null ? w / 2 : on ? w - r : r}
        cy={r}
        fill={on ? onKnobColor : offKnobColor}
      />
    </svg>
  );
};

export default Switch;
