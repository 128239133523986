import withIconProps from "helpers/withIconProps";

const WaterIcon = withIconProps((props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5601 6.60002C13.2478 5.50114 13.7354 4.28906 14.0001 3.02002C14.5001 5.52002 16.0001 7.92002 18.0001 9.52002C20.0001 11.12 21.0001 13.02 21.0001 15.02C21.0058 16.4023 20.601 17.7552 19.8369 18.9071C19.0728 20.059 17.9839 20.9582 16.7082 21.4905C15.4325 22.0229 14.0275 22.1644 12.6713 21.8973C11.315 21.6302 10.0686 20.9664 9.09009 19.99M7 16.3C9.2 16.3 11 14.47 11 12.25C11 11.09 10.43 9.99002 9.29 9.06002C8.15 8.13002 7.29 6.75002 7 5.30002C6.71 6.75002 5.86 8.14002 4.71 9.06002C3.56 9.98002 3 11.1 3 12.25C3 14.47 4.8 16.3 7 16.3Z"
        stroke="currentcolor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default WaterIcon;
