import Alert from "atomic-components/atoms/Alert";
import { checkIsCompliant } from "modules/building/helpers/compliaceScores";
import { useComplianceScoreValues } from "modules/building/hooks/useComplianceScoreValues";
import { useTranslate } from "modules/language";

const ComplianceAlert = () => {
  const t = useTranslate();
  const { data, isLoading, isError } = useComplianceScoreValues();

  const isCompliant = checkIsCompliant({
    scoreValues: data,
  });

  if (isLoading || isError || isCompliant) {
    return null;
  }

  return (
    <Alert type="warning">{t("tooltip.NOT_ELIGIBLE_FOR_CERTIFICATE")}</Alert>
  );
};

export default ComplianceAlert;
