import { AccordionGroupElement } from "atomic-components/atoms/Accordion";
import UnitValue from "atomic-components/atoms/UnitValue";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import PercentageIndicator from "atomic-components/atoms/PercentageIndicator/PercentageIndicator";
import useBuildingData from "modules/building/hooks/useBuildingData";
import { Nullable } from "types/utils";
import { useTranslate } from "modules/language";
import { useState } from "react";
import classNames from "classnames";

import s from "./SingleSource.module.scss";

type SingleSourceProps = {
  source: {
    label: string;
    scopes: string[];
    telemetry: string;
    BreakdownComponent: React.ComponentType<{
      scopes: string[];
    }>;
  };
  emissionValue: Nullable<number>;
  emissionPercentValue: number;
};

const SingleSource = ({
  source: { label, scopes, telemetry, BreakdownComponent },
  emissionValue,
  emissionPercentValue,
}: SingleSourceProps) => {
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;

  const t = useTranslate();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <AccordionGroupElement
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      renderAccordionButton={({ isExpanded }: { isExpanded: boolean }) => (
        <WidgetContainer
          key={telemetry}
          className={classNames(s.row, {
            [s.rowExpanded]: isExpanded,
          })}
        >
          <div>
            <b>{t(label)}</b> ({scopes.map((s) => `S${s}`).join(", ")})
          </div>
          <PercentageIndicator
            value={emissionPercentValue}
            showPercentage={false}
          />
          <span>
            <>
              <UnitValue value={emissionValue} unit={unitsByName.kgco2e} />
            </>
          </span>
        </WidgetContainer>
      )}
    >
      <div className={s.breakdown}>
        <BreakdownComponent scopes={scopes} />
      </div>
    </AccordionGroupElement>
  );
};

export default SingleSource;
