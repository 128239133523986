import HorizontalStacks from "atomic-components/atoms/HorizontalStacks";
import PercentageIndicator from "atomic-components/atoms/PercentageIndicator/PercentageIndicator";
import UnitValue from "atomic-components/atoms/UnitValue";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import useBuildingData from "modules/building/hooks/useBuildingData";
import CollapsibleContainer from "atomic-components/molecules/CollapsibleContainer/CollapsibleContainer";
import { useTranslate } from "modules/language";
import { useTotalEmissions } from "modules/building/hooks/useTotalEmissions";
import getPercent from "helpers/getPercent";
import GraphButton from "./GraphButton/GraphButton";

const TotalEmissions = () => {
  const t = useTranslate();
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;
  const { emissionsByScope, totalEmissions, Notifier } = useTotalEmissions();

  return (
    <Notifier>
      <CollapsibleContainer
        title={t("title.TOTAL_BUILDING_EMISSIONS")}
        secondaryTitle={<GraphButton graphType="breakdown" />}
      >
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.TOTAL_EMISSIONS")}
            value={
              <HorizontalStacks
                unit={unitsByName.kgco2e}
                stacks={[
                  {
                    value: emissionsByScope.s1,
                    tooltip: t("label.SCOPE_1_[VALUE]", {
                      value: (
                        <UnitValue
                          value={emissionsByScope.s1}
                          unit={unitsByName.kgco2e}
                        />
                      ),
                    }),
                    legend: t("label.SCOPE_1_[VALUE]", {
                      value: (
                        <>
                          <UnitValue
                            value={emissionsByScope.s1}
                            unit={unitsByName.kgco2e}
                          />{" "}
                          (
                          <UnitValue
                            value={getPercent(
                              emissionsByScope.s1,
                              totalEmissions
                            )}
                            unit={unitsByName.percent}
                          />
                          )
                        </>
                      ),
                    }),
                  },
                  {
                    value: emissionsByScope.s2,
                    tooltip: t("label.SCOPE_2_[VALUE]", {
                      value: (
                        <UnitValue
                          value={emissionsByScope.s2}
                          unit={unitsByName.kgco2e}
                        />
                      ),
                    }),
                    legend: t("label.SCOPE_2_[VALUE]", {
                      value: (
                        <>
                          <UnitValue
                            value={emissionsByScope.s2}
                            unit={unitsByName.kgco2e}
                          />{" "}
                          (
                          <UnitValue
                            value={getPercent(
                              emissionsByScope.s2,
                              totalEmissions
                            )}
                            unit={unitsByName.percent}
                          />
                          )
                        </>
                      ),
                    }),
                  },
                  {
                    value: emissionsByScope.s3,
                    tooltip: t("label.SCOPE_3_[VALUE]", {
                      value: (
                        <UnitValue
                          value={emissionsByScope.s3}
                          unit={unitsByName.kgco2e}
                        />
                      ),
                    }),
                    legend: t("label.SCOPE_3_[VALUE]", {
                      value: (
                        <>
                          <UnitValue
                            value={emissionsByScope.s3}
                            unit={unitsByName.kgco2e}
                          />{" "}
                          (
                          <UnitValue
                            value={getPercent(
                              emissionsByScope.s3,
                              totalEmissions
                            )}
                            unit={unitsByName.percent}
                          />
                          )
                        </>
                      ),
                    }),
                  },
                ]}
              />
            }
            growValue
          />
        </WidgetContainer>
        {/* tech-1448 */}
        {/* <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.EMISSIONS_TARGETS")}
            value={<PercentageIndicator value={0} />}
            growValue
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.POTENTIAL_EMISSIONS_REDUCTION")}
            value={<UnitValue value={0} />}
          />
        </WidgetContainer> */}
      </CollapsibleContainer>
    </Notifier>
  );
};

export default TotalEmissions;
