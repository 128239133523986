import isRealNumber from "helpers/isRealNumber";
import createLinkedComputersFactory from "./createLinkedComputersFactory";
import {
  type LinkedTelemetry,
  type LinkedTelemetriesResponseL2,
  type TelemetryRequest,
  getTelemetryFromEntityTelemetries,
} from "../hooks/useLinkedData";
import { type ComputeFunction } from "./createLinkedComputersFactory";
import type { Nullable } from "types/utils";
import { type CreateBucketsProps, createBuckets } from "../hooks/useBuckets";
import createTimeBuckets, {
  SupportedTimeBucketsResolutions,
} from "helpers/createTimeBuckets";
import { BuildingData } from "./postProcessBuildingData";
import { isString } from "lodash";

type BucketsConfigKeys =
  | "1day"
  | "5buckets"
  | "0+5buckets"
  | "5IntegerBuckets"
  | "0+5IntegerBuckets";
export const bucketsConfigs: Record<BucketsConfigKeys, BucketsConfig> = {
  "1day": {
    type: "time",
    key: "1day",
  },
  "5buckets": {
    type: "telemetry",
    key: "5buckets",
    stepCount: 5,
  },
  "0+5buckets": {
    type: "telemetry",
    key: "0+5buckets",
    stepCount: 5,
    postProcessBuckets: "prepend-zero",
  },
  "5IntegerBuckets": {
    type: "telemetry",
    key: "5IntegerBuckets",
    stepCount: 5,
    minScale: 1,
    snapToMinScale: true,
  },
  "0+5IntegerBuckets": {
    type: "telemetry",
    key: "0+5IntegerBuckets",
    stepCount: 5,
    minScale: 1,
    snapToMinScale: true,
    postProcessBuckets: "prepend-zero",
  },
};

type TelemetryBucketsRequests = {
  type: "telemetry";
  key: BucketsConfigKeys;
  telemetry?: string;
} & TelemetryBucketsMinMax &
  BucketingExtraParams;
type TimeBucketsRequests = {
  type: "time";
  key: SupportedTimeBucketsResolutions & BucketsConfigKeys;
};
type BucketBucketsRequests = {
  type: "bucket";
  key: BucketsConfigKeys;
  min: number;
  max: number;
} & BucketingExtraParams;
type BucketsConfig =
  | TelemetryBucketsRequests
  | TimeBucketsRequests
  | BucketBucketsRequests;

type PostProcessBucketsKeys = "prepend-zero";
const postProcessBucketsFunctions: Record<
  PostProcessBucketsKeys,
  CreateBucketsProps["postProcessBuckets"]
> = {
  "prepend-zero": (buckets) => {
    const [firstBucket, ...restBuckets] = buckets;
    if (firstBucket.from === 0 && firstBucket.to !== 0) {
      return [
        { from: 0, to: 0, key: "0", fromPercent: 0, toPercent: 0 },
        {
          ...firstBucket,
          from: 1,
          key: firstBucket.to === 1 ? "1" : `1-${firstBucket.to}`,
        },
        ...restBuckets,
      ];
    }
    return buckets;
  },
};

type BucketingFunction = ComputeFunction<BucketingParams, HydrateType, any>;

type BucketingExtraParams = {
  stepCount?: CreateBucketsProps["stepCount"];
  includeZeroThreashold?: CreateBucketsProps["includeZeroThreashold"];
  ensureExceedMax?: CreateBucketsProps["ensureExceedMax"];
  minScale?: CreateBucketsProps["minScale"];
  snapToMinScale?: CreateBucketsProps["snapToMinScale"];
  postProcessBuckets?:
    | CreateBucketsProps["postProcessBuckets"]
    | PostProcessBucketsKeys;
};

type TelemetryBucketsMinMax =
  | {
      min?: never;
      max: number;
    }
  | {
      min: number;
      max?: never;
    }
  | {
      min?: never;
      max?: never;
    };

type TelemetryBucketsRequest = {
  type: "telemetry";
  key: string;
  entityId: string;
  telemetry: string;
} & TelemetryBucketsMinMax &
  BucketingExtraParams;

type BucketsRequest = {
  type: "bucket";
  key: string;
  min: number;
  max: number;
} & BucketingExtraParams;

type TimeBucketsRequest = {
  type: "time";
  key: string;
  timeBucketsResolutionKey: SupportedTimeBucketsResolutions;
  telemetry: TelemetryRequest;
  min?: never;
  max?: never;
};

type Request = TelemetryBucketsRequest | BucketsRequest | TimeBucketsRequest;

type TelemetryBucketingParams = Omit<TelemetryBucketsRequest, "telemetry"> & {
  uniqKeyPath: Nullable<string>[];
  telemetry: LinkedTelemetry;
  computeFunction: BucketingFunction;
};
type BucketsBucketingParams = Omit<BucketsRequest, "telemetry"> & {
  uniqKeyPath: Nullable<string>[];
  computeFunction: BucketingFunction;
};
type TimeBucketingParams = Omit<TimeBucketsRequest, "telemetry"> & {
  uniqKeyPath: Nullable<string>[];
  telemetry: LinkedTelemetry;
  computeFunction: BucketingFunction;
};
type BucketingParams =
  | TelemetryBucketingParams
  | BucketsBucketingParams
  | TimeBucketingParams;

const isStateLive = (telemetriesArray: LinkedTelemetry[]) =>
  telemetriesArray.findIndex(
    (telemetry) =>
      telemetry.lastConsecutiveDatumIndex !== telemetry.data.length - 1
  ) < 0;

type Bucket = {
  key: string;
  from: number;
  to: number;
};
type Buckets = {
  buckets: Bucket[];
  getBucketKey: (value: number) => Nullable<string>;
};

const telemetryBucketingFunction = (
  params: TelemetryBucketingParams,
  internalState: Parameters<BucketingFunction>[1]
): Buckets => {
  const { telemetry } = params;

  const isLive = isStateLive([telemetry]);

  const data = telemetry.data;

  let startIndex = internalState.startIndex ?? 0;
  const nextStartIndex = isLive ? data.length : startIndex;

  let max = (params.min ??
    internalState.max ??
    Number.MIN_SAFE_INTEGER) as number;
  let min = (params.max ??
    internalState.min ??
    Number.MAX_SAFE_INTEGER) as number;
  const prevMinMax = { min, max };
  const prevInterim = internalState.interim;

  for (let i = startIndex; i < data.length; i++) {
    const datum = data[i];
    const currentValue = datum.value;
    if (isRealNumber(currentValue)) {
      max = params.max ?? Math.max(max, currentValue);
      min = params.min ?? Math.min(min, currentValue);
    }
  }

  if (min > max) {
    min = 0;
    max = 100;
  }

  if (isLive) {
    internalState.min = min;
    internalState.max = max;
    internalState.startIndex = nextStartIndex;
  } else {
    internalState.interim = {
      prevMinMax: { min, max },
      buckets: prevInterim?.buckets,
    };
  }

  let cachedBuckets = internalState.buckets;
  if (cachedBuckets && min === prevMinMax.min && max === prevMinMax.max) {
    return cachedBuckets;
  }

  if (prevInterim) {
    let { buckets: cachedBuckets, prevMinMax } = prevInterim;
    if (cachedBuckets && min === prevMinMax.min && max === prevMinMax.max) {
      if (isLive) {
        internalState.buckets = cachedBuckets;
      }
      return cachedBuckets;
    }
  }

  const postProcessBuckets = isString(params.postProcessBuckets)
    ? postProcessBucketsFunctions[params.postProcessBuckets]
    : params.postProcessBuckets;
  const buckets = createBuckets({
    min,
    max,
    stepCount: params.stepCount,
    ensureExceedMax: params.ensureExceedMax,
    includeZeroThreashold: params.includeZeroThreashold,
    minScale: params.minScale,
    snapToMinScale: params.snapToMinScale,
    postProcessBuckets,
  });

  if (!isLive) {
    internalState.interim.buckets = buckets;
  } else {
    internalState.buckets = buckets;
  }

  return buckets;
};

const timeBucketingFunction = (
  params: TimeBucketingParams,
  internalState: Parameters<BucketingFunction>[1],
  hydrateParams: HydrateType
): Buckets => {
  if (internalState.buckets) {
    return internalState.buckets;
  }
  const { fromTs, toTs, interval } = params.telemetry;
  const buckets = createTimeBuckets(
    fromTs,
    toTs,
    hydrateParams.buildingData.timezone,
    interval,
    params.timeBucketsResolutionKey
  );

  internalState.buckets = buckets;

  return buckets;
};

const bucketBucketingFunction = (
  params: BucketsBucketingParams,
  internalState: Parameters<BucketingFunction>[1]
): Buckets => {
  if (internalState.buckets) {
    return internalState.buckets;
  }

  const postProcessBuckets = isString(params.postProcessBuckets)
    ? postProcessBucketsFunctions[params.postProcessBuckets]
    : params.postProcessBuckets;

  const buckets = createBuckets({ ...params, postProcessBuckets });

  internalState.buckets = buckets;

  return buckets;
};

const bucketingFunction: BucketingFunction = (
  params,
  internalState,
  hydrateParams
) => {
  switch (params.type) {
    case "telemetry":
      return telemetryBucketingFunction(params, internalState);
    case "time":
      return timeBucketingFunction(params, internalState, hydrateParams);
    case "bucket":
      return bucketBucketingFunction(params, internalState);
  }
};

type HydrateType = {
  telemetries: LinkedTelemetriesResponseL2;
  buildingData: BuildingData;
};

const createLinkedTelemetriesBucketer = createLinkedComputersFactory<
  Request,
  BucketingParams,
  HydrateType,
  any
>((request, { telemetries }) => {
  let { type, key } = request;
  const params: any = {};
  if ("entityId" in request) {
    params.entityId = request.entityId;
  }
  if ("telemetry" in request) {
    params.telemetry = getTelemetryFromEntityTelemetries(
      telemetries[params.entityId],
      request.telemetry
    );
  }

  let uniqKeyPath;
  switch (type) {
    case "telemetry":
      uniqKeyPath = ["telemetry", params.entityId, params.telemetry.key, key];
      break;
    case "time":
      uniqKeyPath = ["time", params.telemetry.intervals.key, key];
      break;
    case "bucket":
      uniqKeyPath = ["bucket", key];
      break;
  }

  return {
    ...request,
    ...params,
    uniqKeyPath,
    computeFunction: bucketingFunction,
    key,
  } as BucketingParams;
});

export default createLinkedTelemetriesBucketer;
export type {
  Bucket,
  Buckets,
  TelemetryBucketsRequest,
  BucketsRequest,
  TimeBucketsRequest,
  PostProcessBucketsKeys,
  TelemetryBucketsMinMax,
  BucketingExtraParams,
  BucketsConfigKeys,
  BucketsConfig,
};
