import withIconProps from "helpers/withIconProps";

const SyncIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="6 6 12 12"
      fill="none"
      {...props}
    >
      <path
        d="M15.5 8L15.5 10.8284M15.5 10.8284L12.6716 10.8284M15.5 10.8284C15.5 10.8284 14 8.5 12 8.5C8.67157 8.5 8.5 11 8.5 11"
        stroke="currentColor"
      />
      <path
        d="M8.5 16L8.5 13.1716M8.5 13.1716L11.3284 13.1716M8.5 13.1716C8.5 13.1716 10 15.5 12 15.5C15.3284 15.5 15.5 13 15.5 13"
        stroke="currentColor"
      />
    </svg>
  );
});

export default SyncIcon;
