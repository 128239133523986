import withIconProps from "helpers/withIconProps";
import type { SVGAttributes } from "react";

type Props = SVGAttributes<SVGElement> & {
  isHigh?: boolean;
};

const TemperatureIcon = withIconProps(
  ({ color, fill: passedFill, isHigh, ...props }: Props) => {
    const currentColor = color ?? "currentcolor";

    const fill =
      passedFill ||
      (isHigh ? "var(--color-red-pale)" : "var(--color-green-dark)");

    return (
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M0.5 8C0.5 3.58172 4.08172 0 8.5 0H24.5C28.9183 0 32.5 3.58172 32.5 8V24C32.5 28.4183 28.9183 32 24.5 32H8.5C4.08172 32 0.5 28.4183 0.5 24V8Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7019 5.2019C14.3114 4.59241 15.138 4.25 16 4.25C16.862 4.25 17.6886 4.59241 18.2981 5.2019C18.9076 5.8114 19.25 6.63805 19.25 7.5V18.3785C20.022 18.9871 20.61 19.8022 20.9431 20.7329C21.3225 21.7934 21.3509 22.9478 21.024 24.0256C20.697 25.1034 20.0321 26.0475 19.1274 26.7185C18.2228 27.3894 17.1263 27.7517 16 27.7517C14.8737 27.7517 13.7772 27.3894 12.8726 26.7185C11.9679 26.0475 11.303 25.1034 10.976 24.0256C10.6491 22.9478 10.6775 21.7934 11.0569 20.7329C11.39 19.8022 11.978 18.9871 12.75 18.3785V7.5C12.75 6.63805 13.0924 5.8114 13.7019 5.2019ZM16 5.75C15.5359 5.75 15.0908 5.93437 14.7626 6.26256C14.4344 6.59075 14.25 7.03587 14.25 7.5V18.76C14.25 19.0105 14.1249 19.2444 13.9167 19.3836C13.2477 19.8306 12.7403 20.4808 12.4692 21.2383C12.1982 21.9958 12.1779 22.8203 12.4115 23.5902C12.645 24.3601 13.1199 25.0344 13.7661 25.5137C14.4123 25.9929 15.1955 26.2517 16 26.2517C16.8045 26.2517 17.5877 25.9929 18.2339 25.5137C18.8801 25.0344 19.355 24.3601 19.5885 23.5902C19.8221 22.8203 19.8018 21.9958 19.5308 21.2383C19.2597 20.4808 18.7523 19.8306 18.0833 19.3836C17.8751 19.2444 17.75 19.0105 17.75 18.76V7.5C17.75 7.03587 17.5656 6.59075 17.2374 6.26256C16.9092 5.93437 16.4641 5.75 16 5.75Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 11.5C16.2761 11.5 16.5 11.7239 16.5 12L16.5 23H15.5L15.5 12C15.5 11.7239 15.7239 11.5 16 11.5Z"
          fill={currentColor}
        />
        <path
          d="M18.5 22.5C18.5 23.8807 17.3807 25 16 25C14.6193 25 13.5 23.8807 13.5 22.5C13.5 21.1193 14.6193 20 16 20C17.3807 20 18.5 21.1193 18.5 22.5Z"
          fill={currentColor}
        />
      </svg>
    );
  }
);

export default TemperatureIcon;
