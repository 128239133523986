import React, { forwardRef } from "react";
import useResizeObserver from "../../hooks/useResizeObserver";

/**
 * @typedef {object} ElementWithOnResizeExtraProps
 * @property {string} [tagName] the html tag name to use
 * @property {import("../../hooks/useResizeObserver").OnResizeHandler} onResize
 */

/** @type {React.FunctionComponent<Omit<React.AllHTMLAttributes<HTMLElement>, "onResize"> & ElementWithOnResizeExtraProps & {ref?: import("react").RefObject}>} */
const ElementWithOnResize = forwardRef(
  ({ tagName: Element = "div", onResize, ...props }, ref) => {
    const resizeRef = useResizeObserver({ onResize, ref });
    return <Element ref={resizeRef} {...props} />;
  }
);

export default ElementWithOnResize;
