import Linker from "./Linker";
import { useEffect } from "react";
import { set } from "helpers/setGet";
import { type Nullable } from "types/utils";
import { flatten } from "lodash";

type BaseRequestType = Record<string, any>;
type BaseHydrateParamsType = Record<string, any>;
type BaseParamsType<
  PARAMS_TYPE extends BaseParamsType<
    PARAMS_TYPE,
    HYDRATE_PARAMS_TYPE,
    VALUE_TYPE
  >,
  HYDRATE_PARAMS_TYPE extends BaseHydrateParamsType,
  VALUE_TYPE extends BaseValueType
> = Omit<Record<string, any>, "computeFunction" | "uniqKeyPath"> & {
  computeFunction: ComputeFunction<
    PARAMS_TYPE,
    HYDRATE_PARAMS_TYPE,
    VALUE_TYPE
  >;
  uniqKeyPath: Nullable<string>[];
};
type BaseValueType = any;

type ComputeFunction<
  PARAMS_TYPE extends BaseParamsType<
    PARAMS_TYPE,
    HYDRATE_PARAMS_TYPE,
    Tree<VALUE_TYPE>
  >,
  HYDRATE_PARAMS_TYPE extends BaseHydrateParamsType,
  VALUE_TYPE extends BaseValueType
> = (
  params: PARAMS_TYPE,
  comuterInternalState: Record<string | symbol, any>,
  hydrateParams: HYDRATE_PARAMS_TYPE
) => VALUE_TYPE;

type Tree<LEAF_TYPE> = LEAF_TYPE | RecordTree<LEAF_TYPE>;
type RecordTree<LEAF_TYPE> = Record<string, TreeNode<LEAF_TYPE>>;
interface TreeNode<LEAF_TYPE> extends RecordTree<LEAF_TYPE> {}

type GetRequestParams<
  REQUEST_TYPE extends BaseRequestType,
  PARAMS_TYPE extends BaseParamsType<
    PARAMS_TYPE,
    HYDRATE_PARAMS_TYPE,
    VALUE_TYPE
  >,
  HYDRATE_PARAMS_TYPE extends BaseHydrateParamsType,
  VALUE_TYPE extends BaseValueType
> = (
  request: REQUEST_TYPE,
  hydrateParams: HYDRATE_PARAMS_TYPE
) => PARAMS_TYPE | PARAMS_TYPE[];

const createLinkedComputersFactory = <
  REQUEST_TYPE extends BaseRequestType,
  PARAMS_TYPE extends BaseParamsType<
    PARAMS_TYPE,
    HYDRATE_PARAMS_TYPE,
    VALUE_TYPE
  >,
  HYDRATE_PARAMS_TYPE extends BaseHydrateParamsType,
  VALUE_TYPE extends BaseValueType
>(
  getRequestParams: GetRequestParams<
    REQUEST_TYPE,
    PARAMS_TYPE,
    HYDRATE_PARAMS_TYPE,
    VALUE_TYPE
  >,
  idleDuration: number = 60000
) => {
  const rootLinker = new Linker({
    idleDuration,
  });

  function createLinkedComputer(
    uniqKey: string,
    onChange: (value: VALUE_TYPE) => void
  ) {
    let params: Nullable<PARAMS_TYPE>;
    let hydrateParams: Nullable<HYDRATE_PARAMS_TYPE>;

    let unlinkFunction: () => void,
      value: VALUE_TYPE,
      triggerLinkerEvent: Nullable<(value: VALUE_TYPE) => void>,
      computerInternalState: Nullable<Record<string, any>>;

    const doCompute = () => {
      if (!triggerLinkerEvent) {
        return;
      }

      const computeFunction = params!.computeFunction;
      const value = computeFunction(
        params!,
        computerInternalState!,
        hydrateParams!
      );

      triggerLinkerEvent(value);
    };

    const hydrate = (
      _params: PARAMS_TYPE,
      _hydrateParams: HYDRATE_PARAMS_TYPE
    ) => {
      params = _params;
      hydrateParams = _hydrateParams;

      let needsComputing = true;
      if (!unlinkFunction) {
        unlinkFunction = rootLinker.link({
          key: uniqKey,
          callback: (_value: any) => {
            value = _value;
            onChange && onChange(value);
          },
          linkInitalizerFunction: ({
            triggerEvent,
            isDestroyed,
            linkUserData,
          }: {
            triggerEvent: (value: VALUE_TYPE) => void;
            isDestroyed: () => boolean;
            linkUserData: Record<string, any>;
          }) => {
            if (isDestroyed()) {
              return;
            }
            triggerLinkerEvent = triggerEvent;
            computerInternalState = linkUserData;
            doCompute();
            needsComputing = false;
          },
        });
      }
      if (needsComputing) {
        doCompute();
      }
    };

    const unlink = () => {
      triggerLinkerEvent = null;
      computerInternalState = null;
      unlinkFunction && unlinkFunction();
    };

    return {
      hydrate,
      unlink,
      getValue: () => value,
    };
  }

  type LinkedComputer = ReturnType<typeof createLinkedComputer>;
  type ValuesTree = Tree<VALUE_TYPE>;

  function createLinkedComputers(onChange: (values: ValuesTree) => void) {
    type LinkedComputerType = LinkedComputer;
    type ComputersByKey = Record<string, LinkedComputerType>;
    type Response = {
      keyPath: Nullable<string>[];
      value: VALUE_TYPE;
    };

    /** from inputs */
    let requests: REQUEST_TYPE[] = [];
    let hydrateParams: HYDRATE_PARAMS_TYPE = {} as HYDRATE_PARAMS_TYPE;

    /** internal */
    let computersByKey: ComputersByKey = {};
    let callbackTimer: Nullable<ReturnType<typeof setTimeout>>;
    let responses: Nullable<Response[]>;

    /** outputs */
    let values: Nullable<ValuesTree>;

    const setValues = (responses: Response[]) => {
      const nextValues = {};
      responses.forEach(({ keyPath, value }) => {
        set(nextValues, keyPath, value);
      });
      values = nextValues;
    };

    const addResponse = (response: Response) => {
      if (callbackTimer) {
        clearTimeout(callbackTimer);
        callbackTimer = null;
      }
      if (!responses) {
        responses = [];
      }
      responses.push(response);
      callbackTimer = setTimeout(() => {
        setValues(responses!);
        callbackTimer = null;
        responses = null;
        onChange(values!);
      });
    };

    const hydrate = (
      _requests: REQUEST_TYPE[],
      _hydrateParams: HYDRATE_PARAMS_TYPE
    ) => {
      const hydrateParamsChanged =
        Object.entries(_hydrateParams).findIndex(
          ([key, value]) => value !== hydrateParams[key]
        ) >= 0;
      hydrateParams = _hydrateParams;

      const requestsChanged = _requests !== requests;
      requests = _requests;

      const hasChanged = hydrateParamsChanged || requestsChanged;

      if (!hasChanged) {
        return;
      }

      const newComputersByKey: ComputersByKey = {},
        syncResponses: Response[] = [];

      const params = flatten(
        requests.map((request) => getRequestParams(request, hydrateParams))
      );
      params.forEach((params) => {
        const keyPath = params.uniqKeyPath;

        const requestKey = JSON.stringify(keyPath);

        if (newComputersByKey[requestKey]) {
          return;
        }

        let currentComputer = computersByKey[requestKey];

        if (currentComputer) {
          currentComputer.hydrate(params, hydrateParams);
          const response = {
            keyPath,
            value: currentComputer.getValue(),
          };
          syncResponses.push(response);
          delete computersByKey[requestKey];
        } else {
          let syncAnswered = false;
          currentComputer = createLinkedComputer(requestKey, (value) => {
            const response = {
              keyPath,
              value,
            };
            if (!syncAnswered) {
              syncResponses.push(response);
              return;
            }
            addResponse(response);
          });
          currentComputer.hydrate(params, hydrateParams);
        }

        newComputersByKey[requestKey] = currentComputer;
      });

      Object.values(computersByKey).forEach((linker) => {
        linker.unlink();
      });
      computersByKey = newComputersByKey;

      setValues(syncResponses);
    };

    const unlink = () => {
      Object.values(computersByKey).forEach((linker) => {
        linker.unlink();
      });
      computersByKey = {};
    };
    const useUmount = () => useEffect(() => unlink, []);

    return { hydrate, unlink, useUmount, getValue: () => values };
  }

  return createLinkedComputers;
};

export default createLinkedComputersFactory;

export type { ComputeFunction, Tree as ValuesTree };
