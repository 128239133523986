import React from "react";
import { useModelState } from "../../Model";
import PointsAnnotations from "modules/viewer/components/PointsAnnotations";

const EntitiesAnnotations = React.memo(
  ({
    annotations,
    defaultMappingName = "default",
    defaultPointName = "annotationCenter",
    defaultClassName,
    groupClassName,
    disabled,
  }) => {
    const { getEntityMappingByEntityId, objectById } = useModelState();
    return (
      <PointsAnnotations
        disabled={disabled}
        groupClassName={groupClassName}
        annotations={annotations.map(
          ({
            key,
            entityId,
            mappingName = defaultMappingName,
            pointName = defaultPointName,
            className = defaultClassName,
            Component,
          }) => {
            const entityMapping = getEntityMappingByEntityId(
              entityId,
              mappingName
            );
            if (!entityMapping) {
              console.warn(`${entityId} has no "${mappingName}" mapping`);
              return null;
            }
            let pointCoordinates = entityMapping.points.find(
              ({ name }) => name === pointName
            )?.coordinates;
            if (!pointCoordinates) {
              console.warn(`${entityId} has no "${pointName}" point`);
              for (let i = 0; i < entityMapping.objectsIds.length; i++) {
                const id = entityMapping.objectsIds[i];
                const object = objectById[id];
                if (object?.position) {
                  pointCoordinates = object.position.toArray();
                  break;
                }
              }
            }
            if (!pointCoordinates) {
              return null;
            }
            return {
              key,
              pointCoordinates,
              className,
              children: <Component entityId={entityId} />,
            };
          }
        )}
      />
    );
  }
);

export default EntitiesAnnotations;
