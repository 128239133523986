import { useMemo } from "react";
import { sumBy } from "lodash";
import type { Nullable } from "types/utils";
import s from "./CertificateStatusBar.module.scss";
import classNames from "classnames";
import { useWindowSize } from "modules/core/services/window-size.service";
import ScorePointer from "atomic-components/molecules/CertificateStatusBar/ScorePointer/ScorePointer";
import isRealNumber from "helpers/isRealNumber";
import getPercent from "helpers/getPercent";

type CertificateStatusBarProps = {
  customRanges: CustomRangesProps;
  value?: Nullable<number>;
};

type CustomRangesProps = {
  ranges: CustomColorsProps[];
  scores: ScoresProps;
};

type CustomColorsProps = {
  color: string[];
  label: string;
  min: number;
  max: number;
};

type ScoresProps = {
  currentScore: Nullable<number>;
  targetScore: Nullable<number>;
};

const CertificateStatusBar = ({
  value: valueFromProps,
  customRanges,
}: CertificateStatusBarProps) => {
  const { height, width } = useWindowSize();
  const minMeterRangeValue = 0;
  const maxMeterRangeValue = 1;

  const value =
    !valueFromProps || valueFromProps < minMeterRangeValue
      ? minMeterRangeValue
      : valueFromProps > maxMeterRangeValue
      ? maxMeterRangeValue
      : valueFromProps;

  const { colors, percentColors } = useMemo(() => {
    const percentColors: string[] = [];
    const buildRanges: {
      end: number;
      weight: number;
      color: string;
    }[] = [];
    customRanges.ranges.map((item) =>
      buildRanges.push({
        end: 1,
        weight: 1,
        color: item.color[0],
      })
    );
    const totalWeights = sumBy(buildRanges, "weight");

    const valueRangeIndex = buildRanges.findIndex(({ end }) => value <= end);
    const valueRangeStart =
      buildRanges[valueRangeIndex - 1]?.end ?? minMeterRangeValue;
    const valueRangeEnd = buildRanges[valueRangeIndex].end;
    let kpiRangeInit = 0;
    let colorRangeWeightAgg = 0;
    const colors: string[] = [];

    const rangeLength = buildRanges.length;

    for (let i = 0; i < rangeLength; i++) {
      const { color, weight } = buildRanges[i];
      if (i < valueRangeIndex) {
        kpiRangeInit += weight;
      }

      colorRangeWeightAgg += weight;

      const colorPercentPoint = `${Math.round(
        (colorRangeWeightAgg * 100) / totalWeights
      )}%`;
      colors.push(`${color} ${colorPercentPoint}`);
      if (customRanges) {
        percentColors.push(colorPercentPoint);
      }

      const next = buildRanges[i + 1];
      if (next) {
        colors.push(`${next.color} ${colorPercentPoint}`);
      }
    }

    const kpiRangePercent = Math.round(
      (((value - valueRangeStart) / (valueRangeEnd - valueRangeStart)) *
        (buildRanges[valueRangeIndex].weight / totalWeights) +
        kpiRangeInit / totalWeights) *
        100
    );

    return {
      kpiRangePercent,
      colors: colors.join(","),
      statusColor: buildRanges[valueRangeIndex].color,
      percentColors: percentColors,
    };
  }, [value, customRanges]);

  const positionLabel = (position: number) => {
    if (position === 0) {
      return percentColors[position];
    }

    const firstColor = percentColors[position];
    const secondColor = percentColors[position - 1];

    const extractPercentage = (color: string) => Number(color.split("%")[0]);

    const percent =
      extractPercentage(firstColor) - extractPercentage(secondColor);

    return `${percent}%`;
  };

  const findSector = (
    dataObject: { percent: number; points: number | undefined }[],
    value: number
  ) => {
    for (let i = 0; i < dataObject.length; i++) {
      if (value <= dataObject[i].points!) {
        return i;
      }
    }
    return dataObject.length - 1;
  };

  const calculateScoreBucketPercentage = (
    score: number,
    previousMaxSectorPoints: number,
    currentMaxSectorPoints: number,
    bucketPercent: number
  ) => {
    currentMaxSectorPoints++;
    previousMaxSectorPoints++;

    if (score <= 0) {
      return 0;
    }

    if (score >= 100) {
      return 20;
    }

    let bucketLength = currentMaxSectorPoints - previousMaxSectorPoints;

    return ((score - previousMaxSectorPoints) / bucketLength) * bucketPercent;
  };

  const positionScore = (score: Nullable<number>) => {
    if (!isRealNumber(score)) {
      return;
    }

    const percents = percentColors.map((item) => {
      const dataString = item.split("%");
      return Number(dataString[0]);
    });

    const percentByPoints = percents.map((percent, index) => {
      return {
        percent,
        points: customRanges?.ranges[index].max,
      };
    });

    const sector = findSector(percentByPoints, score);

    const currentMaxSectorPoints = percentByPoints[sector].points;
    const previousMaxSectorPoints = percentByPoints[sector - 1]?.points ?? 0;

    const bucketPercent = getPercent(1, percentColors.length) as number;

    const scoreBucketPercentage = calculateScoreBucketPercentage(
      score,
      previousMaxSectorPoints,
      currentMaxSectorPoints,
      bucketPercent
    );

    const previousPercentValue = percentByPoints?.[sector - 1]?.percent ?? 0;

    return `${scoreBucketPercentage + previousPercentValue}%`;
  };

  const gradientBackGround = (item: CustomColorsProps) => {
    if (item.color.length > 1) {
      return `linear-gradient(146deg, ${item.color[0]}, ${item.color[1]}, ${item.color[0]})`;
    }
  };

  return (
    <>
      <div className={s.container}>
        <div className={s.meterContainer}>
          <meter
            className={classNames(s.meter, {
              [s.customColors]: customRanges,
            })}
            min={minMeterRangeValue}
            max={100}
            value={customRanges.scores.currentScore ?? undefined}
            style={{
              background: `linear-gradient(90deg,${colors})`,
            }}
          >
            <>
              <div className={s.headersContain}>
                {customRanges.ranges.map((item, index) => (
                  <p
                    style={{
                      width: `${positionLabel(index)}`,
                      fontSize:
                        width < height ? "11px" : "var(--font-size-regular)",
                    }}
                    key={`${index}header`}
                    className={s.labelHeader}
                  >
                    {`${item.min}-${item.max} pt`}
                  </p>
                ))}
              </div>
              <div
                className={s.labelsContain}
                style={{ marginTop: width < height ? "2px" : "3px" }}
              >
                {customRanges.ranges.map((item, index) => (
                  <p
                    style={{
                      width: `${positionLabel(index)}`,
                      background: `${gradientBackGround(item)}`,
                      fontSize:
                        width < height ? "8px" : "var(--font-size-regular)",
                    }}
                    key={`${index}label`}
                    className={
                      item.color.length > 1 ? s.labelGradiant : s.labelTitle
                    }
                  >
                    {item.label}
                  </p>
                ))}
              </div>
            </>
          </meter>
          <ScorePointer
            currentPosition={positionScore(customRanges.scores.currentScore)}
            targetPosition={positionScore(customRanges.scores.targetScore)}
          />
        </div>
      </div>
    </>
  );
};

export default CertificateStatusBar;
export type { CertificateStatusBarProps };
