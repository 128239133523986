import { useMutation } from "react-query";
import {
  updateComplianceScoreValues,
  UpdateComplianceScoreValuesParams,
} from "../api/complianceScores";
import { invalidateComplianceScoreValuesQuery } from "./useComplianceScoreValues";
import useFullDateFilterState from "./useFullDateFilterState";

const useComplianceScoreValuesMutate = (data: { year?: number } = {}) => {
  const { from } = useFullDateFilterState();
  const year = data.year ?? from.year;

  return useMutation({
    mutationFn: (values: UpdateComplianceScoreValuesParams["values"]) =>
      updateComplianceScoreValues({
        year,
        values,
      }).then((resp) => {
        setTimeout(() => {
          invalidateComplianceScoreValuesQuery();
        }, 1000);
        return resp;
      }),
  });
};

export default useComplianceScoreValuesMutate;
