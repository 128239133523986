import { useTranslate } from "modules/language";
import LabelByRefrigerantTypes from "../LabelByRefrigerantTypes/LabelByRefrigerantTypes";
import useBuildingData from "modules/building/hooks/useBuildingData";
import isRealNumber from "helpers/isRealNumber";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";

const RefrigerantsInUse = () => {
  const t = useTranslate();
  const {
    buildingData: {
      unitsByName,
      constants: { refrigerants },
    },
  } = useBuildingData();

  const { coolingCapacityR22, coolingCapacityR410A, coolingCapacityR407C } =
    refrigerants;

  const { state, Notifier } = useEditableParamsState();

  const refrigerantChargeValue = state.refrigerantMassACUnit.value;

  return (
    <Notifier>
      <LabelByRefrigerantTypes
        label={t("label.REFRIGERANTS_IN_USE")}
        values={[
          isRealNumber(coolingCapacityR22) &&
          isRealNumber(refrigerantChargeValue)
            ? coolingCapacityR22 * refrigerantChargeValue
            : null,
          isRealNumber(coolingCapacityR410A) &&
          isRealNumber(refrigerantChargeValue)
            ? coolingCapacityR410A * refrigerantChargeValue
            : null,
          isRealNumber(coolingCapacityR407C) &&
          isRealNumber(refrigerantChargeValue)
            ? coolingCapacityR407C * refrigerantChargeValue
            : null,
        ]}
        unit={unitsByName.kg}
      />
    </Notifier>
  );
};

export default RefrigerantsInUse;
