import withIconProps from "helpers/withIconProps";

const DropletIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 22C13.8565 22 15.637 21.2625 16.9497 19.9497C18.2625 18.637 19 16.8565 19 15C19 13 18 11.1 16 9.5C14 7.9 12.5 5.5 12 3C11.5 5.5 10 7.9 8 9.5C6 11.1 5 13 5 15C5 16.8565 5.7375 18.637 7.05025 19.9497C8.36301 21.2625 10.1435 22 12 22Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2315 12.7037C9.94 12.7037 9.7037 12.94 9.7037 13.2315C9.7037 13.523 9.94 13.7593 10.2315 13.7593C10.523 13.7593 10.7593 13.523 10.7593 13.2315C10.7593 12.94 10.523 12.7037 10.2315 12.7037ZM9 13.2315C9 12.5514 9.55135 12 10.2315 12C10.9116 12 11.463 12.5514 11.463 13.2315C11.463 13.9116 10.9116 14.463 10.2315 14.463C9.55135 14.463 9 13.9116 9 13.2315ZM14.3808 12.4549C14.5182 12.3175 14.741 12.3175 14.8784 12.4549C15.0158 12.5923 15.0158 12.8151 14.8784 12.9525L9.9525 17.8784C9.81509 18.0158 9.59231 18.0158 9.45491 17.8784C9.3175 17.741 9.3175 17.5182 9.45491 17.3808L14.3808 12.4549ZM14.1019 16.5741C13.8104 16.5741 13.5741 16.8104 13.5741 17.1019C13.5741 17.3933 13.8104 17.6296 14.1019 17.6296C14.3933 17.6296 14.6296 17.3933 14.6296 17.1019C14.6296 16.8104 14.3933 16.5741 14.1019 16.5741ZM12.8704 17.1019C12.8704 16.4217 13.4217 15.8704 14.1019 15.8704C14.782 15.8704 15.3333 16.4217 15.3333 17.1019C15.3333 17.782 14.782 18.3333 14.1019 18.3333C13.4217 18.3333 12.8704 17.782 12.8704 17.1019Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default DropletIcon;
