import withIconProps from "helpers/withIconProps";
import type { SVGAttributes } from "react";

const stylesByDirection = {
  right: { transform: "rotate(180deg)" },
  left: { transform: "rotate(0deg)" },
  up: { transform: "rotate(90deg)" },
  down: { transform: "rotate(-90deg)" },
};

type Props = SVGAttributes<SVGElement> & {
  dir?: "left" | "right" | "up" | "down";
};

const BareArrow = withIconProps(({ color, dir, ...props }: Props) => {
  const currentColor = color ?? "currentcolor";

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transformBox: "fill-box",
        ...(!dir ? {} : stylesByDirection[dir]),
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9503 6.49045C12.2317 6.79438 12.2135 7.2689 11.9096 7.55032L7.9139 11.25H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H7.9139L11.9096 16.4497C12.2135 16.7311 12.2317 17.2056 11.9503 17.5096C11.6689 17.8135 11.1944 17.8317 10.8904 17.5503L5.49044 12.5503C5.33714 12.4084 5.25 12.2089 5.25 12C5.25 11.7911 5.33714 11.5916 5.49044 11.4497L10.8904 6.44968C11.1944 6.16826 11.6689 6.18651 11.9503 6.49045Z"
        fill={currentColor}
      />
    </svg>
  );
});

export default BareArrow;
