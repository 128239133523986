import {
  get as _get,
  isString,
  setWith as _setWith,
  unset as _unset,
} from "lodash";
import { Nullable } from "types/utils";

const nullSymbol = Symbol("null");

export const get = (
  object: Record<string, any>,
  path: string | Nullable<string>[]
) => {
  if (!isString(path)) {
    return _get(
      object,
      path.map((segment) => segment ?? nullSymbol)
    );
  }
  return _get(object, path);
};

export const set = (
  object: Record<string, any>,
  path: string | Nullable<string>[],
  value: any
) => {
  if (!isString(path)) {
    return _setWith(
      object,
      path.map((segment) => segment ?? nullSymbol),
      value,
      Object
    );
  }
  return _setWith(object, path, value, Object);
};

export const unset = (
  object: Record<string, any>,
  path: string | Nullable<string>[]
) => {
  if (!isString(path)) {
    return _unset(
      object,
      path.map((segment) => segment ?? nullSymbol)
    );
  }
  return _unset(object, path);
};
