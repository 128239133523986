import classNames from "classnames";

type PeopleProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
};

const People = ({ className, iconScale = 1, icon = true }: PeopleProps) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8.5C1 7.09375 2.875 7.09375 3.8125 6.15625C4.28125 5.6875 2.875 5.6875 2.875 3.34375C2.875 1.78141 3.49984 1 4.75 1C6.00016 1 6.625 1.78141 6.625 3.34375C6.625 5.6875 5.21875 5.6875 5.6875 6.15625C6.625 7.09375 8.5 7.09375 8.5 8.5"
        stroke="currentcolor"
        stroke-width="0.7"
        stroke-linecap="round"
      />
      <path
        d="M7.87158 7.40194C8.22596 7.15771 8.68599 6.98187 9.12619 6.76986C9.47036 6.60409 9.80241 6.41621 10.0624 6.15625C10.5311 5.6875 9.12487 5.6875 9.12487 3.34375C9.12487 1.78141 9.74972 1 10.9999 1C12.25 1 12.8749 1.78141 12.8749 3.34375C12.8749 5.6875 11.4686 5.6875 11.9374 6.15625C12.8749 7.09375 14.7499 7.09375 14.7499 8.5"
        stroke="currentcolor"
        stroke-width="0.7"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default People;
