import { useMemo, useRef } from "react";
import isRealNumber from "helpers/isRealNumber";
import { getBounds } from "hooks/useBounds";
import { orderBy, sortedLastIndexBy } from "lodash";
import type { LinkedTelemetry } from "modules/building/hooks/useLinkedData";
import type { Bucket } from "types/bucket";
import { Nullable } from "types/utils";

type PostProcessBuckets = (buckets: Bucket[]) => Bucket[];

export const createBuckets = (args: {
  min: number;
  max: number;
  stepCount?: Nullable<number>;
  includeZeroThreashold?: Nullable<number>;
  ensureExceedMax?: Nullable<boolean>;
  minScale?: Nullable<number>;
  snapToMinScale?: Nullable<boolean>;
  postProcessBuckets?: Nullable<PostProcessBuckets>;
}) => {
  const {
    min: argsMin,
    max: argsMax,
    stepCount: argsStepCount,
    includeZeroThreashold = 0,
    ensureExceedMax = false,
    minScale,
    snapToMinScale = true,
    postProcessBuckets,
  } = args;
  let bounds = getBounds({
    min: argsMin,
    max: argsMax,
    stepCount: argsStepCount,
    includeZeroThreashold,
    ensureExceedMax,
    minScale,
  });

  const hasMinScale = minScale != null && snapToMinScale;
  if (hasMinScale && bounds.max === argsMax) {
    bounds = getBounds({
      min: argsMin,
      max: argsMax + minScale,
      stepCount: argsStepCount,
      includeZeroThreashold: includeZeroThreashold ?? 0,
      ensureExceedMax: ensureExceedMax ?? false,
      minScale,
    });
  }

  const { max, min, step, stepCount } = bounds;
  let buckets: Bucket[] = [];
  for (let i = 0; i < stepCount; i++) {
    const from = min + i * step;
    const to = min + (i + 1) * step;

    const fromPercent = ((from - min) / (max - min)) * 100;
    const toPercent = ((to - min) / (max - min)) * 100;
    const toSnappedToMinScale = hasMinScale ? to - minScale : to;

    buckets.push({
      key:
        from === toSnappedToMinScale
          ? `${from}`
          : `${from}-${toSnappedToMinScale}`,
      from,
      to: toSnappedToMinScale,
      fromPercent,
      toPercent,
    });
  }

  if (postProcessBuckets) {
    buckets = orderBy(
      postProcessBuckets(buckets),
      ["from", "to"],
      ["asc", "desc"]
    );
  }

  const getBucketKey = (value: any) => {
    if (!isRealNumber(value)) {
      return;
    }

    const bucketIndex = buckets.findIndex(
      (bucket) => bucket.fromPercent <= value && value <= bucket.toPercent
    );

    return buckets[bucketIndex]?.key;
  };

  return { ...bounds, buckets, getBucketKey, minScale };
};

Object.defineProperty(window, "createBuckets", {
  value: createBuckets,
  enumerable: true,
  configurable: false,
  writable: false,
});

const useTelemetryBuckets = ({
  telemetry,
  max,
  min,
  stepCount,
  includeZeroThreashold,
  ensureExceedMax,
  minScale,
  snapToMinScale,
  postProcessBuckets,
}: (
  | {
      telemetry: LinkedTelemetry;
      max?: never;
      min?: never;
    }
  | {
      telemetry: LinkedTelemetry;
      max: number;
      min?: never;
    }
  | {
      telemetry: LinkedTelemetry;
      max?: never;
      min: number;
    }
) & {
  stepCount?: number;
  includeZeroThreashold?: number;
  ensureExceedMax?: boolean;
  minScale?: number;
  snapToMinScale?: boolean;
  postProcessBuckets?: PostProcessBuckets;
}) => {
  const postProcessBucketsRef = useRef<PostProcessBuckets>();
  postProcessBucketsRef.current = postProcessBuckets;

  return useMemo(() => {
    const values = telemetry.data
      .map(({ value }) => value)
      .filter(isRealNumber);
    const maxValue = max ?? Math.max(...values);
    const minValue = min ?? Math.min(...values);

    return createBuckets({
      min: minValue,
      max: maxValue,
      stepCount,
      includeZeroThreashold,
      ensureExceedMax,
      minScale,
      snapToMinScale,
      postProcessBuckets: postProcessBucketsRef.current,
    });
  }, [
    telemetry,
    max,
    min,
    stepCount,
    includeZeroThreashold,
    ensureExceedMax,
    minScale,
    snapToMinScale,
  ]);
};

const useBuckets = ({
  min,
  max,
  stepCount,
  includeZeroThreashold,
  ensureExceedMax,
  minScale,
  snapToMinScale,
  postProcessBuckets,
}: {
  min: number;
  max: number;
  stepCount?: number;
  includeZeroThreashold?: number;
  ensureExceedMax?: boolean;
  minScale?: number;
  snapToMinScale?: boolean;
  postProcessBuckets?: PostProcessBuckets;
}) => {
  const postProcessBucketsRef = useRef<PostProcessBuckets>();
  postProcessBucketsRef.current = postProcessBuckets;

  return useMemo(() => {
    return createBuckets({
      min,
      max,
      stepCount,
      includeZeroThreashold,
      ensureExceedMax,
      minScale,
      snapToMinScale,
      postProcessBuckets: postProcessBucketsRef.current,
    });
  }, [
    min,
    max,
    stepCount,
    includeZeroThreashold,
    ensureExceedMax,
    minScale,
    snapToMinScale,
  ]);
};

export { useTelemetryBuckets };

export default useBuckets;

type CreateBucketsProps = Parameters<typeof createBuckets>[0];
type Buckets = ReturnType<typeof createBuckets>;
export type { PostProcessBuckets, CreateBucketsProps, Buckets };
