import UnitValue from "atomic-components/atoms/UnitValue";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import HorizontalStackedChart from "atomic-components/organisms/HorizontalStackedChart";
import getPercent from "helpers/getPercent";
import useBuildingData from "modules/building/hooks/useBuildingData";
import { useRefrigerantEmissions } from "modules/building/hooks/useRefrigerantEmissions";
import { useTranslate } from "modules/language";

const TotalEquivalentWarmingImpact = () => {
  const t = useTranslate();
  const {
    buildingData: { unitsByName },
  } = useBuildingData();

  const { tewiTotal, tewiR22, tewiR410A, tewiR407C, Notifier } =
    useRefrigerantEmissions();

  return (
    <Notifier>
      <WidgetContainer>
        <KeyValue
          isLight
          keyText={t("label.TOTAL_EQUIVALENT_WARMING_IMPACT")}
          value={<UnitValue value={tewiTotal} unit={unitsByName.kgco2e} />}
        />
      </WidgetContainer>
      <HorizontalStackedChart
        hasExpandText={false}
        data={[
          {
            key: "tewiR22",
            value: getPercent(tewiR22, tewiTotal),
            legend: (
              <>
                {t("label.REFRIGERANT_TYPE_1")} (
                <UnitValue value={tewiR22} unit={unitsByName.kgco2e} />) (
                <UnitValue
                  value={getPercent(tewiR22, tewiTotal)}
                  unit={unitsByName.percent}
                />
                )
              </>
            ),
          },
          {
            key: "tewiR410A",
            value: getPercent(tewiR410A, tewiTotal),
            legend: (
              <>
                {t("label.REFRIGERANT_TYPE_2")} (
                <UnitValue value={tewiR410A} unit={unitsByName.kgco2e} />) (
                <UnitValue
                  value={getPercent(tewiR410A, tewiTotal)}
                  unit={unitsByName.percent}
                />
                )
              </>
            ),
          },
          {
            key: "tewiR407C",
            value: getPercent(tewiR407C, tewiTotal),
            legend: (
              <>
                {t("label.REFRIGERANT_TYPE_3")} (
                <UnitValue value={tewiR407C} unit={unitsByName.kgco2e} />) (
                <UnitValue
                  value={getPercent(tewiR407C, tewiTotal)}
                  unit={unitsByName.percent}
                />
                )
              </>
            ),
          },
        ]}
        unit={unitsByName.percent}
      />
    </Notifier>
  );
};

export default TotalEquivalentWarmingImpact;
