import { generatePath, type Params } from "react-router-dom";
import { isValidPageUrlEnum, PageUrlEnum } from "router/enums";
import { defaultPage, defaultBuildingUrl } from "whitelabel-config";

type RouteSegments = {
  pageUrl: PageUrlEnum;
  buildingUrl: string;
  floorUrl: string | null;
  zoneUrl: string | null;
};

type SearchParams = { [key in string]: string };

const DEFAULT_LINK_PAGE_URL = defaultPage;
const DEFAULT_LINK_BUILDING_URL = defaultBuildingUrl;
const DEFAULT_LINK_MANAGEMENT_FLOOR_URL = "basement-1";

const getPageRoutePathSegments = ({ pageUrl }: { pageUrl: PageUrlEnum }) =>
  `${pageUrl}/:buildingUrl/:floorUrl?/:zoneUrl?`;
const getPageRoutePath = ({ pageUrl }: { pageUrl: PageUrlEnum }) =>
  `/${getPageRoutePathSegments({ pageUrl })}`;

const normalizeRouteSegments = ({
  pathname,
  params,
}: {
  pathname: string;
  params: Params;
}): RouteSegments => {
  const { buildingUrl, floorUrl, zoneUrl } = params;

  const pageUrl = pathname.split("/")[1];

  const defaultFloor = () =>
    pageUrl === PageUrlEnum.AssetManagementNew
      ? DEFAULT_LINK_MANAGEMENT_FLOOR_URL
      : null;

  return {
    pageUrl: isValidPageUrlEnum(pageUrl) ? pageUrl : DEFAULT_LINK_PAGE_URL,
    buildingUrl: buildingUrl || DEFAULT_LINK_BUILDING_URL,
    floorUrl: floorUrl || defaultFloor(),
    zoneUrl: zoneUrl || null,
  };
};

const createLink = ({
  segments,
  keepSearchParams,
  keepHash,
  searchParams,
}: {
  segments: RouteSegments;
  keepSearchParams: boolean;
  keepHash: boolean;
  searchParams?: SearchParams;
}) => {
  const { pageUrl, ...params } = segments;
  const path = generatePath(getPageRoutePath({ pageUrl }), params);
  const { search, hash } = window.location;

  let urlSearchParamsObj = keepSearchParams
    ? Object.fromEntries(new URLSearchParams(search).entries())
    : undefined;

  if (searchParams) {
    urlSearchParamsObj = { ...urlSearchParamsObj, ...searchParams };
  }

  const searchStr = urlSearchParamsObj
    ? `?${new URLSearchParams(urlSearchParamsObj).toString()}`
    : "";

  return `${path}${searchStr}${keepHash ? hash : ""}`;
};

export { getPageRoutePathSegments, normalizeRouteSegments, createLink };
export type { RouteSegments, SearchParams };
