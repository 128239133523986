import Section from "atomic-components/atoms/Section";
import SectionTitle from "atomic-components/atoms/SectionTitle";
import { useTranslate } from "modules/language";
import { emissionSources } from "modules/building/pages/SustainabilityPage/utils";
import Accordion from "atomic-components/atoms/Accordion";
import SingleSource from "./SingleSource/SingleSource";
import { useTotalEmissions } from "modules/building/hooks/useTotalEmissions";
import getRatio from "helpers/getRatio";

const BreakdownBySource = () => {
  const t = useTranslate();
  const { emissionsByKey, maxEmissions } = useTotalEmissions();

  return (
    <Section>
      <SectionTitle>{t("title.BREAKDOWN_BY_SOURCE")}</SectionTitle>
      <Accordion>
        {emissionSources.map((source) => {
          /* tech-1448 */
          if (source.key === "consumables") {
            return null;
          }
          /* tech-1448 */

          const emissionsValue = emissionsByKey[source.key];
          const emissionPercentValue =
            getRatio(emissionsValue, maxEmissions) ?? NaN;

          return (
            <SingleSource
              key={source.key}
              emissionValue={emissionsValue}
              emissionPercentValue={emissionPercentValue}
              source={source}
            />
          );
        })}
      </Accordion>
    </Section>
  );
};

export default BreakdownBySource;
