import withIconProps from "helpers/withIconProps";

const Plus = withIconProps(({ color, ...props }) => {
  const stroke = color ?? "currentcolor";
  return (
    <svg viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8.25 3C8.52614 3 8.75 3.22386 8.75 3.5V7.25H12.5C12.7761 7.25 13 7.47386 13 7.75V8.25C13 8.52614 12.7761 8.75 12.5 8.75H8.75V12.5C8.75 12.7761 8.52614 13 8.25 13H7.75C7.47386 13 7.25 12.7761 7.25 12.5V8.75H3.5C3.22386 8.75 3 8.52614 3 8.25V7.75C3 7.47386 3.22386 7.25 3.5 7.25H7.25V3.5C7.25 3.22386 7.47386 3 7.75 3H8.25Z"
        fill={stroke}
      />
    </svg>
  );
});

export default Plus;
