import { useTranslate } from "modules/language";
import type { ReactNode } from "react";
import Model from "../Model/Model";
import useBuildingData from "modules/building/hooks/useBuildingData";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import EntityTypesHider from "../Model/components/EntityTypesHider";
import RightPanel from "../RightPanel/RightPanel";
import BottomPanel, { type BottomPanelProps } from "../BottomPanel/BottomPanel";
import { usePageState } from "./PageProvider/PageProvider";
import { MainViewProvider } from "../../providers/ViewProvider/ViewProvider";
import FenceEntity from "../Model/components/FenceEntity";
import CenterCameraOnEntity from "../Model/components/CenterCameraOnEntity";
import ColorizeEntity from "../Model/components/ColorizeEntity";
import TextureHeatmap from "../Model/components/TextureHeatmap";
import Heatmap from "../Model/components/Heatmap";
import ColorizeEntities from "../Model/components/ColorizeEntities";
import PageHeader, { PageHeaderProps } from "../PageHeader/PageHeader";

import s from "./ModelPageLayout.module.scss";

type ModelPageLayoutProps<BOTTOM_PANEL_KEY extends string> = {
  headerMainTitle: PageHeaderProps["mainTitle"];
  headerContent?: PageHeaderProps["content"];
  rightPanelTitle?: ReactNode;
  bottomPanels: BottomPanelProps<BOTTOM_PANEL_KEY>["bottomPanels"];
  modelContent?: ReactNode;
  className?: string;
};

const zonesEntityTypes = ["floor", "zone", "zone-1", "zone-2", "ahu-zone"];

const ModelPageLayoutComponent = <BOTTOM_PANEL_KEY extends string>({
  headerMainTitle,
  headerContent,
  rightPanelTitle,
  bottomPanels,
  modelContent,
  className,
}: ModelPageLayoutProps<BOTTOM_PANEL_KEY>) => {
  const t = useTranslate();
  const { buildingData } = useBuildingData();
  const selectedEntity = useSelectedEntity(buildingData);

  const { entitiesById } = buildingData;
  const {
    shownRightPanel,
    shownBottomPanel,
    heatmapOptions,
    focusedEntityOptions,
    setFocusedEntityOptions,
    rolledOverEntityId,
    onModelClick,
    hoverPrioritizer,
    entitiesAnnotations,
    entitiesSideAnnotations,
    showAnnotations,
    selectedRightTabKey,
    setSelectedRightTabKey,
    floatingRightPanel,
    FloatingRightPanelTarget,
    onRightMenuScrollChange,
    useRightMenuScrollListener,
    rightPanelTabs,
  } = usePageState();

  const focusedZone = focusedEntityOptions
    ? entitiesById[focusedEntityOptions.entityId]
    : undefined;

  const selectedRightTab = rightPanelTabs.find(
    (tab) => tab.key === selectedRightTabKey
  );

  return (
    <div className={s.container}>
      <section className={s.centerPanel}>
        <PageHeader
          mainTitle={headerMainTitle}
          subTitle={selectedRightTab?.text}
          content={headerContent}
          logo="/img/DarLogo.svg"
        />
        <Model
          className={s.model}
          model={selectedEntity.models?.[0]}
          onClick={onModelClick}
          hoverPrioritizer={hoverPrioritizer}
          entitiesSideAnnotations={
            showAnnotations ? entitiesSideAnnotations?.items : null
          }
          entitiesAnnotations={
            showAnnotations ? entitiesAnnotations?.items : null
          }
        >
          {focusedEntityOptions?.type === "fenced" ? (
            <FenceEntity
              entityId={focusedEntityOptions?.entityId}
              color={0xffffff}
            />
          ) : focusedEntityOptions?.type === "locked" ? (
            <>
              <CenterCameraOnEntity
                entityId={focusedEntityOptions?.entityId}
                mappingName={"default"}
                viewpointName={"default"}
                centerName={"center"}
              />
              <ColorizeEntity
                entityId={focusedEntityOptions?.entityId}
                color={0x0000ff}
                opacity={0.6}
                priority={6}
              />
            </>
          ) : heatmapOptions ? (
            heatmapOptions?.heatmapType === "sensors" ? (
              <TextureHeatmap
                entityType={heatmapOptions.entityType}
                telemetryName={heatmapOptions.telemetryName}
                colorTweener={heatmapOptions.colorTweener}
              />
            ) : heatmapOptions?.heatmapType === "zones" ? (
              <Heatmap
                entityType={heatmapOptions.entityType}
                telemetryName={heatmapOptions.telemetryName}
                colorTweener={heatmapOptions.colorTweener}
              />
            ) : null
          ) : null}
          {Array.isArray(rolledOverEntityId) ? (
            <ColorizeEntities
              entitiesIds={rolledOverEntityId}
              color={0x0000ff}
              opacity={0.6}
              priority={6}
            />
          ) : rolledOverEntityId ? (
            <ColorizeEntity
              entityId={rolledOverEntityId}
              color={0x0000ff}
              opacity={0.6}
              priority={6}
            />
          ) : null}
          {focusedEntityOptions?.entityId ? (
            <div className={s.focusedNote}>
              <span>
                {t("note.LOCKED_TO_ENTITY", { entity: focusedZone?.display })}{" "}
              </span>
              <StrippedButton onClick={() => setFocusedEntityOptions(null)}>
                <FontAwesomeIcon icon={faTimes} />
              </StrippedButton>
            </div>
          ) : null}
          <EntityTypesHider entityTypesToHide={zonesEntityTypes} />
          {modelContent}
        </Model>
        {shownBottomPanel ? (
          <BottomPanel
            bottomPanelConfig={shownBottomPanel}
            bottomPanels={bottomPanels}
          />
        ) : null}
      </section>
      {shownRightPanel && (
        <RightPanel
          className={className}
          title={rightPanelTitle}
          tabs={rightPanelTabs}
          selectedTabKey={selectedRightTabKey}
          setSelectedTabKey={setSelectedRightTabKey}
          floatingRightPanel={floatingRightPanel}
          FloatingRightPanelTarget={FloatingRightPanelTarget}
          onRightMenuScrollChange={onRightMenuScrollChange}
          useRightMenuScrollListener={useRightMenuScrollListener}
        />
      )}
    </div>
  );
};

const ModelPageLayout = <BOTTOM_PANEL_KEY extends string>(
  props: ModelPageLayoutProps<BOTTOM_PANEL_KEY>
) => {
  return (
    <MainViewProvider>
      <ModelPageLayoutComponent {...props} />
    </MainViewProvider>
  );
};

export default ModelPageLayout;
export type { ModelPageLayoutProps };
