import classNames from "classnames";
import type { ComponentType, SVGAttributes } from "react";

type IconProps = SVGAttributes<SVGElement> & {
  iconScale?: number;
  icon?: boolean;
};

const withIconProps = <PROPS extends IconProps = IconProps>(
  Component: ComponentType<SVGAttributes<SVGElement>>
): ComponentType<PROPS> => {
  return ({ iconScale, icon, className, style, ...props }: PROPS) => {
    return (
      <Component
        {...props}
        className={classNames(className, { "para-icon": icon ?? true })}
        style={{
          fontSize: `${(iconScale || 1) * 100}%`,
          ...style,
        }}
      />
    );
  };
};

export default withIconProps;
export type { IconProps };
