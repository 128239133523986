import { useTranslate } from "modules/language";
import s from "./FooterGraphEmissions.module.scss";

type FooterGraphEmissionsProps = {
  scopes: number;
  isDeskScreen: boolean;
};
const FooterGraphEmissions = ({
  scopes,
  isDeskScreen,
}: FooterGraphEmissionsProps) => {
  const t = useTranslate();
  return (
    <div className={s.container}>
      {Array(scopes)
        .fill(0)
        .map((_, index) => (
          <p
            key={`${index}leyendText`}
            style={{
              fontSize: isDeskScreen
                ? "var(--font-size-regular)"
                : "var(--font-size-small)",
            }}
          >
            {t("label.SCOPE")} {index + 1}
          </p>
        ))}
    </div>
  );
};

export default FooterGraphEmissions;
export type { FooterGraphEmissionsProps };
