import React from "react";
import { useModelState } from "../../Model";
import { MultiObjectsColorizer } from "modules/viewer/components/ObjectColorizer/ObjectColorizer";

/**
 * @type {React.NamedExoticComponent<{ entityId?: string; mappingName?: string; color: number | string; opacity?: number; priority?: number }>}
 */
const ColorizeEntity = React.memo(
  ({
    entityId,
    mappingName = "default",
    color,
    opacity = 0.5,
    priority = 0,
  }) => {
    const { getEntityMappingByEntityId } = useModelState();

    const entityMapping = getEntityMappingByEntityId(entityId, mappingName);
    return entityMapping?.objectsIds?.length ? (
      <MultiObjectsColorizer
        objects={entityMapping.objectsIds}
        color={color}
        opacity={opacity}
        priority={priority}
      />
    ) : null;
  }
);

export default ColorizeEntity;
