import type { LabelHTMLAttributes } from "react";
import classNames from "classnames";
import EllipsisContainer from "atomic-components/atoms/EllipsisContainer";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import { Info } from "svg-icons";
import s from "./Key.module.scss";

type KeyProps = LabelHTMLAttributes<HTMLElement> & {
  tag?: "header" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div" | "label";
  hasEllipsis?: boolean;
  isLight?: boolean;
  tooltipMessage?: string;
  tooltipVariant?: "default" | "primary" | "dark" | "card";
  tooltipIconColor?: string;
};

const Key = ({
  tag,
  className,
  children,
  tooltipMessage,
  hasEllipsis = true,
  tooltipVariant = "primary",
  tooltipIconColor = "var(--color-primary)",
  ...props
}: KeyProps) => {
  const KeyTag = tag || "div";

  return (
    <KeyTag className={classNames(s.key, className)} {...props}>
      {hasEllipsis ? (
        <EllipsisContainer>{children}</EllipsisContainer>
      ) : (
        children
      )}
      {!!tooltipMessage && (
        <InfoPopover
          className={s.popoverIcon}
          message={tooltipMessage}
          variant={tooltipVariant}
          placement="top"
        >
          <StrippedButton>
            <Info iconScale={1.2} color={tooltipIconColor} />
          </StrippedButton>
        </InfoPopover>
      )}
    </KeyTag>
  );
};

export default Key;
export type { KeyProps };
