import { SingleEvent } from "../../core/helpers/events";

const state = { show: false, count: 0 };

const showEvent = new SingleEvent();
const hideEvent = new SingleEvent();

export const addOnShowLoaderListener = (listener, once = false) =>
  showEvent.addListener(listener, once);
export const addOnHideLoaderListener = (listener, once) =>
  hideEvent.addListener(listener, once);

export const showLoader = () => {
  state.show = true;
  state.count++;
  if (state.count === 1) {
    showEvent.triggerEvent();
  }
};

export const hideLoader = () => {
  state.count--;
  if (state.count === 0) {
    state.show = false;
    hideEvent.triggerEvent();
  }
};
