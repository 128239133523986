import { useMemo } from "react";

import numeral from "numeral";
import { isFunction, isNumber, isString, repeat } from "lodash";

const defaultFormatter = (num) => numeral(num).format("0,0.00");
export const getFormatter = (format) => {
  if (format == null) {
    return defaultFormatter;
  }
  if (isFunction(format)) {
    return format;
  }
  if (isString(format)) {
    return (num) => numeral(num).format(format);
  } else if (isNumber(format)) {
    format = format > 0 ? "0,0.[" + repeat("0", format) + "]" : "0,0";
    return (num) => numeral(num).format(format);
  }
  return defaultFormatter;
};

const useFormatter = (format) => {
  return useMemo(() => {
    const formatter = getFormatter(format);
    return (value) => {
      if (value == null) {
        return "NA";
      }
      if (isNaN(value)) {
        return "NAN";
      }
      if (isNumber(value)) {
        return formatter(value);
      }
      if (isString(value)) {
        return value;
      }
      return "?";
    };
  }, [format]);
};

export default useFormatter;
