import withIconProps from "helpers/withIconProps";

const DownloadGenericIcon = withIconProps((props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5009 12.5V15.8333C17.5009 16.2754 17.3253 16.6993 17.0127 17.0118C16.7001 17.3244 16.2762 17.5 15.8342 17.5H4.16752C3.72549 17.5 3.30157 17.3244 2.98901 17.0118C2.67645 16.6993 2.50085 16.2754 2.50085 15.8333V12.5M5.83419 8.33333L10.0009 12.5M10.0009 12.5L14.1675 8.33333M10.0009 12.5V2.5"
        stroke="currentColor"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default DownloadGenericIcon;
