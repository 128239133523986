import useEditableParamsMutate from "modules/building/hooks/useEditableParamsMutate";
import EditableParamsContainer from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/editableParams/EditableParamsContainer/EditableParamsContainer";
import { useTranslate } from "modules/language";
import { useState } from "react";
import EditableParamWidget from "../../../../../editableParams/EditableParamWidget/EditableParamWidget";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import { UpdateEditableParamParams } from "modules/building/api/editableParams";
import { validateParamValues } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/utils";
import { ParamName } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/definitions";

const DomesticWaterAssumptions = () => {
  const t = useTranslate();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isHistoryEnabled, setIsHistoryEnabled] = useState(false);
  const { mutateAsync, isLoading } = useEditableParamsMutate();
  const { state, telemetries, Notifier } = useEditableParamsState();
  const handleEditSave = async () => {
    if (!validateParamValues(state, ["domesticWaterEmissionsCF"])) {
      return false;
    }

    const payload: UpdateEditableParamParams = [];

    (["domesticWaterEmissionsCF"] as const).forEach((paramName: ParamName) => {
      if (state[paramName].syncInitialValue()) {
        payload.push({
          timeseriesId: telemetries[paramName].timeseriesId,
          value: state[paramName].value!,
        });
      }
    });

    if (payload.length) {
      await mutateAsync(payload);
    }

    return true;
  };

  return (
    <Notifier>
      <EditableParamsContainer
        title={t("label.ASSUMPTIONS")}
        isEditEnabled={isEditEnabled}
        setIsEditEnabled={setIsEditEnabled}
        isHistoryEnabled={isHistoryEnabled}
        setIsHistoryEnabled={setIsHistoryEnabled}
        onSave={handleEditSave}
      >
        <EditableParamWidget
          isHistoryExpanded
          paramName="domesticWaterEmissionsCF"
          keyText={t("label.DOMESTIC_WATER_EMISSIONS_CONVERSION_FACTOR")}
          historyLabel={t(
            "label.DOMESTIC_WATER_EMISSIONS_CONVERSION_FACTOR_HISTORY"
          )}
          isEditEnabled={isEditEnabled}
          isHistoryEnabled={isHistoryEnabled}
          disabled={isLoading}
          maxValue={100}
          minValue={0}
        />
      </EditableParamsContainer>
    </Notifier>
  );
};

export default DomesticWaterAssumptions;
