import { useTranslate } from "modules/language";
import EditableParamContainer from "../EditableParamContainer/EditableParamContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import EditableParamValue from "../../../../components/EditableParamValue/EditableParamValue";
import { useId, useState } from "react";
import EditableParamHistoryContainer from "../EditableParamHistoryContainer/EditableParamHistoryContainer";
import useEditableParamHistory from "modules/building/hooks/useEditableParamHistory";
import EditableParamHistoryButton from "../EditableParamHistoryButton/EditableParamHistoryButton";
import { useEditableParamsState } from "../../../providers/EditableParamsProvider/EditableParamsProvider";
import s from "./GlobalWarmingingPotentialEditable.module.scss";

type GlobalWarmingPotentialEditableProps = {
  isEditEnabled: boolean;
  isHistoryEnabled: boolean;
  isHistoryExpanded?: boolean;
  disabled: boolean;
};

const GlobalWarmingPotentialEditable = ({
  isEditEnabled,
  isHistoryEnabled,
  isHistoryExpanded: isHistoryExpandedFromProps,
  disabled,
}: GlobalWarmingPotentialEditableProps) => {
  const t = useTranslate();
  const labelElementId = useId();

  const {
    state: {
      globalWarmingPotentialR22,
      globalWarmingPotentialR410a,
      globalWarmingPotentialR407c,
    },
    telemetries,
    loading,
  } = useEditableParamsState();

  const gwpR22LogsResp = useEditableParamHistory(
    {
      timeseriesId: telemetries.globalWarmingPotentialR22.timeseriesId,
    },
    { enabled: isHistoryEnabled }
  );
  const gwpR410LogsResp = useEditableParamHistory(
    {
      timeseriesId: telemetries.globalWarmingPotentialR410a.timeseriesId,
    },
    { enabled: isHistoryEnabled }
  );
  const gwpR407LogsResp = useEditableParamHistory(
    {
      timeseriesId: telemetries.globalWarmingPotentialR407c.timeseriesId,
    },
    { enabled: isHistoryEnabled }
  );

  const [isHistoryExpanded, setIsHistoryExpanded] = useState(
    isHistoryExpandedFromProps
  );

  const [isR410HistoryExpanded, setIsR410HistoryExpanded] = useState(false);

  const [isR407HistoryExpanded, setIsR407HistoryExpanded] = useState(false);

  return (
    <>
      <EditableParamContainer
        isEditEnabled={isEditEnabled}
        onRevert={() => {
          globalWarmingPotentialR22.resetValue();
          globalWarmingPotentialR410a.resetValue();
          globalWarmingPotentialR407c.resetValue();
        }}
      >
        <KeyValue
          keyText={t("label.GLOBAL_WARMING_POTENTIAL")}
          keyTag="label"
          keyProps={{
            id: labelElementId,
          }}
          isLight
          gap="small"
          isVertical
          growValue
          value={
            <>
              <div className={s.container}>
                {t("label.R_22_GWP_EDITABLE")}{" "}
                <EditableParamValue
                  disabled={disabled}
                  labelElementId={labelElementId}
                  isEditEnabled={isEditEnabled}
                  value={globalWarmingPotentialR22.value}
                  setValue={(value) =>
                    globalWarmingPotentialR22.setValue(value)
                  }
                  error={globalWarmingPotentialR22.error}
                  setError={(error) =>
                    globalWarmingPotentialR22.setError(error)
                  }
                  maxValue={100000}
                  minValue={0}
                  units={[{ unit: telemetries.globalWarmingPotentialR22.unit }]}
                  isLoading={loading}
                />
                <EditableParamHistoryButton
                  isEnabled={isHistoryEnabled}
                  isLoading={gwpR22LogsResp.isLoading}
                  hasError={gwpR22LogsResp.isError}
                  isExpanded={!!isHistoryExpanded}
                  setIsExpanded={setIsHistoryExpanded}
                />
              </div>
              {isHistoryEnabled &&
              isHistoryExpanded &&
              !gwpR22LogsResp.isLoading &&
              !gwpR22LogsResp.isError ? (
                <EditableParamHistoryContainer
                  historyLogs={gwpR22LogsResp.data.items}
                  isEditEnabled={isEditEnabled}
                  historyLabel={t("label.R_22_GWP_EDITABLE_HISTORY")}
                  setValue={(value) => {
                    globalWarmingPotentialR22.setValue(value);
                  }}
                  units={[
                    {
                      unit: telemetries.globalWarmingPotentialR22.unit,
                    },
                  ]}
                />
              ) : null}
              <div className={s.container}>
                {t("label.R_410_GWP_EDITABLE")}{" "}
                <EditableParamValue
                  disabled={disabled}
                  labelElementId={labelElementId}
                  isEditEnabled={isEditEnabled}
                  value={globalWarmingPotentialR410a.value}
                  setValue={(value) =>
                    globalWarmingPotentialR410a.setValue(value)
                  }
                  error={globalWarmingPotentialR410a.error}
                  setError={(error) =>
                    globalWarmingPotentialR410a.setError(error)
                  }
                  maxValue={100000}
                  minValue={0}
                  units={[
                    { unit: telemetries.globalWarmingPotentialR410a.unit },
                  ]}
                  isLoading={loading}
                />
                <EditableParamHistoryButton
                  isEnabled={isHistoryEnabled}
                  isLoading={gwpR410LogsResp.isLoading}
                  hasError={gwpR410LogsResp.isError}
                  isExpanded={!!isR410HistoryExpanded}
                  setIsExpanded={setIsR410HistoryExpanded}
                />
              </div>
              {isHistoryEnabled &&
              isR410HistoryExpanded &&
              !gwpR410LogsResp.isLoading &&
              !gwpR410LogsResp.isError ? (
                <EditableParamHistoryContainer
                  historyLogs={gwpR410LogsResp.data.items}
                  isEditEnabled={isEditEnabled}
                  historyLabel={t("label.R_410_GWP_EDITABLE_HISTORY")}
                  setValue={(value) => {
                    globalWarmingPotentialR410a.setValue(value);
                  }}
                  units={[
                    {
                      unit: telemetries.globalWarmingPotentialR410a.unit,
                    },
                  ]}
                />
              ) : null}
              <div className={s.container}>
                {t("label.R_407_GWP_EDITABLE")}{" "}
                <EditableParamValue
                  disabled={disabled}
                  labelElementId={labelElementId}
                  isEditEnabled={isEditEnabled}
                  value={globalWarmingPotentialR407c.value}
                  setValue={(value) =>
                    globalWarmingPotentialR407c.setValue(value)
                  }
                  error={globalWarmingPotentialR407c.error}
                  setError={(error) =>
                    globalWarmingPotentialR407c.setError(error)
                  }
                  maxValue={100000}
                  minValue={0}
                  units={[
                    { unit: telemetries.globalWarmingPotentialR407c.unit },
                  ]}
                  isLoading={loading}
                />
                <EditableParamHistoryButton
                  isEnabled={isHistoryEnabled}
                  isLoading={gwpR407LogsResp.isLoading}
                  hasError={gwpR407LogsResp.isError}
                  isExpanded={!!isR407HistoryExpanded}
                  setIsExpanded={setIsR407HistoryExpanded}
                />
              </div>
              {isHistoryEnabled &&
              isR407HistoryExpanded &&
              !gwpR407LogsResp.isLoading &&
              !gwpR407LogsResp.isError ? (
                <EditableParamHistoryContainer
                  historyLogs={gwpR407LogsResp.data.items}
                  isEditEnabled={isEditEnabled}
                  historyLabel={t("label.R_407_GWP_EDITABLE_HISTORY")}
                  setValue={(value) => {
                    globalWarmingPotentialR407c.setValue(value);
                  }}
                  units={[
                    {
                      unit: telemetries.globalWarmingPotentialR407c.unit,
                    },
                  ]}
                />
              ) : null}
            </>
          }
        />
      </EditableParamContainer>
    </>
  );
};

export default GlobalWarmingPotentialEditable;
