import withIconProps from "helpers/withIconProps";

type Props = {
  fill?: string;
};

const MinusSquare = withIconProps(
  ({ fill = "var(--color-primary)" }: Props) => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.16666 3.33317C3.70643 3.33317 3.33333 3.70627 3.33333 4.1665V15.8332C3.33333 16.2934 3.70643 16.6665 4.16666 16.6665H15.8333C16.2936 16.6665 16.6667 16.2934 16.6667 15.8332V4.1665C16.6667 3.70627 16.2936 3.33317 15.8333 3.33317H4.16666ZM1.66666 4.1665C1.66666 2.78579 2.78595 1.6665 4.16666 1.6665H15.8333C17.214 1.6665 18.3333 2.78579 18.3333 4.1665V15.8332C18.3333 17.2139 17.214 18.3332 15.8333 18.3332H4.16666C2.78595 18.3332 1.66666 17.2139 1.66666 15.8332V4.1665ZM5.83333 9.99984C5.83333 9.5396 6.20643 9.1665 6.66666 9.1665H13.3333C13.7936 9.1665 14.1667 9.5396 14.1667 9.99984C14.1667 10.4601 13.7936 10.8332 13.3333 10.8332H6.66666C6.20643 10.8332 5.83333 10.4601 5.83333 9.99984Z"
          fill={fill}
        />
      </svg>
    );
  }
);

export default MinusSquare;
