import classNames from "classnames";
import { ReactNode, forwardRef } from "react";
import type { ButtonHTMLAttributes, ReactComponentElement } from "react";

import s from "./ActivityIndicatorButton.module.scss";

type ActivityIndicatorButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isActive: boolean;
  icon?: (props: { fill: string }) => ReactComponentElement<"svg">;
  children: ReactNode;
  activeClassName?: string;
};

const ActivityIndicatorButton = forwardRef<
  HTMLButtonElement,
  ActivityIndicatorButtonProps
>(
  (
    {
      className,
      activeClassName = "",
      isActive,
      icon: Icon,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <button
        className={classNames(
          s.button,
          {
            [s.buttonActive]: isActive,
            [activeClassName]: isActive,
            [s.buttonInactive]: !isActive,
          },
          className
        )}
        ref={ref}
        {...props}
      >
        {Icon ? (
          <Icon
            fill={
              isActive
                ? "var(--background-color)"
                : "var(--color-primary-light)"
            }
          />
        ) : null}
        {children}
      </button>
    );
  }
);

export default ActivityIndicatorButton;
export type { ActivityIndicatorButtonProps };
