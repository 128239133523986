import { useTranslate } from "modules/language";
import LabelByRefrigerantTypes from "../LabelByRefrigerantTypes/LabelByRefrigerantTypes";
import useBuildingData from "modules/building/hooks/useBuildingData";

const DirectExpansionUnits = () => {
  const t = useTranslate();
  const {
    buildingData: {
      constants: { refrigerants },
    },
  } = useBuildingData();

  const { dxUnitCountR22, dxUnitCountR410A, dxUnitCountR407C } = refrigerants;

  return (
    <LabelByRefrigerantTypes
      label={t("label.DIRECT_EXPANSION_UNITS_NUMBERS")}
      values={[dxUnitCountR22, dxUnitCountR410A, dxUnitCountR407C]}
      link={"/assets/Freon_Direct_Units.pdf"}
    />
  );
};

export default DirectExpansionUnits;
