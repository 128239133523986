import classNames from "classnames";
import RightMainTabs, {
  type RightMainTabsProps,
} from "./RightMainTabs/RightMainTabs";
import { offset, useFloating } from "@floating-ui/react-dom";
import { PopoverPortal } from "atomic-components/atoms/InfoPopover";
import { useWindowSize } from "modules/core/services/window-size.service";
import { type ComponentType, useRef, type ReactNode } from "react";
import type { TargetComponent } from "hooks/usePortal";

import s from "./RightPanel.module.scss";

type FloatingRightPanel<SHOWN_TYPE = ComponentType> = {
  panelId?: string;
  shown: SHOWN_TYPE;
};

type ScrollListener = (params: { scrollTop: number }) => void;

type RightPanelProps<
  TAB_KEY extends string,
  SHOWN_TYPE
> = RightMainTabsProps<TAB_KEY> & {
  title?: ReactNode;
  floatingRightPanel: FloatingRightPanel<SHOWN_TYPE> | null;
  FloatingRightPanelTarget: TargetComponent;
  onRightMenuScrollChange: ScrollListener;
  useRightMenuScrollListener: (effectFunction: ScrollListener) => void;
  className?: string;
};

const RightPanel = <TAB_KEY extends string, SHOWN_TYPE>({
  title,
  tabs,
  selectedTabKey,
  setSelectedTabKey,
  floatingRightPanel,
  FloatingRightPanelTarget,
  onRightMenuScrollChange,
  useRightMenuScrollListener,
  className,
}: RightPanelProps<TAB_KEY, SHOWN_TYPE>) => {
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  useRightMenuScrollListener(({ scrollTop }) => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollTop;
    }
  });

  const { refs, x, y, strategy } = useFloating({
    placement: "left-start",
    middleware: [offset(5)],
  });
  const { height } = useWindowSize();
  const maxHeight = 2 * height - y;

  return (
    <section className={s.rightPanel}>
      <div className={s.rightPanelBorder} />
      {floatingRightPanel ? (
        <PopoverPortal>
          <FloatingRightPanelTarget
            ref={refs.setFloating}
            className={s.floatingRightPanel}
            style={{
              position: strategy,
              top: y ?? "",
              left: x ?? "",
              maxHeight,
            }}
          />
        </PopoverPortal>
      ) : null}
      {title ? <div className={s.rightPanelTitle}>{title}</div> : null}
      <div
        className={classNames(s.rightPanelContent, className)}
        ref={(element) => {
          refs.setReference(element);
          scrollableRef.current = element;
        }}
        onScroll={(e) =>
          onRightMenuScrollChange({ scrollTop: e.currentTarget.scrollTop })
        }
      >
        <RightMainTabs
          tabs={tabs}
          selectedTabKey={selectedTabKey}
          setSelectedTabKey={setSelectedTabKey}
        />
      </div>
    </section>
  );
};

export default RightPanel;
export type { RightPanelProps, FloatingRightPanel, ScrollListener };
