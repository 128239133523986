import withIconProps from "helpers/withIconProps";

const SpaceUtilizationIcon = withIconProps((props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="spaceutilization">
        <g id="Group 1000003303">
          <circle
            id="Ellipse 11"
            cx="5.87097"
            cy="6.87097"
            r="3.87097"
            fill="currentcolor"
          />
          <rect
            id="Rectangle 69"
            x="12.9677"
            y="5.58064"
            width="9.03226"
            height="2.58065"
            fill="currentcolor"
          />
          <rect
            id="Rectangle 70"
            x="12.9677"
            y="17.1935"
            width="9.03226"
            height="2.58065"
            fill="currentcolor"
          />
          <path
            id="Vector"
            d="M7.95217 15.8765L7.95212 15.8764L7.94575 15.8828L5.68482 18.1444L5.33156 18.4978L5.68471 18.8513L7.93569 21.1045C7.94523 21.1163 7.95037 21.1311 7.9501 21.1463C7.9498 21.163 7.94303 21.179 7.93122 21.1908C7.91941 21.2026 7.90347 21.2094 7.88677 21.2097C7.87156 21.2099 7.8568 21.2048 7.84505 21.1953L5.59275 18.9422L5.23931 18.5887L4.8857 18.9421L2.63176 21.1945C2.62001 21.204 2.60523 21.2092 2.59 21.2089C2.57329 21.2086 2.55735 21.2018 2.54554 21.19C2.53373 21.1782 2.52696 21.1623 2.52667 21.1456C2.5264 21.1304 2.53152 21.1156 2.54104 21.1038L4.79339 18.8515L5.14676 18.4981L4.79356 18.1446L2.53339 15.8821L2.52707 15.8758L2.52052 15.8697C2.51419 15.8638 2.50911 15.8567 2.50558 15.8488C2.50206 15.8409 2.50016 15.8323 2.50001 15.8237C2.49986 15.815 2.50145 15.8064 2.50469 15.7984C2.50794 15.7904 2.51276 15.7831 2.51889 15.7769C2.52501 15.7708 2.5323 15.766 2.54033 15.7627C2.54836 15.7595 2.55696 15.7579 2.56562 15.7581C2.57427 15.7582 2.58281 15.7601 2.59072 15.7636C2.59863 15.7672 2.60575 15.7722 2.61165 15.7786L2.61767 15.785L2.6239 15.7913L4.88558 18.0529L5.23908 18.4064L5.59263 18.053L7.85506 15.7913L7.85511 15.7914L7.86104 15.7852C7.86699 15.7791 7.87411 15.7742 7.88198 15.7708C7.88985 15.7674 7.89831 15.7657 7.90687 15.7656C7.91543 15.7655 7.92392 15.7672 7.93184 15.7704L8.12037 15.3102L7.93184 15.7704C7.93977 15.7736 7.94696 15.7784 7.95301 15.7845C7.95906 15.7905 7.96385 15.7977 7.96709 15.8057C7.97033 15.8136 7.97195 15.8221 7.97187 15.8307C7.9718 15.8392 7.97001 15.8477 7.96663 15.8555L8.42594 16.0531L7.96663 15.8555C7.96325 15.8634 7.95833 15.8705 7.95217 15.8765Z"
            fill="currentcolor"
            stroke="currentcolor"
          />
        </g>
      </g>
    </svg>
  );
});

export default SpaceUtilizationIcon;
