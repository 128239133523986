import { normalizeRouteSegments, type RouteSegments } from "router/utils";
import { useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";

const useRouteSegments = (): RouteSegments => {
  const { buildingUrl, floorUrl, zoneUrl } = useParams();
  const { pathname } = useLocation();

  return useMemo(
    () =>
      normalizeRouteSegments({
        pathname,
        params: { buildingUrl, floorUrl, zoneUrl },
      }),
    [buildingUrl, floorUrl, pathname, zoneUrl]
  );
};

export default useRouteSegments;
