import {
  type MouseEvent as ReactMouseEvent,
  useState,
  type ReactNode,
  useId,
} from "react";
import type { Nullable } from "types/utils";
import UnitValue from "../UnitValue";
import isRealNumber from "helpers/isRealNumber";
import { Popover } from "atomic-components/atoms/InfoPopover/InfoPopover";
import getPercent from "helpers/getPercent";
import type { Unit } from "types/unit";
import { STACK_COLORS } from "atomic-components/helpers/stacks";

import s from "./HorizontalStacks.module.scss";

type Stack = {
  value: Nullable<number>;
  color?: string;
  tooltip: ReactNode;
  legend?: ReactNode;
};

type HorizontalStacksProps = {
  stacks: Stack[];
  unit?: Unit;
  displayValue?: Boolean;
};

const HorizontalStacks = ({
  stacks,
  unit,
  displayValue,
}: HorizontalStacksProps) => {
  const elementId = useId();
  const [shownTooltip, setShownTooltip] = useState<{
    display: ReactNode;
    anchorElement: HTMLElement;
  } | null>(null);

  const value = stacks.reduce<number | null>((acc, { value }) => {
    if (!isRealNumber(acc) || !isRealNumber(value)) {
      return null;
    }

    return acc + value;
  }, 0);

  const handleMouseEnter = (
    e: ReactMouseEvent<HTMLSpanElement, MouseEvent>,
    tooltip: ReactNode
  ) => {
    const anchorElement = e.target as HTMLElement;

    setShownTooltip({
      display: tooltip,
      anchorElement,
    });
  };

  const getLegendElementId = (index: number) => {
    return `${elementId}-legend-${index}`;
  };

  const hasLegends = stacks.find(({ legend }) => !!legend);

  return (
    <div className={s.container}>
      <div className={s.stacksWithValueContainer}>
        <div className={s.stacksContainer}>
          {isRealNumber(value)
            ? stacks.map((stack, index) => {
                const width = getPercent(stack.value, value);

                return (
                  <span
                    key={index}
                    aria-labelledby={
                      stack.legend ? getLegendElementId(index) : undefined
                    }
                    onMouseEnter={(e) => handleMouseEnter(e, stack.tooltip)}
                    onMouseLeave={() => setShownTooltip(null)}
                    style={{
                      width: width ? `${width}%` : undefined,
                      backgroundColor: stack.color ?? STACK_COLORS[index],
                    }}
                  ></span>
                );
              })
            : null}
        </div>
        {displayValue ?? true ? (
          <span>
            <UnitValue value={isRealNumber(value) ? value : null} unit={unit} />
          </span>
        ) : null}
        {shownTooltip ? (
          <Popover
            anchorElement={shownTooltip.anchorElement}
            placement="top"
            offset={5}
            className={s.tooltip}
          >
            {shownTooltip.display}
          </Popover>
        ) : null}
      </div>
      {hasLegends
        ? stacks.map(({ legend, color }, index) => {
            if (!legend) {
              return null;
            }
            return (
              <div id={getLegendElementId(index)} className={s.legend}>
                <span
                  className={s.legendBullet}
                  style={{
                    backgroundColor: color ?? STACK_COLORS[index],
                  }}
                />
                <div>{legend}</div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default HorizontalStacks;
export type { HorizontalStacksProps };
