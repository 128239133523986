import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const SEARCH_PARAM_KEY_TAB_KEY = "tabKey";

const useRightTabKey = ({
  rightMainTabKeys,
}: {
  rightMainTabKeys: string[];
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const keyFromSearchParams = searchParams.get(SEARCH_PARAM_KEY_TAB_KEY);
  const selectedRightTabKey =
    keyFromSearchParams && rightMainTabKeys.includes(keyFromSearchParams)
      ? keyFromSearchParams
      : rightMainTabKeys[0];

  const setSelectedRightTabKey = useCallback((key: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(SEARCH_PARAM_KEY_TAB_KEY, key);

    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    selectedRightTabKey,
    setSelectedRightTabKey,
  };
};

export default useRightTabKey;
export { SEARCH_PARAM_KEY_TAB_KEY };
