import classNames from "classnames";
import type { SVGAttributes } from "react";

type UploadFileIconProps = SVGAttributes<SVGElement> & {
  icon?: boolean;
  iconScale?: number;
  color?: string;
  opacity?: string;
};

const UploadFileIcon = ({
  icon = true,
  iconScale = 1,
  color = "#1F2937",
  opacity = "0.3",
  className,
  ...props
}: UploadFileIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, { "para-icon": icon })}
      style={{
        transform: `scale(${iconScale})`,
      }}
      {...props}
    >
      <g opacity={opacity}>
        <path
          d="M3.99985 14.899C3.25689 14.1399 2.69642 13.2217 2.36089 12.214C2.02535 11.2062 1.92356 10.1353 2.06321 9.08232C2.20287 8.02938 2.58031 7.02202 3.16695 6.13655C3.75358 5.25109 4.53403 4.51074 5.44918 3.97157C6.36433 3.43241 7.39018 3.10857 8.44902 3.0246C9.50786 2.94062 10.5719 3.09871 11.5606 3.48688C12.5493 3.87505 13.4367 4.48313 14.1556 5.26506C14.8745 6.04698 15.406 6.98225 15.7099 8.00002H17.4999C18.4654 7.99991 19.4053 8.31034 20.1808 8.88546C20.9564 9.46058 21.5264 10.2699 21.8066 11.1938C22.0869 12.1178 22.0625 13.1074 21.7372 14.0164C21.4118 14.9254 20.8027 15.7057 19.9999 16.242M11.9999 12V21M11.9999 12L15.9999 16M11.9999 12L7.99988 16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default UploadFileIcon;
