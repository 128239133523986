import withIconProps from "helpers/withIconProps";
import type { SVGAttributes } from "react";

type Props = SVGAttributes<SVGElement> & {
  isHigh?: boolean;
};

const NoiseIcon = withIconProps(
  ({ color, fill: passedFill, isHigh, ...props }: Props) => {
    const currentColor = color ?? "currentcolor";

    const fill =
      passedFill ||
      (isHigh ? "var(--color-red-pale)" : "var(--color-green-dark)");

    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
          fill={fill}
        />
        <rect x="10" y="11" width="2" height="10" rx="1" fill={currentColor} />
        <rect x="5" y="13" width="2" height="6" rx="1" fill={currentColor} />
        <rect x="25" y="13" width="2" height="6" rx="1" fill={currentColor} />
        <rect x="15" y="8" width="2" height="16" rx="1" fill={currentColor} />
        <rect x="20" y="11" width="2" height="10" rx="1" fill={currentColor} />
      </svg>
    );
  }
);

export default NoiseIcon;
