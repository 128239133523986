import { PageUrlEnum } from "router/enums";

const config = {
  name: "AUB",
  logoLink: "aub.svg",
  defaultPage: PageUrlEnum.PortfolioManagement,
  hasLocalization: false,
  enabledPages: [PageUrlEnum.PortfolioManagement],
  defaultBuildingUrl: "engineering-faculty-aub",
  headerLogoLink: "aubFullWhite.svg",
  loginBackgroundLink: "aubBackground.png",
};

export default config;
