import type { PaginatedResponseData } from "./types";
import { beApiClient } from "./clients";
import { clearCache } from "../helpers/timeseriesLoader";

type EditableParamHistoryLog = {
  timeseriesId: number;
  fromTimestamp: number;
  actionTimestamp: number;
  userId: string;
  value: number;
};

type GetEditableParamHistoryParams = {
  timeseriesId: number;
  page?: number;
  itemsPerPage?: number;
  signal?: AbortSignal;
};

type UpdateEditableParamParams = {
  timeseriesId: number;
  value: number;
  fromTimestamp?: number;
}[];

const getEditableParamHistory = ({
  timeseriesId,
  page = 1,
  itemsPerPage = 30,
  signal,
}: GetEditableParamHistoryParams) => {
  return beApiClient
    .request<PaginatedResponseData<EditableParamHistoryLog>>({
      url: `/api/editable-param/${timeseriesId}/log?page=${page}&itemsPerPage=${itemsPerPage}`,
      method: "get",
      signal,
    })
    .then((resp) => {
      return resp.data;
    });
};

const updateEditableParam = (editedParams: UpdateEditableParamParams) => {
  return beApiClient
    .request({
      url: `/api/editable-param`,
      method: "post",
      data: editedParams,
    })
    .then(() => {
      clearCache();
    });
};

export { getEditableParamHistory, updateEditableParam };
export type {
  EditableParamHistoryLog,
  GetEditableParamHistoryParams,
  UpdateEditableParamParams,
};
