import { useMemo } from "react";

export const getFormat = (sampleNumber) => {
  if (
    sampleNumber == null ||
    isNaN(sampleNumber) ||
    !isFinite(sampleNumber) ||
    typeof sampleNumber !== "number"
  ) {
    return 0;
  }
  let n = Math.abs(sampleNumber);
  if (n >= 100) {
    return 0;
  }
  if (n > 10) {
    return 1;
  }
  if (n >= 1) {
    return 2;
  }
  if (n === 0) {
    return 0;
  }
  let scale = 0;
  let i = 0;
  for (; i < 100 && n < 1; i++) {
    n *= 10;
    scale++;
  }
  if (i === 100) {
    console.log({ sampleNumber });
    debugger;
    throw new Error(
      `Could not get suitable format for number ${sampleNumber}`,
      { sampleNumber, sampleNumberType: typeof sampleNumber }
    );
  }
  return scale + getFormat(n);
};

/**
 * @param {number} sampleNumber
 * @returns {number}
 */
const useFormat = (sampleNumber) =>
  useMemo(() => getFormat(sampleNumber), [sampleNumber]);

export default useFormat;
