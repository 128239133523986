const config = {
  isProd: true,
  reCaptcha: {
    scriptSrcURL: "https://www.google.com/recaptcha/api.js",
    challengeSolutionTimeout: 5 * 60 * 1000,
    executeTimeout: 5000,
    tokenExpiryTimeout: 1.125 * 60 * 1000,
  },
  layout: {
    breakPoints: [
      {
        name: "mobile",
        upperScale: 120,
        sideWidth: 50,
        canShowTop: true,
        canShowSide: true,
        sideFixed: false,
        topHeight: 45,
        topSubHeight: 56,
      },
      {
        name: "desktop",
        lowerWidth: 1200,
        lowerScale: 55,
        sideWidth: 50,
        topOpen: false,
        canShowTop: false,
        canShowSide: true,
        sideFixed: true,
        topSubHeight: 56,
      },
    ],
  },
  api: {
    baseUrl: "",
  },
};
export default config;
