import { type ReactNode, useState } from "react";
import { useTranslate } from "modules/language";
import EditableParamsContainer from "../../../../../editableParams/EditableParamsContainer/EditableParamsContainer";
import useEditableParamsMutate from "modules/building/hooks/useEditableParamsMutate";
import Section from "atomic-components/atoms/Section";
import Key from "atomic-components/atoms/Key";
import TransportDistributionEditable from "../../../../../editableParams/TransportDistributionEditable/TransportDistributionEditable";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import EditableParamWidget from "../../../../../editableParams/EditableParamWidget/EditableParamWidget";
import type { UpdateEditableParamParams } from "modules/building/api/editableParams";
import { validateParamValues } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/utils";
import type { ParamName } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/definitions";

const TravelAssumptions = () => {
  const t = useTranslate();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isHistoryEnabled, setIsHistoryEnabled] = useState(false);
  const [fieldsetError, setFieldsetError] = useState<ReactNode>(null);

  const { state, telemetries, Notifier } = useEditableParamsState();

  const { mutateAsync, isLoading } = useEditableParamsMutate();

  const handleEditSave = async () => {
    if (
      fieldsetError ||
      !validateParamValues(state, [
        "carsTransportDistribution",
        "busesTransportDistribution",
        "carpoolingTransportDistribution",
        "avgEmployeeKmTraveled",
        "avgEmployeeCarsFuelEconomy",
        "busEmissionPerEmployee",
        "avgEmployeeOccupantsPerBus",
        "avgCompanyCarsFuelEconomy",
        "avgCompanyBusesFuelEconomy",
      ])
    ) {
      return false;
    }

    const {
      carsTransportDistribution,
      busesTransportDistribution,
      carpoolingTransportDistribution,
    } = state;

    const payload: UpdateEditableParamParams = [];

    if (
      carsTransportDistribution.syncInitialValue() ||
      busesTransportDistribution.syncInitialValue() ||
      carpoolingTransportDistribution.syncInitialValue()
    ) {
      payload.push(
        {
          timeseriesId: telemetries.carsTransportDistribution.timeseriesId,
          value: carsTransportDistribution.value,
        },
        {
          timeseriesId: telemetries.busesTransportDistribution.timeseriesId,
          value: busesTransportDistribution.value,
        },
        {
          timeseriesId:
            telemetries.carpoolingTransportDistribution.timeseriesId,
          value: carpoolingTransportDistribution.value,
        }
      );
    }

    (
      [
        "avgEmployeeKmTraveled",
        "avgEmployeeCarsFuelEconomy",
        "busEmissionPerEmployee",
        "avgEmployeeOccupantsPerBus",
        "avgCompanyCarsFuelEconomy",
        "avgCompanyBusesFuelEconomy",
      ] as const
    ).forEach((paramName: ParamName) => {
      if (state[paramName].syncInitialValue()) {
        payload.push({
          timeseriesId: telemetries[paramName].timeseriesId,
          value: state[paramName].value!,
        });
      }
    });

    if (payload.length) {
      await mutateAsync(payload);
    }

    return true;
  };

  return (
    <Notifier>
      <EditableParamsContainer
        title={t("label.ASSUMPTIONS")}
        isEditEnabled={isEditEnabled}
        setIsEditEnabled={setIsEditEnabled}
        isHistoryEnabled={isHistoryEnabled}
        setIsHistoryEnabled={setIsHistoryEnabled}
        onSave={handleEditSave}
      >
        <Section>
          <Key tag="h5" isLight>
            {t("label.DAILY_EMPLOYEE_COMMUTE")}
          </Key>
          <TransportDistributionEditable
            isEditEnabled={isEditEnabled}
            isHistoryEnabled={isHistoryEnabled}
            isHistoryExpanded
            disabled={isLoading}
            fieldsetError={fieldsetError}
            setFieldsetError={setFieldsetError}
          />
          <EditableParamWidget
            paramName="avgEmployeeKmTraveled"
            keyText={t("label.AVG_KM_TRAVELED")}
            historyLabel={t("label.AVG_KM_TRAVELED_HISTORY")}
            isEditEnabled={isEditEnabled}
            isHistoryEnabled={isHistoryEnabled}
            disabled={isLoading}
            maxValue={100}
            minValue={1}
          />
          <EditableParamWidget
            paramName="avgEmployeeCarsFuelEconomy"
            keyText={t("label.AVG_FUEL_ECONOMY_(CARS)")}
            historyLabel={t("label.AVG_FUEL_ECONOMY_(CARS)_HISTORY")}
            isEditEnabled={isEditEnabled}
            isHistoryEnabled={isHistoryEnabled}
            disabled={isLoading}
            maxValue={100}
            minValue={0.1}
          />
          <EditableParamWidget
            paramName="busEmissionPerEmployee"
            keyText={t("label.EMISSIONS_PER_PASSENGER_KM_(BUSES)")}
            historyLabel={t("label.EMISSIONS_PER_PASSENGER_KM_(BUSES)_HISTORY")}
            isEditEnabled={isEditEnabled}
            isHistoryEnabled={isHistoryEnabled}
            disabled={isLoading}
            maxValue={100}
            minValue={0.01}
          />
        </Section>
        <Section>
          <Key tag="h5" isLight>
            {t("label.COMPANY_OWNED_VEHICLES")}
          </Key>
          <EditableParamWidget
            paramName="avgCompanyCarsFuelEconomy"
            keyText={t("label.AVG_FUEL_ECONOMY_(CARS)")}
            historyLabel={t("label.AVG_FUEL_ECONOMY_(CARS)_HISTORY")}
            isEditEnabled={isEditEnabled}
            isHistoryEnabled={isHistoryEnabled}
            disabled={isLoading}
            maxValue={100}
            minValue={0.1}
          />
          <EditableParamWidget
            paramName="avgCompanyBusesFuelEconomy"
            keyText={t("label.AVG_FUEL_ECONOMY_(BUSES)")}
            historyLabel={t("label.AVG_FUEL_ECONOMY_(BUSES)_HISTORY")}
            isEditEnabled={isEditEnabled}
            isHistoryEnabled={isHistoryEnabled}
            disabled={isLoading}
            maxValue={100}
            minValue={0.1}
          />
        </Section>
      </EditableParamsContainer>
    </Notifier>
  );
};

export default TravelAssumptions;
