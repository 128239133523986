import useFormatter from "hooks/useFormatter";
import useFormat from "hooks/useFormat";
import isRealNumber from "helpers/isRealNumber";

/**
 * @param {{value: any, format?: any}} param0
 * @returns {React.ReactNode}
 */
const FormattedValue = ({ value, format: formatFromParent }) => {
  const notANumber = !isRealNumber(value);
  const defaultFormat = useFormat(value);
  const format = formatFromParent ?? defaultFormat;
  const formatter = useFormatter(format);
  return notANumber ? "-" : formatter(value);
};

export default FormattedValue;
