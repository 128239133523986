import { useModalState } from "atomic-components/molecules/Modal";
import { useAuthenticationState } from "modules/authentication/state/authentication.state";
import { type ReactNode, useCallback } from "react";
import ConfirmationModalBody from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ConfirmationModalBody/ConfirmationModalBody";

const useEvaluationWizardModal = ({
  evaluationWizardModalElement,
}: {
  evaluationWizardModalElement: ReactNode;
}) => {
  const { roles } = useAuthenticationState();
  const { setModal } = useModalState();

  const showModal = useCallback(() => {
    const isAdmin = !!roles?.find((role) => role.name === "admin");

    setModal(
      isAdmin ? (
        evaluationWizardModalElement
      ) : (
        <ConfirmationModalBody
          headerMessage="message.ACCESS_NOT_AVAILABLE"
          hintMessage="message.CONTACT_ADMIN"
          hasCancelButton={false}
        />
      ),
      {
        width: "medium",
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, setModal]);

  return {
    showModal,
  };
};

export default useEvaluationWizardModal;
