import useBuildingData from "modules/building/hooks/useBuildingData";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import getRatio from "helpers/getRatio";
import isRealNumber from "helpers/isRealNumber";
import type { Nullable } from "types/utils";

const useRefrigerantEmissions = () => {
  const {
    buildingData: {
      constants: { refrigerants },
    },
  } = useBuildingData();

  const { state, Notifier, loading, error } = useEditableParamsState();

  const {
    coolingCapacityR22,
    coolingCapacityR410A,
    coolingCapacityR407C,
    odpR22,
    odpR410A,
    odpR407C,
  } = refrigerants;

  const endoflifeRefrigerantLossValue = getRatio(
    state.endoflifeRefrigerantLoss.value,
    100
  );
  const lifeTimeDirectExpansionUnitValue =
    state.lifeTimeDirectExpansionUnit.value;
  const refrigerantLeakageRateValue = getRatio(
    state.refrigerantLeakageRate.value,
    100
  );
  const globalWarmingPotentialR22Value = state.globalWarmingPotentialR22.value;
  const globalWarmingPotentialR410AValue =
    state.globalWarmingPotentialR410a.value;
  const globalWarmingPotentialR407CValue =
    state.globalWarmingPotentialR407c.value;
  const refrigerantChargeValue = state.refrigerantMassACUnit.value;

  const computeQ = (
    gwp: Nullable<number>,
    odp: Nullable<number> | string,
    coolingCapacity: Nullable<number> | string
  ) => {
    return isRealNumber(endoflifeRefrigerantLossValue) &&
      isRealNumber(lifeTimeDirectExpansionUnitValue) &&
      isRealNumber(refrigerantLeakageRateValue) &&
      isRealNumber(refrigerantChargeValue) &&
      isRealNumber(gwp) &&
      isRealNumber(odp) &&
      isRealNumber(coolingCapacity)
      ? (((endoflifeRefrigerantLossValue +
          lifeTimeDirectExpansionUnitValue * refrigerantLeakageRateValue) *
          refrigerantChargeValue *
          (gwp + odp * (10 ^ 5))) /
          lifeTimeDirectExpansionUnitValue) *
          coolingCapacity
      : NaN;
  };

  const qTotal =
    isRealNumber(coolingCapacityR22) &&
    isRealNumber(coolingCapacityR410A) &&
    isRealNumber(coolingCapacityR407C)
      ? coolingCapacityR22 + coolingCapacityR410A + coolingCapacityR407C
      : NaN;
  const qR22 = computeQ(
    globalWarmingPotentialR22Value,
    odpR22,
    coolingCapacityR22
  );
  const qR410A = computeQ(
    globalWarmingPotentialR410AValue,
    odpR410A,
    coolingCapacityR410A
  );
  const qR407C = computeQ(
    globalWarmingPotentialR407CValue,
    odpR407C,
    coolingCapacityR407C
  );

  const refrigerantImpact = (qR22 + qR410A + qR407C) / qTotal;

  const computeTEWI = (
    gwp: Nullable<number>,
    coolingCapacity: Nullable<number> | string
  ) => {
    return isRealNumber(gwp) &&
      isRealNumber(refrigerantLeakageRateValue) &&
      isRealNumber(refrigerantChargeValue) &&
      isRealNumber(coolingCapacity)
      ? (gwp *
          refrigerantLeakageRateValue *
          refrigerantChargeValue *
          coolingCapacity) /
          12
      : NaN;
  };

  const tewiR22 = computeTEWI(
    state.globalWarmingPotentialR22.value,
    coolingCapacityR22
  );
  const tewiR410A = computeTEWI(
    state.globalWarmingPotentialR410a.value,
    coolingCapacityR410A
  );
  const tewiR407C = computeTEWI(
    state.globalWarmingPotentialR407c.value,
    coolingCapacityR407C
  );

  const tewiTotal = tewiR22 + tewiR410A + tewiR407C;

  return {
    refrigerantImpact,
    tewiR22,
    tewiR410A,
    tewiR407C,
    tewiTotal,
    Notifier,
    isLoading: loading,
    error,
  };
};

export { useRefrigerantEmissions };
