import { useTranslate } from "modules/language";
import EditableParamContainer from "../EditableParamContainer/EditableParamContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import EditableParamValue from "../../../../components/EditableParamValue/EditableParamValue";
import { useEffect, useId, useState } from "react";
import EditableParamHistoryContainer from "../EditableParamHistoryContainer/EditableParamHistoryContainer";
import EditableParamHistoryButton from "../EditableParamHistoryButton/EditableParamHistoryButton";
import useEditableParamHistory from "modules/building/hooks/useEditableParamHistory";
import { useEditableParamsState } from "../../../providers/EditableParamsProvider/EditableParamsProvider";

type ChillerPlantCOPEditableProps = {
  isEditEnabled: boolean;
  isHistoryEnabled: boolean;
  isHistoryExpanded?: boolean;
  disabled: boolean;
};

const ChillerPlantCOPEditable = ({
  isEditEnabled,
  isHistoryEnabled,
  isHistoryExpanded: isHistoryExpandedFromProps,
  disabled,
}: ChillerPlantCOPEditableProps) => {
  const t = useTranslate();
  const labelElementId = useId();
  const {
    state: { chillerPlantCOP },
    telemetries,
    loading,
  } = useEditableParamsState();
  const historyLogsResp = useEditableParamHistory(
    {
      timeseriesId: telemetries.chillerPlantCOP.timeseriesId,
    },
    { enabled: isHistoryEnabled }
  );
  const [isHistoryExpanded, setIsHistoryExpanded] = useState(
    isHistoryExpandedFromProps
  );

  useEffect(() => {
    setIsHistoryExpanded(isHistoryExpandedFromProps);
  }, [isHistoryExpandedFromProps, isHistoryEnabled]);

  return (
    <>
      <EditableParamContainer
        isEditEnabled={isEditEnabled}
        onRevert={() => chillerPlantCOP.resetValue()}
      >
        <KeyValue
          keyText={t("label.CHILLER_PLANT_COP")}
          keyTag="label"
          keyProps={{
            id: labelElementId,
          }}
          isLight
          value={
            <EditableParamValue
              disabled={disabled}
              labelElementId={labelElementId}
              isEditEnabled={isEditEnabled}
              value={chillerPlantCOP.value}
              setValue={chillerPlantCOP.setValue}
              error={chillerPlantCOP.error}
              setError={chillerPlantCOP.setError}
              maxValue={99.999}
              minValue={0.1}
              isLoading={loading}
              units={[
                {
                  unit: {
                    id: "cop",
                    name: "cop",
                    display: `(${t("unit.COEFFICIENT_OF_PERFORMANCE")})`,
                    isPrefix: false,
                    addSpace: true,
                  },
                },
              ]}
            />
          }
        />
        <EditableParamHistoryButton
          isEnabled={isHistoryEnabled}
          isLoading={historyLogsResp.isLoading}
          hasError={historyLogsResp.isError}
          isExpanded={!!isHistoryExpanded}
          setIsExpanded={setIsHistoryExpanded}
        />
      </EditableParamContainer>
      {isHistoryEnabled &&
      isHistoryExpanded &&
      !historyLogsResp.isLoading &&
      !historyLogsResp.isError ? (
        <EditableParamHistoryContainer
          historyLogs={historyLogsResp.data.items}
          isEditEnabled={isEditEnabled}
          historyLabel={t("label.CHILLER_PLANT_COP_HISTORY")}
          setValue={chillerPlantCOP.setValue}
          units={[]}
        />
      ) : null}
    </>
  );
};

export default ChillerPlantCOPEditable;
