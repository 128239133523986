import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useMemo,
  useRef,
  RefObject,
  useState,
  type MouseEventHandler,
  useEffect,
} from "react";
import { Calendar, type CalendarProps } from "react-calendar";
import classNames from "classnames";
import { autoUpdate, useFloating } from "@floating-ui/react-dom";
import { shift, type Placement } from "@floating-ui/core";
import {
  getCorrespondingDatesFromRange,
  getDayFromTo,
  getMonthOfDayFromTo,
  getYearFromTo,
  isDateInCurrentWeek,
  toJSDate,
} from "modules/building/helpers/intervals";
import useFullDateFilterState from "modules/building/hooks/useFullDateFilterState";
import { PopoverPortal } from "atomic-components/atoms/InfoPopover";
import { fromMillis } from "helpers/dateTimeHelper";
import useClickOutside from "hooks/useClickOutside";
import type { Value } from "react-calendar/dist/cjs/shared/types";
import type { DateTime } from "luxon";
import {
  getEnabledWeekdays,
  isDateWithinRange,
  shortDayToAbbreviationLabel,
} from "./utils";
import { addToast } from "modules/toast";
import { isString } from "lodash";
import { useTranslate } from "modules/language";
import HorizontalDivider from "atomic-components/atoms/HorizontalDivider";
import Button from "atomic-components/atoms/Buttons/Button";

import s from "./DateSelectorPopover.module.scss";

type UseFullDateFilterStateReturn = ReturnType<typeof useFullDateFilterState>;

export type LockFixedRangeType = {
  fromTs: number;
  toTs: number;
  allowRestDay?: boolean;
  allowRangeSelection?: boolean;
};

type DateSelectorPopoverProps = {
  anchorElementRef: RefObject<Element>;
  displayed: boolean;
  setDisplayed: Dispatch<SetStateAction<boolean>>;
  languageCode: string;
  placement?: Placement;
  fromTs: UseFullDateFilterStateReturn["fromTs"];
  toTs: UseFullDateFilterStateReturn["toTs"];
  setDateFilter: UseFullDateFilterStateReturn["setDateFilter"];
  rawDateFilter: UseFullDateFilterStateReturn["rawDateFilter"];
  timezone: UseFullDateFilterStateReturn["timezone"];
  selectRange?: CalendarProps["selectRange"];
  /**
   * fix the calendar selectable range to fixed ranges corresponding to the start and end days provided in the `lockFixedRange`
   * this will do the following:
   * - disallow range selection. On click on any allowed date, it will select its fixed range
   * - disable the dates of the provided `lockFixedRange`
   * - disable all not allowed weekdays (weekdays that not corresponds to `lockFixedRange` weekdays)
   * - disable the max selectable date fixed range if it exceeds to future (disabled) dates and allowRestDay is false
   */
  lockFixedRange?: LockFixedRangeType;
  minDayCountToSelect?: number;
  maxDayCountToSelect?: number | "nolimit";
  onApplyFromParentSelection?: (from: Date, to: Date) => void;
  view?: "month" | "year" | "decade";
} & (
  | {
      showShortcuts: true;
      todayText: string;
      weekText: string;
      monthText: string;
    }
  | {
      showShortcuts?: false;
      todayText?: never;
      weekText?: never;
      monthText?: never;
    }
);

export enum RawDateFilterEnum {
  TODAY = "today",
  WEEK = "week",
  MONTH = "month",
}

const DateSelectorPopover = ({
  anchorElementRef,
  displayed,
  setDisplayed,
  languageCode,
  placement,
  fromTs,
  toTs,
  setDateFilter,
  rawDateFilter,
  timezone,
  selectRange = true,
  lockFixedRange,
  minDayCountToSelect = 1,
  maxDayCountToSelect = 7,
  view = "month",
  showShortcuts,
  todayText,
  weekText,
  monthText,
  onApplyFromParentSelection,
}: DateSelectorPopoverProps) => {
  const t = useTranslate();

  const allowRestDays = !!lockFixedRange?.allowRestDay;
  const anchorElement = anchorElementRef.current;
  const [calendarRangeStart, setCalendarRangeStart] = useState<
    DateTime | undefined
  >(undefined);
  const [defaultActiveStartDate, setDefaultActiveStartDate] = useState<
    Date | undefined
  >(undefined);
  const [highlightedRange, setHighlightedRange] = useState<
    [number, number] | undefined
  >();

  const [dateBeforeApply, setDateBeforeApply] = useState<Value | string | null>(
    null
  );

  const selectRangeEnabled =
    (!lockFixedRange && selectRange) ||
    (lockFixedRange?.allowRangeSelection && selectRange);

  const todayDate = fromMillis(Date.now(), {
    zone: timezone,
    resetTime: true,
  });

  let maxSelectableDateTime = todayDate;

  let getDateFromTo = getDayFromTo;

  switch (view) {
    case "decade":
      maxSelectableDateTime = maxSelectableDateTime.set({
        day: 1,
        month: 12,
      });
      getDateFromTo = getYearFromTo;
      break;
    case "year":
      maxSelectableDateTime = maxSelectableDateTime
        .set({
          day: 1,
        })
        .minus({
          month: 1,
        });
      getDateFromTo = getMonthOfDayFromTo;
      break;
    default:
      maxSelectableDateTime = maxSelectableDateTime.minus({
        day: 1,
      });
  }

  let handleTileDisabled: CalendarProps["tileDisabled"] = undefined;

  if (lockFixedRange) {
    const { fromTs, toTs } = lockFixedRange;
    const enabledWeekDays = getEnabledWeekdays(fromTs, toTs, timezone);
    const firstFutureDateTs = maxSelectableDateTime
      .plus({ day: 1 })
      .set({ hour: 0 })
      .toMillis();
    const maxSelectableDateFixedRange = lockFixedRange
      ? getCorrespondingDatesFromRange({
          reference: maxSelectableDateTime,
          targetRange: lockFixedRange,
          timezone,
        })
      : undefined;

    handleTileDisabled = ({ date }) => {
      const tileDayFromTo = getDayFromTo({
        reference: date,
        timezone,
      });

      const { from: tile, fromTs: tileTs } = tileDayFromTo;

      const maxSelectableDates = () => {
        if (allowRestDays) return false;

        return Boolean(
          maxSelectableDateFixedRange &&
            maxSelectableDateFixedRange.toTs > firstFutureDateTs &&
            isDateWithinRange(
              tileTs,
              maxSelectableDateFixedRange.fromTs,
              maxSelectableDateFixedRange.toTs
            )
        );
      };

      return (
        /**
         * disable dates of locked fixed range
         */
        isDateWithinRange(tileTs, fromTs, toTs) ||
        /**
         * disable weekdays not corresponding to locked fixed range weekdays
         */
        !enabledWeekDays.includes(tile.weekday) ||
        /**
         * disable the maxSelectableDate fixed range if it
         * exceeds to future (disabled) dates
         */
        maxSelectableDates()
      );
    };
  } else if (selectRange) {
    handleTileDisabled = ({ date }) => {
      if (!calendarRangeStart) return false;

      /**
       * on calendarRangeStart is set (user clicked on calendar first range date), disable non-selectable dates
       */
      const tileDayFromTo = getDayFromTo({
        reference: date,
        timezone,
      });

      const { fromTs: tileTs } = tileDayFromTo;

      let allowedRangeStart: number;
      let allowedRangeEnd: number;

      if (maxDayCountToSelect === "nolimit") {
        allowedRangeStart = calendarRangeStart.set({ hour: 0 }).toMillis();

        /**
         * 100 years from now
         */
        allowedRangeEnd = Date.now() + 100 * 365 * 24 * 60 * 60 * 1000;
      } else {
        allowedRangeStart = calendarRangeStart
          .minus({ day: maxDayCountToSelect })
          .plus({ day: 1 })
          .set({ hour: 0 })
          .toMillis();
        allowedRangeEnd = calendarRangeStart
          .plus({ day: maxDayCountToSelect })
          .set({ hour: 0 })
          .toMillis();
      }

      return !isDateWithinRange(tileTs, allowedRangeStart, allowedRangeEnd);
    };
  }

  const {
    x: left,
    y: top,
    refs,
    strategy,
    update,
  } = useFloating({
    middleware: [shift()],
    placement,
    whileElementsMounted: (...args) => {
      const cleanup = autoUpdate(...args, { animationFrame: true });

      /**
       * as this component is mounted via floating-ui so the cleaning-up should be done here
       * as react useEffect won't be called on show/hide of the component
       */
      setCalendarRangeStart(undefined);
      setDefaultActiveStartDate(undefined);
      setHighlightedRange(undefined);

      return cleanup;
    },
  });

  useClickOutside([refs.floating.current, anchorElementRef.current], () =>
    setDisplayed(false)
  );

  const [from, to] = useMemo(() => {
    const from =
      // the displayed "from" date is either the "from" date before apply or the actual date from the "fromTs" prop
      (dateBeforeApply as [Date, Date])?.[0] ||
      toJSDate({
        reference: fromMillis(fromTs, { zone: timezone, resetTime: true }),
      });
    const to =
      // the displayed "to" date is either the "to" date before apply or the actual date from the "toTs" prop
      (dateBeforeApply as [Date, Date])?.[1] ||
      toJSDate({
        reference: fromMillis(toTs, { zone: timezone, resetTime: true }).minus({
          day: 1,
        }),
      });

    return [from, to];
  }, [fromTs, toTs, timezone, dateBeforeApply]);

  useEffect(() => {
    setDateBeforeApply(null);
  }, [displayed]);

  const mutable = useRef<{
    displayed: boolean;
    anchorElement: Element | null;
    floatingElement: Element | null;
  }>({
    displayed,
    anchorElement,
    floatingElement: null,
  }).current;
  refs.setReference(anchorElement);

  update();

  const calendarRef = useCallback((element: HTMLDivElement) => {
    refs.setFloating(element);
    mutable.floatingElement = element;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiallySelectDate = (value: Value | string) => {
    setDateBeforeApply(value);
  };

  const selectDate = (value: Value | string) => {
    if (isString(value)) {
      setDateFilter(value);
    } else {
      let fromTs: number;
      let toTs: number;

      if (Array.isArray(value)) {
        const fromDateFromTo = getDateFromTo({ reference: value[0], timezone });
        const toDateFromTo = getDateFromTo({ reference: value[1], timezone });

        /**
         * if same day is clicked as start and end of selected range and minDayCountToSelect is more than 1
         * - release the range being selected and do nothing (do not set new dates)
         * - set defaultActiveDate as the range start date (to keep user on current month view)
         */
        if (
          minDayCountToSelect > 1 &&
          fromDateFromTo.fromTs === toDateFromTo.fromTs
        ) {
          setCalendarRangeStart(undefined);
          setDefaultActiveStartDate(
            toJSDate({ reference: fromDateFromTo.from })
          );
          return;
        }

        fromTs = fromDateFromTo.fromTs;
        toTs = toDateFromTo.toTs;
      } else if (lockFixedRange) {
        const range = getCorrespondingDatesFromRange({
          reference: value,
          targetRange: lockFixedRange,
          timezone,
        });

        fromTs = range.fromTs;
        toTs = range.toTs;

        /**  If rest of day allowed, if user selects a day whitin the current week
         * the current day minus one day is set as max selectable day
         */
        if (allowRestDays) {
          const selectedDateFromTo = getDateFromTo({
            reference: value,
            timezone,
          });
          const givenDate = selectedDateFromTo.to;
          // Get max day selectable
          const maxToTs = todayDate.minus({ day: 1 }).toMillis();
          toTs = range.toTs > maxToTs ? maxToTs : range.toTs;
          // Check if the given date is within the current week
          if (isDateInCurrentWeek({ reference: givenDate })) {
            toTs = selectedDateFromTo.toTs;
          }
        }
      } else {
        const selectedDateFromTo = getDateFromTo({
          reference: value,
          timezone,
        });
        fromTs = selectedDateFromTo.fromTs;
        toTs = selectedDateFromTo.toTs;
      }

      setDateFilter([fromTs, toTs]);
    }

    setDisplayed(false);
  };

  const onCancelSelection = () => {
    setDisplayed(false);
  };

  const onApplySelection = () => {
    if (onApplyFromParentSelection) {
      onApplyFromParentSelection(from as Date, to as Date);

      return;
    }

    if (dateBeforeApply) {
      selectDate(dateBeforeApply);
    }
  };

  return displayed ? (
    <PopoverPortal>
      <div
        className={s.floatingContainer}
        ref={calendarRef}
        style={{
          position: strategy,
          top: top ?? "",
          left: left ?? "",
        }}
      >
        {showShortcuts && view === "month" ? (
          <div className={s.rawSelectors}>
            <button
              className={classNames(s.rawSelector, {
                [s.rawSelectorSelected]:
                  rawDateFilter === RawDateFilterEnum.TODAY,
              })}
              onClick={() => selectDate(RawDateFilterEnum.TODAY)}
            >
              {todayText}
            </button>
            {/* Disable week button when allowRestDays as it is following the same behavior  */}
            <button
              disabled={allowRestDays}
              className={classNames(s.rawSelector, {
                [s.rawSelectorSelected]:
                  rawDateFilter === RawDateFilterEnum.WEEK,
              })}
              onClick={() => selectDate(RawDateFilterEnum.WEEK)}
            >
              {weekText}
            </button>
            <button
              disabled
              className={classNames(s.rawSelector, {
                [s.rawSelectorSelected]:
                  rawDateFilter === RawDateFilterEnum.MONTH,
              })}
              onClick={() => selectDate(RawDateFilterEnum.MONTH)}
            >
              {monthText}
            </button>
          </div>
        ) : null}
        <Calendar
          defaultView={view}
          defaultActiveStartDate={defaultActiveStartDate}
          className={classNames(s.calendar, {
            [s.calendarWithFixedRange]: lockFixedRange,
          })}
          value={[from, to]}
          onChange={initiallySelectDate}
          locale={languageCode.toLowerCase()}
          maxDate={toJSDate({ reference: maxSelectableDateTime })}
          tileDisabled={handleTileDisabled}
          goToRangeStartOnSelect={false}
          onClickMonth={view === "year" ? selectDate : undefined}
          onClickYear={view === "decade" ? selectDate : undefined}
          selectRange={selectRangeEnabled}
          tileClassName={({ date }) => {
            let isHighlighted = false;

            if (highlightedRange) {
              const dateTs = getDayFromTo({
                reference: date,
                timezone,
              }).fromTs;
              isHighlighted =
                dateTs >= highlightedRange[0] && dateTs < highlightedRange[1];
            }

            return classNames(s.tile, {
              [s.tileHighlighted]: isHighlighted,
            });
          }}
          tileContent={({ date, view }) => {
            if (view !== "month") {
              return null;
            }

            const handleMouseEnter: MouseEventHandler | undefined =
              lockFixedRange
                ? (e) => {
                    if ((e.target as HTMLElement).closest("button")?.disabled) {
                      setHighlightedRange(undefined);
                      return;
                    }

                    const { fromTs, toTs } = getCorrespondingDatesFromRange({
                      reference: date,
                      timezone,
                      targetRange: lockFixedRange,
                    });

                    let letToHighlighted = toTs;

                    /** If allowRestDays and it is selected a incomplete week the Highlight should
                     * be updated to the max selectable date
                     */
                    if (allowRestDays) {
                      const { toTs: toTsSelected, to: fromDateSelected } =
                        getDayFromTo({
                          reference: date,
                          timezone,
                        });
                      if (
                        isDateInCurrentWeek({ reference: fromDateSelected })
                      ) {
                        letToHighlighted = toTsSelected;
                      }
                    }

                    setHighlightedRange([fromTs, letToHighlighted]);
                  }
                : undefined;

            const handleClick: MouseEventHandler | undefined =
              selectRangeEnabled
                ? (e) => {
                    if (!calendarRangeStart) {
                      setCalendarRangeStart(
                        getDayFromTo({
                          reference: date,
                          timezone,
                        }).from
                      );
                      return;
                    }

                    const selectedDateTime = getDayFromTo({
                      reference: date,
                      timezone,
                    }).from;

                    const selectedDatesCount =
                      Math.abs(
                        calendarRangeStart.diff(selectedDateTime, ["days"]).days
                      ) + 1;

                    /**
                     * prevent selecting dates range less than minDatesToSelect
                     *
                     * The selectedDatesCount of value 1 is excluded from this condition (selectedDatesCount > 1)
                     * because if the range selected has only one day, this means that the user clicked the range start date twice
                     * and this behavior is meant either to release the current selected range or to choose the end date as the start date if the minDayCountToSelect equals 1
                     * (this behavior is handled by the `selectDate` function)
                     */
                    if (
                      selectedDatesCount > 1 &&
                      selectedDatesCount < minDayCountToSelect
                    ) {
                      addToast({
                        messageKey: "warn.CANNOT_PICK_LESS_THAN_X_DAYS",
                        variables: {
                          dayCount: minDayCountToSelect,
                        },
                        variant: "warn",
                      });

                      e.stopPropagation();
                      return;
                    }
                  }
                : undefined;

            return (
              <div
                className={s.highlighter}
                onMouseEnter={handleMouseEnter}
                onClick={handleClick}
              />
            );
          }}
          formatShortWeekday={(locale, date) => {
            const shortDay = new Intl.DateTimeFormat(locale, {
              weekday: "short",
            }).format(date) as keyof typeof shortDayToAbbreviationLabel;
            return t(shortDayToAbbreviationLabel[shortDay]);
          }}
        />

        <HorizontalDivider
          className={s.divider}
          marginTop="medium"
          marginBottom="large"
        />
        <div className={s.actionButtonsContainer}>
          <Button
            skin="outline"
            size="large"
            className={s.actionButton}
            onClick={onCancelSelection}
          >
            {t("action.CANCEL")}
          </Button>
          <Button
            skin="primary"
            size="large"
            className={s.actionButton}
            onClick={onApplySelection}
            disabled={!dateBeforeApply}
          >
            {t("action.APPLY")}
          </Button>
        </div>
      </div>
    </PopoverPortal>
  ) : null;
};

export default DateSelectorPopover;
export type { DateSelectorPopoverProps };
