import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import { useTranslate } from "modules/language";
import UnitValue from "atomic-components/atoms/UnitValue";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { Info } from "svg-icons";
import LeedIcon from "svg-icons/LeedIcon";
import { useRefrigerantEmissions } from "modules/building/hooks/useRefrigerantEmissions";

import s from "./RefrigerantImpact.module.scss";

const RefrigerantImpact = () => {
  const t = useTranslate();
  const { refrigerantImpact, Notifier } = useRefrigerantEmissions();

  return (
    <Notifier>
      <WidgetContainer>
        <KeyValue
          isLight
          keyText={
            <>
              <LeedIcon iconScale={2} className={s.leedIcon} />
              {t("label.REFRIGERANT_IMPACT")}
            </>
          }
          value={
            <>
              <UnitValue value={refrigerantImpact} />
              <InfoPopover
                message={t("tooltip.EVALUATE_COMPLIANCE")}
                variant="primary"
                placement="top"
              >
                <StrippedButton>
                  <Info iconScale={1.2} color="var(--color-primary)" />
                </StrippedButton>
              </InfoPopover>
            </>
          }
        />
      </WidgetContainer>
    </Notifier>
  );
};

export default RefrigerantImpact;
