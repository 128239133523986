import classNames from "classnames";

type RainProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  color?: string;
  strokeWidth?: number;
};

const Rain = ({
  className,
  iconScale = 1,
  icon = true,
  color = "#0040FF",
  strokeWidth = 2,
}: RainProps) => (
  <svg
    className={classNames(className, { "para-icon": icon })}
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fontSize: `${iconScale * 100}%`,
    }}
  >
    <path
      d="M10.5601 4.60002C11.2478 3.50114 11.7354 2.28906 12.0001 1.02002C12.5001 3.52002 14.0001 5.92002 16.0001 7.52002C18.0001 9.12002 19.0001 11.02 19.0001 13.02C19.0058 14.4023 18.601 15.7552 17.8369 16.9071C17.0728 18.059 15.9839 18.9582 14.7082 19.4905C13.4325 20.0229 12.0275 20.1644 10.6713 19.8973C9.31501 19.6302 8.06862 18.9664 7.09009 17.99M5 14.3C7.2 14.3 9 12.47 9 10.25C9 9.09002 8.43 7.99002 7.29 7.06002C6.15 6.13002 5.29 4.75002 5 3.30002C4.71 4.75002 3.86 6.14002 2.71 7.06002C1.56 7.98002 1 9.10002 1 10.25C1 12.47 2.8 14.3 5 14.3Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Rain;
