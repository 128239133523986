import Button from "atomic-components/atoms/Buttons/Button";
import { useTranslate } from "modules/language";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { Info } from "svg-icons";
import { useEvaluationWizardBuildingModal } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/hooks/useEvaluationWizardBuildingModal";

import s from "./EvaluationWizardButton.module.scss";

const EvaluationWizardButton = () => {
  const t = useTranslate();

  const { showModal: showEvaluationWizardModal } =
    useEvaluationWizardBuildingModal();

  return (
    <Button
      skin="outline"
      className={s.wizardButton}
      onClick={showEvaluationWizardModal}
    >
      <span className={s.buttonText}>{t("label.EVALUATION_WIZARD")}</span>
      <InfoPopover
        variant="primary"
        placement="top"
        message={t("tooltip.EVALUATION_WIZARD")}
      >
        <StrippedButton>
          <Info iconScale={1.5} color="var(--color-primary)" />
        </StrippedButton>
      </InfoPopover>
    </Button>
  );
};

export default EvaluationWizardButton;
