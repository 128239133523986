import { type DialogHTMLAttributes, useRef, useEffect } from "react";
import classNames from "classnames";

import s from "./Modal.module.scss";

type ModalProps = DialogHTMLAttributes<HTMLDialogElement> & {
  isSuccess?: boolean;
  width?: "auto" | "medium" | "large";
  isFooterSticky?: boolean;
};

const Modal = ({
  isSuccess,
  width = "auto",
  isFooterSticky,
  children,
  className,
  ...props
}: ModalProps) => {
  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }

    if (children) {
      modalRef.current.showModal();
      modalRef.current.focus();
    } else {
      modalRef.current.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(children)]);

  return (
    <dialog
      ref={modalRef}
      className={classNames(
        s.dialog,
        [s[`width--${width}`]],
        {
          [s.isSuccess]: isSuccess,
          [s.footerSticky]: isFooterSticky,
        },
        className
      )}
      {...props}
    >
      {children}
    </dialog>
  );
};

export default Modal;
export type { ModalProps };
