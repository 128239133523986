import config from "../config";
import { isPlainObject } from "lodash";
import { useMemo } from "react";

const getLowerScale = (number, base = 10) =>
  Math.pow(base, Math.floor(Math.log(Math.abs(number)) / Math.log(base)));

/**
 * @typedef {Object} GetBoundsParams
 * @property {number} min
 * @property {number} max
 * @property {number} stepCount returned step count is not guaranteed to be approximateStepsCount. Rather approximateStepsCount is used as an initial estimate. defaults to 5
 * @property {number} includeZeroThreashold if min > 0 && min < includeZeroThreashold*(max-min) returned min will be zero. defaults to 2
 * @param {boolean} [ensureExceedMax] If true ensures the returned max exceeds the supplied max. defaults to true
 * @param {number} [maxExceedMaxRatio] how much is it allowed to raise the max as ratio of the max. defaults to 0.35
 * @param {number} [base] the steps base. defaults to 10 meaning steps are like .1, .2 if base=2 steps would be like .5,.25,.125...
 * @param {number} [minScale] the minimum scale to consider use 1 for whole number 10 for tens(i.e.10,20,30) 0.1 for(.1,.2...)
 */

/**
 * @typedef {Object} Bounds
 * @property {number} min
 * @property {number} max
 * @property {number} step
 * @property {number} stepsCount DEPRECATED: use stepCount instead which is stepsCount - 1
 * @property {number} stepCount
 * @property {number} stepPercent
 */

const getBoundsInternal = ({
  min,
  max,
  stepCount = 5,
  includeZeroThreashold = 2,
  ensureExceedMax = true,
  resolution = 1,
  base = 10,
  minScale,
}) => {
  let diff = max - min;
  if (diff == null || !isFinite(diff) || isNaN(diff)) {
    return {
      min: 0,
      max: stepCount,
      step: 1,
      stepCount,
      /** @deprecated */
      get stepsCount() {
        console.warn(
          `stepsCount is deprecated. Use stepCount instead which is the old stepsCount - 1`
        );
        return stepCount - 1;
      },
      stepPercent: 100 / stepCount,
    };
  }
  if (diff === 0) {
    diff = 1;
    min -= 0.5;
    max += 0.5;
  }
  if (min > 0) {
    if (includeZeroThreashold * diff > min) {
      min = 0;
      diff = max - min;
    }
  }
  const minLowerScale = getLowerScale(min, base);
  const maxLowerScale = getLowerScale(max, base);

  const scale = Math.max(
    minScale ?? Number.MIN_SAFE_INTEGER,
    Math.max(minLowerScale, maxLowerScale) / Math.pow(base, resolution)
  );

  let _min = Math.floor(min / scale) * scale;
  let _max = Math.ceil(max / scale) * scale;

  let step = (_max - _min) / stepCount;
  step = step / scale;

  const stepLog2 = Math.log2(step / 10);
  const isStepInverseOfPowerOfTwo =
    Math.abs(stepLog2 - Math.round(stepLog2)) <= 1e-9;
  if (!isStepInverseOfPowerOfTwo) {
    step = Math.ceil(step) * scale;
  } else {
    step = step * scale;
  }

  if (minScale) {
    step = Math.ceil(step / minScale) * minScale;
  }

  _max = _min + stepCount * step;

  if (ensureExceedMax && Math.abs(_max - max) < 1e-9) {
    return getBoundsInternal({
      min: _min,
      max: _max + step,
      resolution,
      stepCount,
      includeZeroThreashold,
      ensureExceedMax: false,
      minScale,
    });
  }

  return {
    min: _min,
    max: _max,
    step,
    stepCount,
    /** @deprecated */
    get stepsCount() {
      console.warn(
        `stepsCount is deprecated. Use stepCount instead which is the old stepsCount - 1`
      );
      return stepCount - 1;
    },
    stepPercent: 100 / stepCount,
  };
};

/**
 * @param {GetBoundsParams} param0
 * @returns {Bounds}
 */
export const getBounds = (...args) => {
  if (args.length > 1 && !isPlainObject(args[0])) {
    console.warn(
      `using getBounds with multiple args is now deprecated. getBounds now expects single object input`
    );
    const [
      min,
      max,
      approximateStepsCount = 5,
      includeZeroThreashold = 2,
      ensureExceedMax = true,
      isWholeNumber = false,
    ] = args;
    if (isWholeNumber) {
      if (config.isProd) {
        console.error(`getBounds no longer support "isWholeNumber"!`);
      } else {
        throw new Error(`getBounds no longer support "isWholeNumber"!`);
      }
    }
    args[0] = {
      min,
      max,
      stepCount: approximateStepsCount,
      includeZeroThreashold,
      ensureExceedMax,
    };
  }
  const {
    min,
    max,
    stepCount = 5,
    includeZeroThreashold = 2,
    ensureExceedMax = true,
    maxExceedMaxRatio = 0.35,
    base = 10,
    minScale,
  } = args[0];

  for (let resolution = 0; resolution < 10; resolution++) {
    const bounds = getBoundsInternal({
      min,
      max,
      stepCount,
      includeZeroThreashold,
      ensureExceedMax,
      resolution,
      base,
      minScale,
    });
    if ((bounds.max - max) / max <= maxExceedMaxRatio) {
      return bounds;
    }
  }
  return getBoundsInternal({
    min,
    max,
    stepCount,
    includeZeroThreashold,
    ensureExceedMax,
    resolution: 1,
    base,
    minScale,
  });
};

/**
 * @param {number} min
 * @param {number} max
 * @param {number} stepsCount
 * @param {number} includeZeroThreashold
 * @returns {Bounds}
 */
const useBounds = (min, max, stepCount = 5, includeZeroThreashold = 2) => {
  return useMemo(
    () => getBounds({ min, max, stepCount, includeZeroThreashold }),
    [min, max, stepCount, includeZeroThreashold]
  );
};

export default useBounds;
