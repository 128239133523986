import classNames from "classnames";
import type { HTMLAttributes } from "react";

import s from "./Alert.module.scss";

type AlertProps = HTMLAttributes<HTMLDivElement> & {
  type: "warning";
};

const Alert = ({ type, className, ...props }: AlertProps) => {
  return (
    <div
      role="alert"
      className={classNames(className, s.alert, [s[type]])}
      {...props}
    />
  );
};

export default Alert;
export type { AlertProps };
