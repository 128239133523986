/**
 * @typedef {import('three')} Three
 * @typedef {import('three').Scene} Scene
 * @typedef {import('three').Camera} Camera
 * @typedef {import('three').Vector3} Vector3
 * @typedef {import('three').Quaternion} Quaternion
 * @typedef {HTMLCanvasElement} Canvas
 *
 * @typedef {object} ConstructorArgs
 * @property {Three} THREE
 * @property {Scene} scene
 * @property {Camera} camera
 * @property {Canvas} canvas
 * @property {Vector3} [center]
 * @property {Function} [onCameraMoveEnd]
 * @property {Function} [onCameraMoveStart]
 * @property {Function} [onCameraMove]
 * @property {number} [tweenSpeed]
 * @property {number} [rotationSpeed]
 * @property {number} [zoomSpeed]
 */

const computeDistSquared = (a, b) =>
  Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2);

const getCenter = (intersections) => {
  for (let i = 0; i < intersections.length; i++) {
    if (intersections[i].object.visible) {
      return intersections[i].point;
    }
  }
  return intersections[0]?.point;
};

class CameraController {
  /**
   * @param {ConstructorArgs} param0
   */
  constructor({
    THREE = window.THREE,
    scene,
    camera,
    canvas,
    center,
    onCameraMoveEnd,
    onCameraMoveStart,
    onCameraMove,
    tweenSpeed = 1,
    rotationSpeed = 1,
    zoomSpeed = 1,
  }) {
    /** @type {Three} */
    this.THREE = THREE;
    /** @type {Scene} */
    this.scene = scene;
    /** @type {Camera} */
    this.camera = camera;
    /** @type {Canvas} */
    this.canvas = canvas;

    /** @type {Function} */
    this.onCameraMoveEnd = onCameraMoveEnd;
    /** @type {Function} */
    this.onCameraMoveStart = onCameraMoveStart;
    /** @type {Function} */
    this.onCameraMove = onCameraMove;

    /** @type {number} */
    this.tweenSpeed = tweenSpeed;
    /** @type {number} */
    this.rotationSpeed = rotationSpeed;
    /** @type {number} */
    this.zoomSpeed = zoomSpeed;

    /**
     * @type {Vector3}
     * @private
     */
    this.initialCenter = null;
    /**
     * @type {Vector3}
     * @private
     */
    this.center = center;
    /**
     * @type {Vector3}
     * @private
     */
    this.targetCenter = null;

    /**
     * @type {boolean}
     * @private
     */
    this.isControllingCamera = false;
    /**
     * @type {number}
     * @private
     */
    this.timer = null;

    /**
     * @type {Vector3}
     * @private
     */
    this.pointerRelativeToTopLeft = null;
    /**
     * @type {Vector3}
     * @private
     */
    this.pointerRelativeToTopLeft = null;
    /**
     * @type {Vector3}
     * @private
     */
    this.pointerRelativeToCenter = null;
    /**
     * @type {number}
     * @private
     */
    this.tweenCurrentStep = null;
    /**
     * @type {number}
     * @private
     */
    this.tweenStepsCount = null;
    /**
     * @type {"zoom" | "scroll" | "scroll-temp"}
     * @private
     */
    this.wheelMode = null;
    /**
     * @type {boolean}
     * @private
     */
    this.mouseInMe = null;
    /**
     * @type {number}
     * @private
     */
    this.touchesLength = null;
    /**
     * @type {object}
     * @private
     */
    this.updateArgs = null;
    /**
     * @type {{ x: number, y: number, id: number }[]}
     * @private
     */
    this.touchPointers = null;
  }
  reHydrate = (newPartialState) => {
    Object.assign(this, newPartialState);
  };

  cancel = () => {
    if (this.isControllingCamera) {
      const onCameraMoveEnd = this.onCameraMoveEnd;
      if (onCameraMoveEnd) {
        onCameraMoveEnd();
      }
      this.isControllingCamera = false;
    }
    this.canvas.removeEventListener("mousemove", this.mousemoveListener);
    document.removeEventListener("mouseup", this.mouseupListener);

    this.canvas.removeEventListener("touchmove", this.oneTouchmoveListener);
    this.canvas.removeEventListener("touchmove", this.twoTouchmoveListener);
    document.removeEventListener("touchend", this.touchendListener);
    document.removeEventListener("touchcancel", this.touchcancelListener);

    clearTimeout(this.timer);

    this.timer = null;
    this.updateArgs = null;
  };

  update = () => {
    if (this.animateCamera) {
      this.animateCamera();
      delete this.updateArgs;
      return;
    }
    if (!this.updateArgs) {
      return;
    }
    const { camera } = this;
    const {
      zoomArgs,
      center,
      tweenCurrentStep,
      rotationX,
      rotationY,
      moveVector,
    } = this.updateArgs;

    if (zoomArgs != null) {
      const { newCameraPosition, newCenter } = zoomArgs;
      camera.position.copy(newCameraPosition);
      this.center = newCenter;
      camera.updateWorldMatrix();

      if (this.targetCenter) {
        this.initialCenter = newCenter;
        delete this.targetCenter;
        this.tweenCurrentStep = this.tweenStepsCount;
      }
    } else {
      const position = camera.getWorldPosition(new this.THREE.Vector3());
      const positionVector = position.clone().sub(center);

      const { look, right, up } = this.getCameraCoodinateSystem();

      const transformationMatrix = new this.THREE.Matrix4().makeRotationAxis(
        up,
        rotationX
      );

      if (
        up.y > 0.2 ||
        (rotationY > 0 && look.y < 0) ||
        (rotationY < 0 && look.y > 0)
      ) {
        const transformationMatrix2 = new this.THREE.Matrix4().makeRotationAxis(
          right.applyMatrix4(transformationMatrix),
          rotationY
        );

        transformationMatrix.multiply(transformationMatrix2);
      }

      const nextPositionVector = positionVector
        .clone()
        .applyMatrix4(transformationMatrix);

      const nextPosition = center.clone().add(nextPositionVector);

      camera.position.set(nextPosition.x, nextPosition.y, nextPosition.z);
      camera.updateMatrixWorld();
      camera.lookAt(center);
      camera.updateMatrixWorld();

      this.tweenCurrentStep = tweenCurrentStep;
      this.center = center;
    }

    const onCameraMove = this.onCameraMove;
    if (onCameraMove) {
      onCameraMove({ camera, center, rotationX, rotationY, moveVector });
    }

    this.updateArgs = null;
  };

  /** @private */
  ensureCameraControlsAreActive = () => {
    if (!this.isControllingCamera) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.isControllingCamera = true;
      const onCameraMoveStart = this.onCameraMoveStart;
      onCameraMoveStart &&
        onCameraMoveStart({
          cancel: this.cancel,
        });
    }
  };

  /** @private */
  getCameraCoodinateSystem = () => {
    const look = this.camera
      .getWorldDirection(new this.THREE.Vector3())
      .normalize();

    const right = look
      .clone()
      .cross(new this.THREE.Vector3(0, 1, 0))
      .normalize();

    const up = right.clone().cross(look).normalize();

    return { right, up, look };
  };

  /** @private */
  rotationStart = (clientX, clientY) => {
    const { canvas, camera } = this;

    const rect = canvas.getBoundingClientRect();

    const width = canvas.offsetWidth;
    const height = canvas.offsetHeight;

    const initPointerRelativeToTopLeft = {
      x: clientX - rect.left,
      y: clientY - rect.top,
    };

    this.pointerRelativeToTopLeft = initPointerRelativeToTopLeft;
    this.pointerRelativeToCenter = {
      x: (this.pointerRelativeToTopLeft.x / width) * 2 - 1,
      y: -(this.pointerRelativeToTopLeft.y / height) * 2 + 1,
    };

    const raycaster = new this.THREE.Raycaster();
    raycaster.setFromCamera(this.pointerRelativeToCenter, camera);
    this.initialCenter = this.center;
    let targetCenter = getCenter(
      raycaster.intersectObjects(this.scene.children)
    );
    if (targetCenter) {
      this.lastOnModelCenter = targetCenter;
    } else {
      targetCenter = this.lastOnModelCenter ?? this.initialCenter;
    }
    this.targetCenter = targetCenter;

    this.tweenCurrentStep = 0;
    this.tweenStepsCount =
      this.initialCenter === this.targetCenter ? 0 : 100 * this.tweenSpeed;

    this.timer = setTimeout(() => {
      this.ensureCameraControlsAreActive();
    }, 200);
  };

  /** @private */
  rotationMove = (clientX, clientY) => {
    const { canvas } = this;

    this.ensureCameraControlsAreActive();
    const rect = canvas.getBoundingClientRect();
    const width = canvas.offsetWidth;
    const height = canvas.offsetHeight;

    const newPointerRelativeToTopLeft = {
      x: clientX - rect.left,
      y: clientY - rect.top,
    };

    let deltaX =
      newPointerRelativeToTopLeft.x - this.pointerRelativeToTopLeft.x;
    let deltaY =
      (newPointerRelativeToTopLeft.y - this.pointerRelativeToTopLeft.y) / 2;
    this.pointerRelativeToTopLeft = newPointerRelativeToTopLeft;

    const delta = Math.min(
      12,
      Math.max(4, Math.sqrt(deltaX * deltaX + deltaY * deltaY))
    );

    let rotateX, rotateY;

    let currentCenter;
    const tweenCurrentStep = this.tweenCurrentStep + delta;
    const tweenRatio = tweenCurrentStep / this.tweenStepsCount;
    if (!this.targetCenter || tweenCurrentStep >= this.tweenStepsCount) {
      delete this.targetCenter;
      currentCenter = this.center;
      rotateX = true;
      rotateY = true;
    } else {
      currentCenter = this.initialCenter
        .clone()
        .multiplyScalar(1 - tweenRatio)
        .add(this.targetCenter.clone().multiplyScalar(tweenRatio));
      rotateX = deltaX * this.pointerRelativeToCenter.x > 0;
      rotateY = deltaY * this.pointerRelativeToCenter.y > 0;
    }

    const rotationX =
      -((deltaX / width) * 2 * Math.PI) *
      this.rotationSpeed *
      (rotateX ? 1 : tweenRatio);

    const rotationY =
      -((deltaY / height) * 2 * Math.PI) *
      this.rotationSpeed *
      (rotateY ? 1 : tweenRatio);

    this.updateArgs = {
      rotationX,
      rotationY,
      center: currentCenter,
      tweenCurrentStep,
    };
  };

  /** @private */
  zoom = (zoomIn, mouseX, mouseY, speed = 1) => {
    const { camera, canvas, THREE } = this;

    const rect = canvas.getBoundingClientRect();
    const width = canvas.offsetWidth;
    const height = canvas.offsetHeight;

    const pointer = {
      x: ((mouseX - rect.left) / width) * 2 - 1,
      y: -((mouseY - rect.top) / height) * 2 + 1,
    };

    const raycaster = new this.THREE.Raycaster();
    raycaster.setFromCamera({ x: pointer.x, y: pointer.y }, camera);
    let zoomCenter = raycaster.intersectObjects(this.scene.children)[0]?.point;

    if (!zoomCenter) {
      const cameraToMouseVector = new THREE.Vector3(pointer.x, pointer.y, 0.5);
      cameraToMouseVector.unproject(camera);
      cameraToMouseVector.sub(camera.position).normalize();

      zoomCenter = camera.position.clone().add(cameraToMouseVector);
    }

    const distanceToCenter = camera.position.clone().sub(this.center).length();

    const sense = zoomIn ? 1 : -1;
    const directionVector = zoomCenter.clone().sub(camera.position).normalize();
    const moveDistance =
      distanceToCenter * 0.1 * this.zoomSpeed * sense * speed + 0.375 * sense;
    const moveVector = directionVector.clone().multiplyScalar(moveDistance);

    const { look } = this.getCameraCoodinateSystem();

    const newCameraPosition = camera.position.clone().add(moveVector);
    raycaster.set(newCameraPosition, look);
    let newCenter = raycaster.intersectObjects(this.scene.children)[0]?.point;

    if (!newCenter) {
      newCenter = newCameraPosition
        .clone()
        .add(look.clone().multiplyScalar(Math.max(distanceToCenter, 5)));
    } else {
      this.lastOnModelCenter = newCenter;
    }

    this.updateArgs = {
      zoomArgs: {
        newCenter,
        newCameraPosition,
      },
    };
  };

  /**
   * @type {import('react').KeyboardEventHandler}
   * @private
   */
  keydownListener = (e) => {
    if (!this.mouseInMe) {
      return;
    }
    const code = e.code;
    if (["Backquote", "Escape"].includes(code)) {
      this.wheelMode = "scroll";
    } else if (this.wheelMode === "zoom" && code === "KeyS") {
      this.wheelMode = "scroll-temp";
    }
  };

  /**
   * @type {import('react').KeyboardEventHandler}
   * @private
   */
  keyupListener = (e) => {
    if (e.code === "KeyS" && this.wheelMode === "scroll-temp") {
      this.wheelMode = "zoom";
    }
  };

  /**
   * @type {import('react').MouseEventHandler}
   * @private
   */
  mouseenterListener = () => {
    if (this.wheelMode === "scroll-temp") {
      this.wheelMode = "zoom";
    }
    this.mouseInMe = true;
  };

  /**
   * @type {import('react').MouseEventHandler}
   * @private
   */
  mouseleaveListener = () => {
    this.mouseInMe = false;
  };

  /**
   * @type {import('react').WheelEventHandler}
   * @private
   */
  wheelListener = (e) => {
    if (!(e.altKey || e.ctrlKey || e.shiftKey) && this.wheelMode === "zoom") {
      this.zoom(e.deltaY < 0, e.clientX, e.clientY);
      e.preventDefault();
    }
  };

  /**
   * @type {import('react').MouseEventHandler}
   * @private
   */
  mousemoveListener = (e) => {
    this.rotationMove(e.clientX, e.clientY);
  };

  /**
   * @type {import('react').MouseEventHandler}
   * @private
   */
  mouseupListener = () => {
    this.cancel();
  };

  /**
   * @type {import('react').MouseEventHandler}
   * @private
   */
  mousedownListener = (e) => {
    const { canvas } = this;

    this.mouseInMe = true;
    if (this.wheelMode !== "scroll-temp") {
      this.wheelMode = "zoom";
    }

    this.rotationStart(e.clientX, e.clientY);

    canvas.addEventListener("mousemove", this.mousemoveListener);

    document.addEventListener("mouseup", this.mouseupListener);
  };

  /** @private */
  filterTouches = (touches) =>
    Array.prototype.filter.call(
      touches,
      (touch) => touch.target === this.canvas
    );

  /**
   * @param {TouchList} touches
   * @private
   */
  onTouchesChange = (touches) => {
    const { canvas } = this;
    touches = this.filterTouches(touches);

    canvas.removeEventListener("touchmove", this.oneTouchmoveListener);
    canvas.removeEventListener("touchmove", this.twoTouchmoveListener);
    delete this.touchPointers;

    if (touches.length === 0) {
      this.cancel();
      return;
    }

    if (touches.length === 1) {
      this.rotationStart(touches[0].clientX, touches[0].clientY);
      canvas.addEventListener("touchmove", this.oneTouchmoveListener);
    } else if (touches.length === 2) {
      const rect = canvas.getBoundingClientRect();
      const initTouchPointers = [
        {
          id: touches[0].identifier,
          x: touches[0].clientX - rect.left,
          y: touches[0].clientY - rect.top,
        },
        {
          id: touches[1].identifier,
          x: touches[1].clientX - rect.left,
          y: touches[1].clientY - rect.top,
        },
      ];
      this.touchPointers = initTouchPointers;
      canvas.addEventListener("touchmove", this.twoTouchmoveListener);
    }
  };

  /**
   * @type {import('react').TouchEventHandler}
   * @private
   */
  oneTouchmoveListener = (e) => {
    const { canvas } = this;
    const touches = this.filterTouches(e.touches);
    if (touches.length !== 1) {
      canvas.removeEventListener("touchmove", this.oneTouchmoveListener);
      return;
    }
    e.preventDefault();
    this.rotationMove(touches[0].clientX, touches[0].clientY);
  };

  /**
   * @type {import('react').TouchEventHandler}
   * @private
   */
  twoTouchmoveListener = (e) => {
    const { canvas } = this;
    const touches = this.filterTouches(e.touches);

    if (touches.length !== 2) {
      canvas.removeEventListener("touchmove", this.twoTouchmoveListener);
      return;
    }
    const rect = canvas.getBoundingClientRect();
    const newPointers = [
      {
        id: touches[0].identifier,
        x: touches[0].clientX - rect.left,
        y: touches[0].clientY - rect.top,
      },
      {
        id: touches[1].identifier,
        x: touches[1].clientX - rect.left,
        y: touches[1].clientY - rect.top,
      },
    ]
      .filter((p) => this.touchPointers.find(({ id }) => id === p.id))
      .sort((a) => (a.id === this.touchPointers[0].id ? -1 : 1));
    if (newPointers.length < 2) {
      canvas.removeEventListener("touchmove", this.twoTouchmoveListener);
      return;
    }

    const oldPointers = this.touchPointers;
    this.touchPointers = newPointers;

    const deltas = newPointers.map(({ x, y }, i) => ({
      x: x - oldPointers[i].x,
      y: y - oldPointers[i].y,
    }));

    const sameDirection =
      deltas[0].x * deltas[1].x > 0 && deltas[0].y * deltas[1].y > 0;

    if (
      sameDirection &&
      Math.abs(deltas[0].x) < 1.5 &&
      Math.abs(deltas[1].x) < 1.5 &&
      Math.abs(deltas[0].y) > 2 &&
      Math.abs(deltas[1].y) > 2 &&
      Math.abs(deltas[0].y - deltas[1].y) < 1
    ) {
      // scroll
      return;
    }

    e.preventDefault();
    if (sameDirection) {
      return;
    }

    // zoom
    const delta =
      Math.abs(deltas[0].x - deltas[1].x) + Math.abs(deltas[0].y - deltas[1].y);

    const distChange =
      computeDistSquared(...oldPointers) - computeDistSquared(...newPointers);
    if (Math.abs(distChange) < 0.001) {
      return;
    }
    const isZoomIn = distChange < 0;

    const clientX = (touches[0].clientX + touches[1].clientX) / 2;
    const clientY = (touches[0].clientY + touches[1].clientY) / 2;

    this.zoom(isZoomIn, clientX, clientY, delta / 15);
  };

  /**
   * @type {import('react').TouchEventHandler}
   * @private
   */
  touchendListener = (e) => {
    this.onTouchesChange(e.touches);
  };

  /**
   * @type {import('react').TouchEventHandler}
   * @private
   */
  touchcancelListener = (e) => {
    this.onTouchesChange(e.touches);
  };

  /**
   * @type {import('react').TouchEventHandler}
   * @private
   */
  touchstartListener = (e) => {
    this.onTouchesChange(e.touches);
    document.removeEventListener("touchend", this.touchendListener);
    document.removeEventListener("touchcancel", this.touchcancelListener);
    document.addEventListener("touchend", this.touchendListener);
    document.addEventListener("touchcancel", this.touchcancelListener);
  };

  /**
   * @param {{ quaternion: Quaternion,  position: Vector3, animate: boolean, animationSteps: number }} param0
   */
  moveCamera = ({
    quaternion,
    position,
    center,
    animate = true,
    animationSteps = 5,
    signal,
  }) => {
    return new Promise((resolve) => {
      const { camera } = this;
      const initQuaternion = camera.getWorldQuaternion(
        new this.THREE.Quaternion()
      );
      const initPosition = camera.getWorldPosition(new this.THREE.Vector3());
      let step = 0;
      if (!animate) {
        animationSteps = 1;
      }
      const initialCenter = this.center;
      this.ensureCameraControlsAreActive();

      let abortListener;
      signal &&
        signal.addEventListener(
          "abort",
          (abortListener = () => {
            delete this.animateCamera;
            const ratio = step / animationSteps;

            if (!center) {
              const raycaster = new this.THREE.Raycaster();
              raycaster.setFromCamera({ x: 0, y: 0 }, camera);
              center =
                raycaster.intersectObjects(this.scene.children)[0]?.point ??
                this.center;
            } else {
              center = initialCenter
                .clone()
                .multiplyScalar(1 - ratio)
                .add(center.clone().multiplyScalar(ratio));
            }
            this.initialCenter = center;
            this.center = center;

            this.tweenCurrentStep = 0;
            this.cancel();
            resolve(false);
          })
        );
      this.animateCamera = () => {
        step++;
        const ratio = step / animationSteps;
        const currentQuaternion = new this.THREE.Quaternion().slerpQuaternions(
          initQuaternion,
          quaternion,
          ratio
        );
        const currentPosition = initPosition
          .clone()
          .multiplyScalar(1 - ratio)
          .add(position.clone().multiplyScalar(ratio));

        camera.setRotationFromQuaternion(currentQuaternion);
        camera.position.set(
          currentPosition.x,
          currentPosition.y,
          currentPosition.z
        );
        if (step >= animationSteps) {
          signal && signal.removeEventListener("abort", abortListener);

          delete this.animateCamera;

          this.tweenCurrentStep = 0;
          if (!center) {
            const raycaster = new this.THREE.Raycaster();
            raycaster.setFromCamera({ x: 0, y: 0 }, camera);
            center = raycaster.intersectObjects(this.scene.children)[0]?.point;
          }

          if (center) {
            this.initialCenter = center;
            this.center = center;
          }

          this.cancel();
          resolve(true);
        }
      };
    });
  };

  bind = () => {
    const { canvas } = this;
    document.addEventListener("keydown", this.keydownListener);
    document.addEventListener("keyup", this.keyupListener);
    canvas.addEventListener("mouseenter", this.mouseenterListener);
    canvas.addEventListener("mouseleave", this.mouseleaveListener);
    canvas.addEventListener("wheel", this.wheelListener);
    canvas.addEventListener("mousedown", this.mousedownListener);
    canvas.addEventListener("touchstart", this.touchstartListener);
  };

  unbind = () => {
    const { canvas } = this;
    this.cancel();

    document.removeEventListener("keydown", this.keydownListener);
    document.removeEventListener("keyup", this.keyupListener);
    canvas.removeEventListener("mouseenter", this.mouseenterListener);
    canvas.removeEventListener("mouseleave", this.mouseleaveListener);
    canvas.removeEventListener("wheel", this.wheelListener);
    canvas.removeEventListener("mousedown", this.mousedownListener);
    canvas.removeEventListener("touchstart", this.touchstartListener);
  };

  getCenter = () => this.center?.clone();
}

export default CameraController;
