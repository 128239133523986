import withIconProps from "helpers/withIconProps";

type Props = {
  color?: string;
};

const DownloadIcon = withIconProps(
  ({ color = "var(--gray-lmedium-color)" }: Props) => {
    return (
      <svg
        width="12"
        height="16"
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.33317 1.33325V5.33325H11.3332M5.99984 11.9999V7.99992M5.99984 11.9999L3.99984 9.99992M5.99984 11.9999L7.99984 9.99992M7.6665 1.33325H1.99984C1.64622 1.33325 1.30708 1.47373 1.05703 1.72378C0.80698 1.97382 0.666504 2.31296 0.666504 2.66659V13.3333C0.666504 13.6869 0.80698 14.026 1.05703 14.2761C1.30708 14.5261 1.64622 14.6666 1.99984 14.6666H9.99984C10.3535 14.6666 10.6926 14.5261 10.9426 14.2761C11.1927 14.026 11.3332 13.6869 11.3332 13.3333V4.99992L7.6665 1.33325Z"
          stroke={color}
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default DownloadIcon;
