import React, { useEffect } from "react";
import { v4 as uuidV4 } from "uuid";
import texturizeObject from "../../helpers/texturizeObject";
import revertTexturizeObject from "../../helpers/revertTexturizeObject";
import { useViewerState } from "../Provider";

/**
 * @typedef {Object} ObjectTexturizerProps
 * @property {import('three').Object3D | string} object the Object3D to colorize or its id or its name
 * @property {number} [priority] defaults to 0
 * @property {import('three').DataTexture} [texture]
 */

/**
 * @type {React.FunctionComponent<ObjectTexturizerProps>} param0
 */
const ObjectTexturizer = ({ object, texture, priority = 0 }) => {
  const { objects } = useViewerState();
  useEffect(() => {
    if (!objects) {
      return;
    }
    const key = uuidV4();
    const objectToTexturize = objects.find(
      (o) =>
        o === object ||
        o.uuid === object ||
        o.id === object ||
        o.name === object
    );
    if (!objectToTexturize) {
      return;
    }

    texturizeObject(objectToTexturize, {
      texture,
      key,
    });
    return () => {
      revertTexturizeObject(objectToTexturize, key);
    };
  }, [objects, object, texture]);
  return null;
};

export default ObjectTexturizer;
