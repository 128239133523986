import React, { useId } from "react";

import s from "./TextArea.module.scss";

export type TextAreaProps = {
  label?: string;
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  rows?: number;
  maxLength?: number;
  required?: boolean;
};

const TextArea = ({
  label,
  value,
  onChange,
  placeholder,
  rows = 3,
  maxLength,
  required = false,
  ...props
}: TextAreaProps) => {
  const labelId = useId();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div>
      {label ? (
        <label id={labelId} className={s.label}>
          {label}
        </label>
      ) : null}
      <textarea
        required={required}
        className={s.textarea}
        value={value}
        onChange={handleChange}
        aria-labelledby={labelId}
        placeholder={placeholder}
        rows={rows}
        maxLength={maxLength}
        {...props}
      />
    </div>
  );
};

export default TextArea;
