import EN from "./translations/english.translations";
import AR from "./translations/arabic.translations";
import {
  isFunction,
  keyBy,
  lowerCase,
  capitalize,
  map,
  uniq,
  mapValues,
  isString,
} from "lodash";
import config from "../../config";
import removeClassesNames from "../core/helpers/removeClassesNames";
import compileES6template from "es6-template-strings/compile";
import resolveES6template from "es6-template-strings/resolve-to-array";
import { SingleEvent } from "../core/helpers/events";
import { useEffect, useState } from "react";

/**
 * @typedef {Object} LanguageMeta
 * @property {string} name - display name of a function e.g. English, Francaise
 * @property {string} code - 2 letter language code EN, FR, AR
 * @property {string} internalName - internal name of language english lower camelCased
 * @property {string} cssClassName - when this language is used this class will be added to the <html> tag
 * @property {'ltr' | 'rtl'} dir - lrt or rtl
 * @property {string} lang - when this language is used this will be added as attribute lang to the <html> tag
 */

/** @type {LanguageMeta[]} */
const LANGUAGES_ARRAY = [];
/** @type {LanguageMeta[]} */
const LANGUAGES_BY_CODE = {};
/** @type {LanguageMeta[]} */
const LANGUAGES_BY_INTERNAL_NAME = {};

const translationsByLanguageCode = {};

export const processTranslations = (translations) => {
  return mapValues(translations, (translation) => {
    if (!isString(translation)) {
      return translation;
    }
    const compiled = compileES6template(translation);
    if (compiled.substitutions.length === 0) {
      return translation;
    }
    const defaults = mapValues(
      keyBy(uniq(compiled.substitutions), (s) => s),
      (k) => (config.isProd ? "" : "${" + k + "}")
    );
    return (substitutions) =>
      resolveES6template(compiled, { ...defaults, ...substitutions });
  });
};

/**
 * adds a language's translations
 * @param {LanguageMeta} langMeta -the meta of the language
 * @param {object} translations - the translations as translationKey: translationValue the translationValue can use es6 string literals like ${name} these can be sent to the translation function after the key argument `const t = useTranslate(); t('TRANSLATION_KEY', { name: 'Mohammad' })`
 */
export const addLanguage = (langMeta, translations) => {
  if (LANGUAGES_BY_CODE[langMeta.code]) {
    throw new Error(
      "Language " +
        langMeta.internalName +
        "[" +
        langMeta.code +
        "] already loaded"
    );
  }
  if (LANGUAGES_BY_INTERNAL_NAME[langMeta.internalName]) {
    throw new Error(
      "Language " +
        langMeta.internalName +
        "[" +
        langMeta.code +
        "] already loaded"
    );
  }

  langMeta.locale = langMeta.locale ?? langMeta.code.toLowerCase();
  LANGUAGES_ARRAY.push(langMeta);
  LANGUAGES_BY_CODE[langMeta.code] = langMeta;
  LANGUAGES_BY_INTERNAL_NAME[langMeta.internalName] = langMeta;
  translationsByLanguageCode[langMeta.code] = processTranslations(translations);
};

addLanguage(
  {
    name: "English",
    code: "EN",
    internalName: "english",
    dir: "ltr",
    cssClassName: "ltr",
    lang: "en",
  },
  EN
);
addLanguage(
  {
    name: "Arabic",
    code: "AR",
    internalName: "arabic",
    dir: "rtl",
    cssClassName: "rtl",
    lang: "ar",
  },
  AR
);

const genericTranslationFunction = (translations, key, replacements) => {
  const translated = translations[key];
  if (translated) {
    if (isFunction(translated)) {
      return translated(replacements);
    }
    return translated;
  }
  return config.isProd && isString(key) ? capitalize(lowerCase(key)) : key;
};
const cachedFunctions = {};

export const getLanguages = () => LANGUAGES_ARRAY;
export const getLanguageByCode = (languageCode) =>
  LANGUAGES_BY_CODE[languageCode];
export const getLanguageByInternalName = (languageInternalName) =>
  LANGUAGES_BY_INTERNAL_NAME[languageInternalName];

let selectedLanguageCode = localStorage.defaultLanguage || "EN";
const languageChangeEvent = new SingleEvent();
const selectLanguageByCode = (languageCode) => {
  if (!LANGUAGES_BY_CODE[languageCode]) {
    throw new Error(`no language with code "${languageCode}" defined`);
  }
  selectedLanguageCode = languageCode;
  languageChangeEvent.triggerEvent(languageCode);
};

/**
 * @returns {{languageCode:string,setLanguageCode:Function,language:LanguageMeta,t:Function}}
 */
export const useSelectedLanguageState = () => {
  const [languageCode, setLanguageCode] = useState(selectedLanguageCode);
  useEffect(() => {
    return languageChangeEvent.addListener((languageCode) => {
      setLanguageCode(languageCode);
    });
  }, []);
  return {
    languageCode,
    setLanguageCode: selectLanguageByCode,
    language: getLanguageByCode(languageCode),
    t: getTranslationFunction(languageCode),
  };
};

export const useIsRTL = () => {
  const { language } = useSelectedLanguageState();
  return language.dir === "rtl";
};

const getTranslationFunction = (languageCode) =>
  cachedFunctions[languageCode] ||
  (cachedFunctions[languageCode] = genericTranslationFunction.bind(
    null,
    translationsByLanguageCode[languageCode]
  ));
export const useTranslate = () => {
  const { languageCode } = useSelectedLanguageState();
  return getTranslationFunction(languageCode);
};

const selectLanguageListener = (languageCode) => {
  localStorage.defaultLanguage = languageCode;
  const htmlElement = document.getElementsByTagName("html")[0];
  const trimmedClass = removeClassesNames(
    htmlElement.className,
    uniq(map(LANGUAGES_ARRAY, "cssClassName"))
  ).trim();
  htmlElement.className =
    (trimmedClass ? trimmedClass + " " : "") +
    LANGUAGES_BY_CODE[languageCode].cssClassName;
  htmlElement.setAttribute("lang", LANGUAGES_BY_CODE[languageCode].lang);
};
languageChangeEvent.addListener(selectLanguageListener);
selectLanguageListener(selectedLanguageCode);

export const Translator = ({ translationKey, replacements = undefined }) => {
  const t = useTranslate();
  return t(translationKey, replacements);
};

export const onLanguageChange = languageChangeEvent.addListener;
