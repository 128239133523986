import Button, {
  type ButtonProps,
} from "atomic-components/atoms/Buttons/Button";
import type { CSSProperties, HTMLAttributes, MouseEventHandler } from "react";
import { useModalState } from "../ModalProvider";
import classNames from "classnames";

import s from "./ModalFooter.module.scss";

type ModalFooterProps = HTMLAttributes<HTMLElement> & {
  cancel?: {
    text: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    props?: ButtonProps;
  };
  justifyContent?: CSSProperties["justifyContent"];
};

const ModalFooter = ({
  cancel,
  justifyContent,
  className,
  children,
  style,
  ...props
}: ModalFooterProps) => {
  const { hideModal } = useModalState();

  return (
    <footer
      className={classNames(s.footer, className)}
      style={{
        ...style,
        justifyContent,
      }}
      {...props}
    >
      {children}
      {cancel ? (
        <Button
          size="small"
          skin="default"
          formAction="dialog"
          {...cancel.props}
          onClick={(e) => {
            cancel.onClick?.(e);
            hideModal();
          }}
        >
          {cancel.text}
        </Button>
      ) : null}
    </footer>
  );
};

export default ModalFooter;
export type { ModalFooterProps };
