import WidgetRadioTab, {
  type WidgetRadioTabProps,
} from "atomic-components/molecules/WidgetRadioTab/WidgetRadioTab";
import ViewPieChartStatusContainer, {
  type PieChartStatusContainerProps,
} from "atomic-components/organisms/PieChartStatusContainer/PieChartStatusContainer";
import { type ReactNode, useId } from "react";

type PieChartWithTabsProps<SELECTED_TAB_KEY extends string | number> = Omit<
  PieChartStatusContainerProps,
  "statusElement" | "chartProps"
> & {
  selectedTabKey?: SELECTED_TAB_KEY;
  setSelectedTabKey?: (key: SELECTED_TAB_KEY) => void;
  tabItems: {
    key: SELECTED_TAB_KEY;
    text: ReactNode;
    value: ReactNode;
  }[];
  tabsSkin?: WidgetRadioTabProps["skin"];
  tabsVariant?: NonNullable<WidgetRadioTabProps["keyValueProps"]>["variant"];
  tabsSize?: NonNullable<WidgetRadioTabProps["keyValueProps"]>["size"];
};

const PieChartWithTabs = <SELECTED_TAB_KEY extends string | number>({
  selectedTabKey,
  setSelectedTabKey,
  chartItems,
  tabItems,
  tabsSkin,
  tabsVariant,
  tabsSize,
  ...props
}: PieChartWithTabsProps<SELECTED_TAB_KEY>) => {
  const elementId = useId();

  const createTabIds = (key: SELECTED_TAB_KEY) => {
    return {
      tabId: `${elementId}-${key}`,
      panelId: `${elementId}-panel-${key}`,
    };
  };

  const selectedConsumableTabIds =
    selectedTabKey && createTabIds(selectedTabKey);

  const areTabsSelectable = !!setSelectedTabKey;

  return (
    <ViewPieChartStatusContainer
      statusElement={
        <>
          {tabItems.map(({ key, text, value }) => {
            const { panelId, tabId } = createTabIds(key);
            return (
              <WidgetRadioTab
                key={key}
                keyText={text}
                value={value}
                skin={tabsSkin}
                keyValueProps={{
                  variant: tabsVariant || undefined,
                  size: tabsSize || undefined,
                }}
                {...(areTabsSelectable
                  ? {
                      isSelectable: true,
                      isSelected: selectedTabKey === key,
                      onClick: () => setSelectedTabKey?.(key),
                      id: tabId,
                      controlsElementId: panelId,
                    }
                  : {
                      isSelectable: false,
                    })}
              />
            );
          })}
        </>
      }
      chartItems={chartItems}
      chartProps={{
        role: "tabpanel",
        id: selectedConsumableTabIds?.panelId,
        "aria-labelledby": selectedConsumableTabIds?.tabId,
      }}
      {...props}
    />
  );
};

export default PieChartWithTabs;
export type { PieChartWithTabsProps };
