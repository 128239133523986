import { TableCell, TableRow } from "atomic-components/organisms/Table";
import { useTranslate } from "modules/language";
import FormattedValue from "atomic-components/atoms/FormattedValue";
import classNames from "classnames";
import ExclamationTriangle from "svg-icons/ExclamationTriangle";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import { CheckIcon, ExternalLink } from "svg-icons";
import type {
  ComplianceScoreValues,
  SubComplianceScore,
} from "modules/building/pages/SustainabilityPage/types";
import isRealNumber from "helpers/isRealNumber";

import s from "./ComplianceCategoryItemRow.module.scss";

type CategoryItemTableProps<COMPLIANCE_SCORE_KEY extends string> = {
  title: string;
  rowColor: "white" | "lightBlue";
  isNotEligibleForLEED?: boolean;
  content: SubComplianceScore<COMPLIANCE_SCORE_KEY>;
  data: ComplianceScoreValues<COMPLIANCE_SCORE_KEY>;
};

const ComplianceCategoryItemRow = <COMPLIANCE_SCORE_KEY extends string>({
  content,
  data,
  rowColor,
  title: rowTitle,
  isNotEligibleForLEED,
}: CategoryItemTableProps<COMPLIANCE_SCORE_KEY>) => {
  const t = useTranslate();
  const {
    requisite,
    title,
    key,
    minimumRequired,
    maximumRequired,
    target,
    fieldType,
    url,
  } = content;

  return (
    <TableRow
      className={s.tableRow}
      title={t(rowTitle)}
      skin={rowColor}
      key={key}
    >
      <TableCell>
        <span className={classNames(s.requisite, s[`requisite--${requisite}`])}>
          {requisite ? t(`label.${requisite}`) : null}
        </span>
      </TableCell>
      <TableCell>
        {t(title)}{" "}
        {url && (
          <a href={url} target="_blank" rel="noreferrer">
            <ExternalLink color="var(--color-primary)" iconScale={1} />
          </a>
        )}
      </TableCell>
      <TableCell className={s.tableCellCentered}>
        {fieldType === "number" ? (
          <>
            <FormattedValue value={minimumRequired} />/
            <FormattedValue value={maximumRequired} />
          </>
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell className={s.tableCellCentered}>
        {fieldType === "number" ? (
          <span
            className={classNames({
              [s.ineligibleAchievement]: isNotEligibleForLEED,
            })}
          >
            {isRealNumber(data[key]) ? (
              <FormattedValue value={data[key]} />
            ) : (
              "-"
            )}
            {isNotEligibleForLEED && (
              <InfoPopover
                message={t("tooltip.ELIGIBILITY_MINIMUM_NOT_MET")}
                variant="primary"
                placement="top"
              >
                <ExclamationTriangle iconScale={1} fill={false} />
              </InfoPopover>
            )}
          </span>
        ) : data[key] ? (
          <CheckIcon />
        ) : (
          <InfoPopover
            message={t("tooltip.ELIGIBILITY_MINIMUM_NOT_MET")}
            variant="primary"
            placement="top"
          >
            <ExclamationTriangle iconScale={1} fill={false} />
          </InfoPopover>
        )}
      </TableCell>
      <TableCell className={s.tableCellCentered}>
        {fieldType === "number" ? (
          <FormattedValue value={target} />
        ) : (
          <CheckIcon />
        )}
      </TableCell>
    </TableRow>
  );
};

export default ComplianceCategoryItemRow;
