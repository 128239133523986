import React, { useMemo } from "react";
import { flatten } from "lodash";
import { useModelState } from "../../Model";
import { MultiObjectsColorizer } from "modules/viewer/components/ObjectColorizer/ObjectColorizer";

/**
 * @type {React.NamedExoticComponent<{ entitiesIds?: string[]; mappingName?: string; color: number; opacity?: number; priority?: number }>}
 */
const ColorizeEntities = React.memo(
  ({
    entitiesIds,
    mappingName = "default",
    color,
    opacity = 0.5,
    priority = 0,
  }) => {
    const { getEntityMappingByEntityId } = useModelState();

    const objects = useMemo(() => {
      return flatten(
        entitiesIds.map(
          (entityId) =>
            getEntityMappingByEntityId(entityId, mappingName)?.objectsIds ?? []
        )
      );
    }, [entitiesIds, getEntityMappingByEntityId]);

    return objects.length ? (
      <MultiObjectsColorizer
        objects={objects}
        color={color}
        opacity={opacity}
        priority={priority}
      />
    ) : null;
  }
);

export default ColorizeEntities;
