import config from "../../../config";
import { fetch, rejectNotOk } from "../../core/services/fetch.service";

export const login = ({ username, password, captcha }, signal) => {
  return fetch(
    `${config.api.baseUrl}/api/auth`,
    {
      method: "post",
      body: JSON.stringify({
        username,
        password,
        captcha,
      }),
      headers: {
        "content-type": "application/json",
      },
      signal,
    },
    false
  ).then(rejectNotOk.body);
};

export const logout = (signal) => {
  return fetch(`${config.api.baseUrl}/api/auth`, {
    method: "delete",
    signal,
  }).then(rejectNotOk.body);
};

export const refreshSession = (signal) => {
  return fetch(`${config.api.baseUrl}/api/auth`, {
    method: "patch",
    signal,
  }).then(rejectNotOk.body);
};
