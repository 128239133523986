enum AHUModeUnitEnum {
  OFF = 0,
  FAN = 1,
  COOLING = 2,
}

type Unit = {
  addSpace: boolean;
  display: string;
  id: string;
  isPrefix: boolean;
  name: string;
};

type Currency = Unit & {
  toUSD: number;
};

export { AHUModeUnitEnum };
export type { Unit, Currency };
