import withIconProps from "helpers/withIconProps";

const FanIcon = withIconProps(({ color, ...props }) => {
  const currentColor = color ?? "currentcolor";

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_3347_4566)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 9.5C9.32843 9.5 10 8.82843 10 8C10 7.17157 9.32843 6.5 8.5 6.5C7.67157 6.5 7 7.17157 7 8C7 8.82843 7.67157 9.5 8.5 9.5ZM8.5 11C10.1569 11 11.5 9.65685 11.5 8C11.5 6.34314 10.1569 5 8.5 5C6.84314 5 5.5 6.34314 5.5 8C5.5 9.65685 6.84314 11 8.5 11Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 7.72907L3.63938 6.27555C3.3113 5.92507 3.07801 5.49671 2.96158 5.03097C2.95906 5.0209 2.95666 5.01451 2.95522 5.01108L2.95361 5.00758C2.95044 5.0056 2.94129 5.00065 2.92547 4.99837C2.90964 4.9961 2.89946 4.99827 2.89586 4.99928L2.89334 5.00219C2.89099 5.00507 2.88689 5.01052 2.88164 5.01948L2.36582 5.89902C2.12628 6.30747 2 6.7724 2 7.24591C2 8.47553 3.37898 9.20101 4.39231 8.50451L5 8.08682V7.72907ZM0.5 7.24591C0.5 9.68322 3.23337 11.1213 5.24196 9.74066L6.5 8.87597V7.13655L4.73446 5.25046C4.58069 5.0862 4.47136 4.88544 4.41679 4.66716C4.08144 3.32577 2.28721 3.06795 1.58774 4.26065L1.07192 5.14019C0.697423 5.77875 0.5 6.50563 0.5 7.24591Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 8.27093L13.3606 9.72445C13.6887 10.0749 13.922 10.5033 14.0384 10.969C14.0409 10.9791 14.0433 10.9855 14.0448 10.9889L14.0464 10.9924C14.0496 10.9944 14.0587 10.9994 14.0745 11.0016C14.0904 11.0039 14.1005 11.0017 14.1041 11.0007L14.1067 10.9978C14.109 10.9949 14.1131 10.9895 14.1184 10.9805L14.6342 10.101C14.8737 9.69253 15 9.22759 15 8.75409C15 7.52447 13.621 6.79899 12.6077 7.49549L12 7.91318V8.27093ZM16.5 8.75409C16.5 6.31678 13.7666 4.87875 11.758 6.25933L10.5 7.12403V8.86345L12.2655 10.7495C12.4193 10.9138 12.5286 11.1146 12.5832 11.3328C12.9186 12.6742 14.7128 12.932 15.4123 11.7393L15.9281 10.8598C16.3026 10.2212 16.5 9.49436 16.5 8.75409Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.77093 4.5L10.2244 3.13938C10.5749 2.8113 11.0033 2.57801 11.469 2.46158C11.4791 2.45906 11.4855 2.45666 11.4889 2.45522L11.4924 2.45362C11.4944 2.45044 11.4994 2.44129 11.5016 2.42547C11.5039 2.40964 11.5017 2.39946 11.5007 2.39586L11.4978 2.39334C11.4949 2.39099 11.4895 2.38689 11.4805 2.38164L10.601 1.86582C10.1925 1.62628 9.72759 1.5 9.25409 1.5C8.02447 1.5 7.29899 2.87898 7.99549 3.89231L8.41318 4.5H8.77093ZM9.25409 0C6.81678 -4.26153e-07 5.37875 2.73337 6.75933 4.74196L7.62403 6L9.36345 6L11.2495 4.23446C11.4138 4.08069 11.6146 3.97136 11.8328 3.91679C13.1742 3.58144 13.432 1.78721 12.2393 1.08774L11.3598 0.571918C10.7212 0.197423 9.99436 1.29434e-07 9.25409 0Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.22907 11.5L6.77555 12.8606C6.42507 13.1887 5.99671 13.422 5.53097 13.5384C5.5209 13.5409 5.51451 13.5433 5.51108 13.5448L5.50758 13.5464C5.5056 13.5496 5.50065 13.5587 5.49837 13.5745C5.4961 13.5904 5.49827 13.6005 5.49928 13.6041L5.50219 13.6067C5.50507 13.609 5.51052 13.6131 5.51948 13.6184L6.39902 14.1342C6.80747 14.3737 7.2724 14.5 7.74591 14.5C8.97553 14.5 9.70101 13.121 9.00451 12.1077L8.58682 11.5H8.22907ZM7.74591 16C10.1832 16 11.6213 13.2666 10.2407 11.258L9.37597 10H7.63655L5.75046 11.7655C5.5862 11.9193 5.38545 12.0286 5.16717 12.0832C3.82577 12.4186 3.56795 14.2128 4.76065 14.9123L5.64019 15.4281C6.27875 15.8026 7.00563 16 7.74591 16Z"
          fill={currentColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_3347_4566">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default FanIcon;
