import fetch, { rejectNotOk } from "./fetch.service";

let config;
let iframe;
let pendingMessages = {};

let initializationPromise;

const getSiteKey = async () => {
  const localStorageStrValue = localStorage.recaptcha;
  let recaptachaLocalConfig;

  const getFromServerPromise = fetch("/api/config/recaptcha/site-key")
    .then(rejectNotOk.body)
    .then((siteKey) => {
      if (recaptachaLocalConfig) {
        recaptachaLocalConfig.siteKey = siteKey;
        recaptachaLocalConfig.lastUpdate = Date.now();
      } else {
        recaptachaLocalConfig = { siteKey, lastUpdate: Date.now() };
      }
      localStorage.recaptcha = JSON.stringify(recaptachaLocalConfig);
      return siteKey;
    });

  if (localStorageStrValue) {
    recaptachaLocalConfig = JSON.parse(localStorageStrValue);
    const localSiteKey = recaptachaLocalConfig.siteKey;
    getFromServerPromise
      .then((siteKey) => {
        if (siteKey !== localSiteKey) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log("error getting captcha from server", error);
      });
    return localSiteKey;
  }
  return getFromServerPromise;
};

export const initialize = async (
  reCaptchaConfig,
  doLoad = false,
  doRender = false
) => {
  config = reCaptchaConfig;
  config.siteKey = await getSiteKey();
  if (doLoad) {
    return load(doRender);
  }
};
const load = async (doRender = false) => {
  if (iframe) {
    return;
  }
  if (initializationPromise) {
    return initializationPromise;
  }

  return (initializationPromise = new Promise((resolve, reject) => {
    iframe = window.document.createElement("iframe");
    iframe.src = (config.serverUrl || "") + "/captcha.html";
    iframe.onerror = () => {
      iframe.remove();
      iframe = null;
      reject();
    };
    iframe.onload = () => {
      window.addEventListener("message", (e) => {
        if (e.data.id) {
          handleIFrameMessage(e.data);
        }
      });
      resolve();
    };
    iframeRestore(iframe);
    const body = document.getElementsByTagName("body")[0];
    body.appendChild(iframe);
  })
    .then(() => {
      return postMessageToIFrame("initialize", config);
    })
    .then(() => {
      if (doRender) {
        return render();
      }
    }));
};

const iframeRestore = () => {
  iframe.style.display = "";
  iframe.style.position = "absolute";
  iframe.style.bottom = "0";
  iframe.style.right = "0";
  iframe.style.top = "auto";
  iframe.style.left = "auto";
  iframe.style.width = "75px";
  iframe.style.height = "80px";
  iframe.style.border = "0";
  iframe.frameBorder = "0";
  iframe.style.zIndex = "999999";
};
const iframeMaximise = () => {
  iframe.style.display = "";
  iframe.style.position = "absolute";
  iframe.style.bottom = "0";
  iframe.style.right = "0";
  iframe.style.top = "0";
  iframe.style.left = "0";
  iframe.style.width = "100%";
  iframe.style.height = "100%";
  iframe.style.border = "0";
  iframe.frameBorder = "0";
  iframe.style.zIndex = "999999";
};

const handleIFrameMessage = ({ id, params }) => {
  const pendingMessage = pendingMessages[id];
  if (pendingMessage) {
    pendingMessage.callback.apply(null, params || []);
  }
};

const createPendingMessage = (callback) => {
  const id = Math.round(Math.random() * 999999) + "-" + Date.now();
  const ret = { id };
  if (!callback) {
    const result = {};
    callback = (error, data) => {
      if (result.resolve) {
        delete pendingMessages[id];
        if (error) {
          result.reject(error);
        } else {
          result.resolve(data);
        }
      } else {
        result.error = error;
        result.data = data;
        result.done = true;
      }
    };
    ret.promise = new Promise((resolve, reject) => {
      result.resolve = resolve;
      result.reject = reject;
      if (result.done) {
        callback(result.error, result.data);
      }
    });
    ret.callback = callback;
  } else {
    ret.callback = callback;
  }
  pendingMessages[id] = ret;
  return ret;
};

const postMessageToIFrame = (command, parameters) => {
  parameters = Object.assign({}, parameters);
  for (const i in parameters) {
    if (typeof parameters[i] === "function") {
      const pendingMessage = createPendingMessage(parameters[i]);
      parameters[i] = pendingMessage.id;
    }
  }
  const pendingMessage = createPendingMessage();
  iframe.contentWindow.postMessage(
    {
      id: pendingMessage.id,
      command,
      parameters,
    },
    "*"
  );
  return pendingMessage.promise;
};

export const execute = async () => {
  await load();
  iframeMaximise();
  return postMessageToIFrame("execute")
    .catch((err) => {
      console.error("error getting reCaptcha", err);
      throw { messageKey: "ERR_RECAPTCHA" };
    })
    .finally(() => iframeRestore());
};
export const render = async () => {
  await load();
  return postMessageToIFrame("render", {});
};
export const reset = async () => {
  await load();
  return postMessageToIFrame("reset", {});
};
export const destroy = () => {
  iframe.remove();
  iframe = null;
  initializationPromise = null;
  pendingMessages = {};
};
