import classNames from "classnames";
import { ScenarioFieldType } from "../ScenarioDetailsForm";
import CheckCircle from "svg-icons/CheckCircle";
import FormattedValue from "atomic-components/atoms/FormattedValue";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import PlusSquare from "svg-icons/PlusSquare";
import MinusSquare from "svg-icons/MinusSquare";
import { useTranslate } from "modules/language";
import Comment from "svg-icons/Comment";
import { useState } from "react";
import TextArea from "atomic-components/atoms/TextArea/TextArea";
import { ExternalLink } from "svg-icons";
import { useComplianceScoreValues } from "modules/building/hooks/useComplianceScoreValues";

import s from "./ScenarioFieldRow.module.scss";

type ScenarioFieldRowProps = {
  fieldsScores: Record<string, number | boolean>;
  field: ScenarioFieldType;
  setFieldScore: ({ key, score }: { key: string; score: number }) => void;
  fieldsComments: Record<string, string>;
  setFieldComment: ({ key, comment }: { key: string; comment: string }) => void;
  isEditingDisabled?: boolean;
};

const ScenarioFieldRow = ({
  fieldsScores,
  field,
  setFieldScore,
  fieldsComments,
  setFieldComment,
  isEditingDisabled,
}: ScenarioFieldRowProps) => {
  const comment = fieldsComments[field.key];

  const [isCommentOpen, setIsCommentOpen] = useState<boolean>(!!comment);

  const t = useTranslate();

  const { data } = useComplianceScoreValues();

  const modifyFieldScore = ({
    increment,
    decrement,
    fieldToModify: { key, minimumRequired = 0, maximumRequired },
  }: {
    increment?: boolean;
    decrement?: boolean;
    fieldToModify: ScenarioFieldType;
  }) => {
    const scenarioScore = fieldsScores[key];

    if (isEditingDisabled) return;

    if (
      increment &&
      maximumRequired &&
      (scenarioScore as number) >= maximumRequired
    )
      return;

    if (
      decrement &&
      (minimumRequired || minimumRequired === 0) &&
      (scenarioScore as number) <= minimumRequired
    )
      return;

    const newScore =
      (scenarioScore as number) + (increment ? 1 : 0) - (decrement ? 1 : 0);
    setFieldScore({ key: key, score: newScore });
  };

  const renderField = ({
    field: renderedField,
    isSubField,
  }: {
    field: ScenarioFieldType;
    isSubField?: boolean;
  }) => {
    const {
      key,
      title,
      fieldType,
      url,
      minimumRequired,
      maximumRequired,
      children,
    } = renderedField;

    let scenarioScore = fieldsScores[key];
    const currentScore = data?.[key];

    if (children) {
      scenarioScore = children
        .filter(({ fieldType }) => fieldType === "number")
        .reduce((acc, child) => acc + (fieldsScores[child.key] as number), 0);
    }

    const isIncrementDisabled =
      maximumRequired && (scenarioScore as number) >= maximumRequired;

    const isDecrementDisabled =
      (scenarioScore as number) <= (minimumRequired || 0);

    const increment = () =>
      modifyFieldScore({
        increment: true,
        fieldToModify: renderedField,
      });

    const decrement = () =>
      modifyFieldScore({
        decrement: true,
        fieldToModify: renderedField,
      });

    const isBooleanField = fieldType === "boolean";

    const hideComments = isSubField || isEditingDisabled;
    const hideModifyScoreButtons =
      isBooleanField || children || isEditingDisabled;

    return (
      <div
        key={key}
        className={classNames(s.fieldElementRow, {
          [s.subFieldElementRow]: isSubField,
        })}
        onKeyDown={(e) => {
          if (e.key === "ArrowUp") {
            e.preventDefault();
            increment();
          }
          if (e.key === "ArrowDown") {
            e.preventDefault();
            decrement();
          }
        }}
      >
        <span>
          {hideComments ? null : (
            <StrippedButton onClick={() => setIsCommentOpen(!isCommentOpen)}>
              <Comment
                fill={
                  isCommentOpen ? "var(--color-primary-xxlight)" : "transparent"
                }
              />
            </StrippedButton>
          )}
          <span className={s.fieldTitle}>{t(title)}</span>
          {url && (
            <a href={url} target="_blank" rel="noreferrer">
              <ExternalLink color="var(--color-primary)" iconScale={1} />
            </a>
          )}
        </span>
        <span>
          {isBooleanField ? (
            <CheckCircle />
          ) : (
            <>
              <FormattedValue value={currentScore} /> / {maximumRequired}
            </>
          )}
        </span>
        <span>
          <span>
            {isBooleanField ? (
              <CheckCircle />
            ) : (
              <>
                <b>
                  <FormattedValue value={scenarioScore} />
                </b>{" "}
                / {maximumRequired}
              </>
            )}
          </span>
          <span className={s.modifyScoreIconsContainer}>
            {hideModifyScoreButtons ? null : (
              <>
                <StrippedButton
                  onClick={increment}
                  className={classNames({
                    [s.notAllowed]: isIncrementDisabled,
                  })}
                >
                  <PlusSquare
                    fill={
                      isIncrementDisabled
                        ? "var(--gray-light-color)"
                        : "var(--color-primary)"
                    }
                  />
                </StrippedButton>
                <StrippedButton
                  onClick={decrement}
                  className={classNames({
                    [s.notAllowed]: isDecrementDisabled,
                  })}
                >
                  <MinusSquare
                    fill={
                      isDecrementDisabled
                        ? "var(--gray-light-color)"
                        : "var(--color-primary)"
                    }
                  />
                </StrippedButton>
              </>
            )}
          </span>
        </span>
      </div>
    );
  };

  return (
    <>
      <div className={s.fieldElementRowContainer} key={field.key}>
        {renderField({ field: field as ScenarioFieldType })}
        {field.children?.map((child) => {
          return renderField({
            field: child as ScenarioFieldType,
            isSubField: true,
          });
        })}
      </div>
      {isCommentOpen && !isEditingDisabled ? (
        <TextArea
          value={comment}
          onChange={(value) =>
            setFieldComment({ key: field.key, comment: value })
          }
          placeholder={t("message.ENTER_A_COMMENT")}
        />
      ) : null}
    </>
  );
};

export default ScenarioFieldRow;
