import { useEffect } from "react";
import { useViewerState } from "../Provider/Provider";

const DirectionalLight = ({
  color = 0xffffff,
  intensity = 1,
  position = { x: 605, y: 100, z: 398 },
  shadowMapSize = { width: 1024, height: 1024 },
  target = { x: 630, y: 0, z: 388 },
}) => {
  const { initialized, scene } = useViewerState();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    const dirLight = new window.THREE.DirectionalLight(color, intensity);
    dirLight.position.set(position.x, position.y, position.z);
    dirLight.castShadow = true;

    dirLight.shadow.mapSize.width = shadowMapSize.width;
    dirLight.shadow.mapSize.height = shadowMapSize.height;

    const d = 50;

    dirLight.shadow.camera.left = -d;
    dirLight.shadow.camera.right = d;
    dirLight.shadow.camera.top = d;
    dirLight.shadow.camera.bottom = -d;
    dirLight.shadow.camera.near = 0;
    dirLight.shadow.camera.far = 3500;

    dirLight.shadow.bias = -0.0001;

    const dirLightTarget = new window.THREE.Object3D();
    dirLightTarget.translateX(target.x);
    dirLightTarget.translateY(target.y);
    dirLightTarget.translateZ(target.z);
    dirLight.target = dirLightTarget;
    scene.add(dirLight);
    scene.add(dirLightTarget);
    dirLight.target.updateMatrixWorld();

    return () => {
      scene.remove(dirLightTarget);
      scene.remove(dirLight);
    };
  }, [
    initialized,
    scene,
    color,
    intensity,
    shadowMapSize.width,
    shadowMapSize.height,
    position.x,
    position.y,
    position.z,
    target.x,
    target.y,
    target.z,
  ]);

  return null;
};

export default DirectionalLight;
