import classNames from "classnames";
import React from "react";

const style = {
  fill: "none",
  stroke: "currentcolor",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: ".85px",
};
const Unhide = ({ className, icon = true }) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      viewBox="0 0 11 11"
    >
      <path
        style={style}
        d="M7.1,5.7c0,.88-.71,1.59-1.59,1.59s-1.59-.71-1.59-1.59,.71-1.59,1.59-1.59,1.59,.71,1.59,1.59Z"
      />
      <path
        style={style}
        d="M5.51,2C3.14,2,1.14,3.56,.46,5.7c.67,2.15,2.68,3.7,5.05,3.7s4.37-1.56,5.05-3.7c-.67-2.15-2.68-3.7-5.05-3.7Z"
      />
    </svg>
  );
};

export default Unhide;
