import { useQuery } from "react-query";
import { ReactQueryCacheKeys, queryClient } from "reactQuery";
import { getScenarios } from "../api/ComplianceScenarios";
import { type Scenario } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ComplianceTrackingPanel/Certificates/LeedOmCertificate/utils";
import { useEffect } from "react";
import { addToast } from "modules/toast";

const useComplianceScenarios = () => {
  const result: {
    isLoading: boolean;
    isError: boolean;
    data?: Scenario[];
  } = useQuery({
    queryKey: [ReactQueryCacheKeys.ComplianceScenarios],
    queryFn: ({ signal }) => getScenarios({}, { signal }),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (result.isError) {
      addToast({
        messageKey: "toast.AN_ERROR_HAPPENED",
        variant: "danger",
      });
    }
  }, [result.isError]);

  return {
    isLoading: result.isLoading,
    isError: result.isError,
    scenarios: result.data || [],
    activeScenario: result.data?.find((scenario) => scenario.isActive),
  };
};

const invalidateComplianceScenariosQuery = () => {
  queryClient.invalidateQueries({
    predicate: (query) => {
      return query.queryKey[0] === ReactQueryCacheKeys.ComplianceScenarios;
    },
  });
};

export { useComplianceScenarios, invalidateComplianceScenariosQuery };
