import classNames from "classnames";
import type { ButtonHTMLAttributes } from "react";
import s from "./StrippedButton.module.scss";

type StrippedButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isFlex?: boolean;
};

const StrippedButton = ({
  isFlex,
  className,
  ...props
}: StrippedButtonProps) => (
  <button
    className={classNames(
      s.strippedButton,
      {
        [s.flex]: isFlex,
      },
      className
    )}
    {...props}
  />
);

export default StrippedButton;
export type { StrippedButtonProps };
