import { useCallback } from "react";
import useBuildingData from "./useBuildingData";
import type { Unit } from "types/unit";

type GetTelemetryUnit = (params: {
  telemetryName: string;
  entityId: string;
}) => Unit;

const useTelemetryUnit = () => {
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;

  const getTelemetryUnit: GetTelemetryUnit = useCallback(
    ({
      telemetryName,
      entityId,
    }: {
      telemetryName: string;
      entityId: string;
    }) => {
      const entity = buildingData.entitiesById[entityId];

      if (!entity) {
        return unitsByName.none;
      }

      return entity.telemetriesByName[telemetryName]?.unit || unitsByName.none;
    },
    [buildingData.entitiesById, unitsByName.none]
  );

  return { getTelemetryUnit };
};

export default useTelemetryUnit;
export type { GetTelemetryUnit };
