import React, { useContext } from "react";

const HeightContext = React.createContext(0);

export const useHeight = () => useContext(HeightContext);

const HeightProvider = ({ height, children }) => {
  return (
    <HeightContext.Provider value={height}>{children}</HeightContext.Provider>
  );
};
export default HeightProvider;
