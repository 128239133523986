import withIconProps from "helpers/withIconProps";

const ChevronLeftIcon = withIconProps(({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      {...props}
    >
      <path
        d="M5.804738 8.19494C6.0650874 8.45529 6.0650874 8.8774 5.804738 9.13775C5.544388 9.3981 5.122278 9.3981 4.86193 9.13775L0.86193 5.13775C0.601577 4.8774 0.601577 4.45529 0.86193 4.19494L4.86193 0.194938C5.122278 -0.0654125 5.544388 -0.0654125 5.804738 0.194938C6.0650874 0.455287 6.0650874 0.877397 5.804738 1.13775L2.27614 4.66634L5.804738 8.19494Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ChevronLeftIcon;
