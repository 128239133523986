import { TelemetryRequest } from "modules/building/hooks/useLinkedData";

const createDefinition = (definition: {
  telemetryName: string;
  telemetryRequest: TelemetryRequest;
  fallbackValue: number;
}) => {
  return definition;
};

const definitions = {
  electricityEmissionIntensity: createDefinition({
    telemetryName: "electricityEmissionIntensity",
    telemetryRequest: "electricityEmissionIntensity",
    fallbackValue: 0.43,
  }),
  chillerPlantCOP: createDefinition({
    telemetryName: "chillerPlantCOP",
    telemetryRequest: "chillerPlantCOP",
    fallbackValue: 4.5,
  }),
  printedPaperEmissionsFactor: createDefinition({
    telemetryName: "printedPaperCF",
    telemetryRequest: "printedPaperCF",
    fallbackValue: 0.92,
  }),
  tissuesEmissionsFactor: createDefinition({
    telemetryName: "tissuesCF",
    telemetryRequest: "tissuesCF",
    fallbackValue: 1.9,
  }),
  toiletPaperEmissionsFactor: createDefinition({
    telemetryName: "toiletPaperCF",
    telemetryRequest: "toiletPaperCF",
    fallbackValue: 1.9,
  }),
  paperCupsEmissionsFactor: createDefinition({
    telemetryName: "paperCupsCF",
    telemetryRequest: "paperCupsCF",
    fallbackValue: 0.11,
  }),
  plasticCupsEmissionsFactor: createDefinition({
    telemetryName: "plasticCupsCF",
    telemetryRequest: "plasticCupsCF",
    fallbackValue: 0.02,
  }),
  solidWasteEmissionsFactor: createDefinition({
    telemetryName: "solidWasteCF",
    telemetryRequest: "solidWasteCF",
    fallbackValue: 0.47,
  }),
  dieselEmissionsConversionFactor: createDefinition({
    telemetryName: "dieselEmissionsCF",
    telemetryRequest: "dieselEmissionsCF",
    fallbackValue: 2.3,
  }),
  carsTransportDistribution: createDefinition({
    telemetryName: "carsTransportDistribution",
    telemetryRequest: "carsTransportDistribution",
    fallbackValue: 85,
  }),
  busesTransportDistribution: createDefinition({
    telemetryName: "busesTransportDistribution",
    telemetryRequest: "busesTransportDistribution",
    fallbackValue: 15,
  }),
  carpoolingTransportDistribution: createDefinition({
    telemetryName: "carpoolingTransportDistribution",
    telemetryRequest: "carpoolingTransportDistribution",
    fallbackValue: 0,
  }),
  avgEmployeeKmTraveled: createDefinition({
    telemetryName: "avgEmployeeKmTraveled",
    telemetryRequest: "avgEmployeeKmTraveled",
    fallbackValue: 40,
  }),
  avgEmployeeCarsFuelEconomy: createDefinition({
    telemetryName: "avgEmployeeCarsFuelEconomy",
    telemetryRequest: "avgEmployeeCarsFuelEconomy",
    fallbackValue: 0.196,
  }),
  busEmissionPerEmployee: createDefinition({
    telemetryName: "busEmissionPerEmployee",
    telemetryRequest: "busEmissionPerEmployee",
    fallbackValue: 0.034,
  }),
  avgEmployeeOccupantsPerBus: createDefinition({
    telemetryName: "avgEmployeeOccupantsPerBus",
    telemetryRequest: "avgEmployeeOccupantsPerBus",
    fallbackValue: 9.1,
  }),
  avgCompanyCarsFuelEconomy: createDefinition({
    telemetryName: "avgCompanyCarsFuelEconomy",
    telemetryRequest: "avgCompanyCarsFuelEconomy",
    fallbackValue: 0.196,
  }),
  avgCompanyBusesFuelEconomy: createDefinition({
    telemetryName: "avgCompanyBusesFuelEconomy",
    telemetryRequest: "avgCompanyBusesFuelEconomy",
    fallbackValue: 0.25,
  }),
  refrigerantLeakageRate: createDefinition({
    telemetryName: "refrigerantLeakageRate",
    telemetryRequest: "refrigerantLeakageRate",
    fallbackValue: 2,
  }),
  refrigerantMassACUnit: createDefinition({
    telemetryName: "refrigerantMassACUnit",
    telemetryRequest: "refrigerantMassACUnit",
    fallbackValue: 0.389,
  }),
  globalWarmingPotentialR22: createDefinition({
    telemetryName: "globalWarmingPotentialR22",
    telemetryRequest: "globalWarmingPotentialR22",
    fallbackValue: 1780,
  }),
  globalWarmingPotentialR410a: createDefinition({
    telemetryName: "globalWarmingPotentialR410a",
    telemetryRequest: "globalWarmingPotentialR410a",
    fallbackValue: 1890,
  }),
  globalWarmingPotentialR407c: createDefinition({
    telemetryName: "globalWarmingPotentialR407c",
    telemetryRequest: "globalWarmingPotentialR407c",
    fallbackValue: 1700,
  }),
  lifeTimeDirectExpansionUnit: createDefinition({
    telemetryName: "lifeTimeDirectExpansionUnit",
    telemetryRequest: "lifeTimeDirectExpansionUnit",
    fallbackValue: 15,
  }),
  domesticWaterEmissionsCF: createDefinition({
    telemetryName: "domesticWaterEmissionsCF",
    telemetryRequest: "domesticWaterEmissionsCF",
    fallbackValue: 0.17,
  }),
  endoflifeRefrigerantLoss: createDefinition({
    telemetryName: "endoflifeRefrigerantLoss",
    telemetryRequest: "endoflifeRefrigerantLoss",
    fallbackValue: 10,
  }),
};

type ParamName = keyof typeof definitions;

const definitionsArray = Object.entries(definitions).map(([key, value]) => {
  return {
    name: key,
    ...value,
  };
}) as ((typeof definitions)[ParamName] & { name: ParamName })[];

export { definitions, definitionsArray };
export type { ParamName };
