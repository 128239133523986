import React, { useEffect, useMemo } from "react";
import computeTexture, {
  estimateValues,
  getUVAndWorldConverterFunctions,
  computeUVCoord,
  defaultEstimateValue,
} from "helpers/TextureHelpers";
import { useViewerState } from "modules/viewer/components/Provider";
import useBuildingData from "modules/building/hooks/useBuildingData";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import useFullSelectedTimestamp from "modules/building/hooks/useFullSelectedTimestamp";
import { useModelState } from "../../Model";
import { meanBy } from "lodash";
import flattenWithChildren from "helpers/flattenWithChildren";
import { useMemoedCurrentLinkedData } from "modules/building/hooks/useLinkedData";
import isRealNumber from "helpers/isRealNumber";
import ObjectTexturizer from "modules/viewer/components/ObjectTexturizer";
import config from "../../../../../../config";

const filterSensors = (sensorsPositions, sensorsReadings) => {
  let filteredSensors = [],
    filteredSensorsReadings = [];
  for (let index = 0; index < sensorsReadings.length; index++) {
    if (isRealNumber(sensorsReadings[index])) {
      filteredSensors.push(sensorsPositions[index]);
      filteredSensorsReadings.push(sensorsReadings[index]);
    }
  }
  return [filteredSensors, filteredSensorsReadings];
};
const capReadings = (sensorsReadings, valuesMinMax) => {
  let correctedReadings = [];
  for (let index = 0; index < sensorsReadings.length; index++) {
    if (sensorsReadings[index] > valuesMinMax.max) {
      correctedReadings.push(valuesMinMax.max);
    } else if (sensorsReadings[index] < valuesMinMax.min) {
      correctedReadings.push(valuesMinMax.min);
    } else {
      correctedReadings.push(sensorsReadings[index]);
    }
  }
  return correctedReadings;
};
const TEXTURE_WIDTH = 128;
const TEXTURE_HEIGHT = 128;

const TextureHeatmap = ({
  entityType,
  mappingName = "default",
  telemetryName,
  colorTweener,
  priority = 0,
  valuesMinMax = undefined,
  estimateValueFunction = defaultEstimateValue,
  confidenceDecayFactor = undefined,
  globalAlpha = 0.5,
}) => {
  const { buildingData } = useBuildingData();
  const selectedEntity = useSelectedEntity(buildingData);
  const { getEntityMappingByEntityId } = useModelState();
  const { objects } = useViewerState();
  const mapping = getEntityMappingByEntityId(selectedEntity.id, mappingName);
  const objectToTexturize = useMemo(() => {
    const objectsIds = mapping.objectsIds;
    for (let i = 0; i < objectsIds.length; i++) {
      const objectId = objectsIds[i];
      const object = objects.find(
        (object) =>
          object.id === objectId ||
          object.uuid === objectId ||
          object.name === objectId
      );
      if (object) {
        return object;
      }
    }
  }, [mapping, objects]);
  const { fromWorldSpaceToUvSpace, fromUvSpaceToWorldSpace } = useMemo(() => {
    return getUVAndWorldConverterFunctions(objectToTexturize);
  }, [objectToTexturize]);

  useEffect(() => {
    computeUVCoord(objectToTexturize, fromWorldSpaceToUvSpace);
  }, [objectToTexturize, fromWorldSpaceToUvSpace]);

  const { selectedTimestampIndex } = useFullSelectedTimestamp();
  const [sensorEntities, sensorsPositions] = useMemo(() => {
    const allSensorsEntities = flattenWithChildren(selectedEntity).filter(
      ({ type }) => type === entityType
    );
    const sensorsEntitiesAndObjects = allSensorsEntities
      .map((entity) => {
        const objectsIds =
          getEntityMappingByEntityId(entity.id, mappingName)?.objectsIds ?? [];
        const sensorObjects = objectsIds
          .map((id) =>
            objects.find(
              (object) =>
                object.id === id || object.uuid === id || object.name === id
            )
          )
          .filter(Boolean);
        if (sensorObjects.length < objectsIds.length) {
          console.error(`entity have missing objectsIds`, {
            entity,
            mappingName,
            getEntityMappingByEntityId,
          });
          if (!config.isProd) {
            throw new Error(`entity have missing objectsIds`);
          }
        }

        if (!sensorObjects.length) {
          return null;
        }
        return { entity, objects: sensorObjects };
      })
      .filter(Boolean);
    if (sensorsEntitiesAndObjects.length < allSensorsEntities.length) {
      console.error(`some entities does not have entity mappings`, {
        allSensorsEntities,
        mappingName,
        getEntityMappingByEntityId,
      });
      if (!config.isProd) {
        throw new Error(`some entities does not have entity mappings`);
      }
    }
    const sensorEntities = sensorsEntitiesAndObjects.map(
      ({ entity }) => entity
    );
    const sensorsPositions = sensorsEntitiesAndObjects.map(({ objects }) => {
      const positions = objects.map((object) => object.position);
      const x = meanBy(positions, "x");
      const z = meanBy(positions, "z");
      return { x, z };
    });
    return [sensorEntities, sensorsPositions];
  }, [selectedEntity, entityType]);

  const { telemetries: telemetriesByEntityId } = useMemoedCurrentLinkedData(
    () =>
      sensorEntities.map((entity) => ({
        entityId: entity.id,
        telemetries: [telemetryName],
      })),
    [sensorEntities, telemetryName]
  );

  const textureData = useMemo(() => {
    const sensorsReadings = sensorEntities.map(
      (sensor) =>
        telemetriesByEntityId[sensor.id][telemetryName].data[
          selectedTimestampIndex
        ].value
    );

    const [filteredSensorsPositions, filteredSensorsReadings] = filterSensors(
      sensorsPositions,
      sensorsReadings
    );
    const correctedReadings = valuesMinMax
      ? capReadings(filteredSensorsReadings, valuesMinMax)
      : filteredSensorsReadings;

    const estimatedValuesWithConfidence = estimateValues(
      TEXTURE_WIDTH,
      TEXTURE_HEIGHT,
      filteredSensorsPositions,
      correctedReadings,
      fromUvSpaceToWorldSpace,
      estimateValueFunction,
      confidenceDecayFactor
    );
    const data = computeTexture(
      TEXTURE_WIDTH,
      TEXTURE_HEIGHT,
      estimatedValuesWithConfidence,
      colorTweener,
      globalAlpha
    );
    const textureData = new window.THREE.DataTexture(
      data,
      TEXTURE_WIDTH,
      TEXTURE_HEIGHT
    );

    textureData.needsUpdate = true;

    return textureData;
  }, [
    selectedEntity,
    sensorsPositions,
    telemetriesByEntityId,
    telemetryName,
    selectedTimestampIndex,
    sensorEntities,
    estimateValueFunction,
    confidenceDecayFactor,
    globalAlpha,
  ]);

  return (
    <ObjectTexturizer
      key={objectToTexturize.name ?? objectToTexturize}
      object={objectToTexturize}
      texture={textureData}
      priority={priority}
    />
  );
};

export default TextureHeatmap;
