import type { HTMLAttributes, MouseEventHandler } from "react";
import { useModalState } from "../ModalProvider";
import classNames from "classnames";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { Close } from "svg-icons";

import s from "./ModalHeader.module.scss";

type ModalHeaderProps = HTMLAttributes<HTMLElement> & {
  cancel?: {
    text: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
  };
  color?: "auto" | "primary";
};

const ModalHeader = ({
  cancel,
  color = "auto",
  className,
  children,
  ...props
}: ModalHeaderProps) => {
  const { hideModal } = useModalState();

  return (
    <header
      className={classNames(s.header, [s[`color--${color}`]], className)}
      {...props}
    >
      {children}
      {cancel ? (
        <StrippedButton
          className={s.closeBtn}
          title={cancel.text}
          formAction="dialog"
          onClick={(e) => {
            cancel.onClick?.(e);
            hideModal();
          }}
        >
          <Close aria-hidden />
        </StrippedButton>
      ) : null}
    </header>
  );
};

export default ModalHeader;
export type { ModalHeaderProps };
