import withIconProps from "helpers/withIconProps";
import type { SVGAttributes } from "react";

const stylesByDirection = {
  ascending: {},
  descending: { transform: "rotate(180deg)" },
};

type Props = SVGAttributes<SVGElement> & {
  dir?: "ascending" | "descending";
};

const SortArrowsIcon = withIconProps<Props>(
  ({ color, dir = "ascending", style, ...props }: Props) => {
    const currentColor = color ?? "currentcolor";
    const fillColor = "var(--background-color)";

    return (
      <svg
        width="7"
        height="12"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        style={{
          ...style,
          transformBox: "fill-box",
          ...stylesByDirection[dir],
        }}
      >
        <path
          d="M3.90962 11.4148C3.71057 11.6992 3.28943 11.6992 3.09038 11.4148L0.550712 7.78673C0.31874 7.45534 0.555816 7 0.960328 7H6.03967C6.44418 7 6.68126 7.45534 6.44929 7.78673L3.90962 11.4148Z"
          fill={fillColor}
        />
        <path
          d="M3.90962 0.585167C3.71057 0.300808 3.28943 0.300807 3.09038 0.585166L0.550712 4.21327C0.31874 4.54466 0.555816 5 0.960328 5H6.03967C6.44418 5 6.68126 4.54466 6.44929 4.21327L3.90962 0.585167Z"
          fill={currentColor}
        />
      </svg>
    );
  }
);

export default SortArrowsIcon;
