import { NavLink, type NavLinkProps } from "react-router-dom";
import {
  type SearchParams,
  createLink,
  type RouteSegments,
} from "router/utils";
import useRouteSegments from "hooks/useRouteSegments";
import { PageUrlEnum } from "router/enums";
import classNames from "classnames";

import s from "./Link.module.scss";

type LinkProps = Omit<NavLinkProps, "to"> & {
  pageUrl?: PageUrlEnum;
  searchParams?: SearchParams;
  disabled?: boolean;
  keepSegments?: boolean;
};

const Link = ({
  pageUrl,
  searchParams,
  disabled,
  className,
  onClick,
  keepSegments = true,
  ...props
}: LinkProps) => {
  let segments = useRouteSegments();

  if (!keepSegments) {
    segments = Object.fromEntries(
      Object.entries(segments).map(([key]) => [key, ""])
    ) as RouteSegments;
  }

  return (
    <NavLink
      to={
        pageUrl
          ? createLink({
              segments: {
                ...segments,
                pageUrl,
              },
              keepSearchParams: !!searchParams,
              keepHash: true,
              searchParams,
            })
          : "#"
      }
      className={(args) =>
        classNames(
          s.link,
          typeof className === "string" ? className : className?.(args)
        )
      }
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
        onClick?.(e);
      }}
      aria-disabled={disabled}
      {...props}
    />
  );
};

export default Link;
export type { LinkProps, SearchParams };
