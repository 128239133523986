import React, { useEffect, useRef, useState } from "react";
import "./ReactiveViewer.css";

import classNames from "classnames";

import ViewerProvider, {
  usePrerenderEmmiter,
  usePostrenderEmmiter,
  useViewerDispatcher,
  useViewerState,
} from "../Provider";

import {
  setDimentions,
  setInitialized,
} from "../../state/viewer.context-actions";
import modifyObject from "../../helpers/modifyObjects";
import { ResponsiveContainer } from "../../../../atomic-components/organisms/Charts";

/**
 * @typedef {object} Vector3
 * @property {number} x
 * @property {number} y
 * @property {number} z
 *
 * @typedef {object} CameraConfig
 * @property {number} [fov] defaults to 60
 * @property {Vector3} [initPosition] defaults to 0,0,0
 * @property {Vector3} [initLookAt] defaults to 0,0,-1
 * @property {Vector3} [defaultUp] defaults to 0,1,0
 *
 * @typedef {object} ReactiveViewerProps
 * @property {number} [aspectRatio] defaults to 2.5
 * @property {number} [height] overrides aspectRatio
 * @property {CameraConfig} [cameraConfig]
 * @property {boolean} [shadowsEnabled]
 */

/** @type {React.FunctionComponent<ReactiveViewerProps>} */
const ReactiveViewer = ({
  className,
  children,
  aspectRatio = 2.5,
  aspectRatioBase = "x",
  height: heightFromParent,
  width: widthFromParent,
  fullScreen,
  cameraConfig,
  shadowsEnabled,
}) => {
  const {
    PerspectiveCamera,
    WebGLRenderer,
    ACESFilmicToneMapping,
    sRGBEncoding,
    Scene,
  } = window.THREE;

  const canvasRef = useRef();
  const canvas2DRef = useRef();

  const { width, height, scene, renderer, camera, cameraControls, objects } =
    useViewerState();

  const dispatchViewerAction = useViewerDispatcher();

  const emmitPrerender = usePrerenderEmmiter();
  const emmitPostrender = usePostrenderEmmiter();

  // shadow enabler/disabler
  useEffect(() => {
    if (!objects) {
      return;
    }

    objects.forEach((object) => {
      const modify = (object) => {
        if (object.material && object.material.opacity !== 1) {
          return;
        }
        object.castShadow = true;
        object.receiveShadow = true;
      };
      modifyObject(object, modify, modify);
    });

    return () => {
      objects.forEach((object) => {
        const modify = (object) => {
          if (object.material && object.material.opacity !== 1) {
            return;
          }
          object.castShadow = false;
          object.receiveShadow = false;
        };
        modifyObject(object, modify, modify);
      });
    };
  }, [objects, shadowsEnabled]);

  // scene initializer
  useEffect(() => {
    const canvas = canvasRef.current;
    const canvas2D = canvas2DRef.current;

    const scene = new Scene();
    scene.background = new window.THREE.Color(0xf2f6fe);

    const width = canvas.offsetWidth;
    const height = canvas.offsetHeight;

    const renderer = new WebGLRenderer({ antialias: true, canvas });
    renderer.physicallyCorrectLights = false;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearAlpha(0);
    renderer.setSize(width, height);
    renderer.toneMapping = ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
    renderer.outputEncoding = sRGBEncoding;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = 2;
    renderer.autoClear = true;

    const camera = new PerspectiveCamera(
      cameraConfig?.fov ?? 60,
      width / height,
      0.25,
      1000
    );
    if (cameraConfig?.initPosition) {
      camera.position.set(
        cameraConfig.initPosition.x,
        cameraConfig.initPosition.y,
        cameraConfig.initPosition.z
      );
    }
    if (cameraConfig?.initLookAt) {
      camera.lookAt(
        cameraConfig.initLookAt.x,
        cameraConfig.initLookAt.y,
        cameraConfig.initLookAt.z
      );
    }
    if (cameraConfig?.defaultUp) {
      camera.up.set(
        cameraConfig.defaultUp.x,
        cameraConfig.defaultUp.y,
        cameraConfig.defaultUp.z
      );
    } else camera.up.set(0, 1, 0);

    dispatchViewerAction(
      setInitialized(scene, renderer, camera, canvas, canvas2D)
    );
  }, []);

  // runner
  useEffect(() => {
    if (!scene || !renderer || !camera) {
      return;
    }
    let timer;
    const run = () => {
      timer = requestAnimationFrame(run);
      if (cameraControls) {
        cameraControls.update();
      }
      emmitPrerender({ scene, camera, renderer });
      renderer.render(scene, camera);
      emmitPostrender({ scene, camera, renderer });
    };
    run();
    return () => cancelAnimationFrame(timer);
  }, [scene, renderer, camera, cameraControls]);

  // resize listener
  const onResize = ({ width, height }) => {
    dispatchViewerAction(setDimentions(width, height));
  };

  // resizer
  useEffect(() => {
    if (!camera || !renderer || width == null || height == null) {
      return;
    }
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  }, [camera, renderer, width, height]);

  return (
    <ResponsiveContainer
      height={heightFromParent}
      onResize={onResize}
      width={widthFromParent}
      aspectRatio={aspectRatio}
      aspectRatioBase={aspectRatioBase}
      className={classNames("reactive-viewer", {
        "reactive-viewer--full-screen": fullScreen,
        [className]: className,
      })}
    >
      <div className="reactive-viewer__inner">
        <canvas
          className="reactive-viewer__canvas"
          ref={canvasRef}
          width={width}
          height={height}
        />
        <canvas
          className="reactive-viewer__canvas"
          ref={canvas2DRef}
          width={width}
          height={height}
        />
        {children}
      </div>
    </ResponsiveContainer>
  );
};

const withThreeAndHelpersEnsured = (BaseComponent) => (props) => {
  const [THREEHelpers, setTHREEHelpers] = useState(window.THREEHelpers);
  if (THREEHelpers) {
    return <BaseComponent {...props} />;
  }
  const THREEHELPERS_LISTENERS =
    window.THREEHELPERS_LISTENERS || (window.THREEHELPERS_LISTENERS = []);
  if (!THREEHELPERS_LISTENERS.find((fn) => fn === setTHREEHelpers)) {
    THREEHELPERS_LISTENERS.push(setTHREEHelpers);
  }
  return null;
};

/** @type {React.FunctionComponent<ReactiveViewerProps>} */
export default withThreeAndHelpersEnsured((props) => {
  return (
    <ViewerProvider>
      <ReactiveViewer {...props} />
    </ViewerProvider>
  );
});
