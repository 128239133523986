import ModalHeader from "atomic-components/molecules/Modal/ModalHeader";
import { useTranslate } from "modules/language";
import CertificateInfoSummary from "../../SustainabilityBuildingPage/components/ComplianceTrackingPanel/Certificates/LeedOmCertificate/CertificateInfoSummary/CertificateInfoSummary";
import ModalFooter from "atomic-components/molecules/Modal/ModalFooter";
import Button from "atomic-components/atoms/Buttons/Button";
import { useModalState } from "atomic-components/molecules/Modal";
import ComplianceTrackingProgressBar from "../../SustainabilityBuildingPage/components/ComplianceTrackingPanel/Certificates/LeedOmCertificate/CompliantTrackingProgressBar/ComplianceTrackingProgressBar";
import ScenarioDetailsForm, {
  ScenarioFieldType,
} from "./ScenarioDetailsForm/ScenarioDetailsForm";
import SaveScenarioModal from "./SaveScenarioModal/SaveScenarioModal";
import LoadScenarioModal from "./LoadScenarioModal/LoadScenarioModal";
import { useState } from "react";
import { type Scenario } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ComplianceTrackingPanel/Certificates/LeedOmCertificate/utils";
import { useUpdateScenario } from "modules/building/hooks/useComplianceScenariosMutate";
import {
  getSubFields,
  getTotalScenarioScore,
} from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ComplianceTrackingPanel/Certificates/LeedOmCertificate/Scenarios/utils";
import { flatten } from "lodash";
import { complianceScoresContent } from "modules/building/pages/SustainabilityPage/utils";
import { useAuthenticationState } from "modules/authentication/state/authentication.state";
import { useComplianceScoreValues } from "modules/building/hooks/useComplianceScoreValues";

import s from "./ScenarioDetailsModal.module.scss";

type ScenarioDetailsModalProps = {
  isPreview?: boolean;
  scenario?: Omit<Scenario, "id" | "title"> & {
    id?: string;
    title?: string;
  };
};

const ScenarioDetailsModal = ({
  isPreview,
  scenario,
}: ScenarioDetailsModalProps) => {
  const t = useTranslate();
  const { roles } = useAuthenticationState();

  const { data: complianceScoreValues } = useComplianceScoreValues();

  const isAdmin = !!roles?.find((role) => role.name === "admin");
  const isEditingDisabled = !isAdmin;

  const categoriesFamily = complianceScoresContent.filter(
    ({ key }) => key !== "total"
  );

  const mainFields = flatten(categoriesFamily.map(({ children }) => children));
  const subFields = flatten(
    mainFields.map((field) => getSubFields(field as ScenarioFieldType))
  );
  const scenarioFields = [...mainFields, ...subFields];

  const [fieldsScores, setFieldsScores] = useState<
    Record<string, number | boolean>
  >(
    scenarioFields
      .filter((field) => field?.fieldType === "number")
      .reduce(
        (acc, field) => ({
          ...acc,
          [String(field?.key)]:
            scenario?.targetValues?.[field?.key as string] ||
            field?.minimumRequired,
        }),
        {}
      )
  );

  const [fieldsComments, setFieldsComments] = useState<Record<string, string>>(
    scenarioFields.reduce(
      (acc, field) => ({
        ...acc,
        [String(field?.key)]: scenario?.comments?.[field?.key as string] || "",
      }),
      {}
    )
  );

  const [mainComment, setMainComment] = useState(scenario?.comment || "");

  const { setModal, hideModal } = useModalState();

  const totalScore = getTotalScenarioScore({
    fieldsScores,
    scenarioFields: scenarioFields as ScenarioFieldType[],
  });

  const scenarioData = {
    title: scenario?.title || "",
    isActive: false,
    isPinned: false,
    comment: mainComment,
    targetValues: fieldsScores,
    comments: fieldsComments,
  };

  const { mutateAsync: updateScenario, isLoading } = useUpdateScenario({
    id: scenario?.id as string,
    ...scenarioData,
  });

  const onClickLoadScenario = () => {
    setModal(<LoadScenarioModal scenarioData={scenarioData} />, {
      width: "medium",
    });
  };

  const onClickSaveScenario = () => {
    setModal(<SaveScenarioModal scenarioData={scenarioData} />, {
      width: "medium",
    });
  };

  const onClickSubmitScenario = () => {
    if (isPreview) {
      updateScenario().then(hideModal);
    } else {
      setModal(
        <SaveScenarioModal isSubmit={true} scenarioData={scenarioData} />,
        {
          width: "medium",
        }
      );
    }
  };

  const isSubmitDisabled = () =>
    isPreview &&
    Object.keys(fieldsScores).every(
      (key) => fieldsScores[key] === scenario?.targetValues[key]
    ) &&
    Object.keys(fieldsComments).every(
      (key) => fieldsComments[key] === scenario?.comments[key]
    ) &&
    mainComment === scenario?.comment;

  return (
    <>
      <ModalHeader
        color="primary"
        cancel={{
          text: t("action.CANCEL"),
          onClick: hideModal,
        }}
      >
        <h3 className={s.headerText}>
          {isPreview ? scenario?.title : t("label.ADD_SCENARIO")}
        </h3>
      </ModalHeader>
      <div className={s.modalBody}>
        <div>
          {isPreview ? null : (
            <p className={s.hint}>{t("message.ADD_SCENARIO_HINT")}</p>
          )}
          <div className={s.modalContents}>
            <CertificateInfoSummary
              targetScore={complianceScoreValues.target}
            />
            <ComplianceTrackingProgressBar
              targetScore={complianceScoreValues.target}
            />
            <ScenarioDetailsForm
              fieldsScores={fieldsScores}
              setFieldsScores={setFieldsScores}
              mainComment={mainComment}
              setMainComment={setMainComment}
              fieldsComments={fieldsComments}
              setFieldsComments={setFieldsComments}
              totalScore={totalScore}
              isEditingDisabled={isEditingDisabled}
            />
          </div>
        </div>
        {isEditingDisabled ? null : (
          <ModalFooter>
            {isPreview ? null : (
              <Button
                skin="secondary"
                onClick={onClickLoadScenario}
                className={s.actionButton}
                size="large"
              >
                {t("action.LOAD")}
              </Button>
            )}
            <Button
              skin="secondary"
              onClick={onClickSaveScenario}
              className={s.actionButton}
              size="large"
            >
              {t("action.SAVE_AS")}
            </Button>
            <Button
              skin="primary"
              onClick={onClickSubmitScenario}
              className={s.actionButton}
              isLoading={isLoading}
              disabled={isSubmitDisabled()}
              size="large"
            >
              {t("action.SUBMIT")}
            </Button>
          </ModalFooter>
        )}
      </div>
    </>
  );
};

export default ScenarioDetailsModal;
