import type { BottomPanelType } from "modules/building/components/BottomPanel/BottomPanel";
import FloorSelector from "modules/building/components/FloorSelector";
import MonthsSlider from "modules/building/components/MonthsSlider/MonthsSlider";
import PageHeaderDateSelector from "modules/building/components/PageHeaderDateSelector/PageHeaderDateSelector";
import useBuildingData from "modules/building/hooks/useBuildingData";
import { Translator, useTranslate } from "modules/language";
import BuildingEmissionsPanel from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/EmissionsPanel/EmissionsPanel";
import BuildingComplianceTrackingPanel from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ComplianceTrackingPanel/ComplianceTrackingPanel";
import ElectricityBreakdown from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/EmissionsPanel/BreakdownBySource/Breakdowns/ElectricityBreakdown/ElectricityBreakdown";
import ChilledWaterBreakdown from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/EmissionsPanel/BreakdownBySource/Breakdowns/ChilledWaterBreakdown/ChilledWaterBreakdown";
import TravelBreakdown from "./SustainabilityBuildingPage/components/EmissionsPanel/BreakdownBySource/Breakdowns/TravelBreakdown/TravelBreakdown";
import WasteBreakdown from "./SustainabilityBuildingPage/components/EmissionsPanel/BreakdownBySource/Breakdowns/WasteBreakdown/WasteBreakdown";
import ConsumablesBreakdown from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/EmissionsPanel/BreakdownBySource/Breakdowns/ConsumablesBreakdown/ConsumablesBreakdown";
import FuelBreakdown from "./SustainabilityBuildingPage/components/EmissionsPanel/BreakdownBySource/Breakdowns/FuelBreakdown/FuelBreakdown";
import { usePageState } from "modules/building/components/ModelPageLayout";
import RefrigerantBreakdown from "./SustainabilityBuildingPage/components/EmissionsPanel/BreakdownBySource/Breakdowns/RefrigerantBreakdown/RefrigerantBreakdown";
import Slider from "atomic-components/atoms/Slider";
import { useViewState } from "modules/building/providers/ViewProvider/ViewProvider";
import { fromMillis } from "helpers/dateTimeHelper";
import DomesticWaterBreackdown from "./SustainabilityBuildingPage/components/EmissionsPanel/BreakdownBySource/Breakdowns/DomesticWaterBreackdown/DomesticWaterBreackdown";
import {
  RequisiteEnum,
  type ComplianceScore,
  SubComplianceScore,
  ComplianceScoreValues,
} from "./types";
import type { ComplianceScoreKey } from "modules/building/api/complianceScores";
import {
  calculateComplianceScoresTotal,
  minimumComplianceScoreValues,
  complianceCertificates,
  getCertificate,
} from "modules/building/helpers/compliaceScores";
import EmissionsBottomPanel from "modules/building/components/BottomPanel/EmissionsBottomPanel/EmissionsBottomPanel";

enum SustainabilityRightTabEnum {
  EMISSIONS = "emissions",
  COMPLIANCE_TRACKING = "compliance-tracking",
}

const title = <Translator translationKey="menu.SUSTAINABILITY" />;

const HeaderContent = () => {
  const { buildingData } = useBuildingData();
  const { selectedRightTabKey } = usePageState();

  return (
    <>
      <FloorSelector
        buildingData={buildingData}
        disabledOptionNames={[
          "B3",
          "B2",
          "B1",
          "GF",
          "F1",
          "F2",
          "F3",
          "F4",
          "F5",
          "RF",
        ]}
      />
      <PageHeaderDateSelector
        view={
          selectedRightTabKey === SustainabilityRightTabEnum.COMPLIANCE_TRACKING
            ? "decade"
            : "year"
        }
      />
    </>
  );
};

const RightPanelTitle = () => {
  const t = useTranslate();
  const {
    buildingData: { timezone },
  } = useBuildingData();
  const { selectedRightTabKey } = usePageState();
  const { fromTs } = useViewState();
  const year = fromMillis(fromTs, { zone: timezone, resetTime: true }).year;

  return selectedRightTabKey ===
    SustainabilityRightTabEnum.COMPLIANCE_TRACKING ? (
    <Slider
      disabled
      label={
        <>
          {t("message.DISPLAYED_DATE_FOR")} <strong>{year}</strong>
        </>
      }
      value={1}
      setValue={() => {}}
      min={1}
      max={1}
      step={1}
    />
  ) : (
    <MonthsSlider />
  );
};

const buildingRightPanelTabs = [
  {
    key: SustainabilityRightTabEnum.EMISSIONS,
    text: <Translator translationKey={"title.EMISSIONS"} />,
    ViewComponent: BuildingEmissionsPanel,
  },
  {
    key: SustainabilityRightTabEnum.COMPLIANCE_TRACKING,
    text: <Translator translationKey={"title.COMPLIANCE_TRACKING"} />,
    ViewComponent: BuildingComplianceTrackingPanel,
  },
];

const floorRightPanelTabs = [
  {
    key: "emissions",
    text: <Translator translationKey={"title.EMISSIONS"} />,
    /**
     * ComingSoon component is not used here
     * because if the user is accessing this page after another page where floor was selected.
     * It will render the coming soon till it switch to the building view.
     * So it is better experience to render empty component
     */
    ViewComponent: () => <></>,
  },
  {
    key: "compliance-tracking",
    text: <Translator translationKey={"title.COMPLIANCE_TRACKING"} />,
    /**
     * ComingSoon component is not used here
     * because if the user is accessing this page after another page where floor was selected.
     * It will render the coming soon till it switch to the building view.
     * So it is better experience to render empty component
     */
    ViewComponent: () => <></>,
  },
];

const bottomPanels: BottomPanelType[] = [
  { type: "breakdown", Component: EmissionsBottomPanel },
];

//TODO: To remove the scopes key from this array, when scopes are returned per telemetry
const emissionSources = [
  {
    key: "electricity",
    telemetry: "electricityEmissions",
    label: "label.ELECTRICITY",
    scopes: ["2"],
    BreakdownComponent: ElectricityBreakdown,
  },
  {
    key: "chilledWater",
    telemetry: "chilledWaterEmissions",
    label: "label.CHILLED_WATER",
    scopes: ["2"],
    BreakdownComponent: ChilledWaterBreakdown,
  },
  {
    key: "travel",
    telemetry: "travelEmissions",
    label: "label.TRAVEL",
    scopes: ["1", "3"],
    BreakdownComponent: TravelBreakdown,
  },
  {
    key: "refrigerantLeakage",
    telemetry: "refrigerantLeakageEmissions",
    label: "label.REFRIGERANT_LEAKAGE",
    scopes: ["1"],
    BreakdownComponent: RefrigerantBreakdown,
  },
  {
    key: "consumables",
    telemetry: "consumablesEmissions",
    label: "label.CONSUMABLES",
    scopes: ["3"],
    BreakdownComponent: ConsumablesBreakdown,
  },
  {
    key: "fuel",
    telemetry: "fuelEmissions",
    label: "label.FUEL",
    scopes: ["1"],
    BreakdownComponent: FuelBreakdown,
  },
  {
    key: "waste",
    telemetry: "wasteEmissions",
    label: "label.WASTE",
    scopes: ["3"],
    BreakdownComponent: WasteBreakdown,
  },
  {
    key: "domesticWater",
    telemetry: "domesticWaterEmissions",
    label: "label.DOMESTIC_WATER",
    scopes: ["3"],
    BreakdownComponent: DomesticWaterBreackdown,
  },
];

const complianceScoresTotalContent = {
  key: "total" as const,
  title: "label.TOTAL_POSSIBLE_POINTS",
  maximumRequired: 100,
  isEditable: false,
};

const complianceScoresContent: ComplianceScore<ComplianceScoreKey>[] = [
  {
    key: "transportation",
    title: "label.LOCATION_AND_TRANSPORTATION",
    minimumRequired: minimumComplianceScoreValues.transportation,
    maximumRequired: 14,
    url: "https://www.usgbc.org/credits?Rating+System=%22Existing+Buildings%22&Category=%22Location+%26+transportation%22",
    isEditable: true,
    children: [
      {
        key: "transportationPerformance",
        fieldType: "number",
        requisite: RequisiteEnum.Prereq,
        title: "label.TRANSPORTATION_PERFORMANCE",
        minimumRequired: minimumComplianceScoreValues.transportationPerformance,
        maximumRequired: 14,
        target: 14,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/pf904?return=/credits/Existing%20Buildings/v4.1",
      },
    ],
  },
  {
    key: "sustainableSites",
    title: "label.SUSTAINABLE_SITES",
    minimumRequired: minimumComplianceScoreValues.sustainableSites,
    maximumRequired: 4,
    url: "https://www.usgbc.org/credits?Rating+System=%22Existing+Buildings%22&Category=%22Sustainable+sites%22",
    isEditable: true,
    children: [
      {
        key: "rainwaterManagement",
        fieldType: "number",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Credit,
        title: "label.RAINWATER_MANAGEMENT",
        minimumRequired: minimumComplianceScoreValues.rainwaterManagement,
        maximumRequired: 1,
        target: 1,
        url: "https://www.usgbc.org/credits/existing-buildings/v41/ss109?return=/credits/Existing%20Buildings/v4.1/Sustainable%20sites",
      },
      {
        key: "heatIslandReduction",
        fieldType: "number",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Credit,
        title: "label.HEAT_ISLAND_REDUCTION",
        minimumRequired: minimumComplianceScoreValues.heatIslandReduction,
        maximumRequired: 1,
        target: 1,
        url: "https://www.usgbc.org/credits/existing-buildings/v41/ss111?return=/credits/Existing%20Buildings/v4.1/Sustainable%20sites",
      },
      {
        key: "lightPollutionReduction",
        fieldType: "number",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Credit,
        title: "label.LIGHT_POLLUTION_REDUCTION",
        minimumRequired: minimumComplianceScoreValues.lightPollutionReduction,
        maximumRequired: 1,
        target: 1,
        url: "https://www.usgbc.org/credits/existing-buildings/v41/ss113?return=/credits/Existing%20Buildings/v4.1/Sustainable%20sites",
      },
      {
        key: "siteManagement",
        fieldType: "number",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Credit,
        title: "label.SITE_MANAGEMENT",
        minimumRequired: minimumComplianceScoreValues.siteManagement,
        maximumRequired: 1,
        target: 1,
        url: "https://www.usgbc.org/credits/existing-buildings/v41/ss118?return=/credits/Existing%20Buildings/v4.1/Sustainable%20sites",
      },
    ],
  },
  {
    key: "waterEfficiency",
    title: "label.WATER_EFFICIENCY",
    minimumRequired: minimumComplianceScoreValues.waterEfficiency,
    maximumRequired: 15,
    url: "https://www.usgbc.org/credits?Rating+System=%22Existing+Buildings%22&Category=%22Water+efficiency%22",
    isEditable: true,
    children: [
      {
        key: "waterPerformance",
        fieldType: "number",
        requisite: RequisiteEnum.Prereq,
        title: "label.WATER_PERFORMANCE",
        minimumRequired: minimumComplianceScoreValues.waterPerformance,
        maximumRequired: 15,
        target: 8,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/pf902?return=/credits/Existing%20Buildings/v4.1/Water%20efficiency",
      },
    ],
  },
  {
    key: "energyAndAtmosphere",
    title: "label.ENERGY_AND_ATMOSPHERE",
    minimumRequired: minimumComplianceScoreValues.energyAndAtmosphere,
    maximumRequired: 35,
    url: "https://www.usgbc.org/credits?Rating+System=%22Existing+Buildings%22&Category=%22Energy+%26+atmosphere%22",
    isEditable: true,
    children: [
      {
        key: "energyAndAtmospherePractices",
        fieldType: "boolean",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Prereq,
        title: "label.ENERGY_AND_ATMOSPHERE_PRACTICES",
        minimumRequired:
          minimumComplianceScoreValues.energyAndAtmospherePractices,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/ea102?return=/credits/Existing%20Buildings/v4.1/Energy%20&%20atmosphere",
      },
      {
        key: "fundamentalRefrigerant",
        fieldType: "boolean",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Prereq,
        title: "label.FUNDAMENTAL_REFRIGERANT",
        minimumRequired: minimumComplianceScoreValues.fundamentalRefrigerant,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/ea109?return=/credits/Existing%20Buildings/v4.1/Energy%20&%20atmosphere",
      },
      {
        key: "energyPerformance",
        fieldType: "number",
        requisite: RequisiteEnum.Prereq,
        title: "label.ENERGY_PERFORMANCE",
        minimumRequired: minimumComplianceScoreValues.energyPerformance,
        maximumRequired: 33,
        target: 15,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/pf901?return=/credits/Existing%20Buildings/v4.1/Energy%20&%20atmosphere",
        children: [
          {
            key: "ghgEmissionsScore",
            fieldType: "number",
            title: "label.GHG_EMISSIONS_SCORE",
            minimumRequired: minimumComplianceScoreValues.ghgEmissionsScore,
            maximumRequired: 16.5,
            target: 15,
            fieldStep: 0.5,
          },
          {
            key: "sourceEmissionsSource",
            fieldType: "number",
            title: "label.SOURCE_EMISSIONS_SOURCE",
            minimumRequired: minimumComplianceScoreValues.sourceEmissionsSource,
            maximumRequired: 16.5,
            target: 15,
            fieldStep: 0.5,
          },
        ],
      },
      {
        key: "enhancedRefrigerant",
        fieldType: "number",
        requisite: RequisiteEnum.Credit,
        title: "label.ENHANCED_REFRIGERANT",
        minimumRequired: minimumComplianceScoreValues.enhancedRefrigerant,
        maximumRequired: 1,
        target: 1,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/ea127?return=/credits/Existing%20Buildings/v4.1/Energy%20&%20atmosphere",
      },
      {
        key: "gridHarmonization",
        fieldType: "number",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Credit,
        title: "label.GRID_HARMONIZATION",
        minimumRequired: minimumComplianceScoreValues.gridHarmonization,
        maximumRequired: 1,
        target: 1,
        url: "https://www.usgbc.org/credits/existing-buildings/v41/ea129?return=/credits/Existing%20Buildings/v4.1/Energy%20&%20atmosphere",
      },
    ],
  },
  {
    key: "materialsAndResources",
    title: "label.MATERIALS_AND_RESOURCES",
    minimumRequired: minimumComplianceScoreValues.materialsAndResources,
    maximumRequired: 9,
    url: "https://www.usgbc.org/credits?Rating+System=%22Existing+Buildings%22&Category=%22Material+%26+resources%22",
    isEditable: true,
    children: [
      {
        key: "purchasingPolicy",
        fieldType: "boolean",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Prereq,
        title: "label.PURCHASING_POLICY",
        minimumRequired: minimumComplianceScoreValues.purchasingPolicy,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/mr105?return=/credits/Existing%20Buildings/v4.1/Material%20&%20resources",
      },
      {
        key: "facilityMainteanceAndRenovationPolicy",
        fieldType: "boolean",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Prereq,
        title: "label.FACILITY_MAINTEANCE_AND_RENOVATION_POLICY",
        minimumRequired:
          minimumComplianceScoreValues.facilityMainteanceAndRenovationPolicy,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/mr106?return=/credits/Existing%20Buildings/v4.1/Material%20&%20resources",
      },
      {
        key: "wastePerformance",
        fieldType: "number",
        requisite: RequisiteEnum.Prereq,
        title: "label.WASTE_PERFORMANCE",
        minimumRequired: minimumComplianceScoreValues.wastePerformance,
        maximumRequired: 8,
        target: 8,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/pf903?return=/credits/Existing%20Buildings/v4.1/Material%20&%20resources",
      },
      {
        key: "purchasing",
        fieldType: "number",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Credit,
        title: "label.PURCHASING",
        target: 1,
        minimumRequired: minimumComplianceScoreValues.purchasing,
        maximumRequired: 1,
        url: "https://www.usgbc.org/credits/existing-buildings/v41/mr127?return=/credits/Existing%20Buildings/v4.1/Material%20&%20resources",
      },
    ],
  },
  {
    key: "indoorEnvironmentalQuality",
    title: "label.INDOOR_ENVIRONMENTAL_QUALITY",
    minimumRequired: minimumComplianceScoreValues.indoorEnvironmentalQuality,
    maximumRequired: 22,
    url: "https://www.usgbc.org/credits?Rating+System=%22Existing+Buildings%22&Category=%22Indoor+environmental+quality%22",
    isEditable: true,
    children: [
      {
        key: "minimumIndoorAirQuality",
        fieldType: "boolean",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Prereq,
        title: "label.MINIMUM_INDOOR_AIR_QUALITY",
        minimumRequired: minimumComplianceScoreValues.minimumIndoorAirQuality,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/eq103?return=/credits/Existing%20Buildings/v4.1/Indoor%20environmental%20quality",
      },
      {
        key: "environmentalTobaccoSmokeControl",
        fieldType: "boolean",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Prereq,
        title: "label.ENVIRONMEWNAL_TOBACCO_SMOKE_CONTROL",
        minimumRequired:
          minimumComplianceScoreValues.environmentalTobaccoSmokeControl,
        url: "https://www.usgbc.org/credits/existing-buildings/v41/eq106?return=/credits/Existing%20Buildings/v4.1/Indoor%20environmental%20quality",
      },
      {
        key: "greenCleaningPolicy",
        fieldType: "boolean",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Prereq,
        title: "label.GREEN_CLEANING_POLICY",
        minimumRequired: minimumComplianceScoreValues.greenCleaningPolicy,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/eq108?return=/credits/Existing%20Buildings/v4.1/Indoor%20environmental%20quality",
      },
      {
        key: "indoorEnvironmentalQualityPerformance",
        fieldType: "number",
        requisite: RequisiteEnum.Prereq,
        title: "label.INDOOR_ENVIRONMENTAL_QUALITY_PERFORMANCE",
        minimumRequired:
          minimumComplianceScoreValues.indoorEnvironmentalQualityPerformance,
        maximumRequired: 20,
        target: 12,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/pf905?return=/credits/Existing%20Buildings/v4.1/Indoor%20environmental%20quality",
      },
      {
        key: "greenCleaning",
        fieldType: "number",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Credit,
        title: "label.GREEN_CLEANING",
        target: 1,
        minimumRequired: minimumComplianceScoreValues.greenCleaning,
        maximumRequired: 1,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/eq130?return=/credits/Existing%20Buildings/v4.1/Indoor%20environmental%20quality",
      },
      {
        key: "integratedPestManagement",
        fieldType: "number",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Credit,
        title: "label.INTEGRATED_PEST_MANAGEMENT",
        target: 1,
        minimumRequired: minimumComplianceScoreValues.integratedPestManagement,
        maximumRequired: 1,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/eq128?return=/credits/Existing%20Buildings/v4.1/Indoor%20environmental%20quality",
      },
    ],
  },
  {
    key: "innovation",
    title: "label.INNOVATION",
    minimumRequired: minimumComplianceScoreValues.innovation,
    maximumRequired: 1,
    url: "https://www.usgbc.org/credits?Rating+System=%22Existing+Buildings%22&Category=%22Innovation%22",
    isEditable: true,
    children: [
      {
        key: "innovation",
        fieldType: "number",
        fieldInputType: "radio",
        requisite: RequisiteEnum.Credit,
        title: "label.INNOVATION",
        minimumRequired: minimumComplianceScoreValues.innovation,
        maximumRequired: 1,
        target: 1,
        url: "https://www.usgbc.org/credits/existing-buildings-interiors-existing-buildings/v41/in101?return=/credits/Existing%20Buildings/v4.1/Innovation",
      },
    ],
  },
  complianceScoresTotalContent,
];

const checkIsScoreNonCompliant = <COMPLIANCE_SCORE_KEY extends string>({
  scoreValues,
  childContents,
  requisite,
}: {
  scoreValues: ComplianceScoreValues<COMPLIANCE_SCORE_KEY>;
  childContents?: SubComplianceScore<COMPLIANCE_SCORE_KEY>[];
  requisite?: RequisiteEnum;
}): boolean => {
  return !!childContents?.find((childContent) => {
    const childNonCompliant =
      (scoreValues[childContent.key] || 0) <
        (childContent.minimumRequired || 0) &&
      (requisite || childContent.requisite) === RequisiteEnum.Prereq;

    if (childNonCompliant) {
      return true;
    }

    const subChildNonCompliant = childContent.children?.find(
      (subChildContent) => {
        return (
          (scoreValues[subChildContent.key] || 0) <
            (subChildContent.minimumRequired || 0) &&
          (requisite || childContent.requisite) === RequisiteEnum.Prereq
        );
      }
    );

    return subChildNonCompliant;
  });
};

export {
  SustainabilityRightTabEnum,
  title,
  HeaderContent,
  RightPanelTitle,
  floorRightPanelTabs,
  buildingRightPanelTabs,
  bottomPanels,
  emissionSources,
  complianceScoresTotalContent,
  complianceScoresContent,
  complianceCertificates,
  calculateComplianceScoresTotal,
  getCertificate,
  checkIsScoreNonCompliant,
};

export type { ComplianceScoreKey };
