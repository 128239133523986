import classNames from "classnames";
import type { TableHTMLAttributes } from "react";

import s from "./Table.module.scss";

type TableProps = TableHTMLAttributes<HTMLTableElement>;

const Table = ({ className, ...props }: TableProps) => {
  return <table className={classNames(s.table, className)} {...props} />;
};

export default Table;
export type { TableProps };
