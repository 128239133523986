import {
  type ReactNode,
  useEffect,
  useId,
  useRef,
  type InputHTMLAttributes,
  useMemo,
} from "react";
import { useIsRTL } from "modules/language";
import type { Nullable } from "types/utils";
import isRealNumber from "helpers/isRealNumber";

import s from "./Slider.module.scss";

type SliderProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "value" | "min" | "max" | "step"
> & {
  label: ReactNode;
  value: number;
  setValue: (value: number) => void;
  min: number;
  max: number;
  step: number;
  minAllowed?: number;
  maxAllowed?: number;
  tooltip?: Nullable<string>;
  displaySteps?: boolean;
};

const Slider = ({
  label,
  tooltip,
  id,
  disabled,
  value,
  setValue,
  min,
  max,
  step,
  displaySteps,
  onChange,
  minAllowed,
  maxAllowed,
  ...props
}: SliderProps) => {
  let elementId = useId();
  elementId = id || elementId;
  const sliderRef = useRef<HTMLInputElement>(null);
  const sliderValueRef = useRef<HTMLDivElement>(null);
  const coloredBarRef = useRef<HTMLDivElement>(null);
  const isRTL = useIsRTL();

  // positioning the time slider tooltip
  useEffect(() => {
    if (!sliderRef.current || !sliderValueRef.current || disabled) return;

    const newSliderValue = Number(
      ((Number(sliderRef.current.value) - Number(sliderRef.current.min)) *
        100) /
        (Number(sliderRef.current.max) - Number(sliderRef.current.min))
    );

    const newPosition = 10 - newSliderValue * 0.2;

    if (tooltip) {
      sliderValueRef.current.innerText = tooltip;
      sliderValueRef.current.style.insetInlineStart = `calc(${newSliderValue}% + ${newPosition}px)`;
    }
  }, [disabled, tooltip, sliderRef.current?.value, isRTL]);

  // positioning the time slider coloredBar
  useEffect(() => {
    if (!coloredBarRef.current || !coloredBarRef.current) return;
    if (!sliderRef.current || !sliderValueRef.current || disabled) return;

    const currentSlider = sliderRef.current;
    if (currentSlider) {
      const newSliderValue =
        ((Number(currentSlider.value) - Number(currentSlider.min)) * 100) /
        (Number(currentSlider.max) - Number(currentSlider.min));

      if (newSliderValue === 0) {
        coloredBarRef.current.style.width = "0%";
      }

      const newPosition = 3 - newSliderValue * 0.15;
      coloredBarRef.current.style.width = `calc(${newSliderValue}% + ${newPosition}px)`;
    }
  }, [value, coloredBarRef, disabled]);

  let backgroundImage = undefined;
  let backgroundSize = undefined;

  if (displaySteps && max) {
    const partitionsCount = max - min;
    const partitionPercent = (step / partitionsCount) * 100;
    const halfStepPercent = partitionPercent * 0.05;
    const intervalPercent = partitionPercent - halfStepPercent;

    backgroundImage = `repeating-linear-gradient(to right, var(--color-primary-light) 0% ${halfStepPercent}%, transparent ${halfStepPercent}% ${intervalPercent}%, var(--color-primary-light) ${intervalPercent}% ${partitionPercent}%)`;
    backgroundSize = `${100 - halfStepPercent * 3}%`;
  }

  return (
    <div className={s.sliderContainer}>
      {disabled || !tooltip ? null : (
        <div
          ref={sliderValueRef}
          className={s.sliderSliderValue}
          style={{
            transform: `translateX(${isRTL ? "" : "-"}50%)`,
          }}
        ></div>
      )}
      {!disabled && (
        <div className={s.sliderColoredBar} ref={coloredBarRef}></div>
      )}
      <input
        id={elementId}
        className={s.sliderSlider}
        style={{
          backgroundImage,
          backgroundSize,
          backgroundPositionX: "center",
        }}
        type="range"
        ref={sliderRef}
        disabled={disabled}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={(e) => {
          onChange?.(e);

          const value = +(e.target as HTMLInputElement).value;

          if (isRealNumber(minAllowed) && value < minAllowed) {
            return;
          }

          if (isRealNumber(maxAllowed) && value > maxAllowed) {
            return;
          }

          setValue(value);
        }}
        {...props}
      />
      <label className={s.sliderLabel} htmlFor={elementId}>
        {label}
      </label>
    </div>
  );
};

export default Slider;
export type { SliderProps };
