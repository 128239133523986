import { useTranslate } from "modules/language";
import CategoryItem from "./ComplianceCategoryItem/ComplianceCategoryItem";
import Section from "atomic-components/atoms/Section";
import SectionTitle from "atomic-components/atoms/SectionTitle";
import CategoryItemTable from "./ComplianceCategoryItem/ComplianceCategoryItemTable/ComplianceCategoryItemTable";
import DotsLoader from "atomic-components/atoms/DotsLoader/DotsLoader";
import TooltipAlert from "atomic-components/molecules/TooltipAlert";
import ExclamationTriangle from "svg-icons/ExclamationTriangle";
import ExternalLink from "svg-icons/ExternalLink";
import {
  checkIsScoreNonCompliant,
  complianceScoresContent,
} from "modules/building/pages/SustainabilityPage/utils";
import { useComplianceScoreValues } from "modules/building/hooks/useComplianceScoreValues";
import InfoPopover from "atomic-components/atoms/InfoPopover";

import s from "./ComplianceCategories.module.scss";

const ComplianceCategories = () => {
  const t = useTranslate();
  const { data, isFetching, isError } = useComplianceScoreValues();
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };
  return (
    <Section className={s.container}>
      <SectionTitle>
        {t("title.CATEGORIES")}{" "}
        {isError && (
          <TooltipAlert
            iconType="error"
            tooltip={t("error.CANNOT_LOAD_CATEGORIES")}
          />
        )}
        {isFetching && <DotsLoader isInline />}
      </SectionTitle>
      {complianceScoresContent.map((category) => {
        const { title, maximumRequired, url, key, children = [] } = category;

        const isNotAllEligibleForLEED = checkIsScoreNonCompliant({
          scoreValues: data,
          childContents: children,
        });

        const categoryItemTitle = (
          <div className={s.titleContainer} title={key}>
            {t(title)}
            {url && (
              <a
                className={s.externalLink}
                href={url}
                target="_blank"
                rel="noreferrer"
                onClick={handleLinkClick}
              >
                <ExternalLink color="var(--color-primary)" iconScale={1} />
              </a>
            )}
          </div>
        );

        return (
          <CategoryItem
            key={key}
            hasExpandableContent={key !== "total"}
            title={
              isNotAllEligibleForLEED ? (
                <div className={s.titleContainer}>
                  <InfoPopover
                    message={t("tooltip.ELIGIBILITY_MINIMUM_NOT_MET")}
                    variant="primary"
                    placement="top"
                  >
                    <ExclamationTriangle iconScale={1} fill={false} />
                  </InfoPopover>
                  {categoryItemTitle}
                </div>
              ) : (
                <div className={s.titleContainer}>{categoryItemTitle}</div>
              )
            }
            achieved={data?.[key]}
            maximumRequired={maximumRequired}
          >
            <CategoryItemTable content={children} data={data} />
          </CategoryItem>
        );
      })}
    </Section>
  );
};

export default ComplianceCategories;
