import classNames from "classnames";
import { useId, type HTMLAttributes, type ReactNode } from "react";
import Tab, { type TabProps } from "atomic-components/atoms/Tab";

import s from "./TabsView.module.scss";

type TabType<TAB_KEY extends string | number> = {
  key: TAB_KEY;
  text: string | React.ReactNode;
};

type TabsViewProps<TAB_KEY extends string | number> =
  HTMLAttributes<HTMLDivElement> & {
    tabs: TabType<TAB_KEY>[];
    selectedTabKey: TAB_KEY;
    setSelectedTabKey: (tabKey: TAB_KEY) => void;
    children: Required<ReactNode>;
    skin?: TabProps["skin"];
    size?: TabProps["size"];
    tabsClassName?: string;
    panelClassName?: string;
    hasPanelBorder?: boolean;
    growTabs?: boolean;
  };

const TabsView = <TAB_KEY extends string | number>({
  tabs,
  selectedTabKey,
  setSelectedTabKey,
  children,
  skin = "default",
  size = "default",
  tabsClassName,
  panelClassName,
  hasPanelBorder,
  growTabs = true,
  ...props
}: TabsViewProps<TAB_KEY>) => {
  const id = useId();

  const createDomIds = (key: TAB_KEY) => {
    return {
      tabId: `${id}-${key}`,
      panelId: `${id}-panel-${key}`,
    };
  };

  const selectedTabDomIds = createDomIds(selectedTabKey);

  return (
    <section {...props}>
      <div
        role="tablist"
        className={classNames(
          s.tabs,
          s[skin],
          s[size],
          { [s.growTabs]: growTabs },
          tabsClassName
        )}
      >
        {tabs.map(({ key, text }) => {
          const ids = createDomIds(key);
          const isSelected = key === selectedTabKey;

          return (
            <Tab
              key={key}
              id={ids.tabId}
              type="button"
              role="tab"
              skin={skin}
              size={size}
              aria-selected={isSelected}
              aria-controls={ids.panelId}
              onClick={() => setSelectedTabKey(key)}
            >
              {text}
            </Tab>
          );
        })}
      </div>
      <div
        id={selectedTabDomIds.panelId}
        role="tabpanel"
        aria-labelledby={selectedTabDomIds.tabId}
        className={classNames(
          s.panel,
          s[`panel${size}`],
          {
            [s.panelBorder]: hasPanelBorder,
          },
          panelClassName
        )}
      >
        {children}
      </div>
    </section>
  );
};

export default TabsView;
export type { TabsViewProps, TabType as Tab };
