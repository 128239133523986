import classNames from "classnames";
import type { HTMLAttributes } from "react";

import s from "./WidgetContainer.module.scss";

type WidgetContainerProps = HTMLAttributes<HTMLElement> & {
  tag?: "button" | "section";
  size?: "default" | "small" | "smallY";
  skin?: "default" | "white" | "primary";
  /**
   * Adds a border start color for the WidgetContainer
   */
  statusColor?: string;
  /**
   * Sets the widget as flex with column direction and sets the row gap
   */
  multiLine?: boolean;
};

const WidgetContainer = ({
  tag,
  size = "default",
  skin = "default",
  statusColor,
  multiLine,
  className,
  style,
  ...props
}: WidgetContainerProps) => {
  const Tag = tag || "section";

  return (
    <Tag
      style={{
        ...(statusColor
          ? { borderInlineStart: `var(--space-small) solid ${statusColor}` }
          : {}),
        ...style,
      }}
      className={classNames(
        s.container,
        s[size],
        s[skin],
        {
          [s.multiLine]: multiLine,
        },
        className
      )}
      {...props}
    />
  );
};

export default WidgetContainer;
export type { WidgetContainerProps };
