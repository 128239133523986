import LeedOmCertificate from "./Certificates/LeedOmCertificate/LeedOmCertificate";

const certificates = [
  {
    label: "title.LEED_OM",
    CertificateComponent: LeedOmCertificate,
    logoSrc: "/img/icons/certificates/LeedOm.svg",
    isInitiallyExpanded: true,
  },
];

export { certificates };
