import classNames from "classnames";
import React from "react";

const CircularTicks = ({
  activeColor = "#2ed9c3",
  inactiveColor = "#5b5355",
  className,
}) => {
  return (
    <svg className={className} viewBox="0 0 11 11">
      <path
        fill={activeColor}
        d="M5.5,1.81s-.08-.03-.08-.08V.57s.03-.08,.08-.08,.08,.03,.08,.08V1.74s-.03,.08-.08,.08Z"
      />
      <path
        fill={activeColor}
        d="M5.21,1.75l-.07-.79s-.04-.07-.08-.07c-.04,0-.07,.04-.07,.08l.07,.79s.04,.07,.08,.07c.04,0,.07-.04,.07-.08Z"
      />
      <path
        fill={activeColor}
        d="M4.84,1.79l-.14-.78s-.05-.07-.09-.06c-.04,0-.07,.05-.06,.09l.14,.78s.05,.07,.09,.06c.04,0,.07-.05,.06-.09Z"
      />
      <path
        fill={activeColor}
        d="M4.48,1.87l-.21-.76s-.05-.06-.09-.05c-.04,.01-.06,.05-.05,.09l.21,.76s.05,.06,.09,.05c.04-.01,.06-.05,.05-.09Z"
      />
      <path
        fill={activeColor}
        d="M4.12,1.97l-.27-.74s-.06-.06-.1-.05-.06,.06-.05,.1l.27,.74s.06,.06,.1,.05c.04-.01,.06-.06,.05-.1Z"
      />
      <path
        fill={activeColor}
        d="M3.78,2.11l-.33-.72s-.06-.05-.1-.04c-.04,.02-.05,.06-.04,.1l.33,.72s.06,.05,.1,.04c.04-.02,.05-.06,.04-.1Z"
      />
      <path
        fill={activeColor}
        d="M3.44,2.27l-.4-.69s-.07-.05-.1-.03c-.04,.02-.05,.07-.03,.1l.4,.69s.07,.05,.1,.03c.04-.02,.05-.07,.03-.1Z"
      />
      <path
        fill={activeColor}
        d="M3.13,2.46l-.45-.65s-.07-.04-.1-.02c-.03,.02-.04,.07-.02,.1l.45,.65s.07,.04,.1,.02c.03-.02,.04-.07,.02-.1Z"
      />
      <path
        fill={activeColor}
        d="M2.83,2.69l-.51-.61s-.07-.04-.11,0c-.03,.03-.04,.07,0,.11l.51,.61s.07,.04,.11,0c.03-.03,.04-.07,0-.11Z"
      />
      <path
        fill={activeColor}
        d="M2.55,2.93l-.83-.83s-.08-.03-.11,0c-.03,.03-.03,.08,0,.11l.83,.83s.08,.03,.11,0c.03-.03,.03-.08,0-.11Z"
      />
      <path
        fill={activeColor}
        d="M2.3,3.2l-.61-.51s-.08-.02-.11,0c-.03,.03-.02,.08,0,.11l.61,.51s.08,.02,.11,0c.03-.03,.02-.08,0-.11Z"
      />
      <path
        fill={activeColor}
        d="M2.07,3.49l-.65-.45s-.08-.02-.1,.02c-.02,.03-.02,.08,.02,.1l.65,.45s.08,.02,.1-.02c.02-.03,.02-.08-.02-.1Z"
      />
      <path
        fill={activeColor}
        d="M1.86,3.8l-.69-.4s-.08,0-.1,.03c-.02,.04,0,.08,.03,.1l.69,.4s.08,0,.1-.03c.02-.04,0-.08-.03-.1Z"
      />
      <path
        fill={activeColor}
        d="M1.68,4.12l-.72-.33s-.08,0-.1,.04c-.02,.04,0,.08,.04,.1l.72,.33s.08,0,.1-.04c.02-.04,0-.08-.04-.1Z"
      />
      <path
        fill={activeColor}
        d="M1.54,4.46l-.74-.27s-.08,0-.1,.05c-.01,.04,0,.08,.05,.1l.74,.27s.08,0,.1-.05c.01-.04,0-.08-.05-.1Z"
      />
      <path
        fill={activeColor}
        d="M1.42,4.81l-.77-.21s-.08,.01-.09,.05c-.01,.04,.01,.08,.05,.09l.77,.21s.08-.01,.09-.05c.01-.04-.01-.08-.05-.09Z"
      />
      <path
        fill={activeColor}
        d="M1.33,5.17l-.78-.14s-.08,.02-.09,.06c0,.04,.02,.08,.06,.09l.78,.14s.08-.02,.09-.06c0-.04-.02-.08-.06-.09Z"
      />
      <path
        fill={activeColor}
        d="M1.28,5.54l-.79-.07s-.08,.03-.08,.07c0,.04,.03,.08,.07,.08l.79,.07s.08-.03,.08-.07c0-.04-.03-.08-.07-.08Z"
      />
      <path
        fill={activeColor}
        d="M1.25,5.91H.08s-.08,.03-.08,.08,.03,.08,.08,.08H1.25s.08-.03,.08-.08-.03-.08-.08-.08Z"
      />
      <path
        fill={activeColor}
        d="M1.26,6.27l-.79,.07s-.07,.04-.07,.08c0,.04,.04,.07,.08,.07l.79-.07s.07-.04,.07-.08c0-.04-.04-.07-.08-.07Z"
      />
      <path
        fill={activeColor}
        d="M1.31,6.64l-.78,.14s-.07,.05-.06,.09c0,.04,.05,.07,.09,.06l.78-.14s.07-.05,.06-.09c0-.04-.05-.07-.09-.06Z"
      />
      <path
        fill={activeColor}
        d="M1.38,7.01l-.77,.21s-.06,.05-.05,.09c.01,.04,.05,.06,.09,.05l.77-.21s.06-.05,.05-.09c-.01-.04-.05-.06-.09-.05Z"
      />
      <path
        fill={activeColor}
        d="M1.49,7.36l-.74,.27s-.06,.06-.05,.1c.01,.04,.06,.06,.1,.05l.74-.27s.06-.06,.05-.1c-.01-.04-.06-.06-.1-.05Z"
      />
      <path
        fill={activeColor}
        d="M1.62,7.7l-.72,.33s-.05,.06-.04,.1c.02,.04,.06,.05,.1,.04l.72-.33s.05-.06,.04-.1-.06-.05-.1-.04Z"
      />
      <path
        fill={activeColor}
        d="M1.79,8.03l-.69,.4s-.05,.07-.03,.1c.02,.04,.07,.05,.1,.03l.69-.4s.05-.07,.03-.1c-.02-.04-.07-.05-.1-.03Z"
      />
      <path
        fill={activeColor}
        d="M1.98,8.35l-.65,.45s-.04,.07-.02,.1c.02,.03,.07,.04,.1,.02l.65-.45s.04-.07,.02-.1c-.02-.03-.07-.04-.1-.02Z"
      />
      <path
        fill={activeColor}
        d="M2.2,8.65l-.61,.51s-.04,.07,0,.11c.03,.03,.07,.04,.11,0l.61-.51s.04-.07,0-.11c-.03-.03-.07-.04-.11,0Z"
      />
      <path
        fill={activeColor}
        d="M2.44,8.93l-.83,.83s-.03,.08,0,.11c.03,.03,.08,.03,.11,0l.83-.83s.03-.08,0-.11c-.03-.03-.08-.03-.11,0Z"
      />
      <path
        fill={inactiveColor}
        d="M8.42,9.04l.83,.83s.08,.03,.11,0c.03-.03,.03-.08,0-.11l-.83-.83s-.08-.03-.11,0c-.03,.03-.03,.08,0,.11Z"
      />
      <path
        fill={inactiveColor}
        d="M8.71,8.76l.61,.51s.08,.02,.11,0c.03-.03,.02-.08,0-.11l-.61-.51s-.08-.02-.11,0c-.03,.03-.02,.08,0,.11Z"
      />
      <path
        fill={inactiveColor}
        d="M8.94,8.47l.65,.45s.08,.02,.1-.02c.02-.03,.02-.08-.02-.1l-.65-.45s-.08-.02-.1,.02c-.02,.03-.02,.08,.02,.1Z"
      />
      <path
        fill={inactiveColor}
        d="M9.14,8.17l.69,.4s.08,0,.1-.03c.02-.04,0-.08-.03-.1l-.69-.4s-.08,0-.1,.03c-.02,.04,0,.08,.03,.1Z"
      />
      <path
        fill={inactiveColor}
        d="M9.32,7.84l.72,.33s.08,0,.1-.04c.02-.04,0-.08-.04-.1l-.72-.33s-.08,0-.1,.04,0,.08,.04,.1Z"
      />
      <path
        fill={inactiveColor}
        d="M9.47,7.5l.74,.27s.08,0,.1-.05c.01-.04,0-.08-.05-.1l-.74-.27s-.08,0-.1,.05c-.01,.04,0,.08,.05,.1Z"
      />
      <path
        fill={inactiveColor}
        d="M9.58,7.15l.77,.21s.08-.01,.09-.05c.01-.04-.01-.08-.05-.09l-.77-.21s-.08,.01-.09,.05c-.01,.04,.01,.08,.05,.09Z"
      />
      <path
        fill={inactiveColor}
        d="M9.67,6.79l.78,.14s.08-.02,.09-.06c0-.04-.02-.08-.06-.09l-.78-.14s-.08,.02-.09,.06c0,.04,.02,.08,.06,.09Z"
      />
      <path
        fill={inactiveColor}
        d="M9.73,6.43l.79,.07s.08-.03,.08-.07c0-.04-.03-.08-.07-.08l-.79-.07s-.08,.03-.08,.07c0,.04,.03,.08,.07,.08Z"
      />
      <path
        fill={inactiveColor}
        d="M9.75,6.06h1.17s.08-.03,.08-.08-.03-.08-.08-.08h-1.17s-.08,.03-.08,.08,.03,.08,.08,.08Z"
      />
      <path
        fill={inactiveColor}
        d="M9.74,5.69l.79-.07s.07-.04,.07-.08c0-.04-.04-.07-.08-.07l-.79,.07s-.07,.04-.07,.08c0,.04,.04,.07,.08,.07Z"
      />
      <path
        fill={inactiveColor}
        d="M9.7,5.32l.78-.14s.07-.05,.06-.09-.05-.07-.09-.06l-.78,.14s-.07,.05-.06,.09c0,.04,.05,.07,.09,.06Z"
      />
      <path
        fill={inactiveColor}
        d="M9.62,4.96l.77-.21s.06-.05,.05-.09c-.01-.04-.05-.06-.09-.05l-.77,.21s-.06,.05-.05,.09c.01,.04,.05,.06,.09,.05Z"
      />
      <path
        fill={inactiveColor}
        d="M9.52,4.6l.74-.27s.06-.06,.05-.1c-.01-.04-.06-.06-.1-.05l-.74,.27s-.06,.06-.05,.1c.01,.04,.06,.06,.1,.05Z"
      />
      <path
        fill={inactiveColor}
        d="M9.38,4.26l.72-.33s.05-.06,.04-.1c-.02-.04-.06-.05-.1-.04l-.72,.33s-.05,.06-.04,.1c.02,.04,.06,.05,.1,.04Z"
      />
      <path
        fill={inactiveColor}
        d="M9.22,3.93l.69-.4s.05-.07,.03-.1c-.02-.04-.07-.05-.1-.03l-.69,.4s-.05,.07-.03,.1c.02,.04,.07,.05,.1,.03Z"
      />
      <path
        fill={activeColor}
        d="M9.02,3.61l.65-.45s.04-.07,.02-.1c-.02-.03-.07-.04-.1-.02l-.65,.45s-.04,.07-.02,.1c.02,.03,.07,.04,.1,.02Z"
      />
      <path
        fill={activeColor}
        d="M8.8,3.31l.61-.51s.04-.07,0-.11c-.03-.03-.07-.04-.11,0l-.61,.51s-.04,.07,0,.11c.03,.03,.07,.04,.11,0Z"
      />
      <path
        fill={activeColor}
        d="M8.56,3.03l.83-.83s.03-.08,0-.11c-.03-.03-.08-.03-.11,0l-.83,.83s-.03,.08,0,.11c.03,.03,.08,.03,.11,0Z"
      />
      <path
        fill={activeColor}
        d="M8.29,2.78l.51-.61s.02-.08,0-.11c-.03-.03-.08-.02-.11,0l-.51,.61s-.02,.08,0,.11c.03,.03,.08,.02,.11,0Z"
      />
      <path
        fill={activeColor}
        d="M8,2.55l.45-.65s.02-.08-.02-.1c-.03-.02-.08-.02-.1,.02l-.45,.65s-.02,.08,.02,.1c.03,.02,.08,.02,.1-.02Z"
      />
      <path
        fill={activeColor}
        d="M7.69,2.35l.4-.69s0-.08-.03-.1c-.04-.02-.08,0-.1,.03l-.4,.69s0,.08,.03,.1c.04,.02,.08,0,.1-.03Z"
      />
      <path
        fill={activeColor}
        d="M7.37,2.17l.33-.72s0-.08-.04-.1c-.04-.02-.08,0-.1,.04l-.33,.72s0,.08,.04,.1c.04,.02,.08,0,.1-.04Z"
      />
      <path
        fill={activeColor}
        d="M7.03,2.02l.27-.74s0-.08-.05-.1c-.04-.01-.08,0-.1,.05l-.27,.74s0,.08,.05,.1c.04,.01,.08,0,.1-.05Z"
      />
      <path
        fill={activeColor}
        d="M6.67,1.91l.21-.76s-.01-.08-.05-.09c-.04-.01-.08,.01-.09,.05l-.21,.76s.01,.08,.05,.09c.04,.01,.08-.01,.09-.05Z"
      />
      <path
        fill={activeColor}
        d="M6.31,1.82l.14-.78s-.02-.08-.06-.09c-.04,0-.08,.02-.09,.06l-.14,.78s.02,.08,.06,.09c.04,0,.08-.02,.09-.06Z"
      />
      <path
        fill={activeColor}
        d="M5.95,1.76l.07-.79s-.03-.08-.07-.08c-.04,0-.08,.03-.08,.07l-.07,.79s.03,.08,.07,.08c.04,0,.08-.03,.08-.07Z"
      />
    </svg>
  );
};

export default CircularTicks;
