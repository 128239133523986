import classNames from "classnames";
import React from "react";

const Circle = ({
  color = "currentcolor",
  className = "",
  iconScale = 1,
  icon = true,
}) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <circle cx={5.5} cy={5.5} fill={color} stroke="none" r={5.5} />
    </svg>
  );
};

export default Circle;
