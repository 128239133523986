const modifyObject = (
  object,
  meshModificationFunction,
  groupModificationFunction
) => {
  if (object instanceof window.THREE.Mesh) {
    meshModificationFunction && meshModificationFunction(object);
  } else if (Array.isArray(object.children)) {
    groupModificationFunction && groupModificationFunction(object);
    meshModificationFunction &&
      object.children.forEach((object) =>
        modifyObject(
          object,
          meshModificationFunction,
          groupModificationFunction
        )
      );
  }
};

export default modifyObject;
