enum PageUrlEnum {
  Dashboard = "dashboard",
  Sustainability = "sustainability",
  OperationalSpending = "operational-spending",
  AssetManagement = "asset-management",
  AssetManagementNew = "asset-management-new",
  UserComfort = "user-comfort",
  OccupantWellbeing = "occupant-wellbeing",
  PortfolioManagement = "portfolio-management",
  InsightsManagement = "insights-management",
}

const isValidPageUrlEnum = (
  pageUrl?: string | null
): pageUrl is PageUrlEnum => {
  return !!Object.values(PageUrlEnum).find((p) => p === pageUrl);
};

export { PageUrlEnum, isValidPageUrlEnum };
