import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { createPortal } from "react-dom";
import { useIsRTL } from "../../../modules/language";
import ElementWithOnResize from "../../../modules/layout/components/ElementWithOnResize/ElementWithOnResize";
import SizeProvider, {
  useHeight,
  useWidth,
} from "../../../modules/layout/components/SizeProviders";
import isRealNumber from "helpers/isRealNumber";
import { flip, offset, shift, useFloating } from "@floating-ui/react-dom";
import { PopoverPortal } from "atomic-components/atoms/InfoPopover";
import "./Charts.css";

/**
 * @typedef {Object} PercentBar
 * @property {number} categoryIndex
 * @property {number} barIndex
 * @property {number} index alias for bar index
 * @property {number} startPercent
 * @property {number} endPercent
 */

/**
 * @typedef {PercentBar[]} PercentBars
 */

/**
 * @typedef {Object} PxBarExtention
 * @property {number} start in px
 * @property {number} end in px
 */

/**
 * @typedef {PercentBar & PxBarExtention} PxBar
 */

/**
 * @typedef {PxBar[]} PxBars
 */

/**
 * @typedef {Object} PercentCategory
 * @property {number} categoryIndex
 * @property {number} index alias for categoryIndex
 * @property {number} startPercent
 * @property {number} barsStartPercent
 * @property {number} barsEndPercent
 * @property {number} endPercent
 * @property {PercentBar[]} bars
 */

/**
 * @typedef {PercentCategory[]} PercentCategories
 */

/**
 * @typedef {Object} PxCategoryExtention
 * @property {number} start in px
 * @property {number} barsStart in px
 * @property {number} barsEnd in px
 * @property {number} end in px
 * @property {PxBar[]} bars
 */

/**
 * @typedef {PercentCategory & PxCategoryExtention} PxCategory
 */

/**
 * @typedef {PxCategory[]} PxCategories
 */

/**
 * @param {number} categoriesCount
 * @param {number} categoriesSpacing
 * @param {number} barsPerCategory
 * @param {number} barSpacing
 * @returns {PercentCategories}
 */
const computeCategories = (
  categoriesCount,
  categoriesSpacing,
  barsPerCategory,
  barSpacing
) => {
  const categoryPercentSize = (100 - categoriesSpacing) / categoriesCount;
  const categorySpacingPercentSize = categoriesSpacing / categoriesCount;

  const onlyOneBar = barsPerCategory === 1;
  const barPercentSize = onlyOneBar
    ? categoryPercentSize
    : categoryPercentSize /
      (barsPerCategory +
        ((barsPerCategory - 1) * barSpacing) / (100 - barSpacing));

  const barSpacingPercentSize = onlyOneBar
    ? 0
    : (barPercentSize * barSpacing) / (100 - barSpacing);

  let halfCategorySpacingPercentSize = categorySpacingPercentSize / 2;
  let categoryCursor = 0;

  const categories = [];
  for (
    let categoryIndex = 0;
    categoryIndex < categoriesCount;
    categoryIndex++
  ) {
    const category = {};
    categories.push(category);
    const bars = [];
    category.bars = bars;
    category.categoryIndex = categoryIndex;
    category.index = categoryIndex;
    category.startPercent = categoryCursor;
    categoryCursor += halfCategorySpacingPercentSize;
    category.barsStartPercent = categoryCursor;
    let barCursor = categoryCursor;
    for (let barIndex = 0; barIndex < barsPerCategory; barIndex++) {
      bars.push({
        categoryIndex,
        index: barIndex,
        barIndex,
        startPercent: barCursor,
        endPercent: barCursor + barPercentSize,
      });
      barCursor += barPercentSize + barSpacingPercentSize;
    }
    categoryCursor += categoryPercentSize;
    category.barsEndPercent = categoryCursor;
    categoryCursor += halfCategorySpacingPercentSize;
    category.endPercent = categoryCursor;
  }
  return categories;
};

/**
 * @param {number} categoriesCount
 * @param {number} categoriesSpacing
 * @param {number} barsPerCategory
 * @param {number} barSpacing
 * @param {number} [chartsCount] number of rows case horizontal category axis bar charts, and number of columns case vertical category axis bar charts
 * @returns {PercentCategories[]} categories array for each chart of chartsCount
 */
const computeMutliChartCategories = (
  categoriesCount,
  categoriesSpacing,
  barsPerCategory,
  barSpacing,
  chartsCount = 1
) => {
  const categoriesPerChart = Math.ceil(categoriesCount / chartsCount);
  const categories = computeCategories(
    categoriesPerChart,
    categoriesSpacing,
    barsPerCategory,
    barSpacing
  );

  const chartCategories = [];
  let categoriesLeft = categoriesCount;
  for (let i = 0; i < chartsCount; i++) {
    const remainingCharts = chartsCount - i;
    const categoriesInCurrentChart = Math.ceil(
      categoriesLeft / remainingCharts
    );
    categoriesLeft -= categoriesInCurrentChart;
    const currentChartCategories = (
      categoriesInCurrentChart === categoriesPerChart
        ? categories
        : categories.slice(0, -1)
    ).map((category) => {
      const categoryIndex = category.index * chartsCount + i;
      return {
        ...category,
        categoryIndex,
        index: categoryIndex,
        bars: category.bars.map((bar) => ({ ...bar, categoryIndex })),
      };
    });
    chartCategories.push(currentChartCategories);
  }
  return chartCategories;
};

/**
 * @param {PercentCategories} categories
 * @returns {PxCategories}
 */
const resolveCategoriesInPx = (categories, size) => {
  size /= 100;
  return categories.map((category) => ({
    ...category,
    start: Math.round(category.startPercent * size),
    end: Math.round(category.endPercent * size),
    barsStart: Math.round(category.barsStartPercent * size),
    barsEnd: Math.round(category.barsEndPercent * size),
    bars: category.bars.map((bar) => ({
      ...bar,
      start: Math.round(bar.startPercent * size),
      end: Math.round(bar.endPercent * size),
    })),
  }));
};

const CategoriesContext = React.createContext([]);

/**
 * @returns {PercentCategories}
 */
export const useCategories = () => useContext(CategoriesContext);
/**
 * @returns {PxCategories}
 */
export const useCategoriesInPx = (size) => {
  const categories = useCategories();
  return useComputedCategoriesInPx(categories, size);
};

/** @type {React.FunctionComponent<{ categories: PercentCategories }>} */
export const CategoriesProvider = ({ categories, children }) => (
  <CategoriesContext.Provider value={categories}>
    {children}
  </CategoriesContext.Provider>
);

export const BarChartCategoriesProvider = ({
  categoriesCount = 7,
  categoriesSpacing = 30,
  barsPerCategory = 2,
  barSpacing = 0,
  children,
}) => {
  const categories = useComputedCategories(
    categoriesCount,
    categoriesSpacing,
    barsPerCategory,
    barSpacing
  );
  return (
    <CategoriesProvider categories={categories}>{children}</CategoriesProvider>
  );
};

/**
 * @type {React.ForwardRefRenderFunction<HTMLElement,React.HTMLAttributes<HTMLElement> & {ref?: import("react").MutableRefObject}>}
 */
export const DomChartCanvas = forwardRef(({ style, ...props }, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      style={{
        position: "absolute",
        ...style,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    />
  );
});

/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.AllHTMLAttributes |children:React.ReactChildren} & {ref?: import("react").MutableRefObject}>}
 */
export const SVGChartCanvas = forwardRef(({ style, ...props }, ref) => {
  return (
    <svg
      width="100%"
      height="100%"
      ref={ref}
      {...props}
      style={{
        position: "absolute",
        top: 0,
        ...style,
      }}
    />
  );
});

const DomVerticalBar = ({ bar, getBarProps }) => {
  const canvasWidth = useWidth();
  const canvasHeight = useHeight();
  const barProps = getBarProps({
    categoryIndex: bar.categoryIndex,
    seriesIndex: bar.barIndex,
    barIndex: bar.barIndex,
    size: canvasHeight,
    otherSize: canvasWidth,
  });
  const {
    barPercentSize = barProps,
    barSize,
    color = "#000000",
    cursor = "pointer",
    style,
    zIndex,
    ...props
  } = barProps ?? {};

  const height =
    barPercentSize != null ? (canvasHeight * barPercentSize) / 100 : barSize;

  return (
    <div
      {...props}
      data-category-index={bar.categoryIndex}
      data-series-index={bar.barIndex}
      data-bar-index={bar.barIndex}
      style={{
        bottom: 0,
        ...style,
        cursor,
        ...(zIndex != null ? { zIndex } : null),
        position: "absolute",
        [useIsRTL() ? "right" : "left"]: `${bar.start}px`,
        width: `${bar.end - bar.start}px`,
        height: height != null ? `${height}px` : 0,
        ...(color ? { background: color } : null),
      }}
    ></div>
  );
};

const DomHorizontalBar = ({ bar, getBarProps }) => {
  const canvasWidth = useWidth();
  const canvasHeight = useHeight();
  const barProps = getBarProps({
    categoryIndex: bar.categoryIndex,
    seriesIndex: bar.barIndex,
    barIndex: bar.barIndex,
    size: canvasWidth,
    otherSize: canvasHeight,
  });
  const {
    barPercentSize = barProps,
    barSize,
    color = "#000000",
    cursor = "pointer",
    style,
    zIndex,
    ...props
  } = barProps ?? {};

  const width =
    barPercentSize != null ? (canvasWidth * barPercentSize) / 100 : barSize;

  const isRTL = useIsRTL();
  return (
    <div
      {...props}
      data-category-index={bar.categoryIndex}
      data-series-index={bar.barIndex}
      data-bar-index={bar.barIndex}
      style={{
        [isRTL ? "right" : "left"]: 0,
        ...style,
        cursor,
        ...(zIndex != null ? { zIndex } : null),
        position: "absolute",
        top: `${bar.start}px`,
        height: `${bar.end - bar.start}px`,
        width: width != null ? `${width}px` : 0,
        ...(color ? { background: color } : null),
      }}
    ></div>
  );
};

/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.AllHTMLAttributes | {xPercents:number[]|0,yPercents:number[],fill?:string,strokeWidth?:number,stroke?:string,strokeDasharray?:string}>}
 */
export const SVGHorizontalPlotLine = forwardRef(
  (
    {
      xPercents,
      yPercents,
      fill = "none",
      strokeWidth = 1,
      stroke = "black",
      ...pathProps
    },
    ref
  ) => {
    const isRTL = useIsRTL();
    const width = useWidth();
    const height = useHeight();
    const path = useMemo(() => {
      const coordinates = xPercents.map((xPercent, i) => {
        const yPercent = yPercents[i];
        let x = (xPercent * width) / 100;
        x = isRTL ? width - x : x;
        const y = isRealNumber(yPercent)
          ? height - (yPercent * height) / 100
          : null;
        return { x, y };
      });
      const pathParts = [];
      for (let i = 0; i < coordinates.length; i++) {
        while (i < coordinates.length && coordinates[i].y == null) {
          i++;
        }
        if (i >= coordinates.length) {
          break;
        }
        const { x: x0, y: y0 } = coordinates[i];
        pathParts.push(`M ${x0} ${y0}`);
        i++;
        while (i < coordinates.length && coordinates[i].y != null) {
          const { x, y } = coordinates[i];
          pathParts.push(`L ${x} ${y}`);
          i++;
        }
        const yPrev = coordinates[i - 1].y;
        const x = i >= coordinates.length ? width : coordinates[i].x;
        pathParts.push(`L ${x} ${yPrev}`);
        if (i >= coordinates.length) {
          break;
        }
      }
      return pathParts.join(" ");
    }, [width, height, xPercents, yPercents, isRTL]);

    return (
      <path
        ref={ref}
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
        {...pathProps}
        d={path}
      />
    );
  }
);

const eps = 1e-9;
const useAreasUnderTwoGraphs_defaultDiff = (y1, y2) => y2 - y1;
const useAreasUnderTwoGraphs_defaultGetIntersection = (
  { x: xPrev, y1: y1Prev, y2: y2Prev },
  { x, y1, y2 },
  threshold
) => {
  const line1Slope = (y1Prev - y1) / (xPrev - x);
  const line2Slope = (y2Prev - y2) / (xPrev - x);
  if (Math.abs(line1Slope - line2Slope) > eps) {
    const xDelta = (y2Prev - y1Prev - threshold) / (line1Slope - line2Slope);
    const y1Intersection = y1Prev + xDelta * line1Slope;
    const y2Intersection = y2Prev + xDelta * line2Slope;
    const xIntersection = xDelta + xPrev;
    if (Math.abs(xIntersection - xPrev) < eps) {
      return { first: true, x: xPrev, y1: y1Prev, y2: y2Prev };
    }
    if (Math.abs(xIntersection - x) < eps) {
      return { second: true, x, y1, y2 };
    }
    return {
      mid: true,
      x: xIntersection,
      y1: y1Intersection,
      y2: y2Intersection,
    };
  }
};
export const useAreasUnderTwoGraphs = ({
  xPercents,
  y1Percents,
  y2Percents,
  minYLimit = 0,
  maxYLimit = Number.POSITIVE_INFINITY,
  includeYMin = minYLimit > eps,
  minXDuration = 0,
  maxXDuration = Number.POSITIVE_INFINITY,
  getDiff = useAreasUnderTwoGraphs_defaultDiff,
  getIntersection = useAreasUnderTwoGraphs_defaultGetIntersection,
}) => {
  return useMemo(() => {
    const getDiffOriginal = getDiff;
    getDiff = (y1, y2) =>
      isRealNumber(y1) && isRealNumber(y2) ? getDiffOriginal(y1, y2) : null;

    const coordinatesRaw = xPercents.map((xPercent, i) => {
      const y1Percent = Array.isArray(y1Percents) ? y1Percents[i] : y1Percents;
      const y2Percent = Array.isArray(y2Percents) ? y2Percents[i] : y2Percents;
      return {
        x: xPercent,
        y1: isRealNumber(y1Percent) ? y1Percent : null,
        y2: isRealNumber(y2Percent) ? y2Percent : null,
      };
    });
    const areas = [];

    let previousIsNull;
    let previousAboveMinLimit;
    let previousEqualMinLimit;
    let previousBelowMaxLimit;
    let previousEqualMaxLimit;

    let currentIsNull = true;
    let currentAboveMinLimit = false;
    let currentEqualMinLimit = false;
    let currentBelowMaxLimit = false;
    let currentEqualMaxLimit = false;

    const setPreviousToCurrent = () => {
      previousIsNull = currentIsNull;
      previousAboveMinLimit = currentAboveMinLimit;
      previousEqualMinLimit = currentEqualMinLimit;
      previousBelowMaxLimit = currentBelowMaxLimit;
      previousEqualMaxLimit = currentEqualMaxLimit;
    };

    const previous = {
      equalMin: () => previousEqualMinLimit,
      aboveMin: () => previousAboveMinLimit,
      aboveOrEqualMin: () => previousAboveMinLimit || previousEqualMinLimit,
      belowMin: () => !previousAboveMinLimit && !previousEqualMinLimit,
      belowOrEqualMin: () => !previousAboveMinLimit,
      equalMax: () => previousEqualMaxLimit,
      belowMax: () => previousBelowMaxLimit,
      belowOrEqualMax: () => previousBelowMaxLimit || previousEqualMaxLimit,
      aboveMax: () => !previousBelowMaxLimit && !previousEqualMaxLimit,
      aboveOrEqualMax: () => !previousBelowMaxLimit,
    };

    const current = {
      isFirstPoint: () => previousIsNull,
      equalMin: () => currentEqualMinLimit,
      aboveMin: () => currentAboveMinLimit,
      aboveOrEqualMin: () => currentAboveMinLimit || currentEqualMinLimit,
      belowMin: () => !currentAboveMinLimit && !currentEqualMinLimit,
      belowOrEqualMin: () => !currentAboveMinLimit,
      equalMax: () => currentEqualMaxLimit,
      belowMax: () => currentBelowMaxLimit,
      belowOrEqualMax: () => currentBelowMaxLimit || currentEqualMaxLimit,
      aboveMax: () => !currentBelowMaxLimit && !currentEqualMaxLimit,
      aboveOrEqualMax: () => !currentBelowMaxLimit,
    };

    const setCurrent = (diff) => {
      if (diff === null) {
        currentIsNull = true;
        currentAboveMinLimit = undefined;
        currentEqualMinLimit = undefined;
        currentBelowMaxLimit = undefined;
        currentEqualMaxLimit = undefined;
      } else {
        currentIsNull = false;
        currentAboveMinLimit =
          diff > minYLimit && Math.abs(diff - minYLimit) > eps;
        currentEqualMinLimit = Math.abs(diff - minYLimit) <= eps;

        currentBelowMaxLimit =
          diff < maxYLimit && Math.abs(diff - maxYLimit) > eps;
        currentEqualMaxLimit = Math.abs(diff - maxYLimit) <= eps;
      }
    };

    let currentAreaCoordinates;
    const saveCurrentAreaCoordinates = () => {
      if (currentAreaCoordinates && currentAreaCoordinates.length > 1) {
        areas.push(
          currentAreaCoordinates.map((coordinate, i) => {
            const { x, y1, y2 } = coordinate;
            if (i === 0) {
              return { ...coordinate, area: 0 };
            } else {
              const {
                x: xPrev,
                y1: y1Prev,
                y2: y2Prev,
              } = currentAreaCoordinates[i - 1];
              const deltaX = x - xPrev;
              const deltaY2 = y2 - y2Prev;
              const deltaY1 = y1Prev - y1;
              const deltaYPrev = y2Prev - y1Prev;
              return {
                ...coordinate,
                area:
                  (deltaY2 * deltaX) / 2 +
                  (deltaY1 * deltaX) / 2 +
                  deltaYPrev * deltaX,
              };
            }
          })
        );
      }
      currentAreaCoordinates = null;
    };

    for (let i = 0; i < coordinatesRaw.length; i++) {
      saveCurrentAreaCoordinates();

      let diff;
      while (diff == null && i < coordinatesRaw.length) {
        const { x, y1, y2 } = coordinatesRaw[i];
        diff = getDiff(y1, y2);
        setPreviousToCurrent();
        setCurrent(diff);
        i++;
      }
      i--;

      const { x, y1, y2 } = coordinatesRaw[i];

      const isFirstPoint = current.isFirstPoint();

      const didEnterLimitAsFirstPoint =
        isFirstPoint &&
        (current.aboveMin() || (current.equalMin() && includeYMin)) &&
        current.belowOrEqualMax();
      const didEnterLimitFromBelow =
        !isFirstPoint &&
        ((previous.belowMin() && current.aboveOrEqualMin()) ||
          (previous.equalMin() && current.aboveMin()));
      const didEnterLimitFromAbove =
        !isFirstPoint &&
        ((previous.aboveMax() && current.belowOrEqualMax()) ||
          (previous.equalMax() && current.belowMax()));

      if (
        !didEnterLimitAsFirstPoint &&
        !didEnterLimitFromBelow &&
        !didEnterLimitFromAbove
      ) {
        continue;
      }

      currentAreaCoordinates = [];

      const threshold = didEnterLimitFromBelow ? minYLimit : maxYLimit;
      const otherThreshold = didEnterLimitFromBelow ? maxYLimit : minYLimit;
      const didExit =
        (didEnterLimitFromBelow && current.aboveMax()) ||
        (didEnterLimitFromAbove &&
          (current.belowMin() || (current.equalMin() && !includeYMin)));

      if (!isFirstPoint) {
        const intersection = getIntersection(
          coordinatesRaw[i - 1],
          coordinatesRaw[i],
          threshold
        );
        if (intersection) {
          currentAreaCoordinates.push(intersection);
        }
        if (didExit) {
          const intersection = getIntersection(
            coordinatesRaw[i - 1],
            coordinatesRaw[i],
            otherThreshold
          );
          if (intersection) {
            currentAreaCoordinates.push(intersection);
          }
          continue;
        }
        if (intersection && !intersection.second) {
          currentAreaCoordinates.push({ x, y1, y2 });
        }
      } else {
        currentAreaCoordinates.push({ x, y1, y2 });
      }

      i++;
      for (; i < coordinatesRaw.length; i++) {
        const { x, y1, y2 } = coordinatesRaw[i];
        const currentDiff = getDiff(y1, y2);

        setPreviousToCurrent();
        setCurrent(currentDiff);

        if (currentDiff == null) {
          break;
        }

        if (
          (current.aboveMin() || (current.equalMin() && includeYMin)) &&
          current.belowOrEqualMax()
        ) {
          currentAreaCoordinates.push({ x, y1, y2 });
          continue;
        }

        if (current.equalMin() && !includeYMin) {
          if (!previous.equalMin()) {
            currentAreaCoordinates.push({ x, y1, y2 });
          }
          break;
        }

        const threshold = !current.aboveMin() ? minYLimit : maxYLimit;

        const intersection = getIntersection(
          coordinatesRaw[i - 1],
          coordinatesRaw[i],
          threshold
        );

        if (intersection && !intersection.first) {
          currentAreaCoordinates.push(intersection);
        }
        break;
      }
    }
    saveCurrentAreaCoordinates();
    return areas
      .map((area) => {
        const totalArea = area.reduce((cum, { area }) => cum + area, 0);
        const deltaX = area[area.length - 1].x - area[0].x;
        const averageDeltaY = totalArea / deltaX;
        return {
          coordinates: area,
          startX: area[0].x,
          endX: area[area.length - 1].x,
          deltaX,
          averageDeltaY,
          area: totalArea,
        };
      })
      .filter(({ deltaX }) => minXDuration <= deltaX && deltaX <= maxXDuration);
  }, [xPercents, y1Percents, y2Percents, minYLimit]);
};
export const SVGHorizontalPlotArea = ({
  areasPercents,
  getAreaProps = () => null,
  fill = "red",
  strokeWidth = 0,
  ...pathProps
}) => {
  const isRTL = useIsRTL();
  const width = useWidth();
  const height = useHeight();

  const areas = useMemo(() => {
    return areasPercents.map((areaPercent) => {
      return {
        ...areaPercent,
        coordinatesPx: areaPercent.coordinates.map(({ x, y1, y2 }) => {
          x = (x * width) / 100;
          x = isRTL ? width - x : x;
          y1 = height - (y1 * height) / 100;
          y2 = height - (y2 * height) / 100;
          return { x, y1, y2 };
        }),
      };
    });
  }, [areasPercents, width, height, isRTL]);

  const areasWithPath = useMemo(() => {
    return areas.map((area) => {
      const pathParts = [];
      const coordinates = area.coordinatesPx;
      const coordinatesLength = coordinates.length;
      const { x, y2 } = coordinates[0];
      pathParts.push(`M ${x} ${y2}`);
      for (let i = 1; i < coordinates.length; i++) {
        const { x, y2 } = coordinates[i];
        pathParts.push(`L ${x} ${y2}`);
      }
      const { x: xEnd, y1: y1End } = coordinates[coordinatesLength - 1];
      pathParts.push(`L ${xEnd} ${y1End}`);
      for (let i = coordinatesLength - 2; i >= 0; i--) {
        const { x, y1 } = coordinates[i];
        pathParts.push(`L ${x} ${y1}`);
      }
      pathParts.push("z");
      return {
        ...area,
        path: pathParts.join(" "),
      };
    });
  }, [areas]);

  return areasWithPath.map((area, i) => (
    <path
      key={i}
      {...pathProps}
      fill={fill}
      strokeWidth={strokeWidth}
      {...getAreaProps({ areaIndex: i, ...area })}
      d={area.path}
    />
  ));
};
export const SVGHorizontalAreaBetweenTwoGraphs = ({
  xPercents,
  y1Percents,
  y2Percents,
  minYLimit,
  maxYLimit,
  includeYMin,
  minXDuration,
  maxXDuration,
  getDiff,
  getIntersection,
  getAreaProps = () => null,
  ...pathProps
}) => {
  const areasPercents = useAreasUnderTwoGraphs({
    xPercents,
    y1Percents,
    y2Percents,
    minYLimit,
    maxYLimit,
    includeYMin,
    minXDuration,
    maxXDuration,
    getDiff,
    getIntersection,
  });
  return (
    <SVGHorizontalPlotArea
      areasPercents={areasPercents}
      getAreaProps={getAreaProps}
      {...pathProps}
    />
  );
};

export const DomVerticalBars = ({
  getKey = (categoryIndex, barIndex) =>
    `${categoryIndex}${barIndex == null ? "" : `-${barIndex}`}`,
  getBarProps = () => ({
    barPercentSize: Math.round(Math.random() * 100),
  }),
}) => {
  const categories = useCategoriesInPx(useWidth());
  return categories.map((category) =>
    category.bars.map((bar, barIndex) => {
      return (
        <DomVerticalBar
          key={getKey(category.index, barIndex)}
          bar={bar}
          getBarProps={getBarProps}
        />
      );
    })
  );
};

/**
 * @callback GetBarStackProps
 * @param {{categoryIndex: number, barIndex: number, stackIndex: number, size: number, otherSize: number}} param0
 * @returns {null|false|Partial<{stackPercentSize: import("types/utils").Nullable<number>, stackSize: number, color: string, cursor: string, style: import("react").CSSProperties, zIndex: number, key: import("react").Key}>}
 */
/**
 * @param {{getBarStackProps?: GetBarStackProps, stacksCount: number}} param0
 */
export const DomVerticalStackedBars = ({
  getBarStackProps = () => ({
    stackPercentSize: Math.round(Math.random() * 100),
  }),
  stacksCount,
}) => {
  const canvasWidth = useWidth();
  const canvasHeight = useHeight();
  const isRTL = useIsRTL();
  const categories = useCategoriesInPx(useWidth());
  const allStacks = [];
  categories.forEach((category) =>
    category.bars.forEach((bar) => {
      let cumulativeSize = 0;
      const stacks = [];
      for (let stackIndex = 0; stackIndex < stacksCount; stackIndex++) {
        const barProps = getBarStackProps({
          categoryIndex: bar.categoryIndex,
          barIndex: bar.barIndex,
          stackIndex,
          size: canvasHeight,
          otherSize: canvasWidth,
        });
        if (barProps == null) {
          continue;
        }
        if (barProps === false) {
          break;
        }
        const {
          stackPercentSize = barProps,
          stackSize,
          color,
          cursor = "pointer",
          style,
          zIndex,
          key,
          ...props
        } = barProps ?? {};

        const height =
          stackPercentSize != null
            ? (canvasHeight * stackPercentSize) / 100
            : stackSize;

        const stackKey =
          key ?? `${bar.categoryIndex}-${bar.barIndex}-${stackIndex}`;
        stacks.push({
          key: stackKey,
          ...props,
          "data-stack-key": stackKey,
          "data-category-index": bar.categoryIndex,
          "data-series-index": bar.barIndex,
          "data-bar-index": bar.barIndex,
          "data-stack-index": stackIndex,
          style: {
            ...style,
            cursor,
            bottom: cumulativeSize,
            ...(zIndex != null ? { zIndex } : null),
            position: "absolute",
            [isRTL ? "right" : "left"]: `${bar.start}px`,
            width: `${bar.end - bar.start}px`,
            height: height != null ? `${height}px` : 0,
            backgroundColor: color ?? style?.backgroundColor ?? "black",
          },
        });

        cumulativeSize += height;
      }
      stacks.forEach((stack, i) => {
        stack.className = classNames("stacked-bar-chart_stack", {
          [stack.className]: stack.className,
          "stacked-bar-chart_stack--first-stack": i === 0,
          "stacked-bar-chart_stack--last-stack": i === stacks.length - 1,
        });
      });
      allStacks.push(...stacks);
    })
  );
  return allStacks.map((props) => <div {...props} />);
};

export const DomHorizontalBars = ({
  getKey = (categoryIndex, barIndex) =>
    `${categoryIndex}${barIndex == null ? "" : `-${barIndex}`}`,
  getBarProps = () => ({
    barPercentSize: Math.round(Math.random() * 100),
  }),
}) => {
  const categories = useCategoriesInPx(useHeight());
  return categories.map((category) =>
    category.bars.map((bar) => {
      return (
        <DomHorizontalBar
          key={getKey(category.index, bar.index)}
          bar={bar}
          getBarProps={getBarProps}
        />
      );
    })
  );
};

/**
 * @param {number} categoriesCount
 * @param {number} categoriesSpacing
 * @param {number} barsPerCategory
 * @param {number} barSpacing
 * @returns {PercentCategories}
 */
export const useComputedCategories = (
  categoriesCount,
  categoriesSpacing,
  barsPerCategory,
  barSpacing
) =>
  useMemo(
    () =>
      computeCategories(
        categoriesCount,
        categoriesSpacing,
        barsPerCategory,
        barSpacing
      ),
    [categoriesCount, categoriesSpacing, barsPerCategory, barSpacing]
  );

/**
 * @param {PercentCategories} computedCategories
 * @param {number} size
 * @returns {PxCategories}
 */
export const useComputedCategoriesInPx = (computedCategories, size) =>
  useMemo(
    () => resolveCategoriesInPx(computedCategories, size),
    [computedCategories, size]
  );

/**
 * @param {number} categoriesCount
 * @param {number} categoriesSpacing
 * @param {number} barsPerCategory
 * @param {number} barSpacing
 * @param {number} [chartsCount] number of rows case horizontal category axis bar charts, and number of columns case vertical category axis bar charts
 * @returns {PercentCategories[]} categories array for each chart of chartsCount
 */
export const useComputedMultiChartCategories = (
  categoriesCount,
  categoriesSpacing,
  barsPerCategory,
  barSpacing,
  chartsCount
) =>
  useMemo(
    () =>
      computeMutliChartCategories(
        categoriesCount,
        categoriesSpacing,
        barsPerCategory,
        barSpacing,
        chartsCount
      ),
    [
      categoriesCount,
      categoriesSpacing,
      barsPerCategory,
      barSpacing,
      chartsCount,
    ]
  );

/**
 * @typedef {import("modules/layout/hooks/useResizeObserver.js").OnResizeHandler} ResponsiveContainerOnResizeCallback
 */
/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.AllHTMLAttributes | {tagName:string,height:string|number,width:string|number,aspectRatio:number,aspectRatioBase:"x"|"y",children:React.ReactChildren,onResize:ResponsiveContainerOnResizeCallback}>}
 */
export const ResponsiveContainer = forwardRef(
  (
    {
      tagName = "div",
      height: heightFromParent,
      width: widthFromParent,
      aspectRatio,
      aspectRatioBase = "x",
      children,
      onResize,
      ...props
    },
    forwardedRef
  ) => {
    const [[width, height], setSize] = useState([0, 0]);
    const ref = useRef();
    useImperativeHandle(forwardedRef, () => ref.current);
    useEffect(() => {
      const element = ref.current;
      if (!element) {
        return;
      }
      if (aspectRatioBase === "x") {
        if (widthFromParent) {
          element.style.width = widthFromParent;
        }
        if (heightFromParent != null) {
          element.style.height = heightFromParent;
        } else if (aspectRatio != null) {
          element.style.height = `${width / aspectRatio}px`;
        }
      } else {
        if (heightFromParent) {
          element.style.height = heightFromParent;
        }
        if (widthFromParent != null) {
          element.style.width = widthFromParent;
        } else if (aspectRatio != null) {
          element.style.width = `${height * aspectRatio}px`;
        }
      }
    }, [
      widthFromParent,
      heightFromParent,
      aspectRatio,
      aspectRatioBase,
      width,
      height,
    ]);

    return (
      <ElementWithOnResize
        {...props}
        ref={ref}
        tagName={tagName}
        onResize={(e) => {
          onResize && onResize(e);
          setSize([e.width, e.height]);
        }}
      >
        <SizeProvider width={width} height={height}>
          {children}
        </SizeProvider>
      </ElementWithOnResize>
    );
  }
);

/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.AllHTMLAttributes | {tagName:string,height:string|number,width:string|number,aspectRatio:number,aspectRatioBase:"x"|"y",children:React.ReactChildren,onResize:ResponsiveContainerOnResizeCallback}>}
 */
export const ResponsivePlotContainer = forwardRef((props, forwardedRef) => (
  <ResponsiveContainer
    ref={forwardedRef}
    {...props}
    className={`plot-responsive-container${
      props.className ? ` ${props.className}` : ""
    }`}
  />
));

export const DomVerticalHighlighter = forwardRef(
  (
    { start, end, startPercent, endPercent, color, zIndex, style, ...props },
    ref
  ) => {
    const isRTL = useIsRTL();
    const width = useWidth();
    start = start ?? (startPercent * width) / 100;
    end = end ?? (endPercent * width) / 100;

    return (
      <div
        ref={ref}
        {...props}
        style={{
          top: 0,
          bottom: 0,
          background: "red",
          ...style,
          position: "absolute",
          ...(zIndex != null ? { zIndex } : null),
          ...(color ? { background: color } : null),
          [isRTL ? "right" : "left"]: `${start}px`,
          width: `${end - start}px`,
        }}
      ></div>
    );
  }
);

/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.AllHTMLAttributes | {color:string,className:string,includePaddings:number,categoryIndex?:number,category1Index?:number,category2Index?:number}>}
 */
export const DomCategoryVerticalHighlighter = forwardRef(
  (
    {
      categoryIndex,
      category1Index,
      category2Index,
      color,
      zIndex,
      style,
      includePaddings = 1,
      ...props
    },
    forwardedRef
  ) => {
    includePaddings = Number(includePaddings);

    const isRTL = useIsRTL();
    const categories = useCategoriesInPx(useWidth());

    category1Index = category1Index ?? categoryIndex;
    category2Index = category2Index ?? category1Index;

    if (category2Index < category1Index) {
      const t = category1Index;
      category1Index = category2Index;
      category2Index = t;
    }

    const category1 = categories[category1Index];
    const category2 = categories[category2Index];
    if (!category1 || !category2) {
      return null;
    }

    return (
      <div
        ref={forwardedRef}
        {...props}
        style={{
          top: 0,
          bottom: 0,
          background: "red",
          ...style,
          position: "absolute",
          ...(zIndex != null ? { zIndex } : null),
          ...(color ? { background: color } : null),
          [isRTL ? "right" : "left"]: `${
            includePaddings * category1.start +
            (1 - includePaddings) * category1.barsStart
          }px`,
          width: `${
            includePaddings * (category2.end - category1.start) +
            (1 - includePaddings) * (category2.barsEnd - category1.barsStart)
          }px`,
        }}
      ></div>
    );
  }
);

/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.AllHTMLAttributes | {categoryIndex:number, includePaddings:number, children:React.ReactChildren}>}
 */
export const DomCategoryVerticalHighlighterWithTooltip = forwardRef(
  (
    { categoryIndex, category1Index, category2Index, children, ...props },
    forwardedRef
  ) => {
    const { refs, x, y, strategy, update } = useFloating({
      placement: "top",
      middleware: [offset(5), flip(), shift({ padding: 5 })],
    });
    useEffect(() => {
      update();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryIndex, category1Index, category2Index]);
    return (
      <>
        <DomCategoryVerticalHighlighter
          ref={(element) => {
            refs.setReference(element);
            if (typeof forwardedRef === "function") {
              forwardedRef(element);
            } else if (forwardedRef) {
              forwardedRef.current = element;
            }
          }}
          categoryIndex={categoryIndex}
          category1Index={category1Index}
          category2Index={category2Index}
          {...props}
        />
        <PopoverPortal>
          <div
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? "",
              left: x ?? "",
            }}
          >
            {children}
          </div>
        </PopoverPortal>
      </>
    );
  }
);

/**
 * @callback CategoryHandler
 * @param {Event} e
 * @param {{ categoryIndex: number; oldCategoryIndex: number}} param1
 */

/**
 * @param {{onCategoryEnter: CategoryHandler, onCategoryLeave: CategoryHandler, onCategoryClick?: CategoryHandler, onCategoryDoubleClick?: CategoryHandler, includePaddings: number}} param0
 */
export const VerticalCategoriesMouseEvents = ({
  onCategoryEnter,
  onCategoryLeave,
  onCategoryClick = null,
  onCategoryDoubleClick = null,
  includePaddings = 1,
}) => {
  const ref = useRef();
  const width = useWidth();
  const categoriesPx = useCategoriesInPx(width);

  const mutable = useRef({ categoryIndex: -1 }).current;
  Object.assign(mutable, {
    categoriesPx,
    onCategoryEnter,
    onCategoryLeave,
    onCategoryClick,
    onCategoryDoubleClick,
    includePaddings,
  });
  useEffect(() => {
    const parent = ref.current.parentElement;
    const moveAndEnterHandler = (e) => {
      const { categoriesPx, includePaddings } = mutable;
      const targetBoundingRect = parent.getBoundingClientRect();
      const x = e.clientX - targetBoundingRect.x;
      let categoryIndex = categoriesPx.findLastIndex(({ start }) => x >= start);

      if (categoryIndex >= 0) {
        let { barsStart, barsEnd, start, end } = categoriesPx[categoryIndex];
        start = start * includePaddings + barsStart * (1 - includePaddings);
        end = end * includePaddings + barsEnd * (1 - includePaddings);

        if (start >= x || x >= end) {
          categoryIndex = -1;
        }
      }

      if (mutable.categoryIndex !== categoryIndex) {
        if (mutable.categoryIndex >= 0 && mutable.onCategoryLeave) {
          mutable.onCategoryLeave(e, {
            categoryIndex: mutable.categoryIndex,
            newCategoryIndex: categoryIndex,
          });
        }
        if (mutable.onCategoryEnter) {
          mutable.onCategoryEnter(e, {
            categoryIndex,
            oldCategoryIndex: mutable.categoryIndex,
          });
        }
        mutable.categoryIndex = categoryIndex;
      }
    };
    const leaveHandler = (e) => {
      if (mutable.categoryIndex >= 0 && mutable.onCategoryLeave) {
        mutable.onCategoryLeave(e, {
          categoryIndex: mutable.categoryIndex,
          newCategoryIndex: -1,
        });
        mutable.categoryIndex = -1;
      }
    };
    const clickHandler = (e) => {
      if (mutable.onCategoryClick) {
        mutable.onCategoryClick(e, { categoryIndex: mutable.categoryIndex });
      }
    };
    const doubleClickHandler = (e) => {
      if (mutable.categoryIndex >= 0 && mutable.onCategoryDoubleClick) {
        mutable.onCategoryDoubleClick(e, {
          categoryIndex: mutable.categoryIndex,
        });
      }
    };
    parent.addEventListener("mousemove", moveAndEnterHandler);
    parent.addEventListener("mouseenter", moveAndEnterHandler);
    parent.addEventListener("mouseleave", leaveHandler);
    parent.addEventListener("click", clickHandler);
    parent.addEventListener("dblclick", doubleClickHandler);
    return () => {
      parent.removeEventListener("mousemove", moveAndEnterHandler);
      parent.removeEventListener("mouseenter", moveAndEnterHandler);
      parent.removeEventListener("mouseleave", leaveHandler);
      parent.removeEventListener("click", clickHandler);
      parent.removeEventListener("dblclick", doubleClickHandler);
    };
  }, []);

  return <div ref={ref} style={{ display: "none" }} />;
};

/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.AllHTMLAttributes | {sense:"horizontal"|"vertical",anchor:"center"|"start"|"end",position:number,positionPercent:number,size:number,style:"solid"|"dotted"|"dashed",lineStyle:"solid"|"dotted"|"dashed",color:string,zIndex:number}>}
 */
export const DomLine = forwardRef(
  (
    {
      sense,
      anchor = "center",
      position,
      positionPercent,
      size = 1,
      style,
      lineStyle = style ?? "solid",
      color = "rgba(0,0,0,0.75)",
      zIndex,
      ...props
    },
    ref
  ) => {
    const isHorizontal = sense === "horizontal";
    const isRTL = useIsRTL();
    const width = useWidth();
    const height = useHeight();
    const canvasSize = isHorizontal ? height : width;

    position =
      positionPercent != null ? (positionPercent * canvasSize) / 100 : position;
    position -= anchor === "center" ? size / 2 : anchor === "start" ? 0 : size;

    const styleProp = isHorizontal
      ? {
          left: 0,
          right: 0,
          height: 0,
          borderTop: `${size}px ${lineStyle} ${color}`,
          ...(zIndex != null ? { zIndex } : null),
          position: "absolute",
          bottom: Number.isFinite(position) ? `${position}px` : 0,
          display: Number.isFinite(position) ? "" : "none",
        }
      : {
          bottom: 0,
          top: 0,
          width: 0,
          borderLeft: `${size}px ${lineStyle} ${color}`,
          ...(zIndex != null ? { zIndex } : null),
          position: "absolute",
          [isRTL ? "right" : "left"]: Number.isFinite(position)
            ? `${position}px`
            : 0,
          display: Number.isFinite(position) ? "" : "none",
        };
    return <div ref={ref} {...props} style={styleProp}></div>;
  }
);

/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.HTMLAttributes<HTMLElement> | {anchor:"center"|"start"|"end",position:number,positionPercent?:number,size?:number,style?:"solid"|"dotted"|"dashed",lineStyle?:"solid"|"dotted"|"dashed",color?:string,zIndex?:number}>}
 */
export const DomHorizontalLine = forwardRef((props, ref) => (
  <DomLine ref={ref} {...props} sense={"horizontal"} />
));
/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.HTMLAttributes<HTMLElement> | {anchor?:"center"|"start"|"end",position?:number,positionPercent?:number,size?:number,style?:"solid"|"dotted"|"dashed",lineStyle?:"solid"|"dotted"|"dashed",color?:string,zIndex?:number,ref?: import("react").MutableRefObject,children?:React.ReactNode,className?:string,onMouseDown?:Function}>}
 */
export const DomVerticalLine = forwardRef((props, ref) => (
  <DomLine ref={ref} {...props} sense={"vertical"} />
));

/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.HTMLAttributes<HTMLElement> | {sense:"horizontal"|"vertical",anchor:"center"|"start"|"end",count:number,position:number,positionPercent:number,size:number,style:"solid"|"dotted"|"dashed",lineStyle"solid"|"dotted"|"dashed",color:string,zIndex:number}>}
 */
export const DomGridLines = ({
  sense = "horizontal",
  size,
  style,
  lineStyle = style,
  color,
  zIndex,
  count,
  ...props
}) => {
  const interval = 100 / (count + 1);
  const lines = [];
  let cursor = interval;
  for (let i = 0; i < count; i++) {
    lines.push(
      <DomLine
        key={i}
        sense={sense}
        positionPercent={cursor}
        color={color}
        zIndex={zIndex}
        size={size}
        style={lineStyle}
        {...props}
      />
    );
    cursor += interval;
  }
  return lines;
};
/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.AllHTMLAttributes | {anchor?:"center"|"start"|"end",count:number,position?:number,positionPercent?:number,size?:number,style?:"solid"|"dotted"|"dashed",lineStyle?:"solid"|"dotted"|"dashed",color?:string,zIndex?:number}>}
 */
export const DomHorizontalGridLines = (props) => (
  <DomGridLines {...props} sense={"horizontal"} />
);
/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.AllHTMLAttributes | {anchor?:"center"|"start"|"end",count:number,position:number,positionPercent?:number,size?:number,style?:"solid"|"dotted"|"dashed",lineStyle?:"solid"|"dotted"|"dashed",color?:string,zIndex?:number}>}
 */
export const DomVerticalGridLines = (props) => (
  <DomGridLines {...props} sense={"vertical"} />
);

/**
 * @param {React.AllHTMLAttributes | {axis:"x"|"y",position:number,positionPercent:number,positionAnchor:"center"|"start"|"end",className:string,children:React.ReactChildren}} param0
 * @returns {React.ReactElement}
 */
export const AxisLabel = ({
  axis = "y",
  position,
  positionPercent,
  positionAnchor = "center",
  className,
  children,
  ...props
}) => {
  const isY = axis === "y";
  const width = useWidth();
  const height = useHeight();
  const canvasSize = isY ? height : width;
  position =
    positionPercent != null
      ? Math.round((positionPercent * canvasSize) / 100)
      : position;

  const isRTL = useIsRTL();

  const style = isY
    ? {
        transform: `translateY(${height - position}px)`,
      }
    : {
        transform: `translateX(${isRTL ? -position : position}px)`,
      };

  return (
    <div
      className={
        `plot-with-labels__${axis}-label plot-with-labels__${axis}-label--${positionAnchor}` +
        (className ? ` ${className}` : "")
      }
      style={style}
    >
      <div {...props}>{children}</div>
    </div>
  );
};
/**
 * @param {React.AllHTMLAttributes | {position:number,positionPercent:number,positionAnchor:"center"|"start"|"end",className:string,children:React.ReactChildren}} props
 * @returns {React.ReactElement}
 */
export const XAxisLabel = (props) => <AxisLabel {...props} axis="x" />;
/**
 * @param {React.AllHTMLAttributes | {position:number,positionPercent:number,positionAnchor:"center"|"start"|"end",className:string,children:React.ReactChildren}} props
 * @returns {React.ReactElement}
 */
export const YAxisLabel = (props) => <AxisLabel {...props} axis="y" />;

/**
 * @param {React.AllHTMLAttributes | {axis:"x"|"y",count:number,positionAnchor:"center"|"start"|"end",className:string,children:React.ReactChildren}} param0
 * @returns {React.ReactElement}
 */
export const AxisLabels = ({ axis = "y", children, count, ...props }) => {
  const interval = 100 / (count + 1);
  const lines = [];
  let cursor = interval;
  for (let i = 0; i < count; i++) {
    lines.push(
      <AxisLabel key={i} axis={axis} positionPercent={cursor} {...props}>
        {children[i]}
      </AxisLabel>
    );
    cursor += interval;
  }
  return lines;
};
/**
 * @param {React.AllHTMLAttributes | {count:number,positionAnchor:"center"|"start"|"end",className:string,children:React.ReactChildren}} props
 * @returns {React.ReactElement}
 */
export const XAxisLabels = (props) => <AxisLabels {...props} axis={"x"} />;
/**
 * @param {React.AllHTMLAttributes | {count:number,positionAnchor:"center"|"start"|"end",className:string,children:React.ReactChildren}} props
 * @returns {React.ReactElement}
 */
export const YAxisLabels = (props) => <AxisLabels {...props} axis={"y"} />;
export const CategoriesAxisLabels = ({ children, ...props }) => {
  const categories = useCategories();
  return categories.map(({ barsStartPercent, barsEndPercent }, i) => (
    <AxisLabel
      key={i}
      axis={"x"}
      positionPercent={(barsStartPercent + barsEndPercent) / 2}
      {...props}
    >
      {children[i]}
    </AxisLabel>
  ));
};

const LablesPortal = ({ root, labels }) => {
  const [, setDummy] = useState({});
  useEffect(() => {
    if (root) {
      setDummy({});
    }
  }, [root]);
  if (!root) {
    return null;
  }
  return createPortal(labels, root);
};

/**
 * @callback ResponsivePlotContainerWithAxisLabelsOnResizeCallback
 * @param {{width:number,height:number,main:{width:number,height:number,blockStart:number,inlineStart:number}}} param0
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLDivElement,React.AllHTMLAttributes | {tagName?:string,height?:string|number,width:string|number,aspectRatio:number,aspectRatioBase?:"x"|"y",xLabels:React.ReactNode,yLabels:React.ReactNode,xAlternateLabels?:React.ReactNode,yAlternateLabels:React.ReactNode, legendStart?: boolean,plotClassName?:string,children:React.ReactNode,onResize:ResponsivePlotContainerWithAxisLabelsOnResizeCallback}>}
 */
export const ResponsivePlotContainerWithAxisLabels = ({
  tagName,
  height: heightFromParent,
  aspectRatio,
  className,
  plotClassName,
  children,
  xLabels,
  xAlternateLabels,
  yLabels,
  yAlternateLabels,
  legendStart,
  onResize,
  ...props
}) => {
  const isRTL = useIsRTL();
  const ref = useRef({ setters: {}, targets: {} });
  const [portalTargets, setPortalTargets] = useState({});
  const getTargetSetter = (key) =>
    ref.current.setters[key] ??
    (ref.current.setters[key] = (element) => {
      const prevElement = ref.current.targets[key];
      if (prevElement !== element) {
        ref.current.targets[key] = element;
        setPortalTargets((portalTargets) => ({
          ...portalTargets,
          [key]: element,
        }));
      }
    });
  const size = useRef({
    width: 0,
    height: 0,
    main: { width: 0, height: 0, left: 0, top: 0 },
  }).current;
  const mainRef = useRef();
  const resize = () => {
    const main = mainRef.current;
    const parent = main.parentElement;
    const { offsetWidth: parentWidth, offsetHeight: parentHeight } = parent;
    const {
      offsetWidth: mainWidth,
      offsetHeight: mainHeight,
      offsetLeft: mainLeft,
      offsetTop: mainTop,
    } = main;
    Object.assign(size, {
      width: parentWidth,
      height: parentHeight,
      main: {
        width: mainWidth,
        height: mainHeight,
        blockStart: mainTop,
        inlineStart: isRTL ? parentWidth - mainLeft - mainWidth : mainLeft,
      },
    });
    onResize && onResize({ ...size });
  };
  return (
    <>
      <ResponsiveContainer
        {...props}
        tagName={tagName}
        aspectRatio={aspectRatio}
        className={"plot-with-labels" + (className ? ` ${className}` : "")}
        height={heightFromParent}
        onResize={resize}
      >
        <div></div>
        <div
          ref={getTargetSetter("blockStart")}
          className="plot-with-labels__x-labels plot-with-labels__x-labels--alternate"
        ></div>
        <div></div>
        <div
          ref={getTargetSetter("inlineStart")}
          className="plot-with-labels__y-labels"
        ></div>
        <ResponsiveContainer
          ref={mainRef}
          className={
            "plot-with-labels__plot" +
            (plotClassName ? ` ${plotClassName}` : "")
          }
          height="100%"
          onResize={resize}
        >
          {children}
          <LablesPortal root={portalTargets.blockEnd} labels={xLabels} />
          <LablesPortal
            root={portalTargets.blockStart}
            labels={xAlternateLabels}
          />
          <LablesPortal root={portalTargets.inlineStart} labels={yLabels} />
          <LablesPortal
            root={
              legendStart ? portalTargets.inlineStart : portalTargets.inlineEnd
            }
            labels={yAlternateLabels}
          />
        </ResponsiveContainer>
        <div
          ref={getTargetSetter("inlineEnd")}
          className="plot-with-labels__y-labels plot-with-labels__y-labels--alternate"
        />
        <div></div>
        <div
          ref={getTargetSetter("blockEnd")}
          className="plot-with-labels__x-labels"
        />
      </ResponsiveContainer>
    </>
  );
};
