import withIconProps from "helpers/withIconProps";

const OccWellbeingIcon = withIconProps((props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.75C6.33908 2.75 1.75 7.33908 1.75 13C1.75 13.4142 1.41421 13.75 1 13.75C0.585786 13.75 0.25 13.4142 0.25 13C0.25 6.51065 5.51065 1.25 12 1.25C18.4893 1.25 23.75 6.51065 23.75 13C23.75 13.4142 23.4142 13.75 23 13.75C22.5858 13.75 22.25 13.4142 22.25 13C22.25 7.33908 17.6609 2.75 12 2.75ZM6.32121 15.4746C7.01688 14.7016 7.97841 14.25 9 14.25H15C16.0216 14.25 16.9831 14.7016 17.6788 15.4746C18.3721 16.245 18.75 17.2744 18.75 18.3333V20C18.75 20.4142 18.4142 20.75 18 20.75C17.5858 20.75 17.25 20.4142 17.25 20V18.3333C17.25 17.6242 16.9957 16.9579 16.5639 16.478C16.1343 16.0008 15.5697 15.75 15 15.75H9C8.43029 15.75 7.86569 16.0008 7.43615 16.478C7.00426 16.9579 6.75 17.6242 6.75 18.3333V20C6.75 20.4142 6.41421 20.75 6 20.75C5.58579 20.75 5.25 20.4142 5.25 20V18.3333C5.25 17.2744 5.62788 16.245 6.32121 15.4746ZM9.75 9C9.75 7.75736 10.7574 6.75 12 6.75C13.2426 6.75 14.25 7.75736 14.25 9C14.25 10.2426 13.2426 11.25 12 11.25C10.7574 11.25 9.75 10.2426 9.75 9ZM12 5.25C9.92893 5.25 8.25 6.92893 8.25 9C8.25 11.0711 9.92893 12.75 12 12.75C14.0711 12.75 15.75 11.0711 15.75 9C15.75 6.92893 14.0711 5.25 12 5.25Z"
        fill="currentcolor"
      />
    </svg>
  );
});

export default OccWellbeingIcon;
