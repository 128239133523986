import React from "react";
import WidthProvider, { useWidth } from "./WidthProvider";
import HeightProvider, { useHeight } from "./HeightProvider";

export { WidthProvider, HeightProvider, useWidth, useHeight };

const SizeProvider = ({ width, height, children }) => {
  return (
    <WidthProvider width={width}>
      <HeightProvider height={height}>{children}</HeightProvider>
    </WidthProvider>
  );
};

export default SizeProvider;
