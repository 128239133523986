import classNames from "classnames";
import React from "react";

const PIM = ({ color = "#7a7a7a", className, iconScale = 1, icon = true }) => {
  const fill = color ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill={fill}
        d="M2.31,10.92s-.06,0-.08-.02c-.06-.03-.09-.09-.09-.15V2.44c0-.06,.03-.11,.07-.14L5.29,.13c.05-.04,.12-.04,.18-.01,.06,.03,.09,.09,.09,.15V4.37h-.35V.6l-2.73,1.92v7.88l2.73-1.92v-2.32h.35v2.41c0,.06-.03,.11-.07,.14l-3.08,2.17s-.07,.03-.1,.03h0Z"
      />
      <path fill={fill} d="M3.22,2.62h-.35v2.64h.35V2.62Z" />
      <path fill={fill} d="M3.22,6.05h-.35v2.64h.35v-2.64Z" />
      <path fill={fill} d="M4.02,7.12h-.35v1.82h.35v-1.82Z" />
      <path fill={fill} d="M4.02,4.16h-.35v2.51h.35v-2.51Z" />
      <path fill={fill} d="M4.02,2.21h-.35v1.36h.35v-1.36Z" />
      <path fill={fill} d="M4.76,1.63h-.35v2.53h.35V1.63Z" />
      <path fill={fill} d="M4.76,6.25h-.35v1.96h.35v-1.96Z" />
      <path
        fill={fill}
        d="M7.71,4v-.67h-1.19v-.35h1.54v.35l.78-.54-.78-.55v.35h-1.54v-.35h1.19v-.67l1.73,1.21-1.73,1.21Z"
      />
      <path
        fill={fill}
        d="M7.82,9.86v-.67h-1.19v-.35h1.54v.35l.78-.54-.78-.55v.35h-1.54v-.35h1.19v-.67l1.74,1.21-1.74,1.21Z"
      />
      <path
        fill={fill}
        d="M6.2,6.78v-.83h-1.61v-.35h1.96v.51l1.17-.82-1.17-.82v.51h-1.96v-.35h1.61v-.83l2.12,1.48-2.12,1.48Z"
      />
    </svg>
  );
};

export default PIM;
