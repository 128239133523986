import EditableParamValue, {
  type EditableParamValueProps,
} from "../../EditableParamValue/EditableParamValue";
import { type ReactNode, useId, useState } from "react";
import KeyValue from "atomic-components/molecules/KeyValue";
import { useTranslate } from "modules/language";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { Edit, ExternalLink, SaveIcon } from "svg-icons";
import SyncIcon from "svg-icons/SyncIcon";
import classNames from "classnames";
import type { Nullable } from "types/utils";
import EvaluationWizardInputError from "../EvaluationWizardInputError/EvaluationWizardInputError";
import isRealNumber from "helpers/isRealNumber";

import s from "./EvaluationWizardInput.module.scss";

type EvaluationWizardInputProps = {
  title: ReactNode;
  url?: string;
  resetValue: () => void;
  setIsParentExpanded: (enabled: boolean) => void;
  className?: string;
  isEditable?: boolean;
  placeholderValue?: Nullable<number>;
} & Omit<
  EditableParamValueProps,
  "labelElementId" | "isEditEnabled" | "setIsEditEnabled"
>;

const EvaluationWizardInput = ({
  setIsParentExpanded,
  title,
  url,
  maxValue,
  error,
  resetValue,
  setValue,
  className,
  isEditable = true,
  placeholderValue,
  ...props
}: EvaluationWizardInputProps) => {
  const labelElementId = useId();
  const errorElementId = `${labelElementId}-error`;
  const t = useTranslate();
  const [isEditEnabled, _setIsEditEnabled] = useState(false);

  const setIsEditEnabled = (enabled: boolean) => {
    setIsParentExpanded(enabled);
    _setIsEditEnabled(enabled);
  };

  return (
    <div>
      <div className={classNames(s.keyValueContainer, className)}>
        <KeyValue
          keyText={
            <>
              {title}{" "}
              {url ? (
                <a href={url} target="_blank" rel="noreferrer">
                  <ExternalLink />
                </a>
              ) : null}
            </>
          }
          keyTag="label"
          keyProps={{
            id: labelElementId,
          }}
          keyClassName={s.keyValue}
          valueClassName={s.keyValue}
          isLight
          value={
            <>
              <EditableParamValue
                required={!isRealNumber(placeholderValue)}
                labelElementId={labelElementId}
                isEditEnabled={isEditEnabled}
                maxValue={maxValue}
                error={error}
                aria-errormessage={
                  isEditable && isEditEnabled ? undefined : errorElementId
                }
                setValue={setValue}
                placeholderValue={placeholderValue}
                {...props}
              />
              {maxValue ? <>/{maxValue}</> : null}
            </>
          }
        />
        {isEditable ? (
          isEditEnabled ? (
            <>
              {isRealNumber(placeholderValue) ? (
                <StrippedButton
                  isFlex
                  type="reset"
                  title={t("action.AUTO_SYNC")}
                  onClick={(e) => {
                    e.preventDefault();
                    resetValue();
                  }}
                >
                  <SyncIcon className={s.icon} color="var(--color-primary)" />
                </StrippedButton>
              ) : null}
              <StrippedButton
                isFlex
                type="button"
                title={t("action.SAVE")}
                onClick={() => {
                  if (error) {
                    return;
                  }

                  setIsEditEnabled(false);
                }}
              >
                <SaveIcon
                  className={s.icon}
                  color="var(--color-primary)"
                  iconScale={0.7}
                />
              </StrippedButton>
            </>
          ) : (
            <StrippedButton
              isFlex
              type="button"
              title={t("action.EDIT")}
              onClick={() => {
                setIsEditEnabled(true);
              }}
            >
              <Edit
                className={s.icon}
                iconScale={0.8}
                color="var(--color-primary)"
              />
            </StrippedButton>
          )
        ) : null}
      </div>
      {isEditable && !isEditEnabled ? (
        <EvaluationWizardInputError id={errorElementId}>
          {error}
        </EvaluationWizardInputError>
      ) : (
        ""
      )}
    </div>
  );
};

export default EvaluationWizardInput;
export type { EvaluationWizardInputProps };
