const { useRef, useEffect } = require("react");

const useClickOutside = (
  insideElementsIdsOrClasses,
  callback,
  enabled = true
) => {
  const mutable = useRef({}).current;
  mutable.insideElementsIdsOrClasses = insideElementsIdsOrClasses;
  mutable.callback = callback;
  useEffect(() => {
    if (!enabled) {
      return;
    }
    /** @type {React.MouseEventHandler} */
    const onMouseDownHandler = (e) => {
      const { insideElementsIdsOrClasses, callback } = mutable;

      /** @type {HTMLElement} */
      let element = e.target;
      while (element) {
        const matched = Boolean(
          insideElementsIdsOrClasses.find(
            (insideElementIdOrClass) =>
              element === insideElementIdOrClass ||
              element.id === insideElementIdOrClass ||
              element.classList.contains(insideElementIdOrClass)
          )
        );
        if (matched) {
          return;
        }
        element = element.parentElement;
      }
      callback(e);
    };
    document.addEventListener("mousedown", onMouseDownHandler);
    return () => document.removeEventListener("mousedown", onMouseDownHandler);
  }, [enabled]);
};

export default useClickOutside;
