import { useTranslate } from "modules/language";
import EditableParamWidget from "../EditableParamWidget/EditableParamWidget";

type ElectricityEmissionIntensityEditableProps = {
  isEditEnabled: boolean;
  isHistoryEnabled: boolean;
  isHistoryExpanded?: boolean;
  disabled: boolean;
};

const ElectricityEmissionIntensityEditable = ({
  isEditEnabled,
  isHistoryEnabled,
  isHistoryExpanded,
  disabled,
}: ElectricityEmissionIntensityEditableProps) => {
  const t = useTranslate();

  return (
    <EditableParamWidget
      paramName="electricityEmissionIntensity"
      keyText={t("label.ELECTRICITY_EMISSION_INTENSITY")}
      historyLabel={t("label.ELECTRICITY_EMISSION_INTENSITY_HISTORY")}
      isEditEnabled={isEditEnabled}
      isHistoryEnabled={isHistoryEnabled}
      isHistoryExpanded={isHistoryExpanded}
      disabled={disabled}
      maxValue={99.999}
      minValue={0.1}
    />
  );
};

export default ElectricityEmissionIntensityEditable;
