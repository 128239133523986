import { fromMillis } from "helpers/dateTimeHelper";
import {
  ONE_DAY,
  ONE_HOUR,
  checkIntervalCount,
  getDST,
  getDayFromTo,
  normalizeFromTo,
} from "../modules/building/helpers/intervals";
import { sortedLastIndexBy } from "lodash";

type GetBucketKeyFunction = (
  from: number,
  to: number,
  timezone: string
) => string;

type TimeBucket = {
  key: string;
  from: number;
  to: number;
  duration: number;
  timezone: string;
  resolutionKey: SupportedTimeBucketsResolutions;
};

const getBuckets = (
  fromTs: number,
  toTs: number,
  timezone: string,
  interval: number,
  normalIntervalsPerBucket: number,
  getBucketKey: GetBucketKeyFunction = (from, to) => `${from}-${to}`,
  resolutionKey: SupportedTimeBucketsResolutions
) => {
  const getBucket = (from: number, to: number): TimeBucket => ({
    key: getBucketKey(from, to, timezone),
    from,
    to,
    duration: to - from,
    timezone,
    resolutionKey,
  });
  let cursor = fromTs;
  const buckets = [];
  const isGreaterThanAnHour = normalIntervalsPerBucket * interval > ONE_HOUR;
  for (let i = 0; ; i++) {
    if (!isGreaterThanAnHour) {
      let next = cursor + normalIntervalsPerBucket * interval;
      if (next > toTs) {
        const ticksCount = Math.ceil(toTs - cursor) / interval;
        if (ticksCount) {
          next = cursor + ticksCount * interval;
          buckets.push(getBucket(cursor, next));
        }
        break;
      } else {
        buckets.push(getBucket(cursor, next));
      }
      cursor = next;
    } else {
      let expectedTicksInThisInterval = normalIntervalsPerBucket;
      let actualTicksInThisInterval = 0;
      let currentCursor = cursor;
      for (
        ;
        currentCursor < toTs &&
        actualTicksInThisInterval < expectedTicksInThisInterval;
        actualTicksInThisInterval++
      ) {
        let next = currentCursor + interval;
        expectedTicksInThisInterval +=
          (getDST(currentCursor, timezone) * ONE_HOUR) / interval;
        currentCursor = next;
      }
      if (actualTicksInThisInterval > 0) {
        const next = cursor + actualTicksInThisInterval * interval;
        buckets.push(getBucket(cursor, next));
        cursor = currentCursor;
      } else {
        break;
      }
    }
  }

  return buckets;
};

type SupportedTimeBucketsResolutions =
  | "1hour"
  | "3hours"
  | "6hours"
  | "12hours"
  | "1day";
const supportedDurations: Record<SupportedTimeBucketsResolutions, number> = {
  "1hour": ONE_HOUR,
  "3hours": 3 * ONE_HOUR,
  "6hours": 6 * ONE_HOUR,
  "12hours": 12 * ONE_HOUR,
  "1day": ONE_DAY,
};
const supportedDurationsGetBucketKeyFunction: Record<
  SupportedTimeBucketsResolutions,
  GetBucketKeyFunction
> = {
  "1hour": (from, to, timezone) => {
    const fromDateTime = fromMillis(from, { zone: timezone });
    return fromDateTime.toFormat("yyyy-MM-dd' 'h:mma");
  },
  "3hours": (from, to, timezone) => {
    const fromDateTime = fromMillis(from, { zone: timezone });
    const toDateTime = fromMillis(to, { zone: timezone });
    return `${fromDateTime.toFormat(
      "yyyy-MM-dd' 'h:mma"
    )}-${toDateTime.toFormat("h:mma")}`;
  },
  "6hours": (from, to, timezone) => {
    const fromDateTime = fromMillis(from, { zone: timezone });
    const toDateTime = fromMillis(to, { zone: timezone });
    return `${fromDateTime.toFormat(
      "yyyy-MM-dd' 'h:mma"
    )}-${toDateTime.toFormat("h:mma")}`;
  },
  "12hours": (from, to, timezone) => {
    const fromDateTime = fromMillis(from, { zone: timezone });
    const toDateTime = fromMillis(to, { zone: timezone });
    return `${fromDateTime.toFormat(
      "yyyy-MM-dd' 'h:mma"
    )}-${toDateTime.toFormat("h:mma")}`;
  },
  "1day": (from, to, timezone) => {
    const fromDateTime = fromMillis(from, { zone: timezone });
    return fromDateTime.toFormat("yyyy-MM-dd");
  },
};

const createTimeBuckets = (
  fromTs: number,
  toTs: number,
  timezone: string,
  interval: number,
  durationKey: SupportedTimeBucketsResolutions
) => {
  if (!supportedDurations[durationKey]) {
    throw new Error(
      `unsupported time bucket key "${durationKey}". Supported keys: ${Object.keys(
        supportedDurations
      )}`
    );
  }
  fromTs = getDayFromTo({
    timezone: timezone,
    reference: fromTs,
  }).fromTs;
  toTs = getDayFromTo({
    timezone: timezone,
    reference: toTs - 1,
  }).toTs;

  const normalizedTimeRange = normalizeFromTo({
    from: fromTs,
    to: toTs,
    timezone,
  });

  fromTs = normalizedTimeRange.fromTs;
  toTs = normalizedTimeRange.toTs;

  const normalIntervalsPerBucket = Math.round(
    supportedDurations[durationKey] / interval
  );
  checkIntervalCount(normalIntervalsPerBucket, interval);

  const buckets = getBuckets(
    fromTs,
    toTs,
    timezone,
    interval,
    normalIntervalsPerBucket,
    supportedDurationsGetBucketKeyFunction[durationKey],
    durationKey
  );
  const getBucketKey = (timestamp: number) => {
    const bucketIndex =
      // @ts-ignore
      sortedLastIndexBy(buckets, { from: timestamp }, "from") - 1;
    return buckets[bucketIndex]?.key;
  };

  return {
    buckets,
    getBucketKey,
    min: fromTs,
    max: toTs,
    step: supportedDurations[durationKey],
    stepCount: buckets.length,
  };
};

export default createTimeBuckets;
export type { SupportedTimeBucketsResolutions, TimeBucket };
