import classNames from "classnames";
import React from "react";

const Hide = ({ className, icon = true }) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      viewBox="0 0 11 11"
    >
      <path
        style={{
          fill: "none",
          stroke: "currentcolor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "1.05px",
        }}
        d="M.8,.8L10.23,10.23M4.38,4.42c-.27,.28-.44,.67-.44,1.09,0,.87,.7,1.57,1.57,1.57,.43,0,.82-.17,1.1-.45M2.63,2.71c-.99,.66-1.75,1.64-2.12,2.8,.67,2.12,2.65,3.67,5,3.67,1.04,0,2.01-.3,2.83-.83M4.99,1.87c.17-.02,.35-.03,.52-.03,2.34,0,4.33,1.54,5,3.67-.15,.47-.36,.91-.62,1.31"
      />
    </svg>
  );
};

export default Hide;
