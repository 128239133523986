import classNames from "classnames";
import type { SVGAttributes } from "react";

type LineCloseProps = SVGAttributes<SVGElement> & {
  icon?: boolean;
  iconScale?: number;
  color?: string;
};

const LineClose = ({
  icon = true,
  iconScale = 1,
  color = "#171717",
  className,
  ...props
}: LineCloseProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, { "para-icon": icon })}
      style={{
        transform: `scale(${iconScale})`,
      }}
      {...props}
    >
      <path
        d="M17 1L1 17M1 1L17 17"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LineClose;
