import PieChartWithLegends, {
  type PieChartItem,
  type PieChartProps,
} from "../PieChartWithLegends/PieChartWithLegends";
import UnitValue from "atomic-components/atoms/UnitValue";
import SectionTitle from "atomic-components/atoms/SectionTitle/SectionTitle";
import classNames from "classnames";
import type { HTMLAttributes, ReactNode } from "react";
import type { Currency, Unit } from "types/unit";

import s from "./PieChartStatusContainer.module.scss";

type PieChartStatusContainerProps = HTMLAttributes<HTMLDivElement> & {
  statusTitle?: ReactNode;
  chartTitle?: ReactNode;
  statusElement: ReactNode;
  statusElementPortion?: number;
  chartItems: PieChartItem[];
  chartTooltipUnit?: Unit | Currency;
  renderTooltip?: PieChartProps["renderTooltip"];
  chartProps?: Omit<PieChartProps, "items" | "renderTooltip">;
};

const PieChartStatusContainer = ({
  statusTitle,
  chartTitle,
  statusElement,
  statusElementPortion = 1,
  chartItems,
  chartTooltipUnit,
  renderTooltip,
  chartProps,
  className,
  ...props
}: PieChartStatusContainerProps) => {
  return (
    <div className={classNames(s.container, className)} {...props}>
      <section
        className={s.statusContainer}
        style={{ flex: statusElementPortion }}
      >
        {statusTitle ? (
          <SectionTitle tag="h3">{statusTitle}</SectionTitle>
        ) : null}
        <div className={s.statusContent}>{statusElement}</div>
      </section>
      <section className={s.chartContainer}>
        {chartTitle ? (
          <SectionTitle tag="h3" className={s.chartTitle}>
            {chartTitle}
          </SectionTitle>
        ) : null}
        <PieChartWithLegends
          items={chartItems}
          renderTooltip={
            renderTooltip ??
            (({ value }) => {
              return <UnitValue value={value} unit={chartTooltipUnit} />;
            })
          }
          {...chartProps}
        />
      </section>
    </div>
  );
};

export default PieChartStatusContainer;
export type { PieChartStatusContainerProps };
