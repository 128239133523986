import { shift } from "@floating-ui/core";
import {
  BarChartCategoriesProvider,
  CategoriesAxisLabels,
  DomHorizontalGridLines,
  DomHorizontalLine,
  DomVerticalLine,
  DomVerticalStackedBars,
  ResponsivePlotContainerWithAxisLabels,
  YAxisLabel,
  YAxisLabels,
} from "atomic-components/organisms/Charts";
import FormattedValue from "atomic-components/atoms/FormattedValue";
import { times } from "lodash";
import FooterGraphEmissions from "./FooterGraphEmissions/FooterGraphEmissions";
import HeaderGraphEmissions, {
  GreenHouseGas,
} from "./HeaderGraphEmissions/HeaderGraphEmissions";
import { useEffect, useMemo, useRef, useState } from "react";
import { PopoverPortal } from "atomic-components/atoms/InfoPopover";
import Section from "atomic-components/atoms/Section";
import { offset, useFloating } from "@floating-ui/react-dom";
import { useViewState } from "modules/building/providers";
import { useTotalEmissionsByYear } from "modules/building/hooks/useTotalEmissionsByYear";
import { useTranslate } from "modules/language";
import { useWindowSize } from "modules/core/services/window-size.service";
import { useUtilsEmissions } from "modules/building/hooks/useUtilsEmissions";
import useGraphEmissions from "modules/building/hooks/useGraphEmissions";

import s from "./GraphEmissions.module.scss";

type TooltipProps = {
  display: boolean;
  totalScopes: number;
  listScopes: number[];
};

type GraphEmissionsProps = {
  barsCategoryCount: number;
  barsPerCategory: number;
  categoriesSpacing: number;
  stacksCount: number;
};

const GraphEmissions = ({
  barsCategoryCount,
  barsPerCategory,
  categoriesSpacing,
  stacksCount,
}: GraphEmissionsProps) => {
  const t = useTranslate();
  const {
    timeBuckets,
    timeAxis,
    areaBuilding,
    anchorTypes,
    getColor,
    calculateScopesByGHGEI,
    calculateScopesByGHG,
  } = useGraphEmissions();
  const { height, width } = useWindowSize();
  const { yearFromTimeStamp } = useUtilsEmissions();
  const isDeskScreen = useMemo(() => {
    if (width < height) {
      return false;
    }
    return true;
  }, [height, width]);

  const backGroundRef = useRef<HTMLDivElement>(null);
  const [witdhBackGround, setWitdhBackGround] = useState<number>(0);

  const getDivWidth = async () => {
    if (backGroundRef.current) {
      const width = await backGroundRef.current.offsetWidth;
      setWitdhBackGround(width);
    }
  };

  const [typeGas, setTypeGas] = useState<GreenHouseGas>(GreenHouseGas.GHG);
  const [shownTooltip, setShownTooltip] = useState<TooltipProps>({
    display: false,
    totalScopes: 0,
    listScopes: [],
  });
  const { x, y, strategy, refs } = useFloating({
    middleware: [offset(12), shift({ padding: 5 })],
    placement: "top",
  });

  const { fromTs } = useViewState();

  const { consumptionsByYear } = useTotalEmissionsByYear();

  const memoScopes = useMemo(() => {
    const totals: number[] = consumptionsByYear.consumption.map(
      (item) => item.total
    );
    const maxNumber: number = Math.max(...totals);
    const step: number = Number((maxNumber / 4).toFixed(3));

    return {
      sumsPerDate: totals,
      maxNumber,
      step,
    };
  }, [consumptionsByYear]);

  const memoBounds = useMemo(() => {
    if (typeGas === GreenHouseGas.GHG) {
      return {
        min: 0,
        max: Number(memoScopes.maxNumber.toFixed(3)),
        step: memoScopes.step,
        stepCount: 3,
      };
    }
    return {
      min: 0,
      max: Number((memoScopes.maxNumber / areaBuilding).toFixed(3)),
      step: memoScopes.step / areaBuilding,
      stepCount: 3,
    };
  }, [typeGas]);

  const stackMouseEnter = (
    e: MouseEvent,
    { categoryIndex }: { categoryIndex: number }
  ) => {
    const dataConsumption = consumptionsByYear.consumption;
    const listScopes = dataConsumption[categoryIndex].scopes;
    const totalScopes = dataConsumption[categoryIndex].total;
    const invertListScopes = listScopes.slice();
    refs.setReference(e.target as Element);
    setShownTooltip({
      display: true,
      totalScopes:
        typeGas !== GreenHouseGas.GHG
          ? Number((totalScopes / areaBuilding).toFixed(3))
          : Number(totalScopes.toFixed(3)),
      listScopes:
        typeGas !== GreenHouseGas.GHG
          ? calculateScopesByGHGEI(invertListScopes)
          : calculateScopesByGHG(invertListScopes),
    });
  };

  const stackMouseLeave = () =>
    setShownTooltip({ display: false, totalScopes: 0, listScopes: [] });

  useEffect(() => {
    getDivWidth();
  }, []);

  return (
    <>
      <BarChartCategoriesProvider
        categoriesCount={barsCategoryCount}
        barsPerCategory={barsPerCategory}
        categoriesSpacing={categoriesSpacing}
      >
        <HeaderGraphEmissions
          gasType={typeGas}
          isDeskScreen={isDeskScreen}
          onChangeTypeGas={(param) => setTypeGas(param)}
          year={yearFromTimeStamp(fromTs)}
        />
        <ResponsivePlotContainerWithAxisLabels
          width="100%"
          height="80%"
          xLabels={
            <CategoriesAxisLabels>
              {timeBuckets.map((bucket) => (
                <div
                  className={s.xAxisLabel}
                  style={{
                    fontSize: isDeskScreen
                      ? "var(--font-size-large)"
                      : "var(--font-size-xsmall)",
                  }}
                >
                  <p>{`${bucket} ${yearFromTimeStamp(fromTs)}`}</p>
                </div>
              ))}
            </CategoriesAxisLabels>
          }
          yLabels={
            memoBounds ? (
              <>
                <YAxisLabel positionAnchor="start" positionPercent={2}>
                  <div
                    style={{
                      fontSize: isDeskScreen
                        ? "var(--font-size-medium)"
                        : "var(--font-size-small)",
                    }}
                  >
                    {<FormattedValue value={memoBounds.min} />}
                  </div>
                </YAxisLabel>
                <YAxisLabels count={memoBounds.stepCount}>
                  {times(memoBounds.stepCount, (i) => (
                    <div
                      style={{
                        fontSize: isDeskScreen
                          ? "var(--font-size-medium)"
                          : "var(--font-size-small)",
                      }}
                    >
                      <FormattedValue
                        value={memoBounds.min + (i + 1) * memoBounds.step}
                      />
                    </div>
                  ))}
                </YAxisLabels>
                <YAxisLabel positionAnchor="start" positionPercent={100}>
                  <div
                    style={{
                      fontSize: isDeskScreen
                        ? "var(--font-size-medium)"
                        : "var(--font-size-small)",
                    }}
                  >
                    {<FormattedValue value={memoBounds.max} />}
                  </div>
                </YAxisLabel>
              </>
            ) : null
          }
          legendStart={true}
          yAlternateLabels={
            <div className={s.containLeyend}>
              <p>{typeGas}</p>
            </div>
          }
        >
          <div ref={backGroundRef}>
            {timeAxis.map(({ percent }, index) => (
              <DomVerticalLine
                key={index}
                anchor="end"
                positionPercent={percent}
                size={(witdhBackGround * 2) / 100}
                color="var(--graph-columns)"
              />
            ))}
          </div>
          <DomHorizontalGridLines
            count={memoBounds.stepCount * 2 + 1}
            anchor={anchorTypes.center}
            color="var(--gray-xlight-color)"
          />
          <DomVerticalStackedBars
            getBarStackProps={(stackArgs) => {
              const { categoryIndex, stackIndex } = stackArgs;
              const dataConsumption = consumptionsByYear.consumption;
              const dataScopes = dataConsumption[categoryIndex].scopes;
              const value = dataScopes[dataScopes.length - 1 - stackIndex];
              let yPercent =
                (100 * (value - memoBounds!.min)) /
                (memoBounds!.max - memoBounds!.min);
              if (typeGas !== GreenHouseGas.GHG) {
                yPercent = yPercent / areaBuilding;
              }

              return {
                style: {
                  "--background-color": getColor(stackIndex),
                } as any,
                className: s.stack,
                stackPercentSize: yPercent,
                onMouseEnter: (e: MouseEvent) => stackMouseEnter(e, stackArgs),
                onMouseLeave: stackMouseLeave,
              };
            }}
            stacksCount={stacksCount}
          ></DomVerticalStackedBars>
          <DomHorizontalLine position={0} size={1} anchor="start" />
          <DomVerticalLine position={0} size={1} anchor="start" />
        </ResponsivePlotContainerWithAxisLabels>
        <FooterGraphEmissions
          scopes={stacksCount}
          isDeskScreen={isDeskScreen}
        />
      </BarChartCategoriesProvider>
      {shownTooltip.display ? (
        <PopoverPortal>
          <Section
            ref={refs.setFloating}
            className={"tip-container"}
            style={{
              position: strategy,
              top: y ?? "",
              left: x ?? "",
              minWidth: 150,
            }}
          >
            <p className={s.titleTooltip}>
              Total: {<FormattedValue value={shownTooltip.totalScopes} />}
            </p>
            <div className={s.scopesTooltip}>
              {shownTooltip.listScopes.map((scope, index) => (
                <p key={`${index}textLeyend`}>
                  {t("label.SCOPE")} {index + 1}:{" "}
                  {<FormattedValue value={scope} />}
                </p>
              ))}
            </div>
          </Section>
        </PopoverPortal>
      ) : null}
    </>
  );
};

export default GraphEmissions;
export type { GraphEmissionsProps };
