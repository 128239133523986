import { sum } from "lodash";
import isRealNumber from "./isRealNumber";
import type { Nullable } from "types/utils";

const getPerformanceKPI = (
  target: Nullable<number>,
  value: Nullable<number>
) => {
  target = isRealNumber(target) ? target : 0;
  value = isRealNumber(value) ? value : 0;
  if (target === 0 && value === 0) {
    return 0;
  }
  if (value === 0 || target > value) {
    return 1;
  }
  if (target === 0) {
    return 0;
  }
  return target / value;
};

export const getCompoundPerformanceKPI = (
  targets: Nullable<number>[],
  values: Nullable<number>[],
  weights: number[]
) =>
  targets.reduce<number>(
    (kpi, target, i) => kpi + getPerformanceKPI(target, values[i]) * weights[i],
    0
  ) / (sum(weights) || 1);

export default getPerformanceKPI;
