import type { SVGAttributes } from "react";

type EyeIconProps = SVGAttributes<SVGElement>;

const EyeIcon = (props: EyeIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M15.9792 7.87257C15.9929 7.90298 16 7.93692 16 7.97106V8.03012C16 8.06406 15.9929 8.098 15.9792 8.12842C15.9531 8.18747 13.2919 14 7.99964 14C2.70773 14 0.0467556 8.18767 0.0208 8.12842C0.00711111 8.098 0 8.06406 0 8.03012V7.97106C0 7.93692 0.00711111 7.90298 0.0208 7.87257C0.0469333 7.81331 2.70773 2 7.99964 2C13.2919 2 15.9532 7.81331 15.9792 7.87257ZM14.6108 7.44883C14.2244 6.81214 13.7612 6.17631 13.2249 5.58734C11.7392 3.95576 9.99878 3 7.99964 3C6.0007 3 4.26048 3.95573 2.77499 5.58729C2.23876 6.17625 1.77565 6.81207 1.38923 7.4488C1.26245 7.6577 1.15484 7.84889 1.07396 8.0006C1.15438 8.15119 1.26256 8.34347 1.38912 8.55197C1.7755 9.18855 2.23862 9.82427 2.77486 10.4131C4.26037 12.0444 6.00064 13 7.99964 13C9.9988 13 11.7392 12.0444 13.2249 10.413C13.7612 9.82417 14.2243 9.18846 14.6108 8.55183C14.7378 8.34262 14.8452 8.15184 14.926 8.00032C14.8456 7.8497 14.7374 7.65738 14.6108 7.44883ZM7.99906 4.75C9.79207 4.75 11.25 6.2072 11.25 7.99953C11.25 9.79211 9.79201 11.25 7.99906 11.25C6.20726 11.25 4.75 9.79195 4.75 7.99953C4.75 6.20731 6.20707 4.75 7.99906 4.75ZM7.99906 5.75C6.75939 5.75 5.75 6.75956 5.75 7.99953C5.75 9.23978 6.75967 10.25 7.99906 10.25C9.23974 10.25 10.25 9.23981 10.25 7.99953C10.25 6.7596 9.2399 5.75 7.99906 5.75ZM7.99923 7.25C7.58591 7.25 7.25 7.58601 7.25 7.9988C7.25 8.41235 7.58607 8.74759 7.99923 8.74759C8.41393 8.74759 8.75 8.41235 8.75 7.9988C8.75 7.58601 8.41393 7.25 7.99923 7.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EyeIcon;
export type { EyeIconProps };
