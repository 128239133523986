import classNames from "classnames";
import { forwardRef, type HTMLAttributes } from "react";

import s from "./Section.module.scss";

type SectionProps = HTMLAttributes<HTMLElement> & {
  variant?: "default" | "withBorder" | "extraSpacing";
};

const Section = forwardRef<HTMLElement, SectionProps>(
  ({ variant = "default", className, ...props }, ref) => {
    return (
      <section
        ref={ref}
        className={classNames(s.container, className, s[variant])}
        {...props}
      />
    );
  }
);

export default Section;
export type { SectionProps };
