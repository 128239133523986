const flattenWithChildren = <ENTITY extends { children?: ENTITY[] }>(
  items?: ENTITY | ENTITY[],
  getItemChildren = (item: ENTITY) => item?.children
): ENTITY[] => {
  if (!items) {
    return [];
  }
  if (!Array.isArray(items)) {
    items = [items];
  }
  return [
    ...items,
    ...items.flatMap((item) =>
      item ? flattenWithChildren(getItemChildren(item)) : []
    ),
  ];
};

export default flattenWithChildren;

Object.assign(window, flattenWithChildren);
