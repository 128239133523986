import { useMemo } from "react";
import { sumBy } from "lodash";
import { useIsRTL } from "../../../modules/language";
import type { Nullable } from "types/utils";
import FormattedValue from "atomic-components/atoms/FormattedValue";
import isRealNumber from "helpers/isRealNumber";
import classNames from "classnames";

import s from "./PerformanceMeter.module.scss";
import colorNumberToHex from "helpers/colorNumberToHex";

const COLOR_GREEN = 0x6dc111;
const COLOR_ORANGE = 0xff9e00;
const COLOR_RED = 0xff3e4e;

const COLOR_GREEN_DARK = 0x62ad0f;
const COLOR_ORANGE_DARK = 0xea9102;
const COLOR_RED_DARK = 0xdd2b39;

type PerformanceMeterProps = {
  value: Nullable<number>;
  isVertical?: boolean;
  lowValueWeight?: number;
  highValueWeight?: number;
  maxValueWeight?: number;
  rangeValues?: {
    minValue: number;
    lowValue: number;
    highValue: number;
    maxValue: number;
  };
  showTopIndicators?: boolean;
  isInverted?: boolean;
  widthValue?: string;
  showValue?: boolean;
};

const PerformanceMeter = ({
  value: valueFromProps,
  isVertical = false,
  lowValueWeight = 1,
  highValueWeight = 1,
  maxValueWeight = 1,
  rangeValues = {
    minValue: 0,
    lowValue: 0.7,
    highValue: 0.9,
    maxValue: 1,
  },
  showTopIndicators = false,
  showValue = true,
  isInverted = false,
  widthValue,
}: PerformanceMeterProps) => {
  const dir = useIsRTL() ? -1 : 1;
  const { minValue, lowValue, highValue, maxValue } = rangeValues;

  const value =
    !valueFromProps || valueFromProps < minValue
      ? minValue
      : valueFromProps > maxValue
      ? maxValue
      : valueFromProps;

  const { kpiRangePercent, statusColor, colors } = useMemo(() => {
    const colorOptions = [
      { bar: COLOR_RED, bullet: COLOR_RED_DARK },
      { bar: COLOR_ORANGE, bullet: COLOR_ORANGE_DARK },
      { bar: COLOR_GREEN, bullet: COLOR_GREEN_DARK },
    ];
    const colorsOrdered = isInverted ? colorOptions.reverse() : colorOptions;
    const ranges = [
      {
        end: lowValue,
        weight: lowValueWeight,
        barColor: colorsOrdered[0].bar,
        bulletColor: colorsOrdered[0].bullet,
      },
      {
        end: highValue,
        weight: highValueWeight,
        barColor: colorsOrdered[1].bar,
        bulletColor: colorsOrdered[1].bullet,
      },
      {
        end: maxValue,
        weight: maxValueWeight,
        barColor: colorsOrdered[2].bar,
        bulletColor: colorsOrdered[2].bullet,
      },
    ];

    const totalWeights = sumBy(ranges, "weight");

    const valueRangeIndex = ranges.findIndex(({ end }) => value <= end);
    const valueRangeStart = ranges[valueRangeIndex - 1]?.end ?? minValue;
    const valueRangeEnd = ranges[valueRangeIndex].end;
    let kpiRangeInit = 0;
    let colorRangeWeightAgg = 0;
    const colors: string[] = [];

    const rangeLength = ranges.length;
    for (let i = 0; i < rangeLength; i++) {
      const { barColor, weight } = ranges[i];
      const hexColor = colorNumberToHex(barColor);

      if (i < valueRangeIndex) {
        kpiRangeInit += weight;
      }

      colorRangeWeightAgg += weight;

      const colorPercentPoint = `${Math.round(
        (colorRangeWeightAgg * 100) / totalWeights
      )}%`;
      colors.push(`${hexColor} ${colorPercentPoint}`);

      const next = ranges[i + 1];
      if (next) {
        colors.push(`${colorNumberToHex(next.barColor)} ${colorPercentPoint}`);
      }
    }

    const kpiRangePercent = Math.round(
      (((value - valueRangeStart) / (valueRangeEnd - valueRangeStart)) *
        (ranges[valueRangeIndex].weight / totalWeights) +
        kpiRangeInit / totalWeights) *
        100
    );

    return {
      kpiRangePercent,
      colors: colors.join(","),
      statusColor: ranges[valueRangeIndex].bulletColor,
    };
  }, [
    lowValue,
    lowValueWeight,
    highValue,
    highValueWeight,
    maxValue,
    maxValueWeight,
    minValue,
    value,
    isInverted,
  ]);

  return (
    <div
      className={classNames(s.container, {
        [s.vertical]: isVertical,
      })}
    >
      <div className={s.meterContainer}>
        {showTopIndicators && (
          <div className={s.topIndicators}>
            <div>{minValue}</div>
            <div>{lowValue}</div>
            <div>{highValue}</div>
            <div>{maxValue}</div>
          </div>
        )}
        <meter
          className={s.meter}
          min={minValue}
          max={maxValue}
          low={lowValue}
          high={highValue}
          optimum={maxValue}
          value={value}
          style={{
            background: `linear-gradient(90deg, ${colors})`,
          }}
        />
        <div
          aria-hidden
          className={s.pointer}
          style={{
            insetInlineStart:
              kpiRangePercent >= 99 && kpiRangePercent <= 100
                ? `calc(${dir * kpiRangePercent}% - 6px)`
                : `calc(${dir * kpiRangePercent}%)`,
            border: `3px solid ${colorNumberToHex(statusColor)}`,
          }}
        ></div>
      </div>
      {showValue && (
        <span
          className={classNames(s.value, {
            [s.overflowHidden]: !!widthValue,
          })}
          style={{ color: colorNumberToHex(statusColor), width: widthValue }}
        >
          <FormattedValue value={isRealNumber(valueFromProps) ? value : null} />
        </span>
      )}
    </div>
  );
};

export default PerformanceMeter;
export type { PerformanceMeterProps };
