import { lazy } from "react";
import { PageUrlEnum } from "./enums";
import { enabledPages } from "whitelabel-config";

const ErrorPage = lazy(
  () => import("modules/common/pages/ErrorPage/ErrorPage")
);
const SustainabilityPage = lazy(
  () => import("modules/building/pages/SustainabilityPage/SustainabilityPage")
);
const OperationalSpendingPage = lazy(
  () => import("modules/building/pages/OperationalSpending")
);
const AssetManagement = lazy(
  () => import("modules/building/pages/AssetManagement")
);
const AssetManagementPage = lazy(
  () => import("modules/building/pages/AssetManagementPage/AssetManagementPage")
);
const IndoorConditionsPage = lazy(
  () => import("modules/building/pages/IndoorsConditions/IndoorConditions")
);
const OccupantWellbeing = lazy(
  () => import("modules/building/pages/OccupantWellbeing")
);
const PortfolioManagement = lazy(
  () => import("modules/building/pages/PortfolioManagement")
);
const InsightsManagement = lazy(
  () => import("modules/building/pages/InsightsManagement")
);

const routesData = [
  {
    url: PageUrlEnum.OccupantWellbeing,
    element: OccupantWellbeing,
    isBuildingDependent: true,
  },
  {
    url: PageUrlEnum.UserComfort,
    element: IndoorConditionsPage,
    isBuildingDependent: true,
  },
  {
    url: PageUrlEnum.Sustainability,
    element: SustainabilityPage,
    isBuildingDependent: true,
  },
  {
    url: PageUrlEnum.OperationalSpending,
    element: OperationalSpendingPage,
    isBuildingDependent: true,
  },
  {
    url: PageUrlEnum.AssetManagementNew,
    element: AssetManagementPage,
    isBuildingDependent: true,
  },
  {
    url: PageUrlEnum.AssetManagement,
    element: AssetManagement,
    isBuildingDependent: true,
  },
  {
    url: PageUrlEnum.PortfolioManagement,
    element: PortfolioManagement,
    isBuildingDependent: true,
  },
  {
    url: PageUrlEnum.InsightsManagement,
    element: InsightsManagement,
    isBuildingDependent: true,
  },
].filter(({ url }) => enabledPages.includes(url));

export default routesData;

export { ErrorPage };
