import type {
  InsightsRequest,
  TelemetryRequest,
} from "modules/building/hooks/useLinkedData";
import type { ComponentType } from "react";
import type { Currency, Unit } from "./unit";
import type { AggregationKeys } from "modules/building/helpers/createLinkedTelemetriesAggregator";
import type { Entity } from "modules/building/helpers/postProcessBuildingData";

type AggregationsKeys =
  | "thermalPower"
  | "electricPower"
  | "default"
  | "spending"
  | "consumption"
  | "spendingPerHour"
  | "status"
  | "coolingMode";

export enum TelemetryFilterKeyEnum {
  Occupancy = "Occupancy",
}

const aggregationsConfigsByKey: Record<AggregationsKeys, AggregationsConfig> = {
  thermalPower: {
    key: "thermalPower",
    aggregations: ["consumption", "spending", "average"],
  },
  electricPower: {
    key: "electricPower",
    aggregations: ["consumption", "spending", "average"],
  },
  default: {
    key: "default",
    aggregations: ["average"],
  },
  spending: {
    key: "spending",
    aggregations: ["sum"],
  },
  consumption: {
    key: "consumption",
    aggregations: ["sum"],
  },
  spendingPerHour: {
    key: "spendingPerHour",
    aggregations: ["spendingFromSpendingPerHour"],
  },
  status: {
    key: "status",
    aggregations: ["onPercent", "onDuration"],
  },
  coolingMode: {
    key: "coolingMode",
    aggregations: ["onDuration", "coolingModeDuration", "fanModeDuration"],
  },
};

type AggregationsConfig = {
  key: AggregationsKeys;
  aggregations: AggregationKeys[];
};

type TrendDisplay = ComponentType<{
  aggregationKey?: AggregationKeys;
}>;

type Trend<KEY extends string = string> = {
  key: KEY;
  color: string;
  isAlternate?: boolean;
  neededTelemetries: TelemetryRequest[];
  neededInsights?: InsightsRequest[];
  aggregationsKey: AggregationsKeys;
  aggregations: AggregationKeys[];
  /**
   * the entityId to select from the output of `neededTelemetries`
   */
  entityId: string;
  /**
   * the key of the telemetry to select from the output of `neededTelemetries`
   */
  telemetry: string;
  /**
   * to display trend name
   */
  Display: TrendDisplay;
  unit: Unit | Currency;
  entityBreadcrumbs?: Entity[];
  telemetryFilterKey?: TelemetryFilterKeyEnum;
  hasAlarms?: boolean;
};

type TrendSlim = Omit<Trend, "color" | "unit" | "aggregations"> & {
  color?: Trend["color"];
  unit?: Trend["unit"];
};

export { aggregationsConfigsByKey };

export type { Trend, TrendSlim, AggregationsKeys, TrendDisplay };
