import { useId, type LiHTMLAttributes, useState, ReactNode } from "react";
import ChevronDownIcon from "svg-icons/ChevronDownIcon";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import classNames from "classnames";

import s from "./AccordionGroupElement.module.scss";

type AccordionGroupElementProps = LiHTMLAttributes<HTMLLIElement> & {
  text?: ReactNode;
  isInitiallyExpanded?: boolean;
  // Component is optionally controlled
  isExpanded?: boolean;
  setIsExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  variant?: "default" | "primary" | "condensed" | "plain";
  renderAccordionButton?: ({
    isExpanded,
  }: {
    isExpanded: boolean;
  }) => ReactNode;
};

const AccordionGroupElement = ({
  text,
  isInitiallyExpanded = false,
  isExpanded: isExpandedFromProps,
  setIsExpanded: setIsExpandedFromProps,
  children,
  variant = "default",
  renderAccordionButton,
  className,
  ...props
}: AccordionGroupElementProps) => {
  const elementId = useId();
  const childrenElementId = `${elementId}-children`;
  const [isExpandedLocal, setIsExpandedLocal] =
    useState<boolean>(isInitiallyExpanded);

  const isExpanded = isExpandedFromProps ?? isExpandedLocal;
  const setIsExpanded = setIsExpandedFromProps ?? setIsExpandedLocal;

  const ChildrenWrapper = renderAccordionButton ? "div" : "ul";

  return (
    <li
      className={classNames(
        s[`variant--${variant}`],
        {
          [s.isExpanded]: isExpanded,
        },
        className
      )}
      {...props}
    >
      <StrippedButton
        className={classNames(s.btn, s[variant], {
          [s.btnCustom]: renderAccordionButton,
        })}
        type="button"
        id={elementId}
        aria-expanded={isExpanded}
        aria-controls={childrenElementId}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        {renderAccordionButton ? (
          renderAccordionButton({ isExpanded })
        ) : (
          <>
            {text}
            <ChevronDownIcon aria-hidden className={s.chevron} />
          </>
        )}
      </StrippedButton>

      <ChildrenWrapper
        id={childrenElementId}
        aria-labelledby={elementId}
        aria-hidden={!isExpanded}
        className={s.children}
      >
        {children}
      </ChildrenWrapper>
    </li>
  );
};

export default AccordionGroupElement;
export type { AccordionGroupElementProps };
