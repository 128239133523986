const EN = {
  "title.PARA": "Para",
  "error.ERROR_LOADING_BUILDING": "Error loading building",
  "error.ERROR_LOADING_MODEL": "Error loading model",
  "title.LOGIN": "Login",
  "label.USERNAME": "Username",
  "placeholder.USERNAME": "your username",
  "label.PASSWORD": "Password",
  "placeholder.PASSWORD": "your password",
  "action.LOGIN": "Login",
  "error.COULD_NOT_LOGIN":
    "Could not log you in. Make sure your username and password are valid",
  "title.STAY_LOGGED_IN": "Stay logged in",
  "message.STAY_LOGGED_IN":
    "Your session is about to expire. Do you want to stay logged in?",
  "action.STAY_LOGGED_IN": "Stay Logged In",
  "action.CANCEL": "Cancel",
  "action.APPLY": "Apply",
  "action.CONFIRM": "Confirm",
  "action.REPLACE": "Replace",
  "action.EDIT": "Edit",
  "action.ENABLE_HISTORY": "Enable History",
  "action.PDF_EXTERNAL_LINK": "Go to pdf",
  "action.DISABLE_HISTORY": "Disable History",
  "action.EXPAND_HISTORY": "Expand History",
  "action.COLLAPSE_HISTORY": "Collapse History",
  "action.REVERT": "Revert",
  "action.UPDATE": "Update",
  "error.DOCUMENT_REQUIRED": "Document is required",
  "error.INVALID_URL": "Invalid url",
  "error.DOCUMENT_TITLE_REQUIRED": "Document Title is required",
  "error.USERNAME_REQUIRED": "User Name is required",
  "error.PASSWORD_REQUIRED": "Password is required",
  "error.REQUIRED": "Required",
  "error.CHART_DUPLICATED": "Chart with the same name already found",
  "toast.FILL_USERNAME_AND_PASSWORD": "Please fill username and password",
  ERR_RECAPTCHA: "Your captcha validation failed. Try again if you are a human",
  ER_INVALID_CAPTCHA:
    "Your captcha validation failed. Try again if you are a human",
  "error.COULD_NOT_STAY_LOGGEDIN": "Could not keep you logged in",
  "error.UPLOADING_IFC": "Error uploading/converting IFC file",
  ER_BUSY_CONVERTING_ANOTHER_IFC:
    "Busy converting another model. Please wait and try again later.",
  "action.MORE": "More",
  "action.BACK": "Back",
  "menu.DASHBOARD": "Dashboard",
  "menu.USER_COMFORT": "User comfort",
  "menu.HUMAN_CENTIC": "Human Centic",
  "menu.SUSTAINABILITY": "Sustainability",
  "menu.ENERGY_INTELLIGENCE": "Energy Intelligence",
  "menu.ASSET_MANAGEMENT": "Asset Management",
  "title.MAIN_MENU": "Main Menu",
  "title.PERFORMANCE": "Performance",
  "message.POWERED_BY": "Powered by",
  "pageTitle.ENERGY_INTELLIGENCE": "Energy Intelligence",
  "dateFilter.TODAY": "Today",
  "dateFilter.DAY": "Day",
  "dateFilter.WEEK": "Week",
  "dateFilter.MONTH": "Month",
  "dateFilter.DAILY": "Daily",
  "label.DAY": "Day",
  "label.DAYS": "Days",
  "label.WEEKLY": "Weekly",
  "label.MONTHLY": "Monthly",
  "label.BUILDING_VIEW": "Building View",
  "title.OPERATIONAL_SPENDING": "Operational Spending",
  "label.TOTAL": "Total",
  "label.POTENTIAL_SAVINGS": "Potential Savings",
  "label.TOTAL_SPENDING": "Total Spending",
  "message.SPENDING_IMPORVEMENT": "${value}% improvement from last week",
  "message.SPENDING_REGRESSION": "${value}% worse than last week",
  "message.SPENDING_SAME": "Same as last week",
  "label.ELECTRICITY": "Electricity",
  "label.ELECTRICITY_KW": "Electricity (kW)",
  "label.CHILLED_WATER": "Chilled Water",
  "label.HOT_WATER": "Hot Water",
  "label.CHILLED_WATER_KWT": "Chilled Water (kWt)",
  "title.EFFICIENCY": "Efficiency",
  "title.OCCUPANCY": "Occupancy",
  "title.RECOMMENDATIONS": "Recommendations",
  "defaultOption.MODEL_COLORIZATION": "Colorization off",
  "defaultOption.MODEL_ANNOTATION": "Annotations off",
  "option.COLORIZE_DESKS": "Desk",
  "option.COLORIZE_PIM": "PIM",
  "option.COLORIZE_LIGHTS": "Lights",
  "option.ANNOTATE_DESKS": "Desk",
  "option.ANNOTATE_PIM": "PIM",
  "option.ANNOTATE_LIGHTS": "Lights",
  "defaultOption.HEAT_MAP": "Heatmaps off",
  "heatMapOption.NONE": "Heatmaps off",
  "heatMapOption.ATTENDANCE": "Attendance",
  "heatMapOption.OCUPANCY": "Occupancy",
  "heatMapOption.ELECTRICITY": "Electricity",
  "heatMapOption.LIGHTING": "Lighting",
  "heatMapOption.MECHANICAL": "Mechanical",
  "heatMapOption.POWER": "Power",
  "heatMapOption.UPS": "UPS",
  "heatMapOption.WATER": "Water",
  "heatMapOption.CHILLED_WATER": "Chilled Water",
  "title.LIVE_VIEW": "Live View",
  "title.ELECTRICITY": "Electricity",
  "title.CHILLED_WATER": "Chilled Water",
  "title.OCCUPIED_AREAS": "Occupied Areas",
  "title.WHOLE_FLOOR": "Whole Floor",
  "filter.FROM_TO_DATE": "From ${from} to ${to}",
  "error.ERROR_LOADING_TELEMETRIES": "Error loading telemetries",
  "action.HIDE_ALL": "Hide all",
  "title.HOURLY_BREAKDOWN": "Hourly Breakdown",
  "title.ALARMS_HOURLY_BREAKDOWN": "Alarms Hourly Breakdown",
  "title.BREAKDOWN_BY_TYPE": "Breakdown By Type",
  "title.HVAC": "HVAC",
  "title.BREAKDOWN_BY_ZONE": "Breakdown by Zone",
  "title.BREAKDOWN_BY_SOURCE": "Breakdown by Source",
  "title.TOTAL_SPENDING": "Total Spending",
  "title.TOTAL_POTENTIAL_SAVINGS": "Total potential savings",
  "title.ELECTRICITY_TOTAL_SPENDING": "Total Electricity",
  "title.ELECTRICITY_POTENTIAL_SAVINGS": "Electricity potential savings",
  "title.CHILLED_WATER_TOTAL_SPENDING": "Total Chilled Water",
  "title.CHILLED_WATER_POTENTIAL_SAVINGS": "Chilled Water potential savings",
  "title.LIGHTING_TOTAL_SPENDING": "Total Lighting",
  "title.LIGHTING_POTENTIAL_SAVINGS": "Lighting potential savings",
  "title.HVAC_TOTAL_SPENDING": "Total HVAC",
  "title.HVAC_POTENTIAL_SAVINGS": "HVAC potential savings",
  "title.POWER_TOTAL_SPENDING": "Total Power",
  "title.POWER_POTENTIAL_SAVINGS": "Power potential savings",
  "title.LIGHTING": "Lighting",
  "title.POWER": "Power",
  "title.PREVIOUS_WEEK": "Previous week",
  "title.ANALYTICS": "Analytics",
  "label.AVERAGE_SPENDING": "Avg Spending",
  "label.PEAK_SPENDING": "Max Spending",
  "label.LARGEST_PERFORMANCE_GAP": "Max Performance Gap",
  "label.START": "Start",
  "label.END": "End",
  "label.DURATION": "Duration",
  "unit.HOURS": "Hr",
  "unit.HOURS_PLURAL": "Hrs",
  "note.STILL_ACTIVE": "Still active",
  "note.SO_FAR": "so far",
  "legend.FROM_TO": "${from}-${to}",
  "groupOption.BY_FUNCTIONAL_AREA": "Group by functional area",
  "groupOption.BY_SYSTEM": "Group by system",
  "groupOption.BY_DURATION": "Group by duration",
  "groupOption.BY_TOTAL_COST": "Group by total cost",
  "groupOption.BY_COST_PER_HOUR": "Group by hourly cost",
  "groupOption.BY_NUMBER_OF_RECURRENCE": "Group by recurrence count",
  "groupOption.BY_TYPE": "Group by type",
  "title.BY_FUNCTIONAL_AREA": "By functional area",
  "title.BY_DURATION": "By duration",
  "title.BY_TOTAL_COST": "By total cost",
  "title.BY_COST_PER_HOUR": "By hourly cost",
  "title.BY_NUMBER_OF_RECURRENCE": "By recurrence count",
  "title.BY_TYPE": "By type",
  "sortOption.BY_CHRONOLOGY": "Sort by Chronology",
  "sortOption.BY_IMPACT": "Sort by Impact",
  "sortOption.BY_DURATION": "Sort by duration",
  "message.REFRESHING_TELEMETRIES": "Refreshing telemetries from server",
  "warning.TELEMETRIES_TRIMMED_FROM_SERVER":
    "Some of the requested data is not yet available on the server. Will auto check shortly",
  "title.POTENTIAL_SAVINGS_BY_HOUR": "Potential savings by hour",
  "legend.TOTAL_RECOMMENDATIONS": "Recommendation Count",
  "legend.TOTAL_POTENTIAL_SAVINGS": "Potential Savings",
  "title.POTENTIAL_SAVINGS_BY_TYPE": "Potential savings by type",
  "legend.OCCUPIED": "Occupied",
  "legend.UNOCCUPIED": "Unoccupied",
  "legend.WITHINRANGE": "Within range",
  "legend.OUTSIDERANGE": "Outside range",
  "legend.CHARTDETAILS": "Chart details",
  "legend.TOTALHRSWITHINRANGE": "Total # of hrs within range",
  "legend.OCCUPIEDHOURS": "Occupied hours",
  "title.AHU_ZONE": "AHU Zone",
  "title.RECOMMENDATIONS_COUNT": "Recommendations Count",
  "title.POTENTIAL_SAVINGS": "Potential Savings",
  "title.POTENTIAL_SAVINGS_BY_ZONE": "Potential savings by area",
  "title.AIR_HANDLING_UNITS": "Air handling units",
  "title.FAN_COIL_UNITS": "Fan coil units",
  "title.CHILLED_WATER_PUMPS": "Chilled water pumps",
  "title.PIPES": "Pipes",
  "title.DISTRIBUTION_PANEL_BOARDS": "Distribution panel boards",
  "title.DUCTS": "Ducts",
  "title.OTHERS": "Others",
  "title.OTHER_HVAC": "Other HVAC",
  "title.ZONE": "Zone",
  "message.NO_PROPERTIES_TO_DISPLAY": "No properties to display",
  "toast.OBJECT_NOT_SELECTABLE": "This object is not selectable",
  "toast.LINK_COPIED": "The link has been successfully copied",
  "title.STATIC_PROPERTIES": "Technical Info",
  "title.DYNAMIC_PROPERTIES": "Live Data",
  "title.ANALYTICS_PROPERTIES": "Analytics",
  "message.STILL_ONGOING": "Active",
  "telemetryName.CONSUMPTION": "Consumption",
  "telemetryName.CUMULATIVE_POTENTIAL_SAVINGS": "Cumulative Potential Savings",
  "telemetryName.CARBON_FOOTPRINT": "Carbon Footprint",
  "telemetryName.CARBON_FOOTPRINT_TARGET": "Carbon Footprint Target",
  "telemetryName.PERFORMANCE_GAP": "Performance Gap",
  "title.FAULTS_OR_RECOMMENDATION": "Faults/Recommendations",
  "title.CONSUMPTION_VS_PREDICTED": "Actual vs predicted Consumption",
  "message.NO_TELEMETRIES_TO_SHOW": "No live data available for this item yet",
  "status.CLEAN": "Clean",
  "status.DIRTY": "Dirty",
  "tip.HIDE_SHOW_ANNOTATIONS": "Hide/Show hover popups",
  "tip.HIDE_SHOW_COLORIZATIONS": "Enable/Disable status colorization",
  "title.CONSUMPTION": "Consumption",
  "title.CARBON_FOOTPRINT": "Carbon Footprint",
  "message.NO_FAULTS": "No faults to show",
  "tip.HIDE_SHOW_ALL": "Hide/Show top level",
  "title.ASSET_MANAGEMENT": "Asset Management",
  "label.DASHBOARD": "Dashboard",
  "toast.COULD_NOT_GET_LIVE_STATUS":
    "Could not get live status of ${entity.display}",
  "toast.NO_STATUS_AVAILABLE_TO_CHANGE":
    "Status of ${entity.display} in not available to change",
  "toast.LOADING_STATUS_PLEASE_WAIT":
    "Loading status of ${entity.display} in progress. Please wait",
  "toast.CHANGING_STATUS_PLEASE_WAIT":
    "Another status change is in progress. Please wait",
  "toast.COULD_NOT_TURN_ON": "Could not turn on ${entity.display}",
  "toast.COULD_NOT_TURN_OFF": "Could not turn off ${entity.display}",
  "label.CONTROL": "Control",
  "tip.ON_SINCE": "On since ${date}",
  "tip.OFF_SINCE": "Off since ${date}",
  "tip.CLICK_TO_TURN_OFF": "Click to turn off",
  "tip.CLICK_TO_TURN_ON": "Click to turn on",
  "tip.UNKNOWN_STATUS": "Unknown status",
  "status.ON": "On",
  "status.OFF": "Off",
  "confirm.TURN_ON": "Are you sure you want to turn ON ${entity.display}?",
  "confirm.TURN_OFF": "Are you sure you want to turn OFF ${entity.display}?",
  "toast.STATUS_UNKNOWN_FOR":
    "Could not retrieve the status of ${entity.display}. Hence it is not possible to change its status. Will reattempt to retrieve the status",
  "note.WELCOME_USER": "Welcome ${user}!",
  "note.IS_ADMIN": "you are a global admin",
  "action.LOGOUT": "Logout",
  "action.SWITCH": "Switch to",
  "tip.ENABLE_DIABLE_CAMERA_LIGHT": "Enable/Disable flashlight",
  "toast.HOLD_CTRL_TO_ADD_TO_SELECTION":
    "Hold ctrl key and click to add/remove selection",
  "filter.X_HOURS_SELECTED": "${x} hours selected",
  "tip.TIME_IN_THE_FUTURE": "This time is in the future",
  "title.OPERATIONS_AND_SUSTAINABILITY": "Operations and Sustainability",
  "title.FDD_RECOMMENDATIONS": "FDD/Recommendations",
  "telemetryName.TARGET": "Target",
  "action.CLEAR_LOCAL_CACHE": "Clear local cache",
  "toast.FAILED_TO_CLEAR_CACHE": "Failed to clear local cache",
  "label.ID": "ID",
  "label.DATE": "Date",
  "label.START_DATE": "Start Date",
  "label.END_DATE": "End Date",
  "label.DETAILS": "Details",
  "title.CO2_POTENTIAL_SAVINGS": "Potential carbon reduction",
  "option.ENERGY": "Energy",
  "option.CURRENCY": "Currency",
  "option.EMISSIONS": "Emissions",
  "note.AVERAGE_ACTIVE_RECOMMENDATIONS": "Active recommendation(s)",
  "note.POTENTIAL_SAVINGS": "Potential savings",
  "label.SUCCESS_RATE": "Success Rate",
  "label.TOTAL_RECOMMENDATIONS": "Total Recommendations",
  "label.TOTAL_ACTED_UPON": "Acted Upon",
  "label.AVERAGE_RESPONSE_TIME": "Average Response Time",
  "label.TOTAL_SAVINGS": "Total Savings",
  "label.ACTED_UPON": "Acted Upon",
  "label.NOT_ACTED_UPON": "Not Acted Upon",
  "title.INSIGHTS": "Insights",
  "legend.ACTED_UPON": "Acted upon",
  "legend.NOT_ACTED_UPON": "Not acted upon",
  "legend.LIGHTING_INSIGHTS": "Lighting Insights",
  "legend.ACTUAL_SAVING": "Actual Saving",
  "legend.AVG_POWER": "Avg Power",
  "legend.AVG_TARGET_POWER": "Avg Target Power",
  "action.SHOW_LESS": "Show less",
  "action.SHOW_REMAINING_X": "Show remaining ${x}",
  "action.ADVANCED_MENU_HANDLE_TEXT": "Advanced",
  "title.BY_RECOMMENDATION_TYPE": "By Recommendations Type",
  "indicator.TOTAL_RECOMMENDATIONS": "Total Recommendations",
  "title.LIST_OF_RECOMMENDATIONS": "List of recommendations",
  "title.FAULTS": "Faults",
  "title.MAINTENANCE": "Maintenance",
  "title.OPERATIONS": "Operations",
  "title.SPACE_MANAGEMENT": "Space Management",
  "label.TOTAL_CONSUMPTION": "Total Consumption",
  "label.TARGET_CONSUMPTION": "Target Consumption",
  "label.CONSUMPTION_CONTRIBUTION": "Consumption Contribution",
  "label.CONSUMPTION": "Consumption",
  "title.PERFORMANCE_GAP": "Performance Gap",
  "title.PERCENTILE": "Percentile",
  "title.BY_AREA": "By Area",
  "title.BY_SYSTEM": "By System",
  "title.FOCUSED_AREA": "Focused Area",
  "label.AVERAGE_GAP": "Average Gap",
  "message.NO_MODEL_AVAILABLE": "No model available",
  "status.MODERATE": "moderate",
  "label.ALARMS_DURATION": "Alarms Duration",
  "title.TOTAL_ALARMS": "Total Alarms",
  "label.MAX": "Max",
  "label.MIN": "Min",
  "label.AVG": "Avg",
  "title.CURRENT_TEMPERATURE": "Current Temperature",
  "title.CURRENT_HUMIDITY": "Current humidity",
  "action.HEATMAP": "Heatmap",
  "label.CURRENT_TEMPERATURE": "Current Temperature",
  "label.CURRENT_HUMIDITY": "Current Humidity",
  "label.AHU_FAN_SPEED": "AHU Fan Speed",
  "label.CURRENT_TEMP": "Current Temp.",
  "label.PIM_STATUS": "PIM Status",
  "label.PIM_OPENING": "PIM Opening",
  "label.CURRENT_OCCUPANCY": "Current Occupancy",
  "label.ALARMS_THIS_WEEK": "Alarms This Week",
  "title.BREAKDOWN_BY_WEEK": "Weekly Breakdown",
  "label.MIN_TEMPERATURE": "Minimum Temperature",
  "label.MAX_TEMPERATURE": "Maximum Temperature",
  "label.MIN_RECORDED_TEMPERATURE": "Minimum Recorded Temperature",
  "label.MAX_RECORDED_TEMPERATURE": "Maximum Recorded Temperature",
  "status.GOOD": "Good",
  "action.SHOW_GRAPH": "Show Graph",
  "action.HIDE_GRAPH": "Hide Graph",
  "title.WEEKLY_BREAKDOWN": "Weekly Breakdown",
  "label.TOTAL_ALARMS": "Total Alarms",
  "label.TOTAL_ALARMS_DURATION": "Total Alarms Duration",
  "action.ALARMS_DETAILS": "Alarms Details",
  "label.AVERAGE_ALARMS_DURATION": "Average Alarm Duration",
  "label.TEMPERATURE": "Temperature",
  "label.HUMIDITY": "Relative Humidity",
  "label.CO2_LEVEL": "CO2 Level",
  "label.VOC_LEVEL": "VOC Level",
  "label.LUX": "LUX",
  "label.CLOUDCOVER": "Cloud Cover",
  "title.ZONE_X_DETAILS": "${zone.display} Details",
  "title.COMPARE": "Compare",
  "title.RELATED_ASSETS": "Related Assets",
  "title.TEMPERATURE_SEGMENTATION": "Temperature Segmentation",
  "label.DAMPER_POSITION": "Damper Position",
  "label.DAMP_POS": "Damp. Pos.",
  "label.VIEW_ASSET": "View Asset",
  "label.AIRFLOW_RATE": "Airflow rate",
  "label.AIR_TEMP": "Air Temperature",
  "message.TEMP_RANGE": "Temp. Range",
  "message.BETWEEN_X_AND_Y": "Between ${x} and ${y}",
  "message.FROM_X_TO_Y": "From ${x} To ${y}",
  "message.TOTAL_HOURS_IN_RANGE": "Total number of hours within range",
  "note.WHILE_OCCUPIED": "While Occupied",
  "note.WHILE_UNOCCUPIED": "While Unccupied",
  "value.ON": "On",
  "value.OFF": "Off",
  "note.LOCKED_TO_ENTITY": 'View locked to "${entity}"',
  "menu.OPERATIONAL_SPENDING": "Operational Spending",
  "menu.TEMPERATURE_MANAGEMENT": "Temperature Management",
  "menu.PERFORMANCE_METRICS": "Performance Metrics",
  "title.TEMPERATURE": "Temperature",
  "title.RELATIVE_HUMIDITY": "Relative Humidity",
  "title.CO2": "CO2",
  "title.VOC": "VOC",
  "title.PERFORMANCE_METRICS": "Performance Metrics",
  "message.COMING_SOON": "Coming Soon",
  "title.FILTER": "Filter",
  "title.TECHNICAL_INFO": "Technical Info",
  "title.LIVE_DATA": "Live Data",
  "action.GRAPH": "Graph",
  "action.GANTT": "Gantt",
  "action.VIEW_DETAILS": "View details",
  "action.GO_TO_USERCOMFORT": "Go to User Comfort",
  "action.HIDE_DETAILS": "Hide details",
  "status.X_ACTIVE_ALARMS": "Active Alarms: ${x}",
  "warm.NO_COMPONENT_AVAILABLE_FOR_TYPE":
    'Not view defined for entity of type "${type}"',
  "title.FLOOR_DETAILS": "Floor Details",
  "label.FLOOR": "Floor",
  "label.BASEMENT": "Basement",
  "label.ROOF": "Roof",
  "label.AVERAGE_SHORT": "Avg.",
  "status.LOW": "Low",
  "status.MEDIUM": "Medium",
  "status.HIGH": "High",
  "status.ACCEPTABLE": "Acceptable",
  "action.TEMPERATURE_SEGMENTAION": "Temp. Segmentaion",
  "action.TEMP_SENSOR_READINGS": "Temp. Sensor Readings",
  "action.ALARMS_ON_GRAPH": "Alarms on Graph",
  "toast.NO_ALARMS_TO_SHOW": "No alarms to show",
  "toast.NO_ALARMS_IN_SELECTED_RANGE": "No alarms in selected range",
  "title.SPENDING_AND_MISSED_SAVING_RIGHT_PANEL": "Spending & Missed Savings",
  "title.TOTAL_SPENDING_AND_MISSED_SAVING": "Total Spending & Missed Savings",
  "label.TOTAL_MISSED_SAVINGS": "Total Missed Savings",
  "action.VIEW_ALL": "View all",
  "label.MISSED_SAVINGS": "Missed Savings",
  "label.SPENDING": "Spending",
  "title.SPENDING_SUMMARY": "Spending Summary",
  "label.RECOMMENDATIONS": "Recommendations",
  "label.PREVIOUS_WEEK": "Previous Week",
  "message.X_PERCENT_OF_SPENDING": "${x} of spending",
  "message.OF_SPENDING": "of Spending",
  "label.AVG_NUM_OF_TENANTS": "Avg. # of Tenants",
  "label.MAX_CAPACITY": "Max Capacity",
  "label.OCCUPIED_DURATION": "Occupied Duration",
  "label.ENERGY_RECOMMENDATIONS": "Energy Recommendations",
  "label.USER_CONFORT": "User Comfort",
  "label.ESSENTIAL_HVAC_SYSTEM_SPENDING": "Essential HVAC System Spending",
  "message.NO_ESSENTIAL_HVAC_SYSTEM": "No systems available",
  "legend.JOINT_OCCUPANCY": "Joint occupancy",
  "title.HVAC_OCCUPIED_DURATION": "HVAC occupied duration",
  "label.ACT_COMFORT_ALARMS": "Act. Comfort Alarms",
  "label.COMFORT_ALARMS": "Comfort Alarms",
  "title.RELATED_PIMS": "Related PIMs",
  "title.TOTAL": "Total",
  "title.SPENDING": "Spending",
  "title.MISSED_SAVINGS": "Missed Savings",
  "label.TOTAL_ELECTRICITY": "Total Electricity",
  "label.TOTAL_CHILLED_WATER": "Total Chilled Water",
  "action.ADD_SUPPORTING_GRAPH": "Add supporting graph",
  "label.SUPPORTING_GRAPHS": "Supporting Graphs",
  "label.SUBZONE_GRAPHS": "Subzone Graphs",
  "legend.SPENDING": "Spending",
  "toast.COMING_SOON": "Coming soon...",
  "title.OUTSIDE_AIR_TEMPERATURE": "Outside air temperature",
  "title.ENERGY_UTILIZATION_INDEX": "Energy utilization index",
  "option.OCCUPIED_ZONES": "Occupied Zones",
  "legend.X_OCCUPIED_ZONES": "${x} Occupied zones",
  "title.SELECTED_DAYS_DETAILS": "Selected day(s) details",
  "title.WEEK_DETAILS": "Week details",
  "label.WEEK_END": "Week end",
  "label.WEEK_DAY": "Week day",
  "label.AVG_DAILY_SPENDING_MS": "Avg. daily Spending /MS",
  "legend.TOTAL_OCCUPIED_DURATION": "Total occupied duration",
  "label.FIRST_ARRIVAL_TIME": "First arrival time",
  "label.LAST_DEPARTURE_TIME": "Last departure time",
  "label.WORKING_DAYS_AVERAGE_ARRIVAL_TIME":
    "Working days average arrival time",
  "label.WORKING_DAYS_AVERAGE_DEPARTURE_TIME":
    "Working days average departure time",
  "legend.ZONE": "Zone",
  "legend.ZONES": "Zones",
  "legend.MISSED_SAVING": "Missed Saving",
  "legend.MAX_TEMPERATURE": "Lowest Temperature",
  "legend.AVERAGE_TEMPERATURE": "Average Temperature",
  "legend.MIN_TEMPERATURE": "Highest Temperature",
  "legend.LOWEST_OUTSIDE_AIR_TEMPERATURE": "Lowest outside air temperature",
  "legend.AVERAGE_OUTSIDE_AIR_TEMPERATURE": "Average outside air temperature",
  "legend.HIGHEST_OUTSIDE_AIR_TEMPERATURE": "Highest outside air temperature",
  "option.PER_PERSON": "Per person",
  "option.PER_SQUARE_METER": "Per square meter",
  "unit.PERSON": "person",
  "legend.TOTAL_CURRENCY_PER_SQUARE_METER_DURING_OCCUPIED_HOUR":
    "Total ${currency.display} per square meter during occupied hours",
  "legend.FLOOR_AREA_IN_SQUARE_METERS": "Floor area in square meters",
  "legend.TOTAL_SPENDING_WHILE_OCCUPIED": "Total spending while occupied",
  "legend.AVERAGE_OCCUPANCY_WHILE_OCCUPIED": "Average occupancy while occupied",
  "legend.TOTAL_CURRENCY_PER_PERSON_DURING_OCCUPIED_HOUR":
    "Total ${currency.display} per person during occupied hours",
  "title.OCCUPANCY_INSIGHTS": "Occupancy insights",
  "title.TIME_INSIGHTS": "Time insights",
  "legend.TOTAL_ELECTRICITY_SPENDING": "Total Electricity Spending",
  "legend.TOTAL_MISSED_SAVINGS": "Total Missed Savings",
  "legend.TOTAL_ELECTRICITY_MISSED_SAVINGS": "Total Electricity Missed Savings",
  "legend.WORKING_DAYS": "Working Days",
  "legend.NON_WORKING_DAYS": "Non-working Days",
  "option.ENTIRE_DURATION": "Entire Duration",
  "option.WORKING_DAYS": "Working Days",
  "option.NON_WORKING_DAYS": "Non-working Days",
  "option.ONE_HOUR_DURATION": "One hour duration",
  "option.THREE_HOUR_DURATION": "Three hour duration",
  "title.BREAKDOWN_BY_ELECTRICITY_COMPONENT":
    "Breakdown by electricity component",
  "legend.MECHANICAL_ELECTRICITY": "HVAC",
  "legend.LIGHTING_ELECTRICITY": "Lighting",
  "legend.OTHER_LOAD_ELECTRICITY": "Other Loads",
  "title.ELECTRICITY_TIME_FILTER": "Electricity time filter",
  "legend.PERFORMANCE_GAP": "Performance Gap",
  "legend.ABOVE_TARGET": "Above Target",
  "legend.BELOW_TARGET": "Below Target",
  "message.X_OF_WEEK_Y": "${x} of the week ${y}",
  "message.DISPLAYING_SELECTED_INTERVAL_AND_DURAION":
    "Displaying Selected Interval & Duration Total Electricity Spending For:",
  "message.CLICK_ON_INTERVAL_FROM_GRAPH": "Click on an interval from chart",
  "title.POWER_CONSUMPTION": "Power Consumption",
  "label.AVERAGE_TARGET_POWER": "Interval Power Target",
  "label.AVERAGE_POWER_TARGET": "Avg Target Power",
  "label.MIN_POWER": "Lowest",
  "label.MAX_POWER": "Highest",
  "label.AVERAGE_POWER": "Avg Power",
  "label.MAX_OCCUPIED_ZONES": "Max Occupied Zones",
  "label.FLOOR_CAPACITY": "Floor Capacity",
  "note.TOTAL_X_ZONES": "Total ${x} zones",
  "label.OCCUPANCY_DURATION": "Occupancy Duration",
  "label.AVG_OCCUPANCY": "Avg Occupancy",
  "label.AVG_OCC_DENSITY": "Avg. Occ. Density",
  "label.MAX_OCCUPANCY": "Max Occupancy",
  "label.PEAK_OCC": "Peak Occ.",
  "word.PERSON": "Person",
  "word.ZONE": "Zone",
  "word.ZONES": "Zones",
  "title.COMPONENTS_DETAILS": "Component Details",
  "header.TOTAL_ELEC_SPENDING": "Total Elec. Spending",
  "header.TOTAL_ELEC_MISSED_SAVINGS": "Total Elec. Missed Savings",
  "label.HVAC": "HVAC",
  "label.SUMMARY": "Summary",
  "label.FILTER": "Filter",
  "title.HVAC_DETAILS": "HVAC Details",
  "label.AVG_ACTIVATED_PIMS": "Avg Activated PIMs",
  "label.AVG_[ENTITY]_ACTIVATED_PIMS": "Avg ${entity} Activated PIMs",
  "note.TOTAL_X": "total ${x}",
  "note.MAX_X": "maximum ${x}",
  "header.SPENDING": "Spending",
  "header.MISSED_SAVINGS": "Missed Savings",
  "title.HVAC_POWER_CONSUMPTION": "HVAC Power Consumption",
  "note.X_OF_TOTAL_HRS": "${x} of total hrs",
  "label.BOTH_AHUS": "Both AHUs",
  "label.ALL_AHUS": "All AHUs",
  "title.INDOOR_TEMPERATURE_DISTRIBUTION": "Indoor Temperature Distribution",
  "label.AVG_TEMPERATURE": "Avg Temperature",
  "label.AVG_TEMP": "Avg. Temp",
  "label.AVG_REL_HUMIDITY": "Avg. Rel. Humidity",
  "label.AVG_CO2": "Avg. CO2",
  "message.X_OF_WEEK": "${x} of the week",
  "word.WEEK": "Week",
  "title.LIGHTING_DETAILS": "Lighting Details",
  "label.LIGHTING": "Lighting",
  "title.LIGHTING_POWER_CONSUMPTION": "Lighting Power Consumption",
  "title.LUX_LEVEL": "Lux Level",
  "label.AVG_LUX_LEVEL": "Avg. Lux Level",
  "label.AVG_OCCUPIED_LIGHTING_ZONES": "Average Occupied Lighting Zones",
  "title.OTHER_LOADS_DETAILS": "Other Loads Details",
  "label.OTHER_LOADS": "Other Loads",
  "title.OTHER_LOADS_POWER_CONSUMPTION": "Other Loads Power Consumption",
  "title.FLOOR_X": "${x} Floor",
  "title.X_SUPPORTING_GRAPH": "Supporting Graph: ${x}",
  "title.SUBZONE_GRAPH_X": "Subzone Graph: ${x}",
  "unit.KWH_KWHT": "kWh/kWt",
  "title.OPERATIONAL_PERFORMANCE": "Operational Performance",
  "title.TOTAL_HOURS": "Total hours",
  "label.AVG_OCCUPIED_ZONES": "Avg occupied zones",
  "label.AVG_OCCUPIED_FLOORS": "Avg Occupied Floors",
  "label.OCCUPIED": "Occupied",
  "label.UNOCCUPIED": "Unoccupied",
  "title.OCCUPIED_ZONES": "Occupied Zones",
  "title.OCCUPANCY_PERCENT": "Occupancy %",
  "label.AVG_POWER_PERSON": "Avg Power/Person",
  "legend.CLICK_GRAPH": "[ Click on graph to view details ]",
  "label.OVERALL_CAPACITY": "Overall Capacity",
  "label.ACTUAL_SAVING": "Actual Saving",
  "label.OF_WEEK": "of week",
  "label.OF_TOTAL_SPENDING": "of total spending",
  "label.AVG_SPENDING_PER_HOUR": "Avg Spending/hr",
  "label.OF_TOTAL_MISSED_SAVINGS": "of Total Missed Savings",
  "label.OF_TOTAL_ACTUAL_SAVING": "of Total Actual Saving",
  "label.AVG_POWER_TARGET": "Avg Power Target",
  "message.CONFIRM_ADD_GRAPH_TO_PRIMARY":
    "Adding graph to primary axis will remove all other graphs on the primary axis as the units do not match. Are you sure you want to proceed?",
  "message.CONFIRM_ADD_GRAPH_TO_SECONDARY":
    "Adding graph to secondary axis will remove all other graphs on the secondary axis as the units do not match. Are you sure you want to proceed?",
  "message.CONFIRM_SWAP_GRAPH_TO_SECONDARY":
    "Graph will be added to secondary axis and that will remove all other graphs on the secondary axis as the units do not match. Are you sure you want to proceed?",
  "legend.TOTAL_ELECTRICITY": "Total Electricity",
  "legend.TOTAL_ELEC_TARGET": "Total Elec. Target",
  "legend.OCCUPANCY": "Occupancy",
  "label.OCCUPANCY": "Occupancy",
  "label.INDOORCONDITIONS": "Indoor Conditions",
  "label.OUTDOORCONDITIONS": "Outdoor Conditions",
  "label.INDOORCONDITION": "Indoor Condition",
  "title.CONDITIONS": "Conditions",
  "title.TRENDS": "Trends",
  "legend.PRIMARY_AXIS": "Primary Axis",
  "legend.SECONDARY_AXIS": "Secondary Axis",
  "legend.HVAC": "HVAC",
  "legend.HVAC_TARGET": "HVAC Target",
  "legend.LIGHTING": "Lighting",
  "legend.LIGHTING_TARGET": "Lighting Target",
  "legend.SUPPLY_FAN_MOTOR_ELECTRIC_POWER": "Supply Fan Motor Electric Power",
  "legend.TEMPERATURE": "Teperature",
  "legend.SERVED_SPACE_OCCUPANCY": "Served Space Occupancy",
  "legend.STATUS": "Status",
  "label.STATUS": "Status",
  "title.RUNNING_TIME_INSIGHTS": "Running time insights",
  "title.OTHER_LOADS_INSIGHTS": "Other Loads Insights",
  "title.RUNNING_TIME_FILTER": "Running time filter",
  "title.CONSUMPTION_DETAILS": "Consumption Details",
  "title.CONSUMPTION_SUMMARY": "Consumption Summary",
  "label.ALL_ZONES": "All Zones",
  "label.UPS_LOAD": "UPS Load",
  "label.GENERAL_LOAD": "General Load",
  "legend.ENTITY_CONSUMPTION": "${entity} consumption",
  "status.BAD": "Bad",
  "legend.INOORTEMP": "IndoorTemp",
  "legend.OUTSIDE_AIR_TEMPERATURE": "Outside Air Temperature",
  "title.FLOOR_FOR_ENTITY": "${entity} Floor",
  "title.TOTAL_FOR_FLOOR_ENTITY": "Total | ${entity} Floor",
  "title.HVAC_FOR_FLOOR_ENTITY": "HVAC | ${entity} Floor",
  "title.AHU_FOR_ENTITY": "AHU | ${entity}",
  "legend.PIM_ENTITIY_VALVE_POSITION": "${entity} valve position",
  "legend.MISSED_SAVINGS": "Missed Savings",
  "legend.LUX_LEVEL": "Lux Level",
  "legend.POWER": "Power",
  "legend.POWER_TARGET": "Power Target",
  "title.OTHER_LOADS_FOR_ENTITY": "Other Loads | ${entity} Floor",
  "title.LIGHTING_FOR_ENTITY": "Lighting | ${entity} Floor",
  "label.WHEN_TELEMETRY_BETWEEN_FROM_TO":
    'When "${telemetry}" between ${from} and ${to}',
  "label.OUT_OF": "Out of",
  "option.NO_SEGMENTATION": "no segmentation",
  "label.WHEN_TELEMETRY_EQUALS_VALUE": "When ${telemetry} equals ${value}",
  "option.BUCKET_COUNT_BINS": "${bucketsCount} Bins",
  "label.WHEN_TELEMETRY_IS_X": 'When "${telemetry}" is "${x}"',
  "option.BY_STATUS": "By Status",
  "status.UNKNOWN": "unknown",
  "status.ACTIVE": "active",
  "status.INACTIVE": "inactive",
  "title.SELECT_RANGE_IN_KW": "Select range in kW",
  "title.ELECTRICITY_POWER_INTENSITY": "Electricity Power Intensity",
  "title.TOTAL_POWER_INTENSITY": "Total Power Intensity",
  "label.AVG_POWER": "Avg Power",
  "title.LIGHTING_RUNNING_TIME": "Lighting Running Time",
  "label.AVG_OCCUPIED_PARENT_ZONES": "Avg Occupied Parent Zones",
  "title.OTHER_LOADS_RUNNING_TIME": "Other Loads Running Time",
  "legend.PERSON_UNIT": "p",
  "title.HVAC_INSIGHTS": "HVAC Insights",
  "title.OCCUPANCY_PERCENTAGE": "Occupancy %",
  "label.OF_TOTAL_HOURS": "of Total Hours",
  "label.OF_TOTAL_HVAC_SPENDING": "of Total HVAC Spending",
  "label.TARGET_POWER": "Target Power",
  "label.TOTAL_RUNNING_TIME": "Total Running Time",
  "label.TOTAL_RUNNING_FCUS": "Total Running FCUs",
  "label.AVG_RUNNING_FCUS": "Avg. Running FCUs",
  "label.TOTAL_RUNNING_HRAHU": "Total Running HRAHU",
  "label.AVG_RUNNING_HRAHU": "Avg. Running HRAHU",
  "label.TOTAL_RUNNING_AHU": "Total Running AHU",
  "label.AVG_RUNNING_AHU": "Avg. Running AHU",
  "label.TOTAL_RUNNING_AHU_COOLING_MODE": "Total Running AHU Cooling Mode",
  "label.TOTAL_RUNNING_AHU_FAN_MODE": "Total Running AHU Fan Mode",
  "label.FAN": "Fan",
  "label.COOLING": "Cooling",
  "label.COOL": "Cool",
  "label.TOTAL_CW_PUMPS": "Total CW Pumps",
  "label.AVG_CW_PUMPS": "Avg. CW Pumps",
  "label.COOLED_BUA": "Cooled BUA",
  "label.AVG_COOLED_BUA": "Avg. Cooled BUA",
  "label.TOTAL_HVAC_RUNNING": "Total HVAC Running",
  "label.RUNNING_TIME_ENTITY": "${entity} Running Time",
  "label.MECHANICAL_ELECTRICITY": "HVAC Electricity",
  "title.CURATED_GRAPH": "Curated Graph",
  "title.ADVANCED_GRAPH": "Advanced Graph",
  "label.LIGHTING_ELECTRICITY": "Lighting Electricity",
  "label.OTHER_LOADS_ELECTRICITY": "Other Loads",
  "label.TOTAL_ELECTRICITY_MISSEDSAVINGS": "Electricity Missed Savings",
  "label.MECHANICAL_MISSEDSAVINGS": "HVAC Missed Savings",
  "label.LIGHTING_MISSEDSAVINGS": "Lighting Missed Savings",
  "label.OTHER_LOADS_MISSEDSAVINGS": "Other Missed Savings",
  "label.CHILLED_WATER_MISSEDSAVINGS": "Chilled Water Missed Savings",
  "title.OTHER_LOADS": "Other Loads",
  "legend.OTHER_LOADS": "Other Loads",
  "legend.OTHER_LOADS_TARGET": "Other Loads Target",
  "legend.UPS_ELECTICITY": "UPS Electricity",
  "legend.UPS_TARGET": "UPS Target",
  "option.FILTER": "Filter",
  "option.TIME": "Time",
  "option.OCCUPANCY": "Occupancy",
  "option.RUNNING_TIME": "Running Time",
  "label.AVG_OCCUPIED_LC_ZONES": "Avg Occupied LC Zones",
  "label.OCCUPIED_LC_ZONES": "Occupied LC Zones",
  "label.OCC_LC_ZONES": "Occ. LC Zones",
  "label.CONSUMPTION_FOR_ENTITY": "Consupmtion for ${entity}",
  "legend.ESSENTIAL_HVAC_CONSUMPTION": "Essential HVAC Consupmtion",
  "label.MISSEDSAVINGS_FOR_ENTITY": "Missed Savings for ${entity}",
  "title.UPS_LOADS": "UPS Loads",
  "title.GENERAL_LOADS": "General Loads",
  "title.SPENDING_DETAILS": "Spending Details",
  "label.COMPONENT": "Component",
  "label.OF_FLOOR_CAPACITY": "of floor capacity",
  "title.HVAC_RUNNING_TIME": "HVAC Running Time",
  "label.ESSENTIAL_HVAC_SYS": "Essential HVAC Sys",
  "label.TOTAL_AHU": "Total AHU",
  "label.TOTAL_AHU_ENTIRE_DURATION": "Total AHU Running Time (Entire Duration)",
  "label.X_OF_AHU_RUNNING_TIME": "(${x} of total AHU running time)",
  "label.TOTAL_HVAC_SPENDING": "Total HVAC Spending",
  "title.UPS_LOADS_DETAILS": "UPS Loads",
  "title.GENERAL_LOADS_DETAILS": "General Loads",
  "title.APP_LOADS": "App Loads",
  "title.CURRENTLY_ACTIVE_RECOMMENDATIONS": "Currently Active Recommendations",
  "message.ACTIVE_[X]": "Active [${x}]",
  "title.SUMMARY": "Summary",
  "title.DATA": "Data",
  "title.FDD": "FDD",
  "title.INFORMATION": "Information",
  "label.MISSED_SAV": "Missed Sav.",
  "label.ACTUAL_SAV": "Actual Sav.",
  "message.NO_ACTIVE_RECOMMENDATIONS_AVAILABLE":
    "No active recommendations available",
  "title.TOP_X_IMPACTFUL_RECOMMENDATIONS": "Top ${x} Impactful Recommendations",
  "title.SELECT_OCC_RANGE": "Select Occ Range",
  "title.OCCUPANCY_TRENDS": "Occupancy Trends",
  "message.ACTIVE": "Active",
  "message.INACTIVE": "Inactive",
  "message.TRIGGERED": "Triggered",
  "message.NOT_TRIGGERED": "Not Triggered",
  "option.MISSED_SAVINGS": "Missed Savings",
  "option.MISSED_SAVINGS_PER_HOUR": "Missed Savings/hour",
  "option.DURATION": "Duration",
  "option.PRIORITY": "Priority",
  "option.NONE": "None",
  "option.SYSTEM": "System",
  "option.TYPE": "Type",
  "option.ENTITY": "Entity",
  "option.CHRONOLOGY": "Chronology",
  "option.OLDEST_FIRST": "Oldest first",
  "option.NEWEST_FIRST": "Newest first",
  "option.OCCURANCES_COUNT": "Occurances Count",
  "option.TAG_NAME": "Tag Name",
  "option.FLOOR": "Floor",
  "group.NA": "NA",
  "title.ALL_RECOMMENDATIONS": "All Recommendations",
  "action.BACK_TO_SUMMARY": "Back to Summary",
  "label.GROUPED_BY": "Grouped by",
  "label.SORTED_BY": "Sorted by",
  "label.FILTERED_BY": "Filtered by",
  "label.FILTER_BY": "Filter by",
  "title.UNKNOWN_TYPE_[x]": "Unknown (${x})",
  "title.UNKNOWN_SYSTEM_[x]": "Unknown (${x})",
  "label.AFFECTED_AREA": "Affected Area",
  "tooltip.STILL_ACTIVE": "Still Active",
  "label.START_TIME": "Start Time",
  "label.END_TIME": "End Time",
  "label.PRIORITY": "Priority",
  "label.ACTUAL_SAVINGS": "Actual Savings",
  "tooltip.TOTAL_DURATION": "Total duration of the recommendation",
  "tooltip.SELECTED_DURATION":
    "The duration of the recommendation that intersects the current selected date range",
  "tooltip.LEAKAGE_AT_END_OF_LIFE": "10% leakage at end-of-life.",
  "label.TOTAL_DURATION": "Total Duration",
  "label.SELECT_DURATION": "Select Duration",
  "label.OF_TOTAL_HRS_PER_WEEK": "of Total hrs per week",
  "label.AVG_NUM_OF_OCCUPIED_ZONES": "Avg # of Occupied Zones",
  "title.ENERGY_MANAGEMENT_RECOMMENDATIONS":
    "Energy Management Recommendations",
  "toggle.ACTIVE": "Active",
  "legend.DETECTED": "Detected",
  "legend.INITIATED": "Initiated",
  "legend.ACTIONED": "Actioned",
  "legend.ENDED": "Ended",
  "legend.CONTINUED": "Continued",
  "message.NO_RECOMMENDATIONS": "No recommendations to display",
  "label.PERCENT_OF_TOTAL": "% of total",
  "label.NUMBER_OF_RECOMMENDATIONS": "Number of recommendations",
  "label.TOTAL_NUMBER_OF_RECOMMENDATIONS": "Total Number of Recommendations",
  "label.NUMBER_OF_ACTIVE_RECOMMENDATIONS": "Number of Active Recommendations",
  "label.AVG_DURATION": "Avg. Duration",
  "label.AVG_MISSED_SAVINGS": "Avg. Missed Savings",
  "title.SELECTED_RECOMMENDATIONS": "Selected Recommendation(s)",
  "label.DETECTED": "Detected",
  "label.INITIATED": "Initiated",
  "label.ENDED": "Ended",
  "title.HISTORY": "History",
  "label.ACTIONED": "Actioned",
  "label.RECOMMENDATION_DETAILS": "Recommendation Details",
  "option.FUNCTIONAL_AREA": "Functional Area",
  "label.OTHER_NA": "Other/NA",
  "label.TOTAL_AHU_RUNNING_TIME": "Total AHU RunningTime",
  "label.X_OF_TOTAL_ELECTRICITY_MISSED_SAVINGS":
    "(${x} of total Electricity missed saving)",
  "label.X_OF_TOTAL_HVAC_RUNNING_TIME": "(${x} of total HVAC running time)",
  "label.X_OF_TOTAL_HVAC_SPENDING": "(${x} of total HVAC spending)",
  "label.X_OF_TOTAL_ELECTRICITY_ACTUAL_SAVINGS":
    "(${x} of total Electricity actual saving)",
  "option.ACTIVE_STATUS": "Active Status",
  "filter.ALL": "All",
  "filter.ACTIVE": "Active",
  "label.TOTAL_HVAC_RUNNING_TIME": "Total HVAC Running Time",
  "title.RUNNING_TIME_AND_POWER": "RunningTime and Power",
  "label.WEEKLY_AVG": "Weekly Avg",
  "label.TARGET_AVG": "Target Avg",
  "option.SUCCESS_RATE": "Success Rate",
  "label.OCCUPIED_HVAC_ZONES": "Occupied HVAC Zones",
  "label.AVG_OCCUPIED_HVAC_ZONES": "Avg Occ HVAC Zones",
  "title.TODAY": "Today",
  "title.HISTORY_VIEW": "HISTORY VIEW",
  "option.INSTANTANEOUS": "Instantaneous",
  "option.LIVE": "LIVE",
  "option.CUMULATIVE": "Cumulative",
  "title.LIVE_CONSUMPTION": "Live Consumption",
  "title.PERFORMANCE_KPI": "Performance KPI",
  "title.ACTIVE_RECOM": "Active Recom.",
  "title.TOTAL_RECOM": "Total Recom.",
  "title.HVAC_RECOM": "HVAC Recom.",
  "message.INSTANT_SELECTED_INTERVAL_TODAY_RICH": ({ date, time }) => (
    <>
      <strong>Today</strong> {date} at <strong>{time}</strong>
    </>
  ),
  "message.INSTANT_SELECTED_INTERVAL_TODAY": "Today ${date} at ${time}",
  "message.INSTANT_SELECTED_INTERVAL_RICH": ({ date, time }) => (
    <>
      <strong>{date}</strong> at <strong>{time}</strong>
    </>
  ),
  "message.INSTANT_SELECTED_INTERVAL": "${date} at ${time}",
  "message.CUMULATIVE_SELECTED_INTERVAL_TODAY_RICH": ({ date }) => (
    <>
      <strong>Today</strong> {date}
    </>
  ),
  "message.CUMULATIVE_SELECTED_INTERVAL_TODAY": "Today ${date}",
  "message.DISPLAYED_DATE_FOR": "Displaying data for",
  "label.ELECTRICITY_CONSUMPTION": "Electricity Consumption",
  "label.CHILLED_WATER_CONSUMPTION": "Chilled Water Consumption",
  "label.ELECTRICITY_MISSED_SAVINGS": "Electricity Missed Savings",
  "label.CHILLED_WATER_MISSED_SAVINGS": "Chilled Water Missed Savings",
  "tooltip.X_GROUPED_ANNOTATIONS_ZOOM_TO_REVEAL":
    "${x} annotations grouped. Zoom to reveal",
  "action.ANNOTATIONS": "Annotations",
  "action.MECHANICAL_ASSETS": "Mechanical Assets",
  "action.HIDE_MECHANICAL_ASSETS": "Hide Mechanical Assets",
  "action.SHOW_MECHANICAL_ASSETS": "Show Mechanical Assets",
  "label.TOTAL_CH_WATER": "Total Ch. Water",
  "label.TOTAL_HVAC": "Total HVAC",
  "label.HVAC_AREA_A": "HVAC Area A",
  "label.HVAC_AREA_B": "HVAC Area B",
  "label.ESSENTIAL_HVAC": "Essential HVAC",
  "label.ESSENTIAL_LOADS": "Essential Loads",
  "label.DEMAND": "Demand",
  "label.TARGET": "Target",
  "label.PERF_KPI": "Perf. KPI",
  "label.PERFORMANCE_GAP": "Performance Gap",
  "system.UNKNOWN": "Unknown",
  "legend.SUPPLY_AIR_TEMPERATURE": "Supply Air Temperature",
  "title.OPERATING_STATUS": "Operating Status",
  "title.DEMAND_LOAD_BREAKDOWN": "Demand Load Breakdown",
  "title.UTILITIES_BREAKDOWN": "Utilities Breakdown",
  "title.FLOORS_CONSUMPTION_BREAKDOWN": "Floors Consumption Breakdown",
  "title.FLOORS_BREAKDOWN": "Floors Breakdown",
  "label.ACTIVATED_HVAC_ZONES": "Activated HVAC Zones",
  "label.X_MISSED_SAVINGS": "${x} Missed Savings",
  "label.X_ACTUAL_SAVINGS": "${x} Actual Savings",
  "message.CLICK_ROW_FOR_DETAILS": "Click on a row for more details",
  "label.UPS_LOADS": "UPS Loads",
  "label.GENERAL_LOADS": "General Loads",
  "label.EFFICIENCY": "Efficiency",
  "title.ENTITY_DETAILS": "${entity} Details",
  "label.PERF_GAP": "Perf. Gap",
  "label.ACTIVE_RECOM": "Active Recom.",
  "label.TOTAL_LIGHTING": "Total Lighting",
  "label.LUX_LEVEL": "Lux Level",
  "title.[TREND]_IS_SELECTED": "${trend} is selected",
  "label.AHUS": "AHUs",
  "label.HRAHUS": "HRAHUs",
  "label.FCUS": "FCUs",
  "label.AHU": "AHU",
  "label.HRAHU": "HRAHU",
  "label.FCU": "FCU",
  "label.AHU_STATUS": "AHU Status",
  "label.ACT_HVAC_ZONES": "Act. HVAC Zones",
  "label.DEMAND_BREAKDOWN": "Demand Breakdown",
  "label.ACTIVE_RECOMMENDATIONS": "Active Recommendations",
  "label.MODE": "Mode",
  "label.ZONES_DETAILS": "Zones Details",
  "label.HVAC_ZONE": "HVAC Zone",
  "label.OCC": "Occ.",
  "label.TEMP": "Temp.",
  "label.ALARMS": "Alarms",
  "label.ALARM": "Alarm",
  "message.NO_PIM_DETECTED": "No PIM Detected",
  "label.DCHWV": "DCHWV",
  "label.MAIN_DCHWV": "Main DCHWV",
  "title.USER_COMFORT": "User Comfort",
  "title.USER_COMFORT_KPI": "User Comfort KPI",
  "label.UPS_LOADS_SPENDING": "UPS Loads Spending",
  "label.TOTAL_UPS_RECOMMENDATIONS": "Total UPS Recommendations",
  "title.CONSUMPTION_BREAKDOWN": "Consumption Breakdown",
  "title.SPENDING_BREAKDOWN": "Spending Breakdown",
  "label.TOTAL_HVAC_RECOMMENDATIONS": "Total HVAC Recommendations",
  "label.AVG_ACT_HVAC_ZONES": "Avg. Act. HVAC Zones",
  "label.TOTAL_ACT_HVAC_ZONES": "Total Act. HVAC Zones",
  "label.AVG_OCC_HVAC_ZONES": "Avg. Occ. HVAC Zones",
  "label.AVG_OCC_ZONES": "Avg. Occ. Zones",
  "label.AVG_OCC": "Avg. Occ.",
  "label.OCC_DUR": "Occ. Dur.",
  "label.TOTAL_COMFORT_ALARMS": "Total Comfort Alarms",
  "label.CM_OPERATIONAL_DURATION": "CM Operational Duration",
  "title.HVAC_ZONES_DETAILS": "HVAC Zones Details",
  "title.FCU_SPENDING_DETAILS": "FCU Spending Details",
  "title.FCU_CONSUMPTION_DETAILS": "FCU Consumption Details",
  "title.FCU_DEMAND_DETAILS": "FCU Demand Details",
  "label.AVG_ACTIVATED_LC_ZONES": "Avg. Activated LC Zones",
  "label.OPERATIONAL_DURATION": "Operational Duration",
  "label.TOTAL_OP_HOURS": "Total Op. Hours",
  "label.OP_HRS_IN_COOLING": "Op. Hrs in Cooling",
  "label.TOTAL_OTHER_LOADS": "Total Other Loads",
  "label.GENERAL_LOADS_SPENDING": "General Loads Spending",
  "label.GENERAL_LOADS_CONSUMPTION": "General Loads Consumption",
  "label.UPS_LOADS_CONSUMPTION": "UPS Loads Consumption",
  "action.COST_SAVINGS_WINDOWS": "Cost Savings Windows",
  "action.OVERALL_SPENDING": "Overall Spending",
  "action.OVERALL_CONSUMPTION": "Overall Consumption",
  "action.CLOSE": "Close",
  "title.COMPARISON": "Comparison",
  "title.HIGHEST_HOUR_INDICATION": "The largest ${parameter} was from ${hour}",
  "title.WORST_HOUR_INDICATION": "The worst ${parameter} was from ${hour}",
  "label.OVERALL_SPENDING": "Overall Spending",
  "label.OVERALL_CONSUMPTION": "Overall Consumption",
  "label.WINDOW_FOR_COST_SAVINGS": "Window for Cost Savings",
  "title.OCCUPANCY_INSIGHTS_TEXT": "${initial} was while ${middle} ${end}",
  "label.WERE_OCCUPIED": "were occupied",
  "label.HVAC_ZONES": "HVAC Zones",
  "label.WERE_PRESENT": "were present",
  "label.COSTLIEST_DURATION": "Costliest duration",
  "label.COSTLIEST_DAY": "Costliest day",
  "label.COSTLIEST_HOUR": "Costliest hour",
  "label.LARGEST_WINDOW_COST_SAVINGS": "Largest window for cost savings",
  "option.OCCUPANTS": "Occupants",
  "label.MOST_INEFFICIENT_PERFORMANCE": "Most inefficient performance",
  "title.POWER_INTENSITY_INSIGHTS": "Power Intensity Insights",
  "title.POWER_INTENSITY_INSIGHTS_TEXT":
    "Power demand of ${initial} was correlated with the ${middle} ${end}",
  "label.HIGHEST_AMOUNT_MISSED_SAVINGS": "highest amount of missed savings",
  "label.LARGEST_SPENDING": "the largest spending",
  "legend.NUMBER_OF_OCCURANCES": "Number of Occurances",
  "legend.ACTUAL_SAVINGS": "Actual Savings",
  "legend.START_TIME": "Start Time",
  "legend.END_TIME": "End Time",
  "legend.DURATION": "Duration",
  "legend.VALVE_POSITION": "Valve Position",
  "legend.MODE": "Mode",
  "legend.ACTION": "Action",
  "title.INDEX_OF_CORRELATION": "Index of Correlation",
  "label.EUI": "EUI",
  "label.TARGET_SPENDING": "Target Spending",
  "option.ALL_DAYS": "All Days",
  "label.OCCUPANTS": "Occupants",
  "unit.KPI": "KPI",
  "warn.CANNOT_PICK_LESS_THAN_X_DAYS": "Cannot pick less than ${dayCount} days",
  "label.ZONE": "Zone",
  "label.AREA": "Area",
  "title.TOTAL_FLOORS": "Total Floors",
  "title.COMMON_UTILITIES": "Common Utilities",
  "label.COMMON_UTILITIES": "Common Utilities",
  "label.ELEVATORS": "Elevators",
  "label.PUMPS": "Pumps",
  "label.WATER_FEATURE": "Water Feature",
  "label.OUTDOOR_LIGHTING": "Outdoor Lighting",
  "label.PERFORMANCE_KPI": "Performance KPI",
  "action.NEXT": "Next",
  "action.PREVIOUS": "Previous",
  "label.ELEC": "ELEC",
  "label.CHW": "CHW",
  "label.TOTAL_CW_PUMPS_DEM": "Total CW Pumps Dem.",
  "label.AVG_CW_PUMPS_DEM": "Avg. CW Pumps Dem.",
  "label.PV": "PV Generation",
  "label.ONLINE_INVERTERS": "Online Inverters",
  "label.PERFORMANCE_RATIO": "Performance Ratio",
  "label.PV_TARGET": "PV Target",
  "label.IRRADIATION": "Irradiation",
  "label.FAULTS": "Faults",
  "title.RENEWABLE_ENERGY": "Renewable Energy",
  "label.INVERTERS": "Inverters",
  "label.PRODUCTION": "Production",
  "label.MAX_HVAC_OCCUPANCY": "Max HVAC zone occupancy",
  "label.REL_HUM": "Rel. Hum.",
  "label.CLOUD": "Cloud",
  "label.OCCUPIED_FLOORS": "Occupied Floors",
  "label.OCCUPIED_BUA": "Occupied BUA",
  "label.DENSITY": "Density",
  "label.TOTAL_YIELD": "Total Yield",
  "label.CO2_AVOIDED": (
    <>
      CO<sub>2</sub> Avoided
    </>
  ),
  "label.REDUCE_DEFORESTATION": "Red. Deforestation",
  "label.REIMBURSEMENT": "Reimbursement",
  "label.OPER_STAT": "Oper. Stat.",
  "title.DEMAND_SUMMARY": "Demand Summary",
  "label.LOADING_FACTOR": "Loading Factor",
  "label.WORK_HRS": "Work hrs",
  "label.AFTER_HRS": "After hrs",
  "label.NOT_OCC": "Not Occ.",
  "label.UT_SCORE": "Ut. Score",
  "title.DEMAND_DETAILS": "Demand Details",
  "label.NET_CONSUMPTION": "Net Consumption",
  "label.NET_SPENDING": "Net Spending",
  "label.SPENDING_PER_PERSON": "Spending / Person",
  "label.CONSUMPTION_PER_PERSON": "Consumption / Person",
  "label.SPENDING_PER_M2": "Spending / m2",
  "label.CONSUMPTION_PER_M2": "Consumption / m2",
  "label.ACTIVE_COMFORT_ALARMS": "Active Comfort Alarms",
  "title.INDOOR_CONDITIONS": "Indoor Conditions",
  "title.ALARMS_BREAKDOWN": "Alarms Breakdown",
  "label.REL_HUMIDITY": "Rel. Humidity",
  "label.CO2": "CO2",
  "label.VOC": "VOC",
  "option.ACTIVE": "Active",
  "option.TOTAL": "Total",
  "option.BAR_CHART": "Bar chart",
  "option.LINE_CHART": "Line chart",
  "label.OCCUPANCY_STATUS": "Occupancy Status",
  "message.SUB_GRAPH_SWAP":
    "You can only view two secondary graphs at the same time. Do you want to replace",
  "message.SUB_GRAPH_SWAP_CUSTOM":
    "You can only view three graphs at the same time. Do you want to replace",
  "label.TOTAL_HVAC_POWER": "Total HVAC Power",
  "label.TOTAL_HVAC_POWER_TARGET": "Total HVAC Power Target",
  "label.ACTIVATED_PIMS": "Activated PIMs",
  "label.TOTAL_ELECTRICITY_POWER": "Total Electricity Power",
  "label.TOTAL_ELECTRICITY_POWER_TARGET": "Total Electricity Power Target",
  "label.OTHER_LOADS_POWER": "Other Loads Power",
  "label.OTHER_LOADS_POWER_TARGET": "Other Loads Power Target",
  "label.GENERAL_LOAD_POWER": "General Load Power",
  "label.UPS_LOAD_POWER": "UPS Load Power",
  "label.UPS_LOAD_POWER_TARGET": "UPS Load Power Target",
  "label.OCCUPANT_DENSITY": "Occupant Density",
  "label.TOTAL_LIGHTING_POWER": "Total Lighting Power",
  "label.TOTAL_LIGHTING_POWER_TARGET": "Total Lighting Power Target",
  "label.TOTAL_DEMAND": "Total Demand",
  "label.TOTAL_TARGET": "Total Target",
  "label.ELECT_DEMAND": "Elect. Demand",
  "label.ELECT_CONSUMPTION": "Elect. Consumption",
  "label.ELECT_SPENDING": "Elect. Spending",
  "label.THERM_DEMAND": "Therm. Demand",
  "label.THERM_CONSUMPTION": "Therm. Consumption",
  "label.THERM_SPENDING": "Therm. Spending",
  "label.ELECTRICITY_DEMAND": "Electricity Demand",
  "label.ELECTRICITY_TARGET": "Electricity Target",
  "label.CW_CONSUMPTION": "CW Consumption",
  "label.CW_DEMAND": "CW Demand",
  "label.CW_TARGET": "CW Target",
  "label.ESSENTIAL_DEMAND": "Essential Demand",
  "label.AHU_[ENTITY]": "AHU ${entity}",
  "label.AHU_[ENTITY]_TARGET": "AHU ${entity} Target",
  "label.HRAHU_[ENTITY]": "HRAHU ${entity}",
  "label.HRAHU_[ENTITY]_TARGET": "HRAHU ${entity} Target",
  "label.[ENTITY]_DEMAND": "${entity} Demand",
  "label.[ENTITY]_TARGET": "${entity} Target",
  "label.[ENTITY]_STATUS": "${entity} Status",
  "label.[ENTITY]_OCCUPANCY": "${entity} Occupancy",
  "label.AVG_[ENTITY]_OCCUPANCY": "Avg ${entity} Occupancy",
  "label.[ENTITY]_MODE": "${entity} Mode",
  "label.[ENTITY]_DAMP_POS": "${entity} Damp. Pos.",
  "label.AVG_[ENTITY]_DAMP_POS": "Avg ${entity} Damp. Pos.",
  "label.[ENTITY]_ACTIVATED_HVAC_ZONES": "${entity} Activated HVAC Zones",
  "label.ELECTRICITY_SPENDING": "Electic Spending",
  "label.ELECTRICITY_SPENDING_TARGET": "Electic Target Spending",
  "label.CW_SPENDING": "CW Spending",
  "label.CW_SPENDING_TARGET": "CW Target Spending",
  "title.AVG_OCCUPIED_ZONES": "Avg Occupied Zones",
  "label.CONSUMPTION_TARGET": "Consumption Target",
  "label.HVAC_CONSUMPTION": "HVAC Consumption",
  "label.HVAC_SPENDING": "HVAC Spending",
  "label.AVG_HVAC_DEMAND": "Avg HVAC Demand",
  "label.HVAC_CONSUMPTION_TARGET": "HVAC Target Consumption",
  "label.HVAC_SPENDING_TARGET": "HVAC Target Spending",
  "label.AVG_HVAC_TARGET": "Avg HVAC Target",
  "label.AVG_LIGHTING_DEMAND": "Avg Lighting Demand",
  "label.LIGHTING_SPENDING": "Lighting Spending",
  "label.LIGHTING_CONSUMPTION": "Lighting Consumption",
  "label.LIGHTING_CONSUMPTION_TARGET": "Lighting Target Consumption",
  "label.LIGHTING_SPENDING_TARGET": "Lighting Target Spending",
  "label.AVG_LIGHTING_TARGET": "Avg Lighting Target",
  "label.OTHER_LOADS_CONSUMPTION": "Other Loads Consumption",
  "label.OTHER_LOADS_SPENDING": "Other Loads Spending",
  "label.AVG_OTHER_LOADS_DEMAND": "Avg Other Loads Demand",
  "label.AVG_GENERAL_LOADS_DEMAND": "Avg General Loads Demand",
  "label.OTHER_LOADS_CONSUMPTION_TARGET": "Other Loads Target Consumption",
  "label.OTHER_LOADS_SPENDING_TARGET": "Other Loads Target Spending",
  "label.AVG_OTHER_LOADS_TARGET": "Avg Other Loads Target",
  "label.OCCUPIED_ZONES": "Occupied Zones",
  "label.SPENDING_TARGET": "Target Spending",
  "label.AVG_DEMAND": "Avg Demand",
  "label.AVG_TARGET": "Avg Target",
  "label.ESSENTIAL_HVAC_CONSUMPTION": "Essential HVAC Consumption",
  "label.ESSENTIAL_CONSUMPTION": "Essential Consumption",
  "label.[ENTITY]_CONSUMPTION": "${entity} Consumption",
  "label.[ENTITY]_CONSUMPTION_TARGET": "${entity} Target Consumption",
  "label.ESSENTIAL_HVAC_SPENDING": "Essential HVAC Spending",
  "label.ESSENTIAL_SPENDING": "Essential Spending",
  "label.[ENTITY]_SPENDING": "${entity} Spending",
  "label.[ENTITY]_SPENDING_TARGET": "${entity} Target Spending",
  "label.AVG_ESSENTIAL_HVAC_DEMAND": "Avg Essential HVAC Demand",
  "label.AVG_ESSENTIAL_DEMAND": "Avg Essential Demand",
  "label.AVG_[ENTITY]_DEMAND": "Avg ${entity} Demand",
  "label.AVG_[ENTITY]_TARGET": "Avg ${entity} Target",
  "label.[ENTITY]_ACTIVE_DURATION": "${entity} Active Duration",
  "label.[ENTITY]_COOLING_DURATION": "${entity} Cooling Duration",
  "label.[ENTITY]_FAN_DURATION": "${entity} Fan Duration",
  "label.AVG_ACTIVATED_PIM_IN_[ENTITY]_ZONE":
    "Avg Activated PIM in ${entity} zone",
  "label.ACTIVATED_PIM_IN_[ENTITY]_ZONE": "Activated PIM in ${entity} zone",
  "label.AVG_AHU_[ENTITY]_DEMAND": "Avg ${entity} AHU Demand",
  "label.AHU_[ENTITY]_CONSUMPTION": "${entity} AHU Consumption",
  "label.AHU_[ENTITY]_SPENDING": "${entity} AHU Spending",
  "label.AVG_AHU_[ENTITY]_TARGET": "Avg ${entity} AHU Target",
  "label.AHU_[ENTITY]_CONSUMPTION_TARGET": "${entity} AHU Target Consumption",
  "label.AHU_[ENTITY]_SPENDING_TARGET": "${entity} AHU Target Spending",
  "label.AVG_HRAHU_[ENTITY]_DEMAND": "Avg ${entity} HRAHU Demand",
  "label.HRAHU_[ENTITY]_CONSUMPTION": "${entity} HRAHU Consumption",
  "label.HRAHU_[ENTITY]_SPENDING": "${entity} HRAHU Spending",
  "label.AVG_HRAHU_[ENTITY]_TARGET": "Avg ${entity} HRAHU Target",
  "label.HRAHU_[ENTITY]_CONSUMPTION_TARGET":
    "${entity} HRAHU Target Consumption",
  "label.HRAHU_[ENTITY]_SPENDING_TARGET": "${entity} HRAHU Target Spending",
  "label.AVG_UPS_DEMAND": "Avg UPS Demand",
  "label.UPS_CONSUMPTION": "UPS Consumption",
  "label.UPS_SPENDING": "UPS Spending",
  "label.AVG_UPS_TARGET": "Avg UPS Target",
  "label.UPS_CONSUMPTION_TARGET": "UPS Target Consumption",
  "label.UPS_SPENDING_TARGET": "UPS Target Spending",
  "label.DCHWV_OPEN_DURATION": "DCHWV Open Duration",
  "label.AVG_PERFORMANCE_GAP": "Avg Performance Gap",
  "label.AVG_RELATIVE_HUMIDITY": "Avg Relative Humidity",
  "label.RELATIVE_HUMIDITY": "Relative Humidity",
  "label.AVG_CO2_LEVEL": "Avg CO2 Level",
  "label.AVG_VOC_LEVEL": "Avg VOC Level",
  "label.[ENTITY]_TEMP": "${entity} Temp.",
  "label.AVG_[ENTITY]_TEMP": "Avg ${entity} Temp.",
  "label.[ENTITY]_HUMIDITY": "${entity} Humidity",
  "label.AVG_VOC": "Avg. VOC",
  "label.[ENTITY]_VOC": "${entity} VOC",
  "label.[ENTITY]_CO2": "${entity} CO2",
  "label.AVG_INDOOR_TEMPERATURE_IN_[ENTITY]_ZONE":
    "Avg Indoor temperature in ${entity} zone",
  "label.INDOOR_TEMPERATURE_IN_[ENTITY]_ZONE":
    "Indoor temperature in ${entity} zone",
  "label.AVG_[ENTITY]_INDOOR_TEMP": "Avg ${entity} Indoor Temp.",
  "label.AVG_INDOOR_TEMP": "Avg Indoor Temp",
  "label.INDOOR_TEMP": "Indoor Temp",
  "label.[ENTITY]_ZONE": "${entity} Zone",
  "label.AVG_CLOUDCOVER": "Avg Cloud Cover",
  "label.AVERAGING_TOOL": "Averaging tool",
  "option.CUSTOMIZE_GRAPH": "Customize graph",
  "option.OPEN_EXISTING_GRAPH": "Open existing graph",
  "option.CREATE_NEW_GRAPH": "Create new graph",
  "action.ADD_TREND": "Add Trend",
  "action.ADD_REMOVE": "Add/Remove",
  "action.EXPORT": "Export",
  "action.SAVE": "Save",
  "action.SAVE_AS": "Save as",
  "action.LOAD": "Load",
  "action.SUBMIT": "Submit",
  "action.EXPAND": "Expand",
  "action.CLEAR": "Clear",
  "action.DONE": "Done",
  "action.ADD": "Add",
  "action.REMOVE": "Remove",
  "title.ADD_REMOVE_TRENDS": "Add/Remove Trends",
  "title.ADD": "Add",
  "title.CURRENT_TRENDS": "Current Trends",
  "label.SEARCH": "Search",
  "label.TYPE_SEARCH": "Type a command for search...",
  "message.NO_RESULTS": "There are no results",
  "message.DISPLAYING_RESULTS_FOR": "Displaying results for",
  "message.INFO_[NUMBER]": "All (${number})",
  "label.BUILDING": "Building",
  "label.FLOORS": "Floors",
  "label.CATEGORIES": "Categories",
  "label.ELECTRICITY_COMPONENTS": "Electricity Components",
  "label.CHILLED_WATER_COMPONENTS": "Chilled Water Components",
  "label.FLOOR_LEVEL": "Floor Level",
  "label.ELECTRICITY_HVAC": "Electricity HVAC",
  "label.CHILLED_WATER_HVAC": "Chilled Water HVAC",
  "label.AHU_ZONES": "AHU Zones",
  "label.ELECTRICITY_OTHER_LOADS": "Electricity Other Loads",
  "label.ELECTRICITY_LIGHTING": "Electricity Lighting",
  "label.PARENT_ZONES": "Parent Zones",
  "title.GRAPH_CREATED": "Graph Created",
  "message.GRAPH_CREATED": "Please enter a name for this Graph.",
  "title.SAVE_AS": "Save as",
  "label.GRAPH_NAME": "Graph name",
  "action.DONT_SAVE": "Don't Save",
  "message.GRAPH_[NAME]_SAVED_SUCCESSFULLY":
    'Graph "${name}" saved successfully',
  "label.TOTAL_RECOM": "Total Recom.",
  "label.AVG_ACTUAL_SAVINGS": "Avg. Actual Savings",
  "label.ACTUAL_SAVINGS_CONSUMPTION": "Actual Savings Consumption",
  "label.ACTUAL_SAVINGS_SPENDING": "Actual Savings Spending",
  "label.ACTUAL_SAVINGS_DEMAND": "Actual Savings Demand",
  "label.MISSED_SAVINGS_CONSUMPTION": "Missed Savings Consumption",
  "label.MISSED_SAVINGS_SPENDING": "Missed Savings Spending",
  "label.MISSED_SAVINGS_DEMAND": "Missed Savings Demand",
  "label.AVG_[ENTITY]_RELATIVE_HUMIDITY": "Avg ${entity} Relative Humidity",
  "label.[ENTITY]_RELATIVE_HUMIDITY": "${entity} Relative Humidity",
  "label.AVG_[ENTITY]_TEMPERATURE": "Avg ${entity} Temperature",
  "label.[ENTITY]_TEMPERATURE": "${entity} Temperature",
  "label.AVG_[ENTITY]_CO2_LEVEL": "Avg ${entity} CO2 Level",
  "label.[ENTITY]_CO2_LEVEL": "${entity} CO2 Level",
  "label.AVG_[ENTITY]_VOC_LEVEL": "Avg ${entity} VOC Level",
  "label.[ENTITY]_VOC_LEVEL": "${entity} VOC Level",
  "label.AVG_[ENTITY]_LUX_LEVEL": "Avg ${entity} Lux Level",
  "label.[ENTITY]_LUX_LEVEL": "${entity} Lux Level",
  "label.[ENTITY]_ACTIVE_COMFORT_ALARMS": "${entity} Active Comfort Alarms",
  "title.ENERGY": "Energy",
  "title.CURRENCY": "Currency",
  "title.EMISSIONS": "Emissions",
  "title.COMPLIANCE_TRACKING": "Compliance Tracking",
  "label.AVG_[ENTITY]_OCCUPIED_ZONES": "Avg ${entity} Occupied Zones",
  "label.[ENTITY]_OCCUPIED_ZONES": "${entity} Occupied Zones",
  "label.AVG_[ENTITY]_OCCUPIED_LC_ZONES": "Avg ${entity} Occupied LC Zones",
  "label.[ENTITY]_OCCUPIED_LC_ZONES": "${entity} Occupied LC Zones",
  "option.OCCUPANCY_FILTER": "Occupancy Filter",
  "option.TIME_FILTER": "Time Filter",
  "label.SUSTAINABILITY_KPI": "Sustainability KPI",
  "label.AVG_EMISSIONS_PER_PERSON": "Avg. Emissions per person",
  "label.AVG_EMISSIONS_PER_M": "Avg. Emissions per m",
  "label.AVG_EMISSIONS_PER_DAY": "Avg. Emissions per day",
  "label.AVG_EMISSIONS_OVER_PERS": "Avg. Emissions/pers",
  "label.AVG_EMISSIONS_OVER_M": "Avg. Emissions/m",
  "label.AVG_EMISSIONS_OVER_DAY": "Avg. Emissions/day",
  "label.AVG_EMISSIONS_PER_ROUTE": "Avg. Emissions per Route",
  "label.TRAVEL": "Travel",
  "label.REFRIGERANT_LEAKAGE": "Refrigerant Leakage",
  "label.REFRIGERANTS_IN_USE": "Refrigerant(s) in use",
  "label.TOTAL_EQUIVALENT_WARMING_IMPACT":
    "Total Equivalent Warming Impact (TEWI)",
  "label.DIRECT_EXPANSION_UNITS_NUMBERS":
    "Total Number of Direct Expansion (DX) Units",
  "label.OZONE_DEPLETION_POTENTIAL": "Ozone Depletion Potential (ODP)",
  "label.REFRIGERANT_IMPACT": "Refrigerant Impact",
  "label.REFRIGERANT_TYPE_1": "R-22",
  "label.REFRIGERANT_TYPE_2": "R-410a",
  "label.REFRIGERANT_TYPE_3": "R-407c",
  "label.CONSUMABLES": "Consumables",
  "label.FUEL": "Fuel",
  "label.WASTE": "Waste",
  "label.DOMESTIC_WATER": "Domestic Water",
  "label.DOMESTIC_WATER_EMISSIONS_CONVERSION_FACTOR":
    "Domestic Water Emissions Conversion Factor",
  "label.DOMESTIC_WATER_EMISSIONS_CONVERSION_FACTOR_HISTORY":
    "Domestic Water Emissions Conversion Factor Historty",
  "message.NO_OCCUPANCY_FILTER_TRENDS":
    "No occupancy filters available for this selection",
  "title.TOTAL_BUILDING_EMISSIONS": "Total Building Emissions",
  "label.TOTAL_EMISSIONS": "Total Emissions",
  "label.EMISSIONS_TARGETS": "Emissions Targets",
  "label.POTENTIAL_EMISSIONS_REDUCTION": "Potential Emissions Reduction",
  "label.SCOPE": "Scope",
  "label.SCOPE_1_[VALUE]": "Scope 1: ${value}",
  "label.SCOPE_2_[VALUE]": "Scope 2: ${value}",
  "label.SCOPE_3_[VALUE]": "Scope 3: ${value}",
  "title.SELECT_RANGE": "Select a Range",
  "label.FROM": "From",
  "label.TO": "To",
  "label.TREND": "Trend",
  "label.CUMULATIVE": "Cumulative",
  "message.ADD_OCCUPANCY_FILTER_TRENDS":
    "Please add an occupancy trend to activate this filter",
  "message.ADD_FILTER_TRENDS": "Please add any trend to activate this filter",
  "message.OCCUPANCY_FILTER_MESSAGE": ({
    minFilterValue,
    maxFilterValue,
    minFilterValuePercent,
    maxFilterValuePercent,
    entity,
    selectedIntervalText,
  }) => (
    <>
      <b>{minFilterValue}</b> to <b>{maxFilterValue}</b> occupants{" "}
      <>
        corresponding to <b>{minFilterValuePercent}</b> to{" "}
        <b>{maxFilterValuePercent}</b>{" "}
      </>
      of <b>[{entity}]</b> seating capacity for {selectedIntervalText}
    </>
  ),
  "message.OCCUPIED_ZONES_FILTER_MESSAGE": ({
    minFilterValue,
    maxFilterValue,
    entity,
    selectedIntervalText,
  }) => (
    <>
      <b>{minFilterValue}</b> to <b>{maxFilterValue}</b> occupied zones of{" "}
      <b>[{entity}]</b> for {selectedIntervalText}
    </>
  ),
  "label.ELECTRICITY_EMISSION_INTENSITY": "Electricity Emission Intensity",
  "label.ELECTRICITY_EMISSION_INTENSITY_HISTORY":
    "Electricity Emission Intensity History",
  "label.CHILLER_PLANT_COP": "Chiller Plant COP",
  "label.CHILLER_PLANT_COP_HISTORY": "Chiller Plant COP History",
  "label.DIESEL_EMISSIONS_CONVERSION_FACTOR":
    "Diesel Emissions Conversion Factor",
  "label.DIESEL_EMISSIONS_CONVERSION_FACTOR_HISTORY":
    "Diesel Emissions Conversion Factor History",
  "label.ASSUMPTIONS": "Assumptions",
  "label.COMMON": "Common",
  "label.ALL_FLOORS": "All Floors",
  "label.PRESUMED_FLOORS_EMISSIONS": "Presumed Floors Emissions",
  "tooltip.PRESUMED_EMISSIONS":
    '"Presumed emissions" refers to the would-be emissions in the case of complete reliance on energy from the grid.',
  "label.PRESUMED_COMMON_UTILITIES_EMISSIONS":
    "Presumed Common Utilities Emissions",
  "tooltip.DIESEL_CONSUMED":
    "Diesel fuel is consumed by the emergency power generator",
  "tooltip.EVALUATE_COMPLIANCE":
    "Used to evaluate compliance with LEED O+M v4.1 credit requirements",
  "label.PV_PANEL_EMISSIONS_OFFSET": "PV Panel Emissions Offset",
  "label.NET_TOTAL_EMISSIONS": "Net Total Emissions",
  "error.MAX_ALLOWED_VALUE_IS_[MAX_VALUE]": "Max allowed value is ${maxValue}",
  "error.MIN_ALLOWED_VALUE_IS_[MIN_VALUE]": "Min allowed value is ${minValue}",
  "error.MAX_ALLOWED_3_DECIMAL_PLACES": "Max allowed 3 decimal places",
  "message.MULTI_DAY_TEXT": "the duration from",
  "unit.COEFFICIENT_OF_PERFORMANCE": "coefficient of performance",
  "label.TIME": "Time",
  "label.DIESEL_EMISSION": "Diesel Emissions",
  "label.DIESEL_CONSUMPTION": "Diesel Consumption",
  "label.AVG_EMISSION_PER_PERSON": "Avg. Emissions per Person",
  "label.AVG_EMISSION_PER_AREA": (
    <>
      Avg. Emissions per m<sup>2</sup>
    </>
  ),
  "label.VALUE": "Value",
  "label.PRESUMED_COMMON_ELECTRICAL_UTILITIES_EMISSIONS":
    "Presumed Common Electrical Utilities Emissions",
  "action.SEARCH": "Search",
  "title.GRAPHS": "Graphs",
  "title.SAVED_GRAPHS": "Saved Graphs",
  "label.SCOPE_BREAKDOWN": "Scope Breakdown",
  "label.SCOPE_[X]": "Scope ${x}",
  "label.SCOPE_1_COMPANY_OWNED_VEHICLES": "Scope 1: Company Owned Vehicles",
  "label.SCOPE_3_EMPLOYEE_COMMUTING_CARS_BUSES_FLIGHTS":
    "Scope 3: Employee Commuting (Cars, Buses) & Flights",
  "label.TOTAL_WASTE_CONTENT": "Total Waste Content",
  "label.TOTAL_WATER_USAGE": "Total Water Usage",
  "label.TOTAL_EQUIVALENT_EMISSIONS": "Total Equivalent Emissions",
  "label.PRINTED_PAPER": "Printed Paper",
  "label.TISSUES": "Tissues",
  "label.TOILET_PAPER": "Toilet Paper",
  "label.PAPER_CUPS": "Paper Cups",
  "label.PLASTIC_CUPS": "Plastic Cups",
  "label.PRINTED_PAPER_EMISSIONS_CONVERSION_FACTOR":
    "Printed Paper Emissions Conversion Factor",
  "label.PRINTED_PAPER_FACTOR_HISTORY": "Printed Paper Factor History",
  "label.TISSUES_EMISSIONS_CONVERSION_FACTOR":
    "Tissues Emissions Conversion Factor",
  "label.TISSUES_FACTOR_HISTORY": "Tissues Factor History",
  "label.TOILET_PAPER_EMISSIONS_CONVERSION_FACTOR":
    "Toilet Paper Emissions Conversion Factor",
  "label.TOILET_PAPER_FACTOR_HISTORY": "Toilet Paper Factor History",
  "label.PAPER_CUPS_EMISSIONS_CONVERSION_FACTOR":
    "Paper Cups Emissions Conversion Factor",
  "label.PAPER_CUPS_FACTOR_HISTORY": "Paper Cups Factor History",
  "label.PLASTIC_CUPS_EMISSIONS_CONVERSION_FACTOR":
    "Plastic Cups Emissions Conversion Factor",
  "label.PLASTIC_CUPS_FACTOR_HISTORY": "Plastic Cups Factor History",
  "label.WASTE_CONTENT_BREAKDOWN": "Waste Content Breakdown",
  "label.AGRIWASTE": "Agriwaste",
  "label.PAPER_AND_CARTON": "Paper & Carton",
  "label.PLASTIC": "Plastic",
  "label.FOOD_WASTE": "Food Waste",
  "label.CARBON_EMISSIONS_PERCENTAGE_PER_PROCESS":
    "Carbon Emissions Percentage per Process",
  "label.TRANSPORTATION": "Transportation",
  "label.PLASTIC_RECYCLING": "Plastic Recycling",
  "label.PAPER_RECYCLING": "Paper Recycling",
  "label.COMPOSTING": "Composting",
  "label.RDF": "RDF",
  "error.SERVER_ERROR_OCCURRED": "Server error occurred",
  "error.CANNOT_LOAD_HISTORY": "Cannot load history",
  "error.ERROR_DURING_LOADING_DATA": "Error during loading data",
  "message.NO_HISTORY_FOUND": "No history found",
  "label.COMPANY_OWNED_VEHICLES": "Company Owned Vehicles",
  "label.EMPLOYEE_COMMUTE_CARS": "Employee Commute (Cars)",
  "label.EMPLOYEE_COMMUTE_BUSES": "Employee Commute (Buses)",
  "label.FLIGHTS": "Flights",
  "label.TOTAL_TRAVEL_EMISSIONS": "Total Travel Emissions",
  "label.BUSES": "Buses",
  "label.CARS": "Cars",
  "label.FLEET_SIZE": "Fleet Size",
  "label.AVG_FUEL_USAGE": "Avg. Fuel Usage",
  "label.TOTAL_FUEL_USAGE": "Total Fuel Usage",
  "label.AVG_EMISSIONS": "Avg. Emissions",
  "label.AVG_DAILY_FUEL_USAGE_PER_CAR": "Avg. Daily Fuel Usage per Car",
  "label.AVG_KM_TRAVELED": "Avg. km Traveled",
  "label.AVG_DAILY_FUEL_USAGE_PER_BUS": "Avg. Daily Fuel Usage per Bus",
  "unit.DAY": "day",
  "legend.DIESEL_[VALUE]_[PERCENT]": "Diesel (${value}) (${percent})",
  "legend.PETROL_95_OCTANE_[VALUE]_[PERCENT]":
    "Petrol (95 Octane) (${value}) (${percent})",
  "legend.PETROL_92_OCTANE_[VALUE]_[PERCENT]":
    "Petrol (92 Octane) (${value}) (${percent})",
  "label.SOLID_WASTE_EMISSIONS_CONVERSION_FACTOR":
    "Solid Waste Emissions Conversion Factor",
  "label.SOLID_WASTE_FACTOR_HISTORY": "Solid Waste Factor History",
  "label.DAILY_EMPLOYEE_COMMUTE": "Daily Employee Commute",
  "label.AVG_EMPLOYEES_PRESENT": "Avg. employees present",
  "message.HANDLE_CLOSE_CHART_WITH_FILTERS":
    "The filters are currently active; closing the chart will also deactivate the filters.",
  "label.MODE_OF_TRANSPORT_DISTRIBUTION": "Mode of transport distribution",
  "label.MODE_OF_TRANSPORT_DISTRIBUTION_HISTORY":
    "Mode of transport distribution history",
  "label.CARPOOLING": "Carpooling",
  "error.SUM_OF_FIELDS_SHOULD_BE_[SUM_VALUE]":
    "Sum of fields should be ${sumValue}",
  "label.AVG_KM_TRAVELED_HISTORY": "Avg. km Traveled history",
  "label.%_OF_CARPOOLING_TRIPS": "% of carpooling trips",
  "label.%_OF_CARPOOLING_TRIPS_HISTORY": "% of carpooling trips history",
  "label.AVG_FUEL_ECONOMY_(CARS)": "Avg. fuel economy (Cars)",
  "label.AVG_FUEL_ECONOMY_(CARS)_HISTORY": "Avg. fuel economy (Cars) history",
  "label.AVG_FUEL_ECONOMY_(BUSES)": "Avg. fuel economy (Buses)",
  "label.AVG_FUEL_ECONOMY_(BUSES)_HISTORY": "Avg. fuel economy (Buses) history",
  "label.EMISSIONS_PER_PASSENGER_KM_(BUSES)":
    "Emissions per passenger‧km (Buses)",
  "label.EMISSIONS_PER_PASSENGER_KM_(BUSES)_HISTORY":
    "Emissions per passenger‧km (Buses) history",
  "label.AVG_NUMBER_OF_OCCUPANTS_PER_BUS": "Avg. number of occupants per bus",
  "label.AVG_NUMBER_OF_OCCUPANTS_PER_BUS_HISTORY":
    "Avg. number of occupants per bus history",
  "title.LEED_OM": "LEED O+M v4.1",
  "label.EVALUATION_WIZARD": "Evaluation Wizard",
  "tooltip.EVALUATION_WIZARD":
    "Review credit requirements and initiate project evaluation process.",
  "message.ACCESS_NOT_AVAILABLE": "Access Not Available",
  "message.CONTACT_ADMIN":
    "Your account does not have the permissions required. Please contact your administrator for further support.",
  "message.WIZARD_NOT_FILLED":
    "If you are a first-time user, please note that the Evaluation Wizard must be filled out in order to view relevant data.",
  "label.CAIRO_HQ": "Cairo HQ",
  "label.CREDITS_ESTIMATE": "Credits Estimate",
  "label.CURRENT_STATUS": "Current Status",
  "tooltip.NOT_ELIGIBLE_FOR_CERTIFICATE":
    "Eligibility criteria for LEED O+M v4.1 has not been satisfied. Must satisfy all prerequisite credit requirements.",
  "label.MASS_REFRIGERANT_AC_UNIT_BY_CAPACTIY":
    "Mass of Refrigerant in AC Unit",
  "label.MASS_REFRIGERANT_AC_UNIT_BY_CAPACTIY_HISTORY":
    "Mass of Refrigerant per Thermal Capacity of AC Unit History",
  "label.END_OF_LIFE_REFRIGERANT_LOSS": "End-of-life Refrigerant Loss",
  "label.END_OF_LIFE_REFRIGERANT_LOSS_HISTORY":
    "End-of-life Refrigerant Loss History",
  "label.GLOBAL_WARMING_POTENTIAL": "Global Warming Potential (GWP)",
  "label.GLOBAL_WARMING_POTENTIAL_HISTORY": "Global Warming Potential History",
  "label.R_22_GWP_EDITABLE": "R-22 (per kg):",
  "label.R_22_GWP_EDITABLE_HISTORY": "R-22 refrigerant history",
  "label.R_410_GWP_EDITABLE_HISTORY": "R-410 refrigerant history",
  "label.R_407_GWP_EDITABLE_HISTORY": "R-407 refrigerant history",
  "label.R_410_GWP_EDITABLE": "R-410a (per kg):",
  "label.R_407_GWP_EDITABLE": "R-407 (per kg):",
  "label.REFRIGERANT_LEAKAGE_RATE": "Refrigerant Leakage Rate",
  "label.REFRIGERANT_LEAKAGE_RATE_HISTORY": "Refrigerant Leakage Rate History",
  "label.LIFE_TIME_DIRECT_EXPANSION_UNIT":
    "Expected Lifetime of Direct Expansion (DX) Units",
  "label.LIFE_TIME_DIRECT_EXPANSION_UNIT_HISTORY":
    "Expected Lifetime of Direct Expansion (DX) Units History",
  "title.GOAL_ACHIEVEMENT_SCENARIOS": "Goal Achievement Scenarios",
  "label.ADD_SCENARIO": "Add Scenario",
  "message.ADD_SCENARIO_HINT":
    "Select desired score target in each category to create your own scenario",
  "label.SCENARIO_NAME": "Scenario Name",
  "label.COPY": "Copy",
  "title.EVALUATION_WIZARD": "Evaluation Wizard",
  "message.EVALUATION_WIZARD_HINT":
    "Review credit requirements and check whether they are compliant or not. All reported data is assumed to adhere to methodology requirements outlined in the LEED O&M v4.1 Manual",
  "title.CATEGORIES": "Categories",
  "label.LOCATION_AND_TRANSPORTATION": "Location and Transportation",
  "label.REQUISITE": "Req.",
  "label.SUBCATEGORY": "Sub-Category",
  "label.MIN_MAX": "Min/Max",
  "label.TOTAL_ACHIEVED": "Total Achieved",
  "label.TRANSPORTATION_PERFORMANCE": "Transportation Performance",
  "error.CANNOT_LOAD_CATEGORIES": "Cannot load categories",
  "label.PREREQ": "Prereq",
  "label.CREDIT": "Credit",
  "tooltip.ELIGIBILITY_MINIMUM_NOT_MET":
    "Eligibility criteria for LEED O+M v4.1 has not been satisfied. Must satisfy all prerequisite credit requirements.",
  "label.COMMENTS": "Comments",
  "label.VIEW": "View",
  "label.PT": "pt",
  "action.PIN_SCENARIO": "Pin Scenario",
  "action.UNPIN_SCENARIO": "Unpin Scenario",
  "label.SATISFIES": "Satisfies",
  "message.SCENARIO_DOES_NOT_SATISFY_TARGET":
    "Scenario does not satisfy target",
  "message.SCENARIO_DOES_NOT_SATISFY_CERTIFICATE":
    "Scenario does not satisfy a certificate",
  "message.SCENARIO_SATISFIES_TARGET": "Scenario satisfies target",
  "label.UNCERTIFIABLE": "Uncertifiable",
  "label.UNCERTIFIED": "Uncertified",
  "label.CERTIFIED": "Certified",
  "label.SILVER": "Silver",
  "label.GOLD": "Gold",
  "label.PLATINUM": "Platinum",
  "label.LEED_OM_SILVER": "LEED O+M Silver",
  "label.LEED_OM_GOLD": "LEED O+M Gold",
  "label.LEED_OM_PLATINUM": "LEED O+M Platinum",
  "label.LEED_OM_SILVER_PLUS": "LEED O+M Silver+",
  "label.LEED_OM_GOLD_PLUS": "LEED O+M Gold+",
  "label.LEED_OM_CERTIFIED": "LEED O+M Certified",
  "label.[x]_[y]_PT": "${x}/${y} pt",
  "toast.SCENARIO_WAS_ACTIVATED": "Scenario was activated",
  "toast.SCENARIO_WAS_DEACTIVATED": "Scenario was deactivated",
  "toast.MAX_PINNED_SCENARIOS":
    "Maximum number of pinned scenarios has been reached.",
  "label.ALL_SCENARIOS": "All Scenarios",
  "label.ASCENDING": "Ascending",
  "label.DESCENDING": "Descending",
  "label.SUSTAINABLE_SITES": "Sustainable Sites",
  "label.RAINWATER_MANAGEMENT": "Rainwater Management",
  "label.HEAT_ISLAND_REDUCTION": "Heat Island Reduction",
  "label.LIGHT_POLLUTION_REDUCTION": "Light Pollution Reduction",
  "label.SITE_MANAGEMENT": "Site Management",
  "label.WATER_EFFICIENCY": "Water Efficiency",
  "label.WATER_PERFORMANCE": "Water Performance",
  "message.NO_SCENARIOS": "No scenarios to show",
  "message.EVALUATION_WIZARD_NOT_COMPLETED":
    "Evaluation wizard has not been filled for the current year.",
  "message.ARE_YOU_SURE": "Are you absolutely sure?",
  "message.BEFORE_DELETE_SCENARIO_[SCENARIO_NAME]":
    "This action cannot be undone. This action will permanently delete ${SCENARIO_NAME}",
  "label.DELETE_SCENARIO_CONFIRMATION": "Yes, delete scenario",
  "message.BEFORE_ACTIVATE_SCENARIO_[SCENARIO1]_[SCENARIO2]":
    "${SCENARIO1} is currently set as active. Would you like to deactivate it and set ${SCENARIO2} as the active scenario instead?",
  "message.LEED_REQUIREMENTS_MET":
    "Requirements have been met according to LEED guidelines",
  "label.YES": "Yes",
  "label.NO": "No",
  "label.NAME": "Name",
  "label.ENERGY_AND_ATMOSPHERE": "Energy and Atmosphere",
  "label.ENERGY_AND_ATMOSPHERE_PRACTICES":
    "Energy Efficiency Best Management Practices",
  "label.FUNDAMENTAL_REFRIGERANT": "Fundamental Refrigerant Management",
  "label.ENERGY_PERFORMANCE": "Energy Performance",
  "label.GHG_EMISSIONS_SCORE": "GHG Emissions Score",
  "label.SOURCE_EMISSIONS_SOURCE": "Source Emissions Source",
  "label.ENHANCED_REFRIGERANT": "Enhanced Refrigerant Management",
  "label.GRID_HARMONIZATION": "Grid Harmonization",
  "label.MATERIALS_AND_RESOURCES": "Materials and Resources",
  "label.PURCHASING_POLICY": "Purchasing Policy",
  "label.FACILITY_MAINTEANCE_AND_RENOVATION_POLICY":
    "Facility Maintenance and Renovations Policy",
  "label.WASTE_PERFORMANCE": "Waste Performance",
  "label.PURCHASING": "Purchasing",
  "label.INDOOR_ENVIRONMENTAL_QUALITY": "Indoor Environmental Quality",
  "label.MINIMUM_INDOOR_AIR_QUALITY": "Minimum Indoor Air Quality",
  "label.ENVIRONMEWNAL_TOBACCO_SMOKE_CONTROL":
    "Environmental Tobacco Smoke Control",
  "label.GREEN_CLEANING_POLICY": "Green Cleaning Policy",
  "label.INDOOR_ENVIRONMENTAL_QUALITY_PERFORMANCE":
    "Indoor Environmental Quality Performance",
  "label.GREEN_CLEANING": "Green Cleaning",
  "label.INTEGRATED_PEST_MANAGEMENT": "Integrated Pest Management",
  "label.INNOVATION": "Innovation",
  "label.TOTAL_POSSIBLE_POINTS": "TOTAL POSSIBLE POINTS",
  "label.CATEGORY": "Category",
  "label.CURRENT": "Current",
  "label.TOTAL_SCORE": "Total Score",
  "label.REMAINING_CREDITS": "Remaining Credits",
  "label.RESULTING_STATUS": "Resulting Status",
  "message.ENTER_A_COMMENT": "Enter a comment...",
  "label.NEXT_TARGET": "Next target",
  "label.TOTAL_CREDITS": "Total Credits",
  "error.NOT_ALL_FIELDS_ARE_FILLED_WITH_PROPER_VALUES":
    "Not all fields are filled with proper values",
  "action.AUTO_SYNC": "Auto sync",
  "toast.AN_ERROR_HAPPENED": "An error happened",
  "label.ACTIVE_[x]": "Active ${x}",
  "label.TOTAL_CHILLED_WATER_EMISSIONS": "Total Chilled Water Emissions",
  "title.OCCUPANT_WELLBEING": "Occupant Wellbeing",
  "menu.OCCUPANT_WELLBEING": "Occupant Wellbeing",
  "label.ALARMS_GANTT_CHART": "Alarms Gantt Chart",
  "label.TOTAL_NUM_ALARMS": "Total num. of alarms",
  "label.TOTAL_ALARMS_DETAILS": "Total Alarm Details",
  "label.AVERAGE_DURATION": "Average Duration",
  "option.METRIC": "Metric",
  "option.ZONES": "Zones",
  "option.IMPACT": "Impact",
  "option.ALL": "All",
  "option.RH": "RH",
  "option.TEMP": "Temp",
  "option.CO2": "CO2",
  "option.VOC": "VOC",
  "option.PM2.5": "PM2.5",
  "option.LUX": "Lux",
  "option.NOISE": "Noise",
  "label.DATE_&_TIME": "Date & Time",
  "label.AVG_AFFECTED_OCCUPANTS": "Avg. Affected Occupants",
  "label.ACCEPTABLE_LIMIT": "Acceptable limit",
  "label.AVG_DEVIATION": "Avg. Deviation",
  "label.ABOVE_ACCEPTABLE_LIMIT": "Above Acceptable Limit",
  "label.[x]_HRS": "${x} hrs",
  "message.NO_ALARMS_FOUND": "No alarms found",
  "title.BY_METRIC": "By Metric",
  "title.BY_ZONE": "By Zone",
  "message.AVERAGE_CALCULATION_CUMULATIVE":
    "Average is calculated during occupied hours",
  "label.AVG_ALARMS_DURATION": "Avg. Alarms Duration",
  "title.ACTIVE_ALARMS": "Active Alarms",
  "label.ANNUAL_LEAVES": "Annual leaves",
  "label.REMOTE_WORK": "Remote work",
  "month.january": "Jan",
  "month.february": "Feb",
  "month.march": "Mar",
  "month.april": "Apr",
  "month.may": "May",
  "month.june": "Jun",
  "month.july": "Jul",
  "month.august": "Aug",
  "month.september": "Sep",
  "month.october": "Oct",
  "month.november": "Nov",
  "month.december": "Dec",
  "day.MONDAY": "Mon",
  "day.TUESDAY": "Tue",
  "day.WEDNESDAY": "Wed",
  "day.THURSDAY": "Thur",
  "day.FRIDAY": "Fri",
  "day.SATURDAY": "Sat",
  "day.SUNDAY": "Sun",
  "option.BY_METRIC": "By Metric",
  "option.BY_ZONE": "By Zone",
  "option.TEMPERATURE": "Temperature",
  "option.REL_HUMIDITY": "Rel. Humidity",
  "option.PM2_5": "PM 2.5",
  "label.METRIC_RANGES": "Metric Ranges",
  "label.METRIC_OCCUPANCY_RANGES": "Metric & Occupancy Ranges",
  "option.OCCUPANCY_STATUS": "Occupancy Status",
  "title.DETAILS_BY_ZONE": "Details by Zone",
  "title.DETAILS_BY_METRIC": "Details by Metric",
  "label.FDD_ALARM_RECOMMENDATION_OVERVIEW":
    "FDD, Alarm & Recommendation Overview",
  "label.HIGH": "High",
  "label.HIGH_PRIORITY": "High priority",
  "label.MEDIUM": "Medium",
  "label.MEDIUM_PRIORITY": "Medium priority",
  "label.LOW": "Low",
  "label.LOW_PRIORITY": "Low priority",
  "label.ALL": "All",
  "label.ASSET_FDD": "Asset FDD",
  "label.ENERGY_RECOMMENDATION": "Energy Recommendation",
  "label.USER_COMFORT": "User Comfort",
  "label.OVERALL_EFFECTIVENESS": "Overall Effectiveness",
  "label.HEALTH": "Health",
  "label.AVAILABILITY": "Availability",
  "label.UTILIZATION": "Utilization",
  "label.PERFORMANCE": "Performance",
  "label.PROPERTY_IMPACT": "Property Impact",
  "label.COMFORT_INDEX": "Comfort Index",
  "label.INSIGHTS_TOP": "Insights (Top ${x})",
  "label.CHILD_ASSET_HEALTH_BOTTOM": "Child Asset Health (Bottom ${x})",
  "label.CHILD_ASSET_PROPERTY_IMPACT_TITLE":
    "Asset Property Impact  (# of cycle / threshold)",
  "label.CHILD_ASSET_PROPERTY_IMPACT":
    "Child Asset Property Impact (Bottom ${x})",
  "label.CHILD_ASSET_PERFORMANCE_BOTTOM":
    "Child Asset Performance (Bottom ${x})",
  "label.CHILD_ASSET_AVAILABILITY_BOTTOM":
    "Child Asset Availability (Bottom ${x})",
  "title.KPI": "Asset Key Performance Indicators (KPIs)",
  "option.OVERALL": "Overall",
  "tooltip.NUMBER_OF_OCCUPANTS": "Number of occupants",
  "tooltip.AVERAGE_NUMBER_OF_OCCUPANTS": "Average number of occupants",
  "tooltip.PIM_OPENING_PERCENTAGE": "PIM opening percentage",
  "label.AM": "am",
  "label.PM": "pm",
  "label.MORNING": "Morning",
  "label.AFTERNOON": "Afternoon",
  "label.EVENING": "Evening",
  "label.NIGHT": "Night",
  "label.OVERALL_DESCRIPTION":
    "Overall Effectiveness is derived from sub KPIs’ (Health, Availiabiltity, Utilization, Performance, Property Impact & Comfort)",
  "label.OF_ALARM_FAULT": "#of Alarm/Fault",
  "label.OF_ACTIVE_FDD": "#of Active FDD",
  "label.CRITICAL_ALARM": "Critical Alarm",
  "label.INPUT_DEVICE": "Input Device",
  "label.FDD_STATUS": "FDD Status",
  "label.AVAILABILITY_DURATION": "Available Duration (a-b)",
  "label.SELECTED_DURATION": "Selected Duration (a)",
  "label.MAINTENANCE_DURATION_B": "Maintenance Duration (b)",
  "label.MAINTENANCE_DURATION": "Maintenance Duration",
  "label.OPERATING_MODE": "Operating Mode",
  "label.NON_OPERATING_MODE": "Non-Operating Mode",
  "action.BACK_TO_METRICS": "Back to metrics",
  "menu.PORTFOLIO_MANAGEMENT": "Portfolio Management",
  "label.OCC_DURATION": "Occ. Duration",
  "label.AVG_OCCUPANTS": "Avg. Occupants",
  "label.AVG_DENSITY": "Avg. Density",
  "label.ASSET_OVERALL_EFFECTIVENESS": "Overall Asset Effectiveness",
  "label.ASSET_HEALTH": "Asset Health",
  "label.ASSET_AVAILABILITY": "Asset Availability",
  "label.ASSET_UNAVAILABILITY_DURATION": "Asset Unavailable Duration",
  "label.ASSET_UTILIZATION": "Asset Utilization",
  "label.ASSET_PERFORMANCE": "Asset Performance",
  "label.ASSET_PROPERTY_IMPACT": "Asset Property Impact",
  "label.ASSET_COMFORT_INDEX": "Asset Comfort Index",
  "label.EXHAUST_AIR_DAMPER": "Exhaust Air Damper Excessive Cycling",
  "label.ASSET_COMFORT": "Asset Comfort",
  "label.ZONE_TEMPERATURE_ABOVE": "Zone Temperature Above Acceptable Limit",

  "label.CHILD_ASSET_OVERALL_BOTTOM":
    "Child Asset Overall Effectiveness (Bottom ${x})",
  "label.EVENT_HISTORY": "Event History",
  "label.EVENTS_MANAGEMENT": "Events Management",
  "label.ALL_DATA": "All Data",
  "label.KEY_DATA": "Key Data",
  "label.FAULTY_OPERATION": "Faulty Operation",
  "message.TOP_PERFORMING_BUILDINGS":
    "Top performing buildings are ${firstTopPerformingBuilding} and ${secondTopPerforminBuilding}",
  "message.WORST_PERFORMING_BUILDINGS":
    "Worst performing buildings are ${firstWorstPerformingBuilding} and ${secondWorstPerforminBuilding}",
  "message.COSTLIEST_HOUR_OF_WEEK":
    "Costliest hour of the week ${costliestHourOfWeek}",
  "message.HIGHEST_NUMBER_OF_ALARMS_BUILDING":
    "The building with the highest number of alarms and trips is ${highestNumberOfAlarmsBuilding}",
  "message.LOWEST_NUMBER_OF_ALARMS_BUILDING":
    "The building with the lowest number of alarms and trips is ${lowestNumberOfAlarmsBuilding}",
  "message.MOST_UTILIZED_BUILDING":
    "The most utilized building is ${mostUtilizedBuilding}",
  "message.LEAST_UTILIZED_BUILDING":
    "The least utilized building is ${leastUtilizedBuilding}",
  "title.AGGREGATE_PERFORMANCE": "Aggregate Performance",
  "title.PERFORMANCE_TRENDS": "Performance Trends",
  "title.THERMAL_ENERGY": "Thermal Energy",
  "title.WATER": "Water",
  "title.SUSTAINABILITY": "Sustainability",
  "title.ASSETS": "Assets",
  "title.OCC_WELLBEING": "Occupant Wellbeing",
  "title.SPACE_UTILIZATION": "Space Utilization",
  "label.SAVINGS_FROM_PV": "Savings from PV",
  "label.KWH_TO_$_AVERAGE_RATE": "kWh to $ Average Rate",
  "label.TOTAL_COST": "Total Cost",
  "label.CARBON_EMISSIONS": "Carbon Emissions",
  "label.NO_OF_WORKORDERS": "No. of Workorders",
  "label.ACTIVE_NO_OF_WORKORDERS": "Active No. of Work Orders",
  "label.AVG_ASSET_UTILIZATION_RATE": "Avg. Asset Utilization Rate",
  "label.ASSET_UTILIZATION_RATE": "Asset Utilization Rate",
  "label.TOTAL_NO_OF_ALARMS": "Total No. of Alarms",
  "label.ACTIVE_NO_OF_WELLBEING_ALARMS": "Active No. of Wellbeing Alarms",
  "label.AVG_NUMBER_OF_OCC": "Avg. Number of Occ",
  "label.AVG_NO_OF_OCCUPANTS": "No. of Occupants",
  "label.AVG_SPACE_UTILIZATION_RATE": "Avg. Space Utilization Rate",
  "label.LIVE_SPACE_UTILIZATION": "Live Space Utilization",
  "label.AVG_OCCUPANCY_RATE": "Avg. Occupancy Rate",
  "label.OCC_RATE": "Occ Rate",
  "label.OPERATING_DURATION": "Operating Duration (a)",
  "label.ACTUAL_OCCUPIED_DURATION": "Actual Occupied Duration (b)",
  "label.OPTIMAL_START_OPERATING_DURATION":
    "Optimal Start Operating Duration (c)",
  "label.DEFINED_OCCUPIED_DURATION": "Defined Occupied Duration (d)",
  "label.UNOCCUPIED_OPERATION_DURATION_REQUIRED":
    "Unoccupied Operation Duration Required (e)",
  "label.DEFINED_OPERATING_DURATION_REQUIRED":
    "Defined Operationg Duration Required (f=c+d+e)",
  "label.OVER_LESS_UTILIZATION_DURATION":
    "Over (+)/Less (-) Utilization Duration (a-f)",
  "label.OVER_LESS_OCCUPIED_DURATION":
    "Over (+)/Less (-) Occupied Duration (b-d)",
  "label.MISSED_SAVING_DUE_TO_OVER_UTILIZATION":
    "Missed Saving (due to over utilization)",
  "label.CHILD_ASSET_UTILIZATION_BOTTOM":
    "Child Asset Utilization (Bottom ${x})",
  "label.ASSET_PROPERTY_IMPACT_TOOLTIP":
    "For Asset Property Impact KPI: 0 - Low Impact and  1 - High Impact",
  "label.ASSET_CONFORT_INDEX": "Asset Confort Index",
  "label.NUMBER_OF_SUB_ASSET_CYCLING_ABOVE_THRESHOLD":
    "Number of sub-asset cycling above threshold",
  "label.AVOIDABLE_COST": "Avoidable Cost",
  "label.POTENCIAL_SAVING": "Potential Saving",
  "label.URGENCY_SCORE": "Urgency Score",
  "label.SUPPLY_FAN_SMOKE_DETECTOR_ALARM": "Supply Fan Smoke Detector Alarm",
  "label.SUPPLY_STATIC_PRESSURE_ALARM": "Supply Static Pressure Alarm",
  "label.SUPPLY_TEMPERATURE_MAY_BE_FAULTY": "Supply Temperature May be Faulty",
  "label.RETURN_TEMPERATURE_MAY_BE_FAULTY": "Return Temperature May be Faulty",
  "label.EXHAUST_DAMPER": "Exhaust Damper",
  "label.POTENTIAL_SAVING": "Potential Saving",
  "label.OF_CYCLES_THRESHOLD": "#of Cycles / Threshold",
  "label.SUPPLY_FAN": "Supply Fan",
  "label.CHILD_ASSET_OVERALL_EFFECTIVENESS":
    "Child Asset Overall Effectiveness (Bottom ${x})",
  "label.DETAILS_INDOOR": "Details: (Indoor Condition during occupied hours)",
  "label.ZONE_TEMP": "Zone Temp",
  "label.AVERAGE": "Average",
  "label.DEVIATION_DURATION_FDD_STATUS": "Deviation (duration / FDD status)",
  "label.TOTAL_OCCUPIED_DURATION": "Total occupied duration",
  "label.DEGREE_OF_DEVIATION_FROM_ACCEPTABLE_RANGE":
    "Degree of deviation from acceptable range",
  "label.DEVIATION_FROM_ACCEPTABLE_LIMIT": "Deviation from Acceptable Limit",
  "label.ZONE_TEMPERATURE_ABOVE_ACCEPTABLE_LIMIT":
    "Zone Temperature above acceptable limit",
  "label.ZONE_TEMPERATURE_BELOW_ACCEPTABLE_LIMIT":
    "Zone Temperature below acceptable limit",
  "label.WORKING_HOURS": "Working Hours",
  "label.NON_WORKING_HOURS": "Non-Working Hours",
  "label.BASELINE": "Baseline",
  "title.ENERGY_SOURCE_MIX": "Energy Source Mix",
  "label.ENERGY_SOURCE_STATUS": "Energy source status",
  "label.POWER_PLANT": "Power Plant",
  "label.UTILITY": "Utility",
  "label.BREAKDOWN_BY_BUILDING": "Breakdown by Building",
  "label.NORMALIZE_BY": "Normalize by",
  "label.NORMALIZE_OCC": "Normalize by Occ",
  "label.COST": "Cost",
  "unit.OCCUPANCY": "occ",
  "label.OPERATING_COST": "Operating Cost",
  "label.TOTAL_OPERATING_COST": "Total Operating Cost",
  "label.TOTAL_NUMBER_OF_WORKORDERS": "Total Number of Work Order",
  "label.OPERATING_DURATION_A": "Operating Duration (a)",
  "label.STANDARD_OPERATING_DURATION_B": "Standard Operating Duration (b=a-c)",
  "label.NON_STANDARD_OPERATING_DURATION_C":
    "Non-Standard Operating Duration (c = d+e-f)",
  "label.FAULTY_OPERATION_DURATION_D": "Faulty Operation Duration (d)",
  "label.CRITICAL_OPERATION_DURATION_E": "Critical Operation Duration (e)",
  "label.OVERLAP_DURATION_F":
    "Overlap Duration (Faulty & Critical Operation) (f)",
  "label.NON_OPERATION_DURATION_A": "Non-Operating Duration (a)",
  "label.NORMAL_NON_OPERATION_DURATION_B":
    "Normal Non-Operation Duration (b=a-c)",
  "label.NON_OPERATION_DURATION_WITH_FAULT_C":
    "Non-Operation Duration with Fault (c)",
  "label.SUPPLY_STATIC_PRESSURE_ABOVE_SETPOINT":
    "Supply Static Pressure Above Setpoint",
  "label.OUTSIDE_AIRFLOW_BELOW_SETPOINT": "Outside Airflow Below Setpoint",
  "label.SUPPLY_TEMPERATURE_ABOVE_SETPOINT":
    "Supply Temperature Above Setpoint",
  "label.CRITICAL_OPERATION": "Critical Operation",
  "label.SUPPLY_FAN_IS_RUNNING_ABOVE_95_SPEED":
    "Supply Fan is running above 95% speed",
  "label.COOLING_VALVE_IS_OPEN_ABOVE_95": "Cooling valve is open above 95%",
  "label.COOLING_VALVE_IS_OPEN_WHEN_UNTIL_IS_INACTIVE":
    "Cooling valve is open when unit is inactive",
  "label.SUPPLUY_FAN_COMMAND_STATUS_SIGNAL_ARE_MISMATCHED":
    "Supply Fan command & Status signal are mismatched (Status is “OFF” but Command is “ON”)",
  "label.OPERATION_SUSTAINABILITY": "Operation & Sustainability",
  "title.PORTFOLIO_MANAGEMENT": "Portfolio Management",
  "title.THERM_ENG_MIX": "Therm. Eng. Mix",
  "title.WATER_MIX": "Water Mix",
  "label.CO2_EMISSIONS_BY_SOURCE": "CO2 Emissions by source",
  "label.WORK_ORDERS_BREAKDOWN": "Work Orders Breakdown",
  "label.ACTIVE_WORK_ORDERS_BREAKDOWN": "Active Work Orders Breakdown",
  "label.AVERAGE_NUMBER_OF_AFFECTED_OCCUPANTS":
    "Average Number of Affected Occupants",
  "label.NUMBER_OF_AFFECTED_OCCUPANTS": "Number of Affected Occupants",
  "title.SPACE_INFORMATION": "Space Information",
  "label.AVG_RESERVED_SPACE": "Avg. Reserved Space",
  "label.AVG_RESERVED_CLASSES": "Avg. Reserved Classes",
  "label.AVG_RESERVED_LABS": "Avg. Reserved Labs",
  "label.RESERVED_SPACE": "Reserved Space",
  "label.RESERVED_CLASSES": "Reserved Classes",
  "label.RESERVED_LABS": "Reserved Labs",
  "label.TITLE": "Title",
  "label.OPERATING_STATE": "Operating State",
  "label.COOLING_MODE": "Cooling Mode",
  "label.FAN_MODE": "Fan Mode",
  "label.TOTAL_HOURS_OPERATION": "Total Hours of Operation",
  "label.AVOIDABLE_COST2": "Avoidable Cost (c)",
  "label.TOTAL_IDENTIFIED_SAVINGS": "Total Identified Saving (a+b+c)",
  "label.NOTE": "Note",
  "label.NOTES": "Notes",
  "label.NOTES_SUMMARY_CUMULATIVE_CURRENCY_POINT_1":
    "1. Calculated from FDD rules. ",
  "label.NOTES_SUMMARY_CUMULATIVE_CURRENCY_POINT_2":
    "2. Combined for electricity and chilled water using blended rate.",
  "label.ACTUAL_CONSUMPTION": "Actual Consumption",
  "label.TARGETED_CONSUMPTION": "Targeted Consumption",
  "label.ACTUAL_SPENDING": "Actual Spending",
  "label.TARGETED_SPENDING": "Targeted Spending",
  "label.ACTUAL_EMISSIONS": "Actual Emission",
  "label.TARGETED_EMISSIONS": "Targeted Emission",
  "label.MISSED_SAVING_A": "Missed Saving (a)",
  "label.ACTUAL_SAVING_B": "Actual Saving (b)",
  "label.ELECTRICITY_$": "Electricity ($)",
  "label.CHILLED_WATER_$": "Chilled Water ($)",
  "label.TOTAL_$": "Total ($)",
  "label.ELECTRICITY_KGCO2": "Electricity (kgCO2)",
  "label.CHILLED_WATER_KGCO2": "Chilled Water (kgCO2)",
  "label.TOTAL_KGCO2": "Total (kgCO2)",
  "label.CURRENT_DEMAND": "Current Demand",
  "label.CURRENT_TARGET": "Current Target",
  "label.CURRENT_MISSED_SAVING": "Current Missed Saving",
  "label.LEGEND": "Legend",
  "legend.MANUAL_OVERRIDE_FROM_BMS": "Manual override from BMS / other source",
  "legend.PARA_MANUAL_OVERRIDE": "Para manual override",
  "legend.PARA_MANUAL_OVERRIDE_TEMPORARY": "Para manual override - Temporary",
  "legend.PARA_MANUAL_OVERRIDE_TEMPORARY_SCHEDULED":
    "Para manual override - Temporary (Scheduled)",
  "legend.PARA_AUTOMATIC_INTERVENTION": "Para automatic intervention",
  "status.SHOWING": "Showing ${from} to ${to} of ${total} entries",
  "label.TYPE": "Type",
  "label.OVERRIDE": "Override",
  "label.WARNING": "Warning",
  "label.MESSAGE": "Message",
  "label.OVERRIDEN_STATUS": "Overriden Status",
  "label.TIME_DURATION": "Time Duration:",
  "label.TIME_DURATION_HRS": "(Total Duration: ${total} Hrs)",
  "label.OVERRIDE_BY": "Override By",
  "label.TWO": "2",
  "label.CURRENT_PRIORITY_ARRAY": "Current Priority Array",
  "label.VIEW_HISTORY": "View history",
  "filter.MANUAL_OVERRIDE_FROM_PARA": "Manual override from para",
  "filter.TEMPORARY_MANUAL_OVERRIDE_FROM_PARA":
    "Temporary Manual Override from Para",
  "filter.OVERRIDE_FROM_BMS_OR_OTHER_SOURCE":
    "Override from BMS or other source",
  "filter.PARA_AUTOMATIC_INTERVENTION_OVERRIDE":
    "Para Automatic Intervention override",
  "label.NO_OF_OCCUPANTS": "No. of Occupants",
  "label.MONDAY_ABBR": "Mo",
  "label.TUESDAY_ABBR": "Tu",
  "label.WEDNESDAY_ABBR": "We",
  "label.THURSDAY_ABBR": "Th",
  "label.FRIDAY_ABBR": "Fr",
  "label.SATURDAY_ABBR": "Sat",
  "label.SUNDAY_ABBR": "Su",
  "label.SEARCH_THREE_POINTS": "Search...",
  "label.SPACE_UTILIZATION": "Space Utilization",
  "title.BUILDINGS_PERFORMANCE": "Buildings Performance",
  "label.SOURCE_DATA": "Source Data",
  "label.CLAC_DATA": "Clac. Data",
  "label.WEATHER_DATA": "Weather Data",
  "label.SELECTION_ONLY": "Selection Only",
  "label.DESCRIPTION": "Description",
  "label.TOTAL_KEYDATA": "${total} Data",
  "label.HIGHLIGHTED_KEYDATA": "Highlighted in Key Data",
  "label.NO_OF_ACTIVE_ALARMS": "No. of Active Alarms",
  "label.HEALTH_DETAILS_TOOLTIP":
    "Asset Health KPI is calculated based on critical alarms and faults recorded at input devices.",
  "label.ASSET_CLASS": "Asset Class",
  "label.ASSET_TYPE": "Asset Type",
  "label.ASSET_NAME": "Asset Name",
  "label.START_TIME_LAST_OCURRENCE": (
    <>
      Start Time
      <br />
      (Last Occurrence)
    </>
  ),
  "label.END_TIME_LAST_OCURRENCE": (
    <>
      End Time
      <br />
      (Last Occurrence)
    </>
  ),
  "label.TOTAL_OCCURRENCES": "Total Occurrences",
  "label.OCURRENCES_DURATION": "Occurrences Duration",
  "label.KPI_CATEGORY": "KPI Category",
  "label.AVOIDABLE_COST_PRICE": "Avoidable Cost ($)",
  "label.POTENTIAL_COST_SAVING_PRICE": "Potential Cost Saving ($)",
  "label.ACTION": "Action",
  "title.FDD_HEATMAP": "FDD Heatmap",
  "label.LAST_MONTHS": "Last ${months} Months",
  "action.RELEASE": "Release",
  "label.OVERRIDE_STATUS": "Override Status ",
  "label.OVERRIDE_FROM_PARA": "Override",
  "label.OVERRIDE_TEMP_FROM_PARA": "Temp. Override",
  "label.CURRENT_OVERRIDE_INFO": "Current Override Info",
  "label.ZERO_TO_ONEHUNDRED": "(0% to 100%)",
  "status.CLOSE": "Close",
  "status.OPEN": "Open",
  "label.NOISE": "Noise",
  "label.PM2_5": "PM 2.5",
  "label.TOTAL_AVOIDABLE_COST_TOTAL_SPENDING":
    "Total Avoidable Cost / Total Spending (for Range)",
  "label.TOTAL_POTENTIAL_SAVING_FORECASTED_FOR_REMAINING_YEAR":
    "Total Potential Saving forecasted for remaining year",
  "label.ALL_FDD_RECOMMENDATIONS": "All FDD Recommendations",
  "title.ASSET_MANAGEMENT_INSIGHTS": "Asset Management - Insights",
  "label.FDD": "FDD",
  "action.NEED_HELP": "Need help?",
  "action.CLICK_HERE": "Click here",
  "message.REMEMBER_ME_30_DAYS": "Remember for 30 days",
  "message.LOG_IN_TO_YOUR_ACCOUNT": "Log in to your account",
  "message.WELCOME_BACK_DETAILS": "Welcome back! Please enter your details.",
  "action.FORGOT_USERNAME": "Forgot username",
  "action.FORGOT_PASSWORD": "Forgot password",
  "label.TOTAL_AVOIDABLE_COST_SPENDING": "Total Avoidable Cost / Spending",
  "label.TOTAL_POTENTIAL_SAVING": "Total Potential Saving",
  "label.OF_SPENDING": "of spending",
  "label.FORECASTED_FOR_REMAINING_YEAR": "Forecasted for remaining year",
  "label.TOTAL_FDD": "Total FDD",
  "label.DURING_SELECTED_TIME_RANGE": "During selected time range",
  "label.CURRENTLY_ACTIVE": "Currently Active",
  "label.EXPIRED": "Expired",
  "title.ALL_FDD_RECOMMENDATIONS": "All FDD Recommendations",
  "title.TECH_INFO": "Tech. Info.",
  "title.SERVING_INFO": "Serving Info",
  "title.DOCUMENTS": "Documents",
  "label.STATE": "State",
  "label.STATE_SINCE": "State Since",
  "label.LAST_OCCURRED": "Last Ocurred",
  "label.ACTION_STATUS": "Action Status",
  "label.ASSIGNED_TO": "Assigned To",
  "label.DURATION_OF_FAULTY_OPERATION":
    "Duration of Faulty operation (Selected Range)",
  "label.DIAGNOSIS": "Diagnosis",
  "label.DIAGNOSIS_TEXT_1": "1. VFD may be manually overridden.",
  "label.DIAGNOSIS_TEXT_2": "2. Static pressure sensor may be faulty.",
  "label.DIAGNOSIS_TEXT_3": "3. Review SSP control. ",
  "label.DIAGNOSIS_TEXT_4": "4. Fan capacity is not sufficient. ",
  "label.BUILDING_NAME": "Building Name",
  "label.LOCATION_INFO": "Location info",
  "label.ASSET_GROUP": "Asset Group",
  "label.HISTORY_MONTHS": "History (${months} Months):",
  "label.DOCUMENTS_FOUND": "Documents Found",
  "label.SELECT_ALL": "Select all",
  "message.OPS": "Ops.",
  "label.MANUFACTURER": "Manufacturer",
  "label.MODEL": "Model",
  "label.YEAR_OF_INSTALATION": "Year of Installation",
  "label.MATERIAL": "Material",
  "label.RATED_SUPPLY_FAN": "Rated Power (Supply Fan)",
  "label.RATED_SUPPLY_AIRFLOW": "Rated Flow (Supply Airflow)",
  "label.RATED_FLOW_OUTSIDE_AIRFLOW": "Rated Flow (Outside Airflow)",
  "label.RATED_POWER_CHILLED_WATER": "Rated Power (Chilled Water)",
  "label.RATED_FLOW_CHILLED_WATER": "Rated Flow (Chilled Water)",
  "label.AGE": "Age",
  "label.DESIGN_SUPPLY_FLOW_RATE": "Design Supply Flow Rate (L/s)",
  "label.SERVING_TO": "Serving to",
  "label.SERVED_BY": "Served by",
  "action.DOWNLOAD": "Download",
  "label.DOWNLOAD_FILE": "Download File",
  "label.DOWNLOAD_TEXT": "Do you want to download?",
  "label.UPDATE_DATE": "Upload date",
  "label.UPLOADED_BY": "Uploaded by",
  "label.CLOSE_EDIT": "Close Edit",
  "label.UPDATE_DOCUMENT": "Update Document",
  "label.ADD_DOCUMENT": "Add Document",
  "title.ASSET_NOTES": "Asset Notes",
  "label.MAX_NUM_NOTES_REACHED":
    "Note cannot be created. Maximum number of notes reached.",
  "action.ADD_NOTE": "Add Note",
  "label.MAX_5_NOTES": "Max 5 notes",
  "label.CREATED_BY": "Created by",
  "label.CREATED_ON": "Created on",
  "label.EXPIRES_ON": "Expires on",
  "label.NO_EXPIRATION": "No expiration",
  "title.DELETE_ASSET_NOTE": "Delete Asset Note",
  "label.DELETE_NOTE_CONFIRMATION":
    "Are you sure you want to delete this note? This action cannot be undone.",
  "action.DELETE": "Delete",
  "title.ADD_ASSET_NOTE": "Add Asset Note",
  "label.ADD_NOTE_BRIEF": "Write a title and brief description.",
  "label.SET_NOTE_EXPIRY": "Set Note Expiry",
  "label.YEAR": "Year",
  "label.MONTH": "MONTH",
  "label.NOTE_TITLE": "Note Title",
  "label.ERROR_EMPTY_TITLE": "Title cannot be empty",
  "label.NOTE_DESCRIPTION": "Note Description",
  "label.ERROR_EMPTY_DESCRIPTION": "Description cannot be empty",
  "label.ADD_NOTE_DESCRIPTION_BRIEF": "Write a brief note to show...",
  "label.CLICK_TO_UPLOAD": "Click to upload",
  "label.OR_DRAG_AND_DROP": "or drag and drop",
  "label.ERROR_SIZE": "Size not supported",
  "label.ERROR_FORMAT": "Format not supported",
  "label.CHOOSE_FILES_TO_UPLOAD": "Choose files to upload",
  "label.FROM_DEVICE": "From Device",
  "label.EXTERNAL_LINK": "External Link",
  "label.FROM_PARA": "From Para",
  "option.ASSET": "Asset",
  "option.ASSET_GROUP": "Asset Group",
  "option.ASSET_CLASS": "Asset Class",
  "option.ASSET_TYPE": "Asset Type",
  "option.KPI": "KPI",
  "option.AVOIDABLE_COST": "Avoidable Cost",
  "option.POTENTIAL_SAVING": "Potential Saving",
  "option.HIGH": "High",
  "option.LOW": "Low",
  "option.INACTIVE": "Inactive",
  "label.CAPACITY": "Capacity",
  "label.DELETE_FILE": "Delete File",
  "label.DELETE_TEXT": "Are you sure you want to delete this file?",
  "label.LOCATION": "Location",
  "label.SEARCH_LINK_FROM_PARA": "Search for a link from Para",
  "label.ADD_EXTERNAL_LINK": "Add External link",
  "label.SELECT_DATE": "Select Date",
  "error.INVALID_PAST_DATE":
    "The selected date cannot be earlier than today's date. Please choose a valid date.",
  "label.REACHED_DOCUMENTS_LIMIT": "Reached Documents Limit",
  "label.REACHED_DOCUMENTS_LIMIT_MESSAGE":
    "Only 20 documents can be added to a specific asset. Please delete a document from the existing list to permit adding a new one.",
  "label.OK": "OK",
  "title.WORK_ORDERS": "Work Orders:",
  "action.WORK_ORDERS": "Work Orders",
  "label.CLOSED": "Closed",
  "label.HOLD": "Hold",
  "label.WORK_IN_PROGRESS": "Work In Progress",
  "label.WORK_ORDERS_STATUS": "Work Orders Status",
  "label.INDOOR": "Indoor",
  "label.OUTDOOR": "Outdoor",
  "label.OCC_WELL": "Occ. Well.",
  "label.ENERGY_RECOM": "Energy Recom.",
  "label.WIND_SPEED": "Wind Speed",
  "label.ACTIVE_ALARMS_COUNT": "Active Alarms Count",
  "label.ACTIVE_RECOMMENDATIONS_COUNT": "Active Recommendations Count",
  "option.TRIGGERED": "Triggered",
  "option.NOT_TRIGGERED": "Not Triggered",
  "label.TOTAL_SUMMARY": "Total Summary",
  "label.WEEKLY_SUMMARY": "Weekly Summary",
  "label.MONTHLY_SUMMARY": "Monthly Summary",
  "label.DURATION_OF_FAULT": "Duration of Fault",
  "label.LIVE_STATUS": "Live Status",
  "label.DATE_OF_ACTIVATION": "Date of Activation",
  "label.LAST_OCCURENCE": "Last Occurence",
  "label.ACTIVE": "Active",
  "label.NOT_ACTIVE": "Not Active",
  "action.SEND_EMAIL": "Send Email",
  "action.COPY_LINK": "Copy Link",
  "label.NORMAL": "Normal",
  "label.INPUT_AND_OUTPUT": "Input & Output",
  "label.INPUT": "Input",
  "label.OUTPUT": "Output",
  "action.COPIED": "Copied",
  "action.ASSET_TABLE": "Asset Table",
  "label.CONNECTED_ASSETS_TOOLTIP": "Connected Assets",
  "label.NON_CONNECTED_ASSETS_TOOLTIP": "Non Connected Assets",
  "label.CONNECTED_ASSETS_TOOLTIP_CONTENT":
    "Connected Asset: An asset that generates real-time operational data and is integrated to Para platform",
  "label.NON_CONNECTED_ASSETS_TOOLTIP_CONTENT":
    "Non-connected Asset: An asset that doesn't generate real-time operational data or can’t be integrated due existing infrastructure.",
  "label.IOT_SENSOR": "IOT Sensor",
  "label.ELECTRICAL": "Electrical",
  "label.SPACES": "Spaces",
  "label.FAULTY_SENSOR_COUNT": "Faulty Sensor Count",
  "title.SPACE": "Space",
  "title.SYSTEM": "System",
  "label.AVERAGE_OCCUPANCY": "Average Occupancy",
  "label.FROM_LAST_WEEK": "from last week",
  "label.INCREASED_BY": "increased by",
  "label.DECREASED_BY": "decreased",
  "header.ASSET_WITH_HIGHEST_MISSED_SAVING": "Asset with Highest Missed Saving",
  "header.ASSET_WITH_HIGHEST_POTENTIAL_SAVING":
    "Asset with Highest Potential Saving",
  "header.ASSET_WITH_HIGHEST_FAULT_DURATION":
    "Asset with Highest Fault Duration",
  "header.ASSET_WITH_HIGHEST_OPERATING_DURATION":
    "Asset with Highest Operating Duration",
  "message.ASSETS_WITH_NEGATIVE_IMPACT":
    "These assets have negative impact on entire floor spending",
  "message.ASSETS_WITH_GREATEST_POTENTIAL":
    "These assets have greatest potential to reduce spending",
  "message.ASSETS_WITH_NEGATIVE_IMPACT_KPI":
    "These assets have negative impact on entire floor KPI",
  "title.ELECTRICAL": "Electrical",
  "title.LIGHTING_PANEL": "Lighting Panel",
  "title.OTHER_LOAD_PANEL": "Other Load Panel",
  "title.CONNECTED_ASSET_COUNT": "Connected Asset Count",
  "label.INACTIVE": "Inactive",
  "label.OF_UTILIZATION": "% of Utilization",
  "label.MAXIMUM_OCCUPANCY": "Maximum Occupancy",
  "label.AHU_TYP2_F3_OPERATING_DURATION": "AHU-TYP2-F3 Operating Duration",
  "label.AHU_TYP3_F3_OPERATING_DURATION": "AHU-TYP3-F3 Operating Duration",
  "label.AVG_PIM_OPERATING_DURATION": "Avg. PIM Operating Duration",
  "label.AVG_FCU_OPERATING_DURATION": "Avg. FCU Operating Duration",
  "label.DURATION_AVG_PANEL_LOWE_INTENSITY_ABOVE_80":
    "Duration (Avg. Panel Power Intensity above 80%)",
  "label.LUX_LEVEL_WORKING_HOURS": "Lux Level (Working Hours)",
  "label.ACTIVE_AHU_COUNT": "Active AHU Count",
  "label.ACTIVE_PIM_COUNT": "Active PIM Count",
  "label.ACTIVE_FCU_COUNT": "Active FCU Count",
  "label.AVERAGE_PANEL_POWER_INTENSITY": "Average Panel Power Intensity %",
  "label.FDD_FLOOR": "FDD:",
  "action.FDD_TABLE": "FDD Table",
  "label.POTENTIAL_SAVINGS_TOOLTIP_CONTENT": "FDD:",
  "label.FORECAST_FOR_REMAINING_YEAR": "Forecasted for remaining year",
  "label.ACTIVE_FDD": "Active FDD",
  "label.WORST_PERFORMING_ASSETS": "Worst Performing Assets",
  "label.BEST_PERFORMING_ASSETS": "Best Performing Assets",
  "label.SORT_BY": "Sort by",
  "label.SHOW": "Show",
  "label.KPI": "KPI",
  "label.TOP_10": "Top 10",
  "label.TOP_20": "Top 20",
  "label.ALL_ASSETS": "All Assets",
  "label.ACTION_ACTIVITIES": "Action Activities",
  "action.INSERT": "Insert",
  "dateFilter.YESTERDAY": "Yesterday",
  "label.AM_FLOOR_FDD_TOOLTIP":
    "Calculated from FDD rules. Combined for electricity and chilled water using blended rate",
  "label.FDD_BY_PRIORITY": "FDD by Priority",
  "label.KPIS": "KPIs",
  "label.KPI_OVERALL": "Overall",
  "label.KPI_OVERALL_TOOLTIP": "Assets Overall Effectiveness KPI",
  "label.KPI_PROP_IMP": "Prop. Imp.",
  "label.KPI_PROP_IMP_TOOLTIP": "Assets Property Impact KPI",
  "label.KPI_HEALTH": "Health",
  "label.KPI_HEALTH_TOOLTIP": "Assets Health KPI",
  "label.KPI_AVAIL": "Avail.",
  "label.KPI_AVAIL_TOOLTIP": "Assets Availability KPI",
  "label.KPI_UTILIZ": "Utiliz.",
  "label.KPI_UTILIZ_TOOLTIP": "Assets Utilization KPI",
  "label.KPI_PERFOR": "Perfor.",
  "label.KPI_PERFOR_TOOLTIP": "Assets Performance KPI",
  "label.KPI_COMFORT": "Comfort",
  "label.KPI_COMFORT_TOOLTIP": "Assets Comfort Index KPI",
  "title.WORK_ORDERS_BY_SYSTEM": "Work Orders by System",
  "label.OTHER": "Other",
  "title.KPI_BY_PRIORITY": "KPI by priority",
  "message.SEARCH_COLUMN_OPTIONS": "Search column options",
  "label.MESSAGE_TYPE": "Message type",
  "label.ACTION_ACTIVITY": "Action Activity",
  "label.ASSET_NOTE": "Asset Note",
  "label.DELETED_ON": "Deleted on",
  "label.DELETE": "Deleted",
  "label.CREATE": "Created",
  "label.EXPIRE": "Expired",
};

export default EN;
