import classNames from "classnames";
import React from "react";

const Calendar = ({
  color = "#8c8c8c",
  className = undefined,
  iconScale = 1,
  icon = true,
}) => {
  const stroke = color ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".74px"
        d="M.52,3.74H10.53M2.74,.4V1.51M8.3,.4V1.51M2.18,5.96h1.11m-1.11,2.22h1.11m1.67-2.22h1.11m-1.11,2.22h1.11m1.67-2.22h1.11m-1.11,2.22h1.11m-6.56,2.22h6.45c.62,0,.93,0,1.17-.12,.21-.11,.38-.28,.49-.49,.12-.24,.12-.55,.12-1.17V3.29c0-.62,0-.93-.12-1.17-.11-.21-.28-.38-.49-.49-.24-.12-.55-.12-1.17-.12H2.3c-.62,0-.93,0-1.17,.12-.21,.11-.38,.28-.49,.49-.12,.24-.12,.55-.12,1.17v5.34c0,.62,0,.93,.12,1.17,.11,.21,.28,.38,.49,.49,.24,.12,.55,.12,1.17,.12Z"
      />
    </svg>
  );
};

export default Calendar;
