import CollapsibleContainer from "atomic-components/molecules/CollapsibleContainer/CollapsibleContainer";
import TabsView from "atomic-components/organisms/TabsView";
import { useTranslate } from "modules/language";
import type { Tab } from "atomic-components/organisms/TabsView";
import { useState } from "react";
import TotalElectricityBreakdown from "./TotalElectricityBreakdown/TotalElectricityBreakdown";
import CommonElectricityBreakdown from "./CommonElectricityBreakdown/CommonElectricityBreakdown";
import FloorsElectricityBreakdown from "./FloorsElectricityBreakdown/FloorsElectricityBreakdown";
import ElectricityBreakdownAssumptions from "./ElectricityBreakdownAssumptions/ElectricityBreakdownAssumptions";
import BreakdownTitle from "../BreakdownTitle/BreakdownTitle";

enum SelectedTabEnum {
  TOTAL,
  COMMON,
  ALL_FLOORS,
}

const tabComponents = {
  [SelectedTabEnum.TOTAL]: <TotalElectricityBreakdown />,
  [SelectedTabEnum.COMMON]: <CommonElectricityBreakdown />,
  [SelectedTabEnum.ALL_FLOORS]: <FloorsElectricityBreakdown />,
};

type ElectricityBreakdownProps = {
  scopes: string[];
};

const ElectricityBreakdown = ({ scopes }: ElectricityBreakdownProps) => {
  const [selectedTabKey, setSelectedTabKey] = useState<SelectedTabEnum>(
    SelectedTabEnum.TOTAL
  );

  const t = useTranslate();

  const tabs: Tab<SelectedTabEnum>[] = [
    {
      key: SelectedTabEnum.TOTAL,
      text: t("label.TOTAL"),
    },
    {
      key: SelectedTabEnum.COMMON,
      text: t("label.COMMON"),
    },
    // tech-1448
    // {
    //   key: SelectedTabEnum.ALL_FLOORS,
    //   text: t("label.ALL_FLOORS"),
    // },
  ];

  return (
    <CollapsibleContainer
      titleFontWeight="normal"
      title={<BreakdownTitle label={t("label.ELECTRICITY")} scopes={scopes} />}
      hasExtraSpacing
    >
      <TabsView
        tabs={tabs}
        selectedTabKey={selectedTabKey}
        setSelectedTabKey={setSelectedTabKey}
        skin="sleek"
        size="small"
      >
        {tabComponents[selectedTabKey]}
      </TabsView>
      <ElectricityBreakdownAssumptions />
    </CollapsibleContainer>
  );
};

export default ElectricityBreakdown;
