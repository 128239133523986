import classNames from "classnames";
import type { SVGAttributes } from "react";

type UploadIconProps = SVGAttributes<SVGElement> & {
  icon?: boolean;
  iconScale?: number;
  color?: string;
};

const UploadIcon = ({
  icon = true,
  iconScale = 1,
  color = "#475467",
  className,
  ...props
}: UploadIconProps) => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, { "para-icon": icon })}
      style={{
        transform: `scale(${iconScale})`,
      }}
      {...props}
    >
      <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
      <rect
        x="3"
        y="3"
        width="40"
        height="40"
        rx="20"
        stroke="#F9FAFB"
        strokeWidth="6"
      />
      <path
        d="M19.668 26.3333L23.0013 23M23.0013 23L26.3346 26.3333M23.0013 23V30.5M29.668 26.9524C30.6859 26.1117 31.3346 24.8399 31.3346 23.4167C31.3346 20.8854 29.2826 18.8333 26.7513 18.8333C26.5692 18.8333 26.3989 18.7383 26.3064 18.5814C25.2197 16.7374 23.2133 15.5 20.918 15.5C17.4662 15.5 14.668 18.2982 14.668 21.75C14.668 23.4718 15.3642 25.0309 16.4904 26.1613"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadIcon;
