import { useEffect, useMemo, useRef, useState } from "react";
import { SingleEvent } from "../../core/helpers/events";
import { getLiveListener } from "../helpers/liveListener";
import { getIntervals } from "../helpers/intervals";
import useFullDateFilterState from "./useFullDateFilterState";
import useBuildingData from "./useBuildingData";
import { useLiveListener } from "modules/building/helpers/liveListener";

const TimeStampSelector = function ({
  interval,
  timezone,
  fromTs,
  toTs,
  initSelectedTimestamp,
}) {
  const self = this;

  const onChangeEvent = new SingleEvent();

  const liveListener = getLiveListener({
    interval,
  });

  const intervals = getIntervals({
    from: fromTs,
    to: toTs,
    timezone,
    interval,
  });

  let lastTimestamp =
    Math.min(intervals.toTs, liveListener.getLastTimestamp()) -
    intervals.interval;

  let isPast = lastTimestamp === intervals.toTs - intervals.interval;

  let selectedTimestamp =
    intervals.getExactTimestampIndex(initSelectedTimestamp) >= 0
      ? initSelectedTimestamp
      : Math.min(
          intervals.timestamps[intervals.timestamps.length - 1],
          lastTimestamp
        );

  let removeLiveListener = isPast
    ? null
    : liveListener.addListener(({ timestamp }) => {
        timestamp -= interval;
        if (intervals.fromTs <= timestamp && timestamp < intervals.toTs) {
          if (selectedTimestamp === lastTimestamp) {
            selectedTimestamp = timestamp;
          }
          lastTimestamp = timestamp;
          onChangeEvent.triggerEvent({
            selectedTimestamp,
            lastTimestamp,
          });
        }
        isPast = lastTimestamp === intervals.toTs - intervals.interval;
        if (isPast) {
          removeLiveListener();
          removeLiveListener = null;
        }
      });

  const setSelectedTimestamp = (timestamp) => {
    if (timestamp > lastTimestamp) {
      timestamp = lastTimestamp;
    } else if (timestamp < intervals.fromTs) {
      timestamp = intervals.fromTs;
    }
    selectedTimestamp = timestamp;
    onChangeEvent.triggerEvent(selectedTimestamp);
  };
  const useSelectedTimestampState = () => {
    /** @type {number, import('types/utils').Setter<number>} */

    return {
      lastTimestamp,
      selectedTimestamp,
      selectedFromTimestamp: selectedTimestamp,
      selectedToTimestamp: selectedTimestamp + interval,
      setSelectedTimestamp,
      selectedTimestampIndex:
        intervals.getExactTimestampIndex(selectedTimestamp),
      setSelectedTimestampIndex: (index) => {
        const local = intervals.timestamps[index];
        if (local != null) {
          setSelectedTimestamp(intervals.timestamps[index]);
        }
      },
      intervals,
      interval,
      length:
        lastTimestamp < intervals.toTs
          ? intervals.getExactTimestampIndex(lastTimestamp) + 1
          : intervals.timestamps.length,
    };
  };

  self.useState = useSelectedTimestampState;
  self.getSelectedTimestamp = () => selectedTimestamp;

  let numberOfLinks = 0;
  self.link = (onChange) => {
    numberOfLinks++;
    let removeListener;
    if (onChange) {
      removeListener = onChangeEvent.addListener(onChange);
    }
    return () => {
      removeListener && removeListener();
      numberOfLinks--;
      if (numberOfLinks === 0) {
        removeLiveListener && removeLiveListener();
        onChangeEvent.destroy();
        return true;
      }
      return false;
    };
  };
};

const timestampSelectorsCache = {};
const TimeStampSelectorManager = function (onChange) {
  const self = this;

  let interval;
  let timezone;
  let fromTs;
  let toTs;

  let cacheKey;

  /** @type {TimeStampSelector} */
  let timestampSelector = {
    getSelectedTimestamp: () => null,
    unlink: () => false,
  };

  const unlinkTimestampSelector = () => {
    if (unlinkTimestampSelector.unlink?.()) {
      delete timestampSelectorsCache[cacheKey];
    }
  };

  const hydrate = ({
    interval: newInterval,
    timezone: newTimezone,
    fromTs: newFromTs,
    toTs: newToTs,
  }) => {
    if (
      interval === newInterval &&
      timezone === newTimezone &&
      fromTs === newFromTs &&
      toTs === newToTs
    ) {
      return;
    }

    interval = newInterval;
    timezone = newTimezone;
    fromTs = newFromTs;
    toTs = newToTs;

    const lastSelectedTimestamp = timestampSelector.getSelectedTimestamp();
    unlinkTimestampSelector();

    cacheKey = `${timezone}@${interval}[${fromTs}:${toTs}]`;
    timestampSelector =
      timestampSelectorsCache[cacheKey] ??
      (timestampSelectorsCache[cacheKey] = new TimeStampSelector({
        interval,
        timezone,
        fromTs,
        toTs,
        initSelectedTimestamp: lastSelectedTimestamp,
      }));

    unlinkTimestampSelector.unlink = timestampSelector.link(onChange);
  };

  self.hydrate = hydrate;
  self.useState = () => timestampSelector.useState();
  self.unmount = () => {
    unlinkTimestampSelector();
  };
};

const useFullSelectedTimestamp = () => {
  const [, setDummy] = useState();

  const { buildingData } = useBuildingData();
  const { defaultInterval: interval, timezone } = buildingData;

  const { fromTs, toTs } = useFullDateFilterState();

  const timestampSelectorManagerRef = useRef();
  const timestampSelectorManager =
    timestampSelectorManagerRef.current ??
    new TimeStampSelectorManager(() => setDummy({}));

  timestampSelectorManager.hydrate({
    interval,
    timezone,
    fromTs,
    toTs,
  });

  useEffect(() => timestampSelectorManager.unmount);

  return timestampSelectorManager.useState();
};

const useMappedSelectedTimestamp = (fromTs, toTs) => {
  const {
    interval,
    timezone,
    selectedTimestampIndex,
    setSelectedTimestamp,
    lastTimestamp,
  } = useFullSelectedTimestamp();

  const lastLowerTs = useLiveListener().getLowerLastTimestamp();

  return useMemo(() => {
    const intervals = getIntervals({
      from: fromTs,
      to: toTs,
      timezone,
      interval,
    });

    const selectedTimestamp = intervals.timestamps[selectedTimestampIndex];

    return {
      lastTimestamp,
      setSelectedTimestamp,
      selectedTimestamp,
      selectedTimestampIndex,
      selectedFromTimestamp: selectedTimestamp,
      selectedToTimestamp: selectedTimestamp + interval,
      isCurrentTimeStamp: lastLowerTs === selectedTimestamp,
    };
  }, [
    fromTs,
    interval,
    lastLowerTs,
    lastTimestamp,
    selectedTimestampIndex,
    setSelectedTimestamp,
    timezone,
    toTs,
  ]);
};

export default useFullSelectedTimestamp;
export { useMappedSelectedTimestamp };
