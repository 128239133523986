import { redirect, type LoaderFunction } from "react-router-dom";
import { createLink, normalizeRouteSegments } from "router/utils";
import { defaultPage } from "whitelabel-config";
import routesData from "./routesData";

const defaultRoute = routesData.find(({ url }) => url === defaultPage);

// redirect index page to defaultRoutPath
const indexPageMiddleware: LoaderFunction = ({ request, params }) => {
  return redirect(
    defaultRoute?.isBuildingDependent
      ? createLink({
          segments: normalizeRouteSegments({
            pathname: new URL(request.url).pathname,
            params,
          }),
          keepSearchParams: true,
          keepHash: true,
        })
      : defaultPage
  );
};

export default indexPageMiddleware;
