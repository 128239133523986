import { useMemo, useState } from "react";
import Sustainability from "svg-icons/Sustainability";
import Dashboard from "svg-icons/Dashboard";
import { useSelectedLanguageState, useTranslate } from "modules/language";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import {
  useAuthenticationState,
  logout,
  switchRole,
} from "modules/authentication/state/authentication.state";
import Loader from "atomic-components/atoms/Loader";
import { addToast } from "modules/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faSignOutAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import AssetManagement from "svg-icons/AssetManagement";
import { clearCache as telemetriesClearCache } from "modules/building/helpers/telemetriesLoader";
import { clearCache as timeseriesClearCache } from "modules/building/helpers/timeseriesLoader";
import { PageUrlEnum } from "router/enums";
import SideMenuItem from "./MainMenuItem/MainMenuItem";
import EnergyIntelligenceSubMenu from "./EnergyIntelligenceSubMenu/EnergyIntelligenceSubMenu";
import { useLayoutState } from "../LayoutProvider/LayoutProvider";
import { SEARCH_PARAM_KEY_TAB_KEY } from "modules/building/hooks/useRightTabKey";
import { SustainabilityRightTabEnum } from "modules/building/pages/SustainabilityPage/utils";
import { BuildingIcon, UserComfort } from "svg-icons";
import classNames from "classnames";
import {
  logoLink,
  hasLocalization,
  hasOnlyOnePageEnabled,
  enabledPages,
} from "whitelabel-config";

import s from "./MainMenu.module.scss";

const SideMenu = () => {
  const [isMenuHovered, setIsMenuHovered] = useState(false);
  const [{ loggingOut }, setLogoutState] = useState<{
    loggingOut?: boolean;
  }>({});
  const [{ clearingCache }, setClearCacheState] = useState<{
    clearingCache?: boolean;
  }>({});
  const { hideSecondarySideMenu } = useLayoutState();

  const t = useTranslate();

  const { user, roles } = useAuthenticationState() as {
    user: { username: string; isAdmin: boolean };
    roles: { name: string }[];
  };

  const isAdmin = useMemo(
    () => Boolean(roles?.find(({ name }) => name === "admin")),
    [roles]
  );

  const doLogout = () => {
    if (loggingOut) {
      return;
    }
    setLogoutState({ loggingOut: true });
    logout()
      .catch(() => {
        addToast({ messageKey: "toast.FAILED_TO_LOGOUT", variant: "danger" });
      })
      .finally(() => setLogoutState({ loggingOut: false }));
  };

  const doClearCache = () => {
    if (clearingCache) {
      return;
    }
    setClearCacheState({ clearingCache: true });
    Promise.all([telemetriesClearCache(), timeseriesClearCache()])
      .then(() => window.location.reload())
      .catch(() => {
        addToast({
          messageKey: "toast.FAILED_TO_CLEAR_CACHE",
          variant: "danger",
        });
      })
      .finally(() => setClearCacheState({ clearingCache: false }));
  };

  const {
    languageCode: selectedLanguageCode,
    setLanguageCode: setSelectedLanguageCode,
  } = useSelectedLanguageState();
  return (
    <nav
      className={classNames(s.sideMenu, {
        [s.staticSideMenu]: hasOnlyOnePageEnabled,
      })}
      onMouseEnter={() => {
        hideSecondarySideMenu();
        setIsMenuHovered(true);
      }}
      onMouseLeave={() => setIsMenuHovered(false)}
    >
      {logoLink ? (
        <span>
          <img
            className={s.sideMenuTopLogo}
            src={`/img/logos/${logoLink}`}
            alt="Logo"
          />
          <hr className={s.divider} />
        </span>
      ) : null}

      <div className={s.sideMenuTop}>
        <img
          className={s.sideMenuLogo}
          src="/img/left-menu/ParaLogo.svg"
          alt="Logo"
        />
        {isMenuHovered ? <h4>{t("title.PARA")}</h4> : null}
      </div>

      {/* Menu Items */}
      {hasOnlyOnePageEnabled ? null : (
        <div className={classNames(s.sideMenuMid)}>
          <div>
            <SideMenuItem
              icon={<Dashboard />}
              text={t("menu.DASHBOARD")}
              pageUrl={PageUrlEnum.Dashboard}
              disabled
            />

            {enabledPages.includes(PageUrlEnum.OccupantWellbeing) ? (
              <SideMenuItem
                icon={<UserComfort />}
                text={t("menu.OCCUPANT_WELLBEING")}
                pageUrl={PageUrlEnum.OccupantWellbeing}
              />
            ) : null}

            {enabledPages.includes(PageUrlEnum.Sustainability) ? (
              <SideMenuItem
                icon={<Sustainability />}
                text={t("menu.SUSTAINABILITY")}
                pageUrl={PageUrlEnum.Sustainability}
                searchParams={{
                  [SEARCH_PARAM_KEY_TAB_KEY]:
                    SustainabilityRightTabEnum.EMISSIONS,
                }}
              />
            ) : null}

            {enabledPages.includes(PageUrlEnum.OperationalSpending) ? (
              <EnergyIntelligenceSubMenu isMenuHovered={isMenuHovered} />
            ) : null}

            {enabledPages.includes(PageUrlEnum.AssetManagement) ? (
              <SideMenuItem
                icon={<AssetManagement />}
                text={t("menu.ASSET_MANAGEMENT")}
                pageUrl={PageUrlEnum.AssetManagement}
              />
            ) : null}

            {enabledPages.includes(PageUrlEnum.PortfolioManagement) ? (
              <SideMenuItem
                icon={<BuildingIcon iconScale={5} />}
                text={t("menu.PORTFOLIO_MANAGEMENT")}
                pageUrl={PageUrlEnum.PortfolioManagement}
              />
            ) : null}
          </div>
        </div>
      )}

      <div className={s.sideMenuMidBottomSpacer}></div>
      <div className={s.sideMenuBottom}>
        {/* Localization */}
        {hasLocalization ? (
          <div className={classNames(s.sideMenuLanguages)}>
            <button
              className={s.sideMenuLanguage}
              disabled={selectedLanguageCode === "EN"}
              onClick={() => setSelectedLanguageCode("EN")}
            >
              EN
            </button>
            /
            <button
              className={s.sideMenuLanguage}
              disabled={selectedLanguageCode === "AR"}
              onClick={() => setSelectedLanguageCode("AR")}
            >
              AR
            </button>
          </div>
        ) : null}

        {user ? (
          <InfoPopover
            as="button"
            className={s.sideMenuUserButton}
            addSpaceBefore={false}
            addSpaceAfter={false}
            message={
              <div className={s.userFloatingContainer}>
                <h4>{t("note.WELCOME_USER", { user: user.username })}</h4>
                {isAdmin ? (
                  <div className={s.userFloatingContainerAdminNote}>
                    {t("note.IS_ADMIN")}
                  </div>
                ) : null}
                {user.isAdmin && (
                  <div>
                    <button
                      onClick={() => {
                        switchRole(isAdmin ? "user" : "admin");
                      }}
                      className={s.switchButton}
                      disabled={false}
                    >
                      <FontAwesomeIcon icon={faExchangeAlt} fixedWidth />{" "}
                      <>
                        {t("action.SWITCH")} {isAdmin ? "User" : "Admin"}
                      </>
                    </button>
                  </div>
                )}
                <div>
                  <button
                    onClick={doLogout}
                    className={s.logOutButton}
                    disabled={loggingOut}
                  >
                    {loggingOut ? (
                      <>
                        <Loader
                          marginTop={0}
                          size={14}
                          display="inline-block"
                        />{" "}
                      </>
                    ) : (
                      <FontAwesomeIcon icon={faSignOutAlt} fixedWidth />
                    )}{" "}
                    {t("action.LOGOUT")}
                  </button>
                </div>
                <div>
                  <button
                    onClick={doClearCache}
                    className={s.clearCacheButton}
                    disabled={clearingCache}
                  >
                    {clearingCache ? (
                      <>
                        <Loader
                          marginTop={0}
                          size={14}
                          display="inline-block"
                        />{" "}
                      </>
                    ) : (
                      <FontAwesomeIcon icon={faTrash} fixedWidth />
                    )}{" "}
                    {t("action.CLEAR_LOCAL_CACHE")}
                  </button>
                </div>
              </div>
            }
            placement="right"
            floatingStyle={{ minWidth: "150px" }}
            portal
            light
          >
            <div className={s.sideMenuUserContainer}>
              <img
                className={s.sideMenuUserLogo}
                src="/img/user.svg"
                alt="user"
              />
              {isMenuHovered ? <h4>{user.username}</h4> : null}
            </div>
          </InfoPopover>
        ) : null}
      </div>
    </nav>
  );
};

export default SideMenu;
