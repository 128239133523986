import { uniq } from "lodash";
import { useEffect, useRef } from "react";
import intersectObjects from "../../helpers/intersectObjects";
import { useViewerState } from "../Provider/Provider";

const ClickControl = ({ onClick, disabled }) => {
  const mutableRef = useRef({});
  const { initialized, canvas, objects, camera } = useViewerState();
  mutableRef.current = {
    onClick,
    objects,
    camera,
  };

  useEffect(() => {
    if (!initialized || !canvas || disabled) {
      return;
    }

    /** @type {import('react').MouseEventHandler} */
    let clicklistener;
    canvas.parentElement.addEventListener(
      "click",
      (clicklistener = (e) => {
        const { objects, camera, onClick } = mutableRef.current;
        if (!objects?.length || !onClick) {
          return;
        }

        const intersectionResult = intersectObjects(e, objects, canvas, camera);
        if (!intersectionResult) {
          return;
        }

        const { intersections, mouse } = intersectionResult;

        if (onClick) {
          onClick({
            intersections,
            objects:
              uniq(intersections.map((intersection) => intersection.object)) ??
              [],
            mouse,
            modifiers: {
              ctrl: e.ctrlKey,
              alt: e.altKey,
              shift: e.shiftKey,
              meta: e.metaKey,
            },
            button: e.button,
          });
        }
      })
    );
    return () =>
      canvas.parentElement.removeEventListener("click", clicklistener);
  }, [initialized, canvas, disabled]);

  return null;
};

export default ClickControl;
