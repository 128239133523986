import ModalHeader from "atomic-components/molecules/Modal/ModalHeader";
import { useTranslate } from "modules/language";
import ModalFooter from "atomic-components/molecules/Modal/ModalFooter";
import Button from "atomic-components/atoms/Buttons/Button";
import { useModalState } from "atomic-components/molecules/Modal";
import ScenarioDetailsModal from "../ScenarioDetailsModal";
import RadioInput from "atomic-components/atoms/RadioInput";
import { useState } from "react";
import { SearchIcon } from "svg-icons";
import TextInput from "atomic-components/atoms/TextInput/TextInput";
import { useComplianceScenarios } from "modules/building/hooks/useComplianceScenarios";
import { type Scenario } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ComplianceTrackingPanel/Certificates/LeedOmCertificate/utils";

import s from "./LoadScenarioModal.module.scss";

type LoadScenarioModalProps = {
  scenarioData: Omit<Scenario, "id" | "title"> & {
    id?: string;
    title?: string;
  };
};

const LoadScenarioModal = ({ scenarioData }: LoadScenarioModalProps) => {
  const [selectedScenarioId, setSelectedScenarioId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const t = useTranslate();

  const { scenarios } = useComplianceScenarios();

  const { setModal, hideModal } = useModalState();

  const onClickCancel = () => {
    hideModal();
    setModal(<ScenarioDetailsModal scenario={scenarioData} />, {
      width: "large",
    });
  };

  const onClickLoad = () => {
    hideModal();
    setModal(
      <ScenarioDetailsModal
        scenario={
          scenarios.find(({ id }) => id === selectedScenarioId) || scenarioData
        }
      />,
      {
        width: "large",
      }
    );
  };

  const shownScenarios = scenarios.filter(({ title }) =>
    title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <ModalHeader
        color="primary"
        cancel={{
          text: t("action.CANCEL"),
        }}
      >
        <h3 className={s.headerText}>{t("action.LOAD")}</h3>
      </ModalHeader>
      <div>
        <div className={s.searchContainer}>
          <SearchIcon iconScale={4} />
          <TextInput
            type="search"
            placeholder={t("action.SEARCH")}
            value={searchQuery}
            onChange={setSearchQuery}
          />
        </div>

        {shownScenarios.map(({ id, title }) => {
          return (
            <div key={id} className={s.radioInputContainer}>
              <RadioInput
                name={title}
                value={id}
                checked={selectedScenarioId === id}
                onChange={() => setSelectedScenarioId(id)}
              >
                {title}
              </RadioInput>
            </div>
          );
        })}

        {shownScenarios.length === 0 ? (
          <h4>{t("message.NO_SCENARIOS")}</h4>
        ) : null}
      </div>
      <ModalFooter>
        <Button
          skin="secondary"
          onClick={onClickCancel}
          className={s.actionButton}
          size="large"
        >
          {t("action.CANCEL")}
        </Button>
        <Button
          skin="primary"
          onClick={onClickLoad}
          className={s.actionButton}
          disabled={!selectedScenarioId}
          size="large"
        >
          {t("action.LOAD")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default LoadScenarioModal;
