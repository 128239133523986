import type { SVGAttributes } from "react";

type AssetManagementProps = SVGAttributes<SVGElement>;

const AssetManagement = (props: AssetManagementProps) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8131 9.54857H11.8373C11.3486 9.54857 10.9538 9.94338 10.9538 10.4321V25.4515C10.9538 25.9402 11.3486 26.335 11.8373 26.335H15.8131C16.3017 26.335 16.6966 25.9402 16.6966 25.4515V10.4321C16.6966 9.94338 16.3017 9.54857 15.8131 9.54857ZM24.3167 1.59711H20.341C19.8523 1.59711 19.4575 1.99193 19.4575 2.48061V25.4515C19.4575 25.9402 19.8523 26.335 20.341 26.335H24.3167C24.8054 26.335 25.2002 25.9402 25.2002 25.4515V2.48061C25.2002 1.99193 24.8054 1.59711 24.3167 1.59711ZM7.30942 17.5H3.33369C2.84501 17.5 2.4502 17.8948 2.4502 18.3835V25.4515C2.4502 25.9402 2.84501 26.335 3.33369 26.335H7.30942C7.7981 26.335 8.19291 25.9402 8.19291 25.4515V18.3835C8.19291 17.8948 7.7981 17.5 7.30942 17.5Z"
        fill="#F2F6FF"
      />
    </svg>
  );
};

export default AssetManagement;
export type { AssetManagementProps };
