import { useEditableParamsState } from "../pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import {
  useViewData,
  useViewState,
} from "../providers/ViewProvider/ViewProvider";
import useBuildingData from "./useBuildingData";
import useSelectedEntity from "./useSelectedEntity";
import { useMergedNotifier } from "helpers/getNotifier";
import type { LinkedTelemetry } from "../helpers/LinkedTelemetriesComputer";
import getRatio from "helpers/getRatio";
import { useMemoedLinkedData } from "./useLinkedData";
import { useUtilsEmissions } from "./useUtilsEmissions";

const convertToKWHT = (telemetry: LinkedTelemetry) => {
  return (telemetry.data[0]?.value ?? NaN) * telemetry.days * 24;
};

const useChilledWaterEmissions = () => {
  const { buildingData } = useBuildingData();
  const selectedEntity = useSelectedEntity(buildingData);
  const {
    state: { electricityEmissionIntensity, chillerPlantCOP },
    Notifier: EPNotifier,
  } = useEditableParamsState();

  const { telemetries, Notifier: TNotifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        telemetries: [
          {
            telemetryName: "monthlyChilledWater",
          },
        ],
      },
    ],
    [selectedEntity.id]
  );

  const { monthlyChilledWater } = telemetries[selectedEntity.id];
  const totalChilledWaterEmissions =
    getRatio(
      (electricityEmissionIntensity.value ?? NaN) *
        (monthlyChilledWater.data[0]?.value ?? NaN),
      chillerPlantCOP.value
    ) ?? NaN;

  const Notifier = useMergedNotifier(TNotifier, EPNotifier);

  /* EMISSIONS BY YEAR */

  const { fromTs } = useViewState();
  const {
    transformYearEmissions,
    getValues,
    yearFromTimeStamp,
    calculateFromAndTo,
  } = useUtilsEmissions();

  const yearSelected = yearFromTimeStamp(fromTs);
  const timeSelected = calculateFromAndTo(yearSelected);

  const { telemetries: telemetriesByYear } = useMemoedLinkedData(
    timeSelected.firstDay,
    timeSelected.lastDay,
    () => {
      return [
        {
          entityId: selectedEntity.id,
          telemetries: [
            {
              telemetryName: "monthlyChilledWater",
            },
          ],
        },
      ];
    },
    [selectedEntity.id]
  );

  const chilledWaterByYearFinal = transformYearEmissions(
    telemetriesByYear[selectedEntity.id].monthlyChilledWater.data
  );

  const chilledWaterByYear = getValues(chilledWaterByYearFinal);

  const calculateTotalChilledWaterEmissionsByYear = (
    yearlyChilledWater: number[]
  ) => {
    return yearlyChilledWater.map(
      (item) =>
        getRatio(
          (electricityEmissionIntensity.value ?? NaN) * item,
          chillerPlantCOP.value
        ) ?? NaN
    );
  };

  const totalChilledWaterEmissionsByYear =
    calculateTotalChilledWaterEmissionsByYear(chilledWaterByYear);

  return {
    totalChilledWaterEmissions,
    totalChilledWaterEmissionsByYear,
    Notifier,
  };
};

export { useChilledWaterEmissions, convertToKWHT };
