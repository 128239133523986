import { Suspense } from "react";
import { createRoutesFromElements, Route } from "react-router-dom";
import Layout from "modules/layout/components/Layout/Layout";
import Toasts from "modules/toast/components/Toasts";
import { GlobalLoader } from "modules/global-loader";
import AuthGuard from "./AuthGuard";
import routesData, { ErrorPage } from "./routesData";
import indexPageMiddleware from "./indexPageMiddleware";
import { getPageRoutePathSegments } from "router/utils";
import { ModalProvider } from "atomic-components/molecules/Modal";

const routes = createRoutesFromElements(
  <Route
    errorElement={
      <Suspense>
        <ErrorPage />
      </Suspense>
    }
    element={
      <ModalProvider>
        <AuthGuard />
        <Toasts />
        <GlobalLoader />
      </ModalProvider>
    }
  >
    <Route index loader={indexPageMiddleware} />
    <Route element={<Layout />}>
      {routesData.map(({ url, element: Element, isBuildingDependent }) => (
        <Route
          key={url}
          path={
            isBuildingDependent
              ? getPageRoutePathSegments({
                  pageUrl: url,
                })
              : url
          }
          element={
            <Suspense>
              <Element />
            </Suspense>
          }
        />
      ))}
    </Route>
  </Route>
);

export default routes;
