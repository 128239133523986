import withIconProps from "helpers/withIconProps";

const MaximizeIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 3C14.25 2.58579 14.5858 2.25 15 2.25H21C21.4142 2.25 21.75 2.58579 21.75 3V9C21.75 9.41421 21.4142 9.75 21 9.75C20.5858 9.75 20.25 9.41421 20.25 9V3.75H15C14.5858 3.75 14.25 3.41421 14.25 3Z"
        fill="#C3C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15V20.25H9C9.41421 20.25 9.75 20.5858 9.75 21C9.75 21.4142 9.41421 21.75 9 21.75H3C2.58579 21.75 2.25 21.4142 2.25 21V15C2.25 14.5858 2.58579 14.25 3 14.25Z"
        fill="#C3C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5303 2.46967C21.8232 2.76256 21.8232 3.23744 21.5303 3.53033L14.5303 10.5303C14.2374 10.8232 13.7626 10.8232 13.4697 10.5303C13.1768 10.2374 13.1768 9.76256 13.4697 9.46967L20.4697 2.46967C20.7626 2.17678 21.2374 2.17678 21.5303 2.46967Z"
        fill="#C3C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5303 13.4697C10.8232 13.7626 10.8232 14.2374 10.5303 14.5303L3.53033 21.5303C3.23744 21.8232 2.76256 21.8232 2.46967 21.5303C2.17678 21.2374 2.17678 20.7626 2.46967 20.4697L9.46967 13.4697C9.76256 13.1768 10.2374 13.1768 10.5303 13.4697Z"
        fill="#C3C4C4"
      />
    </svg>
  );
});

export default MaximizeIcon;
