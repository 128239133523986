import withIconProps from "helpers/withIconProps";

const FloorsIcon = withIconProps((props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.66666 14.1667L9.99999 18.3334L18.3333 14.1667M1.66666 10.0001L9.99999 14.1667L18.3333 10.0001M9.99999 1.66675L1.66666 5.83341L9.99999 10.0001L18.3333 5.83341L9.99999 1.66675Z"
        stroke="#EDF0F8"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default FloorsIcon;
