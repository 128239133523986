import { Nullable } from "types/utils";
import { TelemetryRequest } from "./useLinkedData";
import { BucketsConfigKeys } from "../helpers/createLinkedTelemetriesBucketer";
import {
  AggregationKeys,
  RequestExtraFilter,
} from "../helpers/createLinkedTelemetriesAggregator";

export type SegmentationBucketsParams = {
  key: BucketsConfigKeys;
  entityId?: string;
  telemetry: TelemetryRequest;
  alias?: string;
};

export enum OutputStructureEnum {
  Default = "Default",
  BuiltIn = "BuiltIn",
  SegmentationBucketsTelemetryAggregation = "SegmentationBucketsTelemetryAggregation",
}

type OutputStructureKeys =
  | "entity"
  | "telemetry"
  | "segmentation"
  | "aggregation"
  | "buckets";
export const outputStructures: Record<
  OutputStructureEnum,
  Nullable<OutputStructureKeys[]>
> = {
  [OutputStructureEnum.BuiltIn]: null,
  [OutputStructureEnum.SegmentationBucketsTelemetryAggregation]: [
    "segmentation",
    "buckets",
    "telemetry",
    "aggregation",
  ],
  [OutputStructureEnum.Default]: [
    "segmentation",
    "entity",
    "telemetry",
    "buckets",
    "aggregation",
  ],
};

export const defaultOutputStructure = OutputStructureEnum.Default;

export type SegmentationRequest = {
  key?: string;
  buckets: (SegmentationBucketsParams | BucketsConfigKeys)[];
  alias: string;
  aggregations: AggregationKeys[];
  outputStructure?: OutputStructureEnum;
} & RequestExtraFilter;
