import ComingSoon from "atomic-components/atoms/ComingSoon";

import s from "./BottomPanel.module.scss";

type ShownBottomPanel<KEY extends string> = {
  type: KEY;
  entityId: string;
};

type BottomPanelType = {
  type: string;
  Component: React.ComponentType<{ entityId: string }>;
};

type BottomPanelProps<KEY extends string> = {
  bottomPanelConfig: ShownBottomPanel<KEY>;
  bottomPanels: BottomPanelType[];
};

const BottomPanel = <KEY extends string>({
  bottomPanelConfig,
  bottomPanels,
}: BottomPanelProps<KEY>) => {
  const Component = bottomPanels.find(
    (bottomPanel) => bottomPanel.type === bottomPanelConfig.type
  )?.Component;
  return (
    <div className={s.bottomPanel}>
      {Component ? (
        <Component entityId={bottomPanelConfig.entityId} />
      ) : (
        <ComingSoon />
      )}
    </div>
  );
};

export default BottomPanel;
export type { BottomPanelProps, ShownBottomPanel, BottomPanelType };
