import withIconProps from "helpers/withIconProps";

const NotesIcon = withIconProps(({ color, ...props }) => {
  const currentColor = color ?? "currentcolor";

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.02719 14.8061C3.41789 15.1968 3.94779 15.4163 4.50033 15.4163L9.83366 15.4163C10.0326 15.4163 10.2233 15.3373 10.364 15.1967L14.364 11.1967C14.5046 11.056 14.5837 10.8653 14.5837 10.6663L14.5837 3.66634C14.5837 3.11381 14.3642 2.5839 13.9735 2.1932C13.5828 1.8025 13.0529 1.58301 12.5003 1.58301L4.50033 1.58301C3.94779 1.58301 3.41789 1.8025 3.02719 2.1932C2.63649 2.5839 2.41699 3.11381 2.41699 3.66634L2.41699 13.333C2.41699 13.8855 2.63649 14.4154 3.02719 14.8061ZM4.50033 13.9163C4.34562 13.9163 4.19724 13.8549 4.08785 13.7455C3.97845 13.6361 3.91699 13.4877 3.91699 13.333L3.91699 3.66634C3.91699 3.51163 3.97845 3.36326 4.08785 3.25386C4.19724 3.14447 4.34562 3.08301 4.50033 3.08301L12.5003 3.08301C12.655 3.08301 12.8034 3.14447 12.9128 3.25386C13.0222 3.36326 13.0837 3.51163 13.0837 3.66634L13.0837 10.3557L9.523 13.9163L4.50033 13.9163Z"
        fill={currentColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83366 15.4163C10.2479 15.4163 10.5837 15.0806 10.5837 14.6663V11.4163L13.8337 11.4163C14.2479 11.4163 14.5837 11.0806 14.5837 10.6663C14.5837 10.2521 14.2479 9.91634 13.8337 9.91634L9.83366 9.91634C9.41945 9.91634 9.08366 10.2521 9.08366 10.6663L9.08366 14.6663C9.08366 15.0806 9.41945 15.4163 9.83366 15.4163Z"
        fill={currentColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08364 5.99968C5.08364 6.41389 5.41943 6.74968 5.83364 6.74968L11.167 6.74968C11.5812 6.74968 11.917 6.41389 11.917 5.99968C11.917 5.58546 11.5812 5.24968 11.167 5.24968L5.83364 5.24968C5.41943 5.24968 5.08364 5.58546 5.08364 5.99968Z"
        fill={currentColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08364 8.99968C5.08364 9.41389 5.41943 9.74968 5.83364 9.74968H7.16697C7.58119 9.74968 7.91697 9.41389 7.91697 8.99968C7.91697 8.58546 7.58119 8.24968 7.16697 8.24968L5.83364 8.24968C5.41943 8.24968 5.08364 8.58546 5.08364 8.99968Z"
        fill={currentColor}
      />
    </svg>
  );
});

export default NotesIcon;
