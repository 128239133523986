import React, { useEffect, useState } from "react";
import "./GlobalLoader.css";
import Loader from "../../../../atomic-components/atoms/Loader";
import {
  addOnHideLoaderListener,
  addOnShowLoaderListener,
} from "../../state/global-loader.state";

const GlobalLoader = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const removeShowListener = addOnShowLoaderListener(() => {
      setShow(true);
    });
    const removeHideListener = addOnHideLoaderListener(() => {
      setShow(false);
    });
    return () => {
      removeShowListener();
      removeHideListener();
    };
  }, []);

  return show ? (
    <div className={"global-loader-container"}>
      <div>
        <Loader marginTop={0} />
      </div>
    </div>
  ) : null;
};

export default GlobalLoader;
