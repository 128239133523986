import React from "react";
import { useModelState } from "../../Model";
import PointsSideAnnotations from "modules/viewer/components/PointsSideAnnotations";

const EntitiesSideAnnotations = React.memo(
  ({
    annotations,
    defaultMappingName = "default",
    defaultPointName = "annotationCenter",
    defaultClassName = "main-tip",
    disabled,
    onHiddenCountChange,
  }) => {
    const { getEntityMappingByEntityId } = useModelState();
    return (
      <PointsSideAnnotations
        disabled={disabled}
        onHiddenCountChange={onHiddenCountChange}
        annotations={annotations.map(
          ({
            key,
            entityId,
            mappingName = defaultMappingName,
            pointName = defaultPointName,
            className = defaultClassName,
            Component,
          }) => {
            const entityMapping = getEntityMappingByEntityId(
              entityId,
              mappingName
            );
            const point = entityMapping?.points.find(
              ({ name }) => name === pointName
            );
            if (!point) {
              return null;
            }
            return {
              key,
              pointCoordinates: point.coordinates,
              className,
              children: <Component entityId={entityId} />,
            };
          }
        )}
      />
    );
  }
);

export default EntitiesSideAnnotations;
