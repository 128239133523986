import { Close } from "svg-icons";
import { useLayoutState } from "../LayoutProvider/LayoutProvider";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { useTranslate } from "modules/language";

import s from "./SecondarySideMenu.module.scss";

const SecondarySideMenu = () => {
  const t = useTranslate();
  const { secondarySideMenuContent, hideSecondarySideMenu } = useLayoutState();

  if (!secondarySideMenuContent) {
    return null;
  }

  return (
    <section className={s.secSideMenu}>
      <div>
        <StrippedButton
          className={s.closeBtn}
          title={t("action.CLOSE")}
          onClick={() => {
            hideSecondarySideMenu();
          }}
        >
          <Close color="var(--color-primary)" />
        </StrippedButton>
      </div>
      {secondarySideMenuContent}
    </section>
  );
};

export default SecondarySideMenu;
