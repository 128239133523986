import useEvent from "modules/building/hooks/useEvent";
import { createContext, useContext, useMemo, useState } from "react";
import {
  type ModelContextValue,
  type ModelProviderProps,
  type FocusedEntityOptions,
} from "./types";

const ModelContext = createContext<ModelContextValue | null>(null);

const ModelProvider = ({ children }: ModelProviderProps) => {
  const [onModelClick, useOnModelClickListener] = useEvent();
  const [focusedEntityOptions, setFocusedEntityOptions] =
    useState<FocusedEntityOptions | null>(null);
  const [rolledOverEntityId, setRolledOverEntityId] = useState<string | null>(
    null
  );
  const [selectedZoneId, setSelectedZoneId] = useState<string | null>("");

  const value = useMemo(
    () => ({
      onModelClick,
      useOnModelClickListener,
      focusedEntityOptions,
      setFocusedEntityOptions,
      rolledOverEntityId,
      setRolledOverEntityId,
      selectedZoneId,
      setSelectedZoneId,
    }),
    [
      focusedEntityOptions,
      onModelClick,
      rolledOverEntityId,
      selectedZoneId,
      useOnModelClickListener,
    ]
  );

  return (
    <ModelContext.Provider value={value}>{children}</ModelContext.Provider>
  );
};

const useModelState = () => {
  const context = useContext(ModelContext);

  if (!context) {
    throw new Error(
      "useModelState hook needs to be called within ViewProvider child components"
    );
  }

  return context;
};

export { ModelProvider, useModelState };
