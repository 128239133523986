import UnitValue from "atomic-components/atoms/UnitValue";
import KeyValue from "atomic-components/molecules/KeyValue";
import FloorsSlider from "atomic-components/organisms/FloorsSlider/FloorsSlider";
import { useMergedNotifier } from "helpers/getNotifier";
import getPerformanceKPI from "helpers/getPerformanceKPI";
import { performanceKPIColorTweenerAsHex } from "helpers/performanceKPIColorTweener";
import useBuildingData from "modules/building/hooks/useBuildingData";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";
import { useTranslate } from "modules/language";

type TotalElectricityBreakdownFloorsProps = { allFloorsConsumption?: number };

const TotalElectricityBreakdownFloors = ({
  allFloorsConsumption,
}: TotalElectricityBreakdownFloorsProps) => {
  const { buildingData } = useBuildingData();
  const { floors, unitsByName } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);
  const {
    state: { electricityEmissionIntensity },
    Notifier: EditableParamsNotifier,
  } = useEditableParamsState();

  // const { resolutions, Notifier: telemetriesNotifier } = useViewData(
  //   () => [
  //     ...floors
  //       .filter((floor) => floor.name === "F3")
  //       .map((floor) => ({
  //         entityId: floor.id,
  //         resolutionKey: "1month",
  //         telemetries: [
  //           { telemetryName: "electricity" },
  //           { telemetryName: "electricityTarget" },
  //         ],
  //       })),
  //   ],
  //   [floors, selectedEntity]
  // );

  const presumedFloorsEmissions =
    (allFloorsConsumption ?? NaN) * (electricityEmissionIntensity.value ?? NaN);
  const t = useTranslate();

  // tech-1448
  // const floorsLabelsColumn = {
  //   mainLabel: t("label.FLOOR") as string,
  //   values: [
  //     {
  //       value: (
  //         <b>
  //           <UnitValue
  //             value={presumedFloorsEmissions}
  //             unit={unitsByName.kgco2e}
  //           />
  //         </b>
  //       ),
  //     },
  //   ],
  // };

  // const floorsColumns = floors.map((floor) => {
  //   const { electricity, electricityTarget } = resolutions["1month"][
  //     floor.id
  //   ] ?? {
  //     electricity: { data: [{ value: NaN }] },
  //     electricityTarget: { data: [{ value: NaN }] },
  //   };
  //   const emissions =
  //     electricity.data[0]?.value * (electricityEmissionIntensity.value ?? NaN);
  //   const emissionsTarget =
  //     electricityTarget.data[0]?.value *
  //     (electricityEmissionIntensity.value ?? NaN);
  //   const titleColor = performanceKPIColorTweenerAsHex(
  //     getPerformanceKPI(emissionsTarget, emissions)
  //   );

  //   return {
  //     floor,
  //     titleColor,
  //     values: [
  //       {
  //         value: emissions,
  //       },
  //     ],
  //   };
  // });

  const Notifier = useMergedNotifier(
    // telemetriesNotifier,
    EditableParamsNotifier
  );

  return (
    <Notifier>
      {/* tech-1448 */}
      <KeyValue
        keyText={t("label.PRESUMED_FLOORS_EMISSIONS")}
        value={
          <>
            <UnitValue
              value={presumedFloorsEmissions}
              unit={unitsByName.kgco2e}
            />
          </>
        }
      />
      {/* tech-1448 */}
      {/* <FloorsSlider labelsColumn={floorsLabelsColumn} columns={floorsColumns} /> */}
    </Notifier>
  );
};

export default TotalElectricityBreakdownFloors;
