import React from "react";
import classNames from "classnames";

const RecommendationEnd = ({
  color = "var(--color-primary)",
  borderWidth = 1,
  borderColor = "white",
  className = "",
  style = {},
  iconScale = 1,
  icon = true,
}) => {
  color = color ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        ...style,
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 9 13"
    >
      <path
        d="M7.42999 6.94025L4.45999 11.9102L1.48999 6.94025L4.45999 1.97021L7.42999 6.94025Z"
        stroke={borderColor}
        strokeWidth={borderWidth}
        fill={color}
      />
    </svg>
  );
};

export default RecommendationEnd;
