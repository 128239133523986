import classNames from "classnames";

type DegreesProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  color?: string;
  strokeWidth?: number;
};

const Degrees = ({
  className,
  iconScale = 1,
  icon = true,
  color = "#0040FF",
  strokeWidth = 2,
}: DegreesProps) => (
  <svg
    className={classNames(className, { "para-icon": icon })}
    width="10"
    height="23"
    viewBox="0 0 10 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fontSize: `${iconScale * 100}%`,
    }}
  >
    <path
      d="M7 3.5V14.04C7.76257 14.4803 8.35855 15.1599 8.69552 15.9734C9.03249 16.7869 9.09161 17.6888 8.86371 18.5394C8.63581 19.3899 8.13362 20.1415 7.43505 20.6775C6.73647 21.2136 5.88054 21.5041 5 21.5041C4.11946 21.5041 3.26353 21.2136 2.56496 20.6775C1.86638 20.1415 1.3642 19.3899 1.1363 18.5394C0.908398 17.6888 0.967516 16.7869 1.30448 15.9734C1.64145 15.1599 2.23743 14.4803 3 14.04V3.5C3 2.96957 3.21072 2.46086 3.58579 2.08579C3.96086 1.71071 4.46957 1.5 5 1.5C5.53043 1.5 6.03914 1.71071 6.41422 2.08579C6.78929 2.46086 7 2.96957 7 3.5Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Degrees;
