import classNames from "classnames";
import React from "react";

const Temperature = ({ className, iconScale = 1, icon = true }) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill="#bcbcbb"
        d="M4.99,10.99h-.1c-.67-.02-1.29-.31-1.76-.79-.46-.49-.72-1.12-.72-1.78,0-.85,.42-1.64,1.11-2.12,.02-.01,.03-.03,.03-.05V1.56C3.55,.76,4.19,.12,4.99,.12s1.44,.65,1.44,1.44V6.25s.01,.04,.03,.05c.69,.48,1.11,1.27,1.11,2.12,0,.71-.28,1.37-.79,1.86-.49,.47-1.11,.72-1.78,.72h0ZM4.99,.62c-.51,0-.93,.42-.93,.93V6.24c0,.19-.09,.36-.25,.47-.56,.38-.89,1.02-.89,1.7,0,1.1,.89,2.03,1.99,2.07,.57,.02,1.11-.19,1.52-.58,.41-.39,.64-.93,.64-1.49,0-.68-.34-1.32-.89-1.7-.15-.1-.25-.28-.25-.47V1.55c0-.51-.42-.93-.93-.93h0Z"
      />
      <path
        fill="#50afcc"
        d="M5.75,7.1c-.2-.12-.33-.34-.33-.58V3.64c0-.24-.19-.44-.43-.45-.12,0-.23,.04-.31,.13-.08,.08-.13,.2-.13,.32v2.88c0,.24-.13,.46-.33,.58-.45,.27-.73,.74-.74,1.26-.02,.83,.64,1.53,1.47,1.55h.04c.39,0,.77-.15,1.05-.43,.29-.29,.46-.67,.46-1.08,0-.53-.29-1.03-.75-1.3h0Z"
      />
      <path
        fill="#bcbcbb"
        d="M8.57,1.04h-1.61c-.09,0-.17,.08-.17,.17s.08,.17,.17,.17h1.61c.09,0,.17-.08,.17-.17s-.07-.17-.17-.17Z"
      />
      <path
        fill="#bcbcbb"
        d="M7.82,1.77h-.85c-.09,0-.17,.08-.17,.17s.08,.17,.17,.17h.85c.09,0,.17-.08,.17-.17s-.08-.17-.17-.17Z"
      />
      <path
        fill="#bcbcbb"
        d="M8.57,2.84h-1.6c-.09,0-.17-.08-.17-.17s.08-.17,.17-.17h1.6c.09,0,.17,.08,.17,.17s-.08,.17-.17,.17Z"
      />
      <path
        fill="#bcbcbb"
        d="M7.82,3.23h-.85c-.09,0-.17,.08-.17,.17s.08,.17,.17,.17h.85c.09,0,.17-.08,.17-.17s-.08-.17-.17-.17Z"
      />
      <path
        fill="#bcbcbb"
        d="M8.57,4.3h-1.6c-.09,0-.17-.08-.17-.17s.08-.17,.17-.17h1.6c.09,0,.17,.08,.17,.17s-.08,.17-.17,.17Z"
      />
      <path
        fill="#bcbcbb"
        d="M7.82,4.7h-.85c-.09,0-.17,.08-.17,.17s.08,.17,.17,.17h.85c.09,0,.17-.08,.17-.17s-.08-.17-.17-.17Z"
      />
      <path
        fill="#bcbcbb"
        d="M8.57,5.77h-1.6c-.09,0-.17-.08-.17-.17s.08-.17,.17-.17h1.6c.09,0,.17,.08,.17,.17s-.08,.17-.17,.17Z"
      />
    </svg>
  );
};

export default Temperature;
