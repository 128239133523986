import isRealNumber from "helpers/isRealNumber";
import { useEditableParamsState } from "../pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import getRatio from "helpers/getRatio";

const KG_TO_LBS = 2.2046; // lbs./kg

const useTravelAvgEmissionPerRoute = () => {
  const { state, Notifier, loading, error } = useEditableParamsState();

  const {
    avgEmployeeKmTraveled,
    carsTransportDistribution,
    busesTransportDistribution,
    avgEmployeeCarsFuelEconomy,
    busEmissionPerEmployee,
  } = state;

  const carsTransportDistributionValue = getRatio(
    carsTransportDistribution.value,
    100
  );
  const busesTransportDistributionValue = getRatio(
    busesTransportDistribution.value,
    100
  );

  const avgEmissionPerRoute =
    isRealNumber(avgEmployeeKmTraveled.value) &&
    isRealNumber(avgEmployeeCarsFuelEconomy.value) &&
    isRealNumber(busEmissionPerEmployee.value) &&
    isRealNumber(carsTransportDistributionValue) &&
    isRealNumber(busesTransportDistributionValue)
      ? KG_TO_LBS *
        avgEmployeeKmTraveled.value *
        (avgEmployeeCarsFuelEconomy.value * carsTransportDistributionValue +
          busEmissionPerEmployee.value * busesTransportDistributionValue)
      : null;

  return {
    avgEmissionPerRoute,
    Notifier,
    isLoading: loading,
    error,
  };
};

export { useTravelAvgEmissionPerRoute };
