import { useTranslate } from "modules/language";
import { useState } from "react";
import useEditableParamsMutate from "modules/building/hooks/useEditableParamsMutate";
import EditableParamsContainer from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/editableParams/EditableParamsContainer/EditableParamsContainer";
import EditableParamWidget from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/editableParams/EditableParamWidget/EditableParamWidget";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import { validateParamValues } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/utils";

const TissuesFactorAssumption = () => {
  const t = useTranslate();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isHistoryEnabled, setIsHistoryEnabled] = useState(false);
  const { state, Notifier, telemetries } = useEditableParamsState();
  const { mutateAsync, isLoading } = useEditableParamsMutate();

  const handleEditSave = async () => {
    if (!validateParamValues(state, ["tissuesEmissionsFactor"])) {
      return false;
    }

    const { tissuesEmissionsFactor } = state;

    if (tissuesEmissionsFactor.syncInitialValue()) {
      await mutateAsync([
        {
          timeseriesId: telemetries.tissuesEmissionsFactor.timeseriesId,
          value: tissuesEmissionsFactor.value,
        },
      ]);
    }

    return true;
  };

  return (
    <Notifier>
      <EditableParamsContainer
        title={t("label.ASSUMPTIONS")}
        isEditEnabled={isEditEnabled}
        setIsEditEnabled={setIsEditEnabled}
        isHistoryEnabled={isHistoryEnabled}
        setIsHistoryEnabled={setIsHistoryEnabled}
        onSave={handleEditSave}
      >
        <EditableParamWidget
          paramName="tissuesEmissionsFactor"
          keyText={t("label.TISSUES_EMISSIONS_CONVERSION_FACTOR")}
          historyLabel={t("label.TISSUES_FACTOR_HISTORY")}
          isEditEnabled={isEditEnabled}
          isHistoryEnabled={isHistoryEnabled}
          isHistoryExpanded
          disabled={isLoading}
          maxValue={99.999}
          minValue={0.1}
        />
      </EditableParamsContainer>
    </Notifier>
  );
};

export default TissuesFactorAssumption;
