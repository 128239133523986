import withIconProps from "helpers/withIconProps";

type Props = {
  fill?: string;
};

const PaperPin = withIconProps(
  ({ fill = "var(--color-primary-background)" }: Props) => {
    return (
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.173073 0.173073C0.403838 -0.0576911 0.777981 -0.0576911 1.00874 0.173073L12.8269 11.9913C13.0577 12.222 13.0577 12.5962 12.8269 12.8269C12.5962 13.0577 12.222 13.0577 11.9913 12.8269L9.20978 10.0455H7.09091V12.4091C7.09091 12.7354 6.82635 13 6.5 13C6.17365 13 5.90909 12.7354 5.90909 12.4091V10.0455H2.36364C2.03729 10.0455 1.77273 9.7809 1.77273 9.45455V8.41455C1.7729 8.08474 1.86508 7.76122 2.03888 7.48093C2.21229 7.20129 2.4601 6.97545 2.75455 6.82866L3.8084 6.29581C3.90685 6.24689 3.98971 6.17149 4.04764 6.07806C4.10558 5.98463 4.13631 5.87689 4.13636 5.76696V4.97204L0.173073 1.00874C-0.0576911 0.777981 -0.0576911 0.403838 0.173073 0.173073ZM5.2829 6.11857C5.2413 6.32393 5.16343 6.52123 5.05202 6.70089C4.87862 6.98052 4.63082 7.20636 4.33638 7.35315L3.28251 7.88601C3.18406 7.93493 3.1012 8.01033 3.04326 8.10376C2.98538 8.1971 2.95466 8.30472 2.95455 8.41455V8.86364H8.02796L5.2829 6.11857ZM3.48046 0.590909C3.48046 0.264559 3.74502 1.76105e-08 4.07137 1.76105e-08H8.86364C9.3338 1.76105e-08 9.7847 0.186769 10.1172 0.51922C10.4496 0.85167 10.6364 1.30257 10.6364 1.77273C10.6364 2.24288 10.4496 2.69378 10.1172 3.02623C9.7847 3.35869 9.3338 3.54545 8.86364 3.54545V4.92818C8.86364 5.25453 8.59909 5.51909 8.27274 5.51909C7.94639 5.51909 7.68183 5.25453 7.68183 4.92818V2.95455C7.68183 2.6282 7.94639 2.36364 8.27274 2.36364H8.86364C9.02036 2.36364 9.17066 2.30138 9.28148 2.19056C9.3923 2.07975 9.45455 1.92945 9.45455 1.77273C9.45455 1.61601 9.3923 1.46571 9.28148 1.35489C9.17066 1.24407 9.02036 1.18182 8.86364 1.18182H4.07137C3.74502 1.18182 3.48046 0.917259 3.48046 0.590909Z"
          fill={fill}
        />
      </svg>
    );
  }
);

export default PaperPin;
