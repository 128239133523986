import withIconProps from "helpers/withIconProps";

const FilterDropdown = withIconProps((props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="filter">
        <g id="Glyph">
          <path
            d="M3.17163 5.5C3.17163 5.22386 3.39549 5 3.67163 5H12.6716C12.9478 5 13.1716 5.22386 13.1716 5.5C13.1716 5.77614 12.9478 6 12.6716 6H3.67163C3.39549 6 3.17163 5.77614 3.17163 5.5Z"
            fill="#304375"
          />
          <path
            d="M5.17163 8.5C5.17163 8.22386 5.39549 8 5.67163 8H10.6716C10.9478 8 11.1716 8.22386 11.1716 8.5C11.1716 8.77614 10.9478 9 10.6716 9H5.67163C5.39549 9 5.17163 8.77614 5.17163 8.5Z"
            fill="#304375"
          />
          <path
            d="M7.67163 11C7.39549 11 7.17163 11.2239 7.17163 11.5C7.17163 11.7761 7.39549 12 7.67163 12H8.67163C8.94777 12 9.17163 11.7761 9.17163 11.5C9.17163 11.2239 8.94777 11 8.67163 11H7.67163Z"
            fill="#304375"
          />
        </g>
      </g>
    </svg>
  );
});

export default FilterDropdown;
