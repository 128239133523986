import Link, { type SearchParams } from "atomic-components/atoms/Link/Link";
import { ReactElement } from "react";
import classNames from "classnames";
import { PageUrlEnum } from "router/enums";

import s from "./MainMenuItem.module.scss";

type SideMenuItemProps = {
  pageUrl?: PageUrlEnum;
  icon: ReactElement;
  text?: string;
  disabled?: boolean;
  isActive?: boolean;
  isSelectable?: boolean;
  searchParams?: SearchParams;
  keepSegments?: boolean;
};

const SideMenuItem = ({
  pageUrl,
  icon,
  text,
  disabled,
  isActive: isActiveFromProps,
  isSelectable = true,
  searchParams,
  keepSegments = true,
  ...props
}: SideMenuItemProps) => {
  const content = (
    <div className={s.itemContentContainer} {...props}>
      <div className={s.iconContainer}>{icon}</div>
      {text ? <h4 className={s.text}>{text}</h4> : null}
    </div>
  );

  if (pageUrl) {
    return (
      <Link
        className={({ isActive }) => {
          return classNames(s.item, {
            [s.itemActive]: isActive || isActiveFromProps,
            [s.itemSelectable]: isSelectable,
          });
        }}
        pageUrl={pageUrl}
        disabled={disabled}
        searchParams={searchParams}
        keepSegments={keepSegments}
      >
        {content}
      </Link>
    );
  }

  return (
    <div
      className={classNames(s.item, {
        [s.itemActive]: isActiveFromProps,
        [s.itemSelectable]: isSelectable,
      })}
    >
      {content}
    </div>
  );
};

export default SideMenuItem;
export type { SideMenuItemProps };
