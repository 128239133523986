import withIconProps from "helpers/withIconProps";

type Props = {
  fill?: string;
};

const PlusSquare = withIconProps(({ fill = "var(--color-primary)" }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16667 3.33317C3.70643 3.33317 3.33334 3.70627 3.33334 4.1665V15.8332C3.33334 16.2934 3.70643 16.6665 4.16667 16.6665H15.8333C16.2936 16.6665 16.6667 16.2934 16.6667 15.8332V4.1665C16.6667 3.70627 16.2936 3.33317 15.8333 3.33317H4.16667ZM1.66667 4.1665C1.66667 2.78579 2.78596 1.6665 4.16667 1.6665H15.8333C17.2141 1.6665 18.3333 2.78579 18.3333 4.1665V15.8332C18.3333 17.2139 17.2141 18.3332 15.8333 18.3332H4.16667C2.78596 18.3332 1.66667 17.2139 1.66667 15.8332V4.1665ZM10 5.83317C10.4602 5.83317 10.8333 6.20627 10.8333 6.6665V9.1665H13.3333C13.7936 9.1665 14.1667 9.5396 14.1667 9.99984C14.1667 10.4601 13.7936 10.8332 13.3333 10.8332H10.8333V13.3332C10.8333 13.7934 10.4602 14.1665 10 14.1665C9.53977 14.1665 9.16667 13.7934 9.16667 13.3332V10.8332H6.66667C6.20643 10.8332 5.83334 10.4601 5.83334 9.99984C5.83334 9.5396 6.20643 9.1665 6.66667 9.1665H9.16667V6.6665C9.16667 6.20627 9.53977 5.83317 10 5.83317Z"
        fill={fill}
      />
    </svg>
  );
});

export default PlusSquare;
