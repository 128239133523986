import React, { useState } from "react";
import CurrencyValue from "atomic-components/atoms/CurrencyValue";
import UnitValue from "atomic-components/atoms/UnitValue";
import { useTranslate } from "modules/language";
import classNames from "classnames";
import { Popover } from "atomic-components/atoms/InfoPopover/InfoPopover.js";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton/StrippedButton";
import { STACK_COLORS } from "atomic-components/helpers/stacks";

import s from "./HorizontalStackedChart.module.scss";

/**
 * @typedef {Object} DataItem
 * @property {string} key
 * @property {number|null} value
 * @property {string} [color]
 * @property {string} [borderColor]
 * @property {NonNullable<import("react").ReactNode>} legend
 */

/**
 * @callback OnAreaClick
 * @param {string|null} key
 */

/**
 * @param {{data: DataItem[]; unit: import("types/unit.js").Unit | null; currency?: import("types/unit.js").Currency | null; selectedBorderColor?: string; selectedAreaKey?: import("types/utils.js").Nullable<string|number>; onAreaClick?: OnAreaClick; hasExpandText?: boolean }} param0
 */
const HorizontalStackedChart = ({
  data,
  unit,
  currency,
  selectedBorderColor,
  selectedAreaKey,
  onAreaClick = null,
  hasExpandText = true,
  ...props
}) => {
  const t = useTranslate();
  const [shownTooltip, setShownTooltip] = useState(null);

  if (!data || data.length < 1) {
    throw new Error("Properties are incomplete");
  }

  const total = data.reduce((acc, { value = 0 }) => acc + value, 0);

  const handleMouseEnter = (e, value) => {
    const anchorElement = e.target;
    const display = currency ? (
      <CurrencyValue value={value} currency={currency} />
    ) : (
      <UnitValue value={value} unit={unit} />
    );

    setShownTooltip({
      display,
      anchorElement,
    });
  };

  const handleMouseLeave = () => {
    setShownTooltip(null);
  };

  return (
    <div {...props}>
      <div className={s.chartContainer}>
        {data.map(({ key, value, color, borderColor }, index) => {
          if (!value) {
            return null;
          }
          return (
            <StrippedButton
              className={classNames(s.area, {
                [s.areaHighlighted]: key === selectedAreaKey,
              })}
              onClick={onAreaClick !== null ? () => onAreaClick(key) : null}
              key={key}
              onMouseEnter={(e) => handleMouseEnter(e, value)}
              onMouseLeave={handleMouseLeave}
              style={{
                flex: Math.round((value / total) * 100),
                backgroundColor: color || STACK_COLORS[index],
                border:
                  key === selectedAreaKey
                    ? `1px solid ${selectedBorderColor}`
                    : borderColor
                    ? `1px solid ${borderColor}`
                    : "none",
              }}
            ></StrippedButton>
          );
        })}
      </div>
      <div className={s.legendContainer}>
        {data.map(({ key, color, borderColor, legend }, index) => (
          <StrippedButton
            className={classNames(s.legendItem, {
              [s["legendItem--selected"]]: key === selectedAreaKey,
              [s["legendItem--no-pointer"]]: onAreaClick === null,
            })}
            key={key}
            onClick={() => onAreaClick(key)}
          >
            <div
              className={s.legendColor}
              style={{
                backgroundColor: color || STACK_COLORS[index],
                border: borderColor ? `1px solid ${borderColor}` : "none",
              }}
            ></div>
            <div>{legend}</div>
          </StrippedButton>
        ))}
      </div>
      {hasExpandText ? (
        <div className={s.expandText}>{`${t("legend.CLICK_GRAPH")}`}</div>
      ) : null}

      {shownTooltip ? (
        <Popover
          anchorElement={shownTooltip.anchorElement}
          placement="top"
          offset={5}
        >
          {shownTooltip.display}
        </Popover>
      ) : null}
    </div>
  );
};

export default HorizontalStackedChart;
