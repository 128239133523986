import { isString } from "lodash";

const lessPriorityErrorMessageKeys = {
  OOPS: true,
  ER_ERROR: true,
};
export const getMessageKey = (
  error,
  errorByStatus = {},
  defaultMessage = "OOPS"
) => {
  let messageKey = isString(error)
    ? error
    : error
    ? error.detailedKey || error.messageKey || defaultMessage
    : defaultMessage;
  if (lessPriorityErrorMessageKeys[messageKey]) {
    const status = error?.status || error?.$status;
    if (errorByStatus[status]) {
      messageKey = errorByStatus[status];
    } else if (!lessPriorityErrorMessageKeys[defaultMessage]) {
      messageKey = defaultMessage;
    }
  }
  return messageKey;
};
