import { cloneDeep, sum } from "lodash";
import { getTelemetryFromEntityTelemetries } from "../hooks/useLinkedData";
import isRealNumber from "helpers/isRealNumber";
import { ONE_HOUR } from "./intervals";
import createLinkedComputersFactory from "./createLinkedComputersFactory";

import type {
  LinkedTelemetry,
  LinkedTelemetriesResponseL2,
  TelemetryRequest,
  Datum,
} from "../hooks/useLinkedData";
import type { BuildingData, Unit } from "./postProcessBuildingData";
import { type ComputeFunction } from "./createLinkedComputersFactory";
import type { Nullable } from "types/utils";
import { AHUModeUnitEnum, Currency } from "types/unit";
import getRatio from "helpers/getRatio";

export type AggregationKeys =
  | "sum"
  | "average"
  | "duration"
  | "notEmptyDuration"
  | "coolingModeDuration"
  | "fanModeDuration"
  | "consumption"
  | "spending"
  | "max"
  | "min"
  | "spendingFromSpendingPerHour"
  | "onPercent"
  | "onDuration"
  | "last";

export const aggregationsConfigs: Record<AggregationKeys, AggregationConfig> = {
  sum: {
    getValue: (state) => state.sum,
    aggregateAggregations: sum,
  },
  average: {
    getValue: (state) => getRatio(state.sum, state.count),
    aggregateAggregations: (aggregations) =>
      sum(aggregations) / aggregations.length,
  },
  duration: {
    getUnit: (_, hydrateParams) => {
      return hydrateParams.buildingData.unitsByName.hours;
    },
    getValue: (state) => state.duration / ONE_HOUR,
    aggregateAggregations: sum,
  },
  notEmptyDuration: {
    valueFilterFunction: (value) => isRealNumber(value) && value !== 0,
    getUnit: (_, hydrateParams) => {
      return hydrateParams.buildingData.unitsByName.hours;
    },
    getValue: (state) => state.duration / ONE_HOUR,
    aggregateAggregations: sum,
  },
  onDuration: {
    valueFilterFunction: (value) => isRealNumber(value) && value > 0,
    getUnit: (_, hydrateParams) => {
      return hydrateParams.buildingData.unitsByName.hours;
    },
    getValue: (state) => state.duration / ONE_HOUR,
    aggregateAggregations: sum,
  },
  onPercent: {
    valueAggregator: (cursor, value, coverage) =>
      (cursor ?? 0) + (value ? 1 : 0) * (coverage ?? 0),
    getUnit: (_, hydrateParams) => {
      return hydrateParams.buildingData.unitsByName.percent;
    },
    getValue: (state) =>
      isRealNumber(state.value) && state.count
        ? (100 * state.value) / state.count
        : null,
    aggregateAggregations: (values) => sum(values) / values.length,
  },
  coolingModeDuration: {
    valueFilterFunction: (value) => value === AHUModeUnitEnum.COOLING,
    getUnit: (_, hydrateParams) => {
      return hydrateParams.buildingData.unitsByName.hours;
    },
    getValue: (state) => state.duration / ONE_HOUR,
    aggregateAggregations: sum,
  },
  fanModeDuration: {
    valueFilterFunction: (value) => value === AHUModeUnitEnum.FAN,
    getUnit: (_, hydrateParams) => {
      return hydrateParams.buildingData.unitsByName.hours;
    },
    getValue: (state) => state.duration / ONE_HOUR,
    aggregateAggregations: sum,
  },
  consumption: {
    getUnit: (params, hydrateParams) => {
      const unitName = params.telemetry.unit?.name;
      switch (unitName) {
        case "kwt":
        case "kwht":
          return hydrateParams.buildingData.unitsByName.kwht;
        case "kw":
        case "kwh":
          return hydrateParams.buildingData.unitsByName.kwh;
        default:
          if (
            unitName ===
            `${hydrateParams.buildingData.currency.name}/${hydrateParams.buildingData.unitsByName.hours.name}`
          ) {
            return hydrateParams.buildingData.currency;
          }
          return params.telemetry.unit;
      }
    },
    getValue: (state, params, hydrateParams) => {
      if (!isRealNumber(state.sum)) {
        return null;
      }
      const unitName = params.telemetry.unit?.name;
      switch (unitName) {
        case "kw":
        case "kwt":
          return (state.sum / state.count) * (state.duration / ONE_HOUR);
        case "kwh":
        case "kwht":
          return state.sum;
        default:
          if (
            unitName ===
            `${hydrateParams.buildingData.currency.name}/${hydrateParams.buildingData.unitsByName.hours.name}`
          ) {
            return (state.sum / state.count) * (state.duration / ONE_HOUR);
          }
          return state.sum;
      }
    },
    aggregateAggregations: sum,
  },
  spending: {
    getUnit: (_, hydrateParams) => {
      return hydrateParams.buildingData.currency;
    },
    getValue: (state, params, hydrateParams) => {
      if (!isRealNumber(state.sum)) {
        return null;
      }
      const unitName = params.telemetry.unit?.name;
      let price: number;
      switch (unitName) {
        case "kwt":
        case "kwht":
          price = hydrateParams.buildingData.prices.chilledWater;
          break;
        case "kw":
        case "kwh":
          price = hydrateParams.buildingData.prices.electricity;
          break;
        default:
          price = 1;
          break;
      }

      switch (unitName) {
        case "kw":
        case "kwt":
          return (
            (state.sum / state.count) * (state.duration / ONE_HOUR) * price
          );
        case "kwh":
        case "kwht":
          return state.sum * price;
        default:
          if (
            unitName ===
            `${hydrateParams.buildingData.currency.name}/${hydrateParams.buildingData.unitsByName.hours.name}`
          ) {
            return (state.sum / state.count) * (state.duration / ONE_HOUR);
          }
          return state.sum * price;
      }
    },
    aggregateAggregations: sum,
  },
  spendingFromSpendingPerHour: {
    getValue: (state, params) =>
      isRealNumber(state.sum)
        ? (state.sum * params.telemetry.interval) / ONE_HOUR
        : null,
    getUnit: (_, hydrateParams) => hydrateParams.buildingData.currency,
    aggregateAggregations: sum,
  },
  max: {
    getInitialValue: () => Number.NEGATIVE_INFINITY,
    valueAggregator: (cursor, value) =>
      !isRealNumber(value)
        ? cursor
        : !isRealNumber(cursor)
        ? value
        : Math.max(cursor, value),
    getValue: (aggregationState) =>
      !isRealNumber(aggregationState.value) ? 1 : aggregationState.value,
    aggregateAggregations: (aggregations) => Math.max(...aggregations),
  },
  min: {
    getInitialValue: () => Number.POSITIVE_INFINITY,
    valueAggregator: (cursor, value) =>
      !isRealNumber(value)
        ? cursor
        : !isRealNumber(cursor)
        ? value
        : Math.min(cursor, value),
    getValue: (aggregationState) =>
      !isRealNumber(aggregationState.value) ? 0 : aggregationState.value,
    aggregateAggregations: (aggregations) => Math.min(...aggregations),
  },
  last: {
    valueAggregator: (cursor, value) => value,
    getValue: (state) => state.value,
    aggregateAggregations: (aggregations) =>
      aggregations[aggregations.length - 1],
  },
};

type FilterFunctionsKeys = "none";
const filterFunctions: Record<FilterFunctionsKeys, FilterFunction> = {
  none: () => true,
};

export type RequestExtraFilter =
  | {
      filterKey: FilterFunctionsKeys;
      filterFunction?: never;
    }
  | {
      filterKey: string;
      filterFunction: FilterFunction;
    }
  | {
      filterKey?: never;
      filterFunction?: never;
    };
export type AggregationRequest = {
  key: AggregationKeys;
  entityId: string;
  telemetry: TelemetryRequest;
} & RequestExtraFilter;

type AggregationParams = Omit<
  AggregationRequest,
  "telemetry" | "filterKey" | "filterFunctionKey"
> & {
  uniqKeyPath: Nullable<string>[];
  telemetry: LinkedTelemetry;
  filterFunction: FilterFunction;
  computeFunction: AggregationFunction;
  buckets: any;
};

type AggregationState = {
  value: Nullable<number>;
  sum: Nullable<number>;
  duration: number;
  count: number;
};
export type AggregationValue = {
  value: Nullable<number>;
  unit: Unit | Currency;
  duration: number;
  count: number;
};

type GetUnitFunction = (
  params: AggregationParams,
  hydrateParams: HydrateParams
) => Unit | Currency;

type GetInitialValueFunction = (
  params: AggregationParams,
  hydrateParams: HydrateParams
) => Nullable<number>;

type ValueAggregatorFunction = (
  cursor: Nullable<number>,
  value: Nullable<number>,
  coverage: Nullable<number>
) => Nullable<number>;

type GetValueFunction = (
  aggregationState: AggregationState,
  params: AggregationParams,
  hydrateParams: HydrateParams
) => Nullable<number>;

type ValueFilterFunction = (value: Nullable<number>) => boolean;

type ShouldForceRecomputeFunction = (
  params: AggregationParams,
  internalState: Parameters<AggregationFunction>[1],
  hydrateParams: HydrateParams
) => boolean;

type AggregationConfig = Partial<{
  getInitialValue: GetInitialValueFunction;
  getUnit: GetUnitFunction;
  valueFilterFunction: ValueFilterFunction;
  valueAggregator: ValueAggregatorFunction;
  getValue: GetValueFunction;
  shouldForceRecompute: ShouldForceRecomputeFunction;
}> & {
  aggregateAggregations: (aggregationsArray: number[]) => number;
};

type FilterFunctionExtraParams = {
  index: number;
  telemetry: LinkedTelemetry;
  telemetries: LinkedTelemetriesResponseL2;
  buildingData: BuildingData;
};

type FilterFunction = (
  datum: Datum,
  extraParams: FilterFunctionExtraParams
) => boolean;
export type AggregationFilterFunction = FilterFunction | FilterFunctionsKeys;

type AggregationFunction = ComputeFunction<
  AggregationParams,
  HydrateParams,
  AggregationValue
>;
type HydrateParams = {
  telemetries: LinkedTelemetriesResponseL2;
  buildingData: BuildingData;
  buckets: any;
};

export const resolveRequestFilter = (
  request: RequestExtraFilter
): { filterKey: string; filterFunction: FilterFunction } => {
  let filterKey: string;
  let filterFunction: FilterFunction;
  if ("filterFunction" in request) {
    filterKey = request.filterKey!;
    filterFunction = request.filterFunction!;
    if (filterKey in filterFunctions) {
      throw new Error(
        `Dynamic filter function cannot be with same key as predefined filterFunction`
      );
    }
  } else {
    filterKey = request.filterKey ?? "none";
    filterFunction = filterFunctions[filterKey as FilterFunctionsKeys];
  }
  return { filterKey, filterFunction };
};

const liveStateSymbol = Symbol("liveState");
const getAggregationState = (
  telemetriesArray: LinkedTelemetry[],
  aggregationInternalState: Record<string | symbol, any>
) => {
  const allTelemetriesUpToDate = !telemetriesArray.find(
    (telemetry) =>
      telemetry.lastConsecutiveDatumIndex !== telemetry.data.length - 1
  );

  const liveState =
    aggregationInternalState[liveStateSymbol] ??
    (aggregationInternalState[liveStateSymbol] = {});

  if (allTelemetriesUpToDate) {
    return [
      liveState,
      (newState: any) => {
        Object.assign(aggregationInternalState, newState);
        Object.assign(liveState, newState);
      },
    ];
  } else {
    return [
      cloneDeep(liveState),
      (newState: any) => {
        Object.assign(aggregationInternalState, newState);
      },
    ];
  }
};

const aggregationFunction: AggregationFunction = (
  aggregationParams,
  aggregationInternalState,
  hydrateParams
) => {
  const { telemetries, buildingData } = hydrateParams;
  const { telemetry, key } = aggregationParams;
  const {
    getInitialValue = () => 0,
    getUnit,
    getValue = (state) => state.value,
    valueAggregator = () => null,
    valueFilterFunction = isRealNumber,
    shouldForceRecompute = () => false,
  } = aggregationsConfigs[key];

  const [state, setState] = getAggregationState(
    [telemetry],
    aggregationInternalState
  );

  const data = telemetry.data;
  const filterFunction = aggregationParams.filterFunction;

  let startIndex = (state.startIndex ?? 0) as number;
  let cursor = (state.value ??
    getInitialValue(aggregationParams, hydrateParams)) as Nullable<number>;
  let sum = (state.sum ?? 0) as number;
  let count = (state?.count ?? 0) as number;
  let duration = (state?.duration ?? 0) as number;

  if (
    shouldForceRecompute(
      aggregationParams,
      aggregationInternalState,
      hydrateParams
    )
  ) {
    startIndex = 0;
    cursor = getInitialValue(aggregationParams, hydrateParams);
    sum = 0;
    count = 0;
    duration = 0;
  }

  state.startIndex = data.length;

  for (let i = startIndex; i < data.length; i++) {
    const datum = data[i];
    if (
      !filterFunction(datum, {
        index: i,
        telemetry,
        telemetries,
        buildingData,
      })
    ) {
      continue;
    }
    const { value, coverage = 1 } = datum;
    if (valueFilterFunction(value)) {
      cursor = valueAggregator(cursor, value, coverage);
      sum = !isRealNumber(value) ? sum : value + sum;
      count += coverage;
      duration += coverage * telemetry.interval;
    }
  }
  const unit = getUnit
    ? getUnit(aggregationParams, hydrateParams)
    : telemetry.unit;

  const newState = {
    value: cursor,
    sum,
    count,
    duration,
    unit,
  };
  setState(newState);
  return {
    value: getValue(newState, aggregationParams, hydrateParams),
    unit,
    count,
    duration,
  };
};

const createLinkedTelemetriesAggregator = createLinkedComputersFactory<
  AggregationRequest,
  AggregationParams,
  HydrateParams,
  any
>((request, { telemetries }) => {
  let { entityId, telemetry: telemetryRequest, key } = request;
  const telemetry = getTelemetryFromEntityTelemetries(
    telemetries[entityId],
    telemetryRequest
  );
  const telemetryKey = telemetry.key;

  const { filterKey, filterFunction } = resolveRequestFilter(request);

  const uniqKeyPath = [entityId, telemetryKey, key, filterKey];
  return {
    uniqKeyPath,
    entityId,
    key,
    telemetry,
    filterFunction,
    computeFunction: aggregationFunction,
    buckets: {},
  };
});

export default createLinkedTelemetriesAggregator;
