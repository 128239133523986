import withIconProps from "helpers/withIconProps";

const ChevronsRightIcon = withIconProps((props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.0769 10.1026L5.17947 5.99999L1.0769 1.89743M6.82049 10.1026L10.9231 5.99999L6.82049 1.89743"
        stroke="#3D5183"
        strokeWidth="2.05128"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default ChevronsRightIcon;
