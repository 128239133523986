import React from "react";
import CurrencyValue from "../CurrencyValue";
import Displayer from "atomic-components/molecules/Displayer/Displayer";

/**
 * @param {{value: any, unit?: import("types/utils").Nullable<import("types/unit").Unit | import("types/unit").Currency>, format?: string}} param0
 * @returns {React.ReactNode}
 */
const UnitValue = ({ value, unit, format }) => {
  if (unit?.enum) {
    const enumObject = unit?.enum[value] ?? "-";
    return <Displayer object={enumObject} />;
  }
  if (unit?.name === "boolean") {
    if (value) {
      return "Yes";
    }
    return "No";
  }
  return <CurrencyValue value={value} currency={unit} format={format} />;
};

export default UnitValue;
