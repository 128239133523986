import useBuildingData from "modules/building/hooks/useBuildingData";
import LabelByRefrigerantTypes from "../LabelByRefrigerantTypes/LabelByRefrigerantTypes";
import { useTranslate } from "modules/language";

const OzoneDepletionPotential = () => {
  const t = useTranslate();
  const {
    buildingData: {
      constants: { refrigerants },
    },
  } = useBuildingData();

  const { odpR22, odpR410A, odpR407C } = refrigerants;

  return (
    <LabelByRefrigerantTypes
      label={t("label.OZONE_DEPLETION_POTENTIAL")}
      values={[odpR22, odpR410A, odpR407C]}
    />
  );
};

export default OzoneDepletionPotential;
