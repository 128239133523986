import { useQuery } from "react-query";
import { ReactQueryCacheKeys, queryClient } from "reactQuery";
import {
  type ComplianceScoreKey,
  getComplianceScoreValues,
} from "../api/complianceScores";
import useFullDateFilterState from "./useFullDateFilterState";
import { useComplianceScoreFallbackValues } from "./useComplianceScoreFallbackValues";
import isRealNumber from "helpers/isRealNumber";
import { defaultComplianceScoreValues } from "../helpers/compliaceScores";

const useComplianceScoreValues = (args: { year?: number } = {}) => {
  const { from } = useFullDateFilterState();
  const year = args.year ?? from.year;

  const result = useQuery({
    queryKey: [ReactQueryCacheKeys.ComplianceScoreValues, year],
    queryFn: ({ signal }) =>
      getComplianceScoreValues(
        { year },
        {
          signal,
        }
      ),
    staleTime: Infinity,
  });

  const isEdit = !!result.data && !result.data.isPlaceholder;

  const {
    values: fallbackValues,
    dependents: fallbackValuesDependents,
    isLoading: isFallbackLoading,
    isError: isFallbackError,
  } = useComplianceScoreFallbackValues({ fetchedValues: result.data?.values });

  const valuesWithoutFallbacks =
    result.data?.values ?? defaultComplianceScoreValues;
  const data = { ...valuesWithoutFallbacks };

  /**
   * fallbacks are applied ONLY if values are already filled before
   */
  if (isEdit) {
    Object.entries(fallbackValues).forEach((item) => {
      const key = item[0] as ComplianceScoreKey;
      const currentValue = data[key];

      if (!isRealNumber(currentValue)) {
        data[key] = item[1];
        const dependents = fallbackValuesDependents[key] || {};

        Object.entries(dependents).forEach(([dKey, dValue]) => {
          data[dKey as ComplianceScoreKey] = dValue;
        });
      }
    });
  }

  return {
    ...result,
    isEdit,
    data,
    valuesWithoutFallbacks,
    isFallbackLoading,
    isFallbackError,
  };
};

const invalidateComplianceScoreValuesQuery = () => {
  queryClient.invalidateQueries({
    predicate: (query) => {
      return query.queryKey[0] === ReactQueryCacheKeys.ComplianceScoreValues;
    },
  });
};

export { useComplianceScoreValues, invalidateComplianceScoreValuesQuery };
