import withIconProps from "helpers/withIconProps";

const CalendarCheckedIcon = withIconProps((props) => {
  return (
    <svg
      {...props}
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4848 1V5M6.24242 1V5M1.09091 9H19.6364M7.27273 15L9.33333 17L13.4545 13M3.15151 3H17.5758C18.7138 3 19.6364 3.89543 19.6364 5V19C19.6364 20.1046 18.7138 21 17.5758 21H3.15151C2.01347 21 1.09091 20.1046 1.09091 19V5C1.09091 3.89543 2.01347 3 3.15151 3Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default CalendarCheckedIcon;
