import { pullAllBy } from "lodash";

const revertTexturizeObject = (object, key) => {
  if (!object.userData.texturized) {
    return;
  }
  const texturizerMaterialsStack = object.userData.texturizerMaterialsStack;

  pullAllBy(texturizerMaterialsStack, [{ key }], "key");

  if (texturizerMaterialsStack.length === 0) {
    object.material = object.userData.texturizerInitialMaterial;
    object.visible = object.userData.texturizerInitialVisibility;
    delete object.userData.texturized;
    delete object.userData.texturizerInitialMaterial;
    delete object.userData.texturizerInitialVisibility;
    delete object.userData.texturizerMaterialsStack;
  } else {
    const lastMaterial =
      texturizerMaterialsStack[texturizerMaterialsStack.length - 1].material;
    object.material = lastMaterial;
    object.visible = true;
  }
};

export default revertTexturizeObject;
