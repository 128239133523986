import React from "react";

/**
 * @param {{className?: string, color?: string, height: number, width?: number, horizontal?: boolean, flip?: boolean, style?: import("react").CSSProperties}} param0
 * @returns {React.ReactNode}
 */
const Triangle = ({
  className,
  color,
  height,
  width = height / 0.8660254,
  horizontal = false,
  flip = false,
  style,
}) => {
  return (
    <svg
      className={className}
      style={style}
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      color={color}
    >
      <path
        fill="currentcolor"
        d={
          horizontal
            ? flip
              ? `M0,0L${width},${height / 2}L0,${height}z`
              : `M${width},0L${width},${height}L0,${height / 2}z`
            : flip
            ? `M0,0L${width},0L${width / 2},${height}z`
            : `M0,${height}L${width / 2},0L${width},${height}z`
        }
      />
    </svg>
  );
};

export default Triangle;
