import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useViewerState } from "../Provider/Provider";
import intersectObjects from "../../helpers/intersectObjects";

const HoverControl = forwardRef(
  (
    {
      resolveHoverObjectFromIntersections = (intersections) =>
        intersections[0].object,
      debounceTimeout = 250,
      onHoverEnter,
      onHoverLeave,
      onHoverMove,
      disabled,
    },
    cancelRef
  ) => {
    const { canvas, objects, camera } = useViewerState();

    const mutableRef = useRef({});
    Object.assign(mutableRef.current, {
      canvas,
      objects,
      camera,
      onHoverEnter,
      onHoverLeave,
      onHoverMove,
      disabled,
      resolveHoverObjectFromIntersections,
    });

    const cancelHoverOut = useCallback(() => {
      const { clearHoverOutTimer } = mutableRef.current;
      if (clearHoverOutTimer) {
        clearHoverOutTimer();
      }
    }, []);

    const clearHover = useCallback(() => {
      const { clearHoverOutTimer, debouncedHoveredObject, onHoverLeave } =
        mutableRef.current;
      if (clearHoverOutTimer) {
        clearHoverOutTimer();
      }
      delete mutableRef.current.debouncedHoveredObject;
      if (onHoverLeave && debouncedHoveredObject) {
        onHoverLeave({
          object: debouncedHoveredObject,
        });
      }
    }, []);

    useEffect(() => {
      if (!disabled) {
        return;
      }
      clearHover();
    }, [disabled]);

    useImperativeHandle(
      cancelRef,
      () => ({
        cancelHoverOut,
      }),
      [cancelHoverOut]
    );

    useEffect(() => {
      if (disabled || !canvas) {
        return;
      }

      let moveListener;
      canvas.parentElement.addEventListener(
        "mousemove",
        (moveListener = (e) => {
          const {
            objects,
            camera,
            onHoverEnter,
            onHoverMove,
            resolveHoverObjectFromIntersections,
          } = mutableRef.current;

          if (!objects?.length) {
            return;
          }
          const intersectionResult = intersectObjects(
            e,
            objects,
            canvas,
            camera
          );
          if (!intersectionResult) {
            return;
          }
          const { intersections, mouse } = intersectionResult;
          const hoveredObject =
            resolveHoverObjectFromIntersections(intersections);

          if (!hoveredObject) {
            let timer;
            if (mutableRef.current.clearHoverOutTimer) {
              mutableRef.current.clearHoverOutTimer();
            }
            mutableRef.current.clearHoverOutTimer = () => {
              clearTimeout(timer);
            };
            timer = setTimeout(clearHover, debounceTimeout);
            return;
          }

          const isSame =
            mutableRef.current.debouncedHoveredObject?.id === hoveredObject.id;

          if (isSame) {
            if (mutableRef.current.clearHoverOutTimer) {
              mutableRef.current.clearHoverOutTimer();
            }
            if (onHoverMove) {
              onHoverMove({
                object: hoveredObject,
                mouse,
              });
            }
          } else {
            clearHover();
            mutableRef.current.debouncedHoveredObject = hoveredObject;
            if (onHoverEnter) {
              onHoverEnter({
                object: hoveredObject,
                mouse,
              });
            }
          }
        })
      );

      return () => {
        canvas.parentElement.removeEventListener("mousemove", moveListener);
      };
    }, [canvas, disabled]);

    return null;
  }
);

export default HoverControl;
