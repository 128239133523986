import classNames from "classnames";
import { HTMLAttributes } from "react";

import s from "./Accordion.module.scss";

type AccordionProps = HTMLAttributes<HTMLUListElement>;

const Accordion = ({ children, className, ...props }: AccordionProps) => {
  return (
    <ul className={classNames(className, s.accordion)} {...props}>
      {children}
    </ul>
  );
};

export default Accordion;
export type { AccordionProps };
