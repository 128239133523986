import { flatten, maxBy, orderBy } from "lodash";
import isRealNumber from "./isRealNumber";

export const mergeIntervals = (intervals, defaultTo = Date.now()) => {
  intervals = orderBy(
    intervals,
    [0, (interval) => interval[1] ?? defaultTo],
    ["asc", "desc"]
  );
  const mergedIntervals = [];
  for (let i = 0; i < intervals.length; i++) {
    const currentInterval = intervals[i];
    let [currentFrom, currentTo] = currentInterval;
    currentTo = currentTo ?? defaultTo;
    let j = i + 1;
    for (; j < intervals.length; j++) {
      const nextInterval = intervals[j];
      const [nextFrom, nextTo] = nextInterval;
      if (nextFrom > currentTo) {
        break;
      }
      currentTo = Math.max(currentTo, nextTo ?? defaultTo);
    }
    mergedIntervals.push([currentFrom, currentTo]);
    i = j - 1;
  }
  return mergedIntervals;
};

export const get2IntervalsIntersection = (
  interval1,
  interval2,
  intervalDefaults = [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
) => {
  const [defaultFrom, defaultTo] = intervalDefaults;
  const [from1 = defaultFrom, to1 = defaultTo] = interval1;
  const [from2 = defaultFrom, to2 = defaultTo] = interval2;
  let intervalFrom, intervalTo;
  if (from1 < from2) {
    intervalFrom = from2;
  } else {
    intervalFrom = from1;
  }
  if (to1 > to2) {
    intervalTo = to2;
  } else {
    intervalTo = to1;
  }
  if (intervalTo - intervalFrom <= 0) {
    return null;
  }
  return [intervalFrom, intervalTo];
};

const intersect = (intervals, [from, to], defaultTo = Date.now()) => {
  to = to ?? defaultTo;
  intervals = mergeIntervals(intervals, defaultTo);
  const isFromReal = isRealNumber(from);
  const isToReal = isRealNumber(to);
  if (!isFromReal || !isToReal) {
    return intervals;
  }
  if (from != null || to != null) {
    const isFromReal = isRealNumber(from);
    const isToReal = isRealNumber(to);
    intervals = intervals
      .map((interval) => {
        let [intervalFrom, intervalTo] = interval;
        intervalTo = intervalTo ?? defaultTo;
        if (isFromReal && intervalFrom < from) {
          intervalFrom = from;
        }
        if (isToReal && intervalTo > to) {
          intervalTo = to;
        }
        if (intervalTo - intervalFrom <= 0) {
          return null;
        }
        return [intervalFrom, intervalTo];
      })
      .filter(Boolean);
  }
  return intervals;
};

const subtract = (intervals, [from, to], defaultTo = Date.now()) => {
  to = to ?? defaultTo;
  intervals = mergeIntervals(intervals, defaultTo);
  const isFromReal = isRealNumber(from);
  const isToReal = isRealNumber(to);
  if (!isFromReal || !isToReal) {
    return intervals;
  }
  intervals = flatten(
    intervals.map((interval) => {
      let [intervalFrom, intervalTo] = interval;
      intervalTo = intervalTo ?? defaultTo;
      if (to <= intervalFrom) {
        return [interval];
      }
      if (from >= intervalTo) {
        return [interval];
      }

      const isToWithinInterval = to < intervalTo;
      const isFromWithinInterval = from > intervalFrom;
      if (isToWithinInterval && isFromWithinInterval) {
        return [
          [intervalFrom, from],
          [to, intervalTo],
        ];
      }
      if (isToWithinInterval) {
        return [[to, intervalTo]];
      }
      if (isFromWithinInterval) {
        return [[intervalFrom, from]];
      }
      return [];
    })
  );
  return intervals;
};

export const unionIntervals = (
  intervals1,
  intervals2,
  defaultTo = Date.now()
) => mergeIntervals([...intervals1, ...intervals2], defaultTo);

export const intersectIntervals = (
  intervals1,
  intervals2,
  defaultTo = Date.now()
) => {
  intervals1 = mergeIntervals(intervals1, defaultTo);
  intervals2 = mergeIntervals(intervals2, defaultTo);
  for (let i = 0; i < intervals2.length; i++) {
    intervals1 = intersect(intervals1, intervals2[i], defaultTo);
  }
  return intervals1;
};

export const subtractIntervals = (
  intervals1,
  intervals2,
  defaultTo = Date.now()
) => {
  intervals1 = mergeIntervals(intervals1, defaultTo);
  intervals2 = mergeIntervals(intervals2, defaultTo);
  for (let i = 0; i < intervals2.length; i++) {
    intervals1 = subtract(intervals1, intervals2[i], defaultTo);
  }
  return intervals1;
};

window.mergeIntervals = mergeIntervals;
window.intersectIntervals = intersectIntervals;
window.unionIntervals = unionIntervals;
window.subtractIntervals = subtractIntervals;

const computeIntervalsDuration = (
  intervals,
  from,
  to,
  defaultTo = Date.now()
) => {
  intervals = mergeIntervals(intervals, defaultTo);
  intervals =
    from === undefined && to === undefined
      ? intervals
      : intersect(intervals, [from, to], defaultTo);
  return intervals.reduce(
    (cumulative, [from, to]) => cumulative + to - from,
    0
  );
};

export const getActiveIntervalsIndexes = (intervals, timestamp) =>
  intervals
    .map(([from, to], i) => {
      if (from <= timestamp && timestamp < (to ?? Number.POSITIVE_INFINITY)) {
        return i;
      }
      return -1;
    })
    .filter((i) => i >= 0);

export const getNumberOfActiveIntervals = (intervals, timestamp) =>
  getActiveIntervalsIndexes(intervals, timestamp).length;

export const isTimestampWithinIntervals = (intervals, timestamp) =>
  getActiveIntervalsIndexes(intervals, timestamp).length > 0;

export default computeIntervalsDuration;
