import withIconProps from "helpers/withIconProps";

const ChevronRightIcon = withIconProps(({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      {...props}
    >
      <path
        d="M0.195262 8.19494C-0.0650874 8.45529 -0.0650874 8.8774 0.195262 9.13775C0.455612 9.3981 0.877722 9.3981 1.13807 9.13775L5.13807 5.13775C5.39842 4.8774 5.39842 4.45529 5.13807 4.19494L1.13807 0.194938C0.877722 -0.0654125 0.455612 -0.0654125 0.195262 0.194938C-0.0650874 0.455287 -0.0650874 0.877397 0.195262 1.13775L3.72386 4.66634L0.195262 8.19494Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ChevronRightIcon;
