import type { SVGAttributes } from "react";

type LiveIconProps = SVGAttributes<SVGElement>;

const LiveIcon = (props: LiveIconProps) => {
  return (
    <svg width="18" height="11" viewBox="0 0 18 11" fill="none" {...props}>
      <g clipPath="url(#clip0_4519_14237)">
        <path
          d="M9.05064 7.75924C10.047 7.75924 10.8547 6.95154 10.8547 5.95518C10.8547 4.95883 10.047 4.15112 9.05064 4.15112C8.05429 4.15112 7.24658 4.95883 7.24658 5.95518C7.24658 6.95154 8.05429 7.75924 9.05064 7.75924Z"
          fill="currentColor"
        />
        <path
          d="M15.8487 10.9983C15.7501 10.9965 15.6549 10.9619 15.5782 10.8999C15.4806 10.8278 15.4156 10.7201 15.3971 10.6003C15.3787 10.4805 15.4084 10.3582 15.4797 10.2602C16.4106 9.0195 16.9137 7.51025 16.9137 5.95917C16.9137 4.40808 16.4106 2.89886 15.4797 1.65813C15.4079 1.55881 15.378 1.43526 15.3964 1.3141C15.4148 1.19293 15.4801 1.08383 15.5782 1.01031C15.6762 0.938964 15.7984 0.909271 15.9182 0.927705C16.038 0.94614 16.1457 1.0112 16.2178 1.10871C17.2709 2.50652 17.8404 4.20907 17.8404 5.95917C17.8404 7.70927 17.2709 9.41183 16.2178 10.8096C16.1753 10.8678 16.1198 10.9152 16.0557 10.9479C15.9916 10.9807 15.9207 10.9979 15.8487 10.9983Z"
          fill="currentColor"
        />
        <path
          d="M14.1184 9.44029C14.0184 9.43924 13.9212 9.40776 13.8396 9.35008C13.7907 9.31374 13.7496 9.26806 13.7186 9.21569C13.6876 9.16332 13.6673 9.10532 13.6589 9.04504C13.6505 8.98477 13.6542 8.92341 13.6697 8.86456C13.6853 8.80572 13.7123 8.75054 13.7494 8.70225C14.3448 7.91172 14.6668 6.94892 14.6668 5.95926C14.6668 4.96959 14.3448 4.00681 13.7494 3.21628C13.7123 3.16798 13.6853 3.11281 13.6697 3.05396C13.6542 2.99512 13.6505 2.93377 13.6589 2.87349C13.6673 2.81322 13.6876 2.7552 13.7186 2.70284C13.7496 2.65047 13.7907 2.60479 13.8396 2.56845C13.9386 2.49778 14.061 2.46775 14.1815 2.48453C14.302 2.50131 14.4114 2.56361 14.4874 2.65865C15.1929 3.60671 15.574 4.75699 15.574 5.93876C15.574 7.12054 15.1929 8.27081 14.4874 9.21887C14.4486 9.28311 14.3948 9.33688 14.3304 9.37548C14.2661 9.41407 14.1933 9.43634 14.1184 9.44029Z"
          fill="currentColor"
        />
        <path
          d="M12.3636 8.08711C12.2789 8.08494 12.1963 8.05978 12.1248 8.01429C12.0533 7.9688 11.9954 7.90472 11.9575 7.8289C11.9196 7.75309 11.903 7.66837 11.9096 7.58386C11.9161 7.49934 11.9455 7.41819 11.9946 7.34909C12.2934 6.94731 12.4548 6.45989 12.4548 5.95914C12.4548 5.45839 12.2934 4.971 11.9946 4.56922C11.9575 4.52092 11.9305 4.46575 11.915 4.4069C11.8995 4.34806 11.8958 4.2867 11.9042 4.22642C11.9126 4.16614 11.9328 4.10814 11.9639 4.05577C11.9949 4.00341 12.036 3.95773 12.0848 3.92139C12.1841 3.84958 12.3077 3.81966 12.4288 3.83807C12.55 3.85647 12.6591 3.92173 12.7326 4.01979C13.145 4.58231 13.3674 5.26164 13.3674 5.95914C13.3674 6.65664 13.145 7.336 12.7326 7.89852C12.6902 7.95669 12.6347 8.00405 12.5706 8.03681C12.5065 8.06958 12.4356 8.0868 12.3636 8.08711Z"
          fill="currentColor"
        />
        <path
          d="M2.23629 10.9985C2.1643 10.9982 2.0934 10.9809 2.0293 10.9482C1.9652 10.9154 1.90971 10.868 1.86729 10.8099C0.814205 9.41208 0.244629 7.70951 0.244629 5.95941C0.244629 4.20931 0.814205 2.50676 1.86729 1.10896C1.93933 1.01145 2.04699 0.946384 2.16682 0.92795C2.28664 0.909515 2.4089 0.939208 2.50691 1.01056C2.60497 1.08408 2.67021 1.19317 2.68862 1.31434C2.70702 1.43551 2.67711 1.55905 2.60531 1.65837C1.67448 2.89911 1.17129 4.40833 1.17129 5.95941C1.17129 7.5105 1.67448 9.01974 2.60531 10.2605C2.67666 10.3585 2.70635 10.4807 2.68792 10.6005C2.66948 10.7204 2.60442 10.8281 2.50691 10.9001C2.43019 10.9621 2.33493 10.9968 2.23629 10.9985Z"
          fill="currentColor"
        />
        <path
          d="M3.99112 9.44033C3.91913 9.44002 3.84821 9.42277 3.78411 9.39001C3.72001 9.35725 3.66451 9.30988 3.62209 9.25172C2.91656 8.30366 2.53552 7.15338 2.53552 5.97161C2.53552 4.78984 2.91656 3.63956 3.62209 2.6915C3.69604 2.59363 3.80584 2.52915 3.92733 2.51223C4.04882 2.49532 4.17205 2.52735 4.26992 2.6013C4.36779 2.67524 4.43226 2.78503 4.44918 2.90652C4.46609 3.02801 4.43406 3.15125 4.36011 3.24911C3.76471 4.03964 3.44271 5.00244 3.44271 5.99211C3.44271 6.98177 3.76471 7.94457 4.36011 8.7351C4.43079 8.83413 4.46083 8.95647 4.44405 9.07697C4.42727 9.19747 4.36496 9.30697 4.26992 9.38293C4.18435 9.42822 4.08762 9.44814 3.99112 9.44033Z"
          fill="currentColor"
        />
        <path
          d="M5.74592 8.08711C5.67394 8.0868 5.60304 8.06958 5.53894 8.03681C5.47484 8.00405 5.41935 7.95669 5.37693 7.89852C4.96453 7.336 4.74219 6.65664 4.74219 5.95914C4.74219 5.26164 4.96453 4.58231 5.37693 4.01979C5.45045 3.92173 5.55954 3.85647 5.68071 3.83807C5.80188 3.81966 5.92541 3.84958 6.02473 3.92139C6.07355 3.95773 6.11467 4.00341 6.14568 4.05577C6.17669 4.10814 6.19697 4.16614 6.20536 4.22642C6.21376 4.2867 6.2101 4.34806 6.19458 4.4069C6.17905 4.46575 6.15198 4.52092 6.11495 4.56922C5.81608 4.971 5.65468 5.45839 5.65468 5.95914C5.65468 6.45989 5.81608 6.94731 6.11495 7.34909C6.16405 7.41819 6.19343 7.49934 6.19996 7.58386C6.20649 7.66837 6.18992 7.75309 6.15201 7.8289C6.1141 7.90472 6.0563 7.9688 5.98477 8.01429C5.91324 8.05978 5.83066 8.08494 5.74592 8.08711Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default LiveIcon;
export type { LiveIconProps };
