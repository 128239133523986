import withIconProps from "helpers/withIconProps";

const BackArrowIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.51535 0.195262C4.7757 0.455612 4.7757 0.877722 4.51535 1.13807L2.32009 3.33333H7.71061C8.27967 3.33333 8.84316 3.44542 9.36891 3.66319C9.89465 3.88096 10.3724 4.20015 10.7747 4.60254C11.1771 5.00492 11.4963 5.48263 11.7141 6.00837C11.9319 6.53412 12.0439 7.0976 12.0439 7.66667C12.0439 8.23573 11.9319 8.79922 11.7141 9.32496C11.4963 9.85071 11.1771 10.3284 10.7747 10.7308C10.3724 11.1332 9.89465 11.4524 9.36891 11.6701C8.84316 11.8879 8.27967 12 7.71061 12H5.37728C5.00909 12 4.71061 11.7015 4.71061 11.3333C4.71061 10.9651 5.00909 10.6667 5.37728 10.6667H7.71061C8.10458 10.6667 8.49469 10.5891 8.85866 10.4383C9.22264 10.2875 9.55336 10.0666 9.83193 9.78799C10.1105 9.50941 10.3315 9.17869 10.4823 8.81472C10.633 8.45074 10.7106 8.06063 10.7106 7.66667C10.7106 7.2727 10.633 6.88259 10.4823 6.51862C10.3315 6.15464 10.1105 5.82392 9.83193 5.54535C9.55336 5.26677 9.22264 5.04579 8.85866 4.89503C8.49469 4.74426 8.10458 4.66667 7.71061 4.66667H2.32009L4.51535 6.86193C4.7757 7.12228 4.7757 7.54439 4.51535 7.80474C4.255 8.06509 3.83289 8.06509 3.57254 7.80474L0.239207 4.4714C-0.0211421 4.21106 -0.0211421 3.78894 0.239207 3.5286L3.57254 0.195262C3.83289 -0.0650874 4.255 -0.0650874 4.51535 0.195262Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default BackArrowIcon;
