import React, { forwardRef, useId } from "react";
import classNames from "classnames";

import s from "./TextInput.module.scss";

export type TextInputProps = {
  label?: string;
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  type?: string;
  icon?: React.JSX.Element;
  required?: boolean;
  onBlur?: () => void;
  disabled?: boolean;
  labelClassName?: string;
  min?: number;
  max?: number;
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      value,
      onChange,
      placeholder,
      type = "text",
      icon,
      labelClassName,
      min,
      max,
      ...props
    },
    ref
  ) => {
    const labelId = useId();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    };

    return (
      <div className={s.container}>
        {label && (
          <label id={labelId} className={classNames(s.label, labelClassName)}>
            {label}
          </label>
        )}
        <div className={s.inputContainer}>
          <input
            ref={ref}
            type={type}
            className={icon && s.icon}
            value={value}
            onChange={handleChange}
            aria-labelledby={labelId}
            placeholder={placeholder}
            min={min}
            max={max}
            {...props}
          />
          {icon}
        </div>
      </div>
    );
  }
);

export default TextInput;
