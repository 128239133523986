import { useComplianceScoreValues } from "modules/building/hooks/useComplianceScoreValues";
import CertificateStatusBar from "atomic-components/molecules/CertificateStatusBar/CertificateStatusBar";
import { useTranslate } from "modules/language";
import { complianceCertificates } from "modules/building/pages/SustainabilityPage/utils";
import { getScenarioTotalScore } from "../Scenarios/ScenariosList/ScenariosList";
import { useComplianceScenarios } from "modules/building/hooks/useComplianceScenarios";
import { Nullable } from "types/utils";

import s from "./ComplianceTrackingProgressBar.module.scss";

type ComplianceTrackingProgressBarProps = {
  targetScore?: Nullable<number>;
};

const ComplianceTrackingProgressBar = ({
  targetScore: passedTargetScore,
}: ComplianceTrackingProgressBarProps) => {
  const t = useTranslate();
  const { data } = useComplianceScoreValues();

  const filledInScore = data.total;

  const { activeScenario } = useComplianceScenarios();
  const activeScenarioScore = getScenarioTotalScore(activeScenario);

  const customRanges = {
    ranges: complianceCertificates.map((certificate) => {
      return {
        ...certificate,
        label: t(certificate.label),
      };
    }),
    scores: {
      currentScore: data.total,
      targetScore: passedTargetScore || activeScenarioScore || filledInScore,
    },
  };

  return (
    <div className={s.segmentsContainer}>
      <CertificateStatusBar customRanges={customRanges} />
    </div>
  );
};

export default ComplianceTrackingProgressBar;
