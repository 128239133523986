import withIconProps from "helpers/withIconProps";

const SortIcon = withIconProps(() => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 0.701172L7.4641 4.80374H0.535898L4 0.701172Z" fill="white" />
      <path
        d="M4 11.2988L0.535899 7.19626L7.4641 7.19626L4 11.2988Z"
        fill="white"
      />
    </svg>
  );
});

export default SortIcon;
