import { ChartAction, ChartAxisKey, SetChartTrendsTrendParam } from "./types";
import { aggregationsConfigsByKey, type Trend } from "types/trend";
import type { Setter } from "types/utils";
import type { useTranslate } from "modules/language";
import { intersection } from "lodash";
import type { GetTelemetryUnit } from "modules/building/hooks/useTelemetryUnit";
import type { ModalContextValue } from "atomic-components/molecules/Modal/ModalProvider";
import AxisOverrideModalBody from "./AxisOverrideModalBody/AxisOverrideModalBody";

const GRAPH_COLORS = [
  "var(--color-primary)",
  "red",
  "blueviolet",
  "orange",
  "green",
  "brown",
  "cadetblue",
  "cornflowerblue",
  "violet",
  "turquoise",
  "yellowgreen",
  "teal",
  "magenta",
  "maroon",
  "chocolate",
  "darkslategray",
];

const graphColors = GRAPH_COLORS.flatMap((color) => [color, color]);

const fillTrendFields = ({
  trends,
  getTelemetryUnit,
}: {
  trends: SetChartTrendsTrendParam[];
  getTelemetryUnit: GetTelemetryUnit;
}) => {
  let colorIndex = 0;

  return trends.map(
    ({ entityId, telemetry, unit, color, aggregationsKey, ...rest }, index) => {
      return {
        ...rest,
        entityId,
        telemetry,
        unit:
          unit ||
          getTelemetryUnit({
            telemetryName: telemetry,
            entityId,
          }),
        isAlternate: Boolean(index % 2),
        color: color ?? graphColors[colorIndex++],
        aggregationsKey,
        aggregations: aggregationsConfigsByKey[aggregationsKey].aggregations,
      };
    }
  );
};

const getChartAxisTrendKeyType = ({
  key,
  chartPrimaryAxisTrendKeys,
  chartSecondaryAxisTrendKeys,
}: {
  key: string;
  chartPrimaryAxisTrendKeys: string[];
  chartSecondaryAxisTrendKeys: string[];
}) => {
  return chartPrimaryAxisTrendKeys.includes(key)
    ? "primary"
    : chartSecondaryAxisTrendKeys.includes(key)
    ? "secondary"
    : undefined;
};

const addChartAxisTrendKey = ({
  key,
  axis,
  trends,
  chartPrimaryAxisTrendKeys,
  chartSecondaryAxisTrendKeys,
  setChartPrimaryAxisTrendKeys,
  setChartSecondaryAxisTrendKeys,
  t,
  setModal,
  chartAction,
}: {
  key: string;
  axis?: ChartAxisKey;
  trends: Trend[];
  chartPrimaryAxisTrendKeys: string[];
  chartSecondaryAxisTrendKeys: string[];
  setChartPrimaryAxisTrendKeys: Setter<string[]>;
  setChartSecondaryAxisTrendKeys: Setter<string[]>;
  setModal: ModalContextValue["setModal"];
  t: ReturnType<typeof useTranslate>;
  chartAction?: ChartAction | null | undefined;
}) => {
  /**
   * get current axis units
   */
  const primaryAxisLastAddedTrend = trends.find(
    (trend) => trend.key === chartPrimaryAxisTrendKeys[0]
  );
  const secondaryAxisLastAddedTrend = trends.find(
    (trend) => trend.key === chartSecondaryAxisTrendKeys[0]
  );
  const newTrend = trends.find((trend) => trend.key === key);

  const primaryAxisUnitName = primaryAxisLastAddedTrend
    ? primaryAxisLastAddedTrend.unit?.name || "none"
    : undefined;
  const secondaryAxisUnitName = secondaryAxisLastAddedTrend
    ? secondaryAxisLastAddedTrend.unit?.name || "none"
    : undefined;
  const newTrendUnitName = newTrend ? newTrend.unit?.name || "none" : undefined;

  const addToPrimaryAxis = (override?: boolean) => {
    setChartPrimaryAxisTrendKeys((prevKeys) => {
      return override ? [key] : [...prevKeys, key];
    });
    setChartSecondaryAxisTrendKeys((prevKeys) => {
      return prevKeys.filter((prevKey) => prevKey !== key);
    });
  };

  const addToSecondaryAxis = (override?: boolean) => {
    setChartPrimaryAxisTrendKeys((prevKeys) => {
      return prevKeys.filter((prevKey) => prevKey !== key);
    });
    setChartSecondaryAxisTrendKeys((prevKeys) => {
      return override ? [key] : [...prevKeys, key];
    });
  };
  const addToSecondaryAxisByPrimaryAxis = () => {
    const newsecondTrend = trends.find(
      (trend) => trend.entityId === newTrend?.entityId && trend.key !== key
    );
    setChartPrimaryAxisTrendKeys([key]);
    setChartSecondaryAxisTrendKeys([newsecondTrend!.key]);
  };
  const addToPrimaryAxisBySecondaryAxis = () => {
    const newsecondTrend = trends.find(
      (trend) => trend.entityId === newTrend?.entityId && trend.key !== key
    );
    setChartPrimaryAxisTrendKeys([newsecondTrend!.key]);
    setChartSecondaryAxisTrendKeys([key]);
  };

  const addToPrimaryAxisForAlarm = () => {
    setChartPrimaryAxisTrendKeys((prevKeys) => {
      const prevKeysWithAlarms = prevKeys.filter((prevKey) => {
        const trend = trends.find((trend) => trend.key === prevKey);
        return trend && trend.hasAlarms;
      });

      const isNewTrendWithAlarms = trends.some(
        (trend) => trend.key === key && trend.hasAlarms
      );

      return isNewTrendWithAlarms
        ? [...prevKeysWithAlarms.filter((prevKey) => prevKey === key), key]
        : [...prevKeysWithAlarms, key];
    });
  };

  switch (axis) {
    case "primary": {
      if (chartAction === ChartAction.Alarm) {
        if (!primaryAxisUnitName || primaryAxisUnitName === newTrendUnitName) {
          addToPrimaryAxisForAlarm();
        } else {
          setModal(
            <AxisOverrideModalBody onConfirm={() => addToPrimaryAxis(true)}>
              {t("message.CONFIRM_ADD_GRAPH_TO_PRIMARY")}
            </AxisOverrideModalBody>,
            { width: "medium" }
          );
        }
      } else {
        if (!primaryAxisUnitName || primaryAxisUnitName === newTrendUnitName) {
          addToPrimaryAxis();
        } else {
          setModal(
            <AxisOverrideModalBody onConfirm={() => addToPrimaryAxis(true)}>
              {t("message.CONFIRM_ADD_GRAPH_TO_PRIMARY")}
            </AxisOverrideModalBody>,
            { width: "medium" }
          );
        }
      }
      break;
    }
    case "secondary": {
      if (
        !secondaryAxisUnitName ||
        secondaryAxisUnitName === newTrendUnitName
      ) {
        addToSecondaryAxis();
      } else {
        setModal(
          <AxisOverrideModalBody onConfirm={() => addToSecondaryAxis(true)}>
            {t("message.CONFIRM_ADD_GRAPH_TO_SECONDARY")}
          </AxisOverrideModalBody>,
          { width: "medium" }
        );
      }
      break;
    }
    default:
      if (chartAction == ChartAction.MetricOccupancyRanges) {
        if (!primaryAxisUnitName || primaryAxisUnitName === newTrendUnitName) {
          addToSecondaryAxisByPrimaryAxis();
        } else if (
          !secondaryAxisUnitName ||
          secondaryAxisUnitName === newTrendUnitName
        ) {
          addToPrimaryAxisBySecondaryAxis();
        }
      } else if (chartAction == ChartAction.MetricRanges) {
        if (!primaryAxisUnitName || primaryAxisUnitName === newTrendUnitName) {
          addToSecondaryAxisByPrimaryAxis();
        } else if (
          !secondaryAxisUnitName ||
          secondaryAxisUnitName === newTrendUnitName
        ) {
          addToPrimaryAxisBySecondaryAxis();
        } else {
          setModal(
            <AxisOverrideModalBody onConfirm={() => addToSecondaryAxis(true)}>
              {t("message.CONFIRM_SWAP_GRAPH_TO_SECONDARY")}
            </AxisOverrideModalBody>,
            { width: "medium" }
          );
        }
      } else if (chartAction === ChartAction.Alarm) {
        if (!primaryAxisUnitName || primaryAxisUnitName === newTrendUnitName) {
          addToPrimaryAxisForAlarm();
        } else if (
          !secondaryAxisUnitName ||
          secondaryAxisUnitName === newTrendUnitName
        ) {
          addToSecondaryAxis();
        } else {
          setModal(
            <AxisOverrideModalBody onConfirm={() => addToSecondaryAxis(true)}>
              {t("message.CONFIRM_SWAP_GRAPH_TO_SECONDARY")}
            </AxisOverrideModalBody>,
            { width: "medium" }
          );
        }
      } else {
        if (!primaryAxisUnitName || primaryAxisUnitName === newTrendUnitName) {
          addToPrimaryAxis();
        } else if (
          !secondaryAxisUnitName ||
          secondaryAxisUnitName === newTrendUnitName
        ) {
          addToSecondaryAxis();
        } else {
          setModal(
            <AxisOverrideModalBody onConfirm={() => addToSecondaryAxis(true)}>
              {t("message.CONFIRM_SWAP_GRAPH_TO_SECONDARY")}
            </AxisOverrideModalBody>,
            { width: "medium" }
          );
        }
      }
  }
};

const removeChartAxisTrendKey = ({
  key,
  setChartPrimaryAxisTrendKeys,
  setChartSecondaryAxisTrendKeys,
}: {
  key: string;
  setChartPrimaryAxisTrendKeys: Setter<string[]>;
  setChartSecondaryAxisTrendKeys: Setter<string[]>;
}) => {
  setChartPrimaryAxisTrendKeys((prevKeys) => {
    return prevKeys.filter((prevKey) => prevKey !== key);
  });
  setChartSecondaryAxisTrendKeys((prevKeys) => {
    return prevKeys.filter((prevKey) => prevKey !== key);
  });
};

const initializeChartAxisTrendKeys = ({
  chartPrimaryAxisTrendKeys,
  chartSecondaryAxisTrendKeys,
  trends,
  setChartPrimaryAxisTrendKeys,
  setChartSecondaryAxisTrendKeys,
}: {
  chartPrimaryAxisTrendKeys: string[];
  chartSecondaryAxisTrendKeys: string[];
  trends: Trend[];
  setChartPrimaryAxisTrendKeys: Setter<string[]>;
  setChartSecondaryAxisTrendKeys: Setter<string[]>;
}) => {
  const selectedTrendKeys = [
    ...chartPrimaryAxisTrendKeys,
    ...chartSecondaryAxisTrendKeys,
  ];

  const trendKeys = trends.map(({ key }) => key);

  const intersectingTrendKeys = intersection(trendKeys, selectedTrendKeys);

  if (intersectingTrendKeys.length < 1) {
    const [firstTrend, secondTrend] = trends;

    const firstTrendUnitName = firstTrend
      ? firstTrend.unit?.name || "none"
      : undefined;

    const secondTrendUnitName = secondTrend
      ? secondTrend.unit?.name || "none"
      : undefined;

    if (firstTrendUnitName && secondTrendUnitName) {
      if (firstTrendUnitName === secondTrendUnitName) {
        setChartPrimaryAxisTrendKeys([firstTrend.key, secondTrend.key]);
        setChartSecondaryAxisTrendKeys([]);
      } else {
        setChartPrimaryAxisTrendKeys([firstTrend.key]);
        setChartSecondaryAxisTrendKeys([secondTrend.key]);
      }
    } else if (firstTrendUnitName) {
      setChartPrimaryAxisTrendKeys([firstTrend.key]);
      setChartSecondaryAxisTrendKeys([]);
    } else if (secondTrendUnitName) {
      setChartPrimaryAxisTrendKeys([secondTrend.key]);
      setChartSecondaryAxisTrendKeys([]);
    } else {
      setChartPrimaryAxisTrendKeys([]);
      setChartSecondaryAxisTrendKeys([]);
    }
  } else {
    setChartPrimaryAxisTrendKeys(
      chartPrimaryAxisTrendKeys.filter((trendKey) =>
        intersectingTrendKeys.includes(trendKey)
      )
    );
    setChartSecondaryAxisTrendKeys(
      chartSecondaryAxisTrendKeys.filter((trendKey) =>
        intersectingTrendKeys.includes(trendKey)
      )
    );
  }
};

export {
  fillTrendFields,
  getChartAxisTrendKeyType,
  addChartAxisTrendKey,
  removeChartAxisTrendKey,
  initializeChartAxisTrendKeys,
};
