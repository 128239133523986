import { createFormStoreHook } from "helpers/createFormStoreHook";
import type { ComplianceScoreKey } from "../../utils";
import type { Nullable } from "types/utils";
import { defaultComplianceScoreValues } from "modules/building/helpers/compliaceScores";

const useEvaluationWizardBuildingForm = createFormStoreHook<{
  [key in ComplianceScoreKey]: Nullable<number>;
}>({
  defaultValues: defaultComplianceScoreValues,
});

export { useEvaluationWizardBuildingForm };
