import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import CollapsibleContainer from "atomic-components/molecules/CollapsibleContainer/CollapsibleContainer";
import { useTranslate } from "modules/language";
import useBuildingData from "modules/building/hooks/useBuildingData";
import KeyValue from "atomic-components/molecules/KeyValue";
import UnitValue from "atomic-components/atoms/UnitValue";
import BreakdownTitle from "../BreakdownTitle/BreakdownTitle";
import WidgetGrid from "atomic-components/molecules/WidgetGrid/WidgetGrid";
import MultiColumnWidgetContainer from "atomic-components/atoms/MultiColumnWidgetContainer/MultiColumnWidgetContainer";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import FuelBreakdownAssumptions from "./FuelBreakdownAssumptions/FuelBreakdownAssumptions";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import { useMergedNotifier } from "helpers/getNotifier";
import { useFuelEmissions } from "modules/building/hooks/useFuelEmissions";
import getRatio from "helpers/getRatio";

type FuelBreakdownProps = {
  scopes: string[];
};

const FuelBreakdown = ({ scopes }: FuelBreakdownProps) => {
  const t = useTranslate();
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);
  const {
    totalFuelEmissions,
    dieselConsumption,
    Notifier: ENotifier,
  } = useFuelEmissions();

  const { resolutions, Notifier: TNotifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        resolutionKey: "1month",
        telemetries: [{ telemetryName: "dailyMaximumUniqueEmployees" }],
      },
    ],
    [selectedEntity]
  );

  const numOfUniqueEmployees =
    resolutions["1month"][selectedEntity.id].dailyMaximumUniqueEmployees.data[0]
      .value;
  const avgEmissionsPerPerson = getRatio(
    totalFuelEmissions,
    numOfUniqueEmployees
  );

  const entityArea = parseFloat(selectedEntity.area ?? "1");
  const avgEmissionPerArea = getRatio(totalFuelEmissions, entityArea);

  const Notifier = useMergedNotifier(ENotifier, TNotifier);

  return (
    <Notifier>
      <CollapsibleContainer
        titleFontWeight="normal"
        title={<BreakdownTitle label={t("label.FUEL")} scopes={scopes} />}
        isExplainable={true}
        tooltipMessage={t("tooltip.DIESEL_CONSUMED")}
      >
        <WidgetGrid>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.DIESEL_EMISSION")}
              value={
                <UnitValue
                  value={totalFuelEmissions}
                  unit={unitsByName.kgco2e}
                />
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.DIESEL_CONSUMPTION")}
              value={
                <UnitValue value={dieselConsumption} unit={unitsByName.liter} />
              }
              isLight
            />
          </WidgetContainer>
          <MultiColumnWidgetContainer>
            <KeyValue
              keyText={t("label.AVG_EMISSION_PER_PERSON")}
              value={
                <>
                  <UnitValue
                    value={avgEmissionsPerPerson}
                    unit={unitsByName.kgco2e}
                  />{" "}
                  /
                  <Displayer object={unitsByName.person} />
                </>
              }
              isLight
            />
          </MultiColumnWidgetContainer>
          <MultiColumnWidgetContainer>
            <KeyValue
              keyText={t("label.AVG_EMISSION_PER_AREA")}
              value={
                <>
                  <UnitValue
                    value={avgEmissionPerArea}
                    unit={unitsByName.kgco2e}
                  />{" "}
                  /
                  <Displayer object={unitsByName.m2} />
                </>
              }
              isLight
            />
          </MultiColumnWidgetContainer>
        </WidgetGrid>
        <FuelBreakdownAssumptions />
      </CollapsibleContainer>
    </Notifier>
  );
};

export default FuelBreakdown;
