import withIconProps from "helpers/withIconProps";

const Edit = withIconProps(({ color, ...props }) => {
  const stroke = color ?? "currentcolor";
  return (
    <svg viewBox="0 0 11 11" {...props}>
      <path
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.15px"
        d="M4.72,1.17H2.53c-.65,0-.97,0-1.22,.13-.22,.11-.39,.29-.5,.5-.13,.25-.13,.57-.13,1.22v5.54c0,.65,0,.97,.13,1.22,.11,.22,.29,.39,.5,.5,.25,.13,.57,.13,1.22,.13h5.54c.65,0,.97,0,1.22-.13,.22-.11,.39-.29,.5-.5,.13-.25,.13-.57,.13-1.22v-2.48M7.31,2.04l1.63,1.63m-4.36,1.1L8.42,.94c.45-.45,1.18-.45,1.63,0,.45,.45,.45,1.18,0,1.63l-3.96,3.96c-.44,.44-.66,.66-.91,.83-.22,.16-.46,.28-.71,.38-.28,.11-.59,.17-1.2,.3l-.28,.06,.03-.19c.1-.68,.15-1.02,.26-1.33,.1-.28,.23-.55,.4-.79,.19-.28,.43-.52,.91-1Z"
      />
    </svg>
  );
});

export default Edit;
