import classNames from "classnames";

type EnergyRecommendationProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  color?: string;
  strokeWidth?: number;
};

const EnergyRecommendation = ({
  className,
  iconScale = 1,
  icon = true,
  color = "#0040FF",
  strokeWidth = 1.3,
}: EnergyRecommendationProps) => (
  <svg
    className={classNames(className, { "para-icon": icon })}
    width="24"
    height="29"
    viewBox="0 0 24 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fontSize: `${iconScale * 100}%`,
    }}
  >
    <path
      d="M3 20L8.12 14.88C8.68207 14.3172 9.44462 14.0007 10.24 14H13C13.5304 14 14.0391 14.2107 14.4142 14.5858C14.7893 14.9609 15 15.4696 15 16C15 16.5304 14.7893 17.0392 14.4142 17.4142C14.0391 17.7893 13.5304 18 13 18H10.5M14.5 17.32L18.67 12.43C18.9922 12.0729 19.4389 11.8529 19.9184 11.8151C20.3979 11.7774 20.8736 11.9249 21.2476 12.2272C21.6217 12.5295 21.8657 12.9637 21.9293 13.4404C21.993 13.9171 21.8716 14.4001 21.59 14.79L17.39 20.73C17.115 21.1196 16.7512 21.438 16.3286 21.6589C15.906 21.8797 15.4368 21.9967 14.96 22H9.83C9.56609 21.9998 9.30474 22.0518 9.06103 22.1531C8.81732 22.2544 8.59606 22.4028 8.41 22.59L7 24M2 19L8 25"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_13_1345)">
      <path
        d="M12.5 6L13.5 7L15.5 5M14 11C16.7615 11 19 8.7615 19 6C19 3.2385 16.7615 1 14 1C11.2385 1 9 3.2385 9 6C9 8.7615 11.2385 11 14 11Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13_1345">
        <rect width="12" height="12" fill="white" transform="translate(8)" />
      </clipPath>
    </defs>
  </svg>
);

export default EnergyRecommendation;
