import { useRef } from "react";

const initRef = (numberOfDimentions) => {
  const refValue = {
    numberOfDimentions,
    refs: {},
    refSetters: {},
    getRefSetter: (...dimentions) => {
      let refSettersObj = refValue.refSetters;
      let refObj = refValue.refs;
      for (let i = 0; i < numberOfDimentions - 1; i++) {
        refSettersObj =
          refSettersObj[dimentions[i]] ?? (refSettersObj[dimentions[i]] = {});
        refObj = refObj[dimentions[i]] ?? (refObj[dimentions[i]] = {});
      }
      const lastDimention = dimentions[dimentions.length - 1];
      return (
        refSettersObj[lastDimention] ??
        (refSettersObj[lastDimention] = (element) =>
          (refObj[lastDimention] = element))
      );
    },
  };
  return refValue;
};

const useMultidimentionalRef = (numberOfDimentions = 1) => {
  const ref = useRef();
  ref.current = ref.current ?? initRef(numberOfDimentions, ref);
  return [ref.current.refs, ref.current.getRefSetter];
};

export default useMultidimentionalRef;
