const { isString, isObject, orderBy, isArray } = require("lodash");

const getStableKey = (keyObject) => {
  if (isString(keyObject)) {
    return keyObject;
  }
  if (isObject(keyObject) && !isArray(keyObject)) {
    keyObject = Object.entries(keyObject);
  }
  keyObject = keyObject.filter(([_, value]) => value != null);
  keyObject = orderBy(keyObject, [([key]) => key], ["asc"]);
  return JSON.stringify(keyObject);
};

export default getStableKey;
