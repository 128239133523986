import classNames from "classnames";

const Time = ({
  color = "#8c8c8c",
  className = "",
  iconScale = 1,
  icon = true,
}) => {
  const stroke = color ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".83px;"
        d="M5.5,2.75v2.75h-1.65m6.61,0c0,2.74-2.22,4.96-4.96,4.96S.54,8.24,.54,5.5,2.76,.54,5.5,.54s4.96,2.22,4.96,4.96Z"
      />
    </svg>
  );
};

export default Time;
