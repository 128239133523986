import classNames from "classnames";
import React from "react";

/**
 * @param {{color?: string, className?: string, iconScale?: number, icon?: boolean}} param0
 * @returns {React.ReactNode}
 */
const PlusCircle = ({
  color = "#adadad",
  className,
  iconScale = 1,
  icon = true,
}) => {
  const stroke = color ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.28,5.5H7.72m-2.22-2.22V7.72m5-2.22c0,2.76-2.24,5-5,5S.5,8.26,.5,5.5,2.74,.5,5.5,.5s5,2.24,5,5Z"
      />
    </svg>
  );
};

export default PlusCircle;
