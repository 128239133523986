enum LeedOmCertificateLevelsEnum {
  Uncertifiable,
  Certified,
  Silver,
  Gold,
  Platinum,
}

const MAX_PINNED_SCENARIOS = 3;

const leedOmCertificateLevels = [
  {
    id: LeedOmCertificateLevelsEnum.Uncertifiable,
    label: "label.UNCERTIFIABLE",
    min: 0,
    max: 39,
  },
  {
    id: LeedOmCertificateLevelsEnum.Certified,
    label: "label.CERTIFIED",
    min: 40,
    max: 49,
  },
  {
    id: LeedOmCertificateLevelsEnum.Silver,
    label: "label.SILVER",
    certificateLabel: "label.LEED_OM_SILVER",
    min: 50,
    max: 59,
  },
  {
    id: LeedOmCertificateLevelsEnum.Gold,
    label: "label.GOLD",
    certificateLabel: "label.LEED_OM_GOLD",
    min: 60,
    max: 79,
  },
  {
    id: LeedOmCertificateLevelsEnum.Platinum,
    label: "label.PLATINUM",
    certificateLabel: "label.LEED_OM_PLATINUM",
    min: 80,
    max: 100,
  },
];

const MAX_CERTIFICATE_SCORE = 100;

type Scenario = {
  id: string;
  title: string;
  isActive: boolean;
  isPinned: boolean;
  comment: string;
  targetValues: Record<string, number | boolean> & {
    total?: number;
  };
  comments: Record<string, string>;
};

export {
  LeedOmCertificateLevelsEnum,
  MAX_PINNED_SCENARIOS,
  leedOmCertificateLevels,
  MAX_CERTIFICATE_SCORE,
  type Scenario,
};
