import classNames from "classnames";
import type { HTMLAttributes } from "react";

import s from "./SectionTitle.module.scss";

type SectionTitleProps = HTMLAttributes<HTMLElement> & {
  tag?: "header" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "button";
  variant?: "withSide" | "default";
  weight?: "normal" | "bold";
};

const SectionTitle = ({
  tag,
  variant = "default",
  weight,
  className,
  ...props
}: SectionTitleProps) => {
  const Tag = tag || "h2";

  return (
    <Tag
      className={classNames(s.h, className, s[variant], {
        [s[`weight--${weight}`]]: !!weight,
      })}
      {...props}
    />
  );
};

export default SectionTitle;
export type { SectionTitleProps };
