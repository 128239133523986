import { remove } from "lodash";
import modifyObject from "./modifyObjects";

export const startColorization = (object) => {
  if (object.userData.colorized) {
    return;
  }
  object.material.userData.colorsCache =
    object.material.userData.colorsCache ?? new Map();

  object.userData.colorized = true;
  object.userData.colorStack = [];
  object.userData.material = object.material;
  object.userData.visible = object.visible;
  object.userData.colorsCache = object.material.userData.colorsCache;
};
export const endColorization = (object) => {
  if (!object.userData.colorized) {
    return;
  }
  unuseMaterial(object.userData.colorsCache, object.material);
  object.material = object.userData.material;
  object.visible = object.userData.visible;
  delete object.userData.colorized;
  delete object.userData.colorStack;
  delete object.userData.material;
  delete object.userData.visible;
  delete object.userData.colorsCache;
};

const unuseMaterial = (colorsCache, material) => {
  if (material.userData.cacheKey && material.userData.used > 0) {
    material.userData.used--;
    if (material.userData.used === 0) {
      setTimeout(() => {
        if (material.userData.used === 0) {
          colorsCache.delete(material.userData.cacheKey);
        }
      }, 2500);
    }
  }
};
const getColorCacheKey = (opacity, color) => {
  if (opacity == 0) {
    return "hidden";
  }
  return `${Math.round(opacity * 1000)}:${color.toString(16)}`;
};

export const sheduleUpdate = (object) => {
  object.userData.cancelTimer && object.userData.cancelTimer();
  let timeoutTimer, animationFrameTimer;
  timeoutTimer = setTimeout(() => {
    animationFrameTimer = requestAnimationFrame(() => {
      if (object.userData.colorStack.length === 0) {
        endColorization(object);
      } else {
        let { color, opacity } = Object.assign(
          {},
          ...object.userData.colorStack
        );

        if (opacity == null) {
          opacity = object.userData.material.opacity;
        }
        if (color == null) {
          color = object.userData.material.color.getHex();
        }

        /** @type {Map} */
        const colorsCache = object.userData.colorsCache;
        const cacheKey = getColorCacheKey(opacity, color);
        let material = colorsCache.get(cacheKey);
        if (!material) {
          material = object.userData.material.clone();
          let visible;

          if (opacity === 0) {
            material.transparent = false;
            material.opacity = 0;
            visible = false;
          } else {
            material.transparent = opacity == 1 ? false : true;
            material.opacity = opacity;
            visible = true;
          }
          material.color.set(color);
          material.needsUpdate = true;

          material.userData.visible = visible;
          material.userData.cacheKey = cacheKey;
          material.userData.used = 0;

          colorsCache.set(cacheKey, material);
        }

        if (object.material != material) {
          unuseMaterial(colorsCache, object.material);
          const visible = material.userData.visible;
          object.material = material;
          object.visible = visible;
          material.userData.used++;
          material.needsUpdate = true;
        }
      }
    });
  }, 10);
  object.userData.cancelTimer = () => {
    clearTimeout(timeoutTimer);
    cancelAnimationFrame(animationFrameTimer);
  };
};

const revertColorizeObject = (object, key) => {
  modifyObject(object, (object) => {
    if (object.userData.colorized) {
      if (key) {
        remove(
          object.userData.colorStack,
          (colorStackItem) => colorStackItem.key === key
        );
      } else {
        object.userData.colorStack.splice(0, object.userData.colorStack.length);
      }
      sheduleUpdate(object);
    }
  });
};

export default revertColorizeObject;
