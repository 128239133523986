/**
 * @template OUTPUT_VALUE
 * @template INPUT_VALUE
 *
 * @callback MappingFunction
 * @param {[string,INPUT_VALUE]} entry
 * @returns {[string,OUTPUT_VALUE]}
 */

/**
 * @template OUTPUT_VALUE
 * @template VALUE
 *
 * @param {Record<string, VALUE>} object
 * @param {MappingFunction<OUTPUT_VALUE, VALUE>} mappingFunction
 */
const mapEntires = (object, mappingFunction) =>
  Object.fromEntries(Object.entries(object).map(mappingFunction));

export default mapEntires;
