const callListeners = (listeners, ...args) => {
  let hasErrors,
    newListeners = [],
    results = [];
  for (let i = 0; i < listeners.length; i++) {
    try {
      const currentListener = listeners[i];
      results.push({ result: currentListener.listener(...args) });
      if (!currentListener.once) {
        newListeners.push(currentListener);
      }
    } catch (error) {
      hasErrors = true;
      results.push({ error });
    }
  }
  listeners.splice(0, listeners.length, ...newListeners);
  if (hasErrors) {
    console.error(
      `Error(s) in event handlers`,
      results.filter((r) => r.error)
    );
  }
  return {
    hasErrors,
    results,
  };
};

const addListenerToListeners = (listeners, listener, once) => {
  listeners.push({ listener, once });
  return () => removeListenerFromListeners(listeners, listener);
};

const removeListenerFromListeners = (listeners, listener) => {
  const i = listeners.findIndex((l) => l.listener === listener);
  if (i >= 0) {
    listeners.splice(i, 1);
    return true;
  }
  return false;
};

export function SingleEvent() {
  if (!this || this === window) {
    throw new Error("SingleEvent is a class and was called without a new");
  }

  const instance = this;
  const listeners = [];

  let destroyed = false;
  let addListener = (listener, once = false) =>
    addListenerToListeners(listeners, listener, once);
  const once = (listener) => addListenerToListeners(listeners, listener, true);
  let triggerEvent = (...args) => callListeners(listeners, ...args);

  Object.defineProperty(instance, "destroyed", {
    configurable: false,
    enumerable: false,
    get: () => destroyed,
  });

  instance.addListener = instance.on = (listener, once = false) =>
    addListener(listener, once);
  instance.once = once;
  instance.removeListener = instance.off = (listener) => {
    if (destroyed) {
      return false;
    }
    return removeListenerFromListeners(listeners, listener);
  };
  instance.removeAllListeners = () => {
    if (destroyed) {
      return 0;
    }
    const count = listeners.length;
    listeners.splice(0, count);
    return count;
  };
  instance.triggerEvent = instance.fire = (...args) => triggerEvent(...args);
  instance.destroy = () => {
    instance.removeAllListeners();
    addListener = triggerEvent = () => {
      throw new Error(`Event destroyed`);
    };
    destroyed = true;
  };
}

export function EventsHub() {
  if (!this || this === window) {
    throw new Error("SingleEvent is a class and was called without a new");
  }

  const instance = this;
  const hub = new Map();

  instance.addListener = instance.on = (
    eventObjectOrName,
    listener,
    once = false
  ) => {
    if (!hub.has(eventObjectOrName)) {
      hub.set(eventObjectOrName, []);
    }
    return addListenerToListeners(hub.get(eventObjectOrName), listener, once);
  };
  instance.once = (eventObjectOrName, listener) =>
    instance.addListener(eventObjectOrName, listener, true);
  instance.removeListener = instance.off = (eventObjectOrName, listener) => {
    if (hub.has(eventObjectOrName)) {
      return removeListenerFromListeners(hub.get(eventObjectOrName), listener);
    }
    return false;
  };
  instance.removeAllListeners = (eventObjectOrName) => {
    if (hub.has(eventObjectOrName)) {
      const listeners = hub.get(eventObjectOrName);
      const count = listeners.length;
      listeners.splice(0, count);
      return count;
    }
    return 0;
  };
  instance.triggerEvent = instance.fire = (eventObjectOrName, ...args) => {
    if (hub.has(eventObjectOrName)) {
      return callListeners(hub.get(eventObjectOrName), ...args);
    }
    return {
      hasErrors: false,
      results: [],
    };
  };
}
