import classNames from "classnames";
import React from "react";

const CO2 = ({
  color = "#7a7a7a",
  className = "",
  iconScale = 1,
  icon = true,
}) => {
  const fill = color ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill={fill}
        d="M3.57,1.5c-.45,.08-.89,.3-1.21,.6-.15,.14-.34,.38-.43,.54-.04,.08-.07,.09-.2,.14-.35,.11-.69,.32-.98,.61-.88,.89-1,2.26-.26,3.23,.47,.62,1.14,.98,1.93,1.03l.22,.02,.15,.14c.65,.61,1.62,.79,2.44,.46,.09-.03,.15-.06,.15-.06,0,0,.06,.08,.12,.17,.47,.63,1.21,.98,2.04,.98s1.59-.38,2.07-1.02c.34-.45,.48-.87,.5-1.43v-.33s.17-.1,.17-.1c.29-.19,.52-.49,.64-.84,.06-.16,.06-.2,.06-.51,0-.31,0-.35-.06-.5-.2-.55-.65-.96-1.19-1.07l-.1-.02-.03-.17c-.14-.87-.86-1.61-1.75-1.78-.23-.04-.65-.04-.89,.01-.38,.08-.72,.25-1.03,.52l-.16,.14-.12-.12c-.35-.33-.76-.54-1.23-.62-.22-.04-.65-.04-.87,0Zm.87,.42c.43,.1,.82,.36,1.1,.74,.2,.27,.32,.27,.54-.02,.24-.31,.58-.54,.93-.62,.36-.09,.87-.02,1.24,.15,.57,.28,.89,.76,.93,1.41,.02,.23,.02,.25,.08,.31,.06,.06,.09,.07,.26,.08,.24,.03,.41,.08,.57,.19,.19,.14,.36,.4,.43,.67,.04,.16,.04,.47,0,.6-.07,.3-.29,.56-.6,.7-.29,.14-.28,.12-.26,.52,.02,.4-.02,.64-.12,.91-.25,.65-.84,1.12-1.62,1.28-.27,.05-.67,.05-.92,0-.5-.12-.9-.42-1.19-.87-.18-.27-.24-.34-.33-.34-.04,0-.17,.05-.31,.12-.32,.16-.52,.21-.83,.21-.52,0-.93-.19-1.32-.61l-.18-.19h-.39c-.3-.02-.42-.04-.55-.08-.66-.19-1.16-.7-1.37-1.39-.08-.28-.1-.77-.03-1.04,.18-.76,.72-1.3,1.49-1.49,.18-.05,.21-.07,.28-.22,.43-.84,1.28-1.25,2.15-1.04Z"
      />
      <path
        fill={fill}
        d="M3.21,3.77c-.49,.12-.9,.55-1.01,1.05-.06,.29-.02,.54,.12,.84,.17,.33,.51,.62,.86,.71,.16,.04,.42,.04,.59,0,.31-.08,.67-.33,.7-.48,0-.05,0-.1-.02-.14-.09-.17-.25-.18-.42-.02-.05,.05-.16,.12-.22,.15-.11,.05-.15,.06-.33,.06-.25,0-.4-.05-.55-.19-.19-.17-.28-.38-.28-.67,0-.52,.33-.86,.83-.86,.22,0,.33,.04,.52,.18,.11,.08,.16,.11,.22,.11,.17,0,.28-.18,.21-.33-.06-.12-.32-.29-.58-.38-.16-.06-.47-.07-.64-.03Z"
      />
      <path
        fill={fill}
        d="M5.66,3.77c-.23,.06-.39,.16-.58,.35-.54,.54-.56,1.29-.06,1.87,.12,.14,.39,.32,.57,.37,.18,.05,.47,.06,.64,0,.57-.17,.99-.78,.95-1.37-.01-.2-.05-.33-.15-.53-.1-.2-.39-.5-.59-.59-.28-.13-.54-.17-.79-.1Zm.4,.45c.35,.07,.58,.32,.64,.71,.06,.4-.11,.78-.43,.93-.13,.07-.16,.07-.35,.07s-.22,0-.35-.07c-.32-.16-.49-.52-.43-.93,.05-.37,.28-.63,.61-.7,.16-.03,.17-.03,.32,0Z"
      />
      <path
        fill={fill}
        d="M7.8,5.32c-.21,.08-.41,.28-.42,.42-.01,.11,.03,.18,.13,.23,.12,.06,.17,.03,.33-.13,.12-.13,.14-.14,.2-.13,.08,.02,.18,.13,.17,.19-.01,.04-.28,.31-.7,.71-.17,.16-.21,.24-.16,.36,.05,.13,.08,.13,.67,.13h.54l.06-.06c.09-.09,.09-.21,0-.31-.06-.07-.07-.07-.26-.08h-.2s.17-.17,.17-.17c.23-.22,.3-.36,.3-.6,0-.11-.01-.2-.03-.25-.06-.13-.18-.25-.29-.29-.12-.04-.38-.04-.49,0Z"
      />
    </svg>
  );
};

export default CO2;
