import type { ReactNode } from "react";

import s from "./EvaluationWizardInputError.module.scss";

type EvaluationWizardInputErrorProps = {
  id: string;
  children: ReactNode;
};

const EvaluationWizardInputError = ({
  id,
  children,
}: EvaluationWizardInputErrorProps) => {
  return (
    <div id={id} className={s.error}>
      {children}
    </div>
  );
};

export default EvaluationWizardInputError;
export type { EvaluationWizardInputErrorProps };
