import { DateTime, Settings } from "luxon";

/**
 * @type {Partial<{ [timezone: string]: Partial<{ [milliseconds: number]: DateTime }>}>}
 */
const millisToDateTime = {};

/**
 * @param {number} milliseconds
 * @param {string} timezone
 * @returns {DateTime}
 */
const getDateTimeFromMillis = (
  milliseconds,
  timezone = Settings.defaultZoneName,
  resetTime = false
) => {
  if (!millisToDateTime[timezone]) {
    millisToDateTime[timezone] = {};
  }

  let dateTime = millisToDateTime[timezone][milliseconds];

  if (!dateTime) {
    dateTime = DateTime.fromMillis(milliseconds, {
      zone: timezone,
    });
    millisToDateTime[timezone][milliseconds] = dateTime;
  }

  if (resetTime) {
    return dateTime
      .set({ minute: 0, second: 0, millisecond: 0 })
      .set({ hour: 0 });
  }

  return dateTime;
};

/**
 * @param {number} milliseconds
 * @param {{zone?: string, resetTime?: boolean}} options
 * @returns {DateTime}
 */
export const fromMillis = (milliseconds, { zone: timezone, resetTime } = {}) =>
  getDateTimeFromMillis(milliseconds, timezone, resetTime);

export const hourIndexToampm = (hour) => {
  const hourIn12 = ((hour + 11) % 12) + 1;
  const hourIn12Formatted =
    hourIn12 < 10 ? `0${hourIn12}:00` : `${hourIn12}:00`;
  return `${hourIn12Formatted} ${hour < 12 ? "AM" : "PM"}`;
};
