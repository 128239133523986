import KeyValue from "atomic-components/molecules/KeyValue";
import CollapsibleContainer from "atomic-components/molecules/CollapsibleContainer/CollapsibleContainer";
import { useTranslate } from "modules/language";
import WidgetGrid from "atomic-components/molecules/WidgetGrid/WidgetGrid";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import PerformanceMeter from "atomic-components/molecules/PerformanceMeter";
import useBuildingData from "modules/building/hooks/useBuildingData";
import UnitValue from "atomic-components/atoms/UnitValue";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import { useTotalEmissions } from "modules/building/hooks/useTotalEmissions";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";
import { useMergedNotifier } from "helpers/getNotifier";
import getRatio from "helpers/getRatio";
import { InsightsCategories } from "modules/building/helpers/insightsUtils";

const Summary = () => {
  const t = useTranslate();
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);
  const { totalEmissions, Notifier: ENotifier } = useTotalEmissions();
  const {
    resolutions,
    insights,
    Notifier: TNotifier,
  } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        resolutionKey: "1month",
        telemetries: [
          {
            telemetryName: "dailyMaximumUniqueEmployees",
          },
        ],
        insights: [
          {
            alias: "totalRecommendations",
            category: InsightsCategories.Recommendation,
          },
          {
            alias: "totalComfortAlarms",
            category: InsightsCategories.ComfortAlarm,
          },
        ],
      },
    ],
    [selectedEntity.id]
  );

  const { dailyMaximumUniqueEmployees } =
    resolutions["1month"][selectedEntity.id];

  const { totalRecommendations, totalComfortAlarms } =
    insights[selectedEntity.id];

  const Notifier = useMergedNotifier(ENotifier, TNotifier);

  return (
    <Notifier>
      <CollapsibleContainer title={t("label.SUMMARY")}>
        {/* tech-1448 */}
        {/* <WidgetContainer>
          <KeyValue
            keyText={t("label.SUSTAINABILITY_KPI")}
            growValue
            value={<PerformanceMeter value={null} />}
            isLight
          />
        </WidgetContainer> */}
        <WidgetContainer>
          <KeyValue
            keyText={t("label.AVG_EMISSIONS_PER_PERSON")}
            value={
              <>
                <UnitValue
                  value={getRatio(
                    totalEmissions,
                    dailyMaximumUniqueEmployees.data[0]?.value
                  )}
                  unit={unitsByName.kgco2e}
                />
                /
                <Displayer object={unitsByName.person} />
              </>
            }
            isLight
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            keyText={
              <>
                {t("label.AVG_EMISSIONS_PER_M")}
                <sup>2</sup>
              </>
            }
            value={
              <>
                <UnitValue
                  value={getRatio(totalEmissions, selectedEntity.area)}
                  unit={unitsByName.kgco2e}
                />
                /
                <Displayer object={unitsByName.m2} />
              </>
            }
            isLight
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            keyText={t("label.AVG_EMISSIONS_PER_DAY")}
            value={
              <>
                <UnitValue
                  value={getRatio(
                    totalEmissions,
                    dailyMaximumUniqueEmployees.days
                  )}
                  unit={unitsByName.kgco2e}
                />
                /{t("label.DAY")}
              </>
            }
            isLight
          />
        </WidgetContainer>
        <WidgetGrid>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.TOTAL_RECOMMENDATIONS")}
              value={totalRecommendations.length}
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.TOTAL_COMFORT_ALARMS")}
              value={totalComfortAlarms.length}
              isLight
            />
          </WidgetContainer>
        </WidgetGrid>
      </CollapsibleContainer>
    </Notifier>
  );
};

export default Summary;
