import withIconProps from "helpers/withIconProps";

const OnIcon = withIconProps(({ color, fill = "#3A6F00", ...props }) => {
  const currentColor = color ?? "currentcolor";

  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 4C0.5 1.79086 2.29086 0 4.5 0H18.5C20.7091 0 22.5 1.79086 22.5 4V16C22.5 18.2091 20.7091 20 18.5 20H4.5C2.29086 20 0.5 18.2091 0.5 16V4Z"
        fill={currentColor}
      />
      <path
        d="M11.6165 10.3636C11.6165 11.1402 11.4744 11.8078 11.1903 12.3665C10.9063 12.9228 10.5168 13.3513 10.022 13.652C9.52959 13.9503 8.9697 14.0994 8.34233 14.0994C7.71259 14.0994 7.15033 13.9503 6.65554 13.652C6.16312 13.3513 5.77486 12.9216 5.49077 12.3629C5.20668 11.8042 5.06463 11.1378 5.06463 10.3636C5.06463 9.58712 5.20668 8.92069 5.49077 8.36435C5.77486 7.80563 6.16312 7.37713 6.65554 7.07884C7.15033 6.77817 7.71259 6.62784 8.34233 6.62784C8.9697 6.62784 9.52959 6.77817 10.022 7.07884C10.5168 7.37713 10.9063 7.80563 11.1903 8.36435C11.4744 8.92069 11.6165 9.58712 11.6165 10.3636ZM10.5298 10.3636C10.5298 9.77178 10.4339 9.27344 10.2422 8.86861C10.0528 8.46141 9.79238 8.15365 9.46094 7.94531C9.13187 7.73461 8.759 7.62926 8.34233 7.62926C7.9233 7.62926 7.54924 7.73461 7.22017 7.94531C6.8911 8.15365 6.63068 8.46141 6.43892 8.86861C6.24953 9.27344 6.15483 9.77178 6.15483 10.3636C6.15483 10.9555 6.24953 11.455 6.43892 11.8622C6.63068 12.267 6.8911 12.5748 7.22017 12.7855C7.54924 12.9938 7.9233 13.098 8.34233 13.098C8.759 13.098 9.13187 12.9938 9.46094 12.7855C9.79238 12.5748 10.0528 12.267 10.2422 11.8622C10.4339 11.455 10.5298 10.9555 10.5298 10.3636ZM13.9611 10.7614V14H12.8993V8.54545H13.9185V9.43324H13.986C14.1114 9.14441 14.3079 8.91241 14.5755 8.73722C14.8453 8.56203 15.1851 8.47443 15.5946 8.47443C15.9663 8.47443 16.2918 8.55256 16.5712 8.70881C16.8506 8.86269 17.0672 9.09233 17.2211 9.39773C17.3749 9.70312 17.4519 10.0807 17.4519 10.5305V14H16.3901V10.6584C16.3901 10.263 16.2871 9.95407 16.0811 9.73153C15.8752 9.50663 15.5923 9.39418 15.2324 9.39418C14.9862 9.39418 14.7672 9.44744 14.5755 9.55398C14.3861 9.66051 14.2357 9.81676 14.1245 10.0227C14.0156 10.2263 13.9611 10.4725 13.9611 10.7614Z"
        fill={fill}
      />
    </svg>
  );
});

export default OnIcon;
