import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { useAuthenticationState } from "modules/authentication/state/authentication.state";
import StayLoggedInModal from "modules/authentication/components/StayLoggedInModal";
import { useModalState } from "atomic-components/molecules/Modal";

const LoginPage = lazy(() => import("modules/common/pages/Login"));

const AuthGuard = () => {
  const { loggedIn, showStayLoggedIn } = useAuthenticationState();
  const { hideModal } = useModalState();

  if (!loggedIn) {
    hideModal();
    return (
      <Suspense>
        <LoginPage />
      </Suspense>
    );
  }

  return (
    <>
      <Outlet />
      {showStayLoggedIn ? <StayLoggedInModal /> : null}
    </>
  );
};

export default AuthGuard;
