import classNames from "classnames";
import React from "react";

const OnOff = ({
  on = true,
  heating = false,
  color = "#7a7a7a",
  className = "",
  iconScale = 1,
  icon = true,
}) => {
  heating = heating === true || heating === 1;
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <defs>
        {heating ? (
          <linearGradient
            id="svg-icons-on-off__linear-gradient"
            x1="4.35"
            y1="103.67"
            x2="4.2"
            y2="105.89"
            gradientTransform="translate(0 106.34) scale(1 -1)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#76bb59" />
            <stop offset="1" stopColor="#a5cb61" />
          </linearGradient>
        ) : (
          <linearGradient
            id="svg-icons-on-off__linear-gradient"
            x1="5.62"
            y1="103.25"
            x2="5.52"
            y2="105.27"
            gradientTransform="translate(0 106.34) scale(1 -1)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#76bb59" />
            <stop offset="1" stopColor="#a5cb61" />
          </linearGradient>
        )}
        <path
          id="svg-icons-on-off__text-path"
          fill="none"
          stroke="red"
          d="M0,10 L11,10"
        />
      </defs>
      {heating ? (
        <>
          <path
            fill="url(#svg-icons-on-off__linear-gradient)"
            d="M1.17,.58H7.39c.57,0,1.04,.46,1.04,1.04h0c0,.57-.46,1.04-1.04,1.04H1.17C.59,2.65,.13,2.19,.13,1.61H.13C.13,1.04,.59,.58,1.17,.58Z"
          />
          <circle fill="#da4633" cx="9.99" cy="1.61" r="1.04" />
        </>
      ) : (
        <path
          fill="url(#svg-icons-on-off__linear-gradient)"
          d="M1.09,1.19H10.06c.52,0,.94,.42,.94,.94h0c0,.52-.42,.94-.94,.94H1.09c-.52,0-.94-.42-.94-.94H.14c0-.52,.42-.94,.94-.94Z"
        />
      )}
      <text>
        <textPath
          href="#svg-icons-on-off__text-path"
          style={{ fontFamily: "Inter, sans-serif", fontSize: "7px" }}
          textLength={on ? 10 : 11}
          lengthAdjust="spacingAndGlyphs"
          fill={color ?? "currentcolor"}
        >
          {on == null ? "???" : on ? "ON" : "OFF"}
        </textPath>
      </text>
    </svg>
  );
};

export default OnOff;
