import { ReactNode, useState } from "react";
import PercentageIndicator from "atomic-components/atoms/PercentageIndicator/PercentageIndicator";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import CollapsibleContainer from "atomic-components/molecules/CollapsibleContainer/CollapsibleContainer";
import classNames from "classnames";
import isRealNumber from "helpers/isRealNumber";
import type { Nullable } from "types/utils";

import s from "./ComplianceCategoryItem.module.scss";

type ComplianceCategoryItemProps = {
  title: ReactNode;
  achieved: Nullable<number>;
  maximumRequired: number;
  children: ReactNode | null;
  hasExpandableContent?: boolean;
};

const ComplianceCategoryItem = ({
  title,
  achieved,
  maximumRequired,
  children,
  hasExpandableContent = true,
}: ComplianceCategoryItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const commonProps = {
    title,
    className: classNames(s.container, s.itemContainer, {
      [s.isExpanded]: isExpanded,
      [s.isHighlighted]: !hasExpandableContent,
    }),
  };

  const collapsibleProps = hasExpandableContent
    ? {
        ...commonProps,
        isExpanded,
        setIsExpanded,
      }
    : commonProps;

  return (
    <CollapsibleContainer {...collapsibleProps}>
      <WidgetContainer className={s.widgetContainer}>
        <PercentageIndicator
          showPercentage={false}
          value={isRealNumber(achieved) ? achieved / maximumRequired : 0}
        />
        <span className={s.indicatorValue}>
          {isRealNumber(achieved) ? achieved : "-"}/{maximumRequired}
        </span>
      </WidgetContainer>
      {isExpanded ? children : null}
    </CollapsibleContainer>
  );
};

export default ComplianceCategoryItem;
