import withIconProps from "helpers/withIconProps";
const GraphBarIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      {...props}
    >
      <path
        d="M13 17V7M7 17V1M1 17V11"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default GraphBarIcon;
