import type { Units } from "./types";

const getUnits = (units: Units | Units[], index?: number) => {
  if (Array.isArray(units) && Array.isArray(units[0])) {
    return units[index ?? 0] as Units;
  }

  return units as Units;
};

export { getUnits };
