import React from "react";
import classNames from "classnames";

const RecommendationActioned = ({
  color = "var(--color-primary)",
  className = "",
  style = {},
  iconScale = 1,
  icon = true,
}) => {
  color = color ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        ...style,
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 22 22"
    >
      <circle r={11} cx={11} cy={11} fill="white" stroke="none" />
      <circle r={9} cx={11} cy={11} fill={color} stroke="none" />
      <circle r={7} cx={11} cy={11} fill="white" stroke="none" />
      <circle r={5} cx={11} cy={11} fill={color} stroke="none" />
    </svg>
  );
};

export default RecommendationActioned;
