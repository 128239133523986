import { QueryClient } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      staleTime: Infinity,
    },
  },
});

enum ReactQueryCacheKeys {
  EditableParamHistory = "editable-param-history",
  ComplianceScoreValues = "compliance-score-values",
  ComplianceScenarios = "compliance-scenarios",
  AlarmsCalls = "alarms-calls",
  FddMessages = "fdd-messages",
  AssetNotes = "asset-notes",
  AssetsContent = "assets-content",
}

export { queryClient, ReactQueryCacheKeys };
