import type { ReactNode } from "react";
import { Nullable } from "types/utils";
import { create } from "zustand";

type FormState<VALUES> = {
  /**
   * `defaultValues` are set by the user on creating the form
   * `defaultValues` are used to reset value
   */
  defaultValues: {
    [fieldName in keyof VALUES]: Nullable<VALUES[fieldName]>;
  };
  /**
   * `initialValues` are set to `defaultValues` and are updated when the values are set via `setValues`
   * `initialValues` are used to check if the form is dirty
   */
  initialValues: {
    [fieldName in keyof VALUES]: Nullable<VALUES[fieldName]>;
  };
  values: {
    [fieldName in keyof VALUES]: Nullable<VALUES[fieldName]>;
  };
  errors: { [fieldName in keyof VALUES]: string };
};

type FormFieldActions<VALUE> = {
  setDefaultValue: (defaultValue: Nullable<VALUE>) => void;
  setValue: (value: Nullable<VALUE>) => void;
  setError: (error: ReactNode) => void;
  /**
   * reset the value to its **default** value
   */
  resetValue: () => void;
};

type FormActions<VALUES> = {
  fieldActions: {
    [fieldName in keyof VALUES]: FormFieldActions<VALUES[fieldName]>;
  };
  isFormValid: () => boolean;
  isFormDirty: () => boolean;
  setValues: (value: VALUES) => void;
};

type FormStore<VALUES> = FormState<VALUES> & FormActions<VALUES>;

const createFormStoreHook = <VALUES extends {}>({
  defaultValues,
}: {
  defaultValues: VALUES;
}) => {
  return create<FormStore<VALUES>>((set, get) => {
    return {
      defaultValues,
      initialValues: defaultValues,
      values: defaultValues,
      errors: Object.fromEntries(
        Object.keys(defaultValues).map((fieldName) => {
          return [[fieldName], ""];
        })
      ),
      setValues: (values) => {
        set(() => {
          return {
            values,
            initialValues: values,
          };
        });
      },
      isFormValid: () => {
        return isFormValid(get());
      },
      isFormDirty: () => {
        return isFormDirty(get());
      },
      fieldActions: Object.fromEntries(
        Object.keys(defaultValues).map((key) => {
          const fieldName = key as keyof VALUES;

          return [
            [fieldName],
            {
              setDefaultValue: (defaultValue: VALUES[typeof fieldName]) => {
                set((state) => {
                  return {
                    defaultValues: {
                      ...state.defaultValues,
                      [fieldName]: defaultValue,
                    },
                  };
                });
              },
              setValue: (value: VALUES[typeof fieldName]) => {
                set((state) => {
                  return {
                    values: {
                      ...state.values,
                      [fieldName]: value,
                    },
                  };
                });
              },
              setError: (error: string) => {
                set((state) => {
                  return {
                    errors: {
                      ...state.errors,
                      [fieldName]: error,
                    },
                  };
                });
              },
              resetValue: () => {
                set((state) => {
                  return {
                    values: {
                      ...state.values,
                      [fieldName]: state.defaultValues[fieldName],
                    },
                  };
                });
              },
            },
          ];
        })
      ),
    };
  });
};

const isFormValid = <VALUES>(state: FormState<VALUES>) => {
  const hasError = Object.values(state.errors).find((error) => error);

  return !hasError;
};

const isFormDirty = <VALUES extends {}>(state: FormState<VALUES>) => {
  const { values, initialValues } = state;
  const hasChange = Object.entries(values).find(([fieldName, value]) => {
    return value !== initialValues[fieldName as keyof VALUES];
  });

  return !!hasChange;
};

export { createFormStoreHook, isFormValid, isFormDirty };
export type { FormFieldActions, FormState, FormActions, FormStore };
