import Section from "atomic-components/atoms/Section";
import ScenarioPreview from "./ScenarioPreview/ScenarioPreview";
import {
  MAX_PINNED_SCENARIOS,
  leedOmCertificateLevels,
  type Scenario,
  LeedOmCertificateLevelsEnum,
} from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ComplianceTrackingPanel/Certificates/LeedOmCertificate/utils";
import { useMemo } from "react";
import { useTranslate } from "modules/language";
import { Filters, SortByOptions } from "../ScenarioFilters/ScenarioFilters";
import { Nullable } from "types/utils";

import s from "./ScenariosList.module.scss";
import { getSubFields, getTotalScenarioScore } from "../utils";
import { ScenarioFieldType } from "modules/building/pages/SustainabilityPage/components/ScenarioDetailsModal/ScenarioDetailsForm/ScenarioDetailsForm";
import { flatten } from "lodash";
import { complianceScoresContent } from "modules/building/pages/SustainabilityPage/utils";

export const getScenarioTotalScore = (scenario: Scenario | undefined) => {
  if (!scenario) return 0;

  const categoriesFamily = complianceScoresContent.filter(
    ({ key }) => key !== "total"
  );
  const mainFields = flatten(categoriesFamily.map(({ children }) => children));
  const subFields = flatten(
    mainFields.map((field) => getSubFields(field as ScenarioFieldType))
  );
  const scenarioFields = [...mainFields, ...subFields];

  const { targetValues } = scenario;

  const totalScenarioScore =
    targetValues.total ||
    getTotalScenarioScore({
      fieldsScores: targetValues,
      scenarioFields: scenarioFields as ScenarioFieldType[],
    });
  return totalScenarioScore;
};

type ScenariosListProps = {
  scenarios: Scenario[];
  targetScore: Nullable<number>;
  selectedFilter: Filters;
  selectedSortBy: SortByOptions;
};

const ScenariosList = ({
  scenarios,
  targetScore,
  selectedFilter,
  selectedSortBy,
}: ScenariosListProps) => {
  const t = useTranslate();

  const isAscending = selectedSortBy === SortByOptions.ASCENDING;

  // sorting and filtering
  const activeScenario = scenarios.find((scenario) => scenario.isActive);
  const pinnedScenarios = scenarios
    .filter((scenario) => scenario.isPinned)
    .filter((scenario) => !scenario.isActive);
  const restOfScenarios = scenarios
    .filter((scenario) => !scenario.isPinned && !scenario.isActive)
    .sort((a, b) => {
      return isAscending
        ? getScenarioTotalScore(a) - getScenarioTotalScore(b)
        : getScenarioTotalScore(b) - getScenarioTotalScore(a);
    })
    .filter(({ targetValues: { total: score } }) => {
      const getIsAboveLevel = (passedLevel: LeedOmCertificateLevelsEnum) =>
        (score || 0) >=
        (leedOmCertificateLevels.find((level) => level.id === passedLevel)
          ?.min || 0);

      if (selectedFilter === Filters.LEED_OM_SILVER_PLUS) {
        return getIsAboveLevel(LeedOmCertificateLevelsEnum.Silver);
      } else if (selectedFilter === Filters.LEED_OM_GOLD_PLUS) {
        return getIsAboveLevel(LeedOmCertificateLevelsEnum.Gold);
      } else if (selectedFilter === Filters.LEED_OM_PLATINUM) {
        return getIsAboveLevel(LeedOmCertificateLevelsEnum.Platinum);
      } else {
        return true;
      }
    });

  const shownScenarios: Scenario[] = useMemo(
    () => [
      ...(activeScenario ? [activeScenario] : []),
      ...pinnedScenarios,
      ...restOfScenarios,
    ],
    [activeScenario, pinnedScenarios, restOfScenarios]
  );

  const pinnedScenariosLength = scenarios.filter(
    (scenario) => scenario.isPinned
  ).length;
  const isPinnedScenariosLimitReached =
    pinnedScenariosLength >= MAX_PINNED_SCENARIOS;

  return (
    <Section>
      {shownScenarios.map((scenario) => (
        <ScenarioPreview
          key={scenario.id}
          scenario={scenario}
          targetScore={targetScore}
          activeScenario={activeScenario}
          isPinnedScenariosLimitReached={isPinnedScenariosLimitReached}
        />
      ))}
      {shownScenarios.length === 0 ? (
        <div className={s.noScenariosContainer}>
          {t("message.NO_SCENARIOS")}
        </div>
      ) : null}
    </Section>
  );
};

export default ScenariosList;
