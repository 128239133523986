import TabsView, { Tab } from "atomic-components/organisms/TabsView/TabsView";
import ComingSoon from "atomic-components/atoms/ComingSoon";

import s from "./RightMainTabs.module.scss";

type TabWithView<TAB_KEY extends string> = Tab<TAB_KEY> & {
  ViewComponent?: React.ComponentType;
};

type RightMainTabsProps<TAB_KEY extends string> = {
  tabs: TabWithView<TAB_KEY>[];
  selectedTabKey: TAB_KEY;
  setSelectedTabKey: (tabKey: TAB_KEY) => void;
};

const RightMainTabs = <TAB_KEY extends string>({
  tabs,
  selectedTabKey,
  setSelectedTabKey,
}: RightMainTabsProps<TAB_KEY>) => {
  const selectedTab = tabs.find(({ key }) => key === selectedTabKey) ?? tabs[0];

  const ViewComponent = selectedTab?.ViewComponent ?? ComingSoon;

  if (tabs.length === 0) {
    return null;
  }

  return (
    <TabsView
      tabs={tabs}
      skin="box"
      selectedTabKey={selectedTabKey}
      setSelectedTabKey={setSelectedTabKey}
      tabsClassName={s.mainTabs}
      panelClassName={s.mainTabsContent}
    >
      <ViewComponent />
    </TabsView>
  );
};

export default RightMainTabs;
export type { RightMainTabsProps, TabWithView };
