import { useMemo } from "react";
import flattenWithChildren from "helpers/flattenWithChildren";
import getEntitiesConnections from "helpers/getEntitiesConnections";
import getStableKey from "helpers/getStableKey";
import {
  cloneDeep,
  flatten,
  isFunction,
  isPlainObject,
  uniqBy,
  get,
  type Dictionary,
} from "lodash";
import { ONE_HOUR } from "modules/building/helpers/intervals";
import type {
  BuildingData,
  Entity,
} from "modules/building/helpers/postProcessBuildingData";
import config from "config";
import isRealNumber from "helpers/isRealNumber";
import isNotEmpty from "helpers/isNotEmpty";
import type { Insight, InsightPayloadTypeData } from "types/insight";
import hardcoded from "helpers/hardcoded";

const consumptionTypeKey = hardcoded({
  Electricity: "electricity",
  ChilledWater: "chilledWater",
  Lighting: "lighting",
});

const consumptionTypes = hardcoded([
  {
    key: consumptionTypeKey.Electricity,
    dataKeys: ["HVAC", "UPS", "Lighting"],
    priceKey: "electricity",
    translationKey: "label.ELECTRICITY",
    unitName: "kw",
  },
  {
    key: consumptionTypeKey.ChilledWater,
    dataKeys: ["CW"],
    priceKey: "chilledWater",
    translationKey: "label.CHILLED_WATER",
    unitName: "kwt",
  },
]);

export enum InsightsCategories {
  Recommendation = "recommendation",
  ComfortAlarm = "comfortAlarm",
  Fault = "fault",
  Temperature = "temperature",
  RelativeHumidity = "relativeHumidity",
  VOC = "VOC",
  CO2 = "CO2",
}

export enum InsightsSystems {
  HVAC = "HVAC",
  Lighting = "Lighting",
  UPS = "UPS",
  PV = "PV",
}

type FilterFunction = (insightSystem: Insight, ...rest: any[]) => boolean;

type BasePostProcessorsFnParams = {
  lastTs: number;
  buildingData: BuildingData;
  groups: Dictionary<Insight[]>;
};

type PostProcessor = (
  insight: Insight,
  params: BasePostProcessorsFnParams
) => Insight;

export type InsightsType = {
  childrenTypes: (typeof childrenTypes)[keyof typeof childrenTypes];
  connectionsChains: (typeof connectionsChains)[keyof typeof connectionsChains];
  filter: FilterFunction;
  postProcessor: PostProcessor;
};

export type InsightsDimention = {
  system?: InsightsSystems;
  category?: InsightsCategories;
};

const createFilter = (
  filterDefenition:
    | object
    | Array<any>
    | FilterFunction
    | number
    | null
    | undefined
    | string
): FilterFunction => {
  if (Array.isArray(filterDefenition)) {
    const ors = filterDefenition.map((filterDefenition) =>
      createFilter(filterDefenition)
    );
    return (...args) => ors.some((filterFunction) => filterFunction(...args));
  }
  if (isFunction(filterDefenition)) {
    return filterDefenition;
  }
  if (filterDefenition == null) {
    return (value) => value == null;
  }
  if (isPlainObject(filterDefenition)) {
    const ands = Object.entries(filterDefenition).map(([key, value]) => {
      const filterFunction = createFilter(value);
      return (
        recommendation: Parameters<FilterFunction>[0],
        ...rest: any[]
      ) => {
        const value = get(recommendation, key);
        return filterFunction(value, ...rest);
      };
    });
    return (recommendation, ...rest) =>
      !ands.some((filterFunction) => !filterFunction(recommendation, ...rest));
  }

  return (value) => value === filterDefenition;
};

const andFilters = (...filtersFunctions: FilterFunction[]) => {
  return (...args: Parameters<FilterFunction>) =>
    !filtersFunctions.some((filterFunction) => !filterFunction(...args));
};

export const insightsCategoriesFilters = {
  [InsightsCategories.Recommendation]: createFilter({ "payload.type": "OPR" }),
  [InsightsCategories.ComfortAlarm]: createFilter({
    "payload.type": (type: string) => type?.startsWith("USC"),
  }),
  [InsightsCategories.Fault]: createFilter({ "payload.type": "FLT" }),
  [InsightsCategories.Temperature]: createFilter({
    "payload.type": (type: string) => ["USCHT", "USCLT"].includes(type),
  }),
  [InsightsCategories.RelativeHumidity]: createFilter({
    "payload.type": (type: string) => ["USCHR", "USCLR"].includes(type),
  }),
  [InsightsCategories.VOC]: createFilter({ "payload.type": "USCHV" }),
  [InsightsCategories.CO2]: createFilter({ "payload.type": "USCHC" }),
};

export const insightsSystemsFilters = {
  [InsightsSystems.HVAC]: createFilter({
    "payload.system": InsightsSystems.HVAC,
  }),
  [InsightsSystems.Lighting]: createFilter({
    "payload.system": InsightsSystems.Lighting,
  }),
  [InsightsSystems.UPS]: createFilter({
    "payload.system": InsightsSystems.UPS,
  }),
  [InsightsSystems.PV]: createFilter({
    "payload.system": InsightsSystems.PV,
  }),
};

const childrenTypes = {
  default: [
    "building",
    "floor",
    "zone",
    "zone-1",
    "zone-2",
    "ahu-zone",
    "ahu",
    "pim",
  ],
};

const connectionsChains = {
  default: [["pim", "ahu"], ["zone-1"]],
};

const basePostProcessors: Record<string, PostProcessor> = {
  identity: (insight: Insight) => insight,
  ensureTsIsNumber: (
    insight: Insight,
    { lastTs }: BasePostProcessorsFnParams
  ) => {
    insight.startTs = Number(insight.startTs);
    insight.endTs = Number(insight.endTs ?? lastTs);
    insight.createdTs = Number(insight.createdTs);
    insight.payload.detectionTs = Number(insight.payload.detectionTs);
    insight.payload.interventionStartTs = insight.payload.interventionStartTs
      ? Number(insight.payload.interventionStartTs)
      : null;
    insight.payload.interventionEndTs = insight.payload.interventionEndTs
      ? Number(insight.payload.interventionEndTs)
      : null;
    return insight;
  },
  addIsActive: (insight: Insight, { lastTs }: BasePostProcessorsFnParams) => {
    const endTs = insight.endTs ?? lastTs;
    insight.isActive = !insight.endTs;
    insight.endTs = endTs;
    return insight;
  },
  addActiveDurations: (insight: Insight) => {
    const startTs = insight.startTs;
    const endTs = insight.endTs;
    insight.activeDurations = [[startTs, endTs]];
    return insight;
  },
  addDuration: (insight: Insight) => {
    const startTs = insight.startTs;
    const endTs = insight.endTs;
    insight.duration = (endTs - startTs) / ONE_HOUR;
    return insight;
  },
  addOccurances: (insight: Insight, { groups }: BasePostProcessorsFnParams) => {
    const occurances = groups[insight.payload.tag];
    insight.occurancesCount = occurances.length;
    insight.occurances = occurances;
    return insight;
  },
  normalizeSavings: (
    insight: Insight,
    { buildingData }: { buildingData: BuildingData }
  ) => {
    const payload = insight.payload;
    const dataByType = payload.dataByType ? { ...payload.dataByType } : null;
    if (!dataByType) {
      return insight;
    }
    let totalMissedSavings = 0;
    let totalActualSavings = 0;
    const { prices, currency, unitsByName } = buildingData;
    const invalidDataKeys = Object.keys(dataByType).filter(
      (dataKey) =>
        !consumptionTypes.find((consumptionType) =>
          consumptionType.dataKeys.includes(dataKey)
        )
    );
    if (invalidDataKeys.length) {
      console.warn(`INVALID TYPESDATA TYPE`, { invalidDataKeys });
      if (!config.isProd) {
        debugger;
        throw new Error(`INVALID TYPESDATA TYPE`);
      }
    }
    const dataByTypeEntries = consumptionTypes
      .map(({ key, dataKeys, priceKey, unitName, translationKey }) => {
        const price = prices[priceKey];

        let missedSavingsInUnit = 0,
          actualSavingsInUnit = 0,
          missedSavings = 0,
          actualSavings = 0,
          data: InsightPayloadTypeData | undefined;

        dataKeys.forEach((dataKey) => {
          const currentData = dataByType[dataKey];
          if (!currentData) {
            return;
          }
          data = { ...data, ...currentData };
          const {
            missedSavings: currentMissedSavingsInUnit,
            actualSavings: currentActualSavingsInUnit,
          } = currentData;
          const currentMissedSavings = currentMissedSavingsInUnit * price;
          const currentActualSavings = currentActualSavingsInUnit * price;
          if (isRealNumber(currentMissedSavings)) {
            totalMissedSavings += currentMissedSavings;
            missedSavings += currentMissedSavings;
            missedSavingsInUnit += currentMissedSavingsInUnit;
          }
          if (isRealNumber(currentActualSavings)) {
            totalActualSavings += currentActualSavings;
            actualSavings += currentActualSavings;
            actualSavingsInUnit += currentActualSavingsInUnit;
          }
        });

        if (!data) {
          return undefined;
        }

        return [
          key,
          {
            ...data,
            missedSavings,
            actualSavings,
            missedSavingsInUnit,
            actualSavingsInUnit,
            price,
            priceKey,
            unit: unitsByName[unitName],
            currency,
            translationKey,
          },
        ] as const;
      })
      .filter(isNotEmpty);

    if (dataByTypeEntries.length) {
      payload.dataByType = Object.fromEntries(dataByTypeEntries);
      payload.missedSavings = totalMissedSavings;
      payload.actualSavings = totalActualSavings;
      payload.currency = currency;
      payload.dataTypes = dataByTypeEntries.map(([key]) => key);
    } else {
      delete payload.dataByType;
      delete payload.missedSavings;
      delete payload.actualSavings;
      delete payload.currency;
      delete payload.dataTypes;
    }
    return insight;
  },
  addSuccessRate: (insight: Insight) => {
    const payload = insight.payload;
    const { missedSavings, actualSavings } = payload;
    if (isRealNumber(missedSavings) && isRealNumber(actualSavings)) {
      payload.successRate =
        payload.successRate ??
        (missedSavings + actualSavings === 0
          ? 0
          : actualSavings / (missedSavings + actualSavings));
    }
    return insight;
  },
};

const createPostProcessor =
  (postProcessorsNames: (keyof typeof basePostProcessors)[]) =>
  (insight: Insight, args: BasePostProcessorsFnParams) => {
    insight = cloneDeep(insight);
    for (let i = 0; i < postProcessorsNames.length; i++) {
      insight =
        basePostProcessors[postProcessorsNames[i]](insight, args) ?? insight;
    }
    return insight;
  };

const postProcessors = {
  default: createPostProcessor([
    "addIsActive",
    "ensureTsIsNumber",
    "addDuration",
    "addActiveDurations",
    "addOccurances",
    "normalizeSavings",
    "addSuccessRate",
  ]),
};

export const insightsTypes: Array<{
  dimention: InsightsDimention;
  insightsType: InsightsType;
}> = [
  {
    dimention: {},
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: () => true,
      postProcessor: postProcessors["default"],
    },
  },
  {
    dimention: { category: InsightsCategories.Temperature },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: () => true,
      postProcessor: postProcessors["default"],
    },
  },
  ...Object.values(InsightsSystems).map((system: InsightsSystems) => ({
    dimention: { system, category: InsightsCategories.Temperature },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: andFilters(
        insightsCategoriesFilters[InsightsCategories.Temperature],
        insightsSystemsFilters[system]
      ),
      postProcessor: postProcessors["default"],
    },
  })),
  {
    dimention: { category: InsightsCategories.RelativeHumidity },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: () => true,
      postProcessor: postProcessors["default"],
    },
  },
  ...Object.values(InsightsSystems).map((system: InsightsSystems) => ({
    dimention: { system, category: InsightsCategories.RelativeHumidity },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: andFilters(
        insightsCategoriesFilters[InsightsCategories.RelativeHumidity],
        insightsSystemsFilters[system]
      ),
      postProcessor: postProcessors["default"],
    },
  })),
  {
    dimention: { category: InsightsCategories.VOC },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: () => true,
      postProcessor: postProcessors["default"],
    },
  },
  ...Object.values(InsightsSystems).map((system: InsightsSystems) => ({
    dimention: { system, category: InsightsCategories.VOC },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: andFilters(
        insightsCategoriesFilters[InsightsCategories.VOC],
        insightsSystemsFilters[system]
      ),
      postProcessor: postProcessors["default"],
    },
  })),
  {
    dimention: { category: InsightsCategories.CO2 },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: () => true,
      postProcessor: postProcessors["default"],
    },
  },
  ...Object.values(InsightsSystems).map((system: InsightsSystems) => ({
    dimention: { system, category: InsightsCategories.CO2 },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: andFilters(
        insightsCategoriesFilters[InsightsCategories.CO2],
        insightsSystemsFilters[system]
      ),
      postProcessor: postProcessors["default"],
    },
  })),
  {
    dimention: { category: InsightsCategories.Recommendation },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: insightsCategoriesFilters[InsightsCategories.Recommendation],
      postProcessor: postProcessors["default"],
    },
  },
  ...Object.values(InsightsSystems).map((system: InsightsSystems) => ({
    dimention: { system, category: InsightsCategories.Recommendation },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: andFilters(
        insightsCategoriesFilters[InsightsCategories.Recommendation],
        insightsSystemsFilters[system]
      ),
      postProcessor: postProcessors["default"],
    },
  })),
  {
    dimention: { category: InsightsCategories.ComfortAlarm },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: insightsCategoriesFilters[InsightsCategories.ComfortAlarm],
      postProcessor: postProcessors["default"],
    },
  },
  ...Object.values(InsightsSystems).map((system: InsightsSystems) => ({
    dimention: { system, category: InsightsCategories.ComfortAlarm },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: andFilters(
        insightsCategoriesFilters[InsightsCategories.ComfortAlarm],
        insightsSystemsFilters[system]
      ),
      postProcessor: postProcessors["default"],
    },
  })),
  {
    dimention: { category: InsightsCategories.Fault },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: insightsCategoriesFilters[InsightsCategories.Fault],
      postProcessor: postProcessors["default"],
    },
  },
  ...Object.values(InsightsSystems).map((system: InsightsSystems) => ({
    dimention: { system, category: InsightsCategories.Fault },
    insightsType: {
      childrenTypes: childrenTypes["default"],
      connectionsChains: connectionsChains["default"],
      filter: andFilters(
        insightsCategoriesFilters[InsightsCategories.Fault],
        insightsSystemsFilters[system]
      ),
      postProcessor: postProcessors["default"],
    },
  })),
];

export const indexedInsightsTypes = Object.fromEntries(
  insightsTypes.map((filter) => [
    getStableKey(filter.dimention),
    filter.insightsType,
  ])
);

export const getRelations = (
  entity: Entity,
  childrenTypes: string[],
  connectionsChains: string[][]
): Entity[] => {
  const relatedDescendants = flattenWithChildren(entity ? entity : []).filter(
    (entity) => childrenTypes.includes(entity.type)
  );
  const relatedConnections = uniqBy(
    flatten(
      connectionsChains.map((connectionsChain) =>
        getEntitiesConnections(relatedDescendants, connectionsChain)
      )
    ),
    "id"
  );
  const relatedConnectionsDescendants = flattenWithChildren(
    relatedConnections
  ).filter((entity) => childrenTypes.includes(entity.type));
  return uniqBy(
    [
      entity,
      ...relatedDescendants,
      ...relatedConnections,
      ...relatedConnectionsDescendants,
    ],
    "id"
  ).filter(Boolean);
};

export const isInsightActiveAtTimestamp = (
  insight: Insight,
  timestamp: number
) => insight.startTs <= timestamp && timestamp <= insight.endTs;
export const filterInsightsAtTimestamp = (
  insights: Insight[],
  timestamp: number
) =>
  insights.filter((insight) => isInsightActiveAtTimestamp(insight, timestamp));

export const useInsightsAtTimestamp = (
  insights: Insight[],
  timestamp: number
) =>
  useMemo(
    () => filterInsightsAtTimestamp(insights, timestamp),
    [insights, timestamp]
  );

export const useMultiInsightsAtTimestamp = (
  insights: { [insightsKey: string]: Insight[] },
  timestamp: number
) =>
  useMemo(
    () =>
      Object.fromEntries(
        Object.entries(insights).map(([insightsKey, insights]) => {
          return [insightsKey, filterInsightsAtTimestamp(insights, timestamp)];
        })
      ),
    [insights, timestamp]
  );
