import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import Loader from "atomic-components/atoms/Loader";
import type { ReactNode } from "react";

import s from "./TooltipAlert.module.scss";

type TooltipAlertProps = {
  iconType: "loader" | "warning" | "error";
  tooltip: ReactNode;
};

const TooltipAlert = ({ iconType, tooltip }: TooltipAlertProps) => {
  let icon = (
    <Loader marginTop={0} size={16} borderWidth={2} display={"block"} />
  );

  if (iconType === "error") {
    icon = <FontAwesomeIcon icon={faExclamationTriangle} color="red" />;
  }

  if (iconType === "warning") {
    icon = <FontAwesomeIcon icon={faExclamationTriangle} color="orange" />;
  }

  return (
    <InfoPopover
      className={s.popoverContainer}
      addSpaceBefore={false}
      addSpaceAfter={false}
      portal
      offset={2}
    >
      <div className={s.icon}>{icon}</div>
      <div className={s.tooltip}>{tooltip}</div>
    </InfoPopover>
  );
};

export default TooltipAlert;
export type { TooltipAlertProps };
