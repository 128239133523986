import { useEffect, useState, useMemo } from "react";
import { getLiveListener } from "modules/building/helpers/liveListener";
import useBuildingData from "./useBuildingData";

const useLastTimestamp = () => {
  const { defaultInterval } = useBuildingData();
  const liveListener = useMemo(() => {
    return getLiveListener({ defaultInterval });
  }, [defaultInterval]);

  const [lastTimestamp, setLastTimeStamp] = useState(
    liveListener.getLastTimestamp
  );

  useEffect(() => {
    return liveListener.addListener(({ timestamp }) =>
      setLastTimeStamp(timestamp)
    );
  }, []);
  return lastTimestamp;
};

export default useLastTimestamp;
