import Button from "atomic-components/atoms/Buttons/Button";
import ModalFooter from "atomic-components/molecules/Modal/ModalFooter";
import ModalHeader from "atomic-components/molecules/Modal/ModalHeader";
import { useTranslate } from "modules/language";
import { useModalState } from "atomic-components/molecules/Modal";
import Accordion from "atomic-components/atoms/Accordion";
import { useId, useState } from "react";
import type { ComplianceScore, ComplianceScoreValues } from "../../../types";
import EvaluationWizardGroupWidget from "../EvaluationWizardGroupWidget/EvaluationWizardGroupWidget";
import type { FormStore } from "helpers/createFormStoreHook";
import DotsLoader from "atomic-components/atoms/DotsLoader";
import EvaluationWizardTargetSelect from "../EvaluationWizardTargetSelect/EvaluationWizardTargetSelect";
import type { Nullable } from "types/utils";
import { complianceScoresTotalContent } from "../../../utils";

import s from "./EvaluationWizardModalBody.module.scss";

type EvaluationWizardModalBodyProps<COMPLIANCE_SCORE_KEY extends string> = {
  complianceScoresContent: ComplianceScore<COMPLIANCE_SCORE_KEY>[];
  isDataLoading: boolean;
  hasDataError: boolean;
  formStore: FormStore<ComplianceScoreValues<COMPLIANCE_SCORE_KEY>>;
  handleSubmit: () => Promise<void>;
  total: Nullable<number>;
  fallbackValues: Partial<ComplianceScoreValues<COMPLIANCE_SCORE_KEY>>;
  isFirstTime: boolean;
};

const EvaluationWizardModalBody = <COMPLIANCE_SCORE_KEY extends string>({
  complianceScoresContent,
  isDataLoading,
  hasDataError,
  formStore,
  handleSubmit,
  total,
  fallbackValues,
  isFirstTime,
}: EvaluationWizardModalBodyProps<COMPLIANCE_SCORE_KEY>) => {
  const t = useTranslate();
  const { hideModal } = useModalState();
  const formElementId = useId();
  const errorElementId = `${formElementId}-error`;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const disabled = isDataLoading || hasDataError || isSubmitting;

  return (
    <>
      <ModalHeader
        color="primary"
        cancel={{
          text: t("action.CANCEL"),
        }}
      >
        <h2 className={s.headerText}>{t("title.EVALUATION_WIZARD")}</h2>
      </ModalHeader>
      <p className={s.hint}>{t("message.EVALUATION_WIZARD_HINT")}</p>
      {hasDataError && (
        <p id={errorElementId} className={s.error}>
          {t("error.ERROR_DURING_LOADING_DATA")}
        </p>
      )}
      <form
        id={formElementId}
        aria-describedby={errorElementId}
        className={s.form}
        onSubmit={async (e) => {
          e.preventDefault();

          if (disabled) {
            return;
          }

          setSubmitError("");

          if (!formStore.isFormValid()) {
            setSubmitError(
              t("error.NOT_ALL_FIELDS_ARE_FILLED_WITH_PROPER_VALUES")
            );
            return;
          }

          if (!formStore.isFormDirty()) {
            hideModal();
            return;
          }

          setIsSubmitting(true);

          handleSubmit()
            .then(() => {
              hideModal();
            })
            .catch(() => {
              setSubmitError(t("error.SERVER_ERROR_OCCURRED"));
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        }}
      >
        {isDataLoading && <DotsLoader hasBlockSpace />}
        <div className={s.targetContainer}>
          <EvaluationWizardTargetSelect
            formStore={formStore}
            disabled={disabled}
          />
          <div className={s.totalCredits}>
            <span>{t("label.TOTAL_CREDITS")}</span>{" "}
            <span>
              {total ?? "-"}/{complianceScoresTotalContent.maximumRequired}
            </span>
          </div>
        </div>
        <Accordion className={s.accordion}>
          {complianceScoresContent.map((score) => {
            if (!score.isEditable) {
              return null;
            }
            return (
              <EvaluationWizardGroupWidget
                key={score.key}
                compliaceScoreContent={score}
                disabled={disabled}
                formStore={formStore}
                fallbackValues={fallbackValues}
                isFirstTime={isFirstTime}
              />
            );
          })}
        </Accordion>
      </form>
      {isSubmitting && <DotsLoader hasBlockSpace />}
      {submitError && (
        <p id={errorElementId} className={s.error}>
          {submitError}
        </p>
      )}
      <ModalFooter className={s.footer} justifyContent="space-between">
        <Button skin="outline" className={s.btn} onClick={() => hideModal()}>
          {t("action.CANCEL")}
        </Button>
        <Button
          skin="primary"
          form={formElementId}
          type="submit"
          className={s.btn}
          disabled={disabled}
        >
          {t("action.CONFIRM")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default EvaluationWizardModalBody;
