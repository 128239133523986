import usePortal from "hooks/usePortal";
import usePrioritizerFactory from "modules/building/components/Model/hooks/usePrioritizerFactory";
import useEvent from "modules/building/hooks/useEvent";
import React, {
  Context,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useAnnotations from "modules/building/hooks/useAnnotations";
import {
  type EntitiesAnnotations,
  type FloatingRightPanel,
  type FocusedEntityOptions,
  type HeatmapOptions,
  type PageContextValue,
  type PageProviderProps,
  type ScrollListener,
  type ShownBottomPanel,
  type UseOnModelClickListenerHook,
} from "./types";
import { HoverDataArgs, ModelOnClick, OnClickArgs } from "../../Model/Model";
import { Setter } from "types/utils";
import useRightTabKey from "modules/building/hooks/useRightTabKey";

const PageContext = React.createContext<PageContextValue>({
  shownRightPanel: true,
  setShownRightPanel: () => {},
  shownBottomPanel: null,
  setShownBottomPanel: () => {},
  heatmapOptions: null,
  setHeatmapOptions: () => {},
  focusedEntityOptions: null,
  setFocusedEntityOptions: () => {},
  rolledOverEntityId: null,
  setRolledOverEntityId: () => {},
  isAveragingToolEnabled: false,
  setIsAveragingToolEnabled: () => {},
  onModelClick: () => {},
  useOnModelClickListener: () => <></>,
  hoverPrioritizer: () => 0,
  entitiesAnnotations: null,
  setEntitiesAnnotations: () => {},
  entitiesSideAnnotations: null,
  setEntitiesSideAnnotations: () => {},
  showAnnotations: false,
  setShowAnnotations: () => {},
  selectedRightTabKey: "",
  setSelectedRightTabKey: () => {},
  rightPanelTabs: [],
  floatingRightPanel: null,
  setFloatingRightPanel: () => {},
  FloatingRightPanelTarget: forwardRef(() => <></>),
  onRightMenuScrollChange: () => {},
  useRightMenuScrollListener: () => {},
});

const PageProvider = <RIGHT_TAB_KEY extends string>({
  children,
  rightPanelTabs = [],
  isDefaultRightPane = true,
}: PageProviderProps<RIGHT_TAB_KEY>) => {
  const [shownRightPanel, setShownRightPanel] = useState(isDefaultRightPane);

  const [shownBottomPanel, setShownBottomPanel] =
    useState<ShownBottomPanel<string> | null>(null);

  const [heatmapOptions, setHeatmapOptions] = useState<HeatmapOptions | null>(
    null
  );

  const [focusedEntityOptions, setFocusedEntityOptions] =
    useState<FocusedEntityOptions | null>(null);

  const [rolledOverEntityId, setRolledOverEntityId] = useState<string | null>(
    null
  );

  const [isAveragingToolEnabled, setIsAveragingToolEnabled] =
    useState<boolean>(false);

  const [onModelClick, useOnModelClickListener] = useEvent() as [
    ModelOnClick<OnClickArgs>,
    UseOnModelClickListenerHook
  ];

  const [entitiesAnnotations, setEntitiesAnnotations] = useAnnotations() as [
    EntitiesAnnotations | null,
    Setter<EntitiesAnnotations | null>
  ];

  const [entitiesSideAnnotations, setEntitiesSideAnnotations] =
    useAnnotations() as [
      EntitiesAnnotations | null,
      Setter<EntitiesAnnotations | null>
    ];

  const [showAnnotations, setShowAnnotations] = useState(true);

  const { selectedRightTabKey, setSelectedRightTabKey } = useRightTabKey({
    rightMainTabKeys: rightPanelTabs.map(({ key }) => key),
  });

  const [floatingRightPanel, setFloatingRightPanel] =
    useState<FloatingRightPanel | null>(null);

  const { Portal: FloatingRightPanelPortal, Target: FloatingRightPanelTarget } =
    usePortal();

  const [onRightMenuScrollChange, useRightMenuScrollListener] =
    useEvent() as unknown as [
      ScrollListener,
      (effectFunction: ScrollListener) => void
    ];

  const { prioritizer: hoverPrioritizer } = usePrioritizerFactory();

  /**
   * showAnnotations state resets (goes back to true) when the main tab changes (For example: From Operational Spending to Recommendations, or from Recommendations to Insights)
   */
  useEffect(() => {
    setShowAnnotations(true);
  }, [selectedRightTabKey]);

  const state = useMemo(
    () => ({
      shownRightPanel,
      setShownRightPanel,
      shownBottomPanel,
      setShownBottomPanel,
      heatmapOptions,
      setHeatmapOptions,
      focusedEntityOptions,
      setFocusedEntityOptions,
      rolledOverEntityId,
      setRolledOverEntityId,
      isAveragingToolEnabled,
      setIsAveragingToolEnabled,
      onModelClick,
      useOnModelClickListener,
      hoverPrioritizer,
      floatingRightPanel,
      setFloatingRightPanel,
      FloatingRightPanelPortal,
      FloatingRightPanelTarget,
      showAnnotations,
      setShowAnnotations,
      entitiesSideAnnotations,
      setEntitiesSideAnnotations,
      entitiesAnnotations,
      setEntitiesAnnotations,
      onRightMenuScrollChange,
      useRightMenuScrollListener,
      selectedRightTabKey,
      setSelectedRightTabKey,
      rightPanelTabs,
    }),
    [
      FloatingRightPanelPortal,
      FloatingRightPanelTarget,
      entitiesAnnotations,
      entitiesSideAnnotations,
      floatingRightPanel,
      focusedEntityOptions,
      heatmapOptions,
      hoverPrioritizer,
      onModelClick,
      onRightMenuScrollChange,
      rolledOverEntityId,
      selectedRightTabKey,
      setEntitiesAnnotations,
      setEntitiesSideAnnotations,
      setSelectedRightTabKey,
      showAnnotations,
      shownBottomPanel,
      isAveragingToolEnabled,
      shownRightPanel,
      useOnModelClickListener,
      useRightMenuScrollListener,
      rightPanelTabs,
    ]
  );

  return <PageContext.Provider value={state}>{children}</PageContext.Provider>;
};

const usePageState = <
  BOTTOM_PANEL_KEY extends string = string,
  MODEL_ON_CLICK_PARAMS extends OnClickArgs = OnClickArgs,
  HOVER_PRIORITIZER_PARAMS extends HoverDataArgs = HoverDataArgs,
  SELECTED_RIGHT_TAB_KEY extends string = string,
  FLOATING_RIGHT_PANEL_SHOWN = null
>() =>
  useContext<
    PageContextValue<
      BOTTOM_PANEL_KEY,
      MODEL_ON_CLICK_PARAMS,
      HOVER_PRIORITIZER_PARAMS,
      SELECTED_RIGHT_TAB_KEY,
      FLOATING_RIGHT_PANEL_SHOWN
    >
  >(
    PageContext as unknown as Context<
      PageContextValue<
        BOTTOM_PANEL_KEY,
        MODEL_ON_CLICK_PARAMS,
        HOVER_PRIORITIZER_PARAMS,
        SELECTED_RIGHT_TAB_KEY,
        FLOATING_RIGHT_PANEL_SHOWN
      >
    >
  );

export default PageProvider;
export { usePageState };
export type { PageProviderProps };
