import { useTranslate } from "modules/language";
import { complianceScoresContent, getCertificate } from "../../../utils";
import Accordion, {
  AccordionGroupElement,
} from "atomic-components/atoms/Accordion";
import { ChevronDownIcon } from "svg-icons";
import classNames from "classnames";
import { type ComplianceScore } from "modules/building/pages/SustainabilityPage/types";
import type { ComplianceScoreKey } from "modules/building/api/complianceScores";
import FormattedValue from "atomic-components/atoms/FormattedValue";
import ScenarioFieldRow from "./ScenarioFieldRow/ScenarioFieldRow";
import TextArea from "atomic-components/atoms/TextArea/TextArea";
import { getSubFields } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ComplianceTrackingPanel/Certificates/LeedOmCertificate/Scenarios/utils";
import { useComplianceScoreValues } from "modules/building/hooks/useComplianceScoreValues";

import s from "./ScenarioDetailsForm.module.scss";

export type ScenarioFieldType = Omit<
  ComplianceScore<ComplianceScoreKey>,
  "isEditable"
> & {
  fieldType?: "number" | "boolean";
  maximumRequired: number;
};

type ScenarioDetailsFormProps = {
  fieldsScores: Record<string, number | boolean>;
  setFieldsScores: (value: Record<string, number | boolean>) => void;
  fieldsComments: Record<string, string>;
  setFieldsComments: (value: Record<string, string>) => void;
  mainComment: string;
  setMainComment: (value: string) => void;
  totalScore: number;
  isEditingDisabled?: boolean;
};

const ScenarioDetailsForm = ({
  fieldsScores,
  setFieldsScores,
  fieldsComments,
  setFieldsComments,
  mainComment,
  setMainComment,
  totalScore,
  isEditingDisabled,
}: ScenarioDetailsFormProps) => {
  const t = useTranslate();

  const { data } = useComplianceScoreValues();

  const targetCertificate = getCertificate({
    value: data.target,
  });

  const resultingCertificate = getCertificate({
    value: totalScore,
  });

  const categoriesFamily = complianceScoresContent.filter(
    ({ key }) => key !== "total"
  );

  return (
    <div>
      <div className={s.headingRow}>
        <span>{t("label.CATEGORY")}</span>
        <span>{t("label.CURRENT")}</span>
        <span>{t("label.TARGET")}</span>
      </div>
      <Accordion key="accordion">
        {categoriesFamily.map((category) => {
          const { key, title, maximumRequired, children } = category;

          const categoryScore = getSubFields(category)
            ?.filter(({ fieldType }) => fieldType === "number")
            // only fields that has no children
            ?.reduce(
              (acc, child) =>
                acc +
                (getSubFields(child).length === 0
                  ? (fieldsScores[child.key] as number)
                  : 0),
              0
            );

          return (
            <AccordionGroupElement
              key={key}
              renderAccordionButton={({ isExpanded }) => (
                <div className={s.categoryHeadingRow} key={`category${key}`}>
                  <span>
                    <ChevronDownIcon
                      aria-hidden
                      iconScale={1.4}
                      className={classNames(s.chevron, {
                        [s.chevronReversed]: isExpanded,
                      })}
                    />
                    {t(title)}
                  </span>
                  <span>
                    <FormattedValue value={categoryScore} /> / {maximumRequired}
                  </span>
                </div>
              )}
            >
              {children?.map((field) => {
                return (
                  <ScenarioFieldRow
                    key={field.key}
                    field={field as ScenarioFieldType}
                    fieldsScores={fieldsScores}
                    setFieldScore={({
                      key,
                      score,
                    }: {
                      key: string;
                      score: number;
                    }) => {
                      setFieldsScores(
                        Object.assign({}, fieldsScores, { [key]: score })
                      );
                    }}
                    fieldsComments={fieldsComments}
                    setFieldComment={({
                      key,
                      comment,
                    }: {
                      key: string;
                      comment: string;
                    }) => {
                      setFieldsComments(
                        Object.assign({}, fieldsComments, { [key]: comment })
                      );
                    }}
                    isEditingDisabled={isEditingDisabled}
                  />
                );
              })}
            </AccordionGroupElement>
          );
        })}
      </Accordion>
      <div>
        <div className={s.resultContainer}>
          <span>{t("label.TOTAL_SCORE")}</span>
          <span>
            <FormattedValue value={totalScore} />
          </span>
        </div>
        <div className={s.resultContainer}>
          <span>{t("label.REMAINING_CREDITS")}</span>
          <span>
            <FormattedValue
              value={(targetCertificate?.min || 0) - totalScore}
            />
          </span>
        </div>
        <div className={s.resultContainer}>
          <span>{t("label.RESULTING_STATUS")}</span>
          <span>
            {resultingCertificate?.certificateLabel
              ? t(resultingCertificate.certificateLabel)
              : "-"}
          </span>
        </div>
      </div>

      {isEditingDisabled ? null : (
        <TextArea
          value={mainComment}
          onChange={setMainComment}
          placeholder={t("message.ENTER_A_COMMENT")}
          label={t("label.COMMENTS")}
          rows={5}
        />
      )}
    </div>
  );
};

export default ScenarioDetailsForm;
