import { DateTime } from "luxon";
import config from "../../../config";
import { fetch, rejectNotOk } from "../../core/services/fetch.service";

export const loadBuilding = (buildingUrl, signal) => {
  return fetch(
    `${config.api.baseUrl}/api/para/building/${encodeURIComponent(
      buildingUrl
    )}`,
    {
      method: "get",
      signal,
    }
  ).then(rejectNotOk.body);
};

const parseDate = (date) => {
  if (date instanceof DateTime) {
    return date.toMillis();
  }
  if (Date instanceof Date) {
    return date.getTime();
  }
  return date;
};
export const loadEntitiesTelemetries = (
  rootEntityId,
  entitiesTelemetriesRequest,
  signal
) => {
  entitiesTelemetriesRequest = entitiesTelemetriesRequest.map(
    ({ from, to, ...rest }) => ({
      ...rest,
      from: parseDate(from),
      to: parseDate(to),
    })
  );
  return fetch(
    `${config.api.baseUrl}/api/para/building/${encodeURIComponent(
      rootEntityId
    )}/get-telemetries`,
    {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(entitiesTelemetriesRequest),
      signal,
    }
  ).then(rejectNotOk.body);
};

export const loadTimeseries = (rootEntityId, timeseriesRequests, signal) => {
  return fetch(
    `${config.api.baseUrl}/api/para/building/${encodeURIComponent(
      rootEntityId
    )}/get-timeseries`,
    {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(timeseriesRequests),
      signal,
    }
  ).then(rejectNotOk.body);
};
export const getFaults = (from, to, signal) => {
  return fetch(
    `${config.api.baseUrl}/api/para/faults?from=${encodeURIComponent(
      `${from}`
    )}&to=${encodeURIComponent(`${to}`)}`,
    {
      method: "get",
      signal,
    }
  ).then(rejectNotOk.body);
};

export const getRecommendations = (from, to, signal) => {
  return fetch(
    `${config.api.baseUrl}/api/para/recommendations?from=${encodeURIComponent(
      `${from}`
    )}&to=${encodeURIComponent(`${to}`)}`,
    {
      method: "get",
      signal,
    }
  ).then(rejectNotOk.body);
};

export const loadEntitiesInsights = (
  rootEntityId,
  entitiesInsightsRequest,
  signal
) => {
  entitiesInsightsRequest = entitiesInsightsRequest.map(
    ({ from, to, ...rest }) => ({
      ...rest,
      from: parseDate(from),
      to: parseDate(to),
    })
  );
  return fetch(
    `${config.api.baseUrl}/api/para/building/${encodeURIComponent(
      rootEntityId
    )}/get-insights`,
    {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(entitiesInsightsRequest),
      signal,
    }
  )
    .then(rejectNotOk.body)
    .then(({ insights }) => insights);
};

export const getStatus = (entityId, field, signal) => {
  return fetch(
    `${config.api.baseUrl}/api/para/status?entityId=${encodeURIComponent(
      entityId
    )}&field=${encodeURIComponent(field)}`,
    {
      method: "get",
      signal,
    }
  ).then(rejectNotOk.body);
};

export const changeStatus = (entityId, field, status, signal) => {
  return fetch(`${config.api.baseUrl}/api/para/status`, {
    method: "put",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      entityId,
      field,
      status,
    }),
    signal,
  }).then(rejectNotOk.body);
};
