import React from "react";
import { useTranslate } from "modules/language";

import "./ComingSoon.css";
import { addToast } from "modules/toast";

const ComingSoon = () => {
  const t = useTranslate();
  return <div className="coming-soon">{t("message.COMING_SOON")}</div>;
};

const onClickComingSoon = () =>
  addToast({ messageKey: "toast.COMING_SOON", variant: "info" });

export default ComingSoon;
export { onClickComingSoon };
