import { useEditableParamsState } from "../pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import {
  useViewData,
  useViewState,
} from "../providers/ViewProvider/ViewProvider";
import useBuildingData from "./useBuildingData";
import { useMemoedLinkedData } from "./useLinkedData";
import useSelectedEntity from "./useSelectedEntity";
import { useMergedNotifier } from "helpers/getNotifier";
import { useUtilsEmissions } from "./useUtilsEmissions";

const useFuelEmissions = () => {
  const { buildingData } = useBuildingData();
  const selectedEntity = useSelectedEntity(buildingData);

  const {
    state: { dieselEmissionsConversionFactor },
    Notifier: EPNotifier,
  } = useEditableParamsState();

  const { telemetries, Notifier: TNotifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        telemetries: [{ telemetryName: "monthlyDieselConsumption" }],
      },
    ],
    [selectedEntity]
  );

  const { monthlyDieselConsumption } = telemetries[selectedEntity.id];
  const monthlyDieselConsumptionValue =
    monthlyDieselConsumption.data[0]?.value ?? NaN;
  const totalFuelEmissions =
    monthlyDieselConsumptionValue *
    (dieselEmissionsConversionFactor.value ?? NaN);

  const Notifier = useMergedNotifier(TNotifier, EPNotifier);

  /* EMISSIONS BY YEAR */

  const { fromTs } = useViewState();
  const {
    transformYearEmissions,
    getValues,
    yearFromTimeStamp,
    calculateFromAndTo,
  } = useUtilsEmissions();

  const yearSelected = yearFromTimeStamp(fromTs);
  const timeSelected = calculateFromAndTo(yearSelected);

  const { telemetries: telemetriesByYear } = useMemoedLinkedData(
    timeSelected.firstDay,
    timeSelected.lastDay,
    () => {
      return [
        {
          entityId: selectedEntity.id,
          telemetries: [{ telemetryName: "monthlyDieselConsumption" }],
        },
      ];
    },
    [selectedEntity]
  );

  const dieselConsumptionValueFinal = transformYearEmissions(
    telemetriesByYear[selectedEntity.id].monthlyDieselConsumption.data
  );

  const yearlyDieselConsumptionValue = getValues(dieselConsumptionValueFinal);

  const calculateTotalFuelEmissionsByYear = (yearlyDiesel: number[]) => {
    return yearlyDiesel.map(
      (item) => item * (dieselEmissionsConversionFactor.value ?? NaN)
    );
  };

  const totalFuelEmissionsByYear = calculateTotalFuelEmissionsByYear(
    yearlyDieselConsumptionValue
  );

  return {
    totalFuelEmissions,
    totalFuelEmissionsByYear,
    dieselConsumption: monthlyDieselConsumptionValue,
    Notifier,
  };
};

export { useFuelEmissions };
