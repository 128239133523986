import { useRef } from "react";

/**
 * @typedef {object} OnResizeHandlerArgs
 * @property {HTMLElement} target
 * @property {number} width
 * @property {number} height
 */

/**
 * @callback OnResizeHandler
 * @param {OnResizeHandlerArgs} args
 */

/**
 * @callback RefFunction
 * @param {HTMLElement} element
 */

const getOnResizeEventArgs = (element) => ({
  target: element,
  width: element.offsetWidth,
  height: element.offsetHeight,
});

const observedElements = new Map();
let timer;
const observer = new ResizeObserver((entries) => {
  cancelAnimationFrame(timer);
  timer = requestAnimationFrame(() => {
    for (let entry of entries) {
      const element = entry.target;
      const onResize = observedElements.get(element);
      onResize && onResize(getOnResizeEventArgs(element));
    }
  });
});

const addElement = (element, onResize) => {
  observedElements.set(element, onResize);
  observer.observe(element);
};

const removeElement = (element) => {
  observer.unobserve(element);
  observedElements.delete(element);
};

/**
 * @param {{ onResize: OnResizeHandler, ref: ReturnType<useRef> | RefFunction }} param0
 * @returns RefFunction
 */
const useResizeObserver = ({ onResize = () => {}, ref: inputRef }) => {
  const innerRef = useRef();
  innerRef.current = innerRef.current ?? {
    outputRef: (element) => {
      if (inputRef) {
        if (typeof inputRef === "function") {
          inputRef(element);
        } else {
          inputRef.current = element;
        }
      }
      const prevElement = innerRef.current.element;
      if (prevElement && prevElement !== element) {
        removeElement(prevElement);
      }
      innerRef.current.element = element;
      if (element && !observedElements.has(element)) {
        addElement(element, (...args) => innerRef.current.onResize(...args));
      }
    },
  };
  innerRef.current.onResize = onResize;
  return innerRef.current.outputRef;
};

export default useResizeObserver;
