import DropDown from "atomic-components/atoms/DropDown";
import { useTranslate } from "modules/language";
import classNames from "classnames";
import { useId } from "react";

import s from "./ScenarioFilters.module.scss";

export enum Filters {
  ALL_SCENARIOS,
  LEED_OM_GOLD_PLUS,
  LEED_OM_SILVER_PLUS,
  LEED_OM_PLATINUM,
}

export enum SortByOptions {
  ASCENDING,
  DESCENDING,
}

type ScenarioFiltersProps = {
  numberOfScenarios: number;
  selectedFilter: Filters;
  setSelectedFilter: (filter: Filters) => void;
  selectedSortBy: SortByOptions;
  setSelectedSortBy: (sortBy: SortByOptions) => void;
};

const ScenarioFilters = ({
  numberOfScenarios,
  selectedFilter,
  setSelectedFilter,
  selectedSortBy,
  setSelectedSortBy,
}: ScenarioFiltersProps) => {
  const t = useTranslate();

  const filters = [
    {
      key: Filters.ALL_SCENARIOS,
      label: t("label.ALL_SCENARIOS"),
    },
    {
      key: Filters.LEED_OM_GOLD_PLUS,
      label: t("label.LEED_OM_GOLD_PLUS"),
    },
    {
      key: Filters.LEED_OM_SILVER_PLUS,
      label: t("label.LEED_OM_SILVER_PLUS"),
    },
    {
      key: Filters.LEED_OM_PLATINUM,
      label: t("label.LEED_OM_PLATINUM"),
    },
  ];

  const sortByOptions = [
    {
      key: SortByOptions.ASCENDING,
      label: t("label.ASCENDING"),
    },
    {
      key: SortByOptions.DESCENDING,
      label: t("label.DESCENDING"),
    },
  ];

  const areFiltersDisabled = numberOfScenarios === 0;

  const filterInputId = useId();
  const sortByInputId = useId();

  return (
    <div className={s.container}>
      <div className={s.dropdownContainer}>
        <label htmlFor={filterInputId} className={s.label}>
          {t("title.FILTER")}
        </label>
        <DropDown
          options={
            filters.map((filter) => ({
              text: filter.label,
              value: filter.key,
            })) || []
          }
          value={selectedFilter}
          onChange={({ value }) => setSelectedFilter(Number(value))}
          inputId={filterInputId}
          variant="outline"
          optionClassName={s.options}
          empty={<span className={s.fade}>{filters[0].label}</span>}
          disabled={areFiltersDisabled}
          className={classNames({
            [s.fade]: areFiltersDisabled,
          })}
        />
      </div>
      <div className={s.dropdownContainer}>
        <label htmlFor={sortByInputId} className={s.label}>
          {t("label.SORTED_BY")}
        </label>
        <DropDown
          options={
            sortByOptions.map((sortByOption) => ({
              text: sortByOption.label,
              value: sortByOption.key,
            })) || []
          }
          value={selectedSortBy}
          onChange={({ value }) => setSelectedSortBy(Number(value))}
          inputId={sortByInputId}
          variant="outline"
          optionClassName={s.options}
          empty={<span className={s.fade}>{sortByOptions[0].label}</span>}
          disabled={areFiltersDisabled}
          className={classNames({
            [s.fade]: areFiltersDisabled,
          })}
        />
      </div>
    </div>
  );
};

export default ScenarioFilters;
