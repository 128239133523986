import withIconProps from "helpers/withIconProps";

const ActiveAlarmIcon = withIconProps((props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="alarm-active">
        <g id="icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.89373 20.3516C10.252 20.1437 10.711 20.2657 10.9188 20.624C11.0287 20.8134 11.1864 20.9706 11.3762 21.0799C11.5659 21.1892 11.7811 21.2468 12.0001 21.2468C12.219 21.2468 12.4342 21.1892 12.6239 21.0799C12.8137 20.9706 12.9714 20.8134 13.0813 20.624C13.2891 20.2657 13.7481 20.1437 14.1064 20.3516C14.4647 20.5594 14.5866 21.0183 14.3788 21.3766C14.1371 21.7934 13.7901 22.1393 13.3726 22.3797C12.9551 22.6202 12.4818 22.7468 12.0001 22.7468C11.5183 22.7468 11.045 22.6202 10.6275 22.3797C10.21 22.1393 9.86305 21.7934 9.62131 21.3766C9.41347 21.0183 9.53543 20.5594 9.89373 20.3516Z"
            fill="currentColor"
          />
          <path
            d="M18.8169 9.7203C19.0115 12.1308 19.5991 13.7407 20.1585 14.7662C20.5011 15.3943 20.8371 15.8107 21.0758 16.0626C21.1953 16.1888 21.2909 16.2742 21.3513 16.3245C21.3815 16.3497 21.403 16.3661 21.4142 16.3745L21.4229 16.3808C21.6931 16.5652 21.8129 16.9039 21.7179 17.2176C21.6221 17.534 21.3306 17.7503 21.0001 17.7503H3.00006C2.66956 17.7503 2.37801 17.534 2.28224 17.2176C2.18726 16.9039 2.30703 16.5652 2.57726 16.3808L2.58589 16.3745C2.59716 16.3661 2.61861 16.3497 2.64883 16.3245C2.70925 16.2742 2.80483 16.1888 2.92434 16.0626C3.16298 15.8107 3.49904 15.3943 3.84164 14.7662C4.52476 13.5138 5.25006 11.3898 5.25006 8.0003C5.25006 6.21009 5.96122 4.4932 7.22709 3.22733C8.41295 2.04147 9.99462 1.34242 11.6616 1.25879C11.4109 1.74602 11.2249 2.27202 11.115 2.82542C10.0511 3.00738 9.06118 3.51457 8.28775 4.28799C7.30318 5.27256 6.75006 6.60792 6.75006 8.0003C6.75006 11.6108 5.97536 13.9868 5.15848 15.4844C5.0014 15.7724 4.84336 16.0268 4.68988 16.2503H19.3102C19.1568 16.0268 18.9987 15.7724 18.8416 15.4844C18.1855 14.2815 17.5566 12.512 17.3345 9.99114C17.8485 9.9629 18.3454 9.86995 18.8169 9.7203Z"
            fill="currentColor"
          />
        </g>
        <circle id="alarm" cx="17" cy="4" r="4" fill="#0040FF" />
      </g>
    </svg>
  );
});

export default ActiveAlarmIcon;
