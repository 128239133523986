import React from "react";
import classNames from "classnames";

/**
 * @param {{color?: string, className?: string, iconScale?: number, icon?: boolean}} param0
 * @returns {React.ReactNode}
 */
const MinusCircle = ({
  color = "#b6b6b6",
  className,
  iconScale = 1,
  icon = true,
}) => {
  const stroke = color ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.29,5.5H7.71m2.76,0c0,2.74-2.22,4.96-4.96,4.96S.54,8.24,.54,5.5,2.76,.54,5.5,.54s4.96,2.22,4.96,4.96Z"
      />
    </svg>
  );
};

export default MinusCircle;
