import BucketDisplayer from "atomic-components/atoms/BucketDisplayer";
import UnitValue from "atomic-components/atoms/UnitValue";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import WidgetGrid from "atomic-components/molecules/WidgetGrid/WidgetGrid";
import HorizontalStackedChart from "atomic-components/organisms/HorizontalStackedChart";
import useBuckets from "modules/building/hooks/useBuckets";
import useBuildingData from "modules/building/hooks/useBuildingData";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import { useTranslate } from "modules/language";
import { useState } from "react";
import TabsView, { Tab } from "atomic-components/organisms/TabsView";
import { BuildingOccupancyTypeEnum } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/types";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";
import { LinkedTelemetry } from "modules/building/hooks/useLinkedData";

type MultipleResolutionsType = {
  [key: string]: LinkedTelemetry;
};

type OccupancyDetailsProps = {
  hvacZoneCount: number;
};

const BUCKET_COLORS = [
  "white",
  "var(--color-primary-3xlight)",
  "var(--color-primary-xxlight)",
  "var(--color-primary-xlight)",
  "var(--color-primary-light)",
  "var(--color-primary)",
];

const initialBucket = {
  key: "0",
  from: 0,
  to: 0,
  fromPercent: 0,
  toPercent: 0,
};

const OccupancyDetails = ({ hvacZoneCount }: OccupancyDetailsProps) => {
  const t = useTranslate();
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);

  const { resolutions, Notifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        resolutionKey: "1month",
        telemetries: [
          {
            telemetryName: "employeesCount",
            resolutionAggregator: [
              "capacity-0-0",
              "capacity-0-20",
              "capacity-20-40",
              "capacity-40-60",
              "capacity-60-80",
              "capacity-80-100",
            ],
          },
        ],
      },
    ],
    [selectedEntity.id]
  );

  const { employeesCount } = resolutions["1month"][selectedEntity.id];
  const employeesCountResolutions =
    employeesCount as any as MultipleResolutionsType;

  const numOfDays = employeesCountResolutions["capacity-0-0"].days;

  const [occupancyType, setOccupancyType] = useState(
    BuildingOccupancyTypeEnum.AvgOccupancy
  );

  const headerTabs: Tab<BuildingOccupancyTypeEnum>[] = [
    {
      key: BuildingOccupancyTypeEnum.AvgOccupancy,
      text: t("label.AVG_OCCUPANCY"),
    },
    {
      key: BuildingOccupancyTypeEnum.OccupiedZones,
      text: t("title.OCCUPIED_ZONES"),
    },
  ];

  const { buckets: occupancyBuckets } = useBuckets({
    min: 1,
    max: 100,
    stepCount: 5,
    minScale: 1,
    snapToMinScale: true,
    includeZeroThreashold: 0,
    postProcessBuckets: (buckets) => {
      return [initialBucket, ...buckets];
    },
  });

  const occupancyBucketValue = Object.fromEntries(
    occupancyBuckets.map((bucket) => [
      [`capacity-${bucket.fromPercent}-${bucket.toPercent}`],
      [
        employeesCountResolutions[
          `capacity-${bucket.fromPercent}-${bucket.toPercent}`
        ]?.data[0]?.value / 6,
      ],
    ])
  );

  const zoneOccupancyStepCount = hvacZoneCount / 2;
  const { buckets: zoneOccupancyBuckets } = useBuckets({
    min: 1,
    max: hvacZoneCount,
    stepCount: zoneOccupancyStepCount < 5 ? zoneOccupancyStepCount : 5,
    minScale: 1,
    snapToMinScale: true,
    includeZeroThreashold: 0,
    postProcessBuckets: (buckets) => {
      return [initialBucket, ...buckets];
    },
  });

  return (
    <Notifier>
      <WidgetContainer multiLine>
        <TabsView
          size="small"
          growTabs={false}
          tabs={headerTabs}
          selectedTabKey={occupancyType}
          setSelectedTabKey={setOccupancyType}
        >
          {occupancyType === BuildingOccupancyTypeEnum.AvgOccupancy ? (
            <HorizontalStackedChart
              data={occupancyBuckets.map((bucket, index) => {
                const { key } = bucket;
                const value =
                  occupancyBucketValue[
                    `capacity-${bucket.fromPercent}-${bucket.toPercent}`
                  ][0];
                const color = BUCKET_COLORS[index];

                return {
                  key,
                  value,
                  color,
                  borderColor: "",
                  legend:
                    key === "0" ? (
                      t("label.UNOCCUPIED")
                    ) : (
                      <BucketDisplayer
                        bucket={bucket}
                        unit={unitsByName.percent}
                      />
                    ),
                };
              })}
              unit={unitsByName.hours}
              hasExpandText={false}
            />
          ) : (
            <HorizontalStackedChart
              data={zoneOccupancyBuckets.map((bucket, index) => {
                const { key } = bucket;
                const value = 4;
                const color = BUCKET_COLORS[index];

                return {
                  key,
                  value,
                  color,
                  borderColor: "",
                  legend:
                    key === "0" ? (
                      t("label.UNOCCUPIED")
                    ) : (
                      <>
                        <BucketDisplayer bucket={bucket} /> {t("legend.ZONES")}
                      </>
                    ),
                };
              })}
              unit={null}
              hasExpandText={false}
            />
          )}
          {/* 
        /> */}
        </TabsView>
        <WidgetGrid>
          <WidgetContainer skin="white" size="smallY">
            <KeyValue
              keyText={t("label.UNOCCUPIED")}
              value={
                <UnitValue
                  value={occupancyBucketValue[`capacity-0-0`][0]}
                  unit={unitsByName.hours}
                />
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer skin="white" size="smallY">
            <KeyValue
              keyText={t("label.OCCUPIED")}
              value={
                <UnitValue
                  value={
                    numOfDays * 24 - occupancyBucketValue[`capacity-0-0`][0]
                  }
                  unit={unitsByName.hours}
                />
              }
              isLight
            />
          </WidgetContainer>
        </WidgetGrid>
      </WidgetContainer>
    </Notifier>
  );
};

export default OccupancyDetails;
export type { OccupancyDetailsProps };
