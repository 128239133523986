import UnitValue from "atomic-components/atoms/UnitValue";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import CollapsibleContainer from "atomic-components/molecules/CollapsibleContainer/CollapsibleContainer";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import KeyValue from "atomic-components/molecules/KeyValue";
import WidgetGrid from "atomic-components/molecules/WidgetGrid/WidgetGrid";
import getRatio from "helpers/getRatio";
import useBuildingData from "modules/building/hooks/useBuildingData";
import { useTranslate } from "modules/language";
import BreakdownTitle from "../BreakdownTitle/BreakdownTitle";
import WasteChart from "./WasteChart/WasteChart";
import WasteProcesses from "./WasteProcesses/WasteProcesses";
import WasteAssumption from "./WasteAssumptions/WasteAssumptions";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";
import { useMergedNotifier } from "helpers/getNotifier";
import { useWasteEmissisons } from "modules/building/hooks/useWasteEmissions";

type PropsType = {
  scopes: string[];
};

const WasteBreakdown = ({ scopes }: PropsType) => {
  const t = useTranslate();
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);
  const {
    totalWasteContent,
    totalWasteEmissions,
    Notifier: ENotifier,
  } = useWasteEmissisons();

  const {
    resolutions,
    telemetries,
    Notifier: telemetriesNotifier,
  } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        resolutionKey: "1month",
        telemetries: [
          {
            telemetryName: "dailyMaximumUniqueEmployees",
          },
        ],
      },
      {
        entityId: selectedEntity.id,
        telemetries: [
          { telemetryName: "monthlyAgriculturalWaste" },
          { telemetryName: "monthlyPaperAndCartonWaste" },
          { telemetryName: "monthlyPlasticWaste" },
          { telemetryName: "monthlyFoodWaste" },
          { telemetryName: "monthlyTransportationEmissions" },
          { telemetryName: "monthlyPlasticRecyclingEmissions" },
          { telemetryName: "monthlyPaperRecyclingEmissions" },
          { telemetryName: "monthlyCompostingEmissions" },
          { telemetryName: "monthlyRDFEmissions" },
        ],
      },
    ],
    [selectedEntity]
  );
  const Notifier = useMergedNotifier(telemetriesNotifier, ENotifier);

  const { dailyMaximumUniqueEmployees } =
    resolutions["1month"][selectedEntity.id];

  const averageMonthlyUniqueEmployees =
    dailyMaximumUniqueEmployees.data[0]?.value;

  const builtupArea = selectedEntity.area;

  const {
    monthlyAgriculturalWaste,
    monthlyPaperAndCartonWaste,
    monthlyPlasticWaste,
    monthlyFoodWaste,
    monthlyTransportationEmissions,
    monthlyPlasticRecyclingEmissions,
    monthlyPaperRecyclingEmissions,
    monthlyCompostingEmissions,
    monthlyRDFEmissions,
  } = telemetries[selectedEntity.id];

  const avgEmissionsPerPerson = getRatio(
    totalWasteEmissions,
    averageMonthlyUniqueEmployees
  );

  const avgEmissionsPerM2 = getRatio(totalWasteEmissions, builtupArea);

  return (
    <Notifier>
      <CollapsibleContainer
        titleFontWeight="normal"
        title={<BreakdownTitle label={t("label.WASTE")} scopes={scopes} />}
        hasExtraSpacing
      >
        <WidgetGrid columnCount={1}>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.TOTAL_WASTE_CONTENT")}
              value={
                <UnitValue value={totalWasteContent} unit={unitsByName.kg} />
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.TOTAL_EQUIVALENT_EMISSIONS")}
              value={
                <UnitValue
                  value={totalWasteEmissions}
                  unit={unitsByName.kgco2e}
                />
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.AVG_EMISSIONS_PER_PERSON")}
              value={
                <>
                  <UnitValue
                    value={avgEmissionsPerPerson}
                    unit={unitsByName.kgco2e}
                  />
                  /
                  <Displayer object={unitsByName.person} />
                </>
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={
                <>
                  {t("label.AVG_EMISSIONS_PER_M")}
                  <sup>2</sup>
                </>
              }
              value={
                <>
                  <UnitValue
                    value={avgEmissionsPerM2}
                    unit={unitsByName.kgco2e}
                  />
                  /
                  <Displayer object={unitsByName.m2} />
                </>
              }
              isLight
            />
          </WidgetContainer>
        </WidgetGrid>
        <WasteChart
          totalWasteContent={totalWasteContent}
          agriWaste={monthlyAgriculturalWaste.data[0]?.value}
          paperWaste={monthlyPaperAndCartonWaste.data[0]?.value}
          plasticWaste={monthlyPlasticWaste.data[0]?.value}
          foodWaste={monthlyFoodWaste.data[0]?.value}
        />
        <WasteProcesses
          transportation={monthlyTransportationEmissions.data[0]?.value}
          plasticRecycling={monthlyPlasticRecyclingEmissions.data[0]?.value}
          paperRecycling={monthlyPaperRecyclingEmissions.data[0]?.value}
          composting={monthlyCompostingEmissions.data[0]?.value}
          rdf={monthlyRDFEmissions.data[0]?.value}
        />
        <WasteAssumption />
      </CollapsibleContainer>
    </Notifier>
  );
};

export default WasteBreakdown;
