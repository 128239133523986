import Key from "atomic-components/atoms/Key";
import Section from "atomic-components/atoms/Section";
import UnitValue from "atomic-components/atoms/UnitValue";
import PieChartWithTabs from "atomic-components/organisms/PieChartWithTabs";
import isRealNumber from "helpers/isRealNumber";
import useBuildingData from "modules/building/hooks/useBuildingData";
import { useTravelEmissions } from "modules/building/hooks/useTravelEmissions";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import { useTranslate } from "modules/language";

const TravelTotalEmissions = () => {
  const t = useTranslate();
  const {
    buildingData: { unitsByName },
  } = useBuildingData();
  const {
    companyVehiclesEmissions,
    companyCarsEmissions,
    companyBusesEmissions,
    employeeCarsEmissions,
    employeeBusesEmissions,
    flightEmissions,
    Notifier,
  } = useTravelEmissions();

  const tabItems = [
    {
      key: "company-owned-vehicles",
      text: t("label.COMPANY_OWNED_VEHICLES"),
      value: <UnitValue value={companyVehiclesEmissions} />,
    },
    {
      key: "employee-commute-cars",
      text: t("label.EMPLOYEE_COMMUTE_CARS"),
      value: <UnitValue value={employeeCarsEmissions} />,
    },
    {
      key: "employee-commute-buses",
      text: t("label.EMPLOYEE_COMMUTE_BUSES"),
      value: <UnitValue value={employeeBusesEmissions} />,
    },
    {
      key: "flights",
      text: t("label.FLIGHTS"),
      value: <UnitValue value={flightEmissions} />,
    },
  ];

  const chartItems = [
    {
      legend: t("label.BUSES"),
      value:
        isRealNumber(employeeBusesEmissions) &&
        isRealNumber(companyBusesEmissions)
          ? employeeBusesEmissions + companyBusesEmissions
          : null,
    },
    {
      legend: t("label.CARS"),
      value:
        isRealNumber(employeeCarsEmissions) &&
        isRealNumber(companyCarsEmissions)
          ? employeeCarsEmissions + companyCarsEmissions
          : null,
    },
    {
      legend: t("label.FLIGHTS"),
      value: flightEmissions,
    },
  ];

  return (
    <Notifier>
      <Section>
        <Key tag="h4">
          {t("label.TOTAL_TRAVEL_EMISSIONS")} (
          <Displayer object={unitsByName.kgco2e} />)
        </Key>
        <PieChartWithTabs
          tabItems={tabItems}
          chartItems={chartItems}
          statusElementPortion={1.5}
          chartTooltipUnit={unitsByName.kgco2e}
          tabsSize="regular"
          tabsSkin="button"
        />
      </Section>
    </Notifier>
  );
};

export default TravelTotalEmissions;
