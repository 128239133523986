import classNames from "classnames";
import React from "react";

const Alarm = ({ className = "", iconScale = 1, icon = true }) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill="#bcbcbb"
        fillRule="evenodd"
        d="M7.3,4.88c0-1.6-.85-3.23-2.35-3.58v-.33c0-.42-.32-.78-.73-.8-.45-.03-.83,.33-.83,.78v.35c-1.49,.32-2.32,1.82-2.35,3.58v2.18c0,.27-.11,.53-.3,.72l-.64,.64c-.07,.07-.11,.16-.11,.26,0,.2,.17,.37,.37,.37H7.98c.2,0,.37-.16,.37-.37,0-.1-.04-.19-.11-.26l-.64-.64c-.19-.19-.3-.45-.3-.72v-2.18Z"
      />
      <path
        fill="#bcbcbb"
        fillRule="evenodd"
        d="M4.17,10.91c.07,0,.14,0,.21-.02,.34-.07,.62-.3,.75-.62,.05-.12,.08-.26,.08-.41H3.12c0,.57,.47,1.05,1.05,1.05Z"
      />
      <path
        fill="#f7941d"
        d="M11,2.89c0-1.6-1.3-2.89-2.89-2.89s-2.89,1.3-2.89,2.89,1.3,2.89,2.89,2.89,2.89-1.3,2.89-2.89Z"
      />
    </svg>
  );
};

export default Alarm;
