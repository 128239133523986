import WidgetContainer, {
  type WidgetContainerProps,
} from "atomic-components/atoms/WidgetContainer/WidgetContainer";

type MultiColumnWidgetContainerProps = WidgetContainerProps & {
  columnSpan?: number;
};

const MultiColumnWidgetContainer = ({
  style,
  columnSpan = 2,
  ...props
}: MultiColumnWidgetContainerProps) => (
  <WidgetContainer
    style={{ ...style, gridColumn: `span ${columnSpan}` }}
    {...props}
  />
);

export default MultiColumnWidgetContainer;
export type { MultiColumnWidgetContainerProps };
