import React, { useEffect, useRef, useState } from "react";
import { useOnPostrender, useViewerMutableState } from "../Provider";
import isRealNumber from "helpers/isRealNumber";
import { sum, sumBy } from "lodash";
import ElementWithOnResize from "modules/layout/components/ElementWithOnResize/ElementWithOnResize";
import useMultidimentionalRef from "hooks/useMultidimentionalRef";
import { get2IntervalsIntersection } from "helpers/computeIntervalsDuration";
import classNames from "classnames";

import s from "./PointsSideAnnotations.module.scss";

const getScreenCoordinatesOf3DPoint = (
  pointCoordinates,
  camera,
  width,
  height
) => {
  const point = new window.THREE.Vector3(...pointCoordinates);
  point.project(camera);
  if (point.z > 1) {
    return null;
  }
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  const x = point.x * halfWidth + halfWidth;
  if (x < 0 || x > width) {
    return null;
  }
  const y = -(point.y * halfHeight) + halfHeight;
  if (y < 0 || y > height) {
    return null;
  }
  return { x, y };
};

const Debouncer = function (mutable, onAction) {
  const self = this;
  let timer,
    positions = {},
    locations,
    boxes = [],
    hiddenCount = 0;

  const clearLines = () => {
    const { width, height, canvas2D } = mutable.viewerState;
    if (!canvas2D) {
      return;
    }
    const context2D = canvas2D.getContext("2d");
    context2D.clearRect(0, 0, width, height);
  };

  const drawLines = () => {
    const { canvas2D } = mutable.viewerState;
    if (!canvas2D) {
      return;
    }
    const context2D = canvas2D.getContext("2d");
    boxes.forEach((box, i) => {
      if (!box) {
        return;
      }
      const { x0Line, y0Line, x1Line, y1Line, intersecting } = box;
      if (intersecting) {
        return;
      }

      context2D.beginPath();
      context2D.moveTo(x0Line, y0Line);
      context2D.lineTo(x1Line, y1Line);
      context2D.stroke();
    });
  };

  const draw = () => {
    boxes.forEach((box) => {
      const { x0, y0, element, intersecting } = box;
      if (intersecting) {
        element.style.visibility = `hidden`;
      }
      element.style.left = `${x0}px`;
      element.style.top = `${y0}px`;
    });
    clearLines();
    drawLines();
  };
  self.draw = draw;

  self.clear = () => {
    boxes?.forEach((box) => {
      const { element } = box;
      element.style.left = ``;
      element.style.top = ``;
      element.style.visibility = `hidden`;
    });
    clearLines();
  };

  const refresh = () => {
    const { viewerState, annotations } = mutable;
    const { camera, width, height } = viewerState;

    if (!isRealNumber(width) || !isRealNumber(height)) {
      return;
    }

    const newPositions = {};
    let haveChanged = false;
    annotations.forEach((annotation) => {
      const key = annotation?.key;
      if (!key) {
        return;
      }
      const { pointCoordinates } = annotation;
      const position = positions[key];
      const newPosition = pointCoordinates
        ? getScreenCoordinatesOf3DPoint(pointCoordinates, camera, width, height)
        : null;
      if (newPosition) {
        newPosition.x = Math.round(newPosition.x);
        newPosition.y = Math.round(newPosition.y);
      }
      if (
        ((!position || !newPosition) && position != newPosition) ||
        (position &&
          newPosition &&
          Math.abs(newPosition.x + newPosition.y - position.x - position.y) > 1)
      ) {
        haveChanged = true;
        newPositions[key] = newPosition;
      } else {
        newPositions[key] = position;
      }
    });

    const didDelete = Boolean(
      Object.keys(positions).find((key) => positions[key] && !newPositions[key])
    );

    haveChanged = haveChanged || didDelete;

    if (!haveChanged) {
      return;
    }

    const filteredPositions = Object.values(newPositions).filter(Boolean);
    const xCenter = sumBy(filteredPositions, "x") / filteredPositions.length;
    const yCenter = sumBy(filteredPositions, "y") / filteredPositions.length;

    const newLocations = {};
    annotations.map((annotation, i) => {
      const key = annotation?.key;
      if (!key) {
        return null;
      }
      const position = newPositions[key];
      if (!position) {
        return null;
      }
      const { x, y } = position;
      const positionVector = [x - xCenter, y - yCenter];
      let len = Math.sqrt(
        positionVector[0] * positionVector[0] +
          positionVector[1] * positionVector[1]
      );
      if (len === 0) {
        positionVector[1] = -1;
        len = 1;
      }
      positionVector[0] = positionVector[0] / len;
      positionVector[1] = positionVector[1] / len;

      if (positionVector[0]) {
        const possibleY = Math.round(
          positionVector[0] < 0
            ? y - (x / positionVector[0]) * positionVector[1]
            : y + ((width - x) / positionVector[0]) * positionVector[1]
        );
        if (0 <= possibleY && possibleY <= height) {
          newLocations[key] = {
            key,
            annotation,
            position,
            x: positionVector[0] < 0 ? 0 : width,
            y: possibleY,
            xPlacement: positionVector[0] < 0 ? "left" : "right",
            yPlacement: "center",
          };
          return;
        }
      } else {
        newLocations[key] = {
          key,
          annotation,
          position,
          x,
          y: positionVector[1] < 0 ? 0 : height,
          xPlacement: "center",
          yPlacement: positionVector[1] < 0 ? "top" : "bottom",
        };
        return;
      }
      if (positionVector[1]) {
        const possibleX = Math.round(
          positionVector[1] < 0
            ? x - (y / positionVector[1]) * positionVector[0]
            : x + ((height - y) / positionVector[1]) * positionVector[0]
        );
        if (0 <= possibleX && possibleX <= width) {
          newLocations[key] = {
            key,
            annotation,
            position,
            x: possibleX,
            y: positionVector[1] < 0 ? 0 : height,
            xPlacement: "center",
            yPlacement: positionVector[1] < 0 ? "top" : "bottom",
          };
          return;
        }
      } else {
        newLocations[key] = {
          key,
          annotation,
          position,
          y,
          x: positionVector[0] < 0 ? 0 : width,
          xPlacement: positionVector[0] < 0 ? "left" : "right",
          yPlacement: "center",
        };
        return;
      }
    });

    positions = newPositions;
    locations = newLocations;

    return true;
  };
  self.refresh = refresh;

  const reflow = () => {
    const { refs, annotations, viewerState } = mutable;
    const { width, height } = viewerState;
    const newBoxes = [];
    annotations.forEach((annotation) => {
      const key = annotation?.key;
      if (!key) {
        return;
      }
      const element = refs[key]?.element;
      if (!element) {
        return;
      }
      const { width: elementWidth, height: elementHeight } =
        refs[key]?.size ?? {};
      const location = locations?.[key];
      if (!location || !elementWidth || !elementHeight) {
        element.style.visibility = "hidden";
        return;
      }
      element.style.visibility = "visible";
      const { x, y, xPlacement, yPlacement, position } = location;

      let x0,
        x1,
        y0,
        y1,
        x0Line = position.x,
        y0Line = position.y;

      switch (xPlacement) {
        case "center":
          x0 = x - elementWidth / 2;
          break;
        case "right":
          x0 = x - elementWidth;
          break;
        case "left":
          x0 = x;
          break;
      }
      switch (yPlacement) {
        case "center":
          y0 = y - elementHeight / 2;
          break;
        case "bottom":
          y0 = y - elementHeight;
          break;
        case "top":
          y0 = y;
          break;
      }

      if (x0 < 0) {
        x0 = 0;
      }
      if (y0 < 0) {
        y0 = 0;
      }
      x1 = x0 + elementWidth;
      y1 = y0 + elementHeight;
      if (x1 > width) {
        x1 = width;
        x0 = width - elementWidth;
      }
      if (y1 > height) {
        y1 = height;
        y0 = height - elementHeight;
      }

      newBoxes.push({
        key,
        xPlacement,
        yPlacement,
        location: { x, y },
        x0,
        x1,
        y0,
        y1,
        w: elementWidth,
        h: elementHeight,
        x0Line,
        y0Line,
        element,
      });
    });

    if (newBoxes.length === 0) {
      boxes = newBoxes;
      draw();
      return;
    }

    const getAngle = ({ x0, y0, x1, y1 }) => {
      const dX = x1 - x0;
      const dY = y1 - y0;
      return (Math.atan(dX / dY) * 180) / Math.PI;
    };
    newBoxes.forEach((b) => {
      b.a = getAngle(b);
    });
    newBoxes.sort((a, b) => a.a - b.b);

    const pushBoxLeft = (box, otherBox, dryDun) => {
      let direction = "left";
      const box2 = { ...box };
      if (dryDun) {
        box = { ...box };
      }
      box.x1 = otherBox.x0;
      box.x0 = box.x1 - box.w;

      if (box.x0 < 0) {
        box.x0 = 0;
        box.x1 = box.w;

        if (box.yPlacement === "top") {
          box.y0 = otherBox.y1;
          box.y1 = box.y0 + box.h;
          direction = "down";
        } else {
          box.y1 = otherBox.y0;
          box.y0 = box.y1 - box.h;
          direction = "up";
        }

        box.yPlacement = "center";
        box.xPlacement = "left";
      }
      return { box, direction };
    };

    const pushBoxRight = (box, otherBox, dryDun) => {
      let direction = "right";
      const box2 = { ...box };
      if (dryDun) {
        box = { ...box };
      }
      box.x0 = otherBox.x1;
      box.x1 = box.x0 + box.w;

      if (box.x1 > width) {
        box.x1 = width;
        box.x1 = width - box.w;

        if (box.yPlacement === "top") {
          box.y0 = otherBox.y1;
          box.y1 = box.y0 + box.h;
          direction = "down";
        } else {
          box.y1 = otherBox.y0;
          box.y0 = box.y0 - box.h;
          direction = "up";
        }

        box.yPlacement = "center";
        box.xPlacement = "right";
      }
      return { box, direction };
    };

    const pushBoxUp = (box, otherBox, dryDun) => {
      let direction = "up";
      const box2 = { ...box };
      if (dryDun) {
        box = { ...box };
      }
      box.y1 = otherBox.y0;
      box.y0 = box.y1 - box.h;

      if (box.y0 < 0) {
        box.y0 = 0;
        box.y1 = box.h;

        if (box.xPlacement === "left") {
          box.x0 = otherBox.x1;
          box.x1 = box.x0 + box.w;
          direction = "right";
        } else {
          box.x1 = otherBox.x0;
          box.x0 = otherBox.x0 - box.w;
          direction = "left";
        }

        box.xPlacement = "center";
        box.yPlacement = "top";
      }
      return { box, direction };
    };

    const pushBoxDown = (box, otherBox, dryDun) => {
      let direction = "down";
      const box2 = { ...box };
      if (dryDun) {
        box = { ...box };
      }
      box.y0 = otherBox.y1;
      box.y1 = box.y0 + box.h;

      if (box.y1 > height) {
        box.y1 = height;
        box.y1 = height - box.h;

        if (box.xPlacement === "left") {
          box.x0 = otherBox.x1;
          box.x1 = box.x0 + box.w;
          direction = "right";
        } else {
          box.x1 = otherBox.x0;
          box.x0 = box.x1 - box.w;
          direction = "left";
        }

        box.xPlacement = "center";
        box.yPlacement = "bottom";
      }
      return {
        box,
        direction,
      };
    };

    const getBoxIntersectionsRaw = (box1, box2) => {
      const intersectionX = get2IntervalsIntersection(
        [box1.x0, box1.x1],
        [box2.x0, box2.x1]
      );
      if (!intersectionX) {
        return null;
      }
      const intersectionY = get2IntervalsIntersection(
        [box1.y0, box1.y1],
        [box2.y0, box2.y1]
      );
      if (!intersectionY) {
        return null;
      }
      return [intersectionX, intersectionY];
    };

    const getBoxIntersections = (i, j) => {
      const box1 = newBoxes[(i + newBoxes.length) % newBoxes.length];
      const box2 = newBoxes[(j + newBoxes.length) % newBoxes.length];
      if (box1 === box2) {
        box1.nextIntesection = null;
        box2.prevIntesection = null;
        return null;
      }

      const intersection = getBoxIntersectionsRaw(box1, box2);

      box1.nextIntesection = intersection;
      box2.prevIntesection = intersection;

      return intersection;
    };

    const getIntersectionsCount = () => {
      let intersectionsCount = 0;
      for (let i = 0; i < newBoxes.length; i++) {
        const intersection = getBoxIntersections(i, i + 1);
        if (intersection) {
          intersectionsCount++;
        }
      }
      return intersectionsCount;
    };

    const reflowBoxDirectional = (
      boxIndex,
      otherBoxIndex,
      pushDirection,
      endIndex = boxIndex
    ) => {
      const box = newBoxes[boxIndex];
      const isOtherNext = (boxIndex + 1) % newBoxes.length === otherBoxIndex;

      if (
        (isOtherNext && !box.nextIntesection) ||
        (!isOtherNext && !box.prevIntesection)
      ) {
        return false;
      }

      const otherBox = newBoxes[otherBoxIndex];

      const ternaryBoxIndex = isOtherNext
        ? (boxIndex - 1 + newBoxes.length) % newBoxes.length
        : (boxIndex + 1) % newBoxes.length;
      const ternaryBox = newBoxes[ternaryBoxIndex];

      switch (pushDirection) {
        case "left":
          {
            const { direction } = pushBoxLeft(box, otherBox);
            pushDirection = direction;
          }
          break;
        case "right":
          {
            const { direction } = pushBoxRight(box, otherBox);
            pushDirection = direction;
          }
          break;
        case "up":
          {
            const { direction } = pushBoxUp(box, otherBox);
            pushDirection = direction;
          }
          break;
        case "down":
          {
            const { direction } = pushBoxDown(box, otherBox);
            pushDirection = direction;
          }
          break;
      }

      getBoxIntersections(boxIndex, boxIndex + 1);
      getBoxIntersections(boxIndex - 1, boxIndex);

      if (otherBoxIndex === endIndex) {
        return false;
      }

      if (isOtherNext && ternaryBox.nextIntesection) {
        return reflowBoxDirectional(
          ternaryBoxIndex,
          boxIndex,
          pushDirection,
          endIndex
        );
      } else if (!isOtherNext && ternaryBox.prevIntesection) {
        return reflowBoxDirectional(
          ternaryBoxIndex,
          boxIndex,
          pushDirection,
          endIndex
        );
      }

      return true;
    };

    const reflowBox = (box) => {
      if (
        (box.nextIntesection && box.prevIntesection) ||
        (!box.nextIntesection && !box.prevIntesection)
      ) {
        return false;
      }

      const boxIndex = newBoxes.findIndex(({ key }) => key === box.key);
      const hasNextIntersection = Boolean(box.nextIntesection);

      const otherBoxIndex = hasNextIntersection
        ? (boxIndex + 1) % newBoxes.length
        : (boxIndex - 1 + newBoxes.length) % newBoxes.length;
      const otherBox = newBoxes[otherBoxIndex];

      const otherTernaryBoxIndex =
        (2 * otherBoxIndex - boxIndex + newBoxes.length) % newBoxes.length;
      const otherTernaryBox = newBoxes[otherTernaryBoxIndex];

      if (box.xPlacement === "left" || box.xPlacement === "right") {
        if (otherBox.y0 + otherBox.y1 < box.y0 + box.y1) {
          if (!otherBox.nextIntesection || !otherBox.prevIntesection) {
            const otherPushed = pushBoxUp(otherBox, box, true);
            if (!getBoxIntersectionsRaw(otherPushed.box, otherTernaryBox)) {
              pushBoxUp(otherBox, box);
              return true;
            }
          }
          return reflowBoxDirectional(boxIndex, otherBoxIndex, "down");
        }
        if (!otherBox.nextIntesection || !otherBox.prevIntesection) {
          const otherPushed = pushBoxDown(otherBox, box, true);
          if (!getBoxIntersectionsRaw(otherPushed.box, otherTernaryBox)) {
            pushBoxDown(otherBox, box);
            return true;
          }
        }
        return reflowBoxDirectional(boxIndex, otherBoxIndex, "up");
      } else if (box.xPlacement === "top" || box.xPlacement === "bottom") {
        if (otherBox.x0 + otherBox.x1 < box.x0 + box.x1) {
          if (!otherBox.nextIntesection || !otherBox.prevIntesection) {
            const otherPushed = pushBoxLeft(otherBox, box, true);
            if (!getBoxIntersectionsRaw(otherPushed.box, otherTernaryBox)) {
              pushBoxLeft(otherBox, box);
              return true;
            }
          }
          return reflowBoxDirectional(boxIndex, otherBoxIndex, "right");
        }
        if (!otherBox.nextIntesection || !otherBox.prevIntesection) {
          const otherPushed = pushBoxRight(otherBox, box, true);
          if (!getBoxIntersectionsRaw(otherPushed.box, otherTernaryBox)) {
            pushBoxRight(otherBox, box);
            return true;
          }
        }
        return reflowBoxDirectional(boxIndex, otherBoxIndex, "left");
      }
      return false;
    };

    function* doWork() {
      if (getIntersectionsCount() === 0) {
        return true;
      }
      while (true) {
        let box = [];
        for (let i = 0; i < newBoxes.length; i++) {
          let currBox = newBoxes[i];
          if (
            (currBox.nextIntesection && !currBox.prevIntesection) ||
            (!currBox.nextIntesection && currBox.prevIntesection)
          ) {
            box = currBox;
            break;
          }
        }
        if (!box) {
          return getIntersectionsCount() === 0;
        }
        reflowBox(box);
        const count = getIntersectionsCount();
        if (count === 0) {
          return true;
        }
        yield count;
      }
    }

    let calculateLines = () => {
      for (let i = 0; i < newBoxes.length; i++) {
        const box = newBoxes[i];
        const { xPlacement, yPlacement, x0, y0, x1, y1 } = box;
        let x1Line, y1Line;
        switch (xPlacement) {
          case "center":
            x1Line = (x0 + x1) / 2;
            break;
          case "right":
            x1Line = x0;
            break;
          case "left":
            x1Line = x1;
            break;
        }
        switch (yPlacement) {
          case "center":
            y1Line = (y0 + y1) / 2;
            break;
          case "bottom":
            y1Line = y0;
            break;
          case "top":
            y1Line = y1;
            break;
        }

        box.x1Line = x1Line;
        box.y1Line = y1Line;
      }
    };

    boxes = newBoxes;

    if (getIntersectionsCount() > 0) {
      let worker = doWork();
      for (let i = 0; i < 3 * newBoxes.length; i++) {
        if (worker.next().done) {
          break;
        }
      }
    }

    for (let i = 0; i < newBoxes.length - 1; i++) {
      const box1 = newBoxes[i];
      for (let j = i + 1; j < newBoxes.length; j++) {
        const box2 = newBoxes[j];
        if (getBoxIntersectionsRaw(box1, box2)) {
          box1.intersecting = true;
          box2.intersecting = true;
        }
      }
    }

    let newHiddenCount = 0;
    for (let i = 0; i < newBoxes.length - 1; i++) {
      const box = newBoxes[i];
      if (box.intersecting) {
        newHiddenCount++;
      }
    }
    if (newHiddenCount !== hiddenCount) {
      mutable.onHiddenCountChange &&
        mutable.onHiddenCountChange(newHiddenCount);
      hiddenCount = newHiddenCount;
    }

    calculateLines();
    draw();

    let worker = doWork();
    window.ann = {
      pushBoxDown,
      pushBoxLeft,
      pushBoxRight,
      pushBoxUp,
      getBoxIntersections,
      getIntersectionsCount,
      reflowBox,
      clearLines,
      draw: () => {
        calculateLines();
        self.draw();
      },
      boxes,
      getAngle,
      restartWork: () => {
        worker = doWork();
      },
      doWork: () => {
        const ret = worker.next();
        calculateLines();
        draw();
        return ret;
      },
    };

    for (let i = 0; i < newBoxes.length; i++) {
      newBoxes[i].element.box = newBoxes[i];
    }
  };
  self.reflow = reflow;

  self.trigger = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      reflow();
    }, 300);
  };
};

const PointsSideAnnotations = ({
  annotations,
  defaultClassName,
  disabled,
  onHiddenCountChange,
}) => {
  const mutableViewerState = useViewerMutableState();
  const [refs, getRefSetter] = useMultidimentionalRef(2);

  const mutable = useRef({}).current;
  mutable.viewerState = mutableViewerState.viewerState;
  mutable.refs = refs;
  mutable.onHiddenCountChange = onHiddenCountChange;

  mutable.annotations = annotations;

  const debouncerRef = useRef();
  const debouncer = debouncerRef.current ?? new Debouncer(mutable);

  useOnPostrender(() => {
    if (debouncer.refresh()) {
      debouncer.reflow();
    }
    return debouncer.clear;
  }, !disabled);

  if (disabled) {
    return null;
  }

  return annotations.map((annotation, i) => {
    const key = annotation?.key;
    if (!annotation?.key) {
      return null;
    }
    const { children, className = defaultClassName } = annotation;
    const refSetter = getRefSetter(key, "element");
    const sizeRefSetter = getRefSetter(key, "size");
    return (
      <ElementWithOnResize
        key={key}
        ref={refSetter}
        onResize={({ width, height }) => {
          sizeRefSetter({ width, height });
          debouncer.trigger();
        }}
        className={classNames(className, s.container)}
        onMouseMove={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {children}
      </ElementWithOnResize>
    );
  });
};

export default PointsSideAnnotations;
