import React from "react";
import classNames from "classnames";

const BarChart = ({
  color = "#ADADAD",
  color2 = "#00B3EC",
  className = undefined,
  iconScale = 1,
  icon = true,
}) => {
  color = color ?? "currentcolor";
  color2 = color2 ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 18 15"
    >
      <path
        d="M11.9699 4.27002H9.42993V12.98H11.9699V4.27002Z"
        fill="none"
        stroke={color2}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M3.98996 1H1.44995V12.98H3.98996V1Z"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M7.97992 7.54004H5.43994V12.98H7.97992V7.54004Z"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M15.9599 8.98999H13.4199V12.98H15.9599V8.98999Z"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
      <path
        d="M17.42 14.4299H0"
        fill="none"
        stroke={color}
        stroke-width="0.88"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default BarChart;
