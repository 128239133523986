import { useChilledWaterEmissions } from "./useChilledWaterEmissions";
import { useDomesticWaterEmissions } from "./useDomesticWaterEmissions";
import { useElectricityEmissisons } from "./useElectricityEmissions";
import { useFuelEmissions } from "./useFuelEmissions";
import { useRefrigerantEmissions } from "./useRefrigerantEmissions";
import { useTravelEmissions } from "./useTravelEmissions";
import { useUtilsEmissions } from "./useUtilsEmissions";
import { useWasteEmissisons } from "./useWasteEmissions";

type ScopesValues = {
  s1: number[];
  s2: number[];
  s3: number[];
};

type Consumption = {
  scopes: number[];
  total: number;
};

export type ConsumptionYear = {
  consumption: Consumption[];
};

const useTotalEmissionsByYear = () => {
  const { totalElectricityEmissionsByYear } = useElectricityEmissisons();
  const { totalChilledWaterEmissionsByYear } = useChilledWaterEmissions();
  const {
    companyVehiclesEmissionsByYear,
    employeeVehiclesEmissionsByYear,
    flightEmissionsByYear,
  } = useTravelEmissions();
  const { tewiTotal } = useRefrigerantEmissions();
  const { totalFuelEmissionsByYear } = useFuelEmissions();
  const { totalDomesticWaterEmissionsByYear } = useDomesticWaterEmissions();
  const { totalWasteEmissionsByYear } = useWasteEmissisons();

  const { combineMultipleArrays, replaceZeroValues } = useUtilsEmissions();

  const calculateScope = (
    travelEmissionsByYear: number[],
    tewi: number,
    fuelEmissionsByYear: number[]
  ) => {
    return travelEmissionsByYear.map(
      (item, index) => item + tewi + (fuelEmissionsByYear[index] || 0)
    );
  };

  const emissionsByScopeByYear = {
    s1: calculateScope(
      companyVehiclesEmissionsByYear,
      tewiTotal,
      totalFuelEmissionsByYear
    ),
    s2: combineMultipleArrays(
      totalElectricityEmissionsByYear,
      totalChilledWaterEmissionsByYear
    ),
    s3: combineMultipleArrays(
      employeeVehiclesEmissionsByYear,
      flightEmissionsByYear,
      totalDomesticWaterEmissionsByYear,
      totalWasteEmissionsByYear
    ),
  };

  const transformEmissions = (data: ScopesValues) => {
    const result: ConsumptionYear = { consumption: [] };

    for (let i = 0; i < 12; i++) {
      const scopes = [data["s1"][i], data["s2"][i], data["s3"][i]];

      const total = scopes.reduce((acc: number, val: number) => acc + val, 0);

      const newObj = {
        scopes,
        total,
      };

      result.consumption.push(newObj);
    }

    return result;
  };

  let consumptionsByYear: ConsumptionYear = transformEmissions(
    emissionsByScopeByYear
  );
  consumptionsByYear = replaceZeroValues(consumptionsByYear);
  return {
    consumptionsByYear,
  };
};

export { useTotalEmissionsByYear };
