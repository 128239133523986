const AR = {
  "error.ERROR_LOADING_BUILDING": "خطأ في تحميل المبنى",
  "error.ERROR_LOADING_MODEL": "خطأ في تحميل النموذج",
  "title.LOGIN": "تسجيل الدخول",
  "label.USERNAME": "اسم االمستخدم",
  "placeholder.USERNAME": "اسم المستخدم الخاص بك",
  "label.PASSWORD": "كلمة المرور",
  "placeholder.PASSWORD": "كلمة السر خاصتك",
  "action.LOGIN": "تسجيل الدخول",
  "error.COULD_NOT_LOGIN":
    "تعذر تسجيل الدخول. تأكد من صحة اسم المستخدم وكلمة المرور",
  "title.STAY_LOGGED_IN": "ابق متصلا",
  "message.STAY_LOGGED_IN": "جلستك على وشك الانتهاء. هل تريد أن تبقى مسجلا؟",
  "action.STAY_LOGGED_IN": "ابق متصلا",
  "action.CANCEL": "يلغي",
  "error.USERNAME_REQUIRED": "اسم المستخدم مطلوب",
  "error.PASSWORD_REQUIRED": "كلمة المرور مطلوبة",
  "toast.FILL_USERNAME_AND_PASSWORD": "يرجى ملء اسم المستخدم وكلمة المرور",
  ERR_RECAPTCHA: "فشل التحقق من صحة captcha. حاول مرة أخرى إذا كنت إنسانًا",
  ER_INVALID_CAPTCHA:
    "فشل التحقق من صحة captcha. حاول مرة أخرى إذا كنت إنسانًا",
  "error.COULD_NOT_STAY_LOGGEDIN": "تعذر الاحتفاظ بتسجيل الدخول",
  "error.UPLOADING_IFC": "خطأ في تحميل / تحويل ملف IFC",
  ER_BUSY_CONVERTING_ANOTHER_IFC:
    "مشغول بتحويل نموذج آخر. يرجى الانتظار والمحاولة مرة أخرى في وقت لاحق.",
  "action.MORE": "أكثر",
  "action.BACK": "خلف",
  "menu.USER_COMFORT": "راحة المستخدم",
  "menu.HUMAN_CENTIC": "المحور إنسان",
  "menu.SUSTAINABILITY": "الاستدامة",
  "menu.ENERGY_MANAGEMENT": "إدارة الطاقة",
  "menu.ASSET_MANAGEMENT": "إدارة الأصول",
  "title.PERFORMANCE": "الأداء",
  "message.POWERED_BY": "معزز من",
  "dateFilter.TODAY": "اليوم",
  "dateFilter.WEEK": "أسبوع",
  "dateFilter.MONTH": "شهر",
  "title.OPERATIONAL_SPENDING": "الإنفاق التشغيلي",
  "label.TOTAL": "مجموع",
  "label.POTENTIAL_SAVINGS": "المدخرات المحتملة",
  "label.TOTAL_SPENDING": "إجمالي الإنفاق",
  "message.SPENDING_IMPORVEMENT": "${value}% تحسن من الأسبوع الماضي",
  "message.SPENDING_REGRESSION": "${value}% أسوأ من الأسبوع الماضي",
  "message.SPENDING_SAME": "مثل الأسبوع الماضي",
  "label.ELECTRICITY": "كهرباء",
  "label.CHILLED_WATER": "المياه المبردة",
  "title.EFFICIENCY": "الفعالية",
  "title.OCCUPANCY": "الإشغال",
  "defaultOption.MODEL_COLORIZATION": "لا التلوين",
  "defaultOption.MODEL_ANNOTATION": "لا ملاحظات",
  "title.RECOMMENDATIONS": "التوصيات",
  "option.COLORIZE_DESKS": "طاولات",
  "option.COLORIZE_PIM": "PIM",
  "option.COLORIZE_LIGHTS": "إضاءة",
  "option.ANNOTATE_DESKS": "طاولات",
  "option.ANNOTATE_PIM": "PIM",
  "option.ANNOTATE_LIGHTS": "إضائة",
  "defaultOption.HEAT_MAP": "لا خرائط الحرارة",
  "heatMapOption.NONE": "لا خرائط الحرارة",
  "heatMapOption.ATTENDANCE": "حضور",
  "heatMapOption.OCUPANCY": "إشغال",
  "heatMapOption.ELECTRICITY": "كهرباء",
  "heatMapOption.LIGHTING": "إضاءة",
  "heatMapOption.MECHANICAL": "ميكانيكي",
  "heatMapOption.POWER": "طاقة",
  "heatMapOption.UPS": "UPS",
  "heatMapOption.WATER": "ماء",
  "heatMapOption.CHILLED_WATER": "مياه مبردة",
  "title.LIVE_VIEW": "عرض مباشر",
  "title.ELECTRICITY": "كهرباء",
  "title.CHILLED_WATER": "مياه مبردة",
  "title.OCCUPIED_AREAS": "مناطق مملوءة",
  "title.WHOLE_FLOOR": "كامل الطابق",
  "filter.FROM_TO_DATE": "من ${from} إلى ${to}",
  "error.ERROR_LOADING_TELEMETRIES": "خطأ في تحميل البيانات",
  "action.HIDE_ALL": "أخف كل شيء",
  "title.HOURLY_BREAKDOWN": "تفصيل ساعي",
  "title.BREAKDOWN_BY_TYPE": "حسب النوع",
  "title.HVAC": "التكييف",
  "title.BREAKDOWN_BY_ZONE": "حسب المكان",
  "title.TOTAL_SPENDING": "إجمالي الإنفاق",
  "title.TOTAL_POTENTIAL_SAVINGS": "إجمالي المدخرات المحتملة",
  "title.ELECTRICITY_TOTAL_SPENDING": "إجمالي الكهرباء",
  "title.ELECTRICITY_POTENTIAL_SAVINGS": "المدخرات المحتملة للكهرباء",
  "title.CHILLED_WATER_TOTAL_SPENDING": "إجمالي المياه المبردة",
  "title.CHILLED_WATER_POTENTIAL_SAVINGS": "المدخرات المحتملة للمياه المبردة",
  "title.LIGHTING_TOTAL_SPENDING": "إجمالي الإضاءة",
  "title.LIGHTING_POTENTIAL_SAVINGS": "المدخرات المحتملة للإضاءة",
  "title.HVAC_TOTAL_SPENDING": "إجمالي التكييف",
  "title.HVAC_POTENTIAL_SAVINGS": "المدخرات المحتملة للتكييف",
  "title.POWER_TOTAL_SPENDING": "إجمالي الطاقة",
  "title.POWER_POTENTIAL_SAVINGS": "المدخرات المحتملة للطاقة",
  "title.LIGHTING": "الإضاءة",
  "title.POWER": "الطاقة",
  "title.PREVIOUS_WEEK": "الأسبوع السابق",
  "title.ANALYTICS": "تحليلات",
  "label.AVERAGE_SPENDING": "متوسط الإنفاق",
  "label.PEAK_SPENDING": "أعلى إنفاق",
  "label.LARGEST_PERFORMANCE_GAP": "أعلى إنفاق",
  "label.START": "بداية",
  "label.END": "نهاية",
  "label.DURATION": "مدة",
  "unit.HOURS": "ساعة",
  "note.STILL_ACTIVE": "لا تزال نشطة",
  "note.SO_FAR": "حتى الآن",
  "legend.FROM_TO": "${from}-${to}",
  "groupOption.BY_FUNCTIONAL_AREA": "التجميع حسب المنطقة الوظيفية",
  "groupOption.BY_SYSTEM": "تجميع حسب النظام",
  "groupOption.BY_DURATION": "تجميع حسب المدة",
  "groupOption.BY_TOTAL_COST": "تجميع حسب التكلفة الإجمالية",
  "groupOption.BY_COST_PER_HOUR": "تجميع حسب تكلفة الساعة",
  "groupOption.BY_NUMBER_OF_RECURRENCE": "تجميع حسب عدد التكرار",
  "groupOption.BY_TYPE": "تجميع حسب النوع",
  "title.BY_FUNCTIONAL_AREA": "حسب المجال الوظيفي",
  "title.BY_DURATION": "حسب المدة",
  "title.BY_TOTAL_COST": "حسب التكلفة الإجمالية",
  "title.BY_COST_PER_HOUR": "حسب التكلفة بالساعة",
  "title.BY_NUMBER_OF_RECURRENCE": "عن طريق العد التكرار",
  "title.BY_TYPE": "حسب النوع",
  "sortOption.BY_CHRONOLOGY": "فرز حسب التسلسل الزمني",
  "sortOption.BY_IMPACT": "فرز حسب التأثير",
  "sortOption.BY_DURATION": "فرز حسب المدة",
  "message.REFRESHING_TELEMETRIES": "جارِ تحديث البيانات من الخادم",
  "warning.TELEMETRIES_TRIMMED_FROM_SERVER":
    "بعض البيانات المطلوبة ليست متاحة بعد على الخادم. سيتم الفحص التلقائي بعد قليل",
  "title.POTENTIAL_SAVINGS_BY_HOUR": "المدخرات المحتملة بالساعة",
  "title.POTENTIAL_SAVINGS_BY_TYPE": "المدخرات المحتملة حسب النوع",
  "title.RECOMMENDATIONS_COUNT": "عدد التوصيات",
  "title.POTENTIAL_SAVINGS": "المدخرات المحتملة",
  "title.POTENTIAL_SAVINGS_BY_ZONE": "المدخرات المحتملة حسب المنطقة",
  "title.AIR_HANDLING_UNITS": "Air handling units",
  "title.FAN_COIL_UNITS": "Fan coil units",
  "title.CHILLED_WATER_PUMPS": "Chilled water pumps",
  "title.PIPES": "Pipes",
  "title.DISTRIBUTION_PANEL_BOARDS": "Distribution panel boards",
  "title.DUCTS": "Ducts",
  "title.OTHERS": "Others",
  "message.NO_PROPERTIES_TO_DISPLAY": "لا توجد أي خصائص لعرضها",
  "toast.OBJECT_NOT_SELECTABLE": "هذا شيء غير قابل للتحديد",
  "title.STATIC_PROPERTIES": "المعلومات الفنية",
  "title.DYNAMIC_PROPERTIES": "بيانات مباشرة",
  "title.ANALYTICS_PROPERTIES": "تحليلات",
  "message.STILL_ONGOING": "ناشط",
  "telemetryName.CONSUMPTION": "استهلاك",
  "telemetryName.CUMULATIVE_POTENTIAL_SAVINGS": "المدخرات المحتملة التراكمية",
  "telemetryName.CARBON_FOOTPRINT": "البصمة الكربونية",
  "telemetryName.CARBON_FOOTPRINT_TARGET": "هدف البصمة الكربونية",
  "telemetryName.PERFORMANCE_GAP": "فجوة الأداء",
  "title.FAULTS_OR_RECOMMENDATION": "خلل/توصيات",
  "title.CONSUMPTION_VS_PREDICTED": "الاستهلاك الفعلي مقابل الاستهلاك المتوقع",
  "message.NO_TELEMETRIES_TO_SHOW":
    "لا توجد بيانات حية متاحة لهذا العنصر حتى الآن",
  "status.ACTIVE": "ناشط",
  "status.INACTIVE": "غير نشط",
  "status.CLEAN": "نظيف",
  "status.DIRTY": "متسخ",
  "tip.HIDE_SHOW_ANNOTATIONS": "إخفاء / إظهار الإطارات المنبثقة",
  "tip.HIDE_SHOW_COLORIZATIONS": "تمكين / تعطيل تلوين الحالة",
  "title.CONSUMPTION": "استهلاك",
  "title.CARBON_FOOTPRINT": "البصمة الكربونية",
  "message.NO_FAULTS": "لا يوجد اي خلل لإظهاره",
  "tip.HIDE_SHOW_ALL": "إخفاء / إظهار المستوى الأعلى",
  "title.ASSET_MANAGEMENT": "إدارة الأصول",
  "toast.COULD_NOT_GET_LIVE_STATUS":
    "تعذر الحصول على الحالة الحية لـ${entity.display}",
  "toast.NO_STATUS_AVAILABLE_TO_CHANGE":
    "حالة ${entity.display} غير متاحة للتغيير", //Status of ${entity.display} in not available to change",
  "toast.LOADING_STATUS_PLEASE_WAIT":
    "حالة تحميل ${entity.display} قيد التقدم. أرجو الإنتظار",
  "toast.CHANGING_STATUS_PLEASE_WAIT":
    "تغيير آخر للوضع قيد التقدم. أرجو الإنتظار",
  "toast.COULD_NOT_TURN_ON": "لا يمكن تشغيل ${entity.display}",
  "toast.COULD_NOT_TURN_OFF": "لا يمكن إيقاف ${entity.display}",
  "label.CONTROL": "تحكم",
  "tip.ON_SINCE": "يعمل منذ ${date}",
  "tip.OFF_SINCE": "متوقف منذ ${date}",
  "tip.CLICK_TO_TURN_OFF": "انقر لإيقاف",
  "tip.CLICK_TO_TURN_ON": "انقر للتشغيل",
  "tip.UNKNOWN_STATUS": "حالة غير معروفة",
  "status.ON": "On",
  "status.OFF": "Off",
  "confirm.TURN_ON": "هل أنت متأكد أنك تريد تشغيل ${entity.display}?",
  "confirm.TURN_OFF": "هل أنت متأكد أنك تريد إيقاف ${entity.display}?",
  "toast.STATUS_UNKNOWN_FOR":
    "تعذر استرداد حالة ${entity.display}. ومن ثم لا يمكن تغيير وضعها. ستتم إعادة محاولة استرداد الحالة",
  "note.WELCOME_USER": "مرحبًا ${user}!",
  "note.IS_ADMIN": "انت مسؤول عام",
  "action.LOGOUT": "تسجيل خروج",
  "tip.ENABLE_DIABLE_CAMERA_LIGHT": "تمكين / تعطيل مصباح يدوي",
  "filter.X_HOURS_SELECTED": "${x} ساعات مختارة",
  "tip.TIME_IN_THE_FUTURE": "هذا الوقت في المستقبل",
  "title.OPERATIONS_AND_SUSTAINABILITY": "العمليات والاستدامة",
  "title.FDD_RECOMMENDATIONS": "FDD / توصيات",
  "telemetryName.TARGET": "الهدف",
  "action.CLEAR_LOCAL_CACHE": "مسح التخزين المحلي",
  "toast.FAILED_TO_CLEAR_CACHE": "فشل مسح التخزين المحلي",
  "label.ID": "ID",
  "label.DATE": "تاريخ",
  "label.START_DATE": "تاريخ البدء",
  "label.END_DATE": "تاريخ الانتهاء",
  "label.START_TIME": "وقت البدء",
  "label.END_TIME": "وقت النهاية",
  "label.DETAILS": "تفاصيل",
  "title.CO2_POTENTIAL_SAVINGS": "تقليل الكربون المحتمل",
  "option.ENERGY": "طاقة",
  "option.CURRENCY": "عملة",
  "option.EMISSIONS": "الانبعاثات",
  "note.AVERAGE_ACTIVE_RECOMMENDATIONS": "توصيات نشطة",
  "note.POTENTIAL_SAVINGS": "المدخرات المحتملة",
  "label.SUCCESS_RATE": "نسبة النجاح",
  "label.TOTAL_RECOMMENDATIONS": "إجمالي التوصيات",
  "label.TOTAL_ACTED_UPON": "عمل عليها",
  "label.AVERAGE_RESPONSE_TIME": "متوسط وقت الاستجابة",
  "label.TOTAL_SAVINGS": "إجمالي المدخرات",
  "label.ACTED_UPON": "عمل عليها",
  "label.NOT_ACTED_UPON": "لم يعمل عليها",
  "title.INSIGHTS": "رؤى",
  "legend.ACTED_UPON": "عمل عليها",
  "legend.NOT_ACTED_UPON": "لم يعمل عليها",
  "action.SHOW_LESS": "عرض أقل",
  "action.SHOW_REMAINING_X": "عرض الـ${x} المتبقين",
  "action.ADVANCED_MENU_HANDLE_TEXT": "متقدم",
  "label.TOTAL_CONSUMPTION": "إجمالي الاستهلاك",
  "label.CONSUMPTION_CONTRIBUTION": "مساهمة الاستهلاك",
  "label.CONSUMPTION": "استهلاك",
  "title.PERFORMANCE_GAP": "فجوة الأداء",
  "title.PERCENTILE": "النسبة المئوية",
  "title.BY_AREA": "حسب المجال الوظيفي",
  "title.BY_SYSTEM": "حسب النظام",
  "title.FOCUSED_AREA": "المنطقة المركزة",
  "label.AVERAGE_GAP": "متوسط الفجوة",
};

export default AR;
