import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  forwardRef,
} from "react";
const { createPortal } = require("react-dom");

const { SingleEvent } = require("modules/core/helpers/events");

const Portaller = function () {
  const self = this;
  if (!(self instanceof Portaller)) {
    throw new Error("Portaller is a class and must be instantiated with `new`");
  }

  const event = new SingleEvent();
  let mutableElement,
    targetRendered = false;

  const Portal = ({ children }) => {
    const [element, setElement] = useState(mutableElement);
    useLayoutEffect(() => {
      if (element !== mutableElement) {
        setElement(mutableElement);
      }
      return event.addListener(setElement);
    }, []);
    if (!element || !children || children.length === 0) {
      return null;
    }
    return createPortal(children, element);
  };

  const Target = forwardRef(({ as, ...props }, ref) => {
    const Component = as ?? "div";
    useEffect(() => {
      if (targetRendered) {
        throw new Error(
          `Portal "Target" component is allowed to render only once`
        );
      }
      targetRendered = true;
      return () => {
        targetRendered = false;
      };
    }, []);
    return (
      <Component
        {...props}
        ref={(element) => {
          mutableElement = element;
          if (typeof ref === "function") {
            ref(element);
          } else if (ref) {
            ref.current = element;
          }
          event.triggerEvent(element);
        }}
      />
    );
  });

  self.Target = Target;
  self.Portal = Portal;

  self.state = { Target, Portal };

  return self;
};

/**
 * const Target: React.ForwardRefExoticComponent<React.RefAttributes<any>>
 * @typedef {React.ForwardRefExoticComponent<any, React.RefAttributes<{as:string | React.Component} | React.AllHTMLAttributes>>} TargetComponent
 */
/**
 * @typedef {React.FunctionComponent<{children: typeof React.Children}>} PortalComponent
 */
/**
 * @returns {{ Target: TargetComponent, Portal: PortalComponent }}
 */
const usePortal = () => {
  const portallerRef = useRef();
  portallerRef.current = portallerRef.current ?? new Portaller();
  return portallerRef.current.state;
};

export default usePortal;
