import classNames from "classnames";
import { forwardRef, type ButtonHTMLAttributes } from "react";
import Loader from "atomic-components/atoms/Loader";

import s from "./Button.module.scss";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  skin?:
    | "default"
    | "primary"
    | "secondary"
    | "blue"
    | "white"
    | "outline"
    | "outline-minor"
    | "link"
    | "danger";
  size?: "default" | "small" | "large" | "xlarge";
  isLoading?: boolean;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      skin = "default",
      size = "default",
      disabled = false,
      className,
      isLoading,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        disabled={disabled}
        type="button"
        className={classNames(s.btn, s[skin], s[`size--${size}`], className)}
        {...props}
      >
        {isLoading ? (
          <Loader size={15} marginTop={0} display="block" />
        ) : (
          children
        )}
      </button>
    );
  }
);

export default Button;
export type { ButtonProps };
