import React, { useMemo, useRef } from "react";
import { useModelState } from "../../Model";
import { MultiObjectsColorizer } from "modules/viewer/components/ObjectColorizer/ObjectColorizer";
import { difference, flatten } from "lodash";
import useBuildingData from "modules/building/hooks/useBuildingData";

/**
 * @type {React.NamedExoticComponent<{ entityTypesToHide: string[]; mappingName?: string; priority?: number }>}
 */
const EntityTypesHider = React.memo(
  ({ entityTypesToHide, mappingName = "default", priority = 0 }) => {
    const { buildingData } = useBuildingData();
    const { entities } = buildingData;

    const { getEntityMappingByEntityId } = useModelState();

    const prevEntityTypesToHideRef = useRef();
    entityTypesToHide = useMemo(() => {
      const prevEntityTypesToHide = prevEntityTypesToHideRef.current;
      if (
        prevEntityTypesToHide?.length !== entityTypesToHide.length ||
        difference(prevEntityTypesToHide, entityTypesToHide).length > 0
      ) {
        return entityTypesToHide;
      }
      return prevEntityTypesToHide;
    }, [entityTypesToHide]);
    prevEntityTypesToHideRef.current = entityTypesToHide;

    const objectsIdsToHide = useMemo(() => {
      return flatten(
        entities
          .filter(({ type }) => entityTypesToHide.includes(type))
          .map(
            ({ id: entityId }) =>
              getEntityMappingByEntityId(entityId, mappingName)?.objectsIds ??
              []
          )
      );
    }, [entityTypesToHide, entities, getEntityMappingByEntityId]);

    return objectsIdsToHide.length ? (
      <MultiObjectsColorizer
        objects={objectsIdsToHide}
        color={0x000000}
        opacity={0}
        priority={priority}
      />
    ) : null;
  }
);

const deepFilterEntityByIds = (entity, IDs) => {
  // check if the entity's id is in the list
  if (IDs.includes(entity.id)) {
    return true;
  }
  // checking parent entity
  if (entity.parentEntity) {
    return deepFilterEntityByIds(entity.parentEntity, IDs);
  }
  // if neither the entity nor any parent entities are in the list, return false
  return false;
};

/**
 * @type {React.NamedExoticComponent<{ entityIDsToHide: string[] }>}
 */
const EntityIDsDeepHider = React.memo(({ entityIDsToHide }) => {
  const { buildingData } = useBuildingData();
  const { entities } = buildingData;

  const mappingName = "default";
  const priority = 0;

  const { getEntityMappingByEntityId } = useModelState();

  const objectsIdsToHide = useMemo(() => {
    return flatten(
      entities
        .filter((entity) => deepFilterEntityByIds(entity, entityIDsToHide))
        .map(
          ({ id: entityId }) =>
            getEntityMappingByEntityId(entityId, mappingName)?.objectsIds ?? []
        )
    );
  }, [entityIDsToHide, entities, getEntityMappingByEntityId]);

  return objectsIdsToHide.length ? (
    <MultiObjectsColorizer
      objects={objectsIdsToHide}
      color={0x000000}
      opacity={0}
      priority={priority}
    />
  ) : null;
});

export default EntityTypesHider;
export { EntityIDsDeepHider };
