import {
  flatten,
  flattenDeep,
  groupBy,
  maxBy,
  minBy,
  orderBy,
  pull,
  sortedIndexBy,
  uniq,
  uniqBy,
  difference,
} from "lodash";
import { loadTimeseries as loadTimeseriesFromServer } from "../api/building.api";
import { v4 as uuidV4 } from "uuid";

import db from "../../../db";
import { EventsHub } from "modules/core/helpers/events";
import { mergeIntervals } from "../../../helpers/computeIntervalsDuration";

const ONE_MINUTE = 60 * 1000;

let timeseriesCache;
let initPromise = db.cache
  .where("key")
  .equals("timeseries")
  .first()
  .then((cachedTimeseries) => {
    timeseriesCache = cachedTimeseries?.value ?? {};
    initPromise = null;
  });

let savePromise;
const saveState = () => {
  const timeseriesEntries = Object.entries(timeseriesCache)
    .map(([timeseriesId, cachedTimeseries]) => {
      const rangesToSave = cachedTimeseries.ranges.filter(
        ({ loading }) => !loading
      );
      if (!rangesToSave.length) {
        return null;
      }
      return [timeseriesId, { ...cachedTimeseries, ranges: rangesToSave }];
    })
    .filter(Boolean);
  (savePromise ?? Promise.resolve()).then(() => {
    return db.cache
      .put({
        key: "timeseries",
        value: Object.fromEntries(timeseriesEntries),
      })
      .catch(() => {
        return db.cache.delete("timeseries");
      })
      .then(() => {
        savePromise = null;
      });
  });
};

const loadTimeseries = (
  rootEntityId,
  timeseriesRequests,
  retries = 0,
  maxRetries = 2
) => {
  return loadTimeseriesFromServer(rootEntityId, timeseriesRequests).catch(
    (err) => {
      if (retries < maxRetries) {
        return loadTimeseries(
          rootEntityId,
          timeseriesRequests,
          retries + 1,
          maxRetries
        );
      }
      throw err;
    }
  );
};

const onLoadEvent = new EventsHub();

const processRangeLoaded = (timeseries, loadedRange) => {
  let wasTrimmedFromServer = false;

  const lastSync = timeseries.lastSync;

  delete loadedRange.loading;
  loadedRange.previousDatum = timeseries.previousDatum;
  loadedRange.data = timeseries.data;
  loadedRange.nextDatum = timeseries.nextDatum;

  const timeseriesId = timeseries.timeseriesId ?? timeseries.id;

  if (lastSync && lastSync < loadedRange.to) {
    console.error(
      `OOPS lastSync < currentToLoadRange.to (${lastSync} < ${loadedRange.to})`
    );

    wasTrimmedFromServer = true;

    const noDataForRange = lastSync <= loadedRange.from;
    const replacementRange = noDataForRange
      ? null
      : {
          ...loadedRange,
          to: lastSync,
        };

    const index = timeseriesCache[timeseriesId].ranges.indexOf(loadedRange);

    /**
     * remove range from cache if lastSync <= loadedRange.from
     * to avoid interpolating to a value in the future
     */
    if (index >= 0) {
      if (noDataForRange) {
        timeseriesCache[timeseriesId].ranges.splice(index, 1);
      } else {
        timeseriesCache[timeseriesId].ranges[index] = replacementRange;
      }
    }
  }

  return wasTrimmedFromServer;
};

/**
 * @param {string} rootEntityId
 * @param {array} timeseriesRequests
 * @param {number} [retries]
 * @param {number} [maxRetries]
 */
const loadInternal = async (
  rootEntityId,
  timeseriesRequests,
  retries = 0,
  maxRetries = 3
) => {
  if (initPromise) {
    await initPromise;
  }

  sort();

  const toLoadRanges = [];
  const loadingRanges = [];
  const requests = [];
  timeseriesRequests.forEach((timeseriesRequest) => {
    const dateFilter = {
      from: timeseriesRequest.from,
      to: timeseriesRequest.to,
    };
    const { from, to } = dateFilter;
    const timeseriesIds = timeseriesRequest.timeseriesIds;

    const requestToLoadRanges = [];

    timeseriesIds.forEach((timeseriesId) => {
      const cachedTimeseries =
        timeseriesCache[timeseriesId] ??
        (timeseriesCache[timeseriesId] = { ranges: [] });

      const timeseriesRanges = cachedTimeseries.ranges;

      let loadFrom,
        loadTo,
        index,
        toAwait = [];
      for (let r = 0; r < timeseriesRanges.length; r++) {
        let range = timeseriesRanges[r];
        if (range.from <= dateFilter.from && dateFilter.from < range.to) {
          let maxTo = range.to;
          while (true) {
            if (range.loading) {
              toAwait.push(range);
            }
            if (timeseriesRanges[r + 1]?.from <= maxTo) {
              range = timeseriesRanges[++r];
              maxTo = Math.max(maxTo, range.to);
            } else {
              break;
            }
          }
          loadFrom = maxTo;
          index = r;
        }
        if (range.from < dateFilter.to && dateFilter.to <= range.to) {
          let minFrom = range.from;
          while (true) {
            if (range.loading) {
              toAwait.push(range);
            }
            if (timeseriesRanges[r - 1]?.to >= minFrom) {
              range = timeseriesRanges[--r];
              minFrom = Math.min(minFrom, range.from);
            } else {
              break;
            }
          }
          loadTo = minFrom;
          break;
        }
      }
      loadFrom = loadFrom ?? from;
      loadTo = loadTo ?? to;
      loadingRanges.push(...toAwait);

      if (loadFrom >= loadTo) {
        return null;
      }

      const newRange = {
        from: loadFrom,
        to: loadTo,
        timeseriesId,
        loading: true,
      };

      requestToLoadRanges.push(newRange);
      timeseriesRanges.splice(index, 0, newRange);
    });

    const timeseriesIdsToLoad = uniq(
      requestToLoadRanges.map((r) => r.timeseriesId)
    );

    if (timeseriesIdsToLoad.length) {
      const realFrom = minBy(requestToLoadRanges, "from").from;
      const realTo = maxBy(requestToLoadRanges, "to").to;
      if (realFrom >= realTo) {
        requestToLoadRanges.forEach((range) => {
          pull(timeseriesCache[range.timeseriesId].ranges, range);
        });
        return;
      }

      requestToLoadRanges.forEach((r) => {
        r.from = realFrom;
        r.to = realTo;
      });
      const uniqRequestToLoadRanges = timeseriesIdsToLoad.map((timeseriesId) =>
        requestToLoadRanges.find((range) => range.timeseriesId === timeseriesId)
      );
      toLoadRanges.push(uniqRequestToLoadRanges);
      requests.push({
        from: realFrom,
        to: realTo,
        timeseriesIds: timeseriesIdsToLoad,
      });
      difference(requestToLoadRanges, uniqRequestToLoadRanges).forEach(
        (range) => {
          pull(timeseriesCache[range.timeseriesId].ranges, range);
        }
      );
    }
    sort();
  });

  const results = requests.length
    ? await loadTimeseries(rootEntityId, requests, retries, maxRetries).catch(
        (error) => {
          for (let i = 0; i < requests.length; i++) {
            const currentToLoadRanges = toLoadRanges[i];

            currentToLoadRanges.forEach((currentToLoadRange) => {
              pull(
                timeseriesCache[currentToLoadRange.timeseriesId].ranges,
                currentToLoadRange
              );
            });

            const flatToLoadRanges = flattenDeep(currentToLoadRanges);
            for (let j = 0; j < flatToLoadRanges.length; j++) {
              const currentToLoadRange = flatToLoadRanges[j];
              onLoadEvent.triggerEvent(currentToLoadRange, {
                range: currentToLoadRange,
                success: false,
                error,
              });
            }
          }
          throw error;
        }
      )
    : [];

  const loadEventsToFire = [];
  let wasTrimmedFromServer = false;
  for (let i = 0; i < results.length; i++) {
    const currentToLoadRanges = toLoadRanges[i];
    const result = results[i];

    const allTimeseries = result.timeseries;

    for (let j = 0; j < allTimeseries.length; j++) {
      const timeseries = allTimeseries[j];
      const currentToLoadRange = currentToLoadRanges[j];

      const currentTrimmedFromServer = processRangeLoaded(
        timeseries,
        currentToLoadRange
      );
      if (timeseries.resolutionAccumulator) {
        timeseriesCache[timeseries.timeseriesId].resolutionAccumulator =
          timeseries.resolutionAccumulator;
      }
      loadEventsToFire.push({
        range: currentToLoadRange,
        wasTrimmedFromServer: currentTrimmedFromServer,
      });
      wasTrimmedFromServer = wasTrimmedFromServer || currentTrimmedFromServer;
    }
  }

  if (wasTrimmedFromServer) {
    sort();
  }
  for (let i = 0; i < loadEventsToFire.length; i++) {
    const { range, wasTrimmedFromServer } = loadEventsToFire[i];
    onLoadEvent.triggerEvent(range, {
      range,
      success: true,
      wasTrimmedFromServer,
    });
  }

  let success = true;
  let errors = true;
  if (loadingRanges.length) {
    await Promise.all(
      loadingRanges.map((range) => {
        if (range.loading) {
          return new Promise((resolve) => {
            onLoadEvent.once(range, (e) => {
              if (!e.success) {
                success = false;
                if (e.error) {
                  errors.push(e.error);
                }
              }
              resolve();
            });
          });
        }
      })
    );
  }

  if (!success) {
    if (errors.length === 0) {
      throw new Error(`Error loading timeseries`);
    }
    if (errors.length === 1) {
      throw errors[0];
    }
    const error = new Error(`Error loading timeseries`);
    error.errors = errors;
    throw error;
  }

  sort();
  clean();
  saveState(); // asynchronously saves the state

  return {
    didLoadFromServer: requests.length > 0 || loadingRanges.length > 0,
    wasTrimmedFromServer,
  };
};

const clean = () => {
  let didMerge = false;
  sort();
  Object.entries(timeseriesCache).forEach(([_, { ranges }]) => {
    const newRanges = [];
    for (let i = 0; i < ranges.length; i++) {
      if (ranges[i].loading || !ranges[i + 1] || ranges[i + 1].loading) {
        newRanges.push(ranges[i]);
      } else if (ranges[i].to < ranges[i + 1].from) {
        newRanges.push(ranges[i]);
      } else {
        didMerge = true;
        const rangesToMerge = [ranges[i], ranges[i + 1]];
        i++;
        while (
          ranges[i + 1] &&
          !ranges[i + 1].loading &&
          ranges[i].to >= ranges[i + 1].from
        ) {
          i++;
          rangesToMerge.push(ranges[i]);
        }
        newRanges.push(mergeRanges(rangesToMerge));
      }
    }
    ranges.splice(0, ranges.length, ...newRanges);
  });
  if (didMerge) {
    sort();
    clean();
  }
};

const sort = () => {
  Object.entries(timeseriesCache).forEach(([_, { ranges }]) => {
    ranges.splice(
      0,
      ranges.length,
      ...orderBy(ranges, ["loading", "from", "to"], ["asc", "asc", "desc"])
    );
  });
};

const mergeRanges = (ranges) => {
  const min = minBy(ranges, "from");
  const max = maxBy(ranges, "to");
  const allRangesHaveData = !ranges.find((range) => !range.data);
  return {
    timeseriesId: min.timeseriesId,
    from: min.from,
    to: max.to,
    previousDatum: min.previousDatum,
    nextDatum: max.nextDatum,
    data: allRangesHaveData
      ? orderBy(
          uniqBy([].concat(...ranges.map((r) => r.data)), "timestamp"),
          ["timestamp"],
          ["asc"]
        )
      : [],
  };
};

const getFromLocal = (timeseriesRequests, sync) => {
  if (!sync && initPromise) {
    return initPromise.then(() => getFromLocal(timeseriesRequests));
  }

  const get = (from, to, timeseriesIds) =>
    timeseriesIds.map((timeseriesId) => {
      const cachedTimeseries = timeseriesCache?.[timeseriesId];
      if (!cachedTimeseries) {
        return { timeseriesId, coverage: 0, data: [], availableRanges: [] };
      }
      const { ranges: timeseriesRanges, resolutionAccumulator } =
        cachedTimeseries;

      let data = [],
        previousDatum,
        nextDatum;
      const updatePreviousNextDatum = (candidatePrevious, candidateNext) => {
        if (
          !previousDatum ||
          (candidatePrevious &&
            candidatePrevious.timestamp > previousDatum.timestamp)
        ) {
          previousDatum = candidatePrevious;
        }
        if (
          !nextDatum ||
          (candidateNext && candidateNext.timestamp < nextDatum.timestamp)
        ) {
          nextDatum = candidateNext;
        }
      };

      const duration = to - from;
      let availableRanges = [];
      for (let i = 0; i < timeseriesRanges.length; i++) {
        const range = timeseriesRanges[i];
        if (range.loading) {
          continue;
        }
        if (range.to <= from || range.from >= to) {
          continue;
        }

        let inRangeFrom = Math.max(from, range.from);
        let inRangeTo = Math.min(to, range.to);
        if (inRangeTo <= inRangeFrom) {
          console.error("Not in range?!? Should be!", {
            range,
            from,
            to,
            maxFrom: inRangeFrom,
            minTo: inRangeTo,
          });
          debugger;
          throw new Error("Not in range?!? Should be!");
        }

        availableRanges.push([inRangeFrom, inRangeTo]);

        const firstIndex = sortedIndexBy(
          range.data,
          { timestamp: from },
          ({ timestamp }) => timestamp
        );
        const nextIndex = sortedIndexBy(
          range.data,
          { timestamp: to },
          ({ timestamp }) => timestamp
        );

        const previousIndex = firstIndex - 1;
        const lastIndex = nextIndex - 1;

        updatePreviousNextDatum(
          range.data[previousIndex] ?? range.previousDatum,
          range.data[nextIndex] ?? range.nextDatum
        );

        if (lastIndex < 0 || lastIndex < firstIndex) {
          continue;
        }

        data.push(range.data.slice(firstIndex, lastIndex + 1));
      }

      if (
        resolutionAccumulator &&
        resolutionAccumulator.fromTs < to &&
        to <= resolutionAccumulator.toTs
      ) {
        const accumulatedDatum = {
          timestamp: resolutionAccumulator.fromTs,
          value: resolutionAccumulator.value,
          coverage: resolutionAccumulator.coverage,
        };
        data.push(accumulatedDatum);
      }

      availableRanges = mergeIntervals(availableRanges);
      const coverage =
        availableRanges.reduce(
          (sum, [startTs, endTs]) => sum + endTs - startTs,
          0
        ) / duration;

      if (coverage > 1) {
        console.error("Coverage > 1?!? Shouldnt be!", { coverage });
        debugger;
        // throw new Error("Coverage > 1?!? Shouldnt be!");
      }

      data = orderBy(
        uniqBy(flatten(data), "timestamp"),
        ["timestamp"],
        ["asc"]
      );

      return {
        timeseriesId,
        coverage,
        nextDatum,
        previousDatum,
        data,
        availableRanges,
      };
    });

  return timeseriesRequests.map((timeseriesRequest) => {
    const { from, to, timeseriesIds } = timeseriesRequest;
    const timeseries = get(from, to, timeseriesIds);
    return { from, to, timeseries };
  });
};

const getFromLocalAsync = async (timeseriesRequests) =>
  getFromLocal(timeseriesRequests, false);

const getFromLocalSync = (timeseriesRequests) =>
  getFromLocal(timeseriesRequests, true);

let batchedTimeseriesRequest = {},
  timer;

const optimizeLoading = (timeseriesRequests) => {
  const groupedRequestsByFromTo = groupBy(
    timeseriesRequests,
    ({ from, to }) => `${from}-${to}`
  );
  timeseriesRequests = Object.entries(groupedRequestsByFromTo).map(
    ([_, group]) => {
      const { from, to } = group[0];
      const timeseriesIds = flatten(
        group.map(({ timeseriesIds }) => timeseriesIds)
      );
      return { from, to, timeseriesIds };
    }
  );
  return orderBy(timeseriesRequests, ["from", "to"], ["asc", "desc"]);
};

/**
 * @typedef {object} LoadParams
 * @property {string} rootEntityId
 * @property {array} timeseriesRequests
 */

const canceledBatches = {};
const batchesTimers = {};

const resolveCurrentRequests = (timeseriesRequests) => timeseriesRequests;

/**
 * @param {string} id
 * @param {LoadParams} originalRequests
 * @param {Function} callback
 */
const loadBatch = (
  id,
  originalRequests,
  callback,
  { loadInitially = true, loadFromServer = loadInitially } = {}
) => {
  const { rootEntityId, timeseriesRequests } = originalRequests;
  const batch =
    batchedTimeseriesRequest[rootEntityId] ??
    (batchedTimeseriesRequest[rootEntityId] = []);

  const myBatch = {
    id,
    doLoad: loadFromServer,
    originalRequests,
    timeseriesRequests: resolveCurrentRequests(timeseriesRequests),
    callback,
  };
  batch.push(myBatch);

  if (loadInitially) {
    getFromLocalAsync(myBatch.timeseriesRequests).then((results) => {
      if (canceledBatches[id]) {
        return;
      }
      callback(null, results, true);
    });
  }

  if (timer) {
    clearTimeout(timer);
  }
  timer = window.setTimeout(() => {
    Object.entries(batchedTimeseriesRequest).forEach(
      async ([rootEntityId, batch]) => {
        const timeseriesRequests = optimizeLoading(
          [].concat(
            ...batch
              .filter(({ id, doLoad }) => !canceledBatches[id] && doLoad)
              .map((b) => b.timeseriesRequests)
          )
        );

        const { success, didLoadFromServer, wasTrimmedFromServer, error } =
          timeseriesRequests.length
            ? await loadInternal(rootEntityId, timeseriesRequests)
                .then(({ didLoadFromServer, wasTrimmedFromServer }) => ({
                  success: true,
                  didLoadFromServer,
                  wasTrimmedFromServer,
                }))
                .catch((error) => ({ success: false, error }))
            : {
                success: true,
                didLoadFromServer: false,
                wasTrimmedFromServer: false,
              };

        batch.forEach(
          async ({
            id,
            doLoad,
            originalRequests,
            timeseriesRequests,
            callback,
          }) => {
            if (canceledBatches[id]) {
              return;
            }
            if (success) {
              if (doLoad && didLoadFromServer) {
                const localData = getFromLocalSync(timeseriesRequests);
                const data = localData.map((result, i) => {
                  const currentRequest = timeseriesRequests[i];
                  return {
                    ...currentRequest,
                    ...result,
                    wasTrimmedFromServer,
                  };
                });
                callback(null, data, false);
              } else {
                callback(null, null, false);
              }
            } else {
              callback(
                error || new Error("Could not load telemetries"),
                null,
                false
              );
              batchesTimers[id] = setTimeout(() => {
                loadBatch(id, originalRequests, callback, {
                  loadInitially: false,
                  loadFromServer: true,
                });
              }, ONE_MINUTE);
            }
          }
        );
      }
    );
    batchedTimeseriesRequest = {};
  }, 50);
};

/**
 * @param {LoadParams} originalRequests
 * @param {Function} callback
 */
const load = (
  originalRequests,
  callback,
  { loadInitially = true, loadFromServer = loadInitially }
) => {
  const id = uuidV4();
  loadBatch(id, originalRequests, callback, {
    once: false,
    loadInitially,
    loadFromServer,
  });
  return {
    cancel: () => {
      canceledBatches[id] = true;
      if (batchesTimers[id]) {
        clearTimeout(batchesTimers[id]);
        delete batchesTimers[id];
      }
    },
    reload: () => {
      // beware of multi loading
      loadBatch(id, originalRequests, callback, {
        loadInitially: false,
        loadFromServer: true,
      });
    },
  };
};

/**
 * @param {LoadParams} originalRequests
 */
export const loadFromLocalAsync = (originalRequests) => {
  return getFromLocalAsync(
    resolveCurrentRequests(originalRequests.timeseriesRequests)
  );
};

/**
 * @param {LoadParams} originalRequests
 */
export const loadFromLocalSync = (originalRequests) => {
  return getFromLocalSync(
    resolveCurrentRequests(originalRequests.timeseriesRequests)
  );
};

export default load;

export const clearCache = () => {
  Object.keys(timeseriesCache).forEach((key) => {
    delete timeseriesCache[key];
  });
  return db.cache.where("key").equals("timeseries").delete();
};

window.timeseriesLoader = {
  load,
  get timeseries() {
    return timeseriesCache;
  },
};
