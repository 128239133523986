import { flatten, isArray } from "lodash";

const getEntitiesConnections = (entities, connectionsChain) => {
  if (!isArray(entities)) {
    entities = [entities];
  }
  let currentEntities = entities;
  connectionsChain = connectionsChain.map((connectionType) => {
    currentEntities = flatten(
      currentEntities.map((entity) => entity.connections[connectionType] ?? [])
    );
    return currentEntities;
  });
  return flatten(connectionsChain);
};

window.getEntitiesConnections = getEntitiesConnections;

export default getEntitiesConnections;
