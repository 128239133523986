import classNames from "classnames";
import React from "react";

const Lux = ({ color = "#7a7a7a", className, iconScale = 1, icon = true }) => {
  const fill = color ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill={fill}
        d="M5.49,3.18c1.28,0,2.33,1.05,2.33,2.32,0,1.26-1.05,2.31-2.31,2.31-1.28,0-2.33-1.05-2.33-2.33,0-1.27,1.05-2.31,2.31-2.31Zm.02,3.89c.94-.02,1.63-.77,1.58-1.68-.05-.83-.81-1.53-1.58-1.44v3.12Z"
      />
      <path
        fill={fill}
        d="M1.55,1.93c.08-.1,.14-.24,.24-.28,.11-.04,.31-.03,.39,.04,.49,.46,.96,.94,1.43,1.42,.15,.15,.13,.34-.02,.49-.15,.15-.34,.15-.49,0-.48-.46-.94-.93-1.4-1.4-.06-.06-.08-.16-.14-.28Z"
      />
      <path
        fill={fill}
        d="M9.45,9.08c-.08,.1-.15,.23-.25,.27-.1,.04-.29,.04-.36-.03-.5-.47-.98-.94-1.44-1.45-.08-.08-.05-.34,.02-.46,.11-.18,.33-.18,.48-.04,.49,.46,.96,.94,1.43,1.42,.06,.06,.08,.16,.13,.28Z"
      />
      <path
        fill={fill}
        d="M9.47,1.94c-.09,.14-.13,.25-.21,.33-.42,.43-.85,.85-1.27,1.27-.17,.18-.37,.24-.56,.05-.19-.18-.15-.38,.03-.56,.44-.44,.87-.89,1.32-1.31,.09-.08,.28-.12,.41-.09,.11,.03,.18,.18,.29,.3Z"
      />
      <path
        fill={fill}
        d="M1.93,9.44c-.09-.07-.23-.12-.28-.22-.05-.11-.05-.31,.02-.39,.47-.5,.94-.98,1.45-1.44,.08-.08,.36-.06,.45,.03,.09,.08,.13,.37,.05,.45-.47,.51-.97,.99-1.46,1.47-.05,.05-.13,.06-.24,.1Z"
      />
      <path
        fill={fill}
        d="M5.14,1.46c0-.29,0-.59,0-.88,0-.25,.1-.43,.36-.43,.26,0,.37,.17,.37,.42v1.83c0,.25-.11,.42-.37,.42-.26,0-.36-.19-.36-.43,0-.3,0-.61,0-.92Z"
      />
      <path
        fill={fill}
        d="M1.46,5.87c-.31,0-.61,0-.92,0-.27,0-.44-.16-.42-.38,.02-.24,.18-.35,.41-.35,.62,0,1.24,0,1.86,0,.24,0,.4,.12,.4,.36,0,.24-.16,.36-.4,.37-.32,0-.63,0-.95,0Z"
      />
      <path
        fill={fill}
        d="M9.56,5.13c.3,0,.59,0,.89,0,.25,0,.43,.11,.43,.37,0,.26-.18,.37-.43,.36-.61,0-1.22,0-1.83,0-.24,0-.39-.11-.41-.35-.02-.22,.15-.37,.42-.38,.32,0,.63,0,.95,0Z"
      />
      <path
        fill={fill}
        d="M5.87,9.54c0,.31,0,.61,0,.92,0,.24-.11,.4-.36,.41-.26,0-.38-.17-.38-.42v-1.83c0-.25,.11-.43,.37-.42,.26,0,.36,.18,.36,.43,0,.31,0,.61,0,.92Z"
      />
    </svg>
  );
};

export default Lux;
