import classNames from "classnames";

type HandIconProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  color?: string;
};

const HandIcon = ({
  className,
  iconScale = 1,
  icon = true,
  color = "#3D5183",
}: HandIconProps) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
    >
      <path
        d="M14.0004 8.16675V4.00008C14.0004 3.55805 13.8248 3.13413 13.5123 2.82157C13.1997 2.50901 12.7758 2.33341 12.3338 2.33341C11.8917 2.33341 11.4678 2.50901 11.1552 2.82157C10.8427 3.13413 10.6671 3.55805 10.6671 4.00008M10.6671 7.33342V2.33341C10.6671 1.89139 10.4915 1.46746 10.1789 1.1549C9.86638 0.842343 9.44245 0.666748 9.00043 0.666748C8.5584 0.666748 8.13448 0.842343 7.82192 1.1549C7.50936 1.46746 7.33376 1.89139 7.33376 2.33341V4.00008M7.33376 4.00008V7.75008M7.33376 4.00008C7.33376 3.55805 7.15817 3.13413 6.8456 2.82157C6.53304 2.50901 6.10912 2.33341 5.66709 2.33341C5.22507 2.33341 4.80114 2.50901 4.48858 2.82157C4.17602 3.13413 4.00043 3.55805 4.00043 4.00008V10.6667M14.0004 5.66675C14.0004 5.22472 14.176 4.8008 14.4886 4.48824C14.8011 4.17568 15.2251 4.00008 15.6671 4.00008C16.1091 4.00008 16.533 4.17568 16.8456 4.48824C17.1581 4.8008 17.3337 5.22472 17.3337 5.66675V10.6667C17.3337 12.4349 16.6314 14.1306 15.3811 15.3808C14.1309 16.631 12.4352 17.3334 10.6671 17.3334H9.00041C6.66708 17.3334 5.25041 16.6167 4.00874 15.3834L1.00874 12.3834C0.722024 12.0659 0.568401 11.6502 0.579682 11.2225C0.590962 10.7948 0.766284 10.3879 1.06935 10.0859C1.37241 9.78389 1.78 9.61001 2.20772 9.60025C2.63545 9.59048 3.05055 9.74557 3.36708 10.0334L4.83374 11.5001"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HandIcon;
