import type { SVGAttributes } from "react";

type SustainabilityProps = SVGAttributes<SVGElement>;

const Sustainability = (props: SustainabilityProps) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.00702 16.664C4.42748 15.361 4.18893 13.9321 4.31381 12.5115C4.43869 11.0909 4.9229 9.72549 5.72088 8.54362C7.45383 5.97543 13.131 3.71129 22.75 1.75C22.4279 9.98335 21.4016 15.3841 19.6699 17.9523C18.4569 19.7505 16.5961 21.0107 14.4762 21.4697C12.3562 21.9288 10.1409 21.5513 8.29267 20.4159C8.96659 19.2295 9.89899 18.21 11.0207 17.4331L11.63 17.0101C12.154 16.6471 12.5123 16.0908 12.6261 15.4635C12.7399 14.8363 12.5999 14.1896 12.2369 13.6656C11.8739 13.1416 11.3175 12.7833 10.6903 12.6695C10.0631 12.5556 9.41635 12.6957 8.89236 13.0587L8.28306 13.4817C7.02306 14.3541 5.9161 15.429 5.00702 16.6628V16.664ZM5.90355 24.9082C5.90355 25.2269 5.77693 25.5326 5.55155 25.7579C5.32618 25.9833 5.0205 26.1099 4.70177 26.1099C4.38304 26.1099 4.07737 25.9833 3.85199 25.7579C3.62662 25.5326 3.5 25.2269 3.5 24.9082C3.5 20.7428 5.54301 16.8419 8.96807 14.4696L9.57737 14.0465C9.83936 13.865 10.1627 13.795 10.4763 13.8519C10.79 13.9088 11.0681 14.088 11.2496 14.35C11.4312 14.612 11.5012 14.9354 11.4442 15.249C11.3873 15.5626 11.2082 15.8407 10.9462 16.0223L10.3369 16.4453C8.96908 17.3927 7.85126 18.6575 7.07916 20.1314C6.30705 21.6052 5.90367 23.2443 5.90355 24.9082Z"
        fill="#F2F6FF"
      />
    </svg>
  );
};

export default Sustainability;
export type { SustainabilityProps };
