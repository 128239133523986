import { useMemo, useState } from "react";

const useAnnotations = () => {
  const [entitiesAnnotations, setEntitiesAnnotations] = useState(null);
  return [
    useMemo(() => {
      if (!entitiesAnnotations) {
        return null;
      }
      const { type, entities, Component } = entitiesAnnotations;
      const items = entities.map((entity) => {
        return {
          key: `${type}-${entity.id ?? entity}`,
          entityId: entity.id ?? entity,
          Component,
        };
      });
      return { type, items, entities, Component };
    }, [entitiesAnnotations]),
    setEntitiesAnnotations,
  ];
};

export default useAnnotations;
