import DateSelector from "../FullDateSelector";
import ActivityIndicatorDateSelectorButton from "../FullDateSelector/ActivityIndicatorDateSelectorButton/ActivityIndicatorDateSelectorButton";
import { DateSelectorProps } from "../FullDateSelector/DateSelector";

type PageHeaderDateSelectorProps = {
  view?: DateSelectorProps["view"];
};

const PageHeaderDateSelector = ({ view }: PageHeaderDateSelectorProps) => {
  return (
    <DateSelector
      renderDateSelectorButton={({ ...props }) => (
        <ActivityIndicatorDateSelectorButton {...props} />
      )}
      view={view}
    />
  );
};

export default PageHeaderDateSelector;
