import classNames from "classnames";
import React from "react";

const ExclamationTriangle = ({
  color = "#dc4f4f",
  fill = false,
  exclamationColor = fill ? "#ffffff" : color,
  className = "",
  iconScale = 1,
  icon = true,
}) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill={fill ? color : "none"}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.15px"
        d="M2.87,9.8h5.24c1.03,0,1.54,0,1.84-.22,.26-.19,.43-.48,.47-.8,.04-.37-.21-.82-.71-1.71L7.1,2.35c-.52-.94-.79-1.42-1.13-1.57-.3-.14-.65-.14-.95,0-.35,.16-.61,.63-1.13,1.57L1.27,7.07c-.5,.9-.75,1.35-.71,1.71,.04,.32,.21,.61,.47,.8,.3,.22,.81,.22,1.84,.22Z"
      ></path>
      <path
        fill="none"
        stroke={exclamationColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.15px"
        d="M5.5,4.06v2.3m0,1.72h0"
      ></path>
    </svg>
  );
};

export default ExclamationTriangle;
