import React from "react";
import classNames from "classnames";

const ActiveIcon = ({
  color = "#2DCCD3",
  className,
  iconScale = 1,
  icon = true,
}) => {
  color = color ?? "currentcolor";
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      fill="none"
      viewBox="0 0 11 11"
    >
      <path
        d="M8.39335 5.35995C8.39335 6.68995 7.29336 7.77995 5.9388 7.77995C4.58424 7.77995 3.47412 6.69995 3.47412 5.35995C3.47412 4.01995 4.57405 2.94995 5.9388 2.94995C7.30355 2.94995 8.39335 4.02995 8.39335 5.35995Z"
        fill={color}
      />
      <path
        d="M10.3796 5.36C10.3796 7.77 8.39356 9.72 5.93905 9.72C3.48454 9.72 1.49854 7.77 1.49854 5.36C1.49854 2.95 3.48454 1 5.93905 1C8.39356 1 10.3796 2.95 10.3796 5.36Z"
        stroke={color}
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default ActiveIcon;
