import classNames from "classnames";

type WindProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  color?: string;
  strokeWidth?: number;
};

const Wind = ({
  className,
  iconScale = 1,
  icon = true,
  color = "#2563EB",
  strokeWidth = 2,
}: WindProps) => (
  <svg
    className={classNames(className, { "para-icon": icon })}
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fontSize: `${iconScale * 100}%`,
    }}
  >
    <path
      d="M16.733 4.69974C17.0257 4.4179 17.3827 4.21165 17.773 4.09886C18.1634 3.98606 18.5754 3.97012 18.9733 4.05242C19.3712 4.13471 19.7431 4.31275 20.0567 4.57113C20.3703 4.82951 20.6162 5.16044 20.7731 5.53527C20.93 5.91009 20.9932 6.31751 20.9571 6.72225C20.9211 7.12698 20.787 7.51684 20.5664 7.85806C20.3457 8.19928 20.0452 8.48158 19.6909 8.68051C19.3366 8.87945 18.9392 8.98902 18.533 8.99974H1.03296M8.63296 1.5998C8.86464 1.36351 9.15133 1.18839 9.46734 1.09016C9.78335 0.991918 10.1188 0.97363 10.4436 1.03693C10.7684 1.10023 11.0725 1.24314 11.3285 1.45284C11.5844 1.66255 11.7844 1.9325 11.9104 2.2385C12.0364 2.5445 12.0845 2.87698 12.0504 3.20614C12.0163 3.53531 11.901 3.85086 11.715 4.12452C11.5289 4.39818 11.2778 4.6214 10.9843 4.77416C10.6907 4.92692 10.3639 5.00445 10.033 4.9998H1.03296M11.633 16.3997C11.8646 16.636 12.1513 16.8111 12.4673 16.9094C12.7833 17.0076 13.1188 17.0259 13.4436 16.9626C13.7684 16.8993 14.0725 16.7564 14.3285 16.5467C14.5844 16.337 14.7844 16.067 14.9104 15.761C15.0364 15.455 15.0845 15.1225 15.0504 14.7934C15.0163 14.4642 14.901 14.1487 14.715 13.875C14.5289 13.6013 14.2778 13.3781 13.9843 13.2254C13.6907 13.0726 13.3639 12.9951 13.033 12.9997H1.03296"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Wind;
