import type { SVGAttributes } from "react";

type DisplayIconProps = SVGAttributes<SVGElement>;

const DisplayIcon = (props: DisplayIconProps) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.75 3.14286C1.75 2.32403 2.35754 1.75 3 1.75H19C19.6425 1.75 20.25 2.32403 20.25 3.14286V13.8571C20.25 14.676 19.6425 15.25 19 15.25H11H3C2.35754 15.25 1.75 14.676 1.75 13.8571V3.14286ZM19 16.75H11.75V19.25H15C15.4142 19.25 15.75 19.5858 15.75 20C15.75 20.4142 15.4142 20.75 15 20.75H11H7C6.58579 20.75 6.25 20.4142 6.25 20C6.25 19.5858 6.58579 19.25 7 19.25H10.25V16.75H3C1.43332 16.75 0.25 15.4052 0.25 13.8571V3.14286C0.25 1.59475 1.43332 0.25 3 0.25H19C20.5667 0.25 21.75 1.59475 21.75 3.14286V13.8571C21.75 15.4052 20.5667 16.75 19 16.75ZM11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8L10.25 12C10.25 12.4142 10.5858 12.75 11 12.75C11.4142 12.75 11.75 12.4142 11.75 12L11.75 8ZM14 5.25C14.4142 5.25 14.75 5.58579 14.75 6L14.75 12C14.75 12.4142 14.4142 12.75 14 12.75C13.5858 12.75 13.25 12.4142 13.25 12L13.25 6C13.25 5.58579 13.5858 5.25 14 5.25ZM8.75 11C8.75 10.5858 8.41421 10.25 8 10.25C7.58579 10.25 7.25 10.5858 7.25 11V12C7.25 12.4142 7.58579 12.75 8 12.75C8.41421 12.75 8.75 12.4142 8.75 12V11Z"
        fill="white"
      />
    </svg>
  );
};

export default DisplayIcon;
export type { DisplayIconProps };
