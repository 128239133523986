import { useTranslate } from "modules/language";
import { useState } from "react";
import useEditableParamsMutate from "modules/building/hooks/useEditableParamsMutate";
import EditableParamsContainer from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/editableParams/EditableParamsContainer/EditableParamsContainer";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import { validateParamValues } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/utils";
import EditableParamWidget from "../../../../../editableParams/EditableParamWidget/EditableParamWidget";

const WasteAssumptions = () => {
  const t = useTranslate();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isHistoryEnabled, setIsHistoryEnabled] = useState(false);
  const { state, telemetries, Notifier } = useEditableParamsState();
  const { mutateAsync, isLoading } = useEditableParamsMutate();

  const handleEditSave = async () => {
    if (!validateParamValues(state, ["solidWasteEmissionsFactor"])) {
      return false;
    }

    const { solidWasteEmissionsFactor } = state;

    if (solidWasteEmissionsFactor.syncInitialValue()) {
      await mutateAsync([
        {
          timeseriesId: telemetries.solidWasteEmissionsFactor.timeseriesId,
          value: solidWasteEmissionsFactor.value,
        },
      ]);
    }

    return true;
  };

  return (
    <Notifier>
      <EditableParamsContainer
        title={t("label.ASSUMPTIONS")}
        isEditEnabled={isEditEnabled}
        setIsEditEnabled={setIsEditEnabled}
        isHistoryEnabled={isHistoryEnabled}
        setIsHistoryEnabled={setIsHistoryEnabled}
        onSave={handleEditSave}
      >
        <EditableParamWidget
          paramName="solidWasteEmissionsFactor"
          keyText={t("label.SOLID_WASTE_EMISSIONS_CONVERSION_FACTOR")}
          historyLabel={t("label.SOLID_WASTE_FACTOR_HISTORY")}
          isEditEnabled={isEditEnabled}
          isHistoryEnabled={isHistoryEnabled}
          isHistoryExpanded
          disabled={isLoading}
          maxValue={99.999}
          minValue={0.1}
        />
      </EditableParamsContainer>
    </Notifier>
  );
};

export default WasteAssumptions;
