import type { HTMLAttributes } from "react";
import s from "./HorizontalDivider.module.scss";
import classNames from "classnames";

type HorizontalDividerProps = HTMLAttributes<HTMLDivElement> & {
  marginTop?: "small" | "medium" | "large";
  marginLeft?: "small" | "medium" | "large";
  marginRight?: "small" | "medium" | "large";
  marginBottom?: "small" | "medium" | "large";
};

const HorizontalDivider = ({
  className,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
  ...props
}: HorizontalDividerProps) => {
  return (
    <hr
      className={classNames(
        s.horizontalDivider,
        className,
        s[`marginBottom--${marginBottom}`],
        s[`marginTop--${marginTop}`],
        s[`marginLeft--${marginLeft}`],
        s[`marginRight--${marginRight}`]
      )}
      {...props}
    />
  );
};

export default HorizontalDivider;
export type { HorizontalDividerProps };
