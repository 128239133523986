import getColorTweener from "modules/core/helpers/getColorTweener";
import colorNumberToHex from "./colorNumberToHex";
import { COLOR_GREEN, COLOR_ORANGE, COLOR_RED } from "./colors";
import type { Nullable } from "types/utils";

const performanceKPIColorTweener = getColorTweener(
  [
    { value: 0, color: COLOR_RED },
    { value: 0.7, color: COLOR_RED },
    { value: 0.7, color: COLOR_ORANGE },
    { value: 0.9, color: COLOR_ORANGE },
    { value: 0.9, color: COLOR_GREEN },
    { value: 1, color: COLOR_GREEN },
  ],
  null
);

export const performanceKPIColorTweenerAsHex = (value: Nullable<number>) =>
  colorNumberToHex(performanceKPIColorTweener(value));

export default performanceKPIColorTweener;
