import CollapsibleContainer from "atomic-components/molecules/CollapsibleContainer/CollapsibleContainer";
import BreakdownTitle from "../BreakdownTitle/BreakdownTitle";
import { useTranslate } from "modules/language";
import RefrigerantsInUse from "./RefrigerantsInUse/RefrigerantsInUse";
import DirectExpansionUnits from "./DirectExpansionUnits/DirectExpansionUnits";
import TotalEquivalentWarmingImpact from "./TotalEquivalentWarmingImpact/TotalEquivalentWarmingImpact";
import OzoneDepletionPotential from "./OzoneDepletionPotential/OzoneDepletionPotential";
import RefrigerantAssumptions from "./RefrigerantAssumptions/RefrigerantAssumptions";
import RefrigerantImpact from "./RefrigerantImpact/RefrigerantImpact";

type RefrigerantBreakdownProps = {
  scopes: string[];
};

const RefrigerantBreakdown = ({ scopes }: RefrigerantBreakdownProps) => {
  const t = useTranslate();
  return (
    <CollapsibleContainer
      titleFontWeight="normal"
      title={
        <BreakdownTitle
          label={t("label.REFRIGERANT_LEAKAGE")}
          scopes={scopes}
        />
      }
      hasExtraSpacing
    >
      <RefrigerantsInUse />
      <DirectExpansionUnits />
      <TotalEquivalentWarmingImpact />
      <OzoneDepletionPotential />
      <RefrigerantImpact />
      <RefrigerantAssumptions />
    </CollapsibleContainer>
  );
};

export default RefrigerantBreakdown;
