import withIconProps from "helpers/withIconProps";

const BuildingIcon = withIconProps((props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="building">
        <g id="icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5 6.50011H7.5V20.5001H17.5V6.50011ZM6 5.00011V22.0001H19V5.00011H6Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 0.939453L17.0303 5.46978L15.9697 6.53044L12.5 3.06077L9.03033 6.53044L7.96967 5.46978L12.5 0.939453Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 10.0001H9V8.50011H16V10.0001Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 13.0001H9V11.5001H16V13.0001Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 16.0001H9V14.5001H16V16.0001Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 19.0001H9V17.5001H16V19.0001Z"
          />
        </g>
      </g>
    </svg>
  );
});

export default BuildingIcon;
