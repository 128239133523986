import withIconProps from "helpers/withIconProps";
import type { SVGAttributes } from "react";

type Props = SVGAttributes<SVGElement> & {
  isHigh?: boolean;
};

const PM2Icon = withIconProps(
  ({ color, fill: passedFill, isHigh, ...props }: Props) => {
    const currentColor = color ?? "currentcolor";

    const fill =
      passedFill ||
      (isHigh ? "var(--color-red-pale)" : "var(--color-green-dark)");

    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 13.0002C9.5 12.1718 10.1716 11.5002 11 11.5002H11.01C11.8384 11.5002 12.51 12.1718 12.51 13.0002C12.51 13.8287 11.8384 14.5002 11.01 14.5002H11C10.1716 14.5002 9.5 13.8287 9.5 13.0002Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 19.0002C9.5 18.1718 10.1716 17.5002 11 17.5002H11.01C11.8384 17.5002 12.51 18.1718 12.51 19.0002C12.51 19.8287 11.8384 20.5002 11.01 20.5002H11C10.1716 20.5002 9.5 19.8287 9.5 19.0002Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 15.9512C14.5 15.1227 15.1716 14.4512 16 14.4512H16.01C16.8384 14.4512 17.51 15.1227 17.51 15.9512C17.51 16.7796 16.8384 17.4512 16.01 17.4512H16C15.1716 17.4512 14.5 16.7796 14.5 15.9512Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 9.95117C14.5 9.12274 15.1716 8.45117 16 8.45117H16.01C16.8384 8.45117 17.51 9.12274 17.51 9.95117C17.51 10.7796 16.8384 11.4512 16.01 11.4512H16C15.1716 11.4512 14.5 10.7796 14.5 9.95117Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 21.9512C14.5 21.1227 15.1716 20.4512 16 20.4512H16.01C16.8384 20.4512 17.51 21.1227 17.51 21.9512C17.51 22.7796 16.8384 23.4512 16.01 23.4512H16C15.1716 23.4512 14.5 22.7796 14.5 21.9512Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5 13.0002C19.5 12.1718 20.1716 11.5002 21 11.5002H21.01C21.8384 11.5002 22.51 12.1718 22.51 13.0002C22.51 13.8287 21.8384 14.5002 21.01 14.5002H21C20.1716 14.5002 19.5 13.8287 19.5 13.0002Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5 19.0002C19.5 18.1718 20.1716 17.5002 21 17.5002H21.01C21.8384 17.5002 22.51 18.1718 22.51 19.0002C22.51 19.8287 21.8384 20.5002 21.01 20.5002H21C20.1716 20.5002 19.5 19.8287 19.5 19.0002Z"
          fill={currentColor}
        />
      </svg>
    );
  }
);

export default PM2Icon;
