import { useQuery } from "react-query";
import {
  type GetEditableParamHistoryParams,
  getEditableParamHistory,
} from "../api/editableParams";
import { ReactQueryCacheKeys, queryClient } from "reactQuery";

const useEditableParamHistory = (
  args: Omit<GetEditableParamHistoryParams, "signal">,
  { enabled }: { enabled?: boolean } = {}
) => {
  const { timeseriesId, page, itemsPerPage } = args;

  const result = useQuery({
    queryKey: [
      ReactQueryCacheKeys.EditableParamHistory,
      timeseriesId,
      page,
      itemsPerPage,
    ],
    staleTime: Infinity,
    queryFn: ({ signal }) =>
      getEditableParamHistory({
        ...args,
        signal,
      }),
    enabled,
  });

  return {
    ...result,
    data: result.data || {
      items: [],
      itemsCount: 0,
      pagesCount: 0,
      itemsPerPage: 0,
    },
  };
};

const invalidateEditableParamHistoryQuery = () => {
  queryClient.invalidateQueries({
    predicate: (query) => {
      return query.queryKey[0] === ReactQueryCacheKeys.EditableParamHistory;
    },
  });
};

export default useEditableParamHistory;
export { invalidateEditableParamHistoryQuery };
