import React from "react";

import FormattedValue from "../FormattedValue/FormattedValue";

/**
 * @param {{value: any, currency?: import("types/utils").Nullable<import("types/unit").Unit | import("types/unit").Currency>, format?: number}} param0
 * @returns {React.ReactNode}
 */
const CurrencyValue = ({ currency, value, format }) => {
  const formatted = <FormattedValue value={value} format={format} />;

  if (!currency) {
    return formatted;
  }

  const { addSpace, display, isPrefix } = currency;

  return (
    <>
      {isPrefix ? (
        <>
          {display}
          {addSpace ? <>&nbsp;</> : null}
        </>
      ) : null}
      {formatted}
      {!isPrefix ? (
        <>
          {addSpace ? <>&nbsp;</> : null}
          {display}
        </>
      ) : null}
    </>
  );
};

export default CurrencyValue;
