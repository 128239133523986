import { beApiClient } from "./clients";
import { type Scenario } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ComplianceTrackingPanel/Certificates/LeedOmCertificate/utils";

type GetScenariosResult = {
  isLoading: boolean;
  isError: boolean;
  data: Scenario[];
};

const getScenarios = async (_: any, { signal }: { signal?: AbortSignal }) => {
  const resp = await beApiClient.request<GetScenariosResult>({
    url: `/api/compliance/scenarios`,
    method: "get",
    signal,
  });

  return resp.data;
};

const createScenario = async (payload: Omit<Scenario, "id">) => {
  const resp = await beApiClient.request({
    url: `/api/compliance/scenarios`,
    method: "post",
    data: payload,
  });

  return resp.data;
};

const updateScenario = async (payload: Scenario) => {
  const { id, ...data } = payload;

  const resp = await beApiClient.request({
    url: `/api/compliance/scenarios/${id}`,
    method: "put",
    data,
  });

  return resp.data;
};

const deleteScenario = async (id: string) => {
  const resp = await beApiClient.request({
    url: `/api/compliance/scenarios/${id}`,
    method: "delete",
  });

  return resp.data;
};

const activateScenario = async (id: string) => {
  const resp = await beApiClient.request({
    url: `/api/compliance/scenarios/activate/${id}`,
    method: "patch",
  });

  return resp.data;
};

const pinScenario = async (id: string) => {
  const resp = await beApiClient.request({
    url: `/api/compliance/scenarios/pin/${id}`,
    method: "patch",
  });

  return resp.data;
};

export {
  getScenarios,
  createScenario,
  updateScenario,
  deleteScenario,
  activateScenario,
  pinScenario,
};
