/**
 * check if any field contains null or undefined
 */
const isNotEmptyObj = <OBJ extends {}>(
  obj: OBJ
): obj is { [key in keyof OBJ]: NonNullable<OBJ[key]> } => {
  const nilFound = Object.values(obj).find(
    (field) => field === undefined || field === null
  );

  return !nilFound;
};

export default isNotEmptyObj;
