import { useEffect, useRef } from "react";
import { SingleEvent } from "modules/core/helpers/events";

/**
 * @typedef {Object} UseListenerOptions
 * @property {boolean} [enabled] defaults to true
 *
 * @typedef {Function} TriggerEvent
 * @typedef {Function} EventListener
 * @typedef {Function} RemoveEventListener
 *
 * @callback UseListener
 * @param {EventListener} listener
 * @param {UseListenerOptions} [options]
 * @returns {RemoveEventListener} a function to remove the listener
 */

/**
 * @param {SingleEvent}
 * @returns {UseListener}
 */
const getUseListener =
  (event) =>
  (listener, { enabled = true } = {}) => {
    const mutableRef = useRef({});
    mutableRef.current.listener = listener;
    mutableRef.current.unbind =
      mutableRef.current.unbind ??
      (() => {
        if (mutableRef.current.removeListener) {
          mutableRef.current.removeListener();
          delete mutableRef.current.removeListener;
        }
      });
    useEffect(() => {
      if (!enabled) {
        return;
      }
      mutableRef.current.removeListener = event.addListener((...args) => {
        if (mutableRef.current.listener) {
          return mutableRef.current.listener(...args);
        }
      });
      return mutableRef.current.unbind;
    }, [enabled]);
    return mutableRef.current.unbind;
  };

/**
 * @returns {[TriggerEvent,UseListener]}
 */
const useEvent = () => {
  const mutableRef = useRef({});

  /** @type {SingleEvent} */
  const event =
    mutableRef.current.event ?? (mutableRef.current.event = new SingleEvent());

  /** @type {UseListener} */
  const useListener =
    mutableRef.current.useListener ??
    (mutableRef.current.useListener = getUseListener(event));

  return [event.triggerEvent, useListener];
};

export default useEvent;
