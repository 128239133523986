import React, {
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";

import { offset, flip, shift, useFloating } from "@floating-ui/react-dom";
import classNames from "classnames";
import { isObject, keyBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslate } from "modules/language";
import { PopoverPortal } from "../InfoPopover";
import StrippedButton from "../Buttons/StrippedButton";

import s from "./DropDown.module.scss";

type DropDownButtonVariants = "default" | "transparent" | "outline";
type DropDownButtonSizes = "default" | "small";
type DropDownButtonArrowSizes = "default" | "big";

type DefaultDropDownButtonProps = {
  id?: string;
  menuElementId: string;
  menuShown: boolean;
  className?: string | null;
  disabled: boolean;
  buttonRef: (element: HTMLElement | null) => void;
  multi: boolean;
  selection: Record<string, any>;
  translateText: boolean;
  textField: string;
  inputTextField?: string;
  empty: React.ReactNode;
  addDownArrow: boolean;
  variant: DropDownButtonVariants;
  size: DropDownButtonSizes;
  arrowSize?: DropDownButtonArrowSizes;
  t: (text: string) => string;
};

const renderDropdownButtonDefault = ({
  id,
  menuElementId,
  menuShown,
  className,
  disabled,
  buttonRef,
  multi,
  selection,
  translateText,
  textField,
  inputTextField,
  empty,
  addDownArrow,
  variant,
  size,
  arrowSize = "default",
  t,
}: DefaultDropDownButtonProps) => {
  const variantToClassNameDict: { [x: string]: string } = {
    default: s.defaultButtonVariant,
    transparent: s.transparentButtonVariant,
    outline: s.outlineButtonVariant,
  };

  const variantClassName = variantToClassNameDict[variant];

  return (
    <button
      id={id}
      aria-haspopup="menu"
      aria-controls={menuElementId}
      aria-expanded={menuShown}
      className={classNames(
        s.dropdownButton,
        variantClassName,
        {
          [className as string]: className,
        },
        s[`size--${size}`]
      )}
      ref={buttonRef}
      disabled={disabled}
      onClick={(e) => e.stopPropagation()}
    >
      <span className={s.dropdownText}>
        {multi
          ? selection.length
            ? [].concat(
                ...selection.map(
                  (option: { [x: string]: string }, i: number) => [
                    i ? ", " : null,
                    translateText
                      ? t(option?.[inputTextField || textField])
                      : option?.[inputTextField || textField],
                  ]
                )
              )
            : empty ?? null
          : selection
          ? translateText
            ? t(selection[inputTextField || textField])
            : selection[inputTextField || textField]
          : empty ?? null}
      </span>
      {addDownArrow ? (
        <span className={s.dropdownKnob}>
          <FontAwesomeIcon
            icon={faAngleDown}
            size={arrowSize === "default" ? "1x" : "2x"}
          />
        </span>
      ) : null}
    </button>
  );
};

type DropDownProps = {
  inputId?: string;
  className?: string | null;
  options: any[];
  value?: any;
  onChange: (values: {
    type: string;
    option: { [x: string]: string };
    value: string;
    selectedOptions?: { [x: string]: string }[];
  }) => void;
  valueField?: string;
  textField?: string;
  inputTextField?: string;
  optionTextField?: string;
  translateText?: boolean;
  translateOptionText?: boolean;
  disabled?: boolean;
  multi?: boolean;
  hideOnSelect?: boolean;
  empty?: React.ReactNode;
  addCheckMarks?: boolean;
  addDownArrow?: boolean;
  variant?: DropDownButtonVariants;
  size?: DropDownButtonSizes;
  optionsClassName?: string | null;
  optionClassName?: string | null;
  arrowSize?: DropDownButtonArrowSizes;
  renderDropDownButton?: (props: {
    ref: (element: HTMLButtonElement) => void;
    disabled: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    areOptionsShown: boolean;
  }) => React.ReactNode;
};

const DropDown = ({
  inputId,
  className = null,
  options,
  value = null,
  onChange,
  valueField = "value",
  textField = "text",
  inputTextField,
  optionTextField = textField,
  translateText = false,
  translateOptionText = translateText,
  disabled = false,
  multi = false,
  hideOnSelect = !multi,
  empty = null,
  addCheckMarks = false,
  addDownArrow = true,
  variant = "default",
  size = "default",
  optionsClassName = null,
  optionClassName = null,
  arrowSize = "default",
  renderDropDownButton,
}: DropDownProps) => {
  const t = useTranslate();
  const elementId = useId();
  const menuElementId = `${elementId}-menu`;

  const mutable = useRef<{
    buttonElement: HTMLElement | null;
    optionsContainer: HTMLElement | null;
    showOptions: boolean;
    multi: boolean;
    hideOnSelect: boolean;
  }>({
    buttonElement: null,
    optionsContainer: null,
    showOptions: true,
    multi: false,
    hideOnSelect: false,
  }).current;

  mutable.multi = multi;
  mutable.hideOnSelect = hideOnSelect;
  const {
    x: left,
    y: top,
    refs,
    strategy,
  } = useFloating({
    placement: "bottom",
    middleware: [offset(5), flip(), shift({ padding: 5 })],
  });

  const [showOptions, setShowOptions] = useState(false);
  mutable.showOptions = showOptions;
  useEffect(() => {
    if (disabled) {
      return;
    }
    let listener: (e: MouseEvent) => void;

    const { buttonElement } = mutable;
    document.addEventListener(
      "click",
      (listener = (e) => {
        const { hideOnSelect, optionsContainer } = mutable;
        let elem = e.target as HTMLElement;
        for (let i = 0; i < 25 && elem; i++) {
          if (elem === buttonElement) {
            setShowOptions((show) => !show);
            return;
          }
          if (elem === optionsContainer) {
            if (hideOnSelect) {
              setShowOptions((show) => !show);
            }
            return;
          }
          elem = elem.parentNode as HTMLElement;
        }
        if (mutable.showOptions) {
          e.preventDefault();
          e.stopPropagation();
          setShowOptions(false);
        }
      })
    );
    buttonElement?.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
      buttonElement?.removeEventListener("click", listener);
    };
  }, [disabled]);

  const buttonRef = useCallback((element: HTMLElement | null) => {
    refs.setReference(element);
    mutable.buttonElement = element;
  }, []);
  const optionsContainerRef = useCallback((element: HTMLElement | null) => {
    refs.setFloating(element);
    mutable.optionsContainer = element;
  }, []);

  const [selection, selectionByValue] = useMemo(() => {
    let selection;
    if (!multi) {
      if (isObject(value)) {
        selection = value;
      } else {
        selection = options.find((option) => option[valueField] === value);
      }
      return [selection, selection ? { [selection[valueField]]: true } : {}];
    } else {
      const selection =
        value?.map((selectedOption: string | { [x: string]: string }) => {
          if (isObject(selectedOption)) {
            return selectedOption;
          }
          return options.find(
            (option) => option[valueField] === selectedOption
          );
        }) ?? [];
      return [selection, keyBy(selection, valueField)];
    }
  }, [options, multi, value, valueField]);

  const onSelect = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: { [x: string]: string }
  ) => {
    const value = option[valueField];
    const selected = selectionByValue[value];
    if (multi) {
      if (selected) {
        const selectedOptions = selection.filter(
          (option: { [x: string]: string }) => option[valueField] != value
        );
        onChange({
          type: "removeOption",
          option,
          value,
          selectedOptions,
        });
      } else {
        const selectedOptions = [...selection, option];
        onChange({
          type: "addOption",
          option,
          value,
          selectedOptions,
        });
      }
    } else {
      onChange({
        type: "selectOption",
        option,
        value,
      });
    }
  };

  useEffect(() => {
    if (disabled) {
      setShowOptions(false);
    }
  }, [disabled]);

  return (
    <>
      {renderDropDownButton
        ? renderDropDownButton({
            ref: buttonRef,
            disabled,
            onClick: (e) => e.stopPropagation(),
            areOptionsShown: showOptions,
          })
        : renderDropdownButtonDefault({
            id: inputId,
            menuElementId,
            menuShown: showOptions,
            className,
            disabled,
            buttonRef,
            multi,
            selection,
            translateText,
            textField,
            inputTextField,
            empty,
            addDownArrow,
            variant,
            size,
            t,
            arrowSize,
          })}

      {showOptions ? (
        <PopoverPortal>
          <ul
            id={menuElementId}
            role="menu"
            ref={optionsContainerRef}
            className={classNames([s.dropdownOptions], optionsClassName)}
            style={{
              position: strategy,
              top: top ?? "",
              left: left ?? "",
            }}
          >
            {options.map((option) => {
              const selected = selectionByValue[option[valueField]];
              return (
                <li>
                  <StrippedButton
                    className={classNames(s.dropdownOption, optionClassName, {
                      [s.dropdownOptionSelected]: selected,
                    })}
                    onClick={(e) => {
                      onSelect(e, option);
                    }}
                    disabled={option.disabled}
                  >
                    {addCheckMarks ? (
                      <>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color={selected ? "" : "transparent"}
                          fixedWidth
                          size="xs"
                        />
                        &nbsp;
                      </>
                    ) : null}
                    {translateOptionText
                      ? t(option[optionTextField])
                      : option[optionTextField]}
                  </StrippedButton>
                </li>
              );
            })}
          </ul>
        </PopoverPortal>
      ) : null}
    </>
  );
};

export default DropDown;
export type { DropDownProps };
