import withIconProps from "helpers/withIconProps";

const Clock = withIconProps((props) => {
  return (
    <svg viewBox="0 0 11 11" {...props}>
      <path
        fill="currentColor"
        d="M6.01,2.25c-.25,0-.45,.2-.45,.45v2.36c-.25,.15-.42,.42-.42,.74,0,.47,.39,.86,.87,.86,.31,0,.59-.17,.74-.41h1.98c.25,0,.45-.2,.45-.45s-.2-.45-.45-.45h-1.98c-.07-.12-.17-.22-.29-.29V2.7c0-.25-.2-.45-.45-.45h0Z"
      />
      <path
        fill="currentColor"
        d="M6.01,.55C3.26,.55,1.02,2.77,1.02,5.5c0,.03,0,.06,0,.09H0l1.45,2.14,.46-.67c.05-.05,.09-.1,.11-.17l.89-1.3H1.9s0-.06,0-.09C1.89,3.24,3.74,1.42,6.01,1.42s4.11,1.83,4.11,4.08-1.85,4.08-4.11,4.08c-1.02,0-2-.37-2.76-1.05-.18-.16-.45-.15-.62,.04-.17,.18-.15,.46,.04,.62,.92,.83,2.1,1.28,3.34,1.28,2.75,0,4.99-2.22,4.99-4.95S8.76,.55,6.01,.55Z"
      />
    </svg>
  );
});

export default Clock;
