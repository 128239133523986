import classNames from "classnames";

type MeasurementProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
};

const Measurement = ({
  className,
  iconScale = 1,
  icon = true,
}: MeasurementProps) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.375 11.25C4.92525 11.25 3.75 10.0747 3.75 8.625C3.75 7.67625 4.257 6.8175 5.0625 6.351L5.25 6.243V1.875C5.25 1.25325 5.75325 0.75 6.375 0.75C6.864 0.75 7.27575 1.06425 7.431 1.5H6.375V2.25H7.5V3H6.375V3.75H7.5V4.5H6.375V5.25H7.5V6.243L7.6875 6.351C8.493 6.8175 9 7.67625 9 8.625C9 10.0747 7.82475 11.25 6.375 11.25ZM8.25 5.8185V1.875C8.25 0.83925 7.41075 0 6.375 0C5.33925 0 4.5 0.83925 4.5 1.875V5.8185C3.57375 6.43725 3 7.482 3 8.625C3 10.4887 4.51125 12 6.375 12C8.23875 12 9.75 10.4887 9.75 8.625C9.75 7.482 9.17625 6.43725 8.25 5.8185Z"
        fill="#304375"
      />
      <path
        d="M6.375 6.75C7.41075 6.75 8.25 7.58925 8.25 8.625C8.25 9.66075 7.41075 10.5 6.375 10.5C5.33925 10.5 4.5 9.66075 4.5 8.625C4.5 7.58925 5.33925 6.75 6.375 6.75Z"
        fill="#304375"
      />
    </svg>
  );
};

export default Measurement;
