import React, { useEffect, useRef, useState } from "react";
import ErrorMessage from "../../../atomic-components/atoms/ErrorMessage";
import Loader from "../../../atomic-components/atoms/Loader";
import useRouteSegments from "hooks/useRouteSegments";
import { loadBuilding as loadBuildingFromServer } from "../api/building.api";
import postProcessBuildingData from "../helpers/postProcessBuildingData";
import { Settings } from "luxon";

const buildings = ((buildingsStr) => {
  if (!buildingsStr) {
    return {};
  }
  try {
    const buildings = JSON.parse(buildingsStr);
    return Object.fromEntries(
      Object.entries(buildings).map(([buildingUrl, rawBuildingData]) => {
        const buildingData = postProcessBuildingData(rawBuildingData);
        return [buildingUrl, { buildingData, stale: true }];
      })
    );
  } catch (error) {
    console.error("Error deserializing buildings", {
      serialized: buildingsStr,
      error,
    });
    return {};
  }
})(localStorage.getItem("buildings"));

const saveState = () => {
  const toSave = Object.fromEntries(
    Object.entries(buildings)
      .map(([buildingUrl, { buildingData }]) => {
        if (!buildingData) {
          return null;
        }
        return [buildingUrl, buildingData.toRaw()];
      })
      .filter(Boolean)
  );
  try {
    localStorage.setItem("buildings", JSON.stringify(toSave));
  } catch (error) {
    console.error("error saving buildings", error);
    localStorage.removeItem("buildings");
  }
};

const loadingListeners = new Map();
const listenToLoadedEvent = (buildingUrl, listener) => {
  let listeners = loadingListeners.get(buildingUrl);
  if (!listeners) {
    listeners = [];
    loadingListeners.set(buildingUrl, listeners);
  }
  listeners.push(listener);
  return () => {
    const i = listeners.findIndex((l) => l === listener);
    if (i >= 0) {
      listeners.splice(i, 1);
      if (!listeners.length) {
        loadingListeners.delete(buildingUrl);
      }
    }
  };
};
const fireLoadedEvent = (buildingUrl, error, data) => {
  const listeners = loadingListeners.get(buildingUrl);
  if (listeners) {
    for (let i = 0; i < listeners.length; i++) {
      listeners[i]({ buildingUrl, error, data });
    }
  }
};

const loadBuilding = (buildingUrl) => {
  return loadBuildingFromServer(buildingUrl).then((rawBuildingData) =>
    postProcessBuildingData(rawBuildingData)
  );
};

/**
 * @returns {{loading: boolean, error: object, buildingData: import('../helpers/postProcessBuildingData').BuildingData}}
 */
const useBuildingData = () => {
  const { buildingUrl } = useRouteSegments();

  const [state, setState] = useState(
    buildings[buildingUrl] ??
      (buildings[buildingUrl] = {
        loading: false,
        error: null,
        buildingData: null,
      })
  );

  const stateRef = useRef({});
  stateRef.current = state;

  useEffect(() => {
    if (!buildingUrl) {
      return;
    }
    const state = stateRef.current;

    let effectCleaned = false;
    if (state.loading || state.buildingData || state.error) {
      /* empty */
    } else if (buildings[buildingUrl].buildingData) {
      setState({ buildingData: buildings[buildingUrl].buildingData });
    } else if (buildings[buildingUrl].loading) {
      setState({ loading: true });
    } else {
      setState({ loading: true });
      Object.assign(buildings[buildingUrl], {
        loading: true,
        error: null,
        buildingData: null,
      });
      loadBuilding(buildingUrl)
        .then((buildingData) => {
          Object.assign(buildings[buildingUrl], {
            loading: false,
            error: null,
            buildingData,
          });
          fireLoadedEvent(buildingUrl, null, buildingData);
          saveState();
        })
        .catch((error) => {
          Object.assign(buildings[buildingUrl], {
            loading: false,
            error,
            buildingData: null,
          });
          fireLoadedEvent(buildingUrl, error, null);
        });
    }

    if (
      buildings[buildingUrl].stale &&
      !buildings[buildingUrl].loading &&
      !buildings[buildingUrl].reloading
    ) {
      buildings[buildingUrl].reloading = true;
      loadBuilding(buildingUrl)
        .then((buildingData) => {
          Object.assign(buildings[buildingUrl], {
            stale: false,
            reloading: false,
            error: null,
            buildingData,
          });
          fireLoadedEvent(buildingUrl, null, buildingData);
          saveState();
        })
        .catch(() => {
          setTimeout(() => {
            buildings[buildingUrl].reloading = false;
          }, 10000);
        });
    }

    const clearListener = listenToLoadedEvent(
      buildingUrl,
      ({ error, data }) => {
        if (!effectCleaned) {
          setState({ error, buildingData: data });
        }
      }
    );

    return () => {
      effectCleaned = true;
      clearListener();
    };
  }, [buildingUrl]);

  return state;
};

export default useBuildingData;

export const withBuildingData =
  (BaseComponent, LoaderComponent = Loader, ErrorComponent = ErrorMessage) =>
  (props) => {
    const { loading, error, buildingData } = useBuildingData();
    if (loading) {
      return <LoaderComponent />;
    }
    if (error) {
      return <ErrorComponent error={error} />;
    }
    if (!buildingData) {
      return <></>;
    }

    Settings.defaultZoneName = buildingData.timezone;

    return <BaseComponent {...props} buildingData={buildingData} />;
  };
