import withIconProps from "helpers/withIconProps";

const SendEmailIcon = withIconProps((props) => {
  return (
    <svg
      {...props}
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8029 3.83325L10.3279 8.58325C10.0706 8.74444 9.77316 8.82993 9.46956 8.82993C9.16597 8.82993 8.8685 8.74444 8.61123 8.58325L1.13623 3.83325M2.8029 1.33325H16.1362C17.0567 1.33325 17.8029 2.07944 17.8029 2.99992V12.9999C17.8029 13.9204 17.0567 14.6666 16.1362 14.6666H2.8029C1.88242 14.6666 1.13623 13.9204 1.13623 12.9999V2.99992C1.13623 2.07944 1.88242 1.33325 2.8029 1.33325Z"
        stroke="currentColor"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default SendEmailIcon;
