import classNames from "classnames";
import type { HTMLAttributes } from "react";

import s from "./KpiBoxes.module.scss";

type KpiBoxesProps = HTMLAttributes<HTMLDivElement>;

const KpiBoxes = ({ className, ...props }: KpiBoxesProps) => {
  return <div className={classNames(s.container, className)} {...props} />;
};

export default KpiBoxes;
export type { KpiBoxesProps };
