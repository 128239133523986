import classNames from "classnames";
import type { HTMLAttributes } from "react";

import s from "./KpiBox.module.scss";

type KpiBoxProps = HTMLAttributes<HTMLDivElement> & {
  borderColor: string;
  title: string;
  children: React.ReactNode;
};

const KpiBox = ({
  className,
  title,
  borderColor,
  children,
  ...props
}: KpiBoxProps) => {
  return (
    <div className={classNames(s.container, className)} {...props}>
      <span className={s.title}>{title}</span>
      <div className={s.value} style={{ borderColor }}>
        {children}
      </div>
    </div>
  );
};

export default KpiBox;
export type { KpiBoxProps };
