import classNames from "classnames";

type Props = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  fill?: string;
};

const Pin = ({
  className,
  iconScale = 1.2,
  icon = true,
  fill = "white",
}: Props) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4.5C0 2.01472 2.01472 0 4.5 0C6.98528 0 9 2.01472 9 4.5C9 6.81628 7.24998 8.72381 5 8.97254V14H4V8.97254C1.75002 8.72381 0 6.81628 0 4.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default Pin;
