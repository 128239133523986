import classNames from "classnames";
import React from "react";

const Info = ({
  className = "",
  color = "#7a7a7a",
  iconScale = 1,
  strokeWidth = "1.1px",
  icon = true,
  ...props
}) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
      {...props}
    >
      <path
        fill="none"
        stroke={color ?? "currentcolor"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M5.51,3.31h0m0,1.65v2.75m4.96-2.2c0,2.74-2.22,4.96-4.96,4.96S.55,8.25,.55,5.51,2.77,.55,5.51,.55s4.96,2.22,4.96,4.96Z"
      />
    </svg>
  );
};

export default Info;
