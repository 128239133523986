import type { SVGAttributes } from "react";

type EyeClosedIconProps = SVGAttributes<SVGElement>;

const EyeClosedIcon = (props: EyeClosedIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M5.31812 13.4705L6.09426 12.6943C6.70097 12.8947 7.33605 13 7.99964 13C9.9988 13 11.7392 12.0444 13.2249 10.413C13.7612 9.82417 14.2243 9.18846 14.6108 8.55183C14.7378 8.34262 14.8452 8.15184 14.926 8.00032C14.8456 7.8497 14.7374 7.65738 14.6108 7.44883C14.2244 6.81214 13.7612 6.17631 13.2249 5.58734L13.2136 5.57497L13.9214 4.8672C15.28 6.34323 15.9661 7.84262 15.9792 7.87257C15.9929 7.90298 16 7.93692 16 7.97106V8.03012C16 8.06406 15.9929 8.098 15.9792 8.12842C15.9531 8.18747 13.2919 14 7.99964 14C7.01464 14 6.12079 13.7986 5.31812 13.4705ZM2.04017 11.0916C0.707086 9.62938 0.033793 8.15808 0.0208 8.12842C0.00711111 8.098 0 8.06406 0 8.03012V7.97106C0 7.93692 0.00711111 7.90298 0.0208 7.87257C0.0469333 7.81331 2.70773 2 7.99964 2C8.96168 2 9.83678 2.1921 10.6249 2.50681L9.8454 3.28632C9.25673 3.09853 8.64152 3 7.99964 3C6.0007 3 4.26048 3.95573 2.77499 5.58729C2.23876 6.17625 1.77565 6.81207 1.38923 7.4488C1.26245 7.6577 1.15484 7.84889 1.07396 8.0006C1.15438 8.15119 1.26256 8.34347 1.38912 8.55197C1.76906 9.17793 2.2232 9.80307 2.7481 10.3836L2.04017 11.0916ZM8.36166 4.77006L7.25518 5.87655C6.61279 6.10253 6.10275 6.61258 5.87668 7.25505L4.77003 8.3617C4.7568 8.24277 4.75 8.12193 4.75 7.99953C4.75 6.20731 6.20707 4.75 7.99906 4.75C8.12161 4.75 8.2426 4.75681 8.36166 4.77006ZM11.2214 7.56721C11.2403 7.70867 11.25 7.85298 11.25 7.99953C11.25 9.79211 9.79201 11.25 7.99906 11.25C7.85266 11.25 7.70849 11.2403 7.56717 11.2214L8.62809 10.1605C9.36428 9.94574 9.94596 9.36404 10.1606 8.62799L11.2214 7.56721ZM1.85355 14.8536C1.65829 15.0488 1.34171 15.0488 1.14645 14.8536C0.951184 14.6583 0.951184 14.3417 1.14645 14.1464L14.1464 1.14645C14.3417 0.951184 14.6583 0.951184 14.8536 1.14645C15.0488 1.34171 15.0488 1.65829 14.8536 1.85355L1.85355 14.8536Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EyeClosedIcon;
export type { EyeClosedIconProps };
