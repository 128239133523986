import { useMemo } from "react";
import useBuildingData from "modules/building/hooks/useBuildingData";
import ColorizeEntities from "../ColorizeEntities";
import CenterCameraOnEntity from "../CenterCameraOnEntity";
import flattenWithChildren from "helpers/flattenWithChildren";

const FenceEntity = ({
  entityId,
  commonAncestorType = "floor",
  mappingName = "default",
  viewpointName = "default",
  centerName = "center",
  color = 0x000000,
  opacity = 0.85,
  priority = 5,
  lockCamera = false,
}) => {
  const { buildingData } = useBuildingData();
  const { entitiesById } = buildingData;
  const entity = entitiesById[entityId];

  const siblingsEntitiesIds = useMemo(() => {
    let commonAncestor = entity;
    while (commonAncestor && commonAncestor.type !== commonAncestorType) {
      commonAncestor = entitiesById[commonAncestor.parentEntityId];
    }
    if (!commonAncestor) {
      return [];
    }
    return flattenWithChildren(commonAncestor)
      .filter(({ id, type }) => type === entity.type && id !== entity.id)
      .map(({ id }) => id);
  }, [entity, entitiesById, commonAncestorType]);

  return (
    <>
      {siblingsEntitiesIds.length ? (
        <ColorizeEntities
          entitiesIds={siblingsEntitiesIds}
          mappingName={mappingName}
          color={color}
          opacity={opacity}
          priority={priority}
        />
      ) : null}
      <CenterCameraOnEntity
        entityId={entityId}
        mappingName={mappingName}
        viewpointName={viewpointName}
        centerName={centerName}
        lock={lockCamera}
      />
    </>
  );
};

export default FenceEntity;
