import type { SVGAttributes } from "react";

type DashboardProps = SVGAttributes<SVGElement>;

const Dashboard = (props: DashboardProps) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.1631 0.367169L23.3169 7.71368C23.5304 7.86784 23.7025 8.06249 23.8207 8.28342C23.9388 8.50435 24.0001 8.74599 24 8.99069V21.3565C24 21.7924 23.8055 22.2104 23.4593 22.5186C23.1131 22.8268 22.6435 23 22.1538 23H1.84615C1.35652 23 0.886947 22.8268 0.540726 22.5186C0.194505 22.2104 0 21.7924 0 21.3565V8.99069C0.000163439 8.74626 0.0615645 8.50495 0.179731 8.28432C0.297898 8.06369 0.469855 7.86931 0.683077 7.71532L10.8369 0.367169C11.1658 0.129636 11.5765 0 12 0C12.4235 0 12.8342 0.129636 13.1631 0.367169ZM22.1538 8.99069L12 1.64254L1.84615 8.98904V21.3565H22.1538V8.99069Z"
        fill="#F2F6FF"
      />
    </svg>
  );
};

export default Dashboard;
export type { DashboardProps };
