import { useTranslate } from "modules/language";
import type { ReactNode } from "react";
import ModalFooter from "atomic-components/molecules/Modal/ModalFooter";
import { useModalState } from "atomic-components/molecules/Modal";
import Button from "atomic-components/atoms/Buttons/Button";

type AxisOverrideModalBodyProps = {
  children: ReactNode;
  onConfirm: () => void;
};

const AxisOverrideModalBody = ({
  children,
  onConfirm,
}: AxisOverrideModalBodyProps) => {
  const t = useTranslate();
  const { hideModal } = useModalState();

  return (
    <>
      {children}
      <ModalFooter
        cancel={{
          text: t("action.CANCEL"),
        }}
      >
        <Button
          type="button"
          size="small"
          skin="primary"
          onClick={() => {
            onConfirm();
            hideModal();
          }}
        >
          {t("action.CONFIRM")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AxisOverrideModalBody;
export type { AxisOverrideModalBodyProps };
