import withIconProps from "helpers/withIconProps";

const ElectricityIcon = withIconProps((props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="zap">
        <path
          id="Vector"
          d="M10.8333 1.66663L2.5 11.6666H10L9.16667 18.3333L17.5 8.33329H10L10.8333 1.66663Z"
          stroke="currentcolor"
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
});

export default ElectricityIcon;
