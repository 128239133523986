import classNames from "classnames";
import type { TdHTMLAttributes, ThHTMLAttributes } from "react";

import s from "./TableCell.module.scss";

type TableCellProps<IS_HEAD extends boolean> = (IS_HEAD extends true
  ? ThHTMLAttributes<HTMLTableCellElement>
  : TdHTMLAttributes<HTMLTableCellElement>) & {
  isHead?: IS_HEAD;
  statusColor?: string;
};

const TableCell = <IS_HEAD extends boolean>({
  isHead,
  statusColor,
  className,
  style,
  ...props
}: TableCellProps<IS_HEAD>) => {
  const Tag = isHead ? "th" : "td";

  return (
    <Tag
      style={{
        ...(statusColor
          ? { borderInlineStart: `var(--space-small) solid ${statusColor}` }
          : {}),
        ...style,
      }}
      className={classNames(s.cell, className)}
      {...props}
    />
  );
};

export default TableCell;
export type { TableCellProps };
