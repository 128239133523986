import { useTranslate } from "modules/language";
import type { ComplianceScoreValues, SubComplianceScore } from "../../../types";
import EvaluationWizardInput from "../EvaluationWizardInput/EvaluationWizardInput";
import type { FormStore } from "helpers/createFormStoreHook";
import EvaluationWizardRadioInput from "../EvaluationWizardRadioInput/EvaluationWizardRadioInput";
import classNames from "classnames";

import s from "./EvaluationWizardInputWidget.module.scss";

type EvaluationWizardInputWidgetProps<COMPLIANCE_SCORE_KEY extends string> = {
  subCompliaceScoreContent: SubComplianceScore<COMPLIANCE_SCORE_KEY>;
  disabled: boolean;
  formStore: FormStore<ComplianceScoreValues<COMPLIANCE_SCORE_KEY>>;
  setIsParentExpanded: (enabled: boolean) => void;
  hasIntent?: boolean;
  isEditable?: boolean;
  value?: number;
  fallbackValues: Partial<ComplianceScoreValues<COMPLIANCE_SCORE_KEY>>;
};

const EvaluationWizardInputWidget = <COMPLIANCE_SCORE_KEY extends string>({
  subCompliaceScoreContent,
  disabled,
  formStore,
  setIsParentExpanded,
  hasIntent,
  isEditable,
  value: valueFromProps,
  fallbackValues,
}: EvaluationWizardInputWidgetProps<COMPLIANCE_SCORE_KEY>) => {
  const t = useTranslate();
  const {
    key,
    title,
    fieldType,
    fieldInputType,
    url,
    fieldStep,
    maximumRequired,
  } = subCompliaceScoreContent;
  const value = valueFromProps ?? formStore.values[key];
  const placeholderValue = fallbackValues[key];
  const error = formStore.errors[key];
  const fieldActions = formStore.fieldActions[key];
  const className = classNames({ [s.intent]: hasIntent });

  if (fieldInputType === "radio" || fieldType === "boolean") {
    return (
      <EvaluationWizardRadioInput
        key={key}
        name={key}
        disabled={disabled}
        title={t(title)}
        url={url}
        value={value}
        setValue={fieldActions.setValue}
        error={error}
        setError={fieldActions.setError}
        className={className}
      />
    );
  }

  return (
    <EvaluationWizardInput
      key={key}
      disabled={disabled}
      title={t(title)}
      url={url}
      setIsParentExpanded={setIsParentExpanded}
      resetValue={fieldActions.resetValue}
      value={value}
      placeholderValue={placeholderValue}
      setValue={fieldActions.setValue}
      error={error}
      setError={fieldActions.setError}
      units={[]}
      step={fieldStep || 1}
      minValue={0}
      maxValue={maximumRequired}
      className={className}
      isEditable={isEditable}
    />
  );
};

export default EvaluationWizardInputWidget;
export type { EvaluationWizardInputWidgetProps };
