import Key, { type KeyProps } from "atomic-components/atoms/Key";
import classNames from "classnames";
import type { HTMLAttributes, ReactNode } from "react";

import s from "./KeyValue.module.scss";

type KeyValueProps = HTMLAttributes<HTMLElement> & {
  keyTag?: KeyProps["tag"];
  keyText: ReactNode;
  keyTitle?: string;
  keyProps?: Omit<
    KeyProps,
    "tag" | "className" | "title" | "hasEllipsis" | "children"
  >;
  value: ReactNode;
  isVertical?: boolean;
  keyClassName?: string;
  valueClassName?: string;
  variant?: "default" | "inlinePlain" | "inline" | "header";
  size?: "default" | "regular" | "large" | "major";
  textColor?: "default" | "primary" | "gray" | "white";
  highlighted?: boolean;
  isLight?: boolean;
  /**
   * allow value element to grow
   * adds `flex: 1 1 auto; align-self: stretch` on value element
   */
  growValue?: boolean;
  gap?: "default" | "small" | "medium" | "large";
  marginBottom?: "small" | "medium" | "large";
  tooltipMessage?: string;
  keyWidth?: string;
  keyHasEllipsis?: boolean;
  tooltipVariant?: "default" | "primary" | "dark" | "card";
  tooltipIconColor?: string;
};

const KeyValue = ({
  keyTag,
  keyText,
  keyTitle,
  keyProps,
  value,
  isVertical,
  className,
  keyClassName,
  valueClassName,
  variant = "default",
  size = "default",
  textColor = "default",
  highlighted = false,
  isLight,
  growValue,
  gap,
  marginBottom,
  tooltipMessage,
  keyWidth = "auto",
  keyHasEllipsis = true,
  tooltipVariant = "primary",
  tooltipIconColor,
  ...props
}: KeyValueProps) => {
  const isInlinePlainVariant = variant === "inlinePlain";
  const isHeaderVariant = variant === "header";
  const hasEllipsis =
    !isInlinePlainVariant && !isHeaderVariant && keyHasEllipsis;

  return (
    <div
      className={classNames(
        s.container,
        s[variant],
        s[size],
        { [s.vertical]: isVertical },
        s[`gap--${gap}`],
        s[`marginBottom--${marginBottom}`],
        className
      )}
      {...props}
    >
      <Key
        tag={keyTag}
        style={{ width: keyWidth }}
        className={classNames(
          s.key,
          s[`${variant}-key`],
          s[`${size}-key`],
          s[`color--${textColor}`],
          keyClassName,
          {
            [s.mainData]: highlighted,
            [s.light]: isLight,
            [s.tooltipContainer]: !!tooltipMessage,
          }
        )}
        title={keyTitle}
        hasEllipsis={hasEllipsis}
        tooltipMessage={tooltipMessage}
        tooltipVariant={tooltipVariant}
        tooltipIconColor={tooltipIconColor}
        {...keyProps}
      >
        {keyText}
        {isInlinePlainVariant ? ": " : null}
      </Key>
      <div
        className={classNames(
          s.value,
          s[`${variant}-value`],
          s[`${size}-value`],
          s[`color--${textColor}`],
          valueClassName,
          {
            [s.mainData]: highlighted,
            [s.growValue]: growValue,
          }
        )}
      >
        {value}
      </div>
    </div>
  );
};

export default KeyValue;
export type { KeyValueProps };
