import isRealNumber from "helpers/isRealNumber";
import { useTravelAvgEmissionPerRoute } from "../useTravelAvgEmissionPerRoute";

/**
 * this mappers and related calculations are in frontend
 * because editable params calculations are currently done
 * in frontend
 */
const avgCO2elbsPerRouteToTransportationFunctionMapper: Record<number, number> =
  {
    0.0: 100.0,
    3.0: 93.1,
    7.0: 83.3,
    8.3: 79.0,
    10.0: 74.0,
    15.0: 62.4,
    16.0: 59.8,
    16.4: 57.6,
    18.4: 52.1,
    19.0: 50.3,
    19.6: 44.3,
    20.0: 41.9,
    21.0: 19.6,
    21.5: 5.2,
    22.0: 0.0,
    25.0: 0.0,
  };

const transportationFunctionToTransportationPerformanceMapper: Record<
  number,
  number
> = {
  40: 6,
  47: 7,
  54: 8,
  61: 9,
  68: 10,
  75: 11,
  83: 12,
  90: 13,
  97: 14,
};

const useTransportationPerformanceFallbackValue = () => {
  const { avgEmissionPerRoute, isLoading, error } =
    useTravelAvgEmissionPerRoute();

  let transportationPerformanceFallbackValue = null;

  if (isRealNumber(avgEmissionPerRoute)) {
    /**
     * Get transportationFunction from calculated avgCO2elbs
     */
    const avgCO2elbsKeys = Object.keys(
      avgCO2elbsPerRouteToTransportationFunctionMapper
    );
    avgCO2elbsKeys.sort((a, b) => +a - +b);

    let transportationFunction =
      avgCO2elbsPerRouteToTransportationFunctionMapper[+avgCO2elbsKeys[0]];

    for (const mapperKey of avgCO2elbsKeys) {
      if (avgEmissionPerRoute < +mapperKey) {
        break;
      }

      transportationFunction =
        avgCO2elbsPerRouteToTransportationFunctionMapper[+mapperKey];
    }

    /**
     * Get transportationPerformance from transportationFunction
     */
    const transportationFunctionKeys = Object.keys(
      transportationFunctionToTransportationPerformanceMapper
    );
    transportationFunctionKeys.sort((a, b) => +a - +b);

    transportationPerformanceFallbackValue =
      transportationFunctionToTransportationPerformanceMapper[
        +transportationFunctionKeys[0]
      ];

    for (const mapperKey of transportationFunctionKeys) {
      if (transportationFunction < +mapperKey) {
        break;
      }

      transportationPerformanceFallbackValue =
        transportationFunctionToTransportationPerformanceMapper[+mapperKey];
    }
  }

  return {
    isLoading,
    error,
    fallbackValue: transportationPerformanceFallbackValue,
  };
};

export { useTransportationPerformanceFallbackValue };
