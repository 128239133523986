import { useSearchParams } from "react-router-dom";
import useCustomDateFilterState from "./useCustomDateFilterState";

const DATE_SEPARATOR = "--";
const SEARCH_PARAM_KEY_DATE = "dateFilter";
const DEFAULT_DATE_FILTER = "week";

/**
 * @param {URLSearchParams} searchParams
 * @returns {RawDateFilter}
 */
const getAppRawDateFilter = (searchParams) => {
  const dateSearchParam =
    searchParams.get(SEARCH_PARAM_KEY_DATE) ||
    searchParams.get(SEARCH_PARAM_KEY_DATE.toLowerCase()) ||
    DEFAULT_DATE_FILTER;

  const dateSearchParamArray = dateSearchParam.split(DATE_SEPARATOR);

  return dateSearchParamArray.length === 1
    ? dateSearchParamArray[0]
    : dateSearchParamArray;
};

/**
 * @param {RawDateFilter} dateFilter
 * @param {import("react-router-dom").SetURLSearchParams} setSearchParams
 */
const appDateFilterChangeHandler = (dateFilter, setSearchParams) => {
  setSearchParams((prevSearchParams) => {
    prevSearchParams.set(
      SEARCH_PARAM_KEY_DATE,
      Array.isArray(dateFilter) ? dateFilter.join(DATE_SEPARATOR) : dateFilter
    );
    return prevSearchParams;
  });
};

const useFullDateFilterState = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useCustomDateFilterState(
    getAppRawDateFilter(searchParams),
    (dateFilter) => appDateFilterChangeHandler(dateFilter, setSearchParams)
  );
};

export default useFullDateFilterState;
export { getAppRawDateFilter, appDateFilterChangeHandler };
