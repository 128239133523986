import { Datum } from "./useLinkedTelemetries";
import { ConsumptionYear } from "./useTotalEmissionsByYear";

const useUtilsEmissions = () => {
  const combinedValues = (...valueArrays: number[][]) => {
    return valueArrays.reduce((acc, curr) => {
      return acc.map((value, index) => value + curr[index]);
    });
  };

  const combineMultipleArrays = (...arrays: number[][]) => {
    return arrays.reduce((acc, curr) => combinedValues(acc, curr));
  };

  const getValues = (arrayValues: Datum[]) => {
    return arrayValues.map((item) => item.value);
  };

  const yearFromTimeStamp = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.getFullYear();
  };

  const calculateFromAndTo = (year: number) => {
    const firstDay = new Date(year, 0, 1);
    const lastDay = new Date(year, 11, 31, 23, 59, 59);
    return {
      firstDay: firstDay.getTime(),
      lastDay: lastDay.getTime(),
    };
  };

  /* TODO--------Delete when the emissions bug is solved */

  const replaceZeroValues = (obj: ConsumptionYear) => {
    const newObj = { ...obj };

    newObj.consumption.forEach((item) => {
      if (item.scopes.some((value: number) => value === 0)) {
        //item.scopes = [80000, 50000, 20000]; this is an example the right values
        /* that any scope will be 0 is imposible, when the bug will be resolved, this function will be not neccessary. */
        item.scopes = [0, 0, 0];
      }
    });

    return newObj;
  };

  const modifiedTelemetriesWithDateString = (data: Datum[]) => {
    return data.map((item) => ({
      timestamp: new Date(item.timestamp).toLocaleString("en-EN", {
        month: "long",
        year: "numeric",
      }),
      originalTimestamp: item.timestamp,
      value: item.value,
    }));
  };

  const transformYearEmissions = (data: Datum[]) => {
    const years: { [key: number]: { [key: number]: number } } = {}; // Object to store maximum value per month per year

    // Iterate over the data to get the maximum value per month per year
    data.forEach((item) => {
      const date = new Date(item.timestamp);
      const year = date.getFullYear();
      const month = date.getMonth();

      if (!years[year]) {
        years[year] = {}; // Initialize year object if it does not exist
      }

      if (years[year][month] === undefined || item.value > years[year][month]) {
        // If the month for the year is not defined or the value is greater, the maximum value is updated
        years[year][month] = item.value;
      }
    });

    // Generate the array with the data by year and month
    const yearlyData: Datum[] = [];
    Object.keys(years).forEach((yearKey) => {
      const year = parseInt(yearKey, 10);
      for (let i = 0; i < 12; i++) {
        const timestamp = new Date(0);
        timestamp.setFullYear(year);
        timestamp.setMonth(i);
        const month = timestamp.getMonth();

        const value = years[year][month] || 0;
        const dataItem: Datum = { timestamp: timestamp.getTime(), value };
        yearlyData.push(dataItem);
      }
    });

    return yearlyData.sort((a, b) => a.timestamp - b.timestamp);
  };

  const transformYearResolutions = (data: Datum[]) => {
    const years: { [key: number]: { [key: number]: number } } = {}; // Object to store maximum value per month per year

    // Iterate over the data to get the maximum value per month per year
    let highestYear = 0;
    data.forEach((item) => {
      const date = new Date(item.timestamp);
      const year = date.getFullYear();
      const month = date.getMonth();

      if (year > highestYear) {
        highestYear = year; // Update highest year found
      }

      if (!years[year]) {
        years[year] = {}; // Initialize year object if it does not exist
      }

      if (years[year][month] === undefined || item.value > years[year][month]) {
        // If the month for the year is not defined or the value is greater, the maximum value is updated
        years[year][month] = item.value;
      }
    });

    // Generate the array with the data by year and month using the highest year found
    const yearlyData: Datum[] = [];
    for (let i = 0; i < 12; i++) {
      const timestamp = new Date(0);
      timestamp.setFullYear(highestYear);
      timestamp.setMonth(i);
      const month = timestamp.getMonth();

      const value = years[highestYear][month] || 0;
      const dataItem: Datum = { timestamp: timestamp.getTime(), value };
      yearlyData.push(dataItem);
    }

    return yearlyData.sort((a, b) => a.timestamp - b.timestamp);
  };

  /* END--------Delete when the emissions bug is solved */
  return {
    combineMultipleArrays,
    getValues,
    yearFromTimeStamp,
    calculateFromAndTo,
    replaceZeroValues,
    modifiedTelemetriesWithDateString,
    transformYearEmissions,
    transformYearResolutions,
  };
};

export { useUtilsEmissions };
