import {
  differenceBy,
  flattenDeep,
  maxBy,
  minBy,
  omit,
  orderBy,
  pull,
  some,
  uniqBy,
} from "lodash";
import hardcoded from "../../../helpers/hardcoded";
import { loadEntitiesInsights as loadEntitiesInsightsFromServer } from "../api/building.api";
import { v4 as uuidV4 } from "uuid";
import { EventsHub } from "modules/core/helpers/events";
import { mergeIntervals } from "helpers/computeIntervalsDuration";

const ONE_MINUTE = 60 * 1000;
const correctTo = (to) => {
  const min = Date.now() - 5 * ONE_MINUTE;
  const snapped = Math.min(to, min);
  return snapped - (snapped % (10 * ONE_MINUTE));
};

let insights = {};

const loadEntitiesInsights = (
  rootEntityId,
  requests,
  retries = 0,
  maxRetries = 2
) => {
  return loadEntitiesInsightsFromServer(rootEntityId, requests).catch((err) => {
    if (retries < maxRetries) {
      return loadEntitiesInsights(
        rootEntityId,
        requests,
        retries + 1,
        maxRetries
      );
    }
    throw err;
  });
};

const onLoadEvent = new EventsHub();

const processRangeLoaded = (loadedRange, loadedInsights) => {
  delete loadedRange.loading;
  loadedRange.data = loadedInsights;
};

/**
 * @param {string} rootEntityId
 * @param {array} insightsRequests
 * @param {number} [retries]
 * @param {number} [maxRetries]
 */
const loadInternal = async (
  rootEntityId,
  insightsRequests,
  retries = 0,
  maxRetries = 3
) => {
  const currentTick = correctTo(Date.now());
  const toLoadRanges = [];
  const loadingRanges = [];
  const requests = insightsRequests
    .map((insightsRequest) => {
      const dateFilter = {
        from: insightsRequest.from,
        to: insightsRequest.to,
      };
      const { from, to } = dateFilter;
      const entityId = insightsRequest.entityId;
      const entitiesIds = insightsRequest.entitiesIds ?? [entityId];

      const currentToLoadRanges = [];

      entitiesIds.forEach((entityId) => {
        const insightRanges = insights[entityId] ?? (insights[entityId] = []);

        let loadFrom,
          loadTo,
          index,
          toAwait = [];
        for (let r = 0; r < insightRanges.length; r++) {
          let range = insightRanges[r];
          if (
            range.from <= dateFilter.from &&
            dateFilter.from < range.to &&
            range.loadTick === currentTick
          ) {
            let maxTo = range.to;
            while (true) {
              if (range.loading) {
                toAwait.push(range);
              }
              if (
                insightRanges[r + 1]?.from <= maxTo &&
                insightRanges[r + 1]?.loadTick === currentTick
              ) {
                range = insightRanges[++r];
                maxTo = Math.max(maxTo, range.to);
              } else {
                break;
              }
            }
            loadFrom = maxTo;
            index = r;
          }
          if (
            range.from < dateFilter.to &&
            dateFilter.to <= range.to &&
            range.loadTick === currentTick
          ) {
            let minFrom = range.from;
            while (true) {
              if (range.loading) {
                toAwait.push(range);
              }
              if (
                insightRanges[r - 1]?.to >= minFrom &&
                insightRanges[r - 1]?.loadTick === currentTick
              ) {
                range = insightRanges[--r];
                minFrom = Math.min(minFrom, range.from);
              } else {
                break;
              }
            }
            loadTo = minFrom;
            break;
          }
        }
        loadFrom = loadFrom ?? from;
        loadTo = loadTo ?? to;
        loadingRanges.push(...toAwait);

        const newRange = {
          from: loadFrom,
          to: loadTo,
          entityId,
          loading: true,
          loadTick: currentTick,
        };

        currentToLoadRanges.push(newRange);

        if (loadFrom >= loadTo) {
          return null;
        }

        insightRanges.splice(index, 0, newRange);
      });

      sort();
      const realFrom = minBy(currentToLoadRanges, "from").from;
      const realTo = maxBy(currentToLoadRanges, "to").to;
      if (realFrom >= realTo) {
        return null;
      }

      currentToLoadRanges.forEach((r) => {
        r.from = realFrom;
        r.to = realTo;
      });
      if (entityId) {
        toLoadRanges.push(currentToLoadRanges[0]);
      } else {
        toLoadRanges.push(currentToLoadRanges);
      }
      return {
        from: realFrom,
        to: realTo,
        entityId,
        entitiesIds: entityId == null ? entitiesIds : undefined,
      };
    })
    .filter(Boolean);

  const results = requests.length
    ? await loadEntitiesInsights(
        rootEntityId,
        requests,
        retries,
        maxRetries
      ).catch((error) => {
        for (let i = 0; i < requests.length; i++) {
          const currentToLoadRanges = toLoadRanges[i];

          if (requests[i].entityId) {
            currentToLoadRanges.forEach((currentToLoadRange) => {
              pull(insights[requests[i].entityId], currentToLoadRange);
            });
          } else {
            requests[i].entitiesIds.forEach((entityId, i) => {
              currentToLoadRanges[i].forEach((currentToLoadRange) => {
                pull(insights[entityId], currentToLoadRange);
              });
            });
          }
          const flatToLoadRanges = flattenDeep(currentToLoadRanges);
          for (let j = 0; j < flatToLoadRanges.length; j++) {
            const currentToLoadRange = flatToLoadRanges[j];
            onLoadEvent.triggerEvent(currentToLoadRange, {
              range: currentToLoadRange,
              success: false,
              error,
            });
          }
        }
        throw error;
      })
    : [];

  const loadEventsToFire = [];
  for (let i = 0; i < results.length; i++) {
    const currentRequest = requests[i];

    const currentToLoadRanges = toLoadRanges[i];
    const result = results[i];

    if (currentRequest.entityId) {
      const loadedInsights = result.data;

      const currentToLoadRange = currentToLoadRanges;
      processRangeLoaded(currentToLoadRange, loadedInsights);

      loadEventsToFire.push({
        range: currentToLoadRange,
        insights: loadedInsights,
      });
    } else {
      currentRequest.entitiesIds.forEach((entityId, i) => {
        const loadedInsights = result.insights[i].data;

        const currentToLoadRange = currentToLoadRanges[i];

        processRangeLoaded(currentToLoadRange, loadedInsights);

        loadEventsToFire.push({
          range: currentToLoadRange,
          insights: loadedInsights,
        });
      });
    }
  }

  for (let i = 0; i < loadEventsToFire.length; i++) {
    const range = loadEventsToFire[i].range;
    onLoadEvent.triggerEvent(range, {
      range: loadEventsToFire[i].range,
      success: true,
    });
  }

  let success = true;
  if (loadingRanges.length) {
    await Promise.all(
      loadingRanges.map((range) => {
        if (range.loading) {
          return new Promise((resolve) => {
            onLoadEvent.once(range, (e) => {
              if (!e.success) {
                success = false;
              }
              resolve();
            });
          });
        }
      })
    );
  }

  if (!success) {
    if (retries < maxRetries) {
      return (
        (await loadInternal(
          rootEntityId,
          insightsRequests,
          retries + 1,
          maxRetries
        )) ||
        requests.length > 0 ||
        loadingRanges.length > 0
      );
    }
    throw new Error("Max retries");
  }

  sort();
  clean();

  return {
    didLoadFromServer: requests.length > 0 || loadingRanges.length > 0,
  };
};

const clean = () => {
  let didUpdate = false;
  sort();
  Object.entries(insights).forEach(([entityId, insightsRanges]) => {
    const newRanges = [];
    for (let i = 0; i < insightsRanges.length; i++) {
      if (
        insightsRanges[i].loading ||
        !insightsRanges[i + 1] ||
        insightsRanges[i + 1].loading
      ) {
        newRanges.push(insightsRanges[i]);
      } else if (insightsRanges[i].to < insightsRanges[i + 1].from) {
        newRanges.push(insightsRanges[i]);
      } else {
        const rangesToMerge = [insightsRanges[i], insightsRanges[i + 1]];
        i++;
        while (
          insightsRanges[i + 1] &&
          !insightsRanges[i + 1].loading &&
          insightsRanges[i].to >= insightsRanges[i + 1].from
        ) {
          i++;
          rangesToMerge.push(insightsRanges[i]);
        }
        newRanges.push(...mergeRanges(rangesToMerge));
      }
    }
    didUpdate = insightsRanges.length !== newRanges.length;
    insightsRanges.splice(0, insightsRanges.length, ...newRanges);
  });
  if (didUpdate) {
    sort();
    clean();
  }
};

const sort = () => {
  Object.entries(insights).forEach(([entityId, insightsRanges]) => {
    insightsRanges.splice(
      0,
      insightsRanges.length,
      ...orderBy(
        insightsRanges,
        ["loading", "from", "to"],
        ["asc", "asc", "desc"]
      )
    );
  });
};

const mergeRanges = (ranges) => {
  const rangesSortedByLoadTick = orderBy(ranges, ["loadTick"], ["asc"]);

  let freshestInsightsData =
    rangesSortedByLoadTick[rangesSortedByLoadTick.length - 1].data;
  // getting the freshest data
  for (let i = rangesSortedByLoadTick.length - 2; i >= 0; i--) {
    const data = differenceBy(
      rangesSortedByLoadTick[i].data,
      freshestInsightsData,
      "id"
    );
    freshestInsightsData = [...freshestInsightsData, ...data];
  }

  let mergedRanges = orderBy(ranges, ["from", "to"], ["asc", "desc"]);

  // normalize all ranges that have the same from. i.e. after this loop no two ranges will have the same from
  for (let i = 0; i < mergedRanges.length; i++) {
    const from = mergedRanges[i].from;
    let j = i;
    while (mergedRanges[++j]?.from === from);
    if (i + 1 === j) {
      continue;
    }
    let currentSlice = mergedRanges.slice(i, j);
    let nextSlice = [];
    while (currentSlice.length) {
      const maxLoadTickRange = maxBy(currentSlice, "loadTick");
      pull(currentSlice, maxLoadTickRange);
      nextSlice.push(maxLoadTickRange);
      currentSlice = currentSlice
        .map((range) => {
          if (range.to <= maxLoadTickRange.to) {
            return null;
          }
          return {
            ...range,
            from: maxLoadTickRange.to,
          };
        })
        .filter(Boolean);
    }
    mergedRanges = orderBy(
      [...mergedRanges.slice(0, i), ...nextSlice, ...mergedRanges.slice(j)],
      ["from", "to"],
      ["asc", "desc"]
    );
    i = -1;
  }

  // normalize overlapping ranges. i.e. after this loop no two ranges will be overlapping
  for (let i = 0; i < mergedRanges.length - 1; i++) {
    const currentRange = mergedRanges[i];
    const nextRange = mergedRanges[i + 1];
    const processedRanges = [];
    if (nextRange.from >= currentRange.to) {
      continue;
    }
    if (currentRange.to >= nextRange.to) {
      if (currentRange.loadTick >= nextRange.loadTick) {
        processedRanges.push(currentRange);
      } else {
        processedRanges.push(
          {
            ...currentRange,
            to: nextRange.from,
          },
          nextRange
        );
        if (currentRange.to > nextRange.to) {
          processedRanges.push({
            ...currentRange,
            from: nextRange.to,
          });
        }
      }
    } else {
      if (currentRange.loadTick > nextRange.loadTick) {
        processedRanges.push(currentRange, {
          ...nextRange,
          from: currentRange.to,
        });
      } else if (currentRange.loadTick < nextRange.loadTick) {
        processedRanges.push(
          {
            ...currentRange,
            to: nextRange.from,
          },
          nextRange
        );
      } else {
        processedRanges.push({
          from: currentRange.from,
          to: nextRange.to,
          loadTick: currentRange.loadTick,
        });
      }
    }
    mergedRanges = orderBy(
      [
        ...mergedRanges.slice(0, i),
        ...processedRanges,
        ...mergedRanges.slice(i + 2),
      ],
      ["from", "to"],
      ["asc", "desc"]
    );
    i = -1;
  }

  mergedRanges = orderBy(mergedRanges, ["from", "to"], ["asc", "desc"]);

  return mergedRanges.map((range) => ({
    ...range,
    data: orderBy(
      freshestInsightsData.filter((datum) => {
        const datumFrom = datum.startTs;
        const datumTo = datum.endTs ?? Number.POSITIVE_INFINITY;
        const { from, to } = range;
        if (datumFrom <= from && datumTo > from) {
          return true;
        }
        if (datumFrom < to) {
          return true;
        }
        return false;
      }),
      ["startTs", "endTs", "id"],
      ["asc", "desc", "asc"]
    ),
  }));
};

const getFromLocal = (entitiesInsightsRequest) => {
  const get = (from, to, entityId) => {
    const insightsRanges = insights?.[entityId];
    if (!insightsRanges) {
      return {
        entityId,
        from,
        to,
        coverage: 0,
        data: [],
        availableRanges: [],
      };
    }

    let data = [];

    const duration = to - from;
    let availableRanges = [];
    for (let i = 0; i < insightsRanges.length; i++) {
      const range = insightsRanges[i];
      if (range.loading) {
        continue;
      }
      if (range.to <= from || range.from >= to) {
        continue;
      }

      let inRangeFrom = Math.max(from, range.from);
      let inRangeTo = Math.min(to, range.to);
      if (inRangeTo <= inRangeFrom) {
        console.error("Not in range?!? Should be!", {
          range,
          from,
          to,
          maxFrom: inRangeFrom,
          minTo: inRangeTo,
        });
        debugger;
        throw new Error("Not in range?!? Should be!");
      }

      availableRanges.push([inRangeFrom, inRangeTo]);

      data.push(
        range.data.filter((insight) => {
          if (
            insight.startTs < to &&
            (!insight.endTs || insight.endTs > from)
          ) {
            return true;
          }
          return false;
        })
      );
    }

    availableRanges = mergeIntervals(availableRanges);
    const coverage =
      availableRanges.reduce(
        (sum, [startTs, endTs]) => sum + endTs - startTs,
        0
      ) / duration;

    if (coverage > 1) {
      console.error("Coverage > 1?!? Shouldnt be!", { coverage });
      debugger;
      // throw new Error("Coverage > 1?!? Shouldnt be!");
    }

    data = orderBy(
      uniqBy([].concat(...data), "id"),
      ["startTs", "endTs", "id"],
      ["asc", "desc", "asc"]
    );

    return {
      entityId,
      from,
      to,
      coverage,
      data,
      availableRanges,
    };
  };
  return entitiesInsightsRequest.map((entityInsightsRequest) => {
    const { from, to, entityId, entitiesIds } = entityInsightsRequest;
    if (entityId) {
      return get(from, to, entityId);
    }
    return entitiesIds.map((entityId) => get(from, to, entityId));
  });
};

let batchedEntitiesInsightsRequest = {},
  timer;

const optimizeLoading = (entitiesInsightsRequest) => {
  return orderBy(entitiesInsightsRequest, ["from", "to"], ["asc", "desc"]);
};

const getTimerDuration = () => {
  const now = Date.now();
  const min = now - 5 * ONE_MINUTE;
  const next = min - (min % (10 * ONE_MINUTE)) + 15 * ONE_MINUTE;
  return next - now;
};

/**
 * @typedef {object} LoadParams
 * @property {string} rootEntityId
 * @property {array} entitiesInsightsRequest
 */

const canceledBatches = {};
const batchesTimers = {};

const resolveCurrentRequests = (entitiesInsightsRequest) =>
  entitiesInsightsRequest.map((request) => {
    const originalToDate = request.to;
    const toDate = correctTo(originalToDate);
    return {
      ...request,
      trimmed: originalToDate - toDate > hardcoded(10 * ONE_MINUTE),
      to: toDate,
    };
  });

/**
 * @param {string} id
 * @param {LoadParams} originalRequests
 * @param {Function} callback
 */
const loadBatch = (id, originalRequests, callback, { once = false } = {}) => {
  const { rootEntityId, entitiesInsightsRequest } = originalRequests;
  const batch =
    batchedEntitiesInsightsRequest[rootEntityId] ??
    (batchedEntitiesInsightsRequest[rootEntityId] = []);

  const myBatch = {
    id,
    originalRequests,
    entitiesInsightsRequest: resolveCurrentRequests(entitiesInsightsRequest),
    callback,
  };
  batch.push(myBatch);

  if (timer) {
    clearTimeout(timer);
  }
  timer = window.setTimeout(() => {
    Object.entries(batchedEntitiesInsightsRequest).forEach(
      async ([rootEntityId, batch]) => {
        const entitiesInsightsRequest = optimizeLoading(
          [].concat(
            ...batch
              .filter(({ id }) => !canceledBatches[id])
              .map((b) => b.entitiesInsightsRequest)
          )
        );

        const { success, didLoadFromServer, error } =
          entitiesInsightsRequest.length
            ? await loadInternal(rootEntityId, entitiesInsightsRequest)
                .then(({ didLoadFromServer }) => ({
                  success: true,
                  didLoadFromServer,
                }))
                .catch((error) => ({ success: false, error }))
            : {
                success: true,
                didLoadFromServer: false,
              };

        batch.forEach(
          async ({
            id,
            originalRequests,
            entitiesInsightsRequest,
            callback,
          }) => {
            if (canceledBatches[id]) {
              return;
            }
            if (success) {
              if (didLoadFromServer) {
                const localData = getFromLocal(entitiesInsightsRequest);
                const data = localData.map((result, i) => {
                  const currentRequest = entitiesInsightsRequest[i];
                  if (currentRequest.entityId) {
                    return {
                      ...currentRequest,
                      ...result,
                    };
                  }
                  return result.map((result, i) => {
                    return {
                      ...omit(currentRequest, "entitiesIds"),
                      entityId: currentRequest.entitiesIds[i],
                      ...result,
                    };
                  });
                });
                callback(null, data, false);
              } else {
                callback(null, null, false);
              }
              if (once) {
                return;
              }
              const isTrimmed = some(
                entitiesInsightsRequest,
                ({ trimmed }) => trimmed
              );
              if (isTrimmed) {
                batchesTimers[id] = setTimeout(() => {
                  loadBatch(id, originalRequests, callback);
                }, getTimerDuration());
              }
            } else {
              callback(
                error || new Error("Could not load insights"),
                null,
                false
              );
              if (once) {
                return;
              }
              batchesTimers[id] = setTimeout(() => {
                loadBatch(id, originalRequests, callback);
              }, getTimerDuration());
            }
          }
        );
      }
    );
    batchedEntitiesInsightsRequest = {};
  }, 50);
};

/**
 * @param {LoadParams} originalRequests
 * @param {Function} callback
 */
const load = (originalRequests, callback, { loadInitially = true } = {}) => {
  const id = uuidV4();
  loadBatch(id, originalRequests, callback, { once: false, loadInitially });
  return {
    cancel: () => {
      canceledBatches[id] = true;
      if (batchesTimers[id]) {
        clearTimeout(batchesTimers[id]);
        delete batchesTimers[id];
      }
    },
    reload: () => {
      // beware of multi loading
      loadBatch(id, originalRequests, callback, true);
    },
  };
};

/**
 * @param {LoadParams} originalRequests
 */
export const loadFromLocalSync = (originalRequests) => {
  return getFromLocal(
    resolveCurrentRequests(originalRequests.entitiesInsightsRequest)
  );
};

export default load;

export const clearCache = () => {
  Object.keys(insights).forEach((key) => {
    delete insights[key];
  });
};

window.insightsLoader = {
  load,
  get insights() {
    return insights;
  },
};
