import classNames from "classnames";
import type { HTMLAttributes } from "react";

import s from "./TableRow.module.scss";

type TableRowProps = HTMLAttributes<HTMLTableRowElement> & {
  skin?: "default" | "primary" | "blue" | "white" | "middleBlue" | "lightBlue";
  isClickable?: boolean;
};

const TableRow = ({
  skin = "default",
  isClickable,
  className,
  ...props
}: TableRowProps) => {
  return (
    <tr
      className={classNames(s[skin], { [s.clickable]: isClickable }, className)}
      {...props}
    />
  );
};

export default TableRow;
export type { TableRowProps };
