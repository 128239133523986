const mergeColorDimention = (color1, color2, ratio) => {
  return Math.floor(color1 * (1 - ratio) + color2 * ratio);
};
const mergeColors = (color1, color2, ratio) => {
  return (
    (mergeColorDimention(color1 & 0xff0000, color2 & 0xff0000, ratio) &
      0xff0000) +
    (mergeColorDimention(color1 & 0x00ff00, color2 & 0x00ff00, ratio) &
      0x00ff00) +
    (mergeColorDimention(color1 & 0x0000ff, color2 & 0x0000ff, ratio) &
      0x0000ff)
  );
};

export default mergeColors;
