import classNames from "classnames";

type ClockCloseProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  color?: string;
  strokeWidth?: number;
};

const ClockClose = ({
  className,
  iconScale = 1,
  icon = true,
  color = "#1F2937",
  strokeWidth = 0.763636,
}: ClockCloseProps) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
    >
      <path
        d="M9.99967 4.50016V10.0002L13.6663 11.8335M19.1663 10.0002C19.1663 15.0628 15.0623 19.1668 9.99967 19.1668C4.93706 19.1668 0.833008 15.0628 0.833008 10.0002C0.833008 4.93755 4.93706 0.833496 9.99967 0.833496C15.0623 0.833496 19.1663 4.93755 19.1663 10.0002Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockClose;
