import { useTranslate } from "modules/language";
import { Fragment, useEffect, useState } from "react";
import type { ComplianceScore, ComplianceScoreValues } from "../../../types";
import type { FormStore } from "helpers/createFormStoreHook";
import { AccordionGroupElement } from "atomic-components/atoms/Accordion";
import EvaluationWizardInputWidget from "../EvaluationWizardInputWidget/EvaluationWizardInputWidget";

import s from "./EvaluationWizardGroupWidget.module.scss";

type EvaluationWizardGroupWidgetProps<COMPLIANCE_SCORE_KEY extends string> = {
  compliaceScoreContent: ComplianceScore<COMPLIANCE_SCORE_KEY>;
  disabled: boolean;
  formStore: FormStore<ComplianceScoreValues<COMPLIANCE_SCORE_KEY>>;
  fallbackValues: Partial<ComplianceScoreValues<COMPLIANCE_SCORE_KEY>>;
  isFirstTime: boolean;
};

const EvaluationWizardGroupWidget = <COMPLIANCE_SCORE_KEY extends string>({
  compliaceScoreContent,
  disabled,
  formStore,
  fallbackValues,
  isFirstTime,
}: EvaluationWizardGroupWidgetProps<COMPLIANCE_SCORE_KEY>) => {
  const t = useTranslate();
  const [isExpandedFromChild, setIsExpandedFromChild] = useState(false);
  const [isExpanded, setIsExpanded] = useState(isFirstTime);
  const { children = [] } = compliaceScoreContent;

  useEffect(() => {
    const childHasError = !!children.find((child) => {
      const subChildWithError = child.children
        ? child.children.find((subChild) => {
            return !!formStore.errors[subChild.key];
          })
        : undefined;

      if (subChildWithError) {
        return !!subChildWithError;
      }

      return !!formStore.errors[child.key];
    });

    if (childHasError) {
      setIsExpanded(true);
    } else {
      setIsExpanded(isExpanded);
    }
  }, [children, formStore.errors, isExpanded]);

  return (
    <AccordionGroupElement
      text={t(compliaceScoreContent.title)}
      variant="condensed"
      isExpanded={isExpanded}
      className={s.accordeonTitle}
      setIsExpanded={(value) => {
        if (isExpandedFromChild) {
          return;
        }
        setIsExpanded(value);
      }}
    >
      <div className={s.children}>
        {children.map((child) => {
          const hasChildren = !!child.children;
          const value = hasChildren
            ? child.children?.reduce((curr, { key }) => {
                return curr + (formStore.values[key] || 0);
              }, 0)
            : undefined;

          return (
            <Fragment key={child.key}>
              <EvaluationWizardInputWidget
                subCompliaceScoreContent={child}
                disabled={disabled}
                formStore={formStore}
                fallbackValues={fallbackValues}
                setIsParentExpanded={setIsExpandedFromChild}
                isEditable={!hasChildren}
                value={value}
              />
              {child.children?.map((subChild) => {
                return (
                  <EvaluationWizardInputWidget
                    key={subChild.key}
                    subCompliaceScoreContent={subChild}
                    disabled={disabled}
                    formStore={formStore}
                    fallbackValues={fallbackValues}
                    setIsParentExpanded={setIsExpandedFromChild}
                    hasIntent
                  />
                );
              })}
            </Fragment>
          );
        })}
      </div>
    </AccordionGroupElement>
  );
};

export default EvaluationWizardGroupWidget;
export type { EvaluationWizardGroupWidgetProps };
