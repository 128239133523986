import { useEffect, useState } from "react";
import Modal from "atomic-components/molecules/Modal/Modal";
import ModalHeader from "atomic-components/molecules/Modal/ModalHeader";
import ModalFooter from "atomic-components/molecules/Modal/ModalFooter";
import Button from "atomic-components/atoms/Buttons/Button";
import { useTranslate } from "../../../language";
import {
  hideStayLoggedIn,
  refreshSession,
  useAuthenticationState,
} from "../../state/authentication.state";
import {
  hideLoader,
  showLoader,
} from "../../../global-loader/state/global-loader.state";
import { addToast } from "../../../toast/state/toast.state";
import { padStart } from "lodash";

import "./StayLoggedInModal.css";

const StayLoggedInModal = () => {
  const t = useTranslate();
  const { expires } = useAuthenticationState();
  const [stayingLoggedIn, setStayingLoggedIn] = useState(false);
  const cancel = () => {
    hideStayLoggedIn();
  };
  const stayLoggedIn = () => {
    if (stayingLoggedIn) {
      return;
    }
    setStayingLoggedIn(true);
    showLoader();
    refreshSession()
      .catch((err) => {
        hideStayLoggedIn();
        addToast({
          messageKey: "error.COULD_NOT_STAY_LOGGEDIN",
          variant: "danger",
        });
        console.error(err);
      })
      .finally(() => {
        hideLoader();
        setStayingLoggedIn(false);
      });
  };

  const [duration, setDuration] = useState(null);

  useEffect(() => {
    if (!expires || expires < Date.now()) {
      return;
    }

    const t = setTimeout(() => {
      const remaining = Math.round((expires - Date.now()) / 1000);
      if (remaining <= 0) {
        clearTimeout(t);
        setDuration(null);
        return;
      }
      const mins = Math.floor(remaining / 60);
      const secs = remaining - mins * 60;
      setDuration(`${mins}:${padStart(`${secs}`, 2, "0")}`);
    }, 1000);

    return () => {
      clearTimeout(t);
    };
  }, [expires, duration]);

  if (!expires) {
    return null;
  }

  return (
    <Modal>
      <ModalHeader
        className={"stay-logged-in-modal"}
        cancel={{
          text: t("action.CANCEL"),
        }}
      >
        {t("title.STAY_LOGGED_IN")}
      </ModalHeader>
      <div>{t("message.STAY_LOGGED_IN")}</div>
      <ModalFooter>
        <Button
          type="submit"
          skin="primary"
          size="default"
          onClick={stayLoggedIn}
          disabled={stayingLoggedIn}
        >
          {t("action.STAY_LOGGED_IN")} <b>({duration})</b>
        </Button>
        <Button variant="outline" onClick={cancel} disabled={stayingLoggedIn}>
          {t("action.CANCEL")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StayLoggedInModal;
