import Section from "atomic-components/atoms/Section";
import SectionTitle from "atomic-components/atoms/SectionTitle";
import { useTranslate } from "modules/language";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { Info } from "svg-icons";
import KpiBoxes from "atomic-components/atoms/KpiBoxes";
import KpiBox from "atomic-components/atoms/KpiBox";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import UnitValue from "atomic-components/atoms/UnitValue";
import useBuildingData from "modules/building/hooks/useBuildingData";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";
import getPerformanceKPI from "helpers/getPerformanceKPI";
import { performanceKPIColorTweenerAsHex } from "helpers/performanceKPIColorTweener";
import CommonElectricityBreakdownTable from "./CommonElectricityBreakdownTable/CommonElectricityBreakdownTable";
import FormattedValue from "atomic-components/atoms/FormattedValue";
import { useMergedNotifier } from "helpers/getNotifier";

import s from "./CommonElectricityBreakdown.module.scss";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";

const commonUtilities = [
  {
    titleKey: "label.ELEVATORS",
    telemetriesNames: {
      consumption: "elevatorsElectricity",
      target: "elevatorsElectricityTarget",
    },
  },
  {
    titleKey: "label.PUMPS",
    telemetriesNames: {
      consumption: "pumpsElectricity",
      target: "pumpsElectricityTarget",
    },
  },
  {
    titleKey: "label.WATER_FEATURE",
    telemetriesNames: {
      consumption: "waterFeatureElectricity",
      target: "waterFeatureElectricityTarget",
    },
  },
  {
    titleKey: "label.OUTDOOR_LIGHTING",
    telemetriesNames: {
      consumption: "outsideLightingElectricity",
      target: "outsideLightingElectricityTarget",
    },
  },
];

const CommonElectricityBreakdown = () => {
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);

  const t = useTranslate();

  const {
    state: { electricityEmissionIntensity },
    Notifier: EditableParamsNotifier,
  } = useEditableParamsState();

  const { resolutions, Notifier: telemetriesNotifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        resolutionKey: "1month",
        telemetries: [
          {
            telemetryName: "elevatorsElectricity",
          },
          {
            telemetryName: "pumpsElectricity",
          },
          {
            telemetryName: "waterFeatureElectricity",
          },
          {
            telemetryName: "outsideLightingElectricity",
          },
          {
            telemetryName: "elevatorsElectricityTarget",
          },
          {
            telemetryName: "pumpsElectricityTarget",
          },
          {
            telemetryName: "waterFeatureElectricityTarget",
          },
          {
            telemetryName: "outsideLightingElectricityTarget",
          },
        ],
      },
    ],
    [selectedEntity.id]
  );
  const numOfDays =
    resolutions["1month"][selectedEntity.id].elevatorsElectricity.days;
  const commonUtilitiesData = commonUtilities.map(
    ({ titleKey, telemetriesNames }) => {
      const consumption =
        resolutions["1month"][selectedEntity.id][telemetriesNames.consumption]
          ?.data[0]?.value *
          numOfDays *
          24 ?? NaN;
      const emissions = consumption * (electricityEmissionIntensity.value || 0);
      const target =
        resolutions["1month"][selectedEntity.id][telemetriesNames.target]
          ?.data[0]?.value ?? NaN;
      const targetEmissions =
        target * (electricityEmissionIntensity.value || 0);
      const perfKPI = getPerformanceKPI(targetEmissions, emissions);
      const kpiColor = performanceKPIColorTweenerAsHex(perfKPI);

      return {
        titleKey,
        emissions,
        kpiColor,
      };
    }
  );

  const totalEmissions = commonUtilitiesData.reduce(
    (acc, { emissions }) => acc + (emissions || 0),
    0
  );

  const Notifier = useMergedNotifier(
    EditableParamsNotifier,
    telemetriesNotifier
  );

  return (
    <Notifier>
      <Section>
        <div className={s.titleContainer}>
          <SectionTitle>
            {t("label.PRESUMED_COMMON_ELECTRICAL_UTILITIES_EMISSIONS")}
          </SectionTitle>
          <InfoPopover
            message={t("tooltip.PRESUMED_EMISSIONS")}
            variant="primary"
            placement="top"
          >
            <StrippedButton>
              <Info iconScale={1.2} color="var(--color-primary)" />
            </StrippedButton>
          </InfoPopover>
        </div>

        <KpiBoxes>
          {commonUtilitiesData.map(({ titleKey, emissions, kpiColor }) => {
            return (
              <KpiBox borderColor={kpiColor} title={t(titleKey)}>
                <FormattedValue value={emissions} />
              </KpiBox>
            );
          })}
        </KpiBoxes>

        <WidgetContainer>
          <KeyValue
            keyText={t("label.TOTAL_EMISSIONS")}
            value={
              <UnitValue value={totalEmissions} unit={unitsByName.kgco2e} />
            }
            isLight
          />
        </WidgetContainer>
        <CommonElectricityBreakdownTable
          commonUtilitiesData={commonUtilitiesData}
        />
      </Section>
    </Notifier>
  );
};

export default CommonElectricityBreakdown;
