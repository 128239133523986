import React, { useRef, useState } from "react";
import { isArray } from "lodash";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { flip, offset, shift, useFloating } from "@floating-ui/react-dom";
import { createPortal } from "react-dom";
import Close from "svg-icons/Close";

import "./InfoPopover.css";
import { useIsRTL } from "modules/language";

const Spaces = ({ count }) => {
  if (!count) {
    return null;
  }
  if (count === true) {
    return <>&nbsp;</>;
  }
  const ret = [];
  for (let i = 0; i < count; i++) {
    ret.push(<React.Fragment key={i}>&nbsp;</React.Fragment>);
  }
  return ret;
};

const popoverContainer = document.createElement("div");
popoverContainer.className = "popovers-container";
document.body.appendChild(popoverContainer);

export const PopoverPortal = ({ children }) =>
  createPortal(children, popoverContainer);

/**
 * @param {import('@floating-ui/react-dom').Placement} placement
 * @param {boolean} isRTL
 */
const getPlacement = (placement, isRTL) => {
  switch (placement) {
    case "left":
      return isRTL ? "right" : "left";
    case "left-end":
      return isRTL ? "right-end" : "left-end";
    case "left-start":
      return isRTL ? "right-start" : "left-start";
    case "right":
      return isRTL ? "left" : "right";
    case "right-end":
      return isRTL ? "left-end" : "right-end";
    case "right-start":
      return isRTL ? "left-start" : "right-start";
    default:
      return placement;
  }
};

/**
 * @param {{as?: string, className?: string, style?: import("react").CSSProperties,
 * floatingStyle?: import("react").CSSProperties, floatingClassName?: string,
 * faIcon?: import("react").ReactNode, faIconColor?: string, children?: import("react").ReactNode,
 * message?: import("react").ReactNode, placement?: import("@floating-ui/react-dom").Placement, showCloseIcon?: boolean,
 * addSpaceBefore?: boolean, addSpaceAfter?: boolean, portal?: boolean, light?: boolean,
 * trigger?: "click" | "hover",
 * showInfoPopover?: boolean,
 * variant?: "default" | "primary" | "dark" | "card",
 * offset?: number}} param0
 */
const InfoPopover = ({
  as: Component = "span",
  className = "",
  style = null,
  floatingStyle = null,
  floatingClassName = "",
  faIcon = null,
  faIconColor = null,
  children,
  message,
  placement = "bottom",
  showCloseIcon = false,
  addSpaceBefore = true,
  addSpaceAfter = false,
  trigger = "hover",
  portal = null,
  light = null,
  offset: offsetPx = 0,
  variant = "default",
  showInfoPopover = true,
}) => {
  const isRTL = useIsRTL();
  placement = getPlacement(placement, isRTL);
  const [show, setShow] = useState(false);
  const mutableRefRef = useRef({ wasShownBefore: false, prevShow: false });
  const wasShownBefore = mutableRefRef.current.wasShownBefore || show;
  mutableRefRef.current.wasShownBefore = wasShownBefore;
  mutableRefRef.current.prevShow = mutableRefRef.current.show;
  mutableRefRef.current.show = show;

  const { x, y, refs, strategy } = useFloating({
    placement,
    middleware: [offset(offsetPx), shift()],
  });

  if (!isArray(children)) {
    children = [children];
  }
  const popoverContent = message
    ? message
    : faIcon
    ? children
    : children.length === 2
    ? children[1]
    : children.length === 1
    ? children[0]
    : "INFO";

  const main = (
    <>
      <Spaces count={addSpaceBefore} />
      {faIcon ? (
        <FontAwesomeIcon color={faIconColor} icon={faIcon} />
      ) : message ? (
        children
      ) : children[0] ? (
        children[0]
      ) : (
        <FontAwesomeIcon color={faIconColor} icon={faInfoCircle} />
      )}
      <Spaces count={addSpaceAfter} />
    </>
  );

  const popover = (
    <div
      ref={refs.setFloating}
      className={classNames(
        "info-popover__popover-container",
        `info-popover__${variant}`,
        {
          [floatingClassName]: floatingClassName,
          "info-popover__popover-container--light": light,
        }
      )}
      style={{
        ...floatingStyle,
        position: strategy,
        top: y ?? "",
        left: x ?? "",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {showCloseIcon ? (
        <span className="info-popover__close-button-container">
          <Close onClick={() => setShow(false)} />
        </span>
      ) : null}
      {popoverContent}
    </div>
  );

  const changeShow = (show) => {
    if (show) {
      clearTimeout(mutableRefRef.current.timer);
      setShow(show);
    } else {
      mutableRefRef.current.timer = setTimeout(() => {
        setShow(false);
      }, 250);
    }
  };

  return (
    <Component
      ref={refs.setReference}
      onClick={(e) => {
        const { wasShownBefore, show } = mutableRefRef.current;
        if (!wasShownBefore && !show) {
          e.stopPropagation();
          changeShow(true);
        } else {
          changeShow((show) => !show);
        }
      }}
      onMouseEnter={() => trigger === "hover" && changeShow(true)}
      onMouseLeave={() => trigger === "hover" && changeShow(false)}
      className={classNames("info-popover-trigger", { [className]: className })}
      style={style}
    >
      {main}
      {show && showInfoPopover ? (
        portal ? (
          <PopoverPortal>{popover}</PopoverPortal>
        ) : (
          popover
        )
      ) : null}
    </Component>
  );
};

/** @type {React.FunctionComponent<{ className?:string,style?:React.CSSProperties,anchorElement:HTMLElement,placement?:string,show?:boolean,portal?:boolean,light?:boolean,offset?:number,children:React.ReactNode}>} */
export const Popover = ({
  className,
  style,
  anchorElement,
  placement = "bottom",
  show = true,
  portal = true,
  light = true,
  offset: offsetPx = 0,
  children,
}) => {
  const isRTL = useIsRTL();
  placement = getPlacement(placement, isRTL);

  const { x, y, refs, strategy } = useFloating({
    placement,
    middleware: [offset(offsetPx), flip(), shift()],
  });
  refs.setReference(anchorElement);

  const popover = (
    <div
      ref={refs.setFloating}
      className={classNames("info-popover__popover-container", {
        [className]: className,
        "info-popover__popover-container--light": light,
      })}
      style={{
        ...style,
        position: strategy,
        top: y ?? "",
        left: x ?? "",
      }}
    >
      {children}
    </div>
  );

  return show ? (
    portal ? (
      <PopoverPortal>{popover}</PopoverPortal>
    ) : (
      popover
    )
  ) : null;
};

export const InfoPopoverButton = ({
  faIcon,
  faIconColor,
  className,
  buttonClassName,
  children,
  message,
  placement = "bottom",
  addSpaceBefore = true,
  addSpaceAfter = false,
  onClick,
  title,
}) => {
  return (
    <button
      className={
        buttonClassName
          ? `${buttonClassName} info-popover-button`
          : `info-popover-button`
      }
      onClick={onClick}
      title={title}
    >
      <InfoPopover
        faIcon={faIcon}
        faIconColor={faIconColor}
        className={className}
        children={children}
        message={message}
        placement={placement}
        addSpaceAfter={addSpaceAfter}
        addSpaceBefore={addSpaceBefore}
      />
    </button>
  );
};

export default InfoPopover;
