import Table, { TableCell, TableRow } from "atomic-components/organisms/Table";
import { useTranslate } from "modules/language";
import ComplianceCategoryItemRow from "../ComplianceCategoryItemRow/ComplianceCategoryItemRow";
import {
  ComplianceScoreValues,
  type SubComplianceScore,
} from "modules/building/pages/SustainabilityPage/types";
import { checkIsScoreNonCompliant } from "modules/building/pages/SustainabilityPage/utils";

import s from "./ComplianceCategoryItemTable.module.scss";

type CategoryItemTableProps<COMPLIANCE_SCORE_KEY extends string> = {
  data: ComplianceScoreValues<COMPLIANCE_SCORE_KEY>;
  content: SubComplianceScore<COMPLIANCE_SCORE_KEY>[];
};

const tableHeader = [
  "label.REQUISITE",
  "label.SUBCATEGORY",
  "label.MIN_MAX",
  "label.TOTAL_ACHIEVED",
  "label.TARGET",
];

const CategoryItemTable = <COMPLIANCE_SCORE_KEY extends string>({
  data,
  content,
}: CategoryItemTableProps<COMPLIANCE_SCORE_KEY>) => {
  const t = useTranslate();

  return (
    <Table className={s.table}>
      <thead>
        <TableRow className={s.tableHeader} skin="middleBlue">
          {tableHeader.map((header) => (
            <TableCell className={s.tableCellCentered} title={t(header)} isHead>
              {t(header)}
            </TableCell>
          ))}
        </TableRow>
      </thead>
      <tbody>
        {content.map((row) => {
          const { title: rowTitle, children, key } = row;

          const isNotEligibleForLEED = checkIsScoreNonCompliant({
            scoreValues: data,
            childContents: [row],
          });

          return (
            <>
              <ComplianceCategoryItemRow
                content={row}
                data={data}
                key={key}
                title={rowTitle}
                rowColor="white"
                isNotEligibleForLEED={isNotEligibleForLEED}
              />
              {children?.map((subRow) => {
                const isNotEligibleForLEED = checkIsScoreNonCompliant({
                  scoreValues: data,
                  childContents: [subRow],
                  requisite: row.requisite,
                });

                return (
                  <ComplianceCategoryItemRow
                    content={subRow}
                    data={data}
                    key={key}
                    title={`${rowTitle}-${subRow.title}`}
                    isNotEligibleForLEED={isNotEligibleForLEED}
                    rowColor="lightBlue"
                  />
                );
              })}
            </>
          );
        })}
      </tbody>
    </Table>
  );
};

export default CategoryItemTable;
