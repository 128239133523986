import type { ReactNode } from "react";
import { useTranslate } from "modules/language";

import s from "./PageHeader.module.scss";

type PageHeaderProps = {
  mainTitle: ReactNode;
  subTitle?: ReactNode;
  content?: ReactNode;
  isCompact?: boolean;
  logo?: string;
};

const PageHeader = ({
  mainTitle,
  subTitle,
  content,
  isCompact = false,
  logo,
}: PageHeaderProps) => {
  const t = useTranslate();

  return (
    <header className={s.pageHeader}>
      <div className={s.pageHeaderTitleContainer}>
        {logo ? (
          <img className={s.pageHeaderLogo} src={logo} alt="Logo" />
        ) : null}
        <h1 className={s.pageHeaderTitle}>
          {isCompact ? null : (
            <span className={s.pageHeaderBranchTitle}>
              {t("label.CAIRO_HQ")}
              {" | "}
            </span>
          )}
          <span className={s.pageHeaderTitleMain}>{mainTitle}</span>
          {subTitle ? (
            <span className={s.pageHeaderTitleSub}> - {subTitle}</span>
          ) : null}
        </h1>
      </div>
      {content ? <div className={s.pageHeaderContent}>{content}</div> : null}
    </header>
  );
};

export default PageHeader;
export type { PageHeaderProps };
