import { useMergedNotifier } from "helpers/getNotifier";
import getRatio from "helpers/getRatio";
import useBuildingData from "modules/building/hooks/useBuildingData";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import {
  useViewData,
  useViewState,
} from "modules/building/providers/ViewProvider/ViewProvider";
import { useMemoedLinkedData } from "./useLinkedData";
import { useUtilsEmissions } from "./useUtilsEmissions";

const useTravelEmissions = () => {
  const { buildingData } = useBuildingData();
  const selectedEntity = useSelectedEntity(buildingData);

  const {
    resolutions,
    Notifier: TMNotifier,
    loading: isTMLoading,
    error: TMError,
  } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        resolutionKey: "1month",
        telemetries: [
          {
            telemetryName: "dailyMaximumUniqueEmployees",
          },
        ],
      },
    ],
    [selectedEntity.id]
  );

  const {
    telemetries,
    Notifier: TNotifier,
    loading: isTLoading,
    error: TError,
  } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        telemetries: [
          {
            telemetryName: "monthlyFlightEmissions",
          },
          {
            telemetryName: "monthlyFuelTypeSolar",
          },
          {
            telemetryName: "monthlyFuelType95",
          },
          {
            telemetryName: "monthlyFuelType92",
          },
        ],
      },
    ],
    [selectedEntity.id]
  );

  const {
    state,
    Notifier: EPNotifier,
    loading: isEPLoading,
    error: EPError,
  } = useEditableParamsState();

  const { dailyMaximumUniqueEmployees } =
    resolutions["1month"][selectedEntity.id];

  const {
    monthlyFlightEmissions,
    monthlyFuelTypeSolar,
    monthlyFuelType95,
    monthlyFuelType92,
  } = telemetries[selectedEntity.id];

  const dailyMaximumUniqueEmployeesValue =
    dailyMaximumUniqueEmployees.data[0]?.value ?? NaN;
  const monthlyFlightEmissionsValue =
    monthlyFlightEmissions.data[0]?.value ?? NaN;
  const monthlyFuelTypeSolarValue = monthlyFuelTypeSolar.data[0]?.value ?? NaN;
  const monthlyFuelType95Value = monthlyFuelType95.data[0]?.value ?? NaN;
  const monthlyFuelType92Value = monthlyFuelType92.data[0]?.value ?? NaN;

  const carsTransportDistributionValue =
    getRatio(state.carsTransportDistribution.value, 100) ?? NaN;
  const busesTransportDistributionValue =
    getRatio(state.busesTransportDistribution.value, 100) ?? NaN;
  const avgEmployeeKmTraveledValue = state.avgEmployeeKmTraveled.value ?? NaN;
  const avgEmployeeCarsFuelEconomyValue =
    state.avgEmployeeCarsFuelEconomy.value ?? NaN;
  const busEmissionPerEmployeeValue = state.busEmissionPerEmployee.value ?? NaN;

  const companyCarsEmissions =
    monthlyFuelType95Value * 2.31 + monthlyFuelType92Value * 2.31;

  const companyBusesEmissions = monthlyFuelTypeSolarValue * 2.68;

  const companyVehiclesEmissions = companyCarsEmissions + companyBusesEmissions;

  const employeeCarsEmissions =
    carsTransportDistributionValue *
    dailyMaximumUniqueEmployeesValue *
    2 *
    avgEmployeeKmTraveledValue *
    avgEmployeeCarsFuelEconomyValue;

  const employeeBusesEmissions =
    busesTransportDistributionValue *
    dailyMaximumUniqueEmployeesValue *
    2 *
    avgEmployeeKmTraveledValue *
    busEmissionPerEmployeeValue;

  const employeeVehiclesEmissions =
    employeeCarsEmissions + employeeBusesEmissions;

  const totalTravelEmissions =
    companyVehiclesEmissions +
    employeeVehiclesEmissions +
    monthlyFlightEmissionsValue;

  const Notifier = useMergedNotifier(TNotifier, TMNotifier, EPNotifier);

  /* EMISSIONS AND RESOLUTIONS BY YEAR */

  const { fromTs } = useViewState();

  const {
    transformYearEmissions,
    transformYearResolutions,
    combineMultipleArrays,
    getValues,
    yearFromTimeStamp,
    calculateFromAndTo,
  } = useUtilsEmissions();

  const yearSelected = yearFromTimeStamp(fromTs);
  const timeSelected = calculateFromAndTo(yearSelected);

  const { telemetries: telemetriesByYear } = useMemoedLinkedData(
    timeSelected.firstDay,
    timeSelected.lastDay,
    () => {
      return [
        {
          entityId: selectedEntity.id,
          telemetries: [
            {
              telemetryName: "monthlyFlightEmissions",
            },
            {
              telemetryName: "monthlyFuelType95",
            },
            {
              telemetryName: "monthlyFuelType92",
            },
            {
              telemetryName: "monthlyFuelTypeSolar",
            },
          ],
        },
      ];
    },
    [selectedEntity.id]
  );

  const totalFuelByYear = (arr1: number[], arr2: number[]) => {
    return arr1.map((item, index) => item * 2.31 + arr2[index] * 2.31);
  };

  const companyCarsEmissionsByYear = () => {
    const fuel92 = telemetriesByYear[selectedEntity.id].monthlyFuelType92.data;
    const fuel92Final = transformYearEmissions(fuel92);
    const fuel95 = telemetriesByYear[selectedEntity.id].monthlyFuelType95.data;
    const fuel95Final = transformYearEmissions(fuel95);
    const fuel92FinalValues = getValues(fuel92Final);
    const fuel95FinalValues = getValues(fuel95Final);
    const fuelByYear = totalFuelByYear(fuel92FinalValues, fuel95FinalValues);
    return { fuelByYear };
  };

  const companyBusesEmissionsByYear = () => {
    const fuelSolar =
      telemetriesByYear[selectedEntity.id].monthlyFuelTypeSolar.data;
    const fuelSolarFinal = transformYearEmissions(fuelSolar);
    const busesEmissions = fuelSolarFinal.map((item) => {
      const newValue = item.value * 2.68;
      return newValue;
    });
    return { busesEmissions };
  };

  const companyCarsYear = companyCarsEmissionsByYear();
  const companyBusesYear = companyBusesEmissionsByYear();
  const companyVehiclesEmissionsByYear: number[] =
    companyCarsYear.fuelByYear.map((value, index) => {
      const total = value + (companyBusesYear.busesEmissions[index] || 0);
      return total;
    });

  const { resolutions: resolutionsByYear } = useMemoedLinkedData(
    timeSelected.firstDay,
    timeSelected.lastDay,
    () => {
      return [
        {
          entityId: selectedEntity.id,
          resolutionKey: "1month",
          telemetries: [
            {
              telemetryName: "dailyMaximumUniqueEmployees",
            },
          ],
        },
      ];
    },
    [selectedEntity.id]
  );

  const dailyMaximumUniqueEmployeesByYearFinal = transformYearResolutions(
    resolutionsByYear["1month"][selectedEntity.id].dailyMaximumUniqueEmployees
      .data
  );

  const dailyMaximumUniqueEmployeesByYearValue = getValues(
    dailyMaximumUniqueEmployeesByYearFinal
  );
  const employeeCarsEmissionsByYear =
    dailyMaximumUniqueEmployeesByYearValue.map((item) => {
      return (
        carsTransportDistributionValue *
        item *
        2 *
        avgEmployeeKmTraveledValue *
        avgEmployeeCarsFuelEconomyValue
      );
    });
  const employeeBusesEmissionsByYear =
    dailyMaximumUniqueEmployeesByYearValue.map((item) => {
      return (
        busesTransportDistributionValue *
        item *
        2 *
        avgEmployeeKmTraveledValue *
        busEmissionPerEmployeeValue
      );
    });
  const employeeVehiclesEmissionsByYear = combineMultipleArrays(
    employeeCarsEmissionsByYear,
    employeeBusesEmissionsByYear
  );

  const monthlyFlightEmissionsByYear =
    telemetriesByYear[selectedEntity.id].monthlyFlightEmissions.data;
  const monthlyFlightEmissionsByYearFinal = transformYearEmissions(
    monthlyFlightEmissionsByYear
  );
  const flightEmissionsByYear = getValues(monthlyFlightEmissionsByYearFinal);

  return {
    Notifier,
    isLoading: isEPLoading || isTLoading || isTMLoading,
    error: EPError || TError || TMError,
    totalTravelEmissions,
    employeeVehiclesEmissions,
    employeeVehiclesEmissionsByYear,
    employeeCarsEmissions,
    employeeBusesEmissions,
    companyVehiclesEmissions,
    companyVehiclesEmissionsByYear,
    companyCarsEmissions,
    companyBusesEmissions,
    flightEmissions: monthlyFlightEmissionsValue,
    flightEmissionsByYear,
    days: dailyMaximumUniqueEmployees.days,
  };
};

export { useTravelEmissions };
