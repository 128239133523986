const texturizeObject = (object, { key, texture }) => {
  const _VS = `
            varying highp vec2 vuv;
            void main() {
              vuv = uv;
              vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
              gl_Position = projectionMatrix * mvPosition;
            }`;
  const _FS = `
            varying highp vec2 vuv;
            uniform sampler2D utexture;
            void main() {
              gl_FragColor = texture2D(utexture, vuv);
            }`;
  const material = new window.THREE.ShaderMaterial({
    uniforms: {
      utexture: {
        value: texture,
      },
    },
    vertexShader: _VS,
    fragmentShader: _FS,
    transparent: true,
  });

  if (!object.userData.texturized) {
    object.userData.texturized = true;
    object.userData.texturizerMaterialsStack = [{ key, material }];
    object.userData.texturizerInitialMaterial = object.material;
    object.userData.texturizerInitialVisibility = object.visible;
  } else {
    object.userData.texturizerMaterialsStack.push({ key, material });
  }
  object.material = material;
  object.visible = true;
};

export default texturizeObject;
