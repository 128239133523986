import HorizontalStacks from "atomic-components/atoms/HorizontalStacks";
import Key from "atomic-components/atoms/Key";
import UnitValue from "atomic-components/atoms/UnitValue";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import getPercent from "helpers/getPercent";
import isRealNumber from "helpers/isRealNumber";
import useBuildingData from "modules/building/hooks/useBuildingData";
import { useTravelEmissions } from "modules/building/hooks/useTravelEmissions";
import { useTranslate } from "modules/language";

const TravelScopeBreakdown = () => {
  const t = useTranslate();
  const {
    buildingData: { unitsByName },
  } = useBuildingData();

  const {
    companyVehiclesEmissions,
    employeeVehiclesEmissions,
    flightEmissions,
    totalTravelEmissions,
    Notifier,
  } = useTravelEmissions();

  const restEmissions =
    isRealNumber(employeeVehiclesEmissions) && isRealNumber(flightEmissions)
      ? employeeVehiclesEmissions + flightEmissions
      : null;

  return (
    <Notifier>
      <WidgetContainer multiLine>
        <Key tag="h4">{t("label.SCOPE_BREAKDOWN")}</Key>
        <HorizontalStacks
          stacks={[
            {
              value: companyVehiclesEmissions,
              tooltip: (
                <UnitValue
                  value={companyVehiclesEmissions}
                  unit={unitsByName.kgco2e}
                />
              ),
              legend: (
                <>
                  <strong>{t("label.SCOPE_1_COMPANY_OWNED_VEHICLES")}</strong>
                  <br />
                  <UnitValue
                    value={companyVehiclesEmissions}
                    unit={unitsByName.kgco2e}
                  />{" "}
                  (
                  <UnitValue
                    value={getPercent(
                      companyVehiclesEmissions,
                      totalTravelEmissions
                    )}
                    unit={unitsByName.percent}
                  />
                  )
                </>
              ),
            },
            {
              value: restEmissions,
              tooltip: (
                <UnitValue value={restEmissions} unit={unitsByName.kgco2e} />
              ),
              legend: (
                <>
                  <strong>
                    {t("label.SCOPE_3_EMPLOYEE_COMMUTING_CARS_BUSES_FLIGHTS")}
                  </strong>
                  <br />
                  <UnitValue
                    value={restEmissions}
                    unit={unitsByName.kgco2e}
                  />{" "}
                  (
                  <UnitValue
                    value={getPercent(restEmissions, totalTravelEmissions)}
                    unit={unitsByName.percent}
                  />
                  )
                </>
              ),
            },
          ]}
          displayValue={false}
        />
      </WidgetContainer>
    </Notifier>
  );
};

export default TravelScopeBreakdown;
