import { useEffect } from "react";
import { useViewerState } from "../Provider/Provider";

const AmbientLight = ({ color = 0xffffff, intensity = 0.25 }) => {
  const { initialized, scene } = useViewerState();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    const ambLight = new window.THREE.AmbientLight({ color, intensity });
    ambLight.intensity = intensity;
    scene.add(ambLight);

    return () => {
      scene.remove(ambLight);
    };
  }, [initialized, scene, color, intensity]);

  return null;
};

export default AmbientLight;
