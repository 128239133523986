import {
  LeedOmCertificateLevelsEnum,
  leedOmCertificateLevels,
  MAX_CERTIFICATE_SCORE,
  type Scenario,
} from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ComplianceTrackingPanel/Certificates/LeedOmCertificate/utils";
import { useTranslate } from "modules/language";
import { truncate } from "lodash";
import Button from "atomic-components/atoms/Buttons/Button";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import Trash from "svg-icons/Trash";
import Switch from "svg-icons/Switch";
import PaperPin from "svg-icons/PaperPin";
import Target from "svg-icons/Target";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import { useModalState } from "atomic-components/molecules/Modal";
import ConfirmationModalBody from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ConfirmationModalBody/ConfirmationModalBody";
import classNames from "classnames";
import { useAuthenticationState } from "modules/authentication/state/authentication.state";
import ScenarioDetailsModal from "modules/building/pages/SustainabilityPage/components/ScenarioDetailsModal/ScenarioDetailsModal";
import { Nullable } from "types/utils";
import {
  useDeleteScenario,
  useToggleActivateScenario,
  useTogglePinScenario,
} from "modules/building/hooks/useComplianceScenariosMutate";
import { addToast } from "modules/toast";
import PinnedPaperPin from "svg-icons/PinnedPaperPin";
import { getScenarioTotalScore } from "../ScenariosList";
import { useEffect } from "react";

import s from "./ScenarioPreview.module.scss";

type ScenarioPreviewProps = {
  scenario: Scenario;
  targetScore: Nullable<number>;
  activeScenario?: Scenario;
  isPinnedScenariosLimitReached?: boolean;
};

const ScenarioPreview = ({
  scenario,
  targetScore,
  activeScenario,
  isPinnedScenariosLimitReached,
}: ScenarioPreviewProps) => {
  const t = useTranslate();
  const { roles } = useAuthenticationState();
  const { setModal, hideModal } = useModalState();

  const isAdmin = !!roles?.find((role) => role.name === "admin");

  const { title, comment, isActive, isPinned } = scenario;

  const onViewScenario = () => {
    setModal(<ScenarioDetailsModal scenario={scenario} isPreview={true} />, {
      width: "large",
    });
  };

  const { mutateAsync: deleteScenario } = useDeleteScenario(scenario.id);
  const { mutateAsync: toggleActivateScenario } = useToggleActivateScenario(
    scenario.id
  );
  const { mutateAsync: togglePinScenario } = useTogglePinScenario(scenario.id);

  const onRemove = () => {
    setModal(
      <ConfirmationModalBody
        headerMessage="message.ARE_YOU_SURE"
        hintMessage={
          <>
            {t("message.BEFORE_DELETE_SCENARIO_[SCENARIO_NAME]", {
              SCENARIO_NAME: title,
            })}
          </>
        }
        confirmText="label.DELETE_SCENARIO_CONFIRMATION"
        confirmationButtonSkin="danger"
        onConfirm={() => {
          deleteScenario();
          hideModal();
        }}
      />,
      {
        width: "medium",
      }
    );
  };

  const onToggleActivate = () => {
    // no confirmation needed if 1-deactivating a scenario, or 2-if activating a scenario while no active scenario
    if (isActive || !activeScenario) {
      toggleActivateScenario().then(() => {
        addToast({
          messageKey: isActive
            ? "toast.SCENARIO_WAS_DEACTIVATED"
            : "toast.SCENARIO_WAS_ACTIVATED",
          variant: "info",
        });
      });
    } else {
      setModal(
        <ConfirmationModalBody
          headerMessage={
            <>
              {t("message.BEFORE_ACTIVATE_SCENARIO_[SCENARIO1]_[SCENARIO2]", {
                SCENARIO1: activeScenario?.title || "",
                SCENARIO2: title,
              })}
            </>
          }
          confirmationButtonSkin="primary"
          onConfirm={() => {
            toggleActivateScenario().then(() => {
              addToast({
                messageKey: isActive
                  ? "toast.SCENARIO_WAS_DEACTIVATED"
                  : "toast.SCENARIO_WAS_ACTIVATED",
                variant: "info",
              });
            });
            hideModal();
          }}
        />,
        {
          width: "medium",
        }
      );
    }
  };

  const onTogglePin = () => {
    if (!isPinned && isPinnedScenariosLimitReached) {
      addToast({
        messageKey: "toast.MAX_PINNED_SCENARIOS",
        variant: "warn",
      });
      return;
    }
    togglePinScenario();
  };

  const totalScenarioScore = getScenarioTotalScore(scenario);

  const satisfiedCertificateLevel = leedOmCertificateLevels.find(
    (level) =>
      (totalScenarioScore || 0) >= level.min &&
      (totalScenarioScore || 0) <= level.max
  );
  const noCertificateSatisfied =
    satisfiedCertificateLevel?.id === LeedOmCertificateLevelsEnum.Uncertifiable;

  const satisfiesTarget =
    targetScore && (totalScenarioScore || 0) >= targetScore;

  // deactivate scenario if it does not satisfy target
  useEffect(() => {
    if (!satisfiesTarget && isActive) onToggleActivate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [satisfiesTarget]);

  const allowToggleActivateScenario = isAdmin && satisfiesTarget;

  const PinIcon = isPinned ? PinnedPaperPin : PaperPin;

  const activateSwitch = (
    <Switch
      on={isActive}
      backgroundColor="var(--color-primary-background)"
      onBackgroundColor="var(--color-primary)"
      onClick={allowToggleActivateScenario ? onToggleActivate : () => null}
      onText=""
      offText=""
      className={classNames({
        [s.notAllowed]: !allowToggleActivateScenario,
      })}
    />
  );

  return (
    <div className={s.container}>
      <div className={s.signalsContainer}>
        <StrippedButton
          title={
            isPinned ? t("action.UNPIN_SCENARIO") : t("action.PIN_SCENARIO")
          }
          onClick={onTogglePin}
        >
          <PinIcon />
        </StrippedButton>

        <span className={s.separator}>&#8212;</span>

        <InfoPopover
          message={
            !targetScore
              ? t("message.EVALUATION_WIZARD_NOT_COMPLETED")
              : satisfiesTarget
              ? t("message.SCENARIO_SATISFIES_TARGET")
              : t("message.SCENARIO_DOES_NOT_SATISFY_TARGET")
          }
          variant="primary"
          placement="top"
        >
          <Target
            fill={
              !targetScore
                ? "var(--gray-xlight-color)"
                : satisfiesTarget
                ? "var(--color-success)"
                : "var(--red-color)"
            }
          />
        </InfoPopover>
      </div>

      <div className={s.verticalContainer}>
        <div className={s.row}>
          <b>{title}</b>
          <div className={s.actionsContainer}>
            <span>
              {isActive ? t("message.ACTIVE") : t("message.INACTIVE")}
            </span>
            {satisfiesTarget ? (
              activateSwitch
            ) : (
              <InfoPopover
                message={
                  satisfiesTarget
                    ? " "
                    : t("message.SCENARIO_DOES_NOT_SATISFY_TARGET")
                }
                variant="primary"
                placement="top"
              >
                {activateSwitch}
              </InfoPopover>
            )}
          </div>
        </div>

        <div className={s.row}>
          {noCertificateSatisfied ? (
            t("message.SCENARIO_DOES_NOT_SATISFY_CERTIFICATE")
          ) : (
            <>
              {t("label.SATISFIES")}:{" "}
              {t(satisfiedCertificateLevel?.certificateLabel)} (
              {t("label.[x]_[y]_PT", {
                x: totalScenarioScore,
                y: MAX_CERTIFICATE_SCORE,
              })}
              )
            </>
          )}
        </div>

        <div className={s.row}>
          <div>
            {t("label.COMMENTS")}: <i>{comment ? truncate(comment) : "-"}</i>
          </div>
          <div className={s.actionsContainer}>
            <Button skin="link" onClick={onViewScenario}>
              {t("label.VIEW")}
            </Button>
            <StrippedButton
              title={t("action.REMOVE")}
              onClick={isAdmin ? onRemove : () => null}
              className={classNames({
                [s.notAllowed]: !isAdmin,
              })}
            >
              <Trash fill="var(--color-primary)" />
            </StrippedButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScenarioPreview;
