import { definitionsArray } from "./definitions";
import type {
  EditableParamsStoreState,
  EditableParamsStoreActions,
} from "./types";
import { createStore } from "zustand";

/**
 * TODO - replace this implementation with createFormStoreHook
 */
const editableParamsStore = createStore<
  EditableParamsStoreState & EditableParamsStoreActions
>((set, get) =>
  Object.fromEntries(
    definitionsArray.map(({ name, fallbackValue }) => {
      return [
        [name],
        {
          initialValue: null,
          value: null,
          error: "",
          setInitialValue: (value) => {
            set((state) => {
              return {
                [name]: {
                  ...state[name],
                  initialValue: value,
                },
              };
            });
          },
          setValue: (value) => {
            set((state) => {
              return {
                [name]: {
                  ...state[name],
                  value,
                },
              };
            });
          },
          setError: (error) => {
            set((state) => {
              return {
                [name]: {
                  ...state[name],
                  error,
                },
              };
            });
          },
          resetValue: () => {
            set((state) => {
              return {
                [name]: {
                  ...state[name],
                  value: fallbackValue,
                },
              };
            });
          },
          syncInitialValue: () => {
            const isValueUpdated =
              get()[name].value !== get()[name].initialValue;

            if (isValueUpdated) {
              set((state) => {
                return {
                  [name]: {
                    ...state[name],
                    initialValue: state[name].value,
                  },
                };
              });
            }

            return isValueUpdated;
          },
        },
      ];
    })
  )
);

export { editableParamsStore };
