import Button from "atomic-components/atoms/Buttons/Button";
import ModalFooter from "atomic-components/molecules/Modal/ModalFooter";
import ModalHeader from "atomic-components/molecules/Modal/ModalHeader";
import { useTranslate } from "modules/language";
import { useModalState } from "atomic-components/molecules/Modal";
import { ButtonProps } from "atomic-components/atoms/Buttons/Button/Button";

import s from "./ConfirmationModalBody.module.scss";

type ConfirmationModalBodyProps = {
  headerMessage: string | React.ReactNode;
  hintMessage?: string;
  confirmText?: string;
  onConfirm?: () => void;
  hasCancelButton?: boolean;
  cancelText?: string;
  onCancel?: () => void;
  confirmationButtonSkin?: ButtonProps["skin"];
};

const ConfirmationModalBody = ({
  headerMessage,
  hintMessage,
  confirmText = "action.CONFIRM",
  onConfirm,
  hasCancelButton = true,
  cancelText = "action.CANCEL",
  onCancel,
  confirmationButtonSkin = "primary",
}: ConfirmationModalBodyProps) => {
  const t = useTranslate();
  const { hideModal } = useModalState();

  return (
    <>
      <ModalHeader
        color="primary"
        cancel={{
          text: t("action.CANCEL"),
        }}
      >
        <h2 className={s.headerText}>{t(headerMessage)}</h2>
      </ModalHeader>

      {hintMessage ? <p className={s.hint}>{t(hintMessage)}</p> : null}

      <ModalFooter>
        {hasCancelButton ? (
          <Button
            type="button"
            size="large"
            skin="secondary"
            onClick={onCancel ? onCancel : hideModal}
            className={s.cancelButton}
          >
            {t(cancelText)}
          </Button>
        ) : null}
        <Button
          type="button"
          size="large"
          skin={confirmationButtonSkin}
          onClick={onConfirm ? onConfirm : hideModal}
        >
          {t(confirmText)}
        </Button>
      </ModalFooter>
    </>
  );
};

export default ConfirmationModalBody;
