import { useState } from "react";
import { useTranslate } from "modules/language";
import EditableParamsContainer from "../../../../../editableParams/EditableParamsContainer/EditableParamsContainer";
import ElectricityEmissionIntensityEditable from "../../../../../editableParams/ElectricityEmissionIntensityEditable/ElectricityEmissionIntensityEditable";
import ChillerPlantCOPEditable from "../../../../../editableParams/ChillerPlantCOPEditable/ChillerPlantCOPEditable";
import useEditableParamsMutate from "modules/building/hooks/useEditableParamsMutate";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import { validateParamValues } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/utils";
import type { UpdateEditableParamParams } from "modules/building/api/editableParams";
import type { ParamName } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/definitions";

const ChilledWaterBreakdownAssumptions = () => {
  const t = useTranslate();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isHistoryEnabled, setIsHistoryEnabled] = useState(false);
  const { state, Notifier, telemetries } = useEditableParamsState();
  const { mutateAsync, isLoading } = useEditableParamsMutate();

  const handleEditSave = async () => {
    if (
      !validateParamValues(state, [
        "electricityEmissionIntensity",
        "chillerPlantCOP",
      ])
    ) {
      return false;
    }

    const payload: UpdateEditableParamParams = [];

    (["electricityEmissionIntensity", "chillerPlantCOP"] as const).forEach(
      (paramName: ParamName) => {
        if (state[paramName].syncInitialValue()) {
          payload.push({
            timeseriesId: telemetries[paramName].timeseriesId,
            value: state[paramName].value!,
          });
        }
      }
    );

    if (payload.length) {
      await mutateAsync(payload);
    }

    return true;
  };

  return (
    <Notifier>
      <EditableParamsContainer
        title={t("label.ASSUMPTIONS")}
        isEditEnabled={isEditEnabled}
        setIsEditEnabled={setIsEditEnabled}
        isHistoryEnabled={isHistoryEnabled}
        setIsHistoryEnabled={setIsHistoryEnabled}
        onSave={handleEditSave}
      >
        <ElectricityEmissionIntensityEditable
          isEditEnabled={isEditEnabled}
          isHistoryEnabled={isHistoryEnabled}
          isHistoryExpanded
          disabled={isLoading}
        />
        <ChillerPlantCOPEditable
          isEditEnabled={isEditEnabled}
          isHistoryEnabled={isHistoryEnabled}
          disabled={isLoading}
        />
      </EditableParamsContainer>
    </Notifier>
  );
};

export default ChilledWaterBreakdownAssumptions;
