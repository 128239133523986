import { isString } from "lodash";

export const removeClassName = (classesNames, classNameToRemove) => {
  return classesNames.replace(
    new RegExp(
      "(^" +
        classNameToRemove +
        "$|\\s+" +
        classNameToRemove +
        "$|^" +
        classNameToRemove +
        "\\s+)",
      "gmi"
    ),
    ""
  );
};

const removeClassesNames = (classesNames, classesNamesToRemove) => {
  if (isString(classesNamesToRemove)) {
    classesNamesToRemove = classesNamesToRemove.split(/\s+/gim);
  }
  for (var i = 0; i < classesNamesToRemove.length; i++) {
    classesNames = removeClassName(classesNames, classesNamesToRemove[i]);
  }
  return classesNames;
};

export default removeClassesNames;
