import withIconProps from "helpers/withIconProps";

const Minus = withIconProps((props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="5"
        y1="10"
        x2="15"
        y2="10"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
});

export default Minus;
