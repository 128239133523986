import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import { useMemo } from "react";
import DropDown, {
  type DropDownProps,
} from "../../../../atomic-components/atoms/DropDown";
import useFloorFilterState from "../../hooks/useFloorFilter";
import ActivityIndicatorButton from "atomic-components/atoms/Buttons/ActivityIndicatorButton/ActivityIndicatorButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import { useTranslate } from "modules/language";
import type { BuildingData } from "modules/building/helpers/postProcessBuildingData";

import s from "./FloorSelector.module.scss";

type FloorSelectorProps = {
  className?: string;
  buildingData: BuildingData;
  disabledOptionNames?: string[];
};

const FloorSelector = ({
  className,
  buildingData,
  disabledOptionNames = [],
}: FloorSelectorProps) => {
  const { floors } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);
  const [floorUrl, , setFloorFilter] = useFloorFilterState();
  const t = useTranslate();

  const [selectedFloor, floorOptions] = useMemo(() => {
    return [
      floors.find((floor) => floor.url === floorUrl),
      [
        {
          text: t("label.BUILDING_VIEW"),
          url: null,
          disabled: disabledOptionNames.includes("building"),
        },
        ...floors.map((floor) => ({
          ...floor,
          text: <Displayer object={floor} />,
          disabled: disabledOptionNames.includes(floor.name),
        })),
      ],
    ];
  }, [floors, floorUrl, disabledOptionNames, t]);

  const onFloorSelectionChange: DropDownProps["onChange"] = ({
    option: floor,
  }) => {
    setFloorFilter(floor.url);
  };

  return (
    <DropDown
      className={className}
      options={floorOptions}
      value={selectedFloor ?? selectedEntity}
      textField="text"
      valueField="id"
      onChange={onFloorSelectionChange}
      renderDropDownButton={({ areOptionsShown, ...props }) => (
        <ActivityIndicatorButton
          className={s.dropdownButton}
          {...props}
          isActive={areOptionsShown}
        >
          <span>
            <FontAwesomeIcon icon={faBuilding} />
            <p>
              {selectedFloor?.type === "floor" ? (
                <Displayer object={selectedEntity} />
              ) : (
                t("label.BUILDING_VIEW")
              )}
            </p>
          </span>
          <span>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </ActivityIndicatorButton>
      )}
    />
  );
};

export default FloorSelector;
export type { FloorSelectorProps };
