import withIconProps from "helpers/withIconProps";
import type { SVGAttributes } from "react";

type Props = SVGAttributes<SVGElement> & {
  isHigh?: boolean;
};

const LuxIcon = withIconProps(
  ({ color, fill: passedFill, isHigh, ...props }: Props) => {
    const currentColor = color ?? "currentcolor";

    const fill =
      passedFill ||
      (isHigh ? "var(--color-red-pale)" : "var(--color-green-dark)");

    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
          fill={fill}
        />
        <path
          d="M16 6V8M16 24V26M8.92993 8.93005L10.3399 10.3401M21.6599 21.66L23.0699 23.07M6 16H8M24 16H26M10.3399 21.66L8.92993 23.07M23.0699 8.93005L21.6599 10.3401M20 16C20 18.2091 18.2091 20 16 20C13.7909 20 12 18.2091 12 16C12 13.7909 13.7909 12 16 12C18.2091 12 20 13.7909 20 16Z"
          stroke={currentColor}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
);

export default LuxIcon;
