import React from "react";
import {
  filter,
  findLast,
  flatten,
  groupBy,
  isArray,
  isPlainObject,
  keyBy,
  last,
  map,
  mapValues,
  omit,
  orderBy,
  some,
  sum,
  sumBy,
  uniqBy,
} from "lodash";
import { mean, std } from "mathjs";
import { DateTime } from "luxon";
import hardcoded from "../../../helpers/hardcoded";
import { fromMillis } from "helpers/dateTimeHelper";
import getStableKey from "helpers/getStableKey";
import { DEFAULT_INTERVAL, DEFAULT_TIMEZONE } from "./intervals";
import flattenWithChildren from "helpers/flattenWithChildren";
import config from "config";
import mapEntires from "helpers/mapEntires";
import { getLanguages, processTranslations } from "modules/language";

window.DateTime = DateTime;

const INTERVAL = hardcoded(10 * 60 * 1000);
const MIN_TICKS = 150;
const TICKS_BREAKPOINTS = [
  1, // every 10 minuets
  3, // every 30 minuets
  6, // every hour
  6 * 3, // every 3 hours
  6 * 6, // every 6 hours
  6 * 12, // every 12 hours
  6 * 24, // every day
  (6 * 24 * 7) / 4, // every 4 readings a week
  (6 * 24 * 7) / 2, // every 2 readings a week
  6 * 24 * 7, // every 1 reading a week
];

const div = (a, b) => {
  if (a == null || b == null) {
    return null;
  }
  if (b === 0) {
    return null;
  }
  return a / b;
};
const mult = (a, b) => {
  if (a == null || b == null) {
    return null;
  }
  return a * b;
};
const add = (...args) => {
  if (args.some((a) => a == null)) {
    return null;
  }
  return sum(args);
};
const subtract = (a, b) => {
  if (a == null || b == null) {
    return null;
  }
  return a - b;
};
const pow = (a, b) => {
  if (a == null || b == null) {
    return null;
  }
  return Math.pow(a, b);
};

const withTimestamps = (array, timestamps) => {
  if (!timestamps) {
    return array;
  }
  return {
    data: array.map((value, i) => ({
      value,
      timestamp: timestamps[i],
    })),
  };
};
const arrayOperation = (...args) => {
  const baseFunction = args.pop();
  let timestamps;
  args = args.map((a) => {
    if (isPlainObject(a) && a.data) {
      timestamps = a.data.map(({ timestamp }) => timestamp);
      return a.data.map(({ value }) => value);
    }
    return a;
  });

  const arr = args.find((a) => isArray(a));

  if (args.findIndex((a) => a == null) >= 0) {
    if (arr) {
      return withTimestamps(
        arr.map(() => null),
        timestamps
      );
    }
    return null;
  }

  if (arr) {
    const len = arr.length;
    if (args.findIndex((a) => isArray(a) && a.length !== len) >= 0) {
      throw new Error("Inputs array sizes missmatch");
    }
    return withTimestamps(
      arr.map((_, i) =>
        baseFunction(...args.map((a) => (isArray(a) ? a[i] : a)))
      ),
      timestamps
    );
  } else {
    return baseFunction(...args);
  }
};
export const FormulasMath = {
  divide: (a, b) => {
    return arrayOperation(a, b, div);
  },
  multiply: (a, b) => {
    return arrayOperation(a, b, mult);
  },
  add: (...args) => {
    return arrayOperation(...args, add);
  },
  subtract: (a, b) => {
    return arrayOperation(a, b, subtract);
  },
  pow: (a, b) => {
    return arrayOperation(a, b, pow);
  },
  nullify: (a) => {
    return arrayOperation(a, () => null);
  },
  last: (array, field) => {
    if (isPlainObject(array) && array.data) {
      array = array.data.map((datum) => datum[field ?? "value"]);
      field = null;
    }
    if (array == null) {
      return null;
    }
    array = array.filter(
      (a) => a != null && (field == null || a[field] != null)
    );
    return field == null ? last(array) : last(array)[field];
  },
  first: (array, field) => {
    if (isPlainObject(array) && array.data) {
      array = array.data.map((datum) => datum[field ?? "value"]);
      field = null;
    }
    if (array == null) {
      return null;
    }
    array = array.filter(
      (a) => a != null && (field == null || a[field] != null)
    );
    return field == null ? array[0] : array[0][field];
  },
  sum: (array, field) => {
    if (isPlainObject(array) && array.data) {
      array = array.data.map((datum) => datum[field ?? "value"]);
      field = null;
    }
    if (array == null) {
      return null;
    }
    array = array.filter((a) => a != null);
    if (array.length === 0) {
      return null;
    }
    if (field == null) {
      return sum(array);
    }
    return sumBy(array, field);
  },
  average: (array, field) => {
    if (isPlainObject(array) && array.data) {
      array = array.data.map((datum) => datum[field ?? "value"]);
      field = null;
    }
    if (array == null) {
      return null;
    }
    array = array.filter((a) => a != null);
    if (array.length === 0) {
      return null;
    }
    if (field == null) {
      return sum(array) / array.length;
    }
    return sumBy(array, field) / array.length;
  },
  max: (array, field) => {
    if (isPlainObject(array) && array.data) {
      array = array.data.map((datum) => datum[field ?? "value"]);
      field = null;
    }
    if (array == null) {
      return null;
    }
    array = array.filter((a) => a != null);
    if (array.length === 0) {
      return null;
    }
    if (field == null) {
      return Math.max(...array);
    }
    return Math.max(...map(array, field));
  },
  min: (array, field) => {
    if (isPlainObject(array) && array.data) {
      array = array.data.map((datum) => datum[field ?? "value"]);
      field = null;
    }
    if (array == null) {
      return null;
    }
    array = array.filter((a) => a != null);
    if (array.length === 0) {
      return null;
    }
    if (field == null) {
      return Math.min(...array);
    }
    return Math.min(...map(array, field));
  },
  sizeUp: (data, stdCount = 3) => {
    let timestamps;
    const original = data;
    const isATelemetry = isPlainObject(data) && data.data;
    if (isATelemetry) {
      timestamps = data.data.map(({ timestamp }) => timestamp);
      data = data.data.map(({ value }) => value);
    }
    if (data == null) {
      return null;
    }

    const noneNullData = data.filter((value) => value != null);

    const standardDeviation = noneNullData.length ? std(noneNullData) : 0;
    const average = noneNullData.length ? mean(noneNullData) : 0;
    const minimum = noneNullData.length ? Math.min(...noneNullData) * 0.9 : 0;
    const maximum = noneNullData.length ? Math.max(...noneNullData) * 1.1 : 0;
    let maxValue = average + stdCount * standardDeviation;
    let minValue = minimum > 0 ? 0 : minimum;
    const maxTimestamp = timestamps?.length
      ? Math.max(...timestamps)
      : Date.now();
    const minTimestamp = timestamps?.length
      ? Math.min(...timestamps)
      : Date.now();
    maxValue = maxValue * 2 < maximum ? maxValue : maximum;
    return {
      ...(isATelemetry ? original : { data }),
      minValue,
      maxValue,
      maxTimestamp,
      minTimestamp,
    };
  },
  accumulate: (data) => {
    let timestamps;
    const original = data;
    const isATelemetry = isPlainObject(data) && data.data;
    if (isATelemetry) {
      timestamps = data.data.map(({ timestamp }) => timestamp);
      data = data.data.map(({ value }) => value);
    }
    if (data == null) {
      return null;
    }
    let cumulative = 0;
    data = data.map((value) => {
      cumulative += value ?? 0;
      return cumulative;
    });
    return {
      ...(isATelemetry
        ? { ...original, ...withTimestamps(data, timestamps) }
        : { data }),
    };
  },
  map: (array, field) => {
    return map(array, field);
  },
  isNull: (val) => {
    return val == null;
  },
  isNotNull: (val) => {
    return val != null;
  },
  filter: (array, field) => {
    if (typeof field === "string") {
      const fieldKey = field;
      field = (a) => a[fieldKey];
    }
    if (isPlainObject(array) && array.data) {
      if (field == null) {
        field = (a) => a.value;
      }
      array = array.data.map((datum) => field(datum));
      field = (a) => a;
    }
    if (field == null) {
      field = (a) => a;
    }
    return filter(array, field);
  },
  clip: (array, min = 0, max = Infinity) => {
    if (array == null) {
      return null;
    }
    let timestamps;
    if (isPlainObject(array) && array.data) {
      timestamps = array.data.map((datum) => datum.timestamp);
      array = array.data.map((datum) => datum.value);
    }
    return withTimestamps(
      array.map((val) => {
        return val < min ? min : val > max ? max : val;
      }),
      timestamps
    );
  },
};
window.FormulasMath = FormulasMath;

const getDST = (prev, next, timezone) => {
  prev = fromMillis(prev, { zone: timezone });
  next = fromMillis(next, { zone: timezone });
  return prev.hour === 23 && next.hour === 1
    ? -1
    : prev.hour === 23 &&
      prev.minute === 50 &&
      next.hour === 23 &&
      next.minute === 0
    ? 1
    : 0;
};

const getDays = (from, to) => {
  const diff = to.diff(from, "days");
  return Math.ceil(diff.days);
};

const getNormalTicksPerInterval = (
  ticksCount,
  minTicks = MIN_TICKS,
  breakpoints = TICKS_BREAKPOINTS
) => {
  return findLast(breakpoints, (tick) => ticksCount / tick > minTicks) || 1;
};

const getTicksTimestamps = (from, to) => {
  from = from.toMillis();
  to = to.toMillis();
  let date = from;
  const rawTimestamps = [];
  while (date < to) {
    date = date + INTERVAL;
    rawTimestamps.push(date);
  }
  return rawTimestamps;
};

const getTicksPerIntervalAndTimestamps = (
  from,
  to,
  ticksTimestamps,
  normalTicksPerInterval
) => {
  const timezone = from.zone;

  from = from.toMillis();
  to = to.toMillis();
  let date = from;

  const ticksPerInterval = [];
  for (let i = 0; ; i++) {
    if (normalTicksPerInterval <= 6) {
      const next = date + normalTicksPerInterval * INTERVAL;
      if (next > to) {
        const ticksCount = Math.round(to - date) / INTERVAL;
        if (ticksCount) {
          ticksPerInterval.push(ticksCount);
        }
        break;
      } else {
        ticksPerInterval.push(normalTicksPerInterval);
      }
      date = next;
    } else {
      let ticksInThisInterval = normalTicksPerInterval;
      let j = 0;
      for (; date < to && j < ticksInThisInterval; j++) {
        let prev = date - INTERVAL;
        let next = date + INTERVAL;
        ticksInThisInterval += getDST(prev, date, timezone) * 6;
        date = next;
      }
      if (j > 0) {
        ticksPerInterval.push(j);
      } else {
        break;
      }
    }
  }
  let at = 0;
  const timestamps = [];
  for (let i = 0; i < ticksPerInterval.length; i++) {
    const currentSlice = ticksTimestamps.slice(at, at + ticksPerInterval[i]);
    at += ticksPerInterval[i];
    const timestamp = last(currentSlice);
    timestamps.push(timestamp);
  }

  if (sum(ticksPerInterval) !== ticksTimestamps.length) {
    throw new Error(
      `sum(ticksPerInterval) !== length | ${sum(ticksPerInterval)} !== ${
        ticksTimestamps.length
      }`
    );
  }

  return { ticksPerInterval, timestamps };
};

export const getTimestampsAndPeriod = (
  from,
  to,
  { ticksPerInterval: ticksPerIntervalArg, minTicks, breakpoints } = {}
) => {
  const rawTicksTimestamps = getTicksTimestamps(from, to);

  const { ticksPerInterval, timestamps } = getTicksPerIntervalAndTimestamps(
    from,
    to,
    rawTicksTimestamps,
    ticksPerIntervalArg ??
      getNormalTicksPerInterval(
        rawTicksTimestamps.length,
        minTicks,
        breakpoints
      )
  );
  const T = ticksPerInterval.map((ticks) => (ticks * INTERVAL) / 1000);
  return { timestamps, T, ticks: ticksPerInterval, TICK_INTERVAL: INTERVAL };
};

const interpolateData = (
  data,
  from,
  to,
  interpolationMethod,
  maxInterpolationDuration,
  previousDatum,
  nextDatum
) => {
  const dataByTimestamp = keyBy(data, "timestamp");

  const maxInterpolationTicks =
    maxInterpolationDuration == null
      ? Number.MAX_SAFE_INTEGER
      : maxInterpolationDuration < 0
      ? Number.MAX_SAFE_INTEGER
      : Math.ceil(maxInterpolationDuration / INTERVAL);

  const interpolatedData = [];
  from = from.toMillis();
  to = to.toMillis();
  let date = from;
  while (date < to) {
    const next = date + INTERVAL;

    const timestamp = next;

    interpolatedData.push({
      timestamp,
      value: dataByTimestamp[timestamp]?.value ?? null,
    });

    date = next;
  }

  let previousValue = previousDatum?.value;
  let previousIndex = previousDatum
    ? (previousDatum.timestamp - from) / INTERVAL
    : undefined;
  let maxNextIndex = nextDatum
    ? interpolatedData.length - 1 + (nextDatum.timestamp - to) / INTERVAL
    : undefined;
  for (let i = 0; i < interpolatedData.length; i++) {
    const datum = interpolatedData[i];
    if (datum.value == null) {
      if (interpolationMethod === "null") {
        interpolatedData[i].value = null;
      } else if (interpolationMethod === "zero") {
        interpolatedData[i].value = 0;
      } else if (
        interpolationMethod === "last" ||
        interpolationMethod === "prev" ||
        interpolationMethod === "previous"
      ) {
        interpolatedData[i].value = previousValue;
      } else {
        let nextIndex = i;
        let nextValue;
        while (true) {
          ++nextIndex;
          if (nextIndex >= interpolatedData.length) {
            break;
          }
          nextValue = interpolatedData[nextIndex].value;
          if (nextValue != null) {
            break;
          }
        }
        nextValue = nextValue ?? nextDatum?.value;
        nextIndex = nextValue != null ? nextIndex : maxNextIndex;
        if (nextIndex - previousIndex - 2 <= maxInterpolationTicks) {
          for (
            let currentIndex = i;
            currentIndex < nextIndex && currentIndex < interpolatedData.length;
            currentIndex++
          ) {
            if (nextValue == null) {
              interpolatedData[currentIndex].value = previousValue;
            } else if (previousValue == null) {
              interpolatedData[currentIndex].value = nextValue;
            } else {
              if (interpolationMethod === "or") {
                interpolatedData[currentIndex].value =
                  nextValue || previousValue;
              } else if (interpolationMethod === "and") {
                interpolatedData[currentIndex].value =
                  nextValue && previousValue;
              } else {
                interpolatedData[currentIndex].value =
                  (nextValue * (currentIndex - previousIndex)) /
                    (nextIndex - previousIndex) +
                  (previousValue * (nextIndex - currentIndex)) /
                    (nextIndex - previousIndex);
              }
            }
          }
        }
        i = nextIndex - 1;
      }
    } else {
      previousValue = datum.value;
      previousIndex = i;
    }
  }
  return interpolatedData;
};

const scaleDownDataTo = (
  data,
  ticksPerInterval,
  timeQuantizationAggregationMethod
) => {
  let scaledDownData = [];

  let at = 0;
  for (let i = 0; i < ticksPerInterval.length; i++) {
    const currentSlice = data.slice(at, at + ticksPerInterval[i]);
    at += ticksPerInterval[i];

    const nonNulls = currentSlice.filter((v) => v?.value != null);
    const nullsCount = currentSlice.length - nonNulls.length;

    const timestamp = last(currentSlice).timestamp;

    if (nullsCount === currentSlice.length) {
      scaledDownData.push({
        value: null,
        timestamp,
      });
    } else {
      let value;
      switch (timeQuantizationAggregationMethod) {
        case "sum":
          value = sumBy(nonNulls, "value");
          break;
        case "or":
          value = some(nonNulls, "value");
          break;
        case "and":
          value = !some(nonNulls, "value");
          break;
        case "max":
        case "maximum":
          value = Math.max(...nonNulls.map((v) => v?.value));
          break;
        case "min":
        case "minimum":
          value = Math.min(...nonNulls.map((v) => v?.value));
          break;
        case "last":
          value = nonNulls[nonNulls.length - 1]?.value;
          break;
        case "first":
          value = nonNulls[0]?.value;
          break;
        default:
          value = sumBy(nonNulls, "value") / nonNulls.length;
          break;
      }

      scaledDownData.push({
        value,
        timestamp,
      });
    }
  }

  scaledDownData = scaledDownData.map(({ timestamp, value }) => ({
    timestamp,
    value: isNaN(value) || value == null ? null : Math.max(0, value),
  }));

  return scaledDownData;
};

const getTelemetryResizeFunction = (defaultTelemetry) => {
  const sizeUp = (data, stdCount) => FormulasMath.sizeUp({ data }, stdCount);

  return function ({
    ticksPerInterval: ticksPerIntervalArg,
    minTicks,
    breakpoints,
    timeQuantizationAggregationMethod,
    stdCount,
  }) {
    const telemetry = this && this !== window ? this : defaultTelemetry;
    const { baseData, from, to, rawTicksTimestamps } = telemetry;
    const normalTicksPerInterval =
      ticksPerIntervalArg ??
      getNormalTicksPerInterval(
        rawTicksTimestamps.length,
        minTicks,
        breakpoints
      );
    const { ticksPerInterval, timestamps } = getTicksPerIntervalAndTimestamps(
      from,
      to,
      getTicksTimestamps(from, to),
      normalTicksPerInterval
    );
    const secondsPerInterval = ticksPerInterval.map(
      (ticks) => (ticks * INTERVAL) / 1000
    );
    return {
      ...telemetry,
      ...sizeUp(
        scaleDownDataTo(
          baseData,
          ticksPerInterval,
          timeQuantizationAggregationMethod ||
            telemetry.timeQuantizationAggregationMethod
        ),
        stdCount || telemetry.stdCount
      ),
      from,
      to,
      timestamps,
      T: secondsPerInterval,
      period: secondsPerInterval,
      secondsPerInterval,
      normalTicksPerInterval,
      normalSecondsPerInterval: (normalTicksPerInterval * INTERVAL) / 1000,
    };
  };
};

const parseTag = (display) => {
  const regex = /(<sub>[^<]*<\/sub>|<sup>[^<]*<\/sup>|[^<]+)/g;
  const result = display.match(regex);
  const filteredResult = result.filter(Boolean);
  const reactElements = filteredResult.map((item, index) => {
    if (item.startsWith("<sub>") && item.endsWith("</sub>")) {
      const content = item.substring(5, item.length - 6);
      return <sub key={index}>{content}</sub>;
    } else if (item.startsWith("<sup>") && item.endsWith("</sup>")) {
      const content = item.substring(5, item.length - 6);
      return <sup key={index}>{content}</sup>;
    } else {
      return item;
    }
  });

  return reactElements;
};

const parseDisplay = (display) => {
  return display ? parseTag(display) || display : null;
};

const setDisplay = (element, trim = false) => {
  if (!element.display) {
    element.display = trim ? element.name.trim() : element.name;
  } else {
    element.display = parseDisplay(
      trim ? element.display.trim() : element.display
    );
  }
};

const setColor = (entity) => {
  if (!entity.color) {
    return () => "transparent";
  }
  const color = JSON.parse(entity.color);
  entity.color = (alpha = 1) =>
    `rgba(${color[0]},${color[1]},${color[2]},${color[3] || alpha})`;
};

/**
 * @typedef {import('react').ReactNode | import('react').ReactNode[]} ReactChild
 * @typedef {string | ReactChild | ((variables?: any) => ReactChild)} I18nValue
 */

/**
 * @typedef {Object} ModelViewpoint
 * @property {string} name
 * @property {string} display
 * @property {[number,number,number]} position
 * @property {[number,number,number,number]} quaternion
 */
/**
 * @typedef {Object} ModelPoint
 * @property {string} name
 * @property {string} display
 * @property {[number,number,number]} coordinates
 */
/**
 * @typedef {Object} ModelMapping
 * @property {string} name
 * @property {string} display
 * @property {string[]} objectsIds
 * @property {ModelViewpoint[]} viewpoints
 * @property {ModelPoint[]} points
 */
/**
 * @typedef {Object} ModelEntityMapping
 * @property {string} entityId
 * @property {ModelMapping[]} mappings
 */
/**
 * @typedef {Object} ModelObjectGroup
 * @property {string} name
 * @property {string} display
 * @property {string[]} objectsIds
 */
/**
 * @typedef {Object} ModelDefenition
 * @property {string} url
 * @property {string} name
 * @property {string} display
 * @property {ModelViewpoint[]} viewpoints
 * @property {ModelPoint[]} points
 * @property {ModelEntityMapping[]} entitiesMappings
 * @property {ModelObjectGroup[]} objectsGroups
 */
/**
 * @typedef {Object} Unit
 * @property {string} id
 * @property {string} name
 * @property {string} display
 * @property {boolean} addSpace
 * @property {boolean} isPrefix
 */
/**
 * @typedef {Object} ConstantDefenition
 * @property {string} id
 * @property {string} name
 * @property {string} category
 * @property {string|number} value
 */
/**
 * @typedef {Object} Currency
 * @property {string} id
 * @property {string} name
 * @property {string} display
 * @property {boolean} addSpace
 * @property {boolean} isPrefix
 * @property {number} toUSD
 */
/**
 * @typedef {Object} Entity
 * @property {string} id
 * @property {string} [parentEntityId]
 * @property {Entity} [parentEntity]
 * @property {string} rootEntityId
 * @property {Entity} rootEntity
 * @property {Object<string,Entity | Entity[]>} connections
 * @property {ReactChild} display
 * @property {Record<string, I18nValue>} i18n
 * @property {Record<string, { display: ReactChild, i18n: Record<string, I18nValue> }>} displays
 * @property {string} type
 * @property {string} url
 * @property {number} [defaultInterval]
 * @property {string} [timezone]
 * @property {number} [startOfWeek]
 * @property {number[]} [weekendDays]
 * @property {number} [occupancyCapacity]
 * @property {string} [area]
 * @property {string[]} devices
 * @property {number} sortOrder
 * @property {string} currencyId
 * @property {Object} metadata
 * @property {string} name
 * @property {Model} models
 * @property {TelemetryDefenition[]} telemetries
 * @property {Object<string,TelemetryDefenition>} telemetriesByName
 * @property {Entity[]} children
 * @property {Object<string,Entity>} childrenById
 * @property {Object<string,Entity>} childrenByName
 * @property {Object<string,Entity>} childrenByUrl
 */

/**
 * @typedef {Object} TelemetryDefenition
 * @property {string} id
 * @property {number} timeseriesId
 * @property {string} name
 * @property {string | import('react').ReactElement} display
 * @property {string} [displayKey]
 * @property {Object<string, string | import('react').ReactElement>} i18n
 * @property {string} dataType
 * @property {string} unitId
 * @property {Unit} unit
 * @property {"base" | "resolution" | "dimension"} type
 * @property {string} [timeQuantizationAggregationMethod]
 * @property {string} [interpolationMethod]
 * @property {string} [maxInterpolationDuration]
 * @property {string} [downsamplingAggregationMethod]
 * @property {string} [upsamplingAggregationMethod]
 * @property {number} interval
 * @property {TelemetryDefenition} [baseTelemetry]
 * @property {string} [baseTelemetryId]
 * @property {string} [baseTimeseriesId]
 * @property {string} [resolutionKey]
 * @property {string} [dimensionAlias]
 * @property {string} [dimensionKey]
 * @property {[string,string][]} [dimensionEntries]
 * @property {Object<string,string>} [dimensionValues]
 * @property {TelemetryDefenition[]} resolutions
 * @property {Object<string,TelemetryDefenition[]>} resolutionsByKey
 * @property {TelemetryDefenition[]} dimensions
 * @property {Object<string,TelemetryDefenition>} dimensionsByKey
 * @property {Entity} [entity]
 * @property {string} [entityId]
 * @property {Entity} [rootEntity]
 * @property {string} [rootEntityId]
 */

/**
 * @typedef {Object} BuildingData
 * @property {string} timezone
 * @property {number} startOfWeek
 * @property {number[]} weekendDays
 * @property {Object<string,boolean>} isWeekendByDay
 * @property {function} isWithinWorkingHours
 * @property {number} defaultInterval
 * @property {Entity} building
 * @property {Entity[]} floors
 * @property {Object<string,Entity>} floorsById
 * @property {Object<string,Entity>} floorsByName
 * @property {Object<string,Entity>} floorsByUrl
 * @property {Entity[]} zones
 * @property {Object<string,Entity>} zonesById
 * @property {Object<string,Entity>} zonesByName
 * @property {Object<string,Entity>} zonesByUrl
 * @property {Entity[]} children
 * @property {Entity[]} entities
 * @property {Object<string,Entity>} entitiesById
 * @property {Object<string,Entity>} entitiesByName
 * @property {Object<string,Entity>} entitiesByUrl
 * @property {TelemetryDefenition[]} telemetries
 * @property {Object<string,TelemetryDefenition>} telemetriesById
 * @property {Object<string,TelemetryDefenition>} telemetriesById
 * @property {Unit[]} units
 * @property {Object<string,Unit>} unitsById
 * @property {Object<string,Unit>} unitsByName
 * @property {Object<string,Object<string,number|string>>} constants
 * @property {Object<string,number>} prices
 * @property {Currency} currency
 * @property {Object[]} telemetryTypes DEPRECATED: use telemetries instead
 * @property {Object[]} telemetryTypesById DEPRECATED: use telemetriesById instead
 * @property {Object[]} telemetryTypesByName DEPRECATED: use entity.telemetriesByName instead
 */

const postProcessDisplayable = (displayable) => {
  displayable.displays = displayable.displays ?? [];
  displayable.i18n = displayable.i18n ?? {};
  const displays = [...displayable.displays, displayable];
  const languagesCodes = getLanguages().map((lang) => lang.code);
  displays.forEach((object) => {
    object.i18n = object.i18n ?? {};
    const fallback =
      object.display ?? languagesCodes.find((code) => object.i18n[code]) ?? "";
    languagesCodes.forEach((code) => {
      object.i18n[code] = object.i18n[code] ?? fallback;
    });
    object.i18n = processTranslations(object.i18n);
  });
  displayable.displays = keyBy(displayable.displays, "name");
  displayable.displays.default = displayable.displays.default ?? {
    display: displayable.display,
    i18n: displayable.i18n,
  };
  displayable.displays[""] =
    displayable.displays[""] ?? displayable.displays.default;
};

const postProcessBuildingData = (rawBuildingData) => {
  const debug = window.para?.debug ?? !config.isProd;
  let {
    building,
    children,
    telemetries,
    telemetryTypes,
    units,
    constants,
    currency,
  } = rawBuildingData;
  const asJSON = JSON.stringify(rawBuildingData);

  let entities = orderBy([building, ...children], ["sortOrder"], ["asc"]);
  const entitiesById = keyBy(entities, "id");
  const entitiesByName = keyBy(entities, "name");
  const entitiesByUrl = keyBy(entities, "url");

  const defaultInterval =
    building.defaultInterval ??
    building.metadata.defaultInterval ??
    DEFAULT_INTERVAL;
  const timezone =
    building.timezone ?? building.metadata.timezone ?? DEFAULT_TIMEZONE;
  let startOfWeek = building.startOfWeek;
  let weekendDays = building.weekendDays;
  if (startOfWeek == null) {
    if (weekendDays?.length) {
      startOfWeek = (weekendDays[weekendDays.length - 1] + 1) % 7;
    } else {
      switch (timezone) {
        case "Africa/Cairo":
          startOfWeek = 7;
          break;
        default:
          startOfWeek = 1;
      }
    }
  }
  if (startOfWeek === 0) {
    startOfWeek = 7;
  }
  if (!weekendDays) {
    weekendDays = [(startOfWeek + 5) % 7, (startOfWeek + 6) % 7].map((day) =>
      day === 0 ? 7 : day
    );
  }
  const isWeekendByDay = mapValues(
    keyBy(weekendDays, (day) => day),
    () => true
  );

  const isWithinWorkingHours = (timestamp) => {
    const dateTime = fromMillis(timestamp, { zone: timezone });
    const day = dateTime.weekday;
    const hour = dateTime.hour;
    const minute = dateTime.minute;

    const isWeekend = isWeekendByDay[day];
    if (isWeekend) {
      return false;
    }

    const isWorkingHour =
      (hour >= 7 && hour < 17) || (hour === 17 && minute <= 30);
    return isWorkingHour;
  };

  building.timezone = timezone;
  building.startOfWeek = startOfWeek;
  building.weekendDays = weekendDays;
  building.isWeekendByDay = isWeekendByDay;
  building.isWithinWorkingHours = isWithinWorkingHours;
  building.defaultInterval = defaultInterval;

  entities.forEach((entity) => {
    setDisplay(entity);
    postProcessDisplayable(entity);
    setColor(entity);
    entity.children = [];
    entity.childrenById = {};
    entity.childrenByName = {};
    entity.childrenByUrl = {};
    entity.metadata = entity.metadata ?? {};
  });
  entities.forEach((entity) => {
    entity.connections = entity.connections ?? [];
    const connectionsDefenitions = mapEntires(
      groupBy(entity.connections, "type"),
      ([type, connections]) => {
        if (connections.length > 1 && debug) {
          debugger;
          throw new Error(
            `Connection "${type}" of "${entity.name}" has multiple(${
              connections.length
            }) connection defenitions! ${JSON.stringify(connections)}`
          );
        }
        const connection = connections[0];
        if (connection.entityId) {
          connection.entitiesIds = [connection.entityId];
          connection.isSingular = true;
        }
        return [type, connection];
      }
    );
    entity.connectionsDefenitions = connectionsDefenitions;

    let connections = Object.values(connectionsDefenitions).map(
      (connection) => {
        const connectedEntities = connection.entitiesIds
          .map((entityId) => {
            const connectedEntity = entitiesById[entityId];
            if (!connectedEntity && debug) {
              debugger;
              throw new Error(
                `Connection "${connection.type}" of "${entity.name}" with id "${entityId}" does not exist!`
              );
            }
            return connectedEntity;
          })
          .filter(Boolean);
        return {
          ...connection,
          entities: connectedEntities,
        };
      }
    );
    connections = groupBy(connections, "type");
    connections = Object.fromEntries(
      Object.entries(connections).map(([type, group]) => {
        const isSingular = group.find(({ isSingular }) => isSingular);
        const entities = flatten(group.map(({ entities }) => entities));
        if (isSingular && entities.length > 1 && debug) {
          debugger;
          throw new Error(
            `Connection "${type}" of "${entity.name}" with marked as singular yet has multiple connections!`
          );
        }
        return [type, isSingular ? entities[0] : entities];
      })
    );
    entity.connections = connections;
  });
  entities.forEach((entity) => {
    Object.entries(entity.connections).forEach(([type, connectedEntities]) => {
      try {
        const connectionDefenition = entity.connectionsDefenitions[type];
        if (!connectionDefenition) {
          return;
        }
        const reverseConnectionDefenition =
          connectionDefenition.reverseConnection ?? {};
        if (!isArray(connectedEntities)) {
          connectedEntities = [connectedEntities];
        }
        connectedEntities.forEach((connectedEntity) => {
          const reverseConnectionType =
            reverseConnectionDefenition.type ?? entity.type;
          if (
            connectedEntity.connectionsDefenitions[reverseConnectionType] &&
            debug
          ) {
            debugger;
            throw new Error(
              `Connection "${type}" of entity "${entity.name}" has a connection to ${connectedEntity.name} with reverseConnection type "${reverseConnectionType}. However ${connectedEntity.name} already has a connection ${reverseConnectionType} defined on it!!!"`
            );
          }
          if (reverseConnectionDefenition.isSingular) {
            if (connectedEntity.connections[reverseConnectionType] && debug) {
              debugger;
              throw new Error(
                `Reverse connection of entity "${entity.name}" and connection "${type}" is marked as singular but yet it has multiple connections setup!!!"`,
                {
                  connections: [
                    connectedEntity.connections[reverseConnectionType],
                    entity,
                  ],
                }
              );
            }
            connectedEntity.connections[reverseConnectionType] = entity;
          } else {
            const reverseConnections =
              connectedEntity.connections[reverseConnectionType] ??
              (connectedEntity.connections[reverseConnectionType] = []);
            if (!isArray(reverseConnections)) {
              if (debug) {
                debugger;
                throw new Error(
                  `Reverse connection "${reverseConnectionType}" of entity "${entity.name}" and connection "${type}" is not an array while it is marked as not singular!!!"`,
                  {
                    connections: [reverseConnections, entity],
                  }
                );
              } else {
                connectedEntity.connections[reverseConnectionType] = [
                  connectedEntity.connections[reverseConnectionType],
                ];
              }
            }
            reverseConnections.push(entity);
          }
        });
      } catch (err) {
        debugger;
        console.error(err);
      }
    });
  });
  entities.forEach((entity) => {
    entity.connections = mapEntires(
      entity.connections,
      ([type, connectedEntities]) => {
        if (isArray(connectedEntities)) {
          return [type, uniqBy(connectedEntities, "id")];
        }
        return [type, connectedEntities];
      }
    );
  });
  entities.forEach((entity) => {
    if (entity.parentEntityId) {
      const parentEntity = entitiesById[entity.parentEntityId];
      parentEntity.children.push(entity);
      parentEntity.childrenById[entity.id] = entity;
      parentEntity.childrenByName[entity.name] = entity;
      parentEntity.childrenByUrl[entity.url] = entity;
      entity.parentEntity = parentEntity;
    }
    entity.rootEntity = entitiesById[entity.rootEntityId] ?? building;
  });

  entities = flattenWithChildren(building);

  telemetries &&
    telemetries.forEach((telemetry) => {
      setDisplay(telemetry);
      postProcessDisplayable(telemetry);
      setColor(telemetry);
    });
  telemetryTypes &&
    telemetryTypes.forEach((telemetryType) => {
      setDisplay(telemetryType);
      setColor(telemetryType);
    });
  units.forEach((unit) => {
    setDisplay(unit, true);
    if (unit.enum?.length) {
      unit.enum = keyBy(unit.enum, "value");
    } else {
      delete unit.enum;
    }
  });
  setDisplay(currency);

  const floors = building.children;
  const floorsById = keyBy(floors, "id");
  const floorsByName = keyBy(floors, "name");
  const floorsByUrl = keyBy(floors, "url");
  building.floors = floors;

  const zones = flatten(floors.map(({ children }) => children));
  const zonesById = keyBy(zones, "id");
  const zonesByName = keyBy(zones, "name");
  const zonesByUrl = keyBy(zones, "url");
  floors.forEach((floor) => {
    floor.zones = floor.children;
  });

  const telemetryTypesById = telemetryTypes && keyBy(telemetryTypes, "id");
  const telemetryTypesByName = telemetryTypes && keyBy(telemetryTypes, "name");

  const telemetriesById = telemetries && keyBy(telemetries, "id");
  const telemetriesByTimeseriesId =
    telemetries && keyBy(telemetries, "timeseriesId");

  const unitsById = keyBy(units, "id");
  const unitsByName = keyBy(units, "name");

  telemetryTypes &&
    telemetryTypes.forEach((telemetryType) => {
      telemetryType.unit = unitsById[telemetryType.unitId];
    });
  telemetries &&
    telemetries.forEach((telemetry) => {
      telemetry.unit = unitsById[telemetry.unitId];
      if (telemetry.unit == null) {
        delete telemetry.unit;
      }
      if (telemetry.display == null) {
        delete telemetry.display;
      }
    });
  telemetries &&
    telemetries.forEach((telemetry) => {
      if (telemetry.baseTelemetryId) {
        const baseTelemetry = telemetriesById[telemetry.baseTelemetryId];
        const resolutionKey = telemetry.resolutionKey;

        if (telemetry.type === "resolution") {
          const resolutions =
            baseTelemetry.resolutions ?? (baseTelemetry.resolutions = []);
          const resolutionsByKey =
            baseTelemetry.resolutionsByKey ??
            (baseTelemetry.resolutionsByKey = {});

          resolutions.push(telemetry);

          if (!resolutionsByKey[resolutionKey]) {
            resolutionsByKey[resolutionKey] = [];
          }
          resolutionsByKey[resolutionKey].push(telemetry);

          telemetry.baseTimeseriesId = baseTelemetry.timeseriesId;
          telemetry.baseTelemetry = baseTelemetry;

          Object.assign(
            telemetry,
            Object.assign(
              {},
              omit(baseTelemetry, "resolutions", "dimensions"),
              telemetry
            )
          );
        } else if (telemetry.type === "dimension") {
          const dimensionKey = getStableKey(JSON.parse(telemetry.dimensionKey));
          const dimensionAlias = telemetry.dimensionAlias;
          const dimensions =
            baseTelemetry.dimensions ?? (baseTelemetry.dimensions = []);
          const dimensionsByKey =
            baseTelemetry.dimensionsByKey ??
            (baseTelemetry.dimensionsByKey = {});

          dimensions.push(telemetry);
          dimensionsByKey[dimensionKey] = telemetry;
          if (dimensionAlias != null) {
            dimensionsByKey[dimensionAlias] = telemetry;
          }

          telemetry.dimensionEntries = JSON.parse(dimensionKey);
          telemetry.dimensionValues = Object.fromEntries(
            telemetry.dimensionEntries
          );

          telemetry.baseTimeseriesId = baseTelemetry.timeseriesId;
          telemetry.baseTelemetry = baseTelemetry;

          Object.assign(
            telemetry,
            Object.assign(
              {},
              omit(baseTelemetry, "resolutions", "dimensions"),
              telemetry
            )
          );
        }
      }
    });

  constants = mapValues(groupBy(constants, "category"), (constantsGroups) =>
    mapValues(keyBy(constantsGroups, "name"), ({ value }) => value)
  );

  entities.forEach((entity) => {
    entity.telemetries = entity.telemetries.map((entityTelemetry) => {
      const telemetryType =
        telemetryTypesById?.[entityTelemetry.telemetryTypeId];
      const telemetry =
        telemetriesById?.[entityTelemetry.telemetryId] ??
        telemetriesById?.[entityTelemetry.telemetryTypeId] ??
        telemetriesByTimeseriesId?.[entityTelemetry.timeseriesId];

      telemetry.entity = entity;
      telemetry.rootEntity = entity.rootEntity;
      telemetry.entityId = entity.id;
      telemetry.rootEntityId = entity.rootEntity.id;
      telemetry.resolutions &&
        telemetry.resolutions.forEach((telemetry) => {
          telemetry.entity = entity;
          telemetry.rootEntity = entity.rootEntity;
          telemetry.entityId = entity.id;
          telemetry.rootEntityId = entity.rootEntity.id;
        });
      telemetry.dimensions &&
        telemetry.dimensions.forEach((telemetry) => {
          telemetry.entity = entity;
          telemetry.rootEntity = entity.rootEntity;
          telemetry.entityId = entity.id;
          telemetry.rootEntityId = entity.rootEntity.id;
        });

      entityTelemetry = {
        ...telemetryType,
        ...entityTelemetry,
        ...telemetry,
        name: entityTelemetry.name ?? telemetryType?.name ?? telemetry?.name,
        display:
          entityTelemetry.display ??
          telemetryType?.display ??
          telemetry?.display,
      };
      [
        "_id",
        "skipEmpty",
        "skipZero",
        "skipInvalid",
        "__v",
        "lastSync",
        "iotIntegration",
        "para",
      ].forEach((key) => {
        delete entityTelemetry[key];
      });
      return entityTelemetry;
    });
    entity.telemetriesByName = keyBy(entity.telemetries, "name");
  });

  const sizeUp = (data, stdCount) => FormulasMath.sizeUp({ data }, stdCount);

  const normalizeDate = (date) => {
    if (date instanceof DateTime) {
      return date;
    }
    if (typeof date === "number") {
      return fromMillis(date);
    }
    if (date instanceof Date) {
      return DateTime.fromJSDate(date);
    }
    if (typeof date === "string") {
      try {
        return DateTime.fromFormat(date, "yyyy-MM-dd.HH:mm:ss");
      } catch {
        try {
          return DateTime.fromFormat(date, "yyyy-MM-dd.HH:mm");
        } catch {
          return DateTime.fromFormat(date, "yyyy-MM-dd");
        }
      }
    }
    throw new Error("Invalid date sent");
  };
  const postProcessTelemetry = (telemetry, fromDate, toDate) => {
    fromDate = normalizeDate(fromDate ?? telemetry.from);
    toDate = normalizeDate(toDate ?? telemetry.to);

    const telemetryType =
      telemetryTypesByName?.[telemetry.telemetryName ?? telemetry.name];
    const baseTelemetry =
      entitiesById[telemetry.entityId]?.telemetriesByName[
        telemetry.telemetryName ?? telemetry.name
      ];
    telemetry = {
      ...telemetryType,
      ...telemetry,
      ...baseTelemetry,
      name: telemetry.name ?? telemetryType?.name ?? baseTelemetry?.name,
      from: fromDate,
      to: toDate,
    };

    const rawTicksTimestamps = getTicksTimestamps(fromDate, toDate);
    const rawTicksCount = rawTicksTimestamps.length;
    const normalTicksPerInterval = getNormalTicksPerInterval(rawTicksCount);

    const { ticksPerInterval, timestamps } = getTicksPerIntervalAndTimestamps(
      fromDate,
      toDate,
      rawTicksTimestamps,
      normalTicksPerInterval
    );

    const secondsPerInterval = ticksPerInterval.map(
      (ticks) => (ticks * INTERVAL) / 1000
    );

    const {
      interpolationMethod,
      maxInterpolationDuration,
      stdCount,
      timeQuantizationAggregationMethod,
    } = telemetry;

    const interpolatedData = interpolateData(
      telemetry.data,
      fromDate,
      toDate,
      interpolationMethod,
      maxInterpolationDuration,
      telemetry.previousDatum,
      telemetry.nextDatum
    );

    telemetry.baseData = interpolatedData;
    telemetry.tickDuration = INTERVAL;

    const resizeFunction = getTelemetryResizeFunction(telemetry);
    return {
      ...telemetry,
      ...sizeUp(
        scaleDownDataTo(
          interpolatedData,
          ticksPerInterval,
          timeQuantizationAggregationMethod
        ),
        stdCount
      ),
      timestamps,
      T: secondsPerInterval,
      days: getDays(fromDate, toDate),
      normalTicksPerInterval,
      normalSecondsPerInterval: (normalTicksPerInterval * INTERVAL) / 1000,
      resize(...args) {
        return resizeFunction.apply(this, args);
      },
    };
  };

  /** @deprecated */
  const __telemetriesByName = telemetries && keyBy(telemetries, "name");
  const buildingData = {
    ...constants,
    timezone,
    startOfWeek,
    weekendDays,
    isWeekendByDay,
    isWithinWorkingHours,
    defaultInterval,
    building,
    floors,
    floorsById,
    floorsByName,
    floorsByUrl,
    zones,
    zonesById,
    zonesByName,
    zonesByUrl,
    children,
    entities,
    entitiesById,
    entitiesByName,
    entitiesByUrl,
    telemetries,
    telemetriesById,
    /** @deprecated */
    get telemetryTypes() {
      console.warn(
        "telemetryTypes of buildingData is deprecated. use telemetries instead"
      );
      return telemetryTypes ?? telemetries;
    },
    /** @deprecated */
    get telemetryTypesById() {
      console.warn(
        "telemetryTypesById of buildingData is deprecated. use telemetriesById instead"
      );
      return telemetryTypesById ?? telemetriesById;
    },
    /** @deprecated */
    get telemetryTypesByName() {
      console.warn(
        "telemetryTypesByName of buildingData is deprecated. use entity.telemetriesByName instead"
      );
      return telemetryTypesByName ?? __telemetriesByName;
    },
    units,
    unitsById,
    unitsByName,
    constants,
    currency,
    postProcessTelemetry,
    toString: () => asJSON,
    toRaw: () => JSON.parse(asJSON),
  };
  window.buildingData = buildingData;
  return buildingData;
};

export default postProcessBuildingData;
