enum LiveViewTypeEnum {
  INSTANT = "instant",
  CUMULATIVE = "cumulative",
}

enum ConsumptionUnitEnum {
  POWER = "power",
  CURRENCY = "currency",
}

enum ConsumptionTypeEnum {
  TOTAL = "TOTAL",
  ELECTRICITY = "electricity",
  CHILLED_WATER = "chilled-water",
}

enum ElectricityConsumptionTypeEnum {
  TOTAL_ELECTRICITY = "total-electricity",
  HVAC = "hvac",
  LIGHTING = "lighting",
  OTHER_LOADS = "other-loads",
}

enum RightMainTabKey {
  OperationalSpending = "operational-spending",
  Recommendations = "recommendations",
  Insights = "insights",
}

export {
  RightMainTabKey,
  LiveViewTypeEnum,
  ConsumptionUnitEnum,
  ConsumptionTypeEnum,
  ElectricityConsumptionTypeEnum,
};
