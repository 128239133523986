import { certificates } from "./utils";
import Accordion from "atomic-components/atoms/Accordion";
import SingleCertificate from "./SingleCertificate/SingleCertificate";

const ComplianceTrackingPanel = () => {
  return (
    <>
      <Accordion>
        {certificates.map((certificate) => (
          <SingleCertificate certificate={certificate} />
        ))}
      </Accordion>
    </>
  );
};

export default ComplianceTrackingPanel;
