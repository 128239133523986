import classNames from "classnames";

type IncreaseProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  color?: string;
  background?: string;
};

const Increase = ({
  className,
  iconScale = 1,
  icon = true,
  color = "#0040FF",
  background = "#F0F3FF",
}: IncreaseProps) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        transform="rotate(-180 12 12)"
        fill={background}
        style={{
          fontSize: `${iconScale * 100}%`,
        }}
      />
      <path
        d="M11.2508 17.9999C11.2508 18.4141 11.5866 18.7499 12.0008 18.7499C12.415 18.7499 12.7508 18.4141 12.7508 17.9999L11.2508 17.9999ZM12.5311 5.46955C12.2382 5.17665 11.7633 5.17665 11.4705 5.46955L6.69748 10.2425C6.40459 10.5354 6.40459 11.0103 6.69748 11.3032C6.99037 11.5961 7.46525 11.5961 7.75814 11.3032L12.0008 7.06054L16.2434 11.3032C16.5363 11.5961 17.0112 11.5961 17.3041 11.3032C17.597 11.0103 17.597 10.5354 17.3041 10.2425L12.5311 5.46955ZM12.7508 17.9999L12.7508 5.99988L11.2508 5.99988L11.2508 17.9999L12.7508 17.9999Z"
        fill={color}
      />
    </svg>
  );
};

export default Increase;
