import { useCallback, useEffect, useId } from "react";
import { useTranslate } from "modules/language";
import type { FormStore } from "helpers/createFormStoreHook";
import type { ComplianceScoreValues } from "../../../types";
import { complianceCertificates } from "../../../utils";
import EvaluationWizardInputError from "../EvaluationWizardInputError/EvaluationWizardInputError";

import s from "./EvaluationWizardTargetSelect.module.scss";

type EvaluationWizardTargetSelectProps<COMPLIANCE_SCORE_KEY extends string> = {
  formStore: FormStore<ComplianceScoreValues<COMPLIANCE_SCORE_KEY>>;
  disabled?: boolean;
  required?: boolean;
};

const EvaluationWizardTargetSelect = <COMPLIANCE_SCORE_KEY extends string>({
  formStore,
  disabled,
  required = true,
}: EvaluationWizardTargetSelectProps<COMPLIANCE_SCORE_KEY>) => {
  const t = useTranslate();
  const inputElementId = useId();
  const errorElementId = `${inputElementId}-error`;
  const value = formStore.values["target" as COMPLIANCE_SCORE_KEY];
  const setValue =
    formStore.fieldActions["target" as COMPLIANCE_SCORE_KEY].setValue;
  const error = formStore.errors["target" as COMPLIANCE_SCORE_KEY];
  const setError =
    formStore.fieldActions["target" as COMPLIANCE_SCORE_KEY].setError;

  const validate = useCallback(
    (newValue?: string) => {
      if (!newValue && required) {
        setError(t("error.REQUIRED"));
        return;
      }

      setError("");
    },
    [required, t]
  );

  useEffect(() => {
    validate(value?.toString());

    return () => {
      setError("");
    };
  }, [validate, value]);

  return (
    <div>
      <label className={s.selectLabel} htmlFor={inputElementId}>
        {t("label.NEXT_TARGET")} <span className={s.required}>*</span>
      </label>
      <div className={s.selectInput}>
        <select
          required={required}
          disabled={disabled}
          id={inputElementId}
          value={value ? value.toString() : ""}
          onChange={(e) => {
            const newValue = e.target.value;
            validate(newValue);
            setValue(+newValue);
          }}
          aria-errormessage={errorElementId}
          aria-invalid={!!error}
        >
          {complianceCertificates.map(
            ({ isTheUncertified, min, certificateLabel, key }) => {
              if (isTheUncertified) {
                return (
                  <option key={key} value="" disabled selected hidden>
                    -
                  </option>
                );
              }

              return (
                <option key={key} value={min}>
                  {t(certificateLabel)}
                </option>
              );
            }
          )}
        </select>
      </div>
      {error ? (
        <EvaluationWizardInputError id={errorElementId}>
          {error}
        </EvaluationWizardInputError>
      ) : null}
    </div>
  );
};

export default EvaluationWizardTargetSelect;
export type { EvaluationWizardTargetSelectProps };
