import { useMergedNotifier } from "helpers/getNotifier";
import { useEditableParamsState } from "../pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import {
  useViewData,
  useViewState,
} from "../providers/ViewProvider/ViewProvider";
import useBuildingData from "./useBuildingData";
import useSelectedEntity from "./useSelectedEntity";
import { useMemoedLinkedData } from "./useLinkedData";
import { useUtilsEmissions } from "./useUtilsEmissions";

const useDomesticWaterEmissions = () => {
  const { buildingData } = useBuildingData();
  const selectedEntity = useSelectedEntity(buildingData);
  const {
    state: { domesticWaterEmissionsCF },
    Notifier: EPNotifier,
  } = useEditableParamsState();

  const { telemetries, Notifier: TNotifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        telemetries: [{ telemetryName: "monthlyWaterUsage" }],
      },
    ],
    [selectedEntity]
  );

  const { monthlyWaterUsage } = telemetries[selectedEntity.id];

  const totalDomesticWaterUsage = monthlyWaterUsage.data[0]?.value ?? NaN;
  const totalDomesticWaterEmissions =
    totalDomesticWaterUsage * (domesticWaterEmissionsCF.value ?? NaN);

  const Notifier = useMergedNotifier(EPNotifier, TNotifier);

  /* EMISSIONS BY YEAR */

  const { fromTs } = useViewState();
  const {
    transformYearEmissions,
    getValues,
    yearFromTimeStamp,
    calculateFromAndTo,
  } = useUtilsEmissions();

  const yearSelected = yearFromTimeStamp(fromTs);
  const timeSelected = calculateFromAndTo(yearSelected);

  const { telemetries: telemetriesByYear } = useMemoedLinkedData(
    timeSelected.firstDay,
    timeSelected.lastDay,
    () => {
      return [
        {
          entityId: selectedEntity.id,
          telemetries: [
            {
              telemetryName: "monthlyWaterUsage",
            },
          ],
        },
      ];
    },
    [selectedEntity.id]
  );

  const waterUsageByYearFinal = transformYearEmissions(
    telemetriesByYear[selectedEntity.id].monthlyWaterUsage.data
  );

  const waterUsageByYear = getValues(waterUsageByYearFinal);
  const totalDomesticWaterEmissionsByYear = waterUsageByYear.map(
    (item) => item * (domesticWaterEmissionsCF.value ?? NaN)
  );

  return {
    totalDomesticWaterEmissions,
    totalDomesticWaterUsage,
    Notifier,
    totalDomesticWaterEmissionsByYear,
  };
};

export { useDomesticWaterEmissions };
