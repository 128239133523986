import Section from "atomic-components/atoms/Section";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import { useComplianceScenarios } from "modules/building/hooks/useComplianceScenarios";
import { useComplianceScoreValues } from "modules/building/hooks/useComplianceScoreValues";
import {
  complianceScoresTotalContent,
  getCertificate,
} from "modules/building/pages/SustainabilityPage/utils";
import { useTranslate } from "modules/language";
import { getScenarioTotalScore } from "../Scenarios/ScenariosList/ScenariosList";
import { Nullable } from "types/utils";

import s from "./CertificateInfoSummary.module.scss";

type CertificateInfoSummaryProps = {
  targetScore?: Nullable<number>;
};

const CertificateInfoSummary = ({
  targetScore: passedTargetScore,
}: CertificateInfoSummaryProps) => {
  const t = useTranslate();
  const { data } = useComplianceScoreValues();
  const { activeScenario } = useComplianceScenarios();

  const filledInTargetScore = data.target;
  const activeScenarioScore = getScenarioTotalScore(activeScenario);

  const targetCertificate = getCertificate({
    value: passedTargetScore || activeScenarioScore || filledInTargetScore,
  });
  const currentCertificate = getCertificate({
    value: data.total,
  });

  return (
    <Section>
      <WidgetContainer>
        <KeyValue
          isLight
          keyText={
            <b>
              {t("label.TARGET")}{" "}
              {activeScenario && !passedTargetScore ? (
                <>
                  (
                  {t("label.ACTIVE_[x]", {
                    x: activeScenario.title,
                  })}
                  )
                </>
              ) : null}
            </b>
          }
          value={
            targetCertificate ? t(targetCertificate.certificateLabel) : "-"
          }
          textColor="primary"
          size="regular"
          valueClassName={s.targetValue}
        />
      </WidgetContainer>
      <WidgetContainer>
        <KeyValue
          isLight
          keyText={<b>{t("label.CREDITS_ESTIMATE")}</b>}
          value={
            <>
              {data.total ?? "-"}/{complianceScoresTotalContent.maximumRequired}
            </>
          }
          textColor="primary"
          size="regular"
        />
      </WidgetContainer>
      <WidgetContainer>
        <KeyValue
          isLight
          keyText={<b>{t("label.CURRENT_STATUS")}</b>}
          value={
            currentCertificate ? t(currentCertificate.certificateLabel) : "-"
          }
          textColor="primary"
          size="regular"
          valueClassName={s.currentValue}
        />
      </WidgetContainer>
    </Section>
  );
};

export default CertificateInfoSummary;
