import { isFunction, isString } from "lodash";
import { ReactNode } from "react";

const { useSelectedLanguageState } = require("modules/language");

type BaseDisplayObject<VARIABLES_TYPE> = {
  i18n: Record<string, Child | ((variables?: VARIABLES_TYPE) => Child)>;
  display?: Child;
  name?: string;
};
type DisplayObject<VARIABLES_TYPE> = BaseDisplayObject<VARIABLES_TYPE> & {
  displays: Record<string, BaseDisplayObject<VARIABLES_TYPE>>;
};

type Child = ReactNode | ReactNode[];
type DisplayerProps<VARIABLES_TYPE = any, DISPLAY_PROPS_TYPE = any> =
  | { object: null | undefined; variables: never; displayProps: never }
  | {
      object: DisplayObject<VARIABLES_TYPE>;
      displayVariantName?: string;
      variables?: VARIABLES_TYPE;
      displayProps?: never;
    }
  | {
      object: BaseDisplayObject<VARIABLES_TYPE>;
      variables?: VARIABLES_TYPE;
      displayProps?: never;
    }
  | {
      object: {
        displayKey: string;
      };
      variables?: VARIABLES_TYPE;
      displayProps?: never;
    }
  | {
      object: { Display: React.ComponentType<DISPLAY_PROPS_TYPE> };
      variables?: never;
      displayProps?: any;
    }
  | {
      object: { display: Child };
      variables?: never;
      displayProps?: never;
    }
  | {
      object: { name: string };
      variables?: never;
      displayProps?: never;
    }
  | {
      object: string;
      variables?: never;
      displayProps?: never;
    };
type Displayable<BASE_TYPE> = BASE_TYPE & DisplayerProps["object"];

const Displayer = <
  VARIABLES_TYPE extends Record<string, any>,
  DISPLAY_PROPS_TYPE extends Record<string, any>
>({
  object,
  variables,
  displayProps,
  ...props
}: DisplayerProps<VARIABLES_TYPE, DISPLAY_PROPS_TYPE>) => {
  const { languageCode: selectedLanguageCode, t } = useSelectedLanguageState();
  if (!object) {
    return null;
  }
  if (isString(object)) {
    return object;
  }
  if ("Display" in object) {
    const Display = object.Display;
    return <Display {...displayProps} />;
  }
  if ("displayKey" in object) {
    return t(object.displayKey, variables);
  }
  let displayable = object;
  if ("displays" in object && "displayVariantName" in props) {
    displayable = object.displays?.[props.displayVariantName ?? ""] ?? object;
  }
  if ("i18n" in displayable && displayable.i18n) {
    const i18n = displayable.i18n;
    const translated = i18n[selectedLanguageCode];
    if (isFunction(translated)) {
      return translated(variables);
    }
    if (!translated) {
      return displayable.display ?? displayable.name ?? null;
    }
    return translated;
  }
  return "display" in displayable
    ? displayable.display
    : displayable.name ?? null;
};

export default Displayer;
export type { DisplayerProps, Displayable };
