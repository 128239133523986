import classNames from "classnames";
import React from "react";

const AHU = ({ className, iconScale = 1, icon = true }) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M5.51,6.58c.57,0,1.03-.46,1.03-1.03s-.46-1.03-1.03-1.03-1.03,.46-1.03,1.03,.46,1.03,1.03,1.03Z"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M10.68,1.07c0-.38-.31-.69-.69-.69"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M.34,1.07C.34,.69,.65,.38,1.03,.38"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M10.68,10.02c0,.38-.31,.69-.69,.69"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M.34,10.02c0,.38,.31,.69,.69,.69"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M10.68,1.07V10.02"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M.34,1.07V10.02"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M1.03,.38H9.99"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M9.99,10.71H1.03"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M9.3,1.75h0"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M1.72,1.75h0"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M9.3,9.33h0"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M1.72,9.33h0"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M5.51,9.33c2.09,0,3.79-1.7,3.79-3.79s-1.7-3.79-3.79-3.79S1.72,3.45,1.72,5.54s1.7,3.79,3.79,3.79Z"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M5.51,4.51c0-.76-.46-1.38-1.03-1.38"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M5.51,6.58c0,.76,.46,1.38,1.03,1.38"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M6.54,5.54c.76,0,1.38-.46,1.38-1.03"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M4.48,5.54c-.76,0-1.38,.46-1.38,1.03"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M4.78,4.81c-.54-.54-1.3-.65-1.71-.24"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M6.24,6.27c.54,.54,1.3,.65,1.71,.24"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M6.24,4.81c.54-.54,.65-1.3,.24-1.71"
      />
      <path
        fill="none"
        stroke="#7a7a7a"
        strokeMiterlimit="10"
        strokeWidth=".47px"
        d="M4.78,6.27c-.54,.54-.65,1.3-.24,1.71"
      />
    </svg>
  );
};

export default AHU;
