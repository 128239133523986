import CollapsibleContainer from "atomic-components/molecules/CollapsibleContainer/CollapsibleContainer";
import { useTranslate } from "modules/language";
import BreakdownTitle from "../BreakdownTitle/BreakdownTitle";
import TravelScopeBreakdown from "./TravelScopeBreakdown/TravelScopeBreakdown";
import TravelTotalEmissions from "./TravelTotalEmissions/TravelTotalEmissions";
import TravelCompanyOwnedVehicles from "./TravelCompanyOwnedVehicles/TravelCompanyOwnedVehicles";
import TravelDailyEmployeeCommute from "./TravelDailyEmployeeCommute/TravelDailyEmployeeCommute";
import TravelAssumptions from "./TravelAssumptions/TravelAssumptions";

type TravelBreakdownProps = {
  scopes: string[];
};

const TravelBreakdown = ({ scopes }: TravelBreakdownProps) => {
  const t = useTranslate();

  return (
    <CollapsibleContainer
      titleFontWeight="normal"
      title={<BreakdownTitle label={t("label.TRAVEL")} scopes={scopes} />}
      hasExtraSpacing
    >
      <TravelScopeBreakdown />
      <TravelTotalEmissions />
      <TravelCompanyOwnedVehicles />
      <TravelDailyEmployeeCommute />
      <TravelAssumptions />
    </CollapsibleContainer>
  );
};

export default TravelBreakdown;
