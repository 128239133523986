import { useMergedNotifier } from "helpers/getNotifier";
import { useChilledWaterEmissions } from "./useChilledWaterEmissions";
import { useDomesticWaterEmissions } from "./useDomesticWaterEmissions";
import { useElectricityEmissisons } from "./useElectricityEmissions";
import { useFuelEmissions } from "./useFuelEmissions";
import { useRefrigerantEmissions } from "./useRefrigerantEmissions";
import { useTravelEmissions } from "./useTravelEmissions";
import { useWasteEmissisons } from "./useWasteEmissions";

const useTotalEmissions = () => {
  const { totalElectricityEmissions, Notifier: ENotifier } =
    useElectricityEmissisons();
  const { totalChilledWaterEmissions, Notifier: CWNotifier } =
    useChilledWaterEmissions();
  const {
    totalTravelEmissions,
    companyVehiclesEmissions,
    employeeVehiclesEmissions,
    flightEmissions,
    Notifier: TRVNotifier,
  } = useTravelEmissions();
  const { tewiTotal, Notifier: RFNotifier } = useRefrigerantEmissions();
  const { totalFuelEmissions, Notifier: FNotifier } = useFuelEmissions();
  const { totalWasteEmissions, Notifier: WNotifier } = useWasteEmissisons();
  const { totalDomesticWaterEmissions, Notifier: DWNotifier } =
    useDomesticWaterEmissions();

  const Notifier = useMergedNotifier(
    ENotifier,
    CWNotifier,
    TRVNotifier,
    RFNotifier,
    FNotifier,
    WNotifier,
    DWNotifier
  );

  const emissionsByKey: { [key: string]: number } = {
    electricity: totalElectricityEmissions,
    chilledWater: totalChilledWaterEmissions,
    travel: totalTravelEmissions,
    refrigerantLeakage: tewiTotal,
    consumables: 0, // missing
    fuel: totalFuelEmissions,
    waste: totalWasteEmissions,
    domesticWater: totalDomesticWaterEmissions,
  };

  const emissionsByScope = {
    s1: companyVehiclesEmissions + tewiTotal + totalFuelEmissions,
    s2: totalElectricityEmissions + totalChilledWaterEmissions,
    s3:
      employeeVehiclesEmissions +
      flightEmissions +
      totalDomesticWaterEmissions +
      totalWasteEmissions, // consumables are missing
  };
  return {
    Notifier,
    emissionsByKey,
    emissionsByScope,
    maxEmissions: Object.values(emissionsByKey).reduce((acc, curr) => {
      return curr > acc ? curr : acc;
    }, 0),
    totalEmissions: Object.values(emissionsByKey).reduce((acc, curr) => {
      return acc + curr;
    }, 0),
  };
};

export { useTotalEmissions };
