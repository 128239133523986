import withIconProps from "helpers/withIconProps";

const MinimizeIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 10C20.75 10.4142 20.4142 10.75 20 10.75L14 10.75C13.5858 10.75 13.25 10.4142 13.25 10V4C13.25 3.58579 13.5858 3.25 14 3.25C14.4142 3.25 14.75 3.58579 14.75 4V9.25L20 9.25C20.4142 9.25 20.75 9.58579 20.75 10Z"
        fill="#C3C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20.75C9.58579 20.75 9.25 20.4142 9.25 20L9.25 14.75H4C3.58579 14.75 3.25 14.4142 3.25 14C3.25 13.5858 3.58579 13.25 4 13.25H10C10.4142 13.25 10.75 13.5858 10.75 14L10.75 20C10.75 20.4142 10.4142 20.75 10 20.75Z"
        fill="#C3C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4697 10.5303C13.1768 10.2374 13.1768 9.76256 13.4697 9.46967L20.4697 2.46967C20.7626 2.17678 21.2374 2.17678 21.5303 2.46967C21.8232 2.76256 21.8232 3.23744 21.5303 3.53033L14.5303 10.5303C14.2374 10.8232 13.7626 10.8232 13.4697 10.5303Z"
        fill="#C3C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.46967 21.5303C2.17678 21.2374 2.17678 20.7626 2.46967 20.4697L9.46967 13.4697C9.76256 13.1768 10.2374 13.1768 10.5303 13.4697C10.8232 13.7626 10.8232 14.2374 10.5303 14.5303L3.53033 21.5303C3.23744 21.8232 2.76256 21.8232 2.46967 21.5303Z"
        fill="#C3C4C4"
      />
    </svg>
  );
});

export default MinimizeIcon;
