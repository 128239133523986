import classNames from "classnames";

type HandIconOverrideProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  color?: string;
  strokeWidth?: number;
};

const HandIconOverride = ({
  className,
  iconScale = 1,
  icon = true,
  color = "#1F2937",
  strokeWidth = 0.763636,
}: HandIconOverrideProps) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
    >
      <path
        d="M18.6209 11.3792V6.20678C18.6209 5.65805 18.4029 5.13181 18.0149 4.7438C17.6269 4.35579 17.1007 4.13781 16.552 4.13781C16.0032 4.13781 15.477 4.35579 15.089 4.7438C14.701 5.13181 14.483 5.65805 14.483 6.20678M14.483 10.3447V4.13781C14.483 3.58909 14.265 3.06284 13.877 2.67483C13.489 2.28683 12.9627 2.06885 12.414 2.06885C11.8653 2.06885 11.339 2.28683 10.951 2.67483C10.563 3.06284 10.3451 3.58909 10.3451 4.13781V6.20678M10.3451 6.20678V10.862M10.3451 6.20678C10.3451 5.65805 10.1271 5.13181 9.73907 4.7438C9.35106 4.35579 8.82481 4.13781 8.27609 4.13781C7.72737 4.13781 7.20112 4.35579 6.81311 4.7438C6.4251 5.13181 6.20712 5.65805 6.20712 6.20678V14.4826M18.6209 8.27574C18.6209 7.72702 18.8389 7.20077 19.2269 6.81276C19.6149 6.42476 20.1411 6.20678 20.6899 6.20678C21.2386 6.20678 21.7648 6.42476 22.1528 6.81276C22.5408 7.20077 22.7588 7.72702 22.7588 8.27574V14.4826C22.7588 16.6775 21.8869 18.7825 20.3349 20.3346C18.7829 21.8866 16.6779 22.7585 14.483 22.7585H12.414C9.51745 22.7585 7.75883 21.8688 6.21745 20.3378L2.49331 16.6137C2.13738 16.2195 1.94668 15.7035 1.96068 15.1726C1.97468 14.6417 2.19233 14.1364 2.56854 13.7616C2.94475 13.3867 3.45073 13.1708 3.9817 13.1587C4.51267 13.1466 5.02796 13.3391 5.4209 13.6964L7.24159 15.5171"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="13.4473"
        y="13.4482"
        width="12.4138"
        height="16.5517"
        fill="white"
      />
      <path
        d="M18.6914 14.6407C18.6914 14.7113 18.6971 14.7847 18.6971 14.8553C18.6971 18.7125 18.6971 22.5726 18.6999 26.4298C18.6999 26.5851 18.6632 26.6952 18.5446 26.8025C18.0448 27.26 17.5535 27.7259 17.0593 28.189C17.048 28.2003 17.0311 28.2088 17.0141 28.2172C17.0057 28.2088 16.9944 28.2059 16.9831 28.2116C16.9831 28.1467 16.9774 28.0789 16.9774 28.0139C16.9774 24.1369 16.9774 20.2599 16.9746 16.3829C16.9746 16.2474 17.0113 16.1514 17.113 16.0582C17.5648 15.6459 18.0081 15.2224 18.4543 14.8045C18.5192 14.7423 18.5898 14.6887 18.6576 14.6294C18.6689 14.6322 18.6802 14.635 18.6914 14.6379V14.6407Z"
        fill={color}
      />
      <path
        d="M14.1424 28.8246C14.1424 28.7286 14.1367 28.6326 14.1367 28.5366C14.1367 25.6169 14.1367 22.6943 14.1367 19.7717C14.1367 19.7124 14.1367 19.6531 14.1367 19.5853C14.5207 19.2239 14.9048 18.8625 15.2916 18.5039C15.4667 18.3401 15.6446 18.1819 15.8225 18.021L15.8338 18.0295L15.8479 18.0238C15.8479 18.0888 15.8536 18.1565 15.8536 18.2215C15.8536 21.1723 15.8536 24.1259 15.8564 27.0767C15.8564 27.2123 15.8168 27.3055 15.7152 27.3986C15.2154 27.8561 14.7241 28.3248 14.2299 28.7879C14.213 28.8049 14.1904 28.8162 14.1734 28.8275C14.165 28.8162 14.1565 28.8133 14.1452 28.819L14.1424 28.8246Z"
        fill={color}
      />
      <path
        d="M23.8511 18.495H24.4158C24.4186 18.543 24.4243 18.591 24.4243 18.6362C24.4243 20.8641 24.4243 23.0892 24.4243 25.3172C24.4243 25.4216 24.3932 25.4951 24.317 25.5657C23.8115 26.0344 23.3089 26.5088 22.8063 26.9804C22.7865 27.0001 22.7611 27.0171 22.7188 27.0481V19.6471H23.275C23.2835 19.4466 23.2779 19.2574 23.2779 19.0682L23.2722 19.0739C23.4614 19.0682 23.6506 19.0626 23.8539 19.0569V18.4922L23.8482 18.4978L23.8511 18.495Z"
        fill={color}
      />
      <path
        d="M21.5541 20.8955V21.0903C21.5541 22.6858 21.5541 24.2812 21.5541 25.8794C21.5541 25.998 21.5202 26.0799 21.4355 26.159C20.9583 26.5995 20.4895 27.0428 20.0151 27.4861C19.9728 27.5257 19.9276 27.5652 19.8655 27.6217C19.857 27.5708 19.8457 27.5341 19.8457 27.4974C19.8457 25.8709 19.8457 24.2445 19.8457 22.618C19.8457 22.5361 19.8626 22.474 19.9276 22.4147C20.4613 21.9205 20.9893 21.4235 21.5512 20.8955H21.5541Z"
        fill={color}
      />
      <path
        d="M23.8494 16.1851V15.626H24.4085V16.1794H23.8438L23.8494 16.1851Z"
        fill={color}
      />
      <path
        d="M23.8444 16.1797C23.8444 16.3745 23.85 16.5665 23.8528 16.7614L23.8585 16.7557H23.2881V16.1966C23.4716 16.1938 23.6608 16.191 23.85 16.1853L23.8444 16.1797Z"
        fill={color}
      />
      <path
        d="M23.8535 16.7615H24.4126V17.3234H23.8592C23.8592 17.1342 23.8592 16.9451 23.8592 16.7559L23.8535 16.7615Z"
        fill={color}
      />
      <path
        d="M23.2666 17.9242H22.7188V17.3481H23.275C23.275 17.5402 23.2891 17.7322 23.2637 17.927L23.2694 17.9214L23.2666 17.9242Z"
        fill={color}
      />
      <path
        d="M23.2627 17.93H23.85C23.85 18.1163 23.85 18.3055 23.85 18.4975L23.8557 18.4919C23.6608 18.4919 23.4688 18.4919 23.274 18.4919L23.2796 18.4975C23.2768 18.3055 23.274 18.1163 23.2683 17.9243L23.2627 17.93Z"
        fill={color}
      />
      <path
        d="M23.2768 19.0682C23.0932 19.0626 22.9097 19.0541 22.7148 19.0485V18.4978C22.9012 18.4978 23.0876 18.4978 23.2768 18.4978L23.2711 18.4922C23.2711 18.687 23.2711 18.879 23.2711 19.0739L23.2768 19.0682Z"
        fill={color}
      />
      <path
        d="M23.8535 14.9963V14.4824H24.4098V15.0274C24.2375 15.05 24.0625 15.0387 23.8846 15.0359C23.8733 15.0218 23.862 15.0105 23.8535 14.9963Z"
        fill={color}
      />
    </svg>
  );
};

export default HandIconOverride;
