import withIconProps from "helpers/withIconProps";

const AlarmIcon = withIconProps((props) => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.72709 3.22703C8.99296 1.96116 10.7098 1.25 12.5001 1.25C14.2903 1.25 16.0072 1.96116 17.273 3.22703C18.5389 4.4929 19.2501 6.20979 19.2501 8C19.2501 11.3895 19.9754 13.5135 20.6585 14.7659C21.0011 15.394 21.3371 15.8104 21.5758 16.0623C21.6953 16.1885 21.7909 16.2739 21.8513 16.3242C21.8815 16.3494 21.903 16.3658 21.9142 16.3742L21.9229 16.3805C22.1931 16.5649 22.3129 16.9036 22.2179 17.2173C22.1221 17.5337 21.8306 17.75 21.5001 17.75H3.50006C3.16956 17.75 2.87801 17.5337 2.78224 17.2173C2.68726 16.9036 2.80703 16.5649 3.07726 16.3805L3.08589 16.3742C3.09716 16.3658 3.11861 16.3494 3.14883 16.3242C3.20925 16.2739 3.30483 16.1885 3.42434 16.0623C3.66298 15.8104 3.99904 15.394 4.34164 14.7659C5.02476 13.5135 5.75006 11.3895 5.75006 8C5.75006 6.20979 6.46122 4.4929 7.72709 3.22703ZM5.18988 16.25H19.8102C19.6568 16.0265 19.4987 15.7721 19.3416 15.4841C18.5248 13.9865 17.7501 11.6105 17.7501 8C17.7501 6.60761 17.1969 5.27225 16.2124 4.28769C15.2278 3.30312 13.8924 2.75 12.5001 2.75C11.1077 2.75 9.77231 3.30312 8.78775 4.28769C7.80318 5.27226 7.25006 6.60761 7.25006 8C7.25006 11.6105 6.47536 13.9865 5.65848 15.4841C5.5014 15.7721 5.34336 16.0265 5.18988 16.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3937 20.3512C10.752 20.1434 11.211 20.2654 11.4188 20.6237C11.5287 20.8131 11.6864 20.9703 11.8762 21.0796C12.0659 21.1889 12.2811 21.2465 12.5001 21.2465C12.719 21.2465 12.9342 21.1889 13.1239 21.0796C13.3137 20.9703 13.4714 20.8131 13.5813 20.6237C13.7891 20.2654 14.2481 20.1434 14.6064 20.3512C14.9647 20.5591 15.0866 21.018 14.8788 21.3763C14.6371 21.7931 14.2901 22.139 13.8726 22.3794C13.4551 22.6199 12.9818 22.7465 12.5001 22.7465C12.0183 22.7465 11.545 22.6199 11.1275 22.3794C10.71 22.139 10.363 21.7931 10.1213 21.3763C9.91347 21.018 10.0354 20.5591 10.3937 20.3512Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default AlarmIcon;
