import withIconProps from "helpers/withIconProps";

const AlarmWithSoundIcon = withIconProps((props) => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00844 12.25C6.10608 12.4276 6.24962 12.5757 6.42406 12.6789C6.5985 12.782 6.79745 12.8364 7.00011 12.8364C7.20277 12.8364 7.40171 12.782 7.57616 12.6789C7.7506 12.5757 7.89414 12.4276 7.99178 12.25M2.33341 1.16666C1.63341 2.15832 1.16675 3.32499 1.16675 4.66666M12.8334 4.66666C12.8334 3.32499 12.3667 2.15832 11.6667 1.16666M3.50008 4.66666C3.50008 3.7384 3.86883 2.84816 4.52521 2.19178C5.18158 1.53541 6.07182 1.16666 7.00008 1.16666C7.92834 1.16666 8.81858 1.53541 9.47496 2.19178C10.1313 2.84816 10.5001 3.7384 10.5001 4.66666C10.5001 8.74999 12.2501 9.91666 12.2501 9.91666H1.75008C1.75008 9.91666 3.50008 8.74999 3.50008 4.66666Z"
        stroke="currentColor"
        stroke-width="1 "
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default AlarmWithSoundIcon;
