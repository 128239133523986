import type {
  ComplianceScoreKey,
  ComplianceScoreValues,
} from "modules/building/api/complianceScores";
import { useEnhancedRefrigerantManagementFallbackValue } from "./useEnhancedRefrigerantManagementFallbackValue";
import { useTransportationPerformanceFallbackValue } from "./useTransportationPerformanceFallbackValue";
import {
  calculateComplianceScoresEnergyAndAtmosphere,
  calculateComplianceScoresTotal,
} from "modules/building/helpers/compliaceScores";

const useComplianceScoreFallbackValues = ({
  fetchedValues,
}: {
  fetchedValues?: ComplianceScoreValues;
}): {
  isLoading: boolean;
  isError: boolean;
  values: Partial<{
    [key in ComplianceScoreKey]: number | null;
  }>;
  /**
   * totals scores recomputed that depend on fallback values
   */
  dependents: Partial<{
    [scoreKey in ComplianceScoreKey]: Partial<{
      [key in ComplianceScoreKey]: number | null;
    }>;
  }>;
} => {
  const {
    fallbackValue: transportationPerformance,
    isLoading: transportationPerformanceLoading,
    error: transportationPerformanceError,
  } = useTransportationPerformanceFallbackValue();
  const {
    fallbackValue: enhancedRefrigerant,
    isLoading: enhancedRefrigerantLoading,
    error: enhancedRefrigerantError,
  } = useEnhancedRefrigerantManagementFallbackValue();

  const fallbackValues = {
    transportationPerformance,
    enhancedRefrigerant,
  };

  const energyAndAtmosphere = calculateComplianceScoresEnergyAndAtmosphere({
    values: fetchedValues || {},
    fallbackValues,
  });

  const total = calculateComplianceScoresTotal({
    values: fetchedValues || {},
    fallbackValues,
  });

  return {
    isLoading: transportationPerformanceLoading || enhancedRefrigerantLoading,
    isError: !!transportationPerformanceError || !!enhancedRefrigerantError,
    values: {
      transportationPerformance,
      enhancedRefrigerant,
    },
    dependents: {
      transportationPerformance: {
        transportation: transportationPerformance,
        total,
      },
      enhancedRefrigerant: {
        energyAndAtmosphere: energyAndAtmosphere,
        total,
      },
    },
  };
};

export { useComplianceScoreFallbackValues };
