import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { usePageState } from "../../ModelPageLayout";
import GraphEmissions from "../GraphEmissions/GraphEmissions";

import s from "./GraphPanel.module.scss";

const initPanelHeight = 425;
const propsGraph = {
  barsCategoryCount: 12,
  barsPerCategory: 1,
  categoriesSpacing: 80,
  stacksCount: 3,
};
const GraphPanel = () => {
  const { setShownBottomPanel } = usePageState();
  const [height] = useState(initPanelHeight);

  return (
    <div className={s.container} style={{ height }}>
      <button className={s.closeBtn} onClick={() => setShownBottomPanel(null)}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <GraphEmissions
        barsCategoryCount={propsGraph.barsCategoryCount}
        barsPerCategory={propsGraph.barsPerCategory}
        categoriesSpacing={propsGraph.categoriesSpacing}
        stacksCount={propsGraph.stacksCount}
      />
    </div>
  );
};

export default GraphPanel;
