import { useEffect, useRef } from "react";
import { useModelState } from "../../Model";

const CenterCameraOnEntity = ({
  entityId,
  mappingName = "default",
  viewpointName = "default",
  centerName = "center",
  lock = false,
}) => {
  const { getEntityMappingByEntityId, cameraControllerRef } = useModelState();

  const entityModelMapping = getEntityMappingByEntityId(entityId, mappingName);

  const mutable = useRef({}).current;
  mutable.prevViewpoint =
    mutable.prevViewpoint ?? cameraControllerRef.current.getViewpoint();

  useEffect(() => {
    if (!entityModelMapping) {
      return;
    }
    const cameraController = cameraControllerRef.current;
    const viewpoint = entityModelMapping.viewpoints?.find(
      ({ name }) => name === viewpointName
    );
    if (!viewpoint) {
      return;
    }
    const center =
      entityModelMapping.points?.find(({ name }) => name === centerName)
        ?.coordinates ??
      entityModelMapping.points?.find(({ name }) => name === viewpointName)
        ?.coordinates;

    if (mutable.abortCameraChange) {
      mutable.abortCameraChange();
      delete mutable.abortCameraChange;
    }
    let abortController = new AbortController();
    let abortCameraChange = abortController.abort.bind(abortController);
    cameraController
      .change({
        quaternion: viewpoint.quaternion,
        position: viewpoint.position,
        center,
        animate: true,
        animationSteps: 20,
        signal: abortController.signal,
      })
      .finally(() => {
        abortCameraChange = null;
      });
    if (lock) {
      cameraController.disable();
    }
    return () => {
      abortCameraChange && abortCameraChange();
      abortController = new AbortController();
      mutable.abortCameraChange = abortController.abort.bind(abortController);

      const prevViewpoint = mutable.prevViewpoint;
      cameraController
        .change({
          ...prevViewpoint,
          animate: true,
          animationSteps: 15,
          signal: abortController.signal,
        })
        .finally(() => {
          delete mutable.abortCameraChange;
        });
      if (lock) {
        cameraController.enable();
      }
    };
  }, [entityModelMapping, centerName, viewpointName, lock]);

  return null;
};

export default CenterCameraOnEntity;
