import Section from "atomic-components/atoms/Section";
import SectionTitle from "atomic-components/atoms/SectionTitle";
import UnitValue from "atomic-components/atoms/UnitValue";
import HorizontalStackedChart from "atomic-components/organisms/HorizontalStackedChart";
import useBuildingData from "modules/building/hooks/useBuildingData";
import { useTranslate } from "modules/language";

const WasteProcesses = ({
  transportation,
  plasticRecycling,
  paperRecycling,
  composting,
  rdf,
}: {
  transportation: number;
  plasticRecycling: number;
  paperRecycling: number;
  composting: number;
  rdf: number;
}) => {
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;

  const t = useTranslate();

  let processesData = [
    {
      text: t("label.TRANSPORTATION"),
      value: transportation,
    },
    {
      text: t("label.PLASTIC_RECYCLING"),
      value: plasticRecycling,
    },
    {
      text: t("label.PAPER_RECYCLING"),
      value: paperRecycling,
    },
    {
      text: t("label.COMPOSTING"),
      value: composting,
    },
    {
      text: t("label.RDF"),
      value: rdf,
    },
  ];

  const barsData = processesData.map(({ text, value }) => {
    return {
      key: text,
      value,
      borderColor: "",
      legend: (
        <>
          {text} <UnitValue value={value} unit={unitsByName.percent} />
        </>
      ),
    };
  });

  return (
    <Section>
      <SectionTitle weight="normal">
        {t("label.CARBON_EMISSIONS_PERCENTAGE_PER_PROCESS")}
      </SectionTitle>
      <HorizontalStackedChart
        data={barsData}
        unit={unitsByName.percent}
        hasExpandText={false}
      />
    </Section>
  );
};

export default WasteProcesses;
