import { sumBy } from "lodash";

export const defaultEstimateValue = (
  positionVectors,
  sensorsValues,
  confidenceDecayFactor = 0.05
) => {
  let sensorsOnPixelContribution = [];
  for (
    let sensorIndex = 0;
    sensorIndex < positionVectors.length;
    sensorIndex++
  ) {
    const { x, z } = positionVectors[sensorIndex];
    const effectRadius = 512 * confidenceDecayFactor;
    let sensorConfidence = (effectRadius * effectRadius) / (x * x + z * z);
    if (sensorConfidence > 1) sensorConfidence = 1;
    const sensorOnPixelContribution = {
      value: sensorsValues[sensorIndex],
      confidence: sensorConfidence,
    };
    sensorsOnPixelContribution.push(sensorOnPixelContribution);
  }
  const confidenceSum = sumBy(sensorsOnPixelContribution, "confidence");
  const value =
    sumBy(
      sensorsOnPixelContribution,
      ({ value, confidence }) => value * confidence
    ) / confidenceSum;
  let confidence = confidenceSum / sensorsOnPixelContribution.length;
  if (confidence > 1.0) confidence = 1.0;
  return { value, confidence };
};

export const estimateValues = (
  textureWidth,
  textureHeight,
  sensors,
  sensorsReadings,
  mapuvToWorld,
  estimateValueFunction,
  confidenceDecayFactor
) => {
  const size = textureHeight * textureWidth;
  const estimatedValuesWithConfidence = new Array(size);

  if (sensors.length === 0) {
    estimatedValuesWithConfidence.fill({
      value: null,
      confidence: 0.2,
    });
    return estimatedValuesWithConfidence;
  }

  for (let i = 0; i < size; i++) {
    const pixelY = Math.floor(i / textureWidth);
    const pixelX = i % textureWidth;
    const pixelUV = {
      s: pixelY / textureHeight,
      t: pixelX / textureWidth,
    };
    const pixelWorldCoord = mapuvToWorld(pixelUV);
    const sensorsPositionVectors = sensors.map((sensor) => {
      const x = sensor.x - pixelWorldCoord.x;
      const z = sensor.z - pixelWorldCoord.z;
      return { x, z };
    });
    estimatedValuesWithConfidence[i] = estimateValueFunction(
      sensorsPositionVectors,
      sensorsReadings,
      confidenceDecayFactor
    );
  }

  return estimatedValuesWithConfidence;
};
export const getFromWorldSpaceToUvSpaceFunction = (min, max) => {
  return (worldPoint) => {
    const RangeX = max.x - min.x;
    const RangeZ = max.z - min.z;

    let xUV = (worldPoint.x - min.x) / RangeX;
    let yUV = (worldPoint.z - min.z) / RangeZ;
    return { s: xUV, t: yUV };
  };
};
export const getFromUvSpaceToWorldSpaceFunction = (min, max) => {
  return (uvPoint) => {
    const RangeX = max.x - min.x;
    const RangeZ = max.z - min.z;
    const xWorld = uvPoint.t * RangeX + min.x;
    const zWorld = uvPoint.s * RangeZ + min.z;

    return { x: xWorld, z: zWorld };
  };
};
export const getUVAndWorldConverterFunctions = (objectToTexturize) => {
  let fromWorldSpaceToUvSpace, fromUvSpaceToWorldSpace;
  if (objectToTexturize) {
    const geometry = objectToTexturize.geometry.clone();
    geometry.attributes.position.applyMatrix4(objectToTexturize.matrixWorld);
    geometry.computeBoundingBox();
    fromWorldSpaceToUvSpace = getFromWorldSpaceToUvSpaceFunction(
      geometry.boundingBox.min,
      geometry.boundingBox.max
    );
    fromUvSpaceToWorldSpace = getFromUvSpaceToWorldSpaceFunction(
      geometry.boundingBox.min,
      geometry.boundingBox.max
    );
  }
  return {
    fromWorldSpaceToUvSpace,
    fromUvSpaceToWorldSpace,
  };
};
const computeTexture = (
  textureWidth,
  textureHeight,
  estimatedValuesWithConfidence,
  heatmapColorTweener,
  globalAlpha = 1.0
) => {
  const size = textureWidth * textureHeight;
  const data = new Uint8Array(4 * size);
  for (let i = 0; i < size; i++) {
    const { value, confidence } = estimatedValuesWithConfidence[i];
    const stride = i * 4;
    if (value != null) {
      const hexColor = heatmapColorTweener(value);
      const color = new window.THREE.Color(hexColor);

      const r = Math.floor(color.r * 255);
      const g = Math.floor(color.g * 255);
      const b = Math.floor(color.b * 255);

      data[stride] = r;
      data[stride + 1] = g;
      data[stride + 2] = b;
      data[stride + 3] = Math.round(confidence * 255 * globalAlpha);
    } else {
      data[stride] = 0.5;
      data[stride + 1] = 0.5;
      data[stride + 2] = 0.5;
      data[stride + 3] = Math.round(0.5 * 255 * globalAlpha);
    }
  }

  return data;
};

export const computeUVCoord = (objectToTexturize, fromWorldSpaceToUvSpace) => {
  const geometry = objectToTexturize.geometry.clone();
  geometry.attributes.position.applyMatrix4(objectToTexturize.matrixWorld);
  const worldVerticies = geometry.attributes.position.array;
  const uv = new Float32Array((worldVerticies.length / 3) * 2);

  if (!objectToTexturize.userData.texturizerDidComputeUv) {
    objectToTexturize.userData.texturizerDidComputeUv = true;
    let uvIndex = 0;
    for (let i = 0; i < worldVerticies.length; i += 3) {
      const uvPoint = fromWorldSpaceToUvSpace({
        x: worldVerticies[i],
        z: worldVerticies[i + 2],
      });

      uv[uvIndex++] = uvPoint.s;
      uv[uvIndex++] = uvPoint.t;
    }
    objectToTexturize.geometry.setAttribute(
      "uv",
      new window.THREE.BufferAttribute(uv, 2)
    );
  }

  return uv;
};

export default computeTexture;
