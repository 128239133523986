import classNames from "classnames";

type Props = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
  color?: string;
};

const Charts = ({
  className,
  iconScale = 1.2,
  icon = true,
  color = "white",
}: Props) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 14C15.7761 14 16 14.2239 16 14.5C16 14.7761 15.7761 15 15.5 15H1.5C1.22386 15 1 14.7761 1 14.5C1 14.2239 1.22386 14 1.5 14H15.5ZM6.5 1C6.77614 1 7 1.22386 7 1.5L6.999 6H10V3.5C10 3.22386 10.2239 3 10.5 3H14.5C14.7761 3 15 3.22386 15 3.5V12.5C15 12.7761 14.7761 13 14.5 13C14.2239 13 14 12.7761 14 12.5V6H11V12.5C11 12.7455 10.8231 12.9496 10.5899 12.9919L10.5 13C10.2239 13 10 12.7761 10 12.5V9H7V12.5C7 12.7761 6.77614 13 6.5 13C6.25454 13 6.05039 12.8231 6.00806 12.5899L6 12.5V5H3V12.5C3 12.7761 2.77614 13 2.5 13C2.22386 13 2 12.7761 2 12.5V1.5C2 1.22386 2.22386 1 2.5 1H6.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Charts;
