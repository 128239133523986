import { PageUrlEnum } from "router/enums";
import aubWhiteLabelConfig from "./aub";

let whiteLabelConfig: {
  name: string;
  defaultPage: PageUrlEnum;
  hasLocalization: boolean;
  logoLink?: string;
  enabledPages: PageUrlEnum[];
  defaultBuildingUrl: string;
  headerLogoLink?: string;
  poweredDarOnly?: boolean;
  loginBackgroundLink?: string;
} = {
  name: "default",
  defaultPage: PageUrlEnum.AssetManagement,
  hasLocalization: true,
  logoLink: "",
  enabledPages: [...Object.values(PageUrlEnum)],
  defaultBuildingUrl: "smart-village",
  headerLogoLink: "paraFullWhite.svg",
  poweredDarOnly: true,
  loginBackgroundLink: "defaultBackground.png",
};

switch (process.env.REACT_APP_WHITELABEL_PROJECT) {
  case "aub":
    whiteLabelConfig = aubWhiteLabelConfig;
    break;
  default:
}

export default whiteLabelConfig;

export const {
  name,
  defaultPage,
  defaultBuildingUrl,
  hasLocalization,
  logoLink,
  enabledPages,
  headerLogoLink,
  poweredDarOnly,
  loginBackgroundLink,
} = whiteLabelConfig;

export const hasOnlyOnePageEnabled = enabledPages.length === 1;
