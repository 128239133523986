import Button, {
  type ButtonProps,
} from "atomic-components/atoms/Buttons/Button";
import { useTranslate } from "modules/language";
import s from "./GraphButton.module.scss";
import classNames from "classnames";
import { usePageState } from "modules/building/components/ModelPageLayout";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import useBuildingData from "modules/building/hooks/useBuildingData";

type GraphButtonProps = Omit<ButtonProps, "onClick" | "children"> & {
  graphType: string;
};

const GraphButton = ({ graphType, className, ...props }: GraphButtonProps) => {
  const t = useTranslate();
  const { buildingData } = useBuildingData();
  const selectedEntity = useSelectedEntity(buildingData);
  const { setShownBottomPanel, shownBottomPanel } = usePageState();

  const toggleGraph = () => {
    if (!shownBottomPanel) {
      return setShownBottomPanel({
        type: graphType,
        entityId: selectedEntity.id,
      });
    }
    return setShownBottomPanel(null);
  };

  return (
    <Button
      size="small"
      onClick={toggleGraph}
      className={classNames(className, s.graphBtn, {
        [s.graphBtnActive]: shownBottomPanel,
      })}
      {...props}
    >
      {t("action.GRAPH")}
    </Button>
  );
};

export default GraphButton;
export type { GraphButtonProps };
