import withIconProps from "helpers/withIconProps";

const EnvelopeIcon = withIconProps((props) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.6865 4.82481L10.7974 11.3595C10.3348 11.7426 9.66517 11.7426 9.20263 11.3595L1.3135 4.82481C1.27231 4.9488 1.25 5.08142 1.25 5.21924V15.2192C1.25 15.9096 1.80964 16.4692 2.5 16.4692H17.5C18.1904 16.4692 18.75 15.9096 18.75 15.2192V5.21924C18.75 5.08142 18.7277 4.9488 18.6865 4.82481ZM2.5 2.71924H17.5C18.8807 2.71924 20 3.83853 20 5.21924V15.2192C20 16.5999 18.8807 17.7192 17.5 17.7192H2.5C1.11929 17.7192 0 16.5999 0 15.2192V5.21924C0 3.83853 1.11929 2.71924 2.5 2.71924ZM2.23712 3.96924L9.20791 9.72348C9.66824 10.1035 10.333 10.105 10.795 9.72708L17.8349 3.96924H2.23712Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default EnvelopeIcon;
