import { getLinkedNotifier } from "helpers/getNotifier";
import { flatten, groupBy, isString, last, orderBy, uniqBy } from "lodash";
import { get, set, unset } from "helpers/setGet";
import { SingleEvent } from "modules/core/helpers/events";
import { useEffect, useRef, useState, useMemo } from "react";
import useBuildingData from "./useBuildingData";
import Linker from "../helpers/Linker";
import { getLiveListener, useLiveListener } from "../helpers/liveListener";
import loadTimeseries, {
  loadFromLocalSync as loadTimeseriesFromLocalSync,
} from "../helpers/timeseriesLoader";
import loadInsights, {
  loadFromLocalSync as loadInsightsFromLocalSync,
} from "../helpers/insightsLoader";
import {
  DEFAULT_INTERVAL,
  DEFAULT_TIMEZONE,
  getIntervals,
  getDayFromTo,
} from "../helpers/intervals";
import useFullDateFilterState from "./useFullDateFilterState";
import getStableKey from "helpers/getStableKey";
import { fromMillis } from "helpers/dateTimeHelper";
import LinkedTelmetriesComputer from "../helpers/LinkedTelemetriesComputer";
import createLinkedTelemetriesAggregator from "../helpers/createLinkedTelemetriesAggregator";
import createLinkedTelemetriesBucketer, {
  bucketsConfigs,
} from "../helpers/createLinkedTelemetriesBucketer";
import createLinkedTelemetriesSegmentator from "../helpers/createLinkedTelemetriesSegmentator";
import {
  defaultOutputStructure,
  outputStructures,
} from "./useLinkedData.types";
import config from "config";
import { getRelations, indexedInsightsTypes } from "../helpers/insightsUtils";
import {
  subtractIntervals,
  unionIntervals,
} from "helpers/computeIntervalsDuration";

const telemetriesByTimeRangeKeyEntityIdTelemetryName = {};
const insightsByTimeRangeKeyEntityId = {};
export const isTelemetrySymbol = Symbol("IsTelemetry");

/**
 * @typedef {Object} Datum
 * @property {number} timestamp
 * @property {number} value
 * @property {number} [coverage] between 0 and 1 how much of the interval this datum represents. is < 1 in resolutions when not the whole interval have passes yet. like if the resolution is daily and the day havent fully passes (today) this will be < 1
 */
/**
 * @callback DatumAtTimestamp
 * @param {number} timestamp
 * @returns {Datum|null|undefined}
 */
/**
 * @callback ValueAtTimestamp
 * @param {number} timestamp
 * @returns {number|null|undefined}
 */
/**
 * @typedef {Object.<string, TelemetryDefenition & LinkedTelemetryBase>} LinkedTelemetryObject
 */

/**
 * @typedef {import('../helpers/postProcessBuildingData').TelemetryDefenition} TelemetryDefenition
 * @typedef {Object} LinkedTelemetryBase
 * @property {TelemetryDefenition} telemetryDefenition
 * @property {TelemetryDefenition} baseTelemetryDefenition
 * @property {number} timeseriesId
 * @property {string} id
 * @property {string} key alias for id
 * @property {string} name telemetry name
 * @property {string} [dimensionKey]
 * @property {string} [dimensionAlias]
 * @property {string} [resolutionKey]
 * @property {string} [timeRangeKey]
 * @property {string} [entityId]
 * @property {import('react').FunctionComponent} [Notifier]
 * @property {boolean} loading
 * @property {Error} [loadingError]
 * @property {number} baseInterval
 * @property {number} interval
 * @property {number} fromTs
 * @property {number} toTs
 * @property {number} targetToTs
 * @property {number} days
 * @property {number[]} timestamps
 * @property {number} lastConsecutiveDatumIndex
 * @property {number} nextConsecutiveTimestamp
 * @property {number[][]} availableRanges
 * @property {number[][]} missingRanges
 * @property {Datum[]} data
 * @property {number} coverage between 0 and 1
 * @property {number} lastSync
 * @property {number[]} fullTimestamps
 * @property {import('../helpers/intervals').BaseIntervals|import('../helpers/intervals').ResolutionIntervals} intervals
 * @property {import('../helpers/intervals').BaseIntervals|import('../helpers/intervals').ResolutionIntervals} intervalsData alias for intervals
 * @property {import('../helpers/intervals').BaseIntervals} baseIntervals
 * @property {import('../helpers/intervals').ResolutionIntervals} [resolutionIntervals]
 * @property {DatumAtTimestamp} datumAtTimestamp
 * @property {DatumAtTimestamp} datumAtClosestTimestamp
 * @property {ValueAtTimestamp} valueAtTimestamp
 * @property {ValueAtTimestamp} valueAtClosestTimestamp
 */
/**
 * @typedef {TelemetryDefenition & LinkedTelemetryBase } LinkedTelemetry
 */

const getTelemetryLinkData = ({
  rootEntity,
  entity,
  pathData,
  baseTelemetry,
  telemetry,
  timeseriesId,
  from,
  to,
}) => {
  const timezone = rootEntity.timezone ?? DEFAULT_TIMEZONE;
  const interval =
    baseTelemetry?.interval ??
    entity?.defaultInterval ??
    rootEntity.defaultInterval ??
    DEFAULT_INTERVAL;
  const baseIntervals = getIntervals({
    from,
    to,
    timezone,
    interval,
  });

  const resolutionIntervals = telemetry?.resolutionKey
    ? baseIntervals.getResolution({
        resolutionKey: telemetry.resolutionKey,
        startOfWeek:
          rootEntity.startOfWeek ??
          rootEntity.metadata.startOfWeek ??
          timezone === "Africa/Cairo"
            ? 7
            : 1,
      })
    : null;

  const graceToIntervalRatio = rootEntity.graceRatio ?? 0.5;
  const gracePeriod = interval * graceToIntervalRatio;

  if (!timeseriesId) {
    console.warn(
      `requesting an not existing telemtry "${JSON.stringify(
        pathData
      )}" for entity ${entity.name}[${entity.url}](${entity.id})`
    );
  }

  const intervalsKey = resolutionIntervals?.key ?? baseIntervals.key;
  const key = `${
    timeseriesId
      ? `[${timeseriesId}]`
      : `NULL${JSON.stringify([
          pathData.entityId,
          pathData.telemetryName,
          pathData.resolutionKey,
          pathData.dimensionKey,
        ])}`
  }@${intervalsKey}/${gracePeriod}`;
  return {
    key,
    timezone,
    baseIntervals,
    resolutionIntervals,
    interval,
    gracePeriod,
  };
};

/** @type {import('../helpers/Linker').LinkInitalizerFunction<Object, Function>} */
const createTelemetryLink = ({
  triggerEvent,
  isDestroyed,
  initializationArgs,
}) => {
  const [
    {
      key,
      baseIntervals,
      resolutionIntervals,
      rootEntity,
      entity,
      pathData,
      baseTelemetry,
      telemetry,
      timeseriesId,
      interval,
      gracePeriod,
    },
  ] = initializationArgs;

  /** @type {import('../helpers/intervals').ResolutionIntervals | import('../helpers/intervals').BaseIntervals} */
  const intervalsData = resolutionIntervals ?? baseIntervals;

  const maxInterpolationTicks =
    telemetry?.maxInterpolationDuration == null
      ? Number.MAX_SAFE_INTEGER
      : telemetry?.maxInterpolationDuration < 0
      ? Number.MAX_SAFE_INTEGER
      : Math.ceil(telemetry?.maxInterpolationDuration / intervalsData.interval);

  const interpolationMethod = telemetry?.interpolationMethod;

  const notifierEvent = new SingleEvent();
  const liveListener = getLiveListener({
    interval,
    gracePeriod,
  });

  let removeLiveListener;
  let cancelPreviousLoad;

  const cleanup = () => {
    notifierEvent.destroy();
    if (removeLiveListener) {
      removeLiveListener();
    }
    if (cancelPreviousLoad) {
      cancelPreviousLoad();
    }
    unset(telemetriesByTimeRangeKeyEntityIdTelemetryName, [
      mutableTelemetry.timeRangeKey,
      mutableTelemetry.entityId,
      mutableTelemetry.name,
      mutableTelemetry.dimensionKey ?? "base",
      mutableTelemetry.resolutionKey ?? "base",
    ]);
  };

  const Notifier = getLinkedNotifier({
    event: notifierEvent,
    initializer: () => ({
      loading: mutableTelemetry.loading,
      error: mutableTelemetry.loadingError,
      warning: mutableTelemetry.wasTrimmedFromServer,
    }),
  });

  const resetCurrentMonthToFirstDay = fromMillis(
    liveListener.getLastTimestamp(),
    {
      zone: intervalsData.timezone,
      resetTime: true,
    }
  )
    .set({ day: 1 })
    .toMillis();

  const getToTs = () =>
    Math.min(
      intervalsData.baseInterval === 2592000000
        ? resetCurrentMonthToFirstDay
        : liveListener.getLastTimestamp(),
      intervalsData.toTs
    );

  const getTimestamps = (toTs) => {
    toTs = toTs ?? getToTs();
    if (toTs >= intervalsData.toTs) {
      return intervalsData.timestamps;
    }
    const index = intervalsData.getExactTimestampIndex(toTs);
    return intervalsData.timestamps.slice(0, index);
  };

  const mutableTelemetry = {
    [isTelemetrySymbol]: true,
    ...telemetry,
    telemetryDefenition: telemetry,
    baseTelemetryDefenition: baseTelemetry,
    timeseriesId,
    id: key,
    key,
    name: pathData.telemetryName,
    dimensionKey: telemetry?.dimensionKey ?? pathData.dimensionKey,
    dimensionAlias: telemetry?.dimensionAlias ?? pathData.dimensionAlias,
    resolutionKey: telemetry?.resolutionKey ?? pathData.resolutionKey,
    aggregationPath: pathData.aggregationPath,
    timeRangeKey: intervalsData.key,
    entityId: entity.id,
    telemetryId: telemetry?.id,
    Notifier,
    loading: false,
    loadingError: null,
    baseInterval: interval,
    interval: intervalsData.interval,
    fromTs: intervalsData.fromTs,
    toTs: getToTs(),
    targetToTs: intervalsData.toTs,
    days: intervalsData.days,
    timestamps: getTimestamps(),
    lastConsecutiveDatumIndex: -1,
    nextConsecutiveTimestamp: intervalsData.fromTs,
    availableRanges: [],
    missingRanges: [[intervalsData.fromTs, getToTs()]],
    data: [],
    coverage: 0,
    lastSync: 0,
    fullTimestamps: intervalsData.timestamps,
    intervals: intervalsData,
    intervalsData,
    baseIntervals,
    resolutionIntervals,
    datumAtTimestamp: (ts) =>
      mutableTelemetry.data[intervalsData.getExactTimestampIndex(ts)],
    datumAtClosestTimestamp: (ts) =>
      mutableTelemetry.data[intervalsData.getApproximateTimestampIndex(ts)],
    valueAtTimestamp: (ts) =>
      mutableTelemetry.data[intervalsData.getExactTimestampIndex(ts)]?.value,
    valueAtClosestTimestamp: (ts) =>
      mutableTelemetry.data[intervalsData.getApproximateTimestampIndex(ts)]
        .value,
  };

  const fillWithNulls = ({ fromTs, toTs }, data) => {
    if (intervalsData.interval === 2592000000) {
      for (
        let ts = fromTs;
        ts < toTs;
        ts = fromMillis(ts, {
          zone: intervalsData.timezone,
          resetTime: true,
        }).plus({
          month: 1,
        }).ts
      ) {
        let index = intervalsData.getExactTimestampIndex(ts);
        index = index < 0 ? 0 : index;
        data[index] = data[index] ?? { timestamp: ts, value: null };
      }
    } else {
      for (let ts = fromTs; ts < toTs; ts += intervalsData.interval) {
        let index = intervalsData.getExactTimestampIndex(ts);
        index = index < 0 ? 0 : index;
        data[index] = data[index] ?? { timestamp: ts, value: null };
      }
    }
  };

  const preLoadTelemetry = ({ fromTs, toTs }) => {
    mutableTelemetry.coverage = Math.min(mutableTelemetry.coverage, 0.9);
    mutableTelemetry.timestamps = getTimestamps(toTs);
    mutableTelemetry.toTs = toTs;

    // Dirty state mutation. Not a big deal.
    // To prime current telemetries with needed datums
    // before anyone else attempts to read from them
    const { data } = mutableTelemetry;
    fillWithNulls({ fromTs, toTs }, data);
  };

  const interpolateData = ({
    toTs,
    lastConsecutiveDatumWithData,
    nextDatum,
    data,
  }) => {
    if (interpolationMethod !== "null") {
      const previousTimestamp =
        lastConsecutiveDatumWithData?.timestamp ?? intervalsData.fromTs;
      let previousValue = lastConsecutiveDatumWithData?.value;
      let previousIndex = lastConsecutiveDatumWithData
        ? (lastConsecutiveDatumWithData.timestamp - intervalsData.fromTs) /
          intervalsData.interval
        : undefined;
      let maxNextIndex = nextDatum
        ? data.length -
          1 +
          (nextDatum.timestamp - toTs) / intervalsData.interval
        : undefined;

      let i = Math.max(
        0,
        intervalsData.getExactTimestampIndex(previousTimestamp)
      );
      for (; i < data.length; i++) {
        const datum = data[i];
        const value = datum.interpolated ? null : datum.value;

        if (value != null) {
          previousValue = value;
          previousIndex = i;
          continue;
        }

        switch (interpolationMethod) {
          case "zero":
            data[i].value = 0;
            data[i].interpolated = true;
            break;
          case "last":
          case "prev":
          case "previous":
            if (previousValue != null) {
              data[i].value = previousValue;
              data[i].interpolated = true;
            }
            break;
          default: {
            let nextIndex = i;
            let nextValue;
            while (true) {
              ++nextIndex;
              if (nextIndex >= data.length) {
                break;
              }
              nextValue = data[nextIndex].interpolated
                ? null
                : data[nextIndex].value;
              if (nextValue != null) {
                break;
              }
            }
            nextValue = nextValue ?? nextDatum?.value;
            nextIndex =
              nextValue != null ? nextIndex : maxNextIndex ?? nextIndex;
            if (nextIndex - previousIndex - 2 <= maxInterpolationTicks) {
              for (
                let currentIndex = i;
                currentIndex < nextIndex && currentIndex < data.length;
                currentIndex++
              ) {
                let interpolatedValue;
                if (nextValue == null) {
                  interpolatedValue = previousValue;
                } else if (previousValue == null) {
                  interpolatedValue = nextValue;
                } else {
                  switch (interpolationMethod) {
                    case "or":
                      interpolatedValue = nextValue || previousValue;
                      break;
                    case "and":
                      interpolatedValue = nextValue && previousValue;
                      break;
                    default:
                      interpolatedValue =
                        (nextValue * (currentIndex - previousIndex)) /
                          (nextIndex - previousIndex) +
                        (previousValue * (nextIndex - currentIndex)) /
                          (nextIndex - previousIndex);
                      break;
                  }
                }
                if (interpolatedValue != null) {
                  data[currentIndex].value = interpolatedValue;
                  data[currentIndex].interpolated = true;
                }
              }
            }
            i = nextIndex - 1;
          }
        }
      }
    }
  };

  let lastConsecutiveDatumWithData = null;
  const updateTelemetry = ({ fromTs, toTs, error, result, loading }) => {
    if (result) {
      const { data, nextDatum, previousDatum } = result;

      mutableTelemetry.nextDatum =
        nextDatum && nextDatum.timestamp >= mutableTelemetry.toTs
          ? nextDatum
          : mutableTelemetry.nextDatum;

      mutableTelemetry.previousDatum =
        previousDatum && previousDatum.timestamp < mutableTelemetry.fromTs
          ? previousDatum
          : mutableTelemetry.previousDatum;

      const nextData = mutableTelemetry.data.slice();
      fillWithNulls({ fromTs, toTs }, nextData);
      data.forEach((datum) => {
        const timestamp = datum.timestamp;
        let index;
        // TODO this resetting the timestamp from the database to match the local timestamp
        if (
          intervalsData.interval === 2592000000 ||
          mutableTelemetry.resolutionKey === "1month"
        ) {
          const resetTimestamp = fromMillis(timestamp).set({
            hour: 0,
            minute: 0,
            second: 0,
          });
          index = nextData.findIndex(
            (obj) => obj.timestamp === resetTimestamp.ts
          );
        } else {
          index = nextData.findIndex((obj) => obj.timestamp === timestamp);
        }

        if (index >= 0) {
          nextData[index] = datum;
        }
      });

      const previousLastConsecutiveDatumWithData = lastConsecutiveDatumWithData;

      const availableRanges = unionIntervals(
        mutableTelemetry.availableRanges,
        result.availableRanges
      );
      const missingRanges = subtractIntervals(
        [[mutableTelemetry.fromTs, mutableTelemetry.toTs]],
        availableRanges
      );

      mutableTelemetry.coverage =
        availableRanges.reduce(
          (cumulative, current) => cumulative + current[1] - current[0],
          0
        ) /
        (mutableTelemetry.toTs - mutableTelemetry.fromTs);

      let lastConsecutiveDatumIndex;
      if (missingRanges.length) {
        if (missingRanges[0][0] === mutableTelemetry.fromTs) {
          lastConsecutiveDatumIndex = -1;
        } else {
          lastConsecutiveDatumIndex = intervalsData.getExactTimestampIndex(
            missingRanges[0][0] - interval
          );
        }
      } else {
        lastConsecutiveDatumIndex = nextData.length - 1;
      }

      for (let i = lastConsecutiveDatumIndex; i >= 0; i--) {
        const datum = nextData[lastConsecutiveDatumIndex];
        if (!datum.interpolated && datum.value != null) {
          lastConsecutiveDatumWithData = datum;
          break;
        }
      }

      interpolateData({
        toTs,
        lastConsecutiveDatumWithData:
          previousLastConsecutiveDatumWithData ??
          mutableTelemetry.previousDatum,
        nextDatum,
        data: nextData,
      });

      mutableTelemetry.data = nextData;
      mutableTelemetry.lastConsecutiveDatumIndex = lastConsecutiveDatumIndex;
      mutableTelemetry.nextConsecutiveTimestamp =
        nextData.length === lastConsecutiveDatumIndex + 1
          ? mutableTelemetry.toTs
          : nextData[lastConsecutiveDatumIndex + 1]?.timestamp ??
            mutableTelemetry.nextConsecutiveTimestamp;
      mutableTelemetry.availableRanges = availableRanges;
      mutableTelemetry.missingRanges = missingRanges;

      mutableTelemetry.lastSync =
        last(data)?.timestamp ?? mutableTelemetry.lastSync;
    }

    const previousWasTrimmedFromServer = mutableTelemetry.wasTrimmedFromServer;
    const wasTrimmedFromServer =
      !loading &&
      mutableTelemetry.timeseriesId != null &&
      mutableTelemetry.missingRanges.length > 0;

    const triggerNotifier =
      mutableTelemetry.loading !== loading ||
      mutableTelemetry.loadingError !== error ||
      wasTrimmedFromServer !== previousWasTrimmedFromServer;

    if (triggerNotifier) {
      mutableTelemetry.loading = loading;
      mutableTelemetry.loadingError = error;
      mutableTelemetry.wasTrimmedFromServer = wasTrimmedFromServer;
      notifierEvent.triggerEvent({
        loading,
        error,
        warning: wasTrimmedFromServer,
      });
    }

    return Boolean(result || triggerNotifier);
  };

  const saveTelemetry = () => {
    const telemetry = { ...mutableTelemetry };
    set(
      telemetriesByTimeRangeKeyEntityIdTelemetryName,
      [
        telemetry.timeRangeKey,
        telemetry.entityId,
        telemetry.name,
        telemetry.dimensionKey ?? "base",
        telemetry.resolutionKey ?? "base",
        telemetry.aggregationPath ?? telemetry.resolutionKey ?? "base",
      ],
      telemetry
    );
    if (!isDestroyed()) {
      triggerEvent(telemetry);
    }
    return telemetry;
  };

  let loadInitially = true;
  const doLoad = (firstLoad) => {
    const fromTs = mutableTelemetry.nextConsecutiveTimestamp;
    const toTs = getToTs();

    preLoadTelemetry({ fromTs, toTs });

    if (fromTs >= toTs) {
      if (loadInitially) {
        saveTelemetry();
      }
      return;
    }
    if (cancelPreviousLoad) {
      cancelPreviousLoad();
    }

    const requests =
      timeseriesId != null
        ? {
            rootEntityId: rootEntity.id,
            timeseriesRequests: [
              {
                from: fromTs,
                to: toTs,
                timeseriesIds: [timeseriesId],
              },
            ],
          }
        : null;

    if (firstLoad && timeseriesId != null) {
      const results = loadTimeseriesFromLocalSync(requests);
      const result = results[0].timeseries[0];
      updateTelemetry({
        fromTs,
        toTs,
        error: null,
        result,
        loading: true,
      });
    }
    saveTelemetry();

    cancelPreviousLoad =
      timeseriesId != null &&
      loadTimeseries(
        requests,
        (error, results, loading) => {
          if (results) {
            loadInitially = false;
          }

          const changed = updateTelemetry({
            fromTs,
            toTs,
            error,
            result: results?.[0].timeseries[0],
            loading,
          });

          if (changed) {
            saveTelemetry();
          }

          if (
            !loading &&
            !error &&
            toTs === intervalsData.toTs &&
            Math.abs(mutableTelemetry.coverage - 1) < 1e-9
          ) {
            removeLiveListener();
            removeLiveListener = null;
          }
        },
        {
          loadInitially,
          loadFromServer: true,
        }
      ).cancel;
  };

  doLoad(true);
  removeLiveListener = liveListener.addPriorityListener(() => doLoad());
  return cleanup;
};

/** @type {import('../helpers/Linker').LinkerInstance<Object, Function>} */
const telemetryLinker = new Linker({
  defaultLinkInitalizerFunction: createTelemetryLink,
  idleDuration: 60000,
});
telemetryLinker.getTelemetries = () =>
  telemetriesByTimeRangeKeyEntityIdTelemetryName;
window.telemetryLinker = telemetryLinker;

const linkTelemetry = (linkParams, callback) => {
  return telemetryLinker.link({ key: linkParams.key, callback }, linkParams);
};

export const getInsightsLinkData = ({ rootEntity, entity, from, to }) => {
  const timezone = rootEntity.timezone ?? DEFAULT_TIMEZONE;
  const interval = entity.interval ?? rootEntity.interval ?? DEFAULT_INTERVAL;
  const baseIntervals = getIntervals({
    from,
    to,
    timezone,
    interval,
  });
  const graceToIntervalRatio =
    entity.graceRatio ?? rootEntity.graceRatio ?? 0.5;
  const gracePeriod = interval * graceToIntervalRatio;

  const intervalsKey = baseIntervals.key;
  const key = `${entity.id}@${intervalsKey}/${gracePeriod}`;
  return {
    key,
    timezone,
    baseIntervals,
    interval,
    gracePeriod,
  };
};

/** @type {import('../helpers/Linker').LinkInitalizerFunction<Object, Function>} */
const createInsightsLink = ({
  triggerEvent,
  isDestroyed,
  initializationArgs,
}) => {
  const [{ key, baseIntervals, interval, gracePeriod, rootEntity, entity }] =
    initializationArgs;

  const notifierEvent = new SingleEvent();
  const liveListener = getLiveListener({
    interval,
    gracePeriod,
  });

  let removeLiveListener;
  let cancelPreviousLoad;

  const cleanup = () => {
    notifierEvent.destroy();
    if (removeLiveListener) {
      removeLiveListener();
    }
    if (cancelPreviousLoad) {
      cancelPreviousLoad();
    }
    unset(insightsByTimeRangeKeyEntityId, [
      mutableInsights.timeRangeKey,
      mutableInsights.entityId,
    ]);
  };

  const Notifier = getLinkedNotifier({
    event: notifierEvent,
    initializer: () => ({
      loading: mutableInsights.loading,
      error: mutableInsights.loadingError,
    }),
  });

  const getToTs = () =>
    Math.min(liveListener.getLastTimestamp(), baseIntervals.toTs);
  const getTimestamps = (toTs) => {
    toTs = toTs ?? getToTs();
    if (toTs === baseIntervals.toTs) {
      return baseIntervals.timestamps;
    }
    const index = baseIntervals.getExactTimestampIndex(toTs);
    return baseIntervals.timestamps.slice(0, index);
  };

  const mutableInsights = {
    id: key,
    key,
    timeRangeKey: baseIntervals.key,
    entityId: entity.id,
    Notifier,
    loading: false,
    loadingError: null,
    interval,
    fromTs: baseIntervals.fromTs,
    toTs: getToTs(),
    targetToTs: baseIntervals.toTs,
    days: baseIntervals.days,
    timestamps: getTimestamps(),
    availableRanges: [],
    missingRanges: [[baseIntervals.fromTs, getToTs()]],
    data: [],
    coverage: 0,
    fullTimestamps: baseIntervals.timestamps,
    intervalsData: baseIntervals,
    baseIntervals,
  };

  const preLoadInsights = ({ toTs }) => {
    mutableInsights.coverage = Math.min(mutableInsights.coverage, 0.9);
    mutableInsights.timestamps = getTimestamps(toTs);
    mutableInsights.toTs = toTs;
  };

  const updateInsights = ({ error, result, loading }) => {
    if (result) {
      const { data } = result;

      const availableRanges = unionIntervals(
        mutableInsights.availableRanges,
        result.availableRanges
      );
      const missingRanges = subtractIntervals(
        [[mutableInsights.fromTs, mutableInsights.toTs]],
        availableRanges
      );

      mutableInsights.coverage =
        availableRanges.reduce(
          (cumulative, current) => cumulative + current[1] - current[0],
          0
        ) /
        (mutableInsights.toTs - mutableInsights.fromTs);

      mutableInsights.data = data;
      mutableInsights.availableRanges = availableRanges;
      mutableInsights.missingRanges = missingRanges;
      mutableInsights.wasTrimmedFromServer = !loading && missingRanges.length;
    }

    const triggerNotifier =
      mutableInsights.loading !== loading ||
      mutableInsights.loadingError !== error;

    if (triggerNotifier) {
      mutableInsights.loading = loading;
      mutableInsights.loadingError = error;
      notifierEvent.triggerEvent({
        loading,
        error,
      });
    }

    return Boolean(result || triggerNotifier);
  };

  const saveInsights = () => {
    const insights = { ...mutableInsights };
    set(
      insightsByTimeRangeKeyEntityId,
      [insights.timeRangeKey, insights.entityId],
      insights
    );
    if (!isDestroyed()) {
      triggerEvent(insights);
    }
    return insights;
  };

  let loadInitially = true;
  const doLoad = (firstLoad) => {
    const fromTs = baseIntervals.fromTs;
    const toTs = getToTs();

    preLoadInsights({ toTs });

    if (fromTs >= toTs) {
      if (loadInitially) {
        saveInsights();
      }
      return;
    }
    if (cancelPreviousLoad) {
      cancelPreviousLoad();
    }

    const requests = {
      rootEntityId: rootEntity.id,
      entitiesInsightsRequest: [
        {
          entityId: entity.id,
          from: fromTs,
          to: toTs,
        },
      ],
    };

    if (firstLoad) {
      const results = loadInsightsFromLocalSync(requests);
      const result = results[0];
      updateInsights({
        error: null,
        result,
        loading: true,
      });
    }
    saveInsights();

    cancelPreviousLoad = loadInsights(
      requests,
      (error, results, loading) => {
        if (results) {
          loadInitially = false;
        }

        const changed = updateInsights({
          error,
          result: results?.[0],
          loading,
        });

        if (changed) {
          saveInsights();
        }

        if (
          !loading &&
          !error &&
          toTs === baseIntervals.toTs &&
          Math.abs(mutableInsights.coverage - 1) < 1e-9
        ) {
          removeLiveListener();
          removeLiveListener = null;
        }
      },
      {
        loadInitially,
        loadFromServer: true,
      }
    ).cancel;
  };

  doLoad(true);
  removeLiveListener = liveListener.addPriorityListener(() => doLoad());
  return cleanup;
};

/** @type {import('../helpers/Linker').LinkerInstance<Object, Function>} */
const insightsLinker = new Linker({
  defaultLinkInitalizerFunction: createInsightsLink,
  idleDuration: 60000,
});
insightsLinker.getInsights = () => insightsByTimeRangeKeyEntityId;
window.insightsLinker = insightsLinker;

export const linkInsights = (linkParams, callback) => {
  return insightsLinker.link({ key: linkParams.key, callback }, linkParams);
};

const level2Merge = (object1, object2) => {
  const target = { ...object1 };
  Object.entries(object2).forEach(([key, value]) => {
    if (target[key]) {
      target[key] = { ...target[key], ...value };
    } else {
      target[key] = value;
    }
  });
  return target;
};

/**
 * @param {AggregationRequest} aggregationRequest
 * @param {string} entityId
 * @param {TelemetryRequest} telemetryRequest
 * @returns {BaseAggregationRequest}
 */
const getAggregationRequest = (
  aggregationRequest,
  entityId,
  telemetryRequest
) => {
  if (isString(aggregationRequest)) {
    aggregationRequest = { key: aggregationRequest };
  }
  aggregationRequest = {
    ...aggregationRequest,
    entityId,
    telemetry: telemetryRequest,
  };
  aggregationRequest.aggregationFunction =
    aggregationRequest.aggregationFunction ?? aggregationRequest.key;
  if (!aggregationRequest.keyPath) {
    const telemetryKey = isString(telemetryRequest)
      ? telemetryRequest
      : telemetryRequest?.alias ?? telemetryRequest.telemetryName;
    aggregationRequest.keyPath = [
      entityId,
      telemetryKey,
      aggregationRequest.alias ?? aggregationRequest.key,
    ].filter(Boolean);
  }
  return aggregationRequest;
};

/**
 * @param {Object} segmentationRequest
 * @param {string} entityId
 * @param {TelemetryRequest} telemetryRequest
 * @returns {import('./useLinkedData.types').SegmentationRequest}
 */
const getSegmentationRequest = (
  segmentationRequest,
  entityId,
  telemetryRequest
) => {
  const {
    key,
    alias,
    outputStructure: outputStructureKey,
    buckets,
    ...rest
  } = segmentationRequest;

  const telemetryKey = isString(telemetryRequest)
    ? telemetryRequest
    : telemetryRequest.alias ?? telemetryRequest.telemetryName;

  const keys = {
    entity: entityId,
    telemetry: telemetryKey,
    segmentation: alias ?? key,
  };

  const bucketsRequests = buckets.map((bucketsRequest) =>
    getBucketsRequest(
      bucketsRequest,
      entityId,
      bucketsRequest.telemetry ?? telemetryRequest,
      bucketsRequest.alias
    )
  );

  const bucketsKeys = JSON.stringify(
    bucketsRequests.map((bucketRequest) => bucketRequest.key)
  );

  let outputStructure = outputStructures[
    outputStructureKey ?? defaultOutputStructure
  ]?.map((outputStructureKey) =>
    outputStructureKey === "buckets"
      ? bucketsRequests.map(({ key }) => key)
      : outputStructureKey
  );
  outputStructure = outputStructure ? flatten(outputStructure) : null;

  return {
    ...rest,
    key,
    alias,
    keys,
    bucketsKeys,
    buckets: bucketsRequests,
    entityId,
    telemetry: telemetryRequest,
    outputStructure,
  };
};
const getBucketsRequest = (
  bucketsRequest,
  entityId,
  telemetryRequest,
  alias
) => {
  if (isString(bucketsRequest)) {
    bucketsRequest = bucketsConfigs[bucketsRequest];
  }
  bucketsRequest = { ...bucketsConfigs[bucketsRequest.key], ...bucketsRequest };
  switch (bucketsRequest.type) {
    case "telemetry": {
      entityId = bucketsRequest.entityId ?? entityId;
      bucketsRequest = {
        entityId,
        ...bucketsRequest,
        keyPath: bucketsRequest.keyPath ?? [
          "telemetry",
          entityId,
          bucketsRequest.telemetry,
          alias ?? bucketsRequest.key,
        ],
      };
      const key = `telemetry[${bucketsRequest.key}][${bucketsRequest.entityId}][${bucketsRequest.telemetry}]`;
      bucketsRequest.key = key;
      break;
    }
    case "time": {
      bucketsRequest = {
        entityId,
        telemetry: telemetryRequest,
        ...bucketsRequest,
        keyPath: bucketsRequest.keyPath ?? [
          "time",
          alias ?? bucketsRequest.key,
        ],
        timeBucketsResolutionKey:
          bucketsRequest.timeBucketsResolutionKey ?? bucketsRequest.key,
      };
      const key = `time[${bucketsRequest.key}]`;
      bucketsRequest.key = key;
      break;
    }
    case "bucket": {
      bucketsRequest = {
        ...bucketsRequest,
        keyPath: bucketsRequest.keyPath ?? [
          "bucket",
          alias ?? bucketsRequest.key,
        ],
      };
      const key = `bucket[${bucketsRequest.key}]`;
      bucketsRequest.key = key;
      break;
    }
    default: {
      throw new Error(
        `Unknown buckets type "${bucketsRequest.type}" expected telemetry,time,bucket`
      );
    }
  }
  return bucketsRequest;
};

const EMPTY_OBJECT = {};
function DataLink(triggerChange) {
  const self = this;

  let loadedTelemetries = {};
  let computedTelemetries = {};
  let outputTelemetries = {};
  let resolutions = {};
  let keyedAggregations = {};
  let keyedBuckets = {};
  let keyedSegmentations = {};
  let outputInsights = {};

  let telmetriesComputer = new LinkedTelmetriesComputer(
    (_computedTelemetries) => {
      computedTelemetries = _computedTelemetries;
      if (lastLinkArgs.computeTelemetriesRequests.length) {
        outputTelemetries = level2Merge(
          loadedTelemetries,
          _computedTelemetries
        );
      } else {
        outputTelemetries = loadedTelemetries;
      }
      triggerChange();
    }
  );
  const telmetriesAggregator = createLinkedTelemetriesAggregator(() => {
    updateAggregations();
    triggerChange();
  });
  const telmetriesBucketer = createLinkedTelemetriesBucketer(() => {
    updateBuckets();
    triggerChange();
  });
  const telmetriesSegmentator = createLinkedTelemetriesSegmentator(() => {
    updateSegmentations();
    triggerChange();
  });

  const updateAggregations = () => {
    const aggregationsRequests = lastLinkArgs.aggregationsRequests;
    if (aggregationsRequests.length === 0) {
      keyedAggregations = EMPTY_OBJECT;
      return;
    }
    const aggregations = telmetriesAggregator.getValue();
    const nextKeyedAggregations = {};
    aggregationsRequests.forEach(
      ({
        entityId,
        key,
        telemetry: telemetryRequest,
        filterKey = "none",
        keyPath,
      }) => {
        const telemetry = telemetryRequest
          ? getTelemetryFromEntityTelemetries(
              outputTelemetries[entityId],
              telemetryRequest
            )
          : null;
        const telemetryKey = telemetry?.key;
        set(
          nextKeyedAggregations,
          keyPath,
          get(aggregations, [entityId, telemetryKey, key, filterKey])
        );
      }
    );
    keyedAggregations = nextKeyedAggregations;
  };
  const updateBuckets = () => {
    const bucketsRequests = lastLinkArgs.bucketsRequests;
    if (bucketsRequests.length === 0) {
      keyedBuckets = EMPTY_OBJECT;
      return;
    }
    const buckets = telmetriesBucketer.getValue();
    const nextKeyedBuckets = {};
    bucketsRequests.forEach(
      ({
        type,
        entityId,
        key,
        telemetry: telemetryRequest,
        keyPath: outputKeyPath,
      }) => {
        const telemetry = telemetryRequest
          ? getTelemetryFromEntityTelemetries(
              outputTelemetries[entityId],
              telemetryRequest
            )
          : null;
        let inputKeyPath;
        switch (type) {
          case "time":
            inputKeyPath = [type, telemetry.intervals.key, key];
            break;
          case "bucket":
            inputKeyPath = [type, key];
            break;
          default:
            inputKeyPath = [type, entityId, telemetry.key, key];
            break;
        }
        set(nextKeyedBuckets, outputKeyPath, get(buckets, inputKeyPath));
      }
    );
    keyedBuckets = nextKeyedBuckets;
  };
  const updateSegmentations = () => {
    const segmentationsRequests = lastLinkArgs.segmentationsRequests;
    if (segmentationsRequests.length === 0) {
      keyedSegmentations = EMPTY_OBJECT;
      return;
    }
    const segmentations = telmetriesSegmentator.getValue();
    const nextKeyedSegmentations = {};
    segmentationsRequests.forEach(
      ({
        entityId,
        telemetry: telemetryRequest,
        filterKey = "none",
        keys,
        outputStructure,
        bucketsKeys,
        buckets,
      }) => {
        const telemetry = getTelemetryFromEntityTelemetries(
          outputTelemetries[entityId],
          telemetryRequest
        );
        const telemetryKey = telemetry.key;

        const nodeValue = get(segmentations, [
          entityId,
          telemetryKey,
          filterKey,
          bucketsKeys,
        ]);

        if (!outputStructure) {
          set(
            nextKeyedSegmentations,
            [keys.entity, keys.telemetry, keys.segmentation],
            nodeValue
          );
        } else {
          const length = buckets.length;
          const setValue = (keys, value) => {
            set(
              nextKeyedSegmentations,
              outputStructure.map((keyName) => keys[keyName]),
              value
            );
          };
          const setSegmentation = (source, keys, index = 0) => {
            const isLeaf = index === length;
            const isAggregation = index === 0;
            Object.entries(source).forEach(([key, value]) => {
              if (isAggregation) {
                setSegmentation(
                  value,
                  { ...keys, aggregation: key },
                  index + 1
                );
              } else if (isLeaf) {
                setValue({ ...keys, [buckets[index - 1].key]: key }, value);
              } else {
                setSegmentation(
                  value,
                  { ...keys, [buckets[index - 1].key]: key },
                  index + 1
                );
              }
            });
          };
          setSegmentation(nodeValue, keys);
        }
      }
    );
    keyedSegmentations = nextKeyedSegmentations;
  };

  const notifierEvent = new SingleEvent();
  let notifierStatus = {
    loading: false,
    error: null,
    warning: null,
    wasTrimmedFromServer: false,
    coverage: 0,
  };
  const Notifier = getLinkedNotifier({
    event: notifierEvent,
    initializer: () => notifierStatus,
    defaultErrorTranslationKey: "error.ERROR_LOADING_TELEMETRIES",
    defaultLoadingTranslationKey: "message.REFRESHING_TELEMETRIES",
    defaultWarningTranslationKey: "warning.TELEMETRIES_TRIMMED_FROM_SERVER",
    getErrorTranslationKey: (error, defaultTranslationKey) =>
      error?.messageKey ??
      error?.errors?.find((error) => error.messageKey)?.messageKey ??
      (navigator.onLine ? defaultTranslationKey : "error.YOU_ARE_OFFLINE"),
  });
  const updateNotifier = () => {
    const errors = [];
    let wasTrimmedFromServer = false;
    let loading = false;
    let coverageSum = 0;
    let coverageCount = 0;

    const processTelemetry = (telemetry) => {
      if (telemetry.error) {
        errors.push(telemetry.error);
      }
      loading = loading || telemetry.loading;
      wasTrimmedFromServer =
        wasTrimmedFromServer || telemetry.wasTrimmedFromServer;
      coverageSum += telemetry.coverage;
      coverageCount++;
    };
    const processInsights = (insights) => {
      if (insights.error) {
        errors.push(insights.error);
      }
      loading = loading || insights.loading;
    };
    Object.values(loadedTelemetries).forEach((telemetryLevel) => {
      Object.values(telemetryLevel).forEach((telemetry) => {
        processTelemetry(telemetry);
      });
    });
    Object.values(resolutions).forEach((entityLevel) => {
      Object.values(entityLevel).forEach((telemetryLevel) => {
        Object.values(telemetryLevel).forEach((telemetry) => {
          processTelemetry(telemetry);
        });
      });
    });
    Object.values(outputInsights).forEach((entityLevel) => {
      Object.values(entityLevel).forEach((insights) => {
        processInsights(insights);
      });
    });

    let error;
    if (errors.length) {
      error = new Error("Error loading data");
      error.errors = errors;
    }
    const coverage = coverageCount === 0 ? 1 : coverageSum / coverageCount;
    notifierStatus = {
      loading,
      error,
      warning: wasTrimmedFromServer,
      wasTrimmedFromServer,
      coverage,
    };
    if (!notifierEvent.destroyed) {
      notifierEvent.triggerEvent(notifierStatus);
    }
  };

  const setTelemetriesAndResolutions = (responses) => {
    if (responses.length === 0) {
      loadedTelemetries = EMPTY_OBJECT;
      resolutions = EMPTY_OBJECT;
      return;
    }
    loadedTelemetries = { ...loadedTelemetries };
    resolutions = { ...resolutions };
    responses.forEach(({ pathData, telemetry }) => {
      let telemetryLevel;
      if (pathData.resolutionKey) {
        const entityLevel = { ...resolutions[pathData.resolutionKey] };
        resolutions[pathData.resolutionKey] = entityLevel;

        telemetryLevel = { ...entityLevel[pathData.entityId] };
        entityLevel[pathData.entityId] = telemetryLevel;
      } else {
        telemetryLevel = { ...loadedTelemetries[pathData.entityId] };
        loadedTelemetries[pathData.entityId] = telemetryLevel;
      }

      if (pathData.aggregationPath) {
        if (!telemetryLevel[pathData.alias]) {
          telemetryLevel[pathData.alias] = {};
        }
        telemetryLevel[pathData.alias][pathData.aggregationPath] = telemetry;
      } else {
        telemetryLevel[pathData.alias] = telemetry;
      }
    });
  };

  let callbackTimer, responses;
  const callback = (pathData, telemetry) => {
    if (callbackTimer) {
      clearTimeout(callbackTimer);
      callbackTimer = null;
    }
    if (!responses) {
      responses = [];
    }
    responses.push({ pathData, telemetry });
    callbackTimer = setTimeout(() => {
      setTelemetriesAndResolutions(responses);

      telmetriesComputer.hydrate(
        lastLinkArgs.computeTelemetriesRequests,
        loadedTelemetries,
        outputInsights,
        lastLinkArgs.buildingData
      );
      if (lastLinkArgs.computeTelemetriesRequests.length) {
        computedTelemetries = telmetriesComputer.value;
        outputTelemetries = level2Merge(loadedTelemetries, computedTelemetries);
      } else {
        outputTelemetries = loadedTelemetries;
      }

      telmetriesAggregator.hydrate(lastLinkArgs.aggregationsRequests, {
        telemetries: outputTelemetries,
        buildingData: lastLinkArgs.buildingData,
      });
      updateAggregations();

      telmetriesBucketer.hydrate(lastLinkArgs.bucketsRequests, {
        telemetries: outputTelemetries,
        buildingData: lastLinkArgs.buildingData,
      });
      updateBuckets();

      telmetriesSegmentator.hydrate(lastLinkArgs.segmentationsRequests, {
        telemetries: outputTelemetries,
        buildingData: lastLinkArgs.buildingData,
        buckets: keyedBuckets,
      });
      updateSegmentations();

      callbackTimer = null;
      responses = null;
      updateNotifier();
      triggerChange();
    });
  };

  const setInsights = (
    insightsResponses,
    insightsRequestsWithOptions,
    lastTs,
    buildingData
  ) => {
    if (insightsRequestsWithOptions.length === 0) {
      outputInsights = EMPTY_OBJECT;
      return;
    }

    outputInsights = {};
    insightsRequestsWithOptions.forEach(
      ({ key, alias = key, entity, relations, filter, postProcessor }) => {
        let insights = flatten(
          relations.map((entity) => {
            const entityId = entity.id;
            return insightsResponses[entityId];
          })
        );
        const filteredAndSortedInsights = orderBy(
          insights.filter(filter),
          [
            ({ endTs }) => (endTs ? 0 : 1),
            ({ payload }) => payload.priorityRanking ?? Number.MIN_SAFE_INTEGER,
            "startTs",
            "endTs",
            "id",
          ],
          ["desc", "desc", "asc", "desc", "asc"]
        );

        const groups = groupBy(filteredAndSortedInsights, "payload.tag");
        const processedInsights = filteredAndSortedInsights.map((insight) =>
          postProcessor(insight, { lastTs, buildingData, groups })
        );

        set(outputInsights, [entity.id, alias], processedInsights);
      }
    );
  };

  let insightsCallbackTimer,
    insightsByEntityId = {};
  const insightsCallback = (entityInsights) => {
    if (insightsCallbackTimer) {
      clearTimeout(insightsCallbackTimer);
      insightsCallbackTimer = null;
    }

    insightsByEntityId[entityInsights.entityId] = entityInsights.data;

    insightsCallbackTimer = setTimeout(() => {
      setInsights(
        insightsByEntityId,
        lastLinkArgs.insightsRequestsWithOptions,
        lastLinkArgs.lastTs,
        lastLinkArgs.buildingData
      );

      telmetriesComputer.hydrate(
        lastLinkArgs.computeTelemetriesRequests,
        loadedTelemetries,
        outputInsights,
        lastLinkArgs.buildingData
      );
      if (lastLinkArgs.computeTelemetriesRequests.length) {
        computedTelemetries = telmetriesComputer.value;
        outputTelemetries = level2Merge(loadedTelemetries, computedTelemetries);
      } else {
        outputTelemetries = loadedTelemetries;
      }

      telmetriesAggregator.hydrate(lastLinkArgs.aggregationsRequests, {
        telemetries: outputTelemetries,
        buildingData: lastLinkArgs.buildingData,
      });
      updateAggregations();

      telmetriesBucketer.hydrate(lastLinkArgs.bucketsRequests, {
        telemetries: outputTelemetries,
        buildingData: lastLinkArgs.buildingData,
      });
      updateBuckets();

      telmetriesSegmentator.hydrate(lastLinkArgs.segmentationsRequests, {
        telemetries: outputTelemetries,
        buildingData: lastLinkArgs.buildingData,
        buckets: keyedBuckets,
      });
      updateSegmentations();

      insightsCallbackTimer = null;
      updateNotifier();
      triggerChange();
    });
  };

  let unlinkFunctionsIx = {};
  let insightsUnlinkFunctionsIx = {};
  const unlink = () => {
    Object.values(unlinkFunctionsIx).forEach((unlinkTelemetry) => {
      unlinkTelemetry();
    });
    Object.values(insightsUnlinkFunctionsIx).forEach((unlinkInsights) => {
      unlinkInsights();
    });
    telmetriesAggregator.unlink();
    telmetriesBucketer.unlink();
    telmetriesSegmentator.unlink();
    telmetriesComputer.unlink();
  };

  let lastLinkArgs;

  /**
   * @param {number} from
   * @param {number} to
   * @param {LinkedDataRequest[]} requests
   * @param {import('../helpers/postProcessBuildingData').BuildingData} buildingData
   * @param {number} lastTs
   */
  const link = (from, to, requests, buildingData, lastTs) => {
    if (
      lastLinkArgs &&
      lastLinkArgs.from === from &&
      lastLinkArgs.to === to &&
      lastLinkArgs.requests === requests &&
      lastLinkArgs.buildingData === buildingData &&
      lastLinkArgs.lastTs === lastTs
    ) {
      return;
    }
    lastLinkArgs = { from, to, requests, buildingData, lastTs };

    const { building: rootEntity, entitiesById } = buildingData;

    const newUnlinkFunctionsIx = {};
    const syncResponses = [];
    const computeTelemetriesRequests = [];
    const aggregationsRequests = [];
    const bucketsRequests = [];
    const segmentationsRequests = [];
    const insightsRequestsWithOptions = [];
    const insightsEntities = [];

    requests.forEach((request) => {
      const {
        entityId,
        resolutionKey = null,
        telemetries: telemetriesRequests,
        insights: insightsRequests,
      } = request;
      const entity = entitiesById[entityId];
      telemetriesRequests?.forEach((telemetryRequest) => {
        if (telemetryRequest.aggregations) {
          aggregationsRequests.push(
            ...telemetryRequest.aggregations.map((aggregationRequest) =>
              getAggregationRequest(
                aggregationRequest,
                entityId,
                telemetryRequest
              )
            )
          );
        }
        if (telemetryRequest.segmentations) {
          telemetryRequest.segmentations.forEach((segmentationRequest) => {
            segmentationRequest = getSegmentationRequest(
              segmentationRequest,
              entityId,
              telemetryRequest
            );
            segmentationsRequests.push(segmentationRequest);
            bucketsRequests.push(...segmentationRequest.buckets);
          });
        }
        if (telemetryRequest.computeFunction) {
          const {
            telemetryParams: telemetryExtraParams,
            computeFunction,
            ...telemetryParams
          } = telemetryRequest;
          computeTelemetriesRequests.push({
            telemetryParams: {
              entityId,
              ...telemetryParams,
              ...telemetryExtraParams,
            },
            computeFunction,
          });
          return;
        }

        if (isString(telemetryRequest)) {
          telemetryRequest = { telemetryName: telemetryRequest };
        } else if (telemetryRequest.name) {
          telemetryRequest.telemetryName = telemetryRequest.name;
        }
        let { telemetryName, dimension, alias, resolutionAggregator } =
          telemetryRequest;

        dimension = dimension ? getStableKey(dimension) : null;
        alias = alias ?? dimension ?? telemetryName;

        const baseTelemetry = entity.telemetriesByName?.[telemetryName] ?? null;
        let telemetry = baseTelemetry;
        telemetry = dimension
          ? telemetry?.dimensionsByKey?.[dimension] ?? null
          : telemetry;
        const dimensionKey = telemetry?.dimensionKey ?? dimension;
        const dimensionAlias = telemetry?.dimensionAlias ?? dimension;

        if (resolutionKey) {
          telemetry = resolutionAggregator
            ? telemetry?.resolutionsByKey?.[resolutionKey].filter(
                (resolution) =>
                  resolutionAggregator.includes(
                    resolution.downsamplingAggregationMethod
                  ) ||
                  (resolutionAggregator.find(
                    (aggregation) => aggregation === "average"
                  ) &&
                    !resolution.downsamplingAggregationMethod)
              )
            : [
                telemetry?.resolutionsByKey?.[resolutionKey].find(
                  (resolution) =>
                    !resolution.downsamplingAggregationMethod ||
                    resolution.downsamplingAggregationMethod === "average"
                ) ?? telemetry?.resolutionsByKey?.[resolutionKey][0],
              ];
        } else {
          telemetry = [telemetry];
        }

        telemetry.forEach((telemetry) => {
          const timeseriesId = telemetry?.timeseriesId;

          const downsamplingAggregationMethod =
            telemetry?.downsamplingAggregationMethod;
          const aggregationPath =
            downsamplingAggregationMethod &&
            downsamplingAggregationMethod !== "average"
              ? downsamplingAggregationMethod
              : resolutionAggregator?.find(
                  (aggregator) => aggregator === "average"
                )
              ? "average"
              : undefined;

          const pathData = {
            entityId,
            telemetryName,
            alias,
            dimensionAlias,
            dimensionKey,
            resolutionKey,
            aggregationPath,
          };

          const {
            key,
            baseIntervals,
            resolutionIntervals,
            gracePeriod,
            interval,
          } = getTelemetryLinkData({
            rootEntity,
            entity,
            pathData,
            baseTelemetry,
            telemetry,
            timeseriesId,
            from,
            to,
          });

          if (unlinkFunctionsIx[key]) {
            let cursor;
            if (pathData.resolutionKey) {
              cursor = resolutions[pathData.resolutionKey];
            } else {
              cursor = loadedTelemetries;
            }
            let telemetry = cursor[pathData.entityId][pathData.alias];
            syncResponses.push({
              pathData,
              telemetry,
            });
            newUnlinkFunctionsIx[key] = unlinkFunctionsIx[key];
            delete unlinkFunctionsIx[key];
            return;
          }
          if (newUnlinkFunctionsIx[key]) {
            return;
          }

          let syncAnswered = false;
          newUnlinkFunctionsIx[key] = linkTelemetry(
            {
              key,
              baseIntervals,
              resolutionIntervals,
              rootEntity,
              entity,
              pathData,
              baseTelemetry,
              telemetry,
              timeseriesId,
              interval,
              gracePeriod,
            },
            (telemetry) => {
              if (!syncAnswered) {
                syncAnswered = true;
                syncResponses.push({
                  pathData,
                  telemetry,
                });
                return;
              }
              callback(pathData, telemetry);
            }
          );
        });
      });

      insightsRequests?.forEach(({ key, alias = key, system, category }) => {
        const dimension = { system, category };
        const stableKey = getStableKey(dimension);
        let insightsType = indexedInsightsTypes[stableKey];
        const { childrenTypes, connectionsChains, filter, postProcessor } =
          insightsType;
        const relations = getRelations(
          entity,
          childrenTypes,
          connectionsChains
        );
        insightsEntities.push(relations);
        insightsRequestsWithOptions.push({
          key: key ?? stableKey,
          alias,
          entity,
          relations,
          filter,
          postProcessor,
        });
      });
    });

    const newInsightsById = {};
    const newInsightsUnlinkFunctionsIx = {};
    uniqBy(flatten(insightsEntities), "id").forEach((entity) => {
      const entityId = entity.id;

      const { key, baseIntervals, gracePeriod, interval } = getInsightsLinkData(
        {
          rootEntity,
          entity,
          from,
          to,
        }
      );

      if (insightsUnlinkFunctionsIx[key]) {
        newInsightsById[entityId] = insightsByEntityId[entityId];
        newInsightsUnlinkFunctionsIx[key] = insightsUnlinkFunctionsIx[key];
        delete insightsUnlinkFunctionsIx[key];
        return;
      }
      if (newInsightsUnlinkFunctionsIx[key]) {
        return;
      }

      let syncAnswered = false;
      newInsightsUnlinkFunctionsIx[key] = linkInsights(
        {
          key,
          baseIntervals,
          rootEntity,
          entity,
          interval,
          gracePeriod,
        },
        (insights) => {
          if (!syncAnswered) {
            syncAnswered = true;
            newInsightsById[insights.entityId] = insights.data;
            return;
          }
          insightsCallback(insights);
        }
      );
    });

    insightsByEntityId = newInsightsById;

    lastLinkArgs.insightsRequestsWithOptions = insightsRequestsWithOptions;
    lastLinkArgs.computeTelemetriesRequests = computeTelemetriesRequests;
    lastLinkArgs.aggregationsRequests = aggregationsRequests;
    lastLinkArgs.bucketsRequests = bucketsRequests;
    lastLinkArgs.segmentationsRequests = segmentationsRequests;

    setInsights(
      newInsightsById,
      insightsRequestsWithOptions,
      lastTs,
      buildingData
    );

    setTelemetriesAndResolutions(syncResponses);

    telmetriesComputer.hydrate(
      computeTelemetriesRequests,
      loadedTelemetries,
      outputInsights,
      buildingData
    );
    if (computeTelemetriesRequests.length) {
      computedTelemetries = telmetriesComputer.value;
      outputTelemetries =
        loadedTelemetries === EMPTY_OBJECT
          ? computedTelemetries
          : level2Merge(loadedTelemetries, computedTelemetries);
    } else {
      computedTelemetries = EMPTY_OBJECT;
      outputTelemetries = loadedTelemetries;
    }

    telmetriesAggregator.hydrate(aggregationsRequests, {
      telemetries: outputTelemetries,
      buildingData,
    });
    updateAggregations();

    telmetriesBucketer.hydrate(bucketsRequests, {
      telemetries: outputTelemetries,
      buildingData,
    });
    updateBuckets();

    telmetriesSegmentator.hydrate(segmentationsRequests, {
      telemetries: outputTelemetries,
      buildingData,
      buckets: keyedBuckets,
    });
    updateSegmentations();

    Object.values(unlinkFunctionsIx).forEach((unlinkTelemetry) => {
      unlinkTelemetry();
    });
    unlinkFunctionsIx = newUnlinkFunctionsIx;
    updateNotifier();
  };

  self.link = link;
  self.destroy = () => {
    if (!notifierEvent.destroyed) {
      notifierStatus = {
        loading: false,
        error: null,
        warning: false,
        wasTrimmedFromServer: false,
        coverage: 1,
      };
      notifierEvent.triggerEvent(notifierStatus);
      notifierEvent.destroy();
    }
    unlink();
  };
  self.Notifier = Notifier;
  self.getState = () => ({
    insights: outputInsights,
    telemetries: outputTelemetries,
    resolutions,
    aggregations: keyedAggregations,
    buckets: keyedBuckets,
    segmentations: keyedSegmentations,
    Notifier,
    ...notifierStatus,
  });

  return self;
}

/**
 * @callback GetDataRequestsCallback
 * @returns {LinkedDataRequest[]}
 */
/**
 * @typedef {Object} LoadTelemetryRequest
 * @property {string} telemetryName
 * @property {string} [name] alias for telemetryName
 * @property {string|Object} [dimension]
 * @property {string} [alias]
 * @property {AggregationRequest[]} [aggregations]
 * @property {SegmentationRequest[]} [segmentations]
 */
/**
 * @typedef {Object} TelemetryExtraParams
 * @property {string} timeQuantizationAggregationMethod
 * @property {string} interpolationMethod
 * @property {string} maxInterpolationDuration
 * @property {string} downsamplingAggregationMethod
 * @property {string} upsamplingAggregationMethod
 */
/**
 * @typedef {Object} ExtraAggregationsOptions
 * @property {string[]} [keyPath] the output key path where the aggregation is placed. like if you specify ['one','two'] you can then access your aggregation using `aggreagations.one.two`. If not specified will default to: [entityId, telemetryAlias|telemetryName, key] where key is the aggregation key specified in the aggregation request
 * @property {string} [alias] only applies if keyPath is not defined. will replace the `key` of the aggregation in the returned aggregations. usually used if you have same key and different `filterKeys` (meaning with different filters applied)
 */
/**
 * @typedef {import('../helpers/createLinkedTelemetriesAggregator').AggregationRequest} BaseAggregationRequest
 * @typedef {Omit<Omit<BaseAggregationRequest,"entityId">,"telemetry">} StrippedAggregationRequest
 * @typedef {StrippedAggregationRequest & ExtraAggregationsOptions} ObjectAggregationRequest
 * @typedef {ObjectAggregationRequest | import('../helpers/createLinkedTelemetriesAggregator').AggregationKeys} AggregationRequest
 * @typedef {import('./useLinkedData.types').SegmentationRequest} SegmentationRequest
 */
/**
 * @typedef {Object} ComputeTelemetryRequest
 * @property {import('../helpers/LinkedTelemetriesComputer').ComputeFunction} computeFunction
 * @property {string} telemetryName
 * @property {string} [name] alias for telemetryName
 * @property {string} [alias]
 * @property {string} [displayKey]
 * @property {string|import('types/unit').Unit|import('types/unit').Currency} [unit]
 * @property {TelemetryExtraParams} [telemetryParams]
 * @property {AggregationRequest[]} [aggregations]
 * @property {SegmentationRequest[]} [segmentations]
 */
/**
 * @typedef {string|LoadTelemetryRequest|ComputeTelemetryRequest} TelemetryRequest
 */
/**
 * @typedef {Object} InsightsRequest
 * @property {string} [alias]
 * @property {import('../helpers/insightsUtils').InsightsSystems} [system]
 * @property {import('../helpers/insightsUtils').InsightsCategories} [category]
 */
/**
 * @typedef {{[entityId: string]: {[insightsKeyOrAlias:string]: import('./useInsights').Insight[]}}} InsightsReponse
 */
/**
 * @typedef {Object} LinkedDataRequest
 * @property {string} entityId
 * @property {string} [resolutionKey]
 * @property {TelemetryRequest[]} [telemetries]
 * @property {InsightsRequest[]} [insights]
 */
/**
 * @typedef {Object<string,LinkedTelemetry>} LinkedTelemetriesResponseL1
 * @typedef {Object<string,LinkedTelemetriesResponseL1>} LinkedTelemetriesResponseL2
 * @typedef {Object<string,LinkedTelemetriesResponseL2>} LinkedTelemetriesResponseL3
 */
/**
 * @typedef {Object} LinkedDataResponse
 * @property {boolean} loading
 * @property {Error} [error]
 * @property {any} [warning]
 * @property {boolean} [wasTrimmedFromServer]
 * @property {number} coverage
 * @property {import('react').FunctionComponent<import('helpers/getNotifier').LinkedNotifierParams>} Notifier
 * @property {LinkedTelemetriesResponseL2} telemetries
 * @property {LinkedTelemetriesResponseL3} resolutions
 * @property {{[entityId: string]: {[telemetryName:string]: {[aggregationKey: string]: {value: import("types/utils").Nullable<number>, unit: import("types/unit").Unit | import("types/unit").Currency}}}}} aggregations
 * @property {any} segmentations
 * @property {any} buckets
 * @property {InsightsReponse} insights
 */

const identity = (a) => a;
/**
 * @param {number} from
 * @param {number} to
 * @param {LinkedDataRequest[]} requests
 * @returns {LinkedDataResponse}
 */
const useLinkedData = (from, to, requests) => {
  let postProcessState = identity;
  if (!Array.isArray(requests)) {
    if (config.isProd) {
      console.warn(
        new Error(`None array requests not supported in useLinkedData`)
      );
      const singularEntityId = requests.entityId;
      const singularResolutionKey = requests.resolutionKey;
      requests = [requests];
      postProcessState = ({ telemetries, resolutions, ...rest }) => ({
        telemetries:
          telemetries[singularEntityId] ??
          resolutions[singularResolutionKey]?.[singularEntityId],
        resolutions: resolutions[singularResolutionKey]?.[singularEntityId],
        ...rest,
      });
    } else {
      throw new Error(`None array requests not supported in useLinkedData`);
    }
  }
  const { buildingData } = useBuildingData();
  const liveListener = useLiveListener();
  const lastTs = liveListener.getLastTimestamp();

  const telemetryLinkRef = useRef();
  const [, setDummy] = useState();
  const telemetryLink =
    telemetryLinkRef.current ??
    (telemetryLinkRef.current = new DataLink(() => setDummy({})));

  telemetryLink.link(from, to, requests, buildingData, lastTs);

  useEffect(() => telemetryLink.destroy, []);

  return postProcessState(telemetryLink.getState());
};

export const useMemoedLinkedData = (
  from,
  to,
  getRequestsCallback,
  getRequestsDependencies
) =>
  useLinkedData(
    from,
    to,
    useMemo(getRequestsCallback, getRequestsDependencies)
  );

/**
 * @param {LinkedDataRequest[]} requests
 * @returns {LinkedDataResponse}
 */
export const useCurrentLinkedData = (requests) => {
  const { fromTs, toTs } = useFullDateFilterState();
  return useLinkedData(fromTs, toTs, requests);
};

/**
 * @param {GetDataRequestsCallback} getRequestsCallback
 * @param {any[]} getRequestsDependencies
 */
export const useMemoedCurrentLinkedData = (
  getRequestsCallback,
  getRequestsDependencies
) => {
  const { fromTs, toTs } = useFullDateFilterState();
  return useLinkedData(
    fromTs,
    toTs,
    useMemo(getRequestsCallback, getRequestsDependencies)
  );
};

/**
 * @param {number} timestamp
 * @param {GetDataRequestsCallback} getRequestsCallback
 * @param {any[]} getRequestsDependencies
 * @param {number} [daysArround] defaults to 2
 */
export const useMemoedLinkedDataAroundTimestamp = (
  timestamp,
  getRequestsCallback,
  getRequestsDependencies,
  daysArround = 2
) => {
  const { buildingData } = useBuildingData();
  const { timezone } = buildingData;
  const { fromTs: timestampDayFromTs } = getDayFromTo({
    reference: timestamp,
    timezone,
  });
  const [fromTs, toTs] = useMemo(() => {
    const dateTime = fromMillis(timestampDayFromTs);
    return [
      dateTime
        .set({
          day: dateTime.day - daysArround,
        })
        .set({ hour: 0 })
        .toMillis(),
      dateTime
        .set({
          day: dateTime.day + daysArround + 1,
        })
        .set({ hour: 0 })
        .toMillis(),
    ];
  }, [timestampDayFromTs]);
  return useLinkedData(
    fromTs,
    toTs,
    useMemo(getRequestsCallback, getRequestsDependencies)
  );
};

/**
 * @param {LinkedTelemetriesResponseL1} entityTelemetries
 * @param {TelemetryRequest} telemetryRequest
 * @returns {LinkedTelemetry}
 */
export const getTelemetryFromEntityTelemetries = (
  entityTelemetries,
  telemetryRequest
) => {
  if (isString(telemetryRequest)) {
    return entityTelemetries[telemetryRequest];
  }
  return entityTelemetries[
    telemetryRequest.alias ??
      telemetryRequest.telemetryName ??
      telemetryRequest.name
  ];
};

export default useLinkedData;
