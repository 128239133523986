import { useEffect, useState } from "react";
import { SingleEvent } from "../helpers/events";
import getWindowSize from "../helpers/getWindowSize";

const windowSizeEvent = new SingleEvent();
let windowSize = getWindowSize();
window.addEventListener("resize", () => {
  windowSize = getWindowSize();
  windowSizeEvent.triggerEvent(windowSize);
});

export const useWindowSize = (debounceDuration = 300) => {
  const [size, setSize] = useState(windowSize);
  useEffect(() => {
    let timer;
    const clearEvent = windowSizeEvent.addListener((size) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setSize(size);
      }, debounceDuration);
    });
    return () => {
      clearTimeout(timer);
      clearEvent();
    };
  }, [debounceDuration]);
  return size;
};
