import {
  useRef,
  type ReactNode,
  type MouseEventHandler,
  useCallback,
} from "react";
import { addToast } from "../../../modules/toast";
import { v4 as uuidV4 } from "uuid";
import { isToastVisible } from "../../../modules/toast/state/toast.state";
import classNames from "classnames";

import s from "./EllipsisContainer.module.scss";

type EllipsisContainerProps = {
  id?: string;
  className?: string;
  children: ReactNode;
  // Number of vertical lines for ellipsis
  numberOfLines?: number;
  style?: React.CSSProperties;
  variant?: string;
  clickThroughOnlyWhenToastIsVisible?: boolean;
  toastOptions?: {};
};

const EllipsisContainer = ({
  id,
  className,
  children,
  numberOfLines = 1,
  style,
  toastOptions,
  variant = "primary",
  clickThroughOnlyWhenToastIsVisible = false,
  ...props
}: EllipsisContainerProps) => {
  const cntRef = useRef<HTMLDivElement>(null);
  const ellipsisRef = useRef<HTMLSpanElement>(null);
  const mutable = useRef<{ toastId: string | null }>({ toastId: null }).current;
  const toastId = mutable.toastId ?? (mutable.toastId = uuidV4());
  const isMultiLine = numberOfLines > 1;

  const isOverflowed = useCallback(() => {
    const cntRefCurrent = cntRef.current;
    const ellipsisRefCurrent = ellipsisRef.current;

    if (cntRefCurrent && ellipsisRefCurrent) {
      // Check onelineOverflow
      const oneLineOverFlow =
        ellipsisRefCurrent.offsetWidth > cntRefCurrent.offsetWidth;

      // If multiline then check offsetHeight
      const multiLineOverFlow = isMultiLine
        ? ellipsisRefCurrent.offsetHeight - 1 > cntRefCurrent.offsetHeight
        : false;

      return oneLineOverFlow || multiLineOverFlow;
    }
    return false;
  }, [cntRef, ellipsisRef, isMultiLine]);

  const ellipsisClick: MouseEventHandler = (e) => {
    if (!isToastVisible(toastId) && isOverflowed()) {
      if (clickThroughOnlyWhenToastIsVisible) {
        e.stopPropagation();
        e.preventDefault();
      }
      addToast(toastOptions ?? { id: toastId, children, variant });
    }
  };

  const mouseEnter = () => {
    const cntRefCurrent = cntRef.current!;

    if (isOverflowed()) {
      cntRefCurrent.style.cursor = "pointer";
      cntRefCurrent.title = cntRefCurrent.innerText;
    } else {
      cntRefCurrent.style.cursor = "";
      cntRefCurrent.removeAttribute("title");
    }
  };

  return (
    <div
      ref={cntRef}
      onMouseEnter={mouseEnter}
      style={{ WebkitLineClamp: numberOfLines, ...style }}
      className={classNames(
        className,
        { [s.ellipsisMulti]: isMultiLine },
        { [s.ellipsis]: !isMultiLine }
      )}
      {...props}
    >
      <span onClick={ellipsisClick} ref={ellipsisRef} id={id}>
        {children}
      </span>
    </div>
  );
};

export default EllipsisContainer;
