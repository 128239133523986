import withIconProps from "helpers/withIconProps";

const LinkedInIcon = withIconProps((props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.0983 0.697998H2.0511C1.65953 0.697998 1.28401 0.853546 1.00713 1.13042C0.730255 1.4073 0.574707 1.78282 0.574707 2.17439V19.2216C0.574707 19.6132 0.730255 19.9887 1.00713 20.2656C1.28401 20.5425 1.65953 20.698 2.0511 20.698H19.0983C19.4899 20.698 19.8654 20.5425 20.1423 20.2656C20.4192 19.9887 20.5747 19.6132 20.5747 19.2216V2.17439C20.5747 1.78282 20.4192 1.4073 20.1423 1.13042C19.8654 0.853546 19.4899 0.697998 19.0983 0.697998ZM6.53582 17.7355H3.52887V8.18411H6.53582V17.7355ZM5.03026 6.8605C4.68918 6.85858 4.3563 6.75566 4.07365 6.56474C3.791 6.37381 3.57124 6.10344 3.4421 5.78774C3.31297 5.47204 3.28025 5.12516 3.34807 4.79088C3.41589 4.4566 3.58121 4.14989 3.82317 3.90948C4.06513 3.66907 4.37289 3.50572 4.7076 3.44005C5.04231 3.37438 5.38897 3.40933 5.70383 3.54049C6.0187 3.67165 6.28766 3.89314 6.47676 4.17701C6.66587 4.46088 6.76664 4.79441 6.76637 5.1355C6.76959 5.36386 6.7268 5.59053 6.64055 5.802C6.5543 6.01347 6.42636 6.20541 6.26435 6.36639C6.10234 6.52736 5.90958 6.65407 5.69756 6.73896C5.48554 6.82385 5.2586 6.86518 5.03026 6.8605ZM17.6192 17.7438H14.6136V12.5258C14.6136 10.9869 13.9594 10.5119 13.115 10.5119C12.2233 10.5119 11.3483 11.1841 11.3483 12.5647V17.7438H8.34137V8.19105H11.233V9.51467H11.2719C11.5622 8.92717 12.5789 7.923 14.1303 7.923C15.808 7.923 17.6205 8.91883 17.6205 11.8355L17.6192 17.7438Z"
        fill="#0A66C2"
      />
    </svg>
  );
});

export default LinkedInIcon;
