import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import Section from "atomic-components/atoms/Section";
import SectionTitle from "atomic-components/atoms/SectionTitle";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import useBuildingData from "modules/building/hooks/useBuildingData";
import { useTranslate } from "modules/language";
import Info from "svg-icons/Info";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import UnitValue from "atomic-components/atoms/UnitValue";
import classNames from "classnames";
import TotalElectricityBreakdownFloors from "./TotalElectricityBreakdownFloors/TotalElectricityBreakdownFloors";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import { useMergedNotifier } from "helpers/getNotifier";

import s from "./TotalElectricityBreakdown.module.scss";
import { useElectricityEmissisons } from "modules/building/hooks/useElectricityEmissions";

const TotalElectricityBreakdown = () => {
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);

  const { totalElectricityEmissions, Notifier: ENotifier } =
    useElectricityEmissisons();

  const {
    state: { electricityEmissionIntensity },
    Notifier: EditableParamsNotifier,
  } = useEditableParamsState();

  const {
    resolutions,
    telemetries,
    Notifier: telemetriesNotifier,
  } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        resolutionKey: "1month",
        telemetries: [
          {
            telemetryName: "elevatorsElectricity",
          },
          {
            telemetryName: "pumpsElectricity",
          },
          {
            telemetryName: "waterFeatureElectricity",
          },
          {
            telemetryName: "outsideLightingElectricity",
          },
          {
            telemetryName: "dailyMaximumUniqueEmployees",
          },
        ],
      },
      {
        entityId: selectedEntity.id,
        telemetries: [
          {
            telemetryName: "monthlyElectricity",
          },
          { telemetryName: "monthlyPVElectricity" },
        ],
      },
    ],
    [selectedEntity]
  );
  const resolutionDemands = resolutions["1month"][selectedEntity.id];
  const averageMonthlyUniqueEmployees =
    resolutions["1month"][selectedEntity.id].dailyMaximumUniqueEmployees.data[0]
      .value;
  const numOfDays =
    resolutions["1month"][selectedEntity.id].dailyMaximumUniqueEmployees.days;

  const resolutionsConsumption = Object.fromEntries(
    Object.entries(resolutionDemands)
      .filter(
        ([telemetryName, _]) => telemetryName !== "dailyMaximumUniqueEmployees"
      )
      .map(([telemetryName, telemetry]) => [
        telemetryName,
        telemetry.data[0]?.value * telemetry.days * 24,
      ])
  );

  const {
    elevatorsElectricity,
    pumpsElectricity,
    waterFeatureElectricity,
    outsideLightingElectricity,
  } = resolutionsConsumption;

  const netCleanConsumption =
    telemetries[selectedEntity.id].monthlyElectricity.data[0]?.value;

  const pvConsumption =
    telemetries[selectedEntity.id].monthlyPVElectricity.data[0]?.value;

  const commonUtilitiesConsumption =
    elevatorsElectricity +
    pumpsElectricity +
    waterFeatureElectricity +
    outsideLightingElectricity;

  const allFloorsConsumption = commonUtilitiesConsumption
    ? netCleanConsumption + pvConsumption - commonUtilitiesConsumption
    : NaN;

  const t = useTranslate();

  const presumedEmissionsPopover = (
    <InfoPopover
      message={t("tooltip.PRESUMED_EMISSIONS")}
      floatingClassName={s.popoverContainer}
      placement="top"
    >
      <StrippedButton>
        <Info iconScale={1.2} color="var(--color-primary)" />
      </StrippedButton>
    </InfoPopover>
  );

  const Notifier = useMergedNotifier(
    telemetriesNotifier,
    EditableParamsNotifier,
    ENotifier
  );

  const [
    presumedCommonUtilitiesEmissions,
    pvEmissionsOffset,
    avgEmissionsPerPerson,
    avgEmissionsPerDay,
  ] = [
    commonUtilitiesConsumption,
    pvConsumption,
    netCleanConsumption / averageMonthlyUniqueEmployees,
    netCleanConsumption / numOfDays,
  ].map(
    (consumptionValue) =>
      consumptionValue * (electricityEmissionIntensity.value ?? NaN)
  );

  return (
    <Notifier>
      <WidgetContainer>
        <Section variant="withBorder">
          <div className={s.titleContainer}>
            <SectionTitle>{t("label.PRESUMED_FLOORS_EMISSIONS")}</SectionTitle>
            {presumedEmissionsPopover}
          </div>

          <TotalElectricityBreakdownFloors
            allFloorsConsumption={allFloorsConsumption}
          />
        </Section>
        <br />
        <Section variant="withBorder" className={s.fieldContainer}>
          <div>
            <KeyValue
              keyText={t("label.PRESUMED_COMMON_UTILITIES_EMISSIONS")}
              value={
                <>
                  <UnitValue
                    value={presumedCommonUtilitiesEmissions}
                    unit={unitsByName.kgco2e}
                  />
                  {presumedEmissionsPopover}
                </>
              }
            />
          </div>
        </Section>
        <br />
        <Section
          variant="withBorder"
          className={classNames(s.fieldContainer, s.pvFieldContainer)}
        >
          <KeyValue
            keyText={t("label.PV_PANEL_EMISSIONS_OFFSET")}
            value={
              <UnitValue value={-pvEmissionsOffset} unit={unitsByName.kgco2e} />
            }
          />
        </Section>
        <br />
        <Section
          variant="withBorder"
          className={classNames(s.fieldContainer, s.totalFieldContainer)}
        >
          <KeyValue
            keyText={t("label.NET_TOTAL_EMISSIONS")}
            value={
              <UnitValue
                value={totalElectricityEmissions}
                unit={unitsByName.kgco2e}
              />
            }
            keyClassName={s.totalFieldText}
            valueClassName={s.totalFieldText}
            isLight
          />
        </Section>
      </WidgetContainer>
      <br />
      <WidgetContainer>
        <KeyValue
          keyText={t("label.AVG_EMISSIONS_PER_PERSON")}
          value={
            <>
              <UnitValue
                value={avgEmissionsPerPerson}
                unit={unitsByName.kgco2e}
              />
              /
              <Displayer object={unitsByName.person} />
            </>
          }
          isLight
        />
      </WidgetContainer>
      <br />
      <WidgetContainer>
        <KeyValue
          keyText={t("label.AVG_EMISSIONS_PER_DAY")}
          value={
            <>
              <UnitValue value={avgEmissionsPerDay} unit={unitsByName.kgco2e} />
              /{t("label.DAY")}
            </>
          }
          isLight
        />
      </WidgetContainer>
    </Notifier>
  );
};

export default TotalElectricityBreakdown;
