import withIconProps from "helpers/withIconProps";

const PeopleIcon = withIconProps((props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="occupancy">
        <g id="icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.64124 16.1412C2.53204 15.2504 3.74022 14.75 5 14.75H13C14.2598 14.75 15.468 15.2504 16.3588 16.1412C17.2496 17.032 17.75 18.2402 17.75 19.5V21.5C17.75 21.9142 17.4142 22.25 17 22.25C16.5858 22.25 16.25 21.9142 16.25 21.5V19.5C16.25 18.638 15.9076 17.8114 15.2981 17.2019C14.6886 16.5924 13.862 16.25 13 16.25H5C4.13805 16.25 3.3114 16.5924 2.7019 17.2019C2.09241 17.8114 1.75 18.638 1.75 19.5V21.5C1.75 21.9142 1.41421 22.25 1 22.25C0.585786 22.25 0.25 21.9142 0.25 21.5V19.5C0.25 18.2402 0.750445 17.032 1.64124 16.1412Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 4.25C7.20507 4.25 5.75 5.70507 5.75 7.5C5.75 9.29493 7.20507 10.75 9 10.75C10.7949 10.75 12.25 9.29493 12.25 7.5C12.25 5.70507 10.7949 4.25 9 4.25ZM4.25 7.5C4.25 4.87665 6.37665 2.75 9 2.75C11.6234 2.75 13.75 4.87665 13.75 7.5C13.75 10.1234 11.6234 12.25 9 12.25C6.37665 12.25 4.25 10.1234 4.25 7.5Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.2738 15.4425C19.3774 15.0414 19.7864 14.8003 20.1875 14.9038C21.2065 15.1669 22.1093 15.761 22.7541 16.5928C23.3989 17.4246 23.7492 18.447 23.75 19.4994V21.5C23.75 21.9142 23.4142 22.25 23 22.25C22.5858 22.25 22.25 21.9142 22.25 21.5V19.5006C22.2494 18.7806 22.0097 18.0809 21.5686 17.5118C21.1274 16.9427 20.5097 16.5362 19.8125 16.3562C19.4114 16.2526 19.1703 15.8436 19.2738 15.4425Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2734 3.44397C15.3762 3.0427 15.7848 2.8007 16.186 2.90344C17.2078 3.16505 18.1134 3.75927 18.7601 4.59243C19.4068 5.42559 19.7578 6.4503 19.7578 7.505C19.7578 8.5597 19.4068 9.58441 18.7601 10.4176C18.1134 11.2507 17.2078 11.845 16.186 12.1066C15.7848 12.2093 15.3762 11.9673 15.2734 11.566C15.1707 11.1648 15.4127 10.7562 15.814 10.6534C16.5131 10.4744 17.1327 10.0679 17.5752 9.49781C18.0177 8.92775 18.2578 8.22664 18.2578 7.505C18.2578 6.78336 18.0177 6.08225 17.5752 5.51219C17.1327 4.94213 16.5131 4.53556 15.814 4.35656C15.4127 4.25382 15.1707 3.84524 15.2734 3.44397Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
});

export default PeopleIcon;
