import { useNavigate } from "react-router-dom";
import useRouteSegments from "hooks/useRouteSegments";
import { createLink } from "router/utils";

/**
 * @callback SetFloorFilter
 * @param {string} floorUrl
 * @param {string} [zoneUrl]
 */

/**
 * @returns {Array & {0: string, 1: string, 2: SetFloorFilter, length: 3}}
 */
const useFloorFilterState = () => {
  const { floorUrl, zoneUrl, ...segments } = useRouteSegments();
  const navigate = useNavigate();

  const setFloorFilter = (floorUrl, zoneUrl = null) => {
    navigate(
      createLink({
        segments: {
          ...segments,
          floorUrl,
          zoneUrl,
        },
        keepSearchParams: true,
        keepHash: true,
      })
    );
  };

  return [floorUrl, zoneUrl, setFloorFilter];
};

export default useFloorFilterState;
