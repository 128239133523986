import { useEditableParamsState } from "../pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import {
  useViewData,
  useViewState,
} from "../providers/ViewProvider/ViewProvider";
import useBuildingData from "./useBuildingData";
import { useMemoedLinkedData } from "./useLinkedData";
import useSelectedEntity from "./useSelectedEntity";
import { useMergedNotifier } from "helpers/getNotifier";
import { useUtilsEmissions } from "./useUtilsEmissions";

const useElectricityEmissisons = () => {
  const { buildingData } = useBuildingData();
  const selectedEntity = useSelectedEntity(buildingData);
  const {
    state: { electricityEmissionIntensity },
    Notifier: EPNotifier,
  } = useEditableParamsState();

  const { telemetries, Notifier: TNotifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        telemetries: [
          {
            telemetryName: "monthlyElectricity",
          },
        ],
      },
    ],
    [selectedEntity]
  );

  const netCleanConsumption =
    telemetries[selectedEntity.id].monthlyElectricity.data[0]?.value ?? NaN;

  const totalElectricityEmissions =
    netCleanConsumption * (electricityEmissionIntensity.value ?? NaN);

  const Notifier = useMergedNotifier(TNotifier, EPNotifier);

  /* EMISSIONS BY YEAR */

  const { fromTs } = useViewState();
  const {
    transformYearEmissions,
    getValues,
    yearFromTimeStamp,
    calculateFromAndTo,
  } = useUtilsEmissions();
  const yearSelected = yearFromTimeStamp(fromTs);
  const timeSelected = calculateFromAndTo(yearSelected);

  const { telemetries: telemetriesByYear } = useMemoedLinkedData(
    timeSelected.firstDay,
    timeSelected.lastDay,
    () => {
      return [
        {
          entityId: selectedEntity.id,
          telemetries: [
            {
              telemetryName: "monthlyElectricity",
            },
          ],
        },
      ];
    },
    [selectedEntity]
  );

  const netCleanConsumptionFinal = transformYearEmissions(
    telemetriesByYear[selectedEntity.id].monthlyElectricity.data
  );

  const netCleanConsumptionByYear = getValues(netCleanConsumptionFinal);

  const calculateTotalElectricityEmissionsByYear = (
    yearlyNetClean: number[]
  ) => {
    return yearlyNetClean.map(
      (item) => item * (electricityEmissionIntensity.value ?? NaN)
    );
  };
  const totalElectricityEmissionsByYear =
    calculateTotalElectricityEmissionsByYear(netCleanConsumptionByYear);

  return {
    totalElectricityEmissions,
    totalElectricityEmissionsByYear,
    Notifier,
  };
};

export { useElectricityEmissisons };
