import {
  useRef,
  useState,
  type ReactNode,
  useId,
  type HTMLAttributes,
} from "react";
import PieChart from "atomic-components/organisms/PieChart";
import ElementWithOnResize from "modules/layout/components/ElementWithOnResize/ElementWithOnResize";
import {
  type Placement,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react-dom";
import getTipPlacementByAngle from "helpers/getTipPlacementByAngle";
import { PopoverPortal } from "atomic-components/atoms/InfoPopover";
import isRealNumber from "helpers/isRealNumber";
import classNames from "classnames";
import type { GetSectorPropsParams } from "atomic-components/organisms/PieChart/PieChart";
import getPercent from "helpers/getPercent";
import { type Nullable } from "types/utils";

import s from "./PieChartWithLegends.module.scss";

type ShownTip = Pick<
  GetSectorPropsParams,
  "index" | "centerAngle" | "radius" | "xCenter" | "yCenter"
> & {
  value: Nullable<number>;
  legend: string | ReactNode;
  placement: Placement;
  color?: string;
};

type PieChartItem = {
  legend: string | ReactNode;
  value: Nullable<number>;
  color?: string;
};

export type TooltipVariants = "default" | "black";

type PieChartProps = HTMLAttributes<HTMLDivElement> & {
  items: PieChartItem[];
  renderTooltip: (params: { value: number; color?: string }) => ReactNode;
  chartClassName?: string;
  showLegends?: boolean;
  variant?: TooltipVariants;
  tooltipClassName?: string;
  hideLegendInTooltip?: boolean;
  hideLegend?: boolean;
  legendClassName?: string;
  legendTitle?: string;
  showLegendValue?: boolean;
  pieCenterValue?: number;
  legendColorClassName?: string;
};

const PieTooltip = ({
  value,
  legend,
  variant,
  className,
  hideLegend,
}: {
  value: ReactNode;
  legend: string | ReactNode;
  variant?: TooltipVariants;
  className?: string;
  hideLegend?: boolean;
}) => {
  return (
    <section
      className={classNames("main-tip", s[`variant--${variant}`], className)}
    >
      {hideLegend ? null : <>{legend}: </>}
      <span
        className={classNames({
          [s.circle]: variant === ("black" as TooltipVariants),
        })}
      >
        {value}
      </span>
    </section>
  );
};

const PieChartWithLegends = ({
  items,
  renderTooltip,
  className,
  chartClassName,
  legendClassName,
  showLegends = true,
  variant = "default",
  tooltipClassName,
  hideLegendInTooltip = false,
  legendTitle,
  showLegendValue = false,
  pieCenterValue,
  legendColorClassName,
  ...props
}: PieChartProps) => {
  const elementId = useId();
  const tooltipId = `${elementId}-tooltip`;
  const svgRef = useRef<SVGSVGElement>(null);
  const [width, setWidth] = useState(0);
  const [shownTip, setShownTip] = useState<ShownTip | null>(null);
  const { refs, x, y, strategy } = useFloating({
    placement: shownTip?.placement,
    middleware: [offset(5), flip(), shift({ padding: 5 })],
  });
  const tipTimer = useRef<NodeJS.Timeout>();

  const sumValue = items.reduce((prev, curr) => {
    const { value } = curr;
    return prev + (isRealNumber(value) ? value : 0);
  }, 0);

  const mouseEnter = ({
    index,
    centerAngle,
    radius,
    xCenter,
    yCenter,
  }: Omit<ShownTip, "value" | "legend" | "placement">) => {
    const item = items[index];
    if (!item) {
      return;
    }

    clearTimeout(tipTimer.current);

    const placement = getTipPlacementByAngle(centerAngle);

    setShownTip({
      index,
      value: item.value,
      legend: item.legend,
      color: item?.color,
      placement,
      centerAngle,
      radius,
      xCenter,
      yCenter,
    });

    const svg = svgRef.current;

    if (!svg) return;

    let { x: startX, y: startY } = svg.getBoundingClientRect();

    const x =
      startX + xCenter + radius * Math.cos((centerAngle * Math.PI) / 180);
    const y =
      startY + yCenter + radius * Math.sin((centerAngle * Math.PI) / 180);

    refs.setReference({
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x,
          y,
          top: y,
          left: x,
          right: x,
          bottom: y,
        };
      },
    });
  };

  const mouseLeave = () => {
    tipTimer.current = setTimeout(() => {
      setShownTip(null);
      refs.setReference(null);
    }, 350);
  };

  const getColor = ({ color, index }: { color?: string; index: number }) => {
    return (
      color ||
      `hsl(220, ${75 - (50 * index) / (items.length - 1)}%, ${
        90 - (80 * index) / (items.length - 1)
      }%)`
    );
  };

  return (
    <div className={classNames(s.container, className)} {...props}>
      <div className={classNames(s.chartContainer, chartClassName)}>
        <ElementWithOnResize
          onResize={(meta) => {
            setWidth(meta.width);
          }}
        >
          <div className={s.svgContainer}>
            <svg
              role="graphics-symbol"
              aria-roledescription="pie chart"
              ref={svgRef}
              width={width}
              height={width}
              aria-owns={tooltipId}
            >
              <PieChart
                size={width}
                innerSize={width * 0.5}
                getSectorProps={(props) => {
                  return {
                    fill: getColor({
                      color: items[props.index].color,
                      index: props.index,
                    }),
                    onMouseEnter: () => mouseEnter(props),
                    onMouseLeave: mouseLeave,
                  };
                }}
                percentages={items.map(
                  ({ value }) => getPercent(value, sumValue) || 0
                )}
              />
            </svg>
            {!!pieCenterValue && (
              <div className={s.pieValue}>{pieCenterValue}</div>
            )}
          </div>
        </ElementWithOnResize>
      </div>
      {showLegends ? (
        <div className={classNames(s.legendsContainer, legendClassName)}>
          {legendTitle && <div className={s.legendTitle}>{legendTitle}</div>}
          {items.map(({ legend, color, value }, index) => (
            <div className={s.legend} key={`legend-${index + 1}`}>
              <span
                style={{ background: getColor({ color, index }) }}
                className={classNames(s.legendColor, legendColorClassName)}
                aria-hidden
              />
              {legend} {showLegendValue && `(${value})`}
            </div>
          ))}
        </div>
      ) : null}
      {shownTip && shownTip.value ? (
        <PopoverPortal>
          <div
            id={tooltipId}
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? "",
              left: x ?? "",
            }}
            onMouseEnter={() => mouseEnter(shownTip)}
            onMouseLeave={mouseLeave}
          >
            <PieTooltip
              value={renderTooltip({
                value: shownTip.value,
                color: shownTip?.color,
              })}
              legend={shownTip.legend}
              variant={variant}
              className={tooltipClassName}
              hideLegend={hideLegendInTooltip}
            />
          </div>
        </PopoverPortal>
      ) : null}
    </div>
  );
};

export default PieChartWithLegends;
export type { PieChartProps, PieChartItem };
