import React, { useState, useRef, useEffect } from "react";
import "./Toasts.css";
import { useTranslate } from "../../../language";
import { isString } from "lodash";
import {
  getToasts,
  hideToast,
  onChange as onToastsChange,
} from "../../state/toast.state";

const Toast = ({ toast }) => {
  const ref = useRef();
  const t = useTranslate();

  const hide = toast.hide;
  const [localHide, setLocalHide] = useState(toast.hide);

  const close = () => {
    hideToast(toast);
  };
  const click = () => {
    if (toast.closeOnClick) {
      close();
    }
  };

  useEffect(() => {
    if (hide) {
      const h = ref.current.offsetHeight;
      ref.current.style.height = h + "px";
      const t = setTimeout(() => setLocalHide(true), 100);
      return () => clearTimeout(t);
    }
    if (localHide) {
      setLocalHide(false);
    }
  }, [hide]);

  const ChildComponent = toast.component;
  const content = ChildComponent ? (
    <ChildComponent close={close} toast={toast} />
  ) : isString(toast.messageKey) ? (
    t(toast.messageKey, toast.variables)
  ) : (
    toast.children || (isString(toast.message) ? toast.message : "")
  );
  const style = {};
  if (localHide) {
    style.height = "0px";
  }
  return (
    <div
      ref={ref}
      style={style}
      className={
        "toast-container" +
        (toast.noStyle ? "" : " do-style") +
        (localHide ? " hide" : "")
      }
    >
      <div
        className={toast.variant ? "variant-" + toast.variant : ""}
        onClick={click}
      >
        {content}
      </div>
    </div>
  );
};

const Toasts = () => {
  const [toasts, setToasts] = useState(getToasts);
  useEffect(() => {
    return onToastsChange((event) => {
      setToasts(event.toasts);
    });
  }, []);

  if (!toasts.length) {
    return null;
  }
  return (
    <div className={"toasts-container"}>
      {toasts.map((toast) => (
        <Toast key={toast.id} toast={toast} />
      ))}
    </div>
  );
};

export default Toasts;
