import { flatten } from "lodash";
import { ScenarioFieldType } from "modules/building/pages/SustainabilityPage/components/ScenarioDetailsModal/ScenarioDetailsForm/ScenarioDetailsForm";

const getSubFields = (field: ScenarioFieldType): ScenarioFieldType[] => {
  if (!field?.children) return [];
  const subFields = field?.children;
  return [
    ...(subFields as ScenarioFieldType[]),
    ...flatten(
      subFields.map((subField) => getSubFields(subField as ScenarioFieldType))
    ),
  ];
};

const getTotalScenarioScore = ({
  fieldsScores,
  scenarioFields,
}: {
  fieldsScores: Record<string, number | boolean>;
  scenarioFields: ScenarioFieldType[];
}) =>
  Object.keys(fieldsScores)
    // only fields that has no children
    .filter(
      (key) =>
        getSubFields(
          scenarioFields.find(
            (field) => field?.key === key
          ) as ScenarioFieldType
        ).length === 0
    )
    .reduce((acc, key) => acc + (fieldsScores[key] as number), 0);

export { getSubFields, getTotalScenarioScore };
