const { fromMillis } = require("helpers/dateTimeHelper");
const { sortedIndex, flatten } = require("lodash");
const { DateTime } = require("luxon");

/**
 * @typedef {number|string|DateTime|import("react-calendar/dist/cjs/shared/types").Value} Reference
 */

const timestampsCache = {};

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_WEEK = 7 * ONE_DAY;

export const DEFAULT_INTERVAL = 10 * ONE_MINUTE;
export const DEFAULT_TIMEZONE = "Africa/Cairo";

const MONDAY_WEEKDAY_NUMBER = 1;
const DEFAULT_START_OF_WEEK = MONDAY_WEEKDAY_NUMBER;
const INTERVALS_TYPE_SYMBOL = Symbol("isBaseInterval");

export const getIntervalsType = (object) => object?.[INTERVALS_TYPE_SYMBOL];
export const isIntervals = (object) => Boolean(getIntervalsType(object));

const tryFormats = (dateStr, formats, timezone) => {
  for (let i = 0; i < formats.length; i++) {
    try {
      const dateTime = DateTime.fromFormat(dateStr, formats[i], {
        zone: timezone,
      });
      if (dateTime.isValid) {
        return dateTime;
      }
    } catch {
      /* empty */
    }
  }
};

const getDate = (date, timezone) => {
  if (typeof date === "number") {
    return fromMillis(date, { zone: timezone });
  }
  if (date instanceof DateTime || DateTime.isDateTime(date)) {
    return DateTime.fromObject(date.toObject(), { zone: timezone });
    // return fromMillis(date.toMillis(), { zone: timezone });
  }
  if (date instanceof Date) {
    return DateTime.fromObject(DateTime.fromJSDate(date).toObject(), {
      zone: timezone,
    });
    // return fromMillis(date.getTime(), { zone: timezone });
  }
  if (typeof date === "string") {
    switch (date.length) {
      case 10: {
        const dateTime = tryFormats(date, ["yyyy-MM-dd"], timezone);
        if (dateTime) {
          return dateTime;
        }
        break;
      }
      case 16: {
        const dateTime = tryFormats(
          date,
          [
            "yyyy-MM-dd'T'HH:mm",
            "yyyy-MM-dd' 'HH:mm",
            "yyyy-MM-dd'.'HH:mm",
            "yyyy-MM-dd'T'HH.mm",
            "yyyy-MM-dd' 'HH.mm",
            "yyyy-MM-dd'.'HH.mm",
          ],
          timezone
        );
        if (dateTime) {
          return dateTime;
        }
        break;
      }
      case 19: {
        const dateTime = tryFormats(
          date,
          [
            "yyyy-MM-dd'T'HH:mm:ss",
            "yyyy-MM-dd' 'HH:mm:ss",
            "yyyy-MM-dd'.'HH:mm:ss",
            "yyyy-MM-dd'T'HH.mm.ss",
            "yyyy-MM-dd' 'HH.mm.ss",
            "yyyy-MM-dd'.'HH.mm.ss",
          ],
          timezone
        );
        if (dateTime) {
          return dateTime;
        }
        break;
      }
      default:
        break;
    }
  }
  throw new Error(`Invalid Date sent "${date}"`);
};

/**
 * @param {DateTime} dateTime
 */
const toStartOfDay = (dateTime) => {
  return dateTime.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
};

/**
 * @param {DateTime} dateTime
 */
const toEndOfDay = (dateTime) => {
  return fromMillis(
    dateTime
      .set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      })
      .toMillis() + 1,
    { zone: dateTime.zoneName }
  );
};

const checkInterval = (interval) => {
  if (interval < ONE_SECOND) {
    throw new Error(
      `Interval cannot be less than 1 second (interval:"${interval}")`
    );
  }
  if (interval < ONE_MINUTE && ONE_MINUTE % interval !== 0) {
    throw new Error(
      `When interval is less than a minute it must be a factor of 60 (interval:"${interval}")`
    );
  }
  if (interval < ONE_DAY && ONE_HOUR % interval !== 0) {
    throw new Error(
      `When interval is less than an day it must be a factor of 3600 (interval:"${interval}")`
    );
  }
};

/**
 * @callback GetExactTimestampIndexFunction
 * @param {number} timestamp
 * @returns {number} the index of the timestamp in timestamps or -1 if not exactly found
 */
/**
 * @callback GetApproximateTimestampIndexFunction
 * @param {number} timestamp
 * @returns {number} the index of the timestamp in timestamps that is closest to the sent timestamp
 */
/**
 * @callback GetResolutionFunction
 * @param {{resolutionKey:"1day"|"1week",startOfWeek:number}} GetResolutionFunctionParams
 * @returns {ResolutionIntervals}
 */
/**
 * @typedef {Object} ResolutionIntervalData
 * @property {number} timestamp
 * @property {number} duration
 * @property {number[]} baseTimestamps an array of base timestamps per resolution timestamp
 * @property {number} baseIntervalsCount basically baseTimestamps.length
 */
/**
 * @typedef {Omit<BaseIntervals,"getResolution"> & {intervalsData:ResolutionIntervalData[]}} ResolutionIntervals
 */
/**
 * @typedef {Object} BaseIntervals
 * @property {string} key
 * @property {DateTime} from
 * @property {DateTime} to
 * @property {number} fromTs
 * @property {number} toTs
 * @property {string} timezone
 * @property {number} days
 * @property {number} duration
 * @property {number[]} timestamps
 * @property {number} baseInterval
 * @property {number} interval
 * @property {number} baseIntervalsPerInterval
 * @property {GetExactTimestampIndexFunction} getExactTimestampIndex
 * @property {GetApproximateTimestampIndexFunction} getApproximateTimestampIndex
 * @property {GetResolutionFunction} getResolution
 */

/**
 * @typedef {Object} GetIntervalsParams
 * @property {number|string|Date|DateTime} from
 * @property {number|string|Date|DateTime} to
 * @property {string} timezone
 * @property {number} interval
 */

export const normalizeFromTo = ({ from, to, timezone = DEFAULT_TIMEZONE }) => {
  from = toStartOfDay(getDate(from, timezone));
  to = getDate(to, timezone);
  if (to.hour + to.minute + to.second + to.millisecond > 0) {
    if (to.hour === 1 && to.minute + to.second + to.millisecond === 0) {
      // accounting for daylight saving
      const tryTo = toEndOfDay(to);
      if (tryTo.set({ day: tryTo.day - 1 }).toMillis() !== to.toMillis()) {
        to = tryTo;
      }
    } else {
      to = toEndOfDay(to);
    }
  }
  const fromTs = from.toMillis();
  const toTs = to.toMillis();

  if (toTs - fromTs <= 0) {
    throw new Error(`Empty interval: [from:"${fromTs}->to:"${toTs}"]`);
  }
  const days = Math.round(to.diff(from, "day").days);
  return {
    from,
    to,
    fromTs,
    toTs,
    days,
  };
};

/**
 * @param {GetIntervalsParams} param0
 * @returns {BaseIntervals}
 */
const getIntervals = ({
  from,
  to,
  timezone = DEFAULT_TIMEZONE,
  interval = DEFAULT_INTERVAL,
}) => {
  checkInterval(interval);

  const normalizedTimeRange = normalizeFromTo({ from, to, timezone });

  from = normalizedTimeRange.from;
  to = normalizedTimeRange.to;

  const fromTs = normalizedTimeRange.fromTs;
  const toTs = normalizedTimeRange.toTs;

  const days = normalizedTimeRange.days;

  if (!Number.isInteger(Math.round((toTs - fromTs) / interval))) {
    throw new Error(
      `Interval("${interval}") should be a divisor of: (to:"${toTs}" minus from:"${fromTs}") i.e. of "${
        toTs - fromTs
      }"`
    );
  }

  const duration = toTs - fromTs;

  const cacheKey = `${timezone}[${fromTs}-${toTs}]/${interval}`;
  if (timestampsCache[cacheKey]) {
    return timestampsCache[cacheKey];
  }

  const timestamps = [];
  // NOTE: the below handle the case of monthly interval
  if (interval === 2592000000) {
    const from = fromMillis(fromTs, { zone: timezone, resetTime: true });
    const to = fromMillis(toTs, { zone: timezone, resetTime: true });
    for (
      let timestamp = from.ts;
      timestamp < to.ts;
      timestamp = fromMillis(timestamp, {
        zone: timezone,
        resetTime: true,
      }).plus({
        month: 1,
      }).ts
    ) {
      timestamps.push(timestamp);
    }
  } else {
    for (let timestamp = fromTs; timestamp < toTs; timestamp += interval) {
      timestamps.push(timestamp);
    }
  }

  const timestampsIndexes = Object.fromEntries(
    timestamps.map((ts, i) => [ts, i])
  );

  const fromTimestamp = (timestamp) => {
    const index = timestampsIndexes[timestamp];
    if (index != null) {
      return { index, exact: true };
    }
    const approximateIndex = sortedIndex(timestamps, timestamp);
    return {
      index: approximateIndex - 1,
      exact: false,
    };
  };

  const resolutions = {};
  /** @type {BaseIntervals} */
  const timestampsObject = {
    [INTERVALS_TYPE_SYMBOL]: "base",
    key: cacheKey,
    from,
    to,
    fromTs,
    toTs,
    timezone,
    days,
    duration,
    timestamps,
    baseInterval: interval,
    interval,
    baseIntervalsPerInterval: 1,
    getExactTimestampIndex: (timestamp) => {
      const index = timestampsIndexes[timestamp];
      if (index == null) {
        return -1;
      }
      return index;
    },
    getApproximateTimestampIndex: (timestamp) => {
      return fromTimestamp(timestamp).index;
    },
    getTimestampDuration: () => interval,
    getResolution: ({ resolutionKey, startOfWeek = 1 }) => {
      const key =
        resolutionKey === "1week"
          ? `${resolutionKey}@${startOfWeek}`
          : resolutionKey;
      /** @type {ResolutionIntervals} */
      const resolution =
        resolutions[key] ??
        (resolutions[key] = getResolutionIntervalsOfBaseIntervals(
          timestampsObject,
          resolutionKey,
          startOfWeek,
          `${cacheKey}->${key}`
        ));
      return resolution;
    },
  };

  timestampsCache[cacheKey] = timestampsObject;
  return timestampsCache[cacheKey];
};

/**
 * @typedef {Object} GetWeekIntervalsParams
 * @property {string} timezone
 * @property {number} interval
 */

export const getWeekFromTo = ({
  reference = Date.now(),
  timezone = DEFAULT_TIMEZONE,
}) => {
  const to = toEndOfDay(getDate(reference, timezone));
  const from = to
    .set({
      hour: 12,
    })
    .set({
      day: to.day - 7,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  return { from, to, fromTs: from.toMillis(), toTs: to.toMillis() };
};

/**
 * @param {GetWeekIntervalsParams} param0
 * @returns {BaseIntervals}
 */
const getWeekIntervals = ({ timezone, interval }) => {
  return getIntervals({ ...getWeekFromTo({ timezone }), timezone, interval });
};

/**
 * @typedef {Object} GetCurrentWeekIntervalsParams
 * @property {string} timezone
 * @property {number} interval
 * @property {number} startOfWeek defaults to 1 <=> monday
 */

/**
 * @param {GetCurrentWeekIntervalsParams} param0
 * @returns {BaseIntervals}
 */
const getCurrentWeekIntervals = ({
  startOfWeek = DEFAULT_START_OF_WEEK,
  timezone,
  interval,
}) => {
  const firstMilliTomorrow = toEndOfDay(getDate(Date.now(), timezone));
  const firstMilliToday = firstMilliTomorrow
    .set({
      hour: 12,
    })
    .set({
      day: firstMilliTomorrow.day - 1,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  let diffToStartOfCurrentWeek = firstMilliToday.weekday - startOfWeek;
  diffToStartOfCurrentWeek =
    diffToStartOfCurrentWeek < 0
      ? 7 + diffToStartOfCurrentWeek
      : diffToStartOfCurrentWeek;

  const startOfCurrentWeek = firstMilliToday
    .set({
      hour: 12,
    })
    .set({
      day: firstMilliToday.day - diffToStartOfCurrentWeek,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  return getIntervals({
    from: startOfCurrentWeek,
    to: firstMilliTomorrow,
    timezone,
    interval,
  });
};

/**
 * @typedef {Object} GetPreviousWeekIntervalsParams
 * @property {string} timezone
 * @property {number} interval
 * @property {number} [startOfWeek] defaults to 1 <=> monday
 * @property {number} [diff] number of weeks to go back defaults to 1
 */

/**
 * @param {{diff?: number; reference?: Reference; startOfWeek?: number; timezone: string}} param0
 * @returns {{from: DateTime, to: DateTime, fromTs: number, toTs: number}}
 */
export const getPreviousWeekFromTo = ({
  diff = 1,
  reference = Date.now(),
  startOfWeek = DEFAULT_START_OF_WEEK,
  timezone,
}) => {
  const firstMilliTomorrow = toEndOfDay(getDate(reference, timezone));
  const firstMilliToday = firstMilliTomorrow
    .set({
      hour: 12,
    })
    .set({
      day: firstMilliTomorrow.day - 1,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  let diffToStartOfCurrentWeek = firstMilliToday.weekday - startOfWeek;
  diffToStartOfCurrentWeek =
    diffToStartOfCurrentWeek < 0
      ? 7 + diffToStartOfCurrentWeek
      : diffToStartOfCurrentWeek;

  const startOfCurrentWeek = firstMilliToday
    .set({
      hour: 12,
    })
    .set({
      day: firstMilliToday.day - diffToStartOfCurrentWeek,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  const startOfPrevWeek = startOfCurrentWeek
    .set({ hour: 12 })
    .set({
      day: startOfCurrentWeek.day - diff * 7,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  const endOfPrevWeek = startOfPrevWeek
    .set({ hour: 12 })
    .set({
      day: startOfPrevWeek.day + 7,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  return {
    from: startOfPrevWeek,
    to: endOfPrevWeek,
    fromTs: startOfPrevWeek.toMillis(),
    toTs: endOfPrevWeek.toMillis(),
  };
};

/**
 * @param {GetPreviousWeekIntervalsParams} param0
 * @returns {BaseIntervals}
 */
const getPreviousWeekIntervals = ({
  diff = 1,
  startOfWeek = DEFAULT_START_OF_WEEK,
  reference = Date.now(),
  timezone,
  interval,
}) => {
  return getIntervals({
    ...getPreviousWeekFromTo({ diff, reference, startOfWeek, timezone }),
    timezone,
    interval,
  });
};

/**
 * @typedef {Object} GetDayIntervalsParams
 * @property {string} timezone
 * @property {number} interval
 */

/**
 * @param {{reference?: Reference; timezone: string}} param0
 * @returns {{from: DateTime; to: DateTime; fromTs: number; toTs: number}}
 */
export const getDayFromTo = ({
  reference = Date.now(),
  timezone = DEFAULT_TIMEZONE,
}) => {
  const to = toEndOfDay(getDate(reference, timezone));
  const from = to
    .set({
      hour: 12,
    })
    .set({
      day: to.day - 1,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  return { from, to, fromTs: from.toMillis(), toTs: to.toMillis() };
};

/**
 * @param {GetDayIntervalsParams} param0
 * @returns {BaseIntervals}
 */
const getDayIntervals = ({ reference = Date.now(), timezone, interval }) => {
  return getIntervals({
    ...getDayFromTo({ reference, timezone }),
    timezone,
    interval,
  });
};

/**
 * @typedef {Object} GetMonthIntervalsParams
 * @property {string} timezone
 * @property {number} interval
 */

/**
 * @param {{reference?: Reference; timezone?: string}} param0
 * @returns {{from: DateTime; to: DateTime; fromTs: number; toTs: number}}
 */
export const getMonthFromTo = ({
  reference = Date.now(),
  timezone = DEFAULT_TIMEZONE,
}) => {
  const to = toEndOfDay(getDate(reference, timezone));
  const from = to
    .set({
      hour: 12,
    })
    .set({
      month: to.month - 1,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  return { from, to, fromTs: from.toMillis(), toTs: to.toMillis() };
};

/**
 * @param {{reference?: Reference; timezone: string}} param0
 * @returns {{from: DateTime; to: DateTime; fromTs: number; toTs: number}}
 */
export const getMonthOfDayFromTo = ({ reference = Date.now(), timezone }) => {
  const fromTs =
    getDayFromTo({
      reference,
      timezone,
    })
      .from.plus({ month: 1 })
      .set({ day: 1 })
      .toMillis() - 1;

  return getMonthFromTo({
    reference: fromTs,
    timezone,
  });
};

/**
 * @param {GetMonthIntervalsParams} param0
 * @returns {BaseIntervals}
 */
const getMonthIntervals = ({ timezone, interval }) => {
  return getIntervals({ ...getMonthFromTo({ timezone }), timezone, interval });
};

/**
 * @typedef {Object} GetCurrentMonthIntervalsParams
 * @property {string} timezone
 * @property {number} interval
 */

/**
 * @param {GetCurrentMonthIntervalsParams} param0
 * @returns {BaseIntervals}
 */
const getCurrentMonthIntervals = ({ timezone, interval }) => {
  const firstMilliTomorrow = toEndOfDay(getDate(Date.now(), timezone));
  const firstMilliToday = firstMilliTomorrow
    .set({
      hour: 12,
    })
    .set({
      day: firstMilliTomorrow.day - 1,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  const startOfMonth = firstMilliToday
    .set({ hour: 12 })
    .set({
      day: 1,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  return getIntervals({
    from: startOfMonth,
    to: firstMilliTomorrow,
    timezone,
    interval,
  });
};

/**
 * @typedef {Object} GetPreviousMonthIntervalsParams
 * @property {string} timezone
 * @property {number} interval
 * @property {number} [diff] number of weeks to go back defaults to 1
 */

/**
 * @param {GetPreviousMonthIntervalsParams} param0
 * @returns {BaseIntervals}
 */
const getPreviousMonthIntervals = ({ diff = 1, timezone, interval }) => {
  const firstMilliTomorrow = toEndOfDay(getDate(Date.now(), timezone));
  const firstMilliToday = firstMilliTomorrow
    .set({
      hour: 12,
    })
    .set({
      day: firstMilliTomorrow.day - 1,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  const startOfCurrentMonth = firstMilliToday
    .set({ hour: 12 })
    .set({
      day: 1,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  const startOfPrevMonth = startOfCurrentMonth
    .set({ hour: 12 })
    .set({
      month: startOfCurrentMonth.month - diff,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  const endOfPrevMonth = startOfPrevMonth
    .set({ hour: 12 })
    .set({
      month: startOfPrevMonth.month + 1,
    })
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  return getIntervals({
    from: startOfPrevMonth,
    to: endOfPrevMonth,
    timezone,
    interval,
  });
};

export const getDST = (ts, timezone) => {
  const dateTime = fromMillis(ts, { zone: timezone });
  if (dateTime.hour === 1 && dateTime.minute + dateTime.second === 0) {
    const justBefore = fromMillis(
      dateTime.toMillis() - dateTime.millisecond - 1,
      { zone: timezone }
    );
    if (justBefore.hour === 23) {
      return -1;
    }
    return 0;
  }
  if (dateTime.hour === 23 && dateTime.minute + dateTime.second === 0) {
    const afterAnHour = fromMillis(dateTime.toMillis() + ONE_HOUR, {
      zone: timezone,
    });
    if (afterAnHour.hour === 23) {
      return 1;
    }
    return 0;
  }
  return 0;
};

export const checkIntervalCount = (intervalsCount, baseInterval) => {
  const interval = intervalsCount * baseInterval;

  if (interval <= ONE_HOUR) {
    if (ONE_HOUR % interval !== 0) {
      throw new Error(
        `When new interval (baseInterval:"${baseInterval}"*intervalsCount:"${intervalsCount}"="${interval}") is less than an hour "${ONE_HOUR}" it must be a divisor of one hour (${ONE_HOUR})`
      );
    }
  } else if (interval <= ONE_DAY) {
    if (ONE_DAY % interval !== 0) {
      throw new Error(
        `When new interval (baseInterval:"${baseInterval}"*intervalsCount:"${intervalsCount}"="${interval}") is less than an day "${ONE_DAY}" it must be a divisor of one day (${ONE_DAY})`
      );
    }
  } else if (interval % ONE_DAY !== 0) {
    throw new Error(
      `When new interval (baseInterval:"${baseInterval}"*intervalsCount:"${intervalsCount}"="${interval}") is greater than one day "${ONE_DAY}" it must be a multiple of one day (${ONE_DAY})`
    );
  }

  return interval;
};

/**
 * @param {BaseIntervals} baseIntervalsObject
 * @param {"1day"|"1week"} resolutionKey
 * @param {number} startOfWeek
 * @param {string} key
 * @returns {ResolutionIntervals}
 */
const getResolutionIntervalsOfBaseIntervals = (
  baseIntervalsObject,
  resolutionKey,
  startOfWeek,
  key
) => {
  let {
    timezone,
    interval: baseInterval,
    from,
    to,
    fromTs,
    toTs,
    timestamps: baseTimestamps,
  } = baseIntervalsObject;

  let baseIntervalsPerInterval;
  switch (resolutionKey) {
    case "1day": {
      fromTs = getDayFromTo({
        timezone: baseIntervalsObject.timezone,
        reference: baseIntervalsObject.fromTs,
      }).fromTs;
      toTs = getDayFromTo({
        timezone: baseIntervalsObject.timezone,
        reference: baseIntervalsObject.toTs - 1,
      }).toTs;
      baseIntervalsPerInterval = Math.round(
        ONE_DAY / baseIntervalsObject.interval
      );
      break;
    }
    case "1week": {
      fromTs = getPreviousWeekFromTo({
        diff: 0,
        timezone: baseIntervalsObject.timezone,
        reference: baseIntervalsObject.fromTs,
        startOfWeek,
      }).fromTs;
      toTs = getPreviousWeekFromTo({
        diff: 0,
        timezone: baseIntervalsObject.timezone,
        reference: baseIntervalsObject.toTs - 1,
        startOfWeek,
      }).toTs;
      baseIntervalsPerInterval = Math.round(
        ONE_WEEK / baseIntervalsObject.interval
      );
      break;
    }
    case "1month": {
      const fromDateFromTo = getMonthOfDayFromTo({
        timezone: baseIntervalsObject.timezone,
        reference: baseIntervalsObject.fromTs,
      });
      fromTs = fromDateFromTo.fromTs;
      const numberOfDays = new Date(
        fromDateFromTo.from.year,
        fromDateFromTo.from.month,
        0
      ).getDate();
      toTs = getMonthOfDayFromTo({
        timezone: baseIntervalsObject.timezone,
        reference: baseIntervalsObject.toTs - 1,
      }).toTs;

      baseIntervalsPerInterval = Math.round(
        (numberOfDays * ONE_DAY) / baseIntervalsObject.interval
      );
      break;
    }
    default:
      throw new Error(`Resolution "${resolutionKey}" not supported`);
  }

  const normalizedTimeRange = normalizeFromTo({
    from: fromTs,
    to: toTs,
    timezone,
  });

  from = normalizedTimeRange.from;
  to = normalizedTimeRange.to;

  fromTs = normalizedTimeRange.fromTs;
  toTs = normalizedTimeRange.toTs;

  const days = normalizedTimeRange.days;
  const duration = toTs - fromTs;

  const newInterval = checkIntervalCount(
    baseIntervalsPerInterval,
    baseInterval
  );

  const isNewIntervalLessThanAnHour = newInterval < ONE_HOUR;

  const baseIntervalsByInterval = [];
  for (let i = 0; i < baseTimestamps.length; i += baseIntervalsPerInterval) {
    if (isNewIntervalLessThanAnHour) {
      const baseIntervals = baseTimestamps.slice(
        i,
        i + baseIntervalsPerInterval
      );
      if (baseIntervals.length) {
        baseIntervalsByInterval.push(baseIntervals);
      } else {
        break;
      }
    } else {
      let baseIntervalsCountInThisInterval = baseIntervalsPerInterval;
      let start = i,
        end = start;
      for (
        let j = 0;
        j < baseIntervalsCountInThisInterval && end < baseTimestamps.length;
        j++, end++
      ) {
        let ts = baseTimestamps[end];
        baseIntervalsCountInThisInterval +=
          (getDST(ts, timezone) * ONE_HOUR) / baseInterval;
      }
      if (end > start) {
        baseIntervalsByInterval.push(baseTimestamps.slice(start, end));
        i += end - start - baseIntervalsPerInterval; // to account for daylight saving in interval if there is one (normally this is zero)
      } else {
        break;
      }
    }
  }

  const baseTimestampsMappings = Object.fromEntries(
    flatten(
      baseIntervalsByInterval.map((baseTimestamps, i) =>
        baseTimestamps.map((ts) => [ts, i])
      )
    )
  );
  const intervalsData = baseIntervalsByInterval.map((baseTimestamps, i) => {
    return {
      timestamp: baseTimestamps[0],
      duration: baseTimestamps.length * baseInterval,
      baseTimestamps,
      baseIntervalsCount: baseTimestamps.length,
    };
  });

  const timestamps = intervalsData.map((data) => data.timestamp);

  const getApproximateIntervalData = (timestamp) => {
    let index = baseTimestampsMappings[timestamp];
    if (index != null) {
      return {
        ...intervalsData[index],
        index,
        exact: true,
      };
    }
    const baseTimestampIndex =
      baseIntervalsObject.getApproximateTimestampIndex(timestamp);
    const baseTimestamp = baseIntervalsObject.timestamps[baseTimestampIndex];

    index = baseTimestampsMappings[baseTimestamp];
    if (index == null) {
      return null;
    }
    return {
      ...intervalsData[index],
      index,
      exact: false,
    };
  };

  return {
    [INTERVALS_TYPE_SYMBOL]: "resolution",
    key,
    from,
    to,
    fromTs,
    toTs,
    timezone,
    days,
    duration,
    timestamps,
    baseInterval,
    interval: newInterval,
    baseIntervalsPerInterval,
    getExactTimestampIndex: (timestamp) => {
      const index = baseTimestampsMappings[timestamp];
      if (index == null) {
        return -1;
      }
      return index;
    },
    getApproximateTimestampIndex: (timestamp) => {
      const intervalData = getApproximateIntervalData(timestamp);
      if (intervalData == null) {
        return -1;
      }
      return intervalData.index;
    },
    getTimestampDuration: (timestamp) => {
      const index = baseTimestampsMappings[timestamp];
      if (index == null) {
        throw new Error(`Invalid timestamp`);
      }
      return intervalsData[index].duration;
    },
    intervalsData,
  };
};

/**
 * Get date range corresponding to the `targetRange` but using the `reference` date.
 * It acts like time-travel, like return the same range of dates but with reference to the provided `reference`
 *
 * @param {{reference: Reference; targetRange: lockFixedRangeType; timezone: string}} param0
 * @returns {{from: DateTime; to: DateTime; fromTs: number; toTs: number}}
 */
const getCorrespondingDatesFromRange = ({
  reference,
  targetRange,
  timezone,
}) => {
  const referenceDateTime = getDayFromTo({ reference, timezone }).from;
  const { fromTs, toTs } = targetRange;
  const targetFromDateTime = fromMillis(fromTs, {
    zone: timezone,
  });
  const targetToDateTime = fromMillis(toTs, {
    zone: timezone,
  });

  const referenceDayDiffFromStart =
    referenceDateTime.weekday - targetFromDateTime.weekday;

  const from = referenceDateTime
    .minus({
      day:
        referenceDayDiffFromStart < 0
          ? 7 + referenceDayDiffFromStart // get the proper weekday diff if the reference weekday is before the target range start weekday
          : referenceDayDiffFromStart,
    })
    .set({ hour: 0 });

  const daysDiff = targetToDateTime.diff(targetFromDateTime, ["days"]).days;

  const to = from
    .plus({
      day: daysDiff,
    })
    .set({ hour: 0 });

  return normalizeFromTo({ from, to, timezone });
};

/**
 * Get JSDate from DateTime
 * This will ignore the timezone conversion to avoid DateTime from considering the browser's timezone on conversion
 *
 * @param {{ reference: DateTime }} param0
 * @returns {Date}
 */
const toJSDate = ({ reference }) => {
  return DateTime.fromObject(reference.toObject()).toJSDate();
};

/**
 * @param {{reference?: Reference; timezone: string}} param0
 * @returns {{from: DateTime; to: DateTime; fromTs: number; toTs: number}}
 */
const getYearFromTo = ({ reference = Date.now(), timezone }) => {
  const from = getDate(reference, timezone).set({
    month: 1,
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const to = from.plus({ year: 1 }).set({
    month: 1,
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return {
    from,
    fromTs: from.toMillis(),
    to,
    toTs: to.toMillis(),
  };
};

/**
 * @param {{ fromRef?: Reference; toRef?: Reference, timezone: string }} param0
 * @returns {Boolean}
 */
const isValidMonthFromTo = ({ fromRef, toRef, timezone }) => {
  if (!fromRef || !toRef) {
    return false;
  }

  const from = getDate(fromRef, timezone);
  const to = getDate(toRef, timezone);

  return (
    from.day === 1 && to.day === 1 && to.diff(from, ["months"]).months === 1
  );
};

/**
 * @param {{ fromRef?: Reference; toRef?: Reference, timezone: string }} param0
 * @returns {Boolean}
 */
const isValidYearFromTo = ({ fromRef, toRef, timezone }) => {
  if (!fromRef || !toRef) {
    return false;
  }

  const from = getDate(fromRef, timezone);
  const to = getDate(toRef, timezone);

  return (
    from.day === 1 && to.day === 1 && to.diff(from, ["months"]).months === 12
  );
};

/**
 * @typedef {Object} isDateInCurrentWeek
   @param {dateTime} reference
 * @param {string} timezone
 * @returns {Boolean}
 */
export const isDateInCurrentWeek = ({
  reference = Date.now(),
  timezone = DEFAULT_TIMEZONE,
}) => {
  const { from } = getDayFromTo({ timezone });

  const startOfWeek = from.startOf("week");
  const endOfWeek = from.endOf("week");

  // Check if the given date is within the current week
  return reference >= startOfWeek && reference <= endOfWeek;
};

export {
  getIntervals,
  getDate,
  getDayIntervals,
  getWeekIntervals,
  getCurrentWeekIntervals,
  getPreviousWeekIntervals,
  getMonthIntervals,
  getCurrentMonthIntervals,
  getPreviousMonthIntervals,
  getCorrespondingDatesFromRange,
  toJSDate,
  getYearFromTo,
  isValidMonthFromTo,
  isValidYearFromTo,
};
