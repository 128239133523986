import React, { useContext } from "react";

const WidthContext = React.createContext(0);

export const useWidth = () => useContext(WidthContext);

const WidthProvider = ({ width, children }) => {
  return (
    <WidthContext.Provider value={width}>{children}</WidthContext.Provider>
  );
};
export default WidthProvider;
