import withIconProps from "helpers/withIconProps";

const SearchIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      {...props}
    >
      <path
        d="M4.08571 0.558594C5.16931 0.558594 6.20853 0.989052 6.97475 1.75527C7.74097 2.52149 8.17143 3.56071 8.17143 4.64431C8.17143 5.65631 7.80057 6.58659 7.19086 7.30316L7.36057 7.47288H7.85714L11 10.6157L10.0571 11.5586L6.91429 8.41574V7.91917L6.74457 7.74945C6.028 8.35917 5.09771 8.73002 4.08571 8.73002C3.00212 8.73002 1.9629 8.29956 1.19668 7.53334C0.430458 6.76712 0 5.72791 0 4.64431C0 3.56071 0.430458 2.52149 1.19668 1.75527C1.9629 0.989052 3.00212 0.558594 4.08571 0.558594ZM4.08571 1.81574C2.51429 1.81574 1.25714 3.07288 1.25714 4.64431C1.25714 6.21574 2.51429 7.47288 4.08571 7.47288C5.65714 7.47288 6.91429 6.21574 6.91429 4.64431C6.91429 3.07288 5.65714 1.81574 4.08571 1.81574Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default SearchIcon;
