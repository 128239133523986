import withIconProps from "helpers/withIconProps";

type Props = {
  fill?: string;
};

const CheckCircle = withIconProps(
  ({ fill = "var(--color-primary)" }: Props) => {
    return (
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 6C0 2.68621 2.68621 0 6 0C9.31379 0 12 2.68621 12 6C12 9.31379 9.31379 12 6 12C2.68621 12 0 9.31379 0 6ZM6 1.09091C3.2887 1.09091 1.09091 3.2887 1.09091 6C1.09091 8.7113 3.2887 10.9091 6 10.9091C8.7113 10.9091 10.9091 8.7113 10.9091 6C10.9091 3.2887 8.7113 1.09091 6 1.09091ZM8.02206 4.5234C8.23507 4.73641 8.23507 5.08177 8.02206 5.29479L5.84024 7.4766C5.62723 7.68962 5.28186 7.68962 5.06885 7.4766L3.97794 6.38569C3.76493 6.17268 3.76493 5.82732 3.97794 5.61431C4.19096 5.40129 4.53632 5.40129 4.74933 5.61431L5.45455 6.31952L7.25067 4.5234C7.46368 4.31038 7.80905 4.31038 8.02206 4.5234Z"
          fill={fill}
        />
      </svg>
    );
  }
);

export default CheckCircle;
