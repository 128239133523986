import { flattenDeep, isString, mean, min, pick, setWith } from "lodash";
import Linker from "./Linker";
import { isTelemetrySymbol } from "../hooks/useLinkedData";
import { useEffect } from "react";

/**
 * @typedef {import('../hooks/useLinkedData').LinkedTelemetry} LinkedTelemetry
 * @typedef {import("../hooks/useLinkedData").Datum} Datum
 */
/**
 * @typedef {Object} TelemetryParams
 * @property {string} entityId
 * @property {string} alias
 * @property {string} displayKey
 * @property {string | import('types/unit').Unit | import('types/unit').Currency} unit
 * @property {string} timeQuantizationAggregationMethod
 * @property {string} interpolationMethod
 * @property {string} maxInterpolationDuration
 * @property {string} downsamplingAggregationMethod
 * @property {string} upsamplingAggregationMethod
 */
/**
 * @callback ComputeFunction
 * @param {import("../hooks/useLinkedData").LinkedTelemetriesResponseL2} telemetries
 * @param {number} startIndex
 * @param {{telemetry: LinkedTelemetry, insights: import('../hooks/useLinkedData').InsightsReponse}} params
 * @returns {Datum[]}
 */
/**
 * @typedef {Object} Request
 * @property {TelemetryParams} telemetryParams
 * @property {ComputeFunction} computeFunction
 */

/** @type {import('./Linker').LinkerInstance<Object, Function>} */
const computedTelemetryLinker = new Linker({
  idleDuration: 60000,
});

function LinkedTelmetryComputer(key, onChange) {
  let computeFunction, telemetryParams, telemetries, telemetriesArray, insights;

  let unlinkFunction, computedTelemetry, triggerLinkerEvent, linkerUserData;

  const doCompute = () => {
    if (!triggerLinkerEvent) {
      return;
    }
    /** @type {LinkedTelemetry} */
    const referenceTelemetry = telemetriesArray[0].telemetry;

    const lastConsecutiveDatumIndex =
      min(
        telemetriesArray.map(
          ({ telemetry }) => telemetry.lastConsecutiveDatumIndex
        )
      ) ?? -1;
    const startIndex = linkerUserData.startIndex ?? 0;
    const nextStartIndex = lastConsecutiveDatumIndex + 1;
    linkerUserData.startIndex = nextStartIndex;

    const length = referenceTelemetry.timestamps.length;

    const { entity, unit } = telemetryParams;
    const rootEntity = entity.rootEntity;
    const rootEntityId = rootEntity.id;

    const telemetryDefenition = {
      [isTelemetrySymbol]: true,
      ...telemetryParams,
      unitId: unit?.id,
      type: "computed",
      entity,
      rootEntityId,
      rootEntity,
    };

    const intervalsData = referenceTelemetry.intervalsData;

    const computedTelmetry = {
      ...telemetryDefenition,
      ...pick(
        referenceTelemetry,
        "baseInterval",
        "interval",
        "fromTs",
        "toTs",
        "targetToTs",
        "days",
        "timestamps",
        "fullTimestamps",
        "intervals",
        "intervalsData",
        "baseIntervals",
        "resolutionIntervals"
      ),
      telemetryDefenition,
      baseTelemetryDefenition: telemetryDefenition,
      id: key,
      key,
      Notifier: ({ children }) => children,
      loading: telemetriesArray.some(({ telemetry }) => telemetry.loading),
      loadingError: telemetriesArray.find(
        ({ telemetry }) => telemetry.loadingError
      ),
      coverage: mean(
        telemetriesArray.map(({ telemetry }) => telemetry.coverage)
      ),
      lastSync: min(
        telemetriesArray.map(({ telemetry }) => telemetry.lastSync)
      ),
      lastConsecutiveDatumIndex,
      datumAtTimestamp: (ts) =>
        computedTelmetry.data[intervalsData.getExactTimestampIndex(ts)],
      datumAtClosestTimestamp: (ts) =>
        computedTelmetry.data[intervalsData.getApproximateTimestampIndex(ts)],
      valueAtTimestamp: (ts) =>
        computedTelmetry.data[intervalsData.getExactTimestampIndex(ts)]?.value,
      valueAtClosestTimestamp: (ts) =>
        computedTelmetry.data[intervalsData.getApproximateTimestampIndex(ts)]
          .value,
    };

    let data = computeFunction(telemetries, startIndex, {
      telemetry: computedTelmetry,
      insights,
    });
    // Dirty state mutation. Not a big deal.
    // To prime current telemetries with needed datums
    // before anyone else attempts to read from them
    const nextData = linkerUserData.data ?? [];
    for (let i = nextData.length; i < length; i++) {
      nextData[i] = {
        timestamp: referenceTelemetry.fullTimestamps[i],
        value: null,
      };
    }
    for (let i = 0; i < data.length; i++) {
      const index = referenceTelemetry.intervalsData.getExactTimestampIndex(
        data[i].timestamp
      );
      if (index < 0) {
        debugger;
        referenceTelemetry.intervalsData.getExactTimestampIndex(
          data[i].timestamp
        );
        throw new Error("Index < 0 :O");
      }
      nextData[index] = data[i];
    }
    data = [...nextData];
    linkerUserData.data = data;

    computedTelmetry.data = data;

    triggerLinkerEvent(computedTelmetry);
  };

  const hydrate = (
    _computeFunction,
    _telemetryParams,
    _telemetries,
    _telemetriesArray,
    _insights
  ) => {
    computeFunction = _computeFunction;

    let hasChanged;

    if (telemetries !== _telemetries) {
      telemetries = _telemetries;
      telemetriesArray = _telemetriesArray;

      hasChanged = true;
    }

    if (insights !== _insights) {
      insights = _insights;

      hasChanged = true;
    }

    if (telemetryParams !== _telemetryParams) {
      telemetryParams = _telemetryParams;

      hasChanged = true;
    }

    if (hasChanged) {
      let needsComputing = true;
      if (!unlinkFunction) {
        unlinkFunction = computedTelemetryLinker.link({
          key,
          callback: (_computedTelemetry) => {
            computedTelemetry = _computedTelemetry;
            onChange && onChange(computedTelemetry);
          },
          linkInitalizerFunction: ({
            triggerEvent,
            isDestroyed,
            linkUserData,
          }) => {
            if (isDestroyed()) {
              return;
            }
            triggerLinkerEvent = triggerEvent;
            linkerUserData = linkUserData;
            doCompute();
            needsComputing = false;
          },
        });
      }
      if (needsComputing) {
        doCompute();
      }
    }
  };

  const unlink = () => {
    triggerLinkerEvent = null;
    linkerUserData = null;
    unlinkFunction && unlinkFunction();
  };

  this.hydrate = hydrate;
  this.unlink = unlink;

  Object.defineProperty(this, "value", {
    get: () => computedTelemetry,
  });
}

const flattenTelemetries = (telemetries, keyPath = []) => {
  const customizer = Array.isArray(telemetries) ? Array : Object;
  return flattenDeep(
    Object.entries(telemetries).map(([key, possibleTelemetry]) => {
      if (possibleTelemetry[isTelemetrySymbol]) {
        return {
          keyPath: [...keyPath, key],
          telemetry: possibleTelemetry,
          customizer,
        };
      }
      return flattenTelemetries(possibleTelemetry, [...keyPath, key]);
    })
  );
};

function LinkedTelmetriesComputer(onChange) {
  let requests,
    telemetries,
    telemetriesArray,
    insights,
    buildingData,
    unitsByName,
    entitiesById,
    gracePeriod,
    intervalsKey;
  /** @type {Object<string,LinkedTelmetryComputer>} */
  let computersByKey = {};

  /**
   * @type {import('../hooks/useLinkedData').LinkedTelemetriesResponseL2}
   */
  let computedTelemetries;

  const setComputedTelemetries = (responses) => {
    const nextComputedTelemetries = {};
    responses.forEach(({ entityId, alias, computedTelemetry }) => {
      setWith(
        nextComputedTelemetries,
        [entityId, alias],
        computedTelemetry,
        Object
      );
    });
    computedTelemetries = nextComputedTelemetries;
  };

  let callbackTimer, responses;
  const addResponse = (response) => {
    if (callbackTimer) {
      clearTimeout(callbackTimer);
      callbackTimer = null;
    }
    if (!responses) {
      responses = [];
    }
    responses.push(response);
    callbackTimer = setTimeout(() => {
      setComputedTelemetries(responses);
      callbackTimer = null;
      responses = null;
      onChange(computedTelemetries);
    });
  };

  /**
   * @param {Request[]} _requests
   * @param {LinkedTelemetry[] | Object<string,LinkedTelemetry> | Object<string,Object<string,LinkedTelemetry>>} _telemetries
   * @param {import('../hooks/useLinkedData').InsightsReponse} _insights
   * @param {import('./postProcessBuildingData').BuildingData} _buildingData
   */
  const hydrate = (_requests, _telemetries, _insights, _buildingData) => {
    let hasChanged, referenceTelemetry;

    if (telemetries !== _telemetries) {
      telemetries = _telemetries;
      telemetriesArray = flattenTelemetries(telemetries);

      hasChanged = true;
    }

    referenceTelemetry = telemetriesArray[0]?.telemetry;

    if (insights !== _insights) {
      insights = _insights;

      hasChanged = true;
    }

    if (buildingData !== _buildingData) {
      buildingData = _buildingData;

      unitsByName = buildingData.unitsByName;
      entitiesById = buildingData.entitiesById;

      hasChanged = true;
    }

    if (requests !== _requests) {
      requests = _requests;

      hasChanged = true;
    }

    gracePeriod =
      buildingData.gracePeriod ??
      buildingData.building.gracePeriod ??
      (referenceTelemetry
        ? referenceTelemetry.interval *
          (buildingData.graceRatio ?? buildingData.building.graceRatio ?? 0.5)
        : "NA");

    intervalsKey = referenceTelemetry
      ? referenceTelemetry.intervalsData.key
      : "NA";

    if (hasChanged) {
      /** @type {Object<string,LinkedTelmetryComputer>} */
      const newComputersByKey = {},
        syncResponses = [];
      requests.forEach(({ telemetryParams, computeFunction }) => {
        let {
          entityId,
          name,
          telemetryName = name,
          alias = telemetryName,
          unit,
        } = telemetryParams;

        const requestKey = `COMPUTED${JSON.stringify([
          entityId,
          alias,
        ])}@${intervalsKey}/${gracePeriod}`;

        if (newComputersByKey[requestKey]) {
          return;
        }

        const entity = entitiesById[entityId];
        unit = isString(unit) ? unitsByName[unit] : unit;

        telemetryParams = {
          ...telemetryParams,
          entity,
          name: name ?? telemetryName ?? alias,
          unit,
        };

        let telemetryComputer = computersByKey[requestKey];

        if (telemetryComputer) {
          syncResponses.push({
            entityId,
            alias,
            computedTelemetry: telemetryComputer.value,
          });
          delete computersByKey[requestKey];
        } else {
          let syncAnswered;
          telemetryComputer = new LinkedTelmetryComputer(
            requestKey,
            (computedTelemetry) => {
              const response = { entityId, alias, computedTelemetry };
              if (!syncAnswered) {
                syncResponses.push(response);
                return;
              }
              addResponse(response);
            }
          );
        }

        telemetryComputer.hydrate(
          computeFunction,
          telemetryParams,
          telemetries,
          telemetriesArray,
          insights
        );

        newComputersByKey[requestKey] = telemetryComputer;
      });

      Object.values(computersByKey).forEach((telemetryComputer) => {
        telemetryComputer.unlink();
      });
      computersByKey = newComputersByKey;

      setComputedTelemetries(syncResponses);
    }
  };

  const unlink = () => {
    Object.values(computersByKey).forEach((telemetryComputer) => {
      telemetryComputer.unlink();
    });
    computersByKey = {};
  };
  const useUmount = () => useEffect(() => unlink, []);

  this.hydrate = hydrate;
  this.unlink = unlink;
  this.useUmount = useUmount;

  Object.defineProperty(this, "value", {
    get: () => computedTelemetries,
  });
}

export default LinkedTelmetriesComputer;
