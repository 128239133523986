import withIconProps from "helpers/withIconProps";

const ArrowIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      {...props}
    >
      <path
        d="M5.25 11L5.25 2.99996M1 6.24264L5.24264 2L9.48528 6.24264"
        stroke="currentcolor"
        stroke-width="1.5"
      />
    </svg>
  );
});

export default ArrowIcon;
