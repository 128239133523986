import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import CollapsibleContainer from "atomic-components/molecules/CollapsibleContainer/CollapsibleContainer";
import { useTranslate } from "modules/language";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import useBuildingData from "modules/building/hooks/useBuildingData";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import getPerformanceKPI from "helpers/getPerformanceKPI";
import { performanceKPIColorTweenerAsHex } from "helpers/performanceKPIColorTweener";
import UnitValue from "atomic-components/atoms/UnitValue";
import KeyValue from "atomic-components/molecules/KeyValue";
import getRatio from "helpers/getRatio";
import FloorsSlider from "atomic-components/organisms/FloorsSlider/FloorsSlider";
import { useMemo } from "react";
import ChilledWaterBreakdownAssumptions from "./ChilledWaterBreakdownAssumptions/ChilledWaterBreakdownAssumptions";
import BreakdownTitle from "../BreakdownTitle/BreakdownTitle";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import { useMergedNotifier } from "helpers/getNotifier";
import {
  useChilledWaterEmissions,
  convertToKWHT,
} from "modules/building/hooks/useChilledWaterEmissions";

type ChilledWaterBreakdownProps = {
  scopes: string[];
};

const ChilledWaterBreakdown = ({ scopes }: ChilledWaterBreakdownProps) => {
  const t = useTranslate();
  const { buildingData } = useBuildingData();
  const { unitsByName, floors } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);

  // tech-1448
  // const {
  //   state: { electricityEmissionIntensity, chillerPlantCOP },
  //   Notifier: editableParamsNotifier,
  // } = useEditableParamsState();

  // tech-1448
  // const getEmissionsInfo = (value: number) => {
  //   const emissionsValue = value < 10000 ? value : value / 1000;
  //   const emissionsUnit =
  //     value < 10000 ? unitsByName.kgco2e : unitsByName.tco2e;
  //   return { emissionsValue, emissionsUnit };
  // };

  const { totalChilledWaterEmissions, Notifier: ENotifier } =
    useChilledWaterEmissions();

  const { resolutions, Notifier: telemetriesNotifier } = useViewData(
    () => [
      // tech-1448
      // ...floors.map((floor) => ({
      //   entityId: floor.id,
      //   resolutionKey: "1month",
      //   telemetries: [{ telemetryName: "chilledWater" }],
      // })),
      {
        entityId: selectedEntity.id,
        resolutionKey: "1month",
        telemetries: [
          {
            telemetryName: "dailyMaximumUniqueEmployees",
          },
        ],
      },
    ],
    [floors, selectedEntity]
  );

  const Notifier = useMergedNotifier(
    telemetriesNotifier,
    // tech-1448
    // editableParamsNotifier,
    ENotifier
  );

  const { dailyMaximumUniqueEmployees } =
    resolutions["1month"][selectedEntity.id];

  const averageMonthlyUniqueEmployees =
    dailyMaximumUniqueEmployees.data[0]?.value;

  const builtupArea = selectedEntity.area;

  const averageEmissionsPerArea = getRatio(
    totalChilledWaterEmissions,
    builtupArea
  );

  const avgEmissionsPerPerson = getRatio(
    totalChilledWaterEmissions,
    averageMonthlyUniqueEmployees
  );

  // tech-1448
  // const { emissionsValue, emissionsUnit } = getEmissionsInfo(
  //   totalChilledWaterEmissions ?? NaN
  // );

  // tech-1448
  // const floorsLabelsColumn = {
  //   mainLabel: t("label.FLOOR") as string,
  //   values: [
  //     {
  //       value: (
  //         <b>
  //           <UnitValue value={emissionsValue} unit={emissionsUnit} />
  //         </b>
  //       ),
  //     },
  //   ],
  // };

  // const floorsColumns = useMemo(() => {
  //   return floors.map((floor) => {
  //     const titleColor = performanceKPIColorTweenerAsHex(
  //       getPerformanceKPI(0, 0)
  //     );
  //     const { chilledWater } = resolutions["1month"][floor.id];
  //     const chilledWaterEmissions = getRatio(
  //       (electricityEmissionIntensity.value ?? NaN) *
  //         convertToKWHT(chilledWater),
  //       chillerPlantCOP.value
  //     );
  //     return {
  //       floor,
  //       titleColor,
  //       values: [
  //         {
  //           value: chilledWaterEmissions,
  //         },
  //       ],
  //     };
  //   });
  // }, [
  //   chillerPlantCOP.value,
  //   electricityEmissionIntensity.value,
  //   floors,
  //   resolutions,
  // ]);

  return (
    <Notifier>
      <CollapsibleContainer
        titleFontWeight="normal"
        title={
          <BreakdownTitle label={t("label.CHILLED_WATER")} scopes={scopes} />
        }
        hasExtraSpacing
      >
        {/* tech-1448 */}
        <WidgetContainer>
          <KeyValue
            keyText={t("label.TOTAL_CHILLED_WATER_EMISSIONS")}
            isLight
            value={
              <>
                <UnitValue
                  value={totalChilledWaterEmissions}
                  unit={unitsByName.kgco2e}
                />
              </>
            }
          />
        </WidgetContainer>
        {/* tech-1448 */}
        {/* <FloorsSlider
          labelsColumn={floorsLabelsColumn}
          columns={floorsColumns}
        /> */}

        <WidgetContainer>
          <KeyValue
            keyText={t("label.AVG_EMISSIONS_PER_PERSON")}
            isLight
            value={
              <>
                <UnitValue
                  value={avgEmissionsPerPerson}
                  unit={unitsByName.kgco2e}
                />
                /<Displayer object={unitsByName.person}></Displayer>
              </>
            }
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            keyText={
              <>
                {t("label.AVG_EMISSIONS_PER_M")}
                <sup>2</sup>
              </>
            }
            isLight
            value={
              <>
                <UnitValue
                  value={averageEmissionsPerArea}
                  unit={unitsByName.kgco2e}
                />
                /<Displayer object={unitsByName.m2}></Displayer>
              </>
            }
          />
        </WidgetContainer>
        <ChilledWaterBreakdownAssumptions />
      </CollapsibleContainer>
    </Notifier>
  );
};

export default ChilledWaterBreakdown;
