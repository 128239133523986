import { flatten, keyBy, orderBy, uniqBy } from "lodash";
import useBuildingData from "modules/building/hooks/useBuildingData";
import CameraControl from "modules/viewer/components/CameraControl";
import DirectionalLight from "modules/viewer/components/DirectionalLight";
import AmbientLight from "modules/viewer/components/AmbientLight";
import IFCModel from "modules/viewer/components/IFCModel";
import ReactiveViewer from "modules/viewer/components/ReactiveViewer";
import React, { useRef, useState, useMemo, useContext, useEffect } from "react";
import HoverControl from "modules/viewer/components/HoverControl";
import ClickControl from "modules/viewer/components/ClickControl";
import { MultiObjectsColorizer } from "modules/viewer/components/ObjectColorizer/ObjectColorizer";
import { useTranslate } from "modules/language";
import EntitiesSideAnnotations from "./components/EntitiesSideAnnotations";
import EntitiesAnnotations from "./components/EntitiesAnnotations";
import flattenWithChildren from "helpers/flattenWithChildren";
import FenceEntity from "./components/FenceEntity";
import CenterCameraOnEntity from "./components/CenterCameraOnEntity";
import ColorizeEntity from "./components/ColorizeEntity";
import ColorizeEntities from "./components/ColorizeEntities";

import "./Model.css";

const HOVERED_OBJECT_PRIORITY = 0;
const HOVERED_OBJECT_COLOR = 0x00c9ea;
const HOVERED_OBJECT_OPACITY = 0.7;

const setViewpointToEntity = (
  cameraController,
  model,
  { viewpointName = "default", animate = true } = {}
) => {
  const viewpoint =
    model.length > 1
      ? model[0]?.viewpoints?.find(({ name }) => name === viewpointName)
      : model?.viewpoints?.find(({ name }) => name === viewpointName);
  if (!cameraController || !viewpoint) {
    return;
  }
  const center =
    model.length > 1 ? model[0]?.points?.center : model?.points?.center;
  cameraController.change({
    quaternion: viewpoint.quaternion,
    position: viewpoint.position,
    center,
    animate,
  });
};

const ModelContext = React.createContext({});
export const useModelState = () => useContext(ModelContext);

/**
 * @typedef {Object} EntityWithMapping
 * @property {import('modules/building/helpers/postProcessBuildingData').Entity} entity
 * @property {String} entityId
 * @property {import('modules/building/helpers/postProcessBuildingData').ModelMapping} mapping
 */

/**
 * @typedef {Object} HoverDataBaseArgs
 * @property {string} object
 * @property {string[]} objects
 * @property {boolean} isPickable
 * @property {boolean} visible
 * @property {{x:number,y:number}} mouse
 */
/**
 * @typedef {HoverDataBaseArgs | EntityWithMapping} HoverDataArgs
 */
/**
 * @typedef {HoverDataBaseArgs | EntityWithMapping | {mouse:{x:number,y:number}}} OnClickArgs
 */
/**
 * @callback IsEntityPickableCallback
 * @param {import('modules/building/helpers/postProcessBuildingData').Entity} param0
 * @returns {boolean}
 */
/**
 * @template {HoverDataArgs} PARAM0
 *
 * @callback Prioritizer
 * @param {PARAM0} param0
 * @returns {number} the priority, the higher the more prior it is
 */
/**
 * @template {OnClickArgs} PARAM0
 *
 * @callback ModelOnClick
 * @param {PARAM0} param0
 */
/**
 * @typedef {Object} ModelProps
 * @property {string} className
 * @property {import('modules/building/helpers/postProcessBuildingData').ModelDefenition} model
 * @property {React.ReactChildren} children
 * @property {boolean} cameraControlsEnabled
 * @property {IsEntityPickableCallback} isEntityPickable
 * @property {Boolean} hoverEnabled
 * @property {Prioritizer} hoverPrioritizer
 * @property {ModelOnClick} onClick
 * @property {Function} setHoveredEntity
 */

/**
 * @type {Prioritizer}
 */
const defaultHoverPrioritizer = ({ visible, isPickable, entity }) => {
  return (entity ? 1 : 0) + (isPickable ? 2 : 0) + (visible ? 4 : 0);
};

/**
 * @type {React.FunctionComponent<ModelProps>}
 */
const Model = ({
  className,
  model,
  children,
  cameraControlsEnabled = true,
  isEntityPickable = () => true,
  hoverEnabled = true,
  hoverPrioritizer = defaultHoverPrioritizer,
  onClick,
  entitiesSideAnnotations,
  entitiesAnnotations,
  focusedEntityOptions,
  rolledOverEntityId,
  setHoveredEntity = () => null,
}) => {
  const { buildingData } = useBuildingData();
  const { entitiesById } = buildingData;

  const {
    url: modelUrl,
    entitiesMappings,
    objectsGroups,
    materialsMappings,
  } = Array.isArray(model) ? model[0] : model;

  const [modelObjects, setModelObjects] = useState([]);
  const [hoverData, setHoverData] = useState(null);
  const cameraControllerRef = useRef();
  const [isCameraMoving, setCameraMoving] = useState(false);

  useEffect(() => {
    if (hoverData?.entity) {
      setHoveredEntity(hoverData.entity);
    } else {
      setHoveredEntity(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoverData]);

  const getEntityWithMappingByObject = useMemo(() => {
    const entityByObject = Object.assign(
      {},
      ...entitiesMappings.map(({ entityId, mappings }) => {
        const objectsIds = flatten(
          mappings.map((mapping) =>
            mapping.objectsIds.map((objectId) => ({
              objectId,
              mapping,
            }))
          )
        );
        return Object.assign(
          {},
          ...objectsIds.map(({ objectId, mapping }) => ({
            [objectId]: {
              entity: entitiesById[entityId],
              entityId,
              mapping,
            },
          }))
        );
      })
    );
    return (object) => {
      return (
        entityByObject[object.name] ??
        entityByObject[object.uuid] ??
        entityByObject[object.id] ??
        entityByObject[object]
      );
    };
  }, [entitiesMappings, entitiesById]);

  const getEntityMappingByEntityId = useMemo(() => {
    const entityMappingsByEntityId = Object.fromEntries(
      entitiesMappings.map(({ entityId, mappings }) => {
        const entityMappingByMappingName = Object.fromEntries(
          mappings.map((mapping) => [mapping.name, mapping])
        );

        return [entityId, entityMappingByMappingName];
      })
    );
    return (entityId, mappingName) => {
      mappingName = mappingName ?? "default";
      const mapping = entityMappingsByEntityId[entityId]?.[mappingName];
      return mapping;
    };
  }, [entitiesMappings]);

  const toHideObjectsIds = useMemo(
    () => objectsGroups.find(({ name }) => name === "toHide")?.objectsIds,
    [objectsGroups]
  );

  const state = useMemo(() => {
    const objects = flattenWithChildren(modelObjects);
    const objectById = {
      ...keyBy(objects, "name"),
      ...keyBy(objects, "id"),
      ...keyBy(objects, "uuid"),
    };
    return {
      getEntityWithMappingByObject,
      getEntityMappingByEntityId,
      isCameraMoving,
      cameraControllerRef,
      objects,
      objectById,
    };
  }, [
    getEntityWithMappingByObject,
    getEntityMappingByEntityId,
    isCameraMoving,
    cameraControllerRef,
    modelObjects,
  ]);

  return (
    <ModelContext.Provider value={state}>
      <ReactiveViewer className={className} height="100%">
        <IFCModel
          url={modelUrl}
          onLoad={({ objects }) => setModelObjects(objects)}
          materialsMappings={materialsMappings}
        />
        {model.length > 1 ? (
          <IFCModel
            url={model[1].url}
            onLoad={({ objects }) => setModelObjects(objects)}
          />
        ) : null}
        <AmbientLight color={0xffffff} intensity={0.25} />
        <DirectionalLight color={0xffffff} intensity={1} />
        <CameraControl
          ref={cameraControllerRef}
          onInit={({ cameraController }) =>
            setViewpointToEntity(cameraController, model, {
              animate: false,
            })
          }
          onCameraMoveStart={() => setCameraMoving(true)}
          onCameraMoveEnd={() => setCameraMoving(false)}
          tweenSpeed={1.5}
          rotationSpeed={2}
          enabled={cameraControlsEnabled}
        />
        <HoverControl
          disabled={!hoverEnabled || isCameraMoving}
          resolveHoverObjectFromIntersections={(intersections) => {
            const allHoverData = uniqBy(
              intersections,
              ({ object }) => object.name
            ).map(({ object }) => {
              const entityWithMapping = getEntityWithMappingByObject(object);
              const entity = entityWithMapping?.entity;
              return {
                ...entityWithMapping,
                object,
                objects: entityWithMapping?.mapping?.objectsIds,
                isPickable: Boolean(entity && isEntityPickable(entity)),
                visible: object.visible,
              };
            });

            const allHoverDataWithPriorities = allHoverData.map(
              (hoverData) => ({
                hoverData,
                object: hoverData.object,
                priority: hoverPrioritizer(hoverData),
              })
            );

            return orderBy(
              allHoverDataWithPriorities,
              ["priority"],
              ["desc"]
            ).find(({ priority }) => priority >= 0)?.object;
          }}
          onHoverEnter={({ object, mouse }) => {
            let hoveredEntityWithMapping = getEntityWithMappingByObject(object);
            const entity = hoveredEntityWithMapping?.entity;
            const isPickable = entity && isEntityPickable(entity);
            setHoverData({
              ...hoveredEntityWithMapping,
              object,
              objects: hoveredEntityWithMapping?.mapping?.objectsIds ?? [
                object,
              ],
              isPickable,
              visible: object.visible,
              mouse,
            });
          }}
          onHoverLeave={() => {
            setHoverData(null);
          }}
          onHoverMove={({ mouse }) => {
            setHoverData(
              (hoverData) =>
                hoverData && {
                  ...hoverData,
                  mouse,
                }
            );
          }}
        />
        <ClickControl
          disabled={isCameraMoving}
          onClick={() => {
            if (!hoverData || !onClick) {
              return;
            }
            onClick(hoverData);
          }}
        />
        {hoverData ? (
          <MultiObjectsColorizer
            objects={hoverData.objects}
            priority={HOVERED_OBJECT_PRIORITY}
            color={HOVERED_OBJECT_COLOR}
            opacity={HOVERED_OBJECT_OPACITY}
          />
        ) : null}
        {toHideObjectsIds ? (
          <MultiObjectsColorizer
            objects={toHideObjectsIds}
            color={0xffffff}
            opacity={0}
          />
        ) : null}
        {entitiesSideAnnotations?.length ? (
          <EntitiesSideAnnotations
            annotations={entitiesSideAnnotations}
            disabled={isCameraMoving}
          />
        ) : null}
        {entitiesAnnotations?.length ? (
          <EntitiesAnnotations
            annotations={entitiesAnnotations}
            disabled={isCameraMoving}
          />
        ) : null}
        {focusedEntityOptions?.type === "fenced" ? (
          <FenceEntity
            entityId={focusedEntityOptions?.entityId}
            color={0xffffff}
          />
        ) : focusedEntityOptions?.type === "locked" ? (
          <>
            <CenterCameraOnEntity
              entityId={focusedEntityOptions?.entityId}
              mappingName={"default"}
              viewpointName={"default"}
              centerName={"center"}
            />
            <ColorizeEntity
              entityId={focusedEntityOptions?.entityId}
              color={0x0000ff}
              opacity={0.6}
              priority={6}
            />
          </>
        ) : null}
        {Array.isArray(rolledOverEntityId) ? (
          <ColorizeEntities
            entitiesIds={rolledOverEntityId}
            color={0x0000ff}
            opacity={0.6}
            priority={6}
          />
        ) : rolledOverEntityId ? (
          <ColorizeEntity
            entityId={rolledOverEntityId}
            color={0x0000ff}
            opacity={0.6}
            priority={6}
          />
        ) : null}
        {children}
      </ReactiveViewer>
    </ModelContext.Provider>
  );
};

export default (props) => {
  const t = useTranslate();
  if (!props.model) {
    return (
      <div className="model__no-model-message">
        {t("message.NO_MODEL_AVAILABLE")}
      </div>
    );
  }
  return <Model {...props} />;
};
