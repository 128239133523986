import CollapsibleContainer from "atomic-components/molecules/CollapsibleContainer/CollapsibleContainer";
import { useTranslate } from "modules/language";
import useBuildingData from "modules/building/hooks/useBuildingData";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import UnitValue from "atomic-components/atoms/UnitValue";
import KeyValue from "atomic-components/molecules/KeyValue";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import { useState } from "react";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import getRatio from "helpers/getRatio";
import BreakdownTitle from "../BreakdownTitle/BreakdownTitle";
import PieChartWithTabs from "atomic-components/organisms/PieChartWithTabs";
import Section from "atomic-components/atoms/Section";
import FormattedValue from "atomic-components/atoms/FormattedValue";
import PrintedPaperFactorAssumption from "./ConsumableBreakdownAssumptions/PrintedPaperFactorAssumption/PrintedPaperFactorAssumption";
import TissuesFactorAssumption from "./ConsumableBreakdownAssumptions/TissuesFactorAssumption/TissuesFactorAssumption";
import ToiletPaperFactorAssumption from "./ConsumableBreakdownAssumptions/ToiletPaperFactorAssumption/ToiletPaperFactorAssumption";
import PaperCupsFactorAssumption from "./ConsumableBreakdownAssumptions/PaperCupsFactorAssumption/PaperCupsFactorAssumption";
import PlasticCupsFactorAssumption from "./ConsumableBreakdownAssumptions/PlasticCupsFactorAssumption/PlasticCupsFactorAssumption";
import WidgetGrid from "atomic-components/molecules/WidgetGrid/WidgetGrid";

export enum ConsumablesEnum {
  PRINTED_PAPER,
  TISSUES,
  TOILET_PAPER,
  PAPER_CUPS,
  PLASTIC_CUPS,
}

const consumablesEnumToTelemetry = {
  [ConsumablesEnum.PRINTED_PAPER]: "printedPaper",
  [ConsumablesEnum.TISSUES]: "tissues",
  [ConsumablesEnum.TOILET_PAPER]: "toiletPaper",
  [ConsumablesEnum.PAPER_CUPS]: "paperCups",
  [ConsumablesEnum.PLASTIC_CUPS]: "plasticCups",
};

const consumablesEnumToAssumptionComponent = {
  [ConsumablesEnum.PRINTED_PAPER]: PrintedPaperFactorAssumption,
  [ConsumablesEnum.TISSUES]: TissuesFactorAssumption,
  [ConsumablesEnum.TOILET_PAPER]: ToiletPaperFactorAssumption,
  [ConsumablesEnum.PAPER_CUPS]: PaperCupsFactorAssumption,
  [ConsumablesEnum.PLASTIC_CUPS]: PlasticCupsFactorAssumption,
};

type ConsumablesBreakdownProps = {
  scopes: string[];
};

const ConsumablesBreakdown = ({ scopes }: ConsumablesBreakdownProps) => {
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);

  const t = useTranslate();

  const [selectedConsumable, setSelectedConsumable] = useState<ConsumablesEnum>(
    ConsumablesEnum.PRINTED_PAPER
  );

  const consumableTabItems = [
    {
      key: ConsumablesEnum.PRINTED_PAPER,
      text: t("label.PRINTED_PAPER"),
      value: 3,
    },
    {
      key: ConsumablesEnum.TISSUES,
      text: t("label.TISSUES"),
      value: 3,
    },
    {
      key: ConsumablesEnum.TOILET_PAPER,
      text: t("label.TOILET_PAPER"),
      value: 3,
    },
    {
      key: ConsumablesEnum.PAPER_CUPS,
      text: t("label.PAPER_CUPS"),
      value: 3,
    },
    {
      key: ConsumablesEnum.PLASTIC_CUPS,
      text: t("label.PLASTIC_CUPS"),
      value: 3,
    },
  ];

  const consumableChartItems = [
    {
      legend: t("label.PRINTED_PAPER"),
      value: 3,
    },
    {
      legend: t("label.TISSUES"),
      value: 3,
    },
    {
      legend: t("label.TOILET_PAPER"),
      value: 3,
    },
    {
      legend: t("label.PAPER_CUPS"),
      value: 3,
    },
    {
      legend: t("label.PLASTIC_CUPS"),
      value: 3,
    },
  ];

  const totalEmissions = consumableTabItems.reduce(
    (acc, { value }) => acc + value,
    0
  );

  const avgEmissionsPerPerson = getRatio(totalEmissions, 0);
  const avgEmissionsPerM2 = "NA";

  const EditableAssumption =
    consumablesEnumToAssumptionComponent[selectedConsumable];

  return (
    <CollapsibleContainer
      titleFontWeight="normal"
      title={<BreakdownTitle label={t("label.CONSUMABLES")} scopes={scopes} />}
      hasExtraSpacing
    >
      <WidgetContainer>
        <KeyValue
          keyText={t("label.TOTAL_EMISSIONS")}
          value={<UnitValue value={totalEmissions} unit={unitsByName.kgco2e} />}
          isLight
        />
      </WidgetContainer>

      <Section variant="withBorder">
        <PieChartWithTabs
          selectedTabKey={selectedConsumable}
          setSelectedTabKey={setSelectedConsumable}
          tabItems={consumableTabItems.map((item) => {
            return {
              ...item,
              value: <FormattedValue value={item.value} />,
            };
          })}
          chartItems={consumableChartItems}
        />
      </Section>

      <WidgetGrid columnCount={1}>
        <WidgetContainer>
          <KeyValue
            keyText={
              consumableTabItems.find(({ key }) => key === selectedConsumable)
                ?.text
            }
            value={
              <UnitValue
                value={
                  consumableTabItems.find(
                    ({ key }) => key === selectedConsumable
                  )?.value ?? 0
                }
                unit={unitsByName.kg}
              />
            }
            isLight
          />
        </WidgetContainer>

        <WidgetContainer>
          <KeyValue
            keyText={t("label.AVG_EMISSIONS_PER_PERSON")}
            value={
              <>
                <UnitValue
                  value={avgEmissionsPerPerson}
                  unit={unitsByName.kgco2e}
                />
                /
                <Displayer object={unitsByName.person} />
              </>
            }
            isLight
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            keyText={
              <>
                {t("label.AVG_EMISSIONS_PER_M")}
                <sup>2</sup>
              </>
            }
            value={
              <>
                <UnitValue
                  value={avgEmissionsPerM2}
                  unit={unitsByName.kgco2e}
                />
                /
                <Displayer object={unitsByName.m2} />
              </>
            }
            isLight
          />
        </WidgetContainer>
      </WidgetGrid>
      <EditableAssumption />
    </CollapsibleContainer>
  );
};

export default ConsumablesBreakdown;
