import { useState, useEffect } from "react";
import Accordion, {
  AccordionGroupElement,
} from "atomic-components/atoms/Accordion";
import EnergyManagement from "svg-icons/EnergyManagement";
import MainMenuItem from "../MainMenuItem/MainMenuItem";
import MainMenuSubItem from "../MainMenuItem/MainMenuSubItem/MainMenuSubItem";
import { useTranslate } from "modules/language";
import { PageUrlEnum } from "router/enums";
import useRouteSegments from "hooks/useRouteSegments";
import classNames from "classnames";
import { RightMainTabKey } from "modules/building/pages/OperationalSpending/components/CommonProvider/types";
import useBuildingData from "modules/building/hooks/useBuildingData";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import useRightTabKey, {
  SEARCH_PARAM_KEY_TAB_KEY,
} from "modules/building/hooks/useRightTabKey";

import s from "./EnergyIntelligenceSubMenu.module.scss";

type EnergyIntelligenceSubMenuProps = {
  isMenuHovered: boolean;
};

const EnergyIntelligenceSubMenu = ({
  isMenuHovered,
}: EnergyIntelligenceSubMenuProps) => {
  const t = useTranslate();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { buildingData } = useBuildingData();
  const selectedEntity = useSelectedEntity(buildingData);
  const { pageUrl } = useRouteSegments();

  const subMenuItems = [
    {
      text: "title.OPERATIONAL_SPENDING",
      pageUrl: PageUrlEnum.OperationalSpending,
      rightTabSearchParam: RightMainTabKey.OperationalSpending,
    },
    {
      text: "title.RECOMMENDATIONS",
      pageUrl: PageUrlEnum.OperationalSpending,
      rightTabSearchParam: RightMainTabKey.Recommendations,
    },
  ];

  if (selectedEntity.type === "floor") {
    subMenuItems.push({
      text: "title.INSIGHTS",
      pageUrl: PageUrlEnum.OperationalSpending,
      rightTabSearchParam: RightMainTabKey.Insights,
    });
  }

  const isActive = subMenuItems.map(({ pageUrl }) => pageUrl).includes(pageUrl);

  const { selectedRightTabKey } = useRightTabKey({
    rightMainTabKeys: subMenuItems.map(
      ({ rightTabSearchParam }) => rightTabSearchParam
    ),
  });

  useEffect(() => {
    if (isMenuHovered) {
      if (isActive) {
        setIsExpanded(true);
      }
      return;
    }
    setIsExpanded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuHovered]);

  return (
    <Accordion className={s.accordion}>
      <AccordionGroupElement
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        text={
          <MainMenuItem
            icon={<EnergyManagement />}
            pageUrl={PageUrlEnum.OperationalSpending}
            text={t("menu.ENERGY_INTELLIGENCE")}
            isActive={isActive}
            searchParams={{
              [SEARCH_PARAM_KEY_TAB_KEY]: RightMainTabKey.OperationalSpending,
            }}
          />
        }
        variant="primary"
        className={classNames(s.accordionGroup, {
          [s.accordionGroupActive]: isActive,
        })}
      >
        {subMenuItems.map(({ text, pageUrl, rightTabSearchParam }) => (
          <MainMenuSubItem
            key={text}
            text={t(text)}
            pageUrl={pageUrl}
            searchParams={{
              [SEARCH_PARAM_KEY_TAB_KEY]: rightTabSearchParam,
            }}
            isActive={isActive && selectedRightTabKey === rightTabSearchParam}
          />
        ))}
      </AccordionGroupElement>
    </Accordion>
  );
};

export default EnergyIntelligenceSubMenu;
