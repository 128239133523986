import classNames from "classnames";
import Minus from "svg-icons/Minus";
import Plus from "svg-icons/Plus";
import SectionTitle, {
  type SectionTitleProps,
} from "atomic-components/atoms/SectionTitle";
import { type ReactNode, HTMLAttributes } from "react";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { ChevronUpIcon, Info } from "svg-icons";

import s from "./CollapsibleContainer.module.scss";

type CollapsibleContainerProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  "title"
> & {
  title?: ReactNode;
  secondaryTitle?: ReactNode | string;
  headerClassName?: string;
  titleFontWeight?: SectionTitleProps["weight"];
  hasExtraSpacing?: boolean;
  collapseIconsType?: "plusMinus" | "arrow";
  tag?: "section" | "div";
  titleVariant?: "withSide" | "default";
} & (
    | {
        isExpanded?: never;
        setIsExpanded?: never;
      }
    | {
        isExpanded: boolean;
        setIsExpanded: (isExpanded: boolean) => void;
      }
  ) &
  (
    | {
        isExplainable?: never;
        tooltipMessage?: never;
      }
    | {
        isExplainable: boolean;
        tooltipMessage?: string;
        isExpanded?: never;
        setIsExpanded?: never;
      }
  );

const CollapsibleContainer = ({
  title,
  secondaryTitle,
  children,
  isExpanded,
  setIsExpanded,
  isExplainable,
  tooltipMessage,
  className,
  headerClassName,
  titleFontWeight,
  hasExtraSpacing,
  collapseIconsType = "plusMinus",
  tag: Tag = "section",
  titleVariant = "withSide",
  ...props
}: CollapsibleContainerProps) => {
  const isCollapsible = Boolean(setIsExpanded);
  const headerTag = isCollapsible ? "button" : "header";

  const expandedIcon =
    collapseIconsType === "arrow" ? (
      <ChevronUpIcon className={s.arrowIcon} />
    ) : (
      <Minus iconScale={1.5} />
    );
  const collapsedIcon =
    collapseIconsType === "arrow" ? (
      <ChevronUpIcon className={classNames(s.arrowIcon, s.upside)} />
    ) : (
      <Plus iconScale={1.5} />
    );

  return (
    <Tag
      className={classNames(
        s.container,
        {
          [s.hasExtraSpacing]: hasExtraSpacing,
        },
        className
      )}
      {...props}
    >
      {title || secondaryTitle || isCollapsible ? (
        <SectionTitle
          tag={headerTag}
          className={headerClassName}
          onClick={() => setIsExpanded?.(!isExpanded)}
          variant={titleVariant}
          weight={titleFontWeight || "bold"}
        >
          {title ? <h3>{title}</h3> : null}
          {secondaryTitle}
          {isCollapsible && (isExpanded ? expandedIcon : collapsedIcon)}
          {isExplainable ? (
            <InfoPopover
              message={tooltipMessage}
              variant="primary"
              placement="top"
            >
              <StrippedButton>
                <Info iconScale={1.2} color="var(--color-primary)" />
              </StrippedButton>
            </InfoPopover>
          ) : (
            <></>
          )}
        </SectionTitle>
      ) : null}
      {children}
    </Tag>
  );
};

export default CollapsibleContainer;
export type { CollapsibleContainerProps };
