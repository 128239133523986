import { SingleEvent } from "../../core/helpers/events";
import { v4 as uuidV4 } from "uuid";

const toasts = [];

const toastsChanged = new SingleEvent();

export const addToast = (toast) => {
  toast.duration = toast.duration ?? 3000;
  toast.closeOnClick = !toast.duration || (toast.closeOnClick ?? true);
  toast.id = toast.id ?? uuidV4();

  const index = toast.id ? toasts.findIndex(({ id }) => toast.id === id) : -1;
  if (index < 0) {
    toasts.push(toast);
  } else {
    toasts.push(toast);
  }
  toastsChanged.triggerEvent({
    name: "addToast",
    toast,
    index,
    toasts: [...toasts],
  });

  if (toast.duration) {
    let clearListener;
    const timer = setTimeout(() => {
      clearListener();
      hideToast(toast);
    }, toast.duration);
    clearListener = toastsChanged.addListener((event) => {
      if (event.toast !== toast) {
        return;
      }
      clearListener();
      clearTimeout(timer);
    });
  }
};

export const hideToast = (toastOrToastId) => {
  const toast = toastOrToastId;
  const toastId = toast.id ?? toastOrToastId;
  const index = toasts.findIndex((t) => toastId === t.id);
  if (index < 0) {
    return false;
  } else {
    let clearListener;
    const timer = setTimeout(() => {
      clearListener();
      const index = toasts.findIndex((t) => toastId === t.id || toast === t);
      if (index >= 0) {
        toasts.splice(index, 1);
        toastsChanged.triggerEvent({
          name: "removeToast",
          toast,
          index,
          toasts: [...toasts],
        });
      }
    }, 310);

    toast.hide = true;
    toastsChanged.triggerEvent({ name: "hideToast", toast, index, toasts });

    clearListener = toastsChanged.addListener((event) => {
      if (event.toast !== toast) {
        return;
      }
      clearListener();
      clearTimeout(timer);
    });
  }
};

export const isToastVisible = (toastId) => {
  return Boolean(toasts.find((toast) => toast.id === toastId));
};

export const getToasts = () => toasts;
export const onChange = (listener) => toastsChanged.addListener(listener);
