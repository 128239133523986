import EditableParamContainer from "../EditableParamContainer/EditableParamContainer";
import KeyValue from "atomic-components/molecules/KeyValue";
import EditableParamValue, {
  EditableParamValueProps,
} from "../../../../components/EditableParamValue/EditableParamValue";
import { type ReactNode, useEffect, useId, useState } from "react";
import EditableParamHistoryContainer from "../EditableParamHistoryContainer/EditableParamHistoryContainer";
import useEditableParamHistory from "modules/building/hooks/useEditableParamHistory";
import EditableParamHistoryButton from "../EditableParamHistoryButton/EditableParamHistoryButton";
import { useEditableParamsState } from "../../../providers/EditableParamsProvider/EditableParamsProvider";
import type { ParamName } from "../../../providers/EditableParamsProvider/definitions";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { Info } from "svg-icons";
import s from "./EditableParamWidget.module.scss";

type EditableParamWidgetProps = {
  paramName: ParamName;
  keyText: ReactNode;
  historyLabel: string;
  isEditEnabled: boolean;
  isHistoryEnabled: boolean;
  isHistoryExpanded?: boolean;
  disabled: boolean;
  maxValue?: EditableParamValueProps["maxValue"];
  minValue?: EditableParamValueProps["minValue"];
  tooltipText?: String;
};

const EditableParamWidget = ({
  paramName,
  keyText,
  historyLabel,
  isEditEnabled,
  isHistoryEnabled,
  isHistoryExpanded: isHistoryExpandedFromProps,
  disabled,
  maxValue,
  minValue,
  tooltipText,
}: EditableParamWidgetProps) => {
  const labelElementId = useId();

  const { state, telemetries, loading } = useEditableParamsState();

  const paramState = state[paramName];

  const historyLogsResp = useEditableParamHistory(
    {
      timeseriesId: telemetries[paramName].timeseriesId,
    },
    { enabled: isHistoryEnabled }
  );

  const [isHistoryExpanded, setIsHistoryExpanded] = useState(
    isHistoryExpandedFromProps
  );

  useEffect(() => {
    setIsHistoryExpanded(isHistoryExpandedFromProps);
  }, [isHistoryExpandedFromProps, isHistoryEnabled]);

  return (
    <div>
      <EditableParamContainer
        isEditEnabled={isEditEnabled}
        onRevert={() => {
          paramState.resetValue();
        }}
      >
        <KeyValue
          keyText={keyText}
          keyTag="label"
          keyProps={{
            id: labelElementId,
          }}
          isLight
          value={
            <>
              <EditableParamValue
                disabled={disabled}
                labelElementId={labelElementId}
                isEditEnabled={isEditEnabled}
                value={paramState.value}
                setValue={(value) => paramState.setValue(value)}
                error={paramState.error}
                setError={(error) => paramState.setError(error)}
                maxValue={maxValue}
                minValue={minValue}
                units={[{ unit: telemetries[paramName].unit }]}
                isLoading={loading}
              />
              {!!tooltipText && (
                <InfoPopover
                  message={tooltipText}
                  variant="primary"
                  placement="top"
                >
                  <StrippedButton>
                    <Info iconScale={1.2} color="var(--color-primary)" />
                  </StrippedButton>
                </InfoPopover>
              )}
            </>
          }
        />
        <EditableParamHistoryButton
          isEnabled={isHistoryEnabled}
          isLoading={historyLogsResp.isLoading}
          hasError={historyLogsResp.isError}
          isExpanded={!!isHistoryExpanded}
          setIsExpanded={setIsHistoryExpanded}
        />
      </EditableParamContainer>
      {isHistoryEnabled &&
      isHistoryExpanded &&
      !historyLogsResp.isLoading &&
      !historyLogsResp.isError ? (
        <EditableParamHistoryContainer
          className={s.historyContainer}
          historyLogs={historyLogsResp.data.items}
          isEditEnabled={isEditEnabled}
          historyLabel={historyLabel}
          setValue={(value) => {
            paramState.setValue(value);
          }}
          units={[
            {
              unit: telemetries[paramName].unit,
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default EditableParamWidget;
export type { EditableParamWidgetProps };
