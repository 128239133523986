import wait from "modules/core/helpers/wait";
import { SingleEvent } from "modules/core/helpers/events";
import hardcoded from "helpers/hardcoded";
import { DEFAULT_INTERVAL } from "./intervals";
import useBuildingData from "../hooks/useBuildingData";

const DEFAULT_GRACE_PERIOD_TO_INTERVAL_RATIO = 0.5;

/** @class */
const LiveListener = function ({ interval, gracePeriod }) {
  const priorityEvent = new SingleEvent();
  const event = new SingleEvent();

  /** @type {number} */
  let lastTimestamp;

  (async () => {
    while (true) {
      lastTimestamp = Date.now() - gracePeriod;
      lastTimestamp = lastTimestamp - (lastTimestamp % interval);
      priorityEvent.triggerEvent({
        timestamp: lastTimestamp,
      });
      event.triggerEvent({
        timestamp: lastTimestamp,
      });

      const nextTimeStamp = lastTimestamp + interval;

      await wait(nextTimeStamp + gracePeriod - Date.now() + 1);
    }
  })();

  this.addListener = event.addListener;
  this.removeListener = event.removeListeners;
  this.addPriorityListener = priorityEvent.addListener;
  this.removePriorityListener = priorityEvent.removeListeners;
  this.getLastTimestamp = () => lastTimestamp;
  this.getLowerLastTimestamp = () => lastTimestamp - interval;
  this.getUpperLastTimestamp = () => lastTimestamp;
};

const cachedLiveListeners = {};
/**
 * @param {{ interval: number, gracePeriod: number }} params
 * @returns {LiveListener}
 */
export const getLiveListener = ({
  interval = DEFAULT_INTERVAL, // Useless arg
  gracePeriod = interval * DEFAULT_GRACE_PERIOD_TO_INTERVAL_RATIO, // Useless arg
} = {}) =>
  cachedLiveListeners[`${interval}-${gracePeriod}`] ??
  (cachedLiveListeners[`${interval}-${gracePeriod}`] = new LiveListener({
    interval: DEFAULT_INTERVAL,
    gracePeriod: DEFAULT_INTERVAL * DEFAULT_GRACE_PERIOD_TO_INTERVAL_RATIO,
  }));

const defaultLiveListener = hardcoded(getLiveListener());
export const addLastTimestampChangeListener = defaultLiveListener.addListener;
export const getCurrentLastTimestamp = defaultLiveListener.getLastTimestamp;

export const useLiveListener = () => {
  const { buildingData } = useBuildingData();
  const rootEntity = buildingData.building;
  const interval = rootEntity.defaultInterval ?? DEFAULT_INTERVAL;
  const graceToIntervalRatio = rootEntity.graceRatio ?? 0.5;
  const gracePeriod = interval * graceToIntervalRatio;

  const liveListener = getLiveListener({ interval, gracePeriod });
  return liveListener;
};
