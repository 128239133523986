import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  createContext,
  Context,
} from "react";
import { useTranslate } from "modules/language";
import { TelemetryFilterKeyEnum, type Trend } from "types/trend";
import { useModalState } from "atomic-components/molecules/Modal";
import {
  AddChartAxisTrendKey,
  AddSubChart,
  ChartPanelContextValue,
  ChartPanelProviderProps,
  GetChartAxisTrendKeyType,
  RemoveChartAxisTrendKey,
  RemoveSubChart,
  SetChartTrendsTrendParam,
  SubChart,
  ChartType,
  SelectedAggregations,
  RemoveSubCharts,
  CustomChart,
  FilterTypeEnum,
  ChartAction,
  chartDetails,
} from "./types";
import {
  addChartAxisTrendKey,
  fillTrendFields,
  getChartAxisTrendKeyType,
  initializeChartAxisTrendKeys,
  removeChartAxisTrendKey,
} from "./utils";
import SubGraphSwapModalBody from "./SubGraphSwapModalBody/SubGraphSwapModalBody";
import useFullDateFilterState from "modules/building/hooks/useFullDateFilterState";
import useLastTimestamp from "modules/building/hooks/useLastTimestamp";
import useTelemetryUnit from "modules/building/hooks/useTelemetryUnit";
import { Nullable } from "types/utils";
import { sortedIndex } from "lodash";
import { GraphType } from "modules/building/hooks/useGraphsFromTrends";
const ChartPanelContext = createContext<ChartPanelContextValue>({
  lineChartStartTs: 0,
  lineChartEndTs: 0,
  setChartStartEndTs: () => {},
  customChart: undefined,
  setCustomChart: () => {},
  subCharts: [],
  addSubChart: () => {},
  removeSubChart: () => {},
  removeSubCharts: () => {},
  mainChartTrends: [],
  setMainChartTrends: () => {},
  mainChartPrimaryAxisTrendKeys: [],
  mainChartSecondaryAxisTrendKeys: [],
  getMainChartAxisTrendKeyType: () => undefined,
  addMainChartAxisTrendKey: () => {},
  removeMainChartAxisTrendKey: () => {},
  firstSubChartTrends: [],
  setFirstSubChartTrends: () => {},
  firstSubChartPrimaryAxisTrendKeys: [],
  firstSubChartSecondaryAxisTrendKeys: [],
  getFirstSubChartAxisTrendKeyType: () => undefined,
  addFirstSubChartAxisTrendKey: () => {},
  removeFirstSubChartAxisTrendKey: () => {},
  secondSubChartTrends: [],
  setSecondSubChartTrends: () => {},
  secondSubChartPrimaryAxisTrendKeys: [],
  secondSubChartSecondaryAxisTrendKeys: [],
  getSecondSubChartAxisTrendKeyType: () => undefined,
  addSecondSubChartAxisTrendKey: () => {},
  removeSecondSubChartAxisTrendKey: () => {},
  chartType: "line",
  setChartType: () => {},
  selectedAggregations: {},
  setSelectedAggregations: () => {},
  selectedTimeBucketsIndexes: [],
  setSelectedTimeBucketsIndexes: () => {},
  toggleTimeBucketIndex: () => {},
  selectedTimeBucketsRangesIndexes: [],
  filterType: undefined,
  setFilterType: () => {},
  minFilterValue: 0,
  maxFilterValue: 0,
  setMinFilterValue: () => {},
  setMaxFilterValue: () => {},
  occupancyFilterTrendKey: undefined,
  setOccupancyFilterTrendKey: () => {},
  chartAction: undefined,
  setChartAction: () => {},
  chartDetails: {
    lowerValue: 0,
    upperValue: 0,
    unit: undefined,
    primaryData: [],
    secondaryData: [],
  },
  setChartDetails: () => {},
});

const ChartPanelProvider = ({ children }: ChartPanelProviderProps) => {
  const t = useTranslate();
  const { setModal } = useModalState();
  const { getTelemetryUnit } = useTelemetryUnit();
  const { fromTs, toTs } = useFullDateFilterState();
  const lastTs = useLastTimestamp();

  const [chartType, setChartType] = useState<ChartType>("line");
  const [selectedAggregations, setSelectedAggregations] =
    useState<SelectedAggregations>({});

  const [[lineChartStartTs, lineChartEndTs], setChartStartEndTs] = useState<
    [number, number]
  >([fromTs, toTs]);

  useEffect(() => {
    const endTs = fromTs <= lastTs && lastTs < toTs ? lastTs : toTs;
    setChartStartEndTs([fromTs, endTs]);
  }, [fromTs, lastTs, toTs]);

  const [customChart, setCustomChart] = useState<CustomChart>();
  const [subCharts, setSubCharts] = useState<SubChart[]>([]);
  const [chartDetails, setChartDetails] = useState<chartDetails>({
    lowerValue: 0,
    upperValue: 0,
    unit: undefined,
    primaryData: [],
    secondaryData: [],
  });
  useEffect(() => {
    const firstSubChartTrends = subCharts[0]?.trends;
    const secondSubChartTrends = subCharts[1]?.trends;
    if (firstSubChartTrends) {
      setFirstSubChartTrends(firstSubChartTrends);
    }
    if (secondSubChartTrends) {
      setSecondSubChartTrends(secondSubChartTrends);
    }
  }, [subCharts]);

  const changeCustomChart = (selectChart: SubChart) => {
    const changeCustom: CustomChart = {
      name: selectChart.key,
      trendKeys: selectChart.trends?.map((trend) => trend.key) || [],
    };

    setMainChartTrends(selectChart.trends || []);
    setCustomChart(changeCustom);
  };

  const setModalFromLeftPane = (custom: CustomChart, sub: SubChart) => {
    const chartFirst: SubChart = {
      key: custom.name,
      text: custom.name,
      type: "custom",
      trends: _mainChartTrends,
    };

    setModal(
      <SubGraphSwapModalBody
        availableSubCharts={[chartFirst, ...subCharts]}
        setSubCharts={setSubCharts}
        newSubChart={sub}
        changeCustomChart={changeCustomChart}
        isCustomChart={true}
      />,
      {
        width: "medium",
      }
    );
  };

  const setModalFromBottomPanel = (sub: SubChart) => {
    setModal(
      <SubGraphSwapModalBody
        availableSubCharts={subCharts}
        setSubCharts={setSubCharts}
        newSubChart={sub}
      />,
      {
        width: "medium",
      }
    );
  };

  const addSubChart: AddSubChart = useCallback(
    (subChart) => {
      if (subCharts.length > 1) {
        if (customChart) {
          setModalFromLeftPane(customChart, subChart);
          return;
        }
        setModalFromBottomPanel(subChart);
        return;
      }

      setSubCharts((prevSubCharts) => {
        return [...prevSubCharts, subChart];
      });
    },
    [setModal, subCharts, customChart]
  );

  const removeSubChart: RemoveSubChart = useCallback(
    (subChartKey) => {
      const subChartIndex = subCharts.findIndex(
        (subChart) => subChart.key === subChartKey
      );

      setSubCharts((prevSubCharts) => {
        return prevSubCharts.filter(
          (prevSubChart) => prevSubChart.key !== subChartKey
        );
      });

      if (subChartIndex === 0) {
        setFirstSubChartPrimaryAxisTrendKeys([]);
        setFirstSubChartSecondaryAxisTrendKeys([]);
      } else {
        setSecondSubChartPrimaryAxisTrendKeys([]);
        setSecondSubChartSecondaryAxisTrendKeys([]);
      }
    },
    [subCharts]
  );

  const removeSubCharts: RemoveSubCharts = useCallback(({ types } = {}) => {
    if (types) {
      const excludedSubChartIndexes: number[] = [];

      setSubCharts((prevSubCharts) => {
        return prevSubCharts.filter((prevSubChart, index) => {
          const excluded = types.includes(prevSubChart.type);

          if (excluded) {
            excludedSubChartIndexes.push(index);
          }

          return !excluded;
        });
      });

      excludedSubChartIndexes.forEach((subChartIndex) => {
        if (subChartIndex === 0) {
          setFirstSubChartPrimaryAxisTrendKeys([]);
          setFirstSubChartSecondaryAxisTrendKeys([]);
        } else {
          setSecondSubChartPrimaryAxisTrendKeys([]);
          setSecondSubChartSecondaryAxisTrendKeys([]);
        }
      });

      return;
    }

    setSubCharts([]);
    setFirstSubChartPrimaryAxisTrendKeys([]);
    setFirstSubChartSecondaryAxisTrendKeys([]);
    setSecondSubChartPrimaryAxisTrendKeys([]);
    setSecondSubChartSecondaryAxisTrendKeys([]);
  }, []);

  /**
   * chartTrends
   */
  const [_mainChartTrends, setMainChartTrends] = useState<
    SetChartTrendsTrendParam[]
  >([]);
  const [_firstSubChartTrends, setFirstSubChartTrends] = useState<
    SetChartTrendsTrendParam[]
  >([]);
  const [_secondSubChartTrends, setSecondSubChartTrends] = useState<
    SetChartTrendsTrendParam[]
  >([]);

  const mainChartTrends: Trend[] = useMemo(() => {
    return fillTrendFields({ trends: _mainChartTrends, getTelemetryUnit });
  }, [_mainChartTrends, getTelemetryUnit]);

  const firstSubChartTrends: Trend[] = useMemo(() => {
    return fillTrendFields({ trends: _firstSubChartTrends, getTelemetryUnit });
  }, [_firstSubChartTrends, getTelemetryUnit]);

  const secondSubChartTrends: Trend[] = useMemo(() => {
    return fillTrendFields({ trends: _secondSubChartTrends, getTelemetryUnit });
  }, [_secondSubChartTrends, getTelemetryUnit]);

  /**
   * chartPrimaryAxisTrendKeys
   */
  const [mainChartPrimaryAxisTrendKeys, setMainChartPrimaryAxisTrendKeys] =
    useState<string[]>([]);
  const [
    firstSubChartPrimaryAxisTrendKeys,
    setFirstSubChartPrimaryAxisTrendKeys,
  ] = useState<string[]>([]);
  const [
    secondSubChartPrimaryAxisTrendKeys,
    setSecondSubChartPrimaryAxisTrendKeys,
  ] = useState<string[]>([]);

  /**
   * chartSecondaryAxisTrendKeys
   */
  const [mainChartSecondaryAxisTrendKeys, setMainChartSecondaryAxisTrendKeys] =
    useState<string[]>([]);
  const [
    firstSubChartSecondaryAxisTrendKeys,
    setFirstSubChartSecondaryAxisTrendKeys,
  ] = useState<string[]>([]);
  const [
    secondSubChartSecondaryAxisTrendKeys,
    setSecondSubChartSecondaryAxisTrendKeys,
  ] = useState<string[]>([]);

  /**
   * getChartAxisTrendKeyType
   */
  const getMainChartAxisTrendKeyType: GetChartAxisTrendKeyType = useCallback(
    (key) =>
      getChartAxisTrendKeyType({
        key,
        chartPrimaryAxisTrendKeys: mainChartPrimaryAxisTrendKeys,
        chartSecondaryAxisTrendKeys: mainChartSecondaryAxisTrendKeys,
      }),
    [mainChartPrimaryAxisTrendKeys, mainChartSecondaryAxisTrendKeys]
  );

  const getFirstSubChartAxisTrendKeyType: GetChartAxisTrendKeyType =
    useCallback(
      (key) =>
        getChartAxisTrendKeyType({
          key,
          chartPrimaryAxisTrendKeys: firstSubChartPrimaryAxisTrendKeys,
          chartSecondaryAxisTrendKeys: firstSubChartSecondaryAxisTrendKeys,
        }),
      [firstSubChartPrimaryAxisTrendKeys, firstSubChartSecondaryAxisTrendKeys]
    );

  const getSecondSubChartAxisTrendKeyType: GetChartAxisTrendKeyType =
    useCallback(
      (key) =>
        getChartAxisTrendKeyType({
          key,
          chartPrimaryAxisTrendKeys: secondSubChartPrimaryAxisTrendKeys,
          chartSecondaryAxisTrendKeys: secondSubChartSecondaryAxisTrendKeys,
        }),
      [secondSubChartPrimaryAxisTrendKeys, secondSubChartSecondaryAxisTrendKeys]
    );

  /**
   * line footer chart action
   */
  const [chartAction, setChartAction] = useState<Nullable<ChartAction>>(null);

  const getPrimaryAxisGraphs = (trends: Trend[]) => {
    const primaryAxisGraphs: GraphType[] = [];

    trends.forEach((trend) => {
      const { key, isAlternate } = trend;
      const axis = getMainChartAxisTrendKeyType(key);

      if (!axis) {
        return;
      }

      const axisGraph = {
        ...trend,
        axis,
        dasharray: isAlternate ? "2" : undefined,
      };

      if (axis === "primary") {
        primaryAxisGraphs.push(axisGraph);
      }
    });

    return primaryAxisGraphs;
  };

  useEffect(() => {
    if (chartAction === ChartAction.Alarm) {
      const primaryAxisGraphs = getPrimaryAxisGraphs(mainChartTrends);

      const graphWithAlarmsInPrimary = primaryAxisGraphs.find(
        ({ hasAlarms }) => hasAlarms
      );

      const mainChartTrendWithAlarms =
        graphWithAlarmsInPrimary ||
        mainChartTrends.find(({ hasAlarms }) => hasAlarms);

      if (mainChartTrendWithAlarms) {
        initializeChartAxisTrendKeys({
          chartPrimaryAxisTrendKeys: [mainChartTrendWithAlarms.key],
          chartSecondaryAxisTrendKeys: [],
          trends: mainChartTrends,
          setChartPrimaryAxisTrendKeys: setMainChartPrimaryAxisTrendKeys,
          setChartSecondaryAxisTrendKeys: setMainChartSecondaryAxisTrendKeys,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartAction, mainChartTrends]);

  /**
   * addChartAxisTrendKey
   */
  const addMainChartAxisTrendKey: AddChartAxisTrendKey = useCallback(
    ({ key, axis }) =>
      addChartAxisTrendKey({
        key,
        axis,
        trends: mainChartTrends,
        chartPrimaryAxisTrendKeys: mainChartPrimaryAxisTrendKeys,
        chartSecondaryAxisTrendKeys: mainChartSecondaryAxisTrendKeys,
        setChartPrimaryAxisTrendKeys: setMainChartPrimaryAxisTrendKeys,
        setChartSecondaryAxisTrendKeys: setMainChartSecondaryAxisTrendKeys,
        t,
        setModal,
        chartAction,
      }),
    [
      mainChartPrimaryAxisTrendKeys,
      mainChartSecondaryAxisTrendKeys,
      mainChartTrends,
      setModal,
      t,
      chartAction,
    ]
  );

  const addFirstSubChartAxisTrendKey: AddChartAxisTrendKey = useCallback(
    ({ key, axis }) =>
      addChartAxisTrendKey({
        key,
        axis,
        trends: firstSubChartTrends,
        chartPrimaryAxisTrendKeys: firstSubChartPrimaryAxisTrendKeys,
        chartSecondaryAxisTrendKeys: firstSubChartSecondaryAxisTrendKeys,
        setChartPrimaryAxisTrendKeys: setFirstSubChartPrimaryAxisTrendKeys,
        setChartSecondaryAxisTrendKeys: setFirstSubChartSecondaryAxisTrendKeys,
        t,
        setModal,
      }),
    [
      firstSubChartPrimaryAxisTrendKeys,
      firstSubChartSecondaryAxisTrendKeys,
      firstSubChartTrends,
      setModal,
      t,
    ]
  );

  const addSecondSubChartAxisTrendKey: AddChartAxisTrendKey = useCallback(
    ({ key, axis }) =>
      addChartAxisTrendKey({
        key,
        axis,
        trends: secondSubChartTrends,
        chartPrimaryAxisTrendKeys: secondSubChartPrimaryAxisTrendKeys,
        chartSecondaryAxisTrendKeys: secondSubChartSecondaryAxisTrendKeys,
        setChartPrimaryAxisTrendKeys: setSecondSubChartPrimaryAxisTrendKeys,
        setChartSecondaryAxisTrendKeys: setSecondSubChartSecondaryAxisTrendKeys,
        t,
        setModal,
      }),
    [
      secondSubChartPrimaryAxisTrendKeys,
      secondSubChartSecondaryAxisTrendKeys,
      secondSubChartTrends,
      setModal,
      t,
    ]
  );

  /**
   * removeChartAxisTrendKey
   */
  const removeMainChartAxisTrendKey: RemoveChartAxisTrendKey = useCallback(
    (key) =>
      removeChartAxisTrendKey({
        key,
        setChartPrimaryAxisTrendKeys: setMainChartPrimaryAxisTrendKeys,
        setChartSecondaryAxisTrendKeys: setMainChartSecondaryAxisTrendKeys,
      }),
    []
  );

  const removeFirstSubChartAxisTrendKey: RemoveChartAxisTrendKey = useCallback(
    (key) =>
      removeChartAxisTrendKey({
        key,
        setChartPrimaryAxisTrendKeys: setFirstSubChartPrimaryAxisTrendKeys,
        setChartSecondaryAxisTrendKeys: setFirstSubChartSecondaryAxisTrendKeys,
      }),
    []
  );

  const removeSecondSubChartAxisTrendKey: RemoveChartAxisTrendKey = useCallback(
    (key) =>
      removeChartAxisTrendKey({
        key,
        setChartPrimaryAxisTrendKeys: setSecondSubChartPrimaryAxisTrendKeys,
        setChartSecondaryAxisTrendKeys: setSecondSubChartSecondaryAxisTrendKeys,
      }),
    []
  );

  /** bar charts time bucket selections */
  const [selectedTimeBucketsIndexes, setSelectedTimeBucketsIndexes] = useState<
    number[]
  >([]);
  const toggleTimeBucketIndex = useCallback(
    (timeBucketIndex: Nullable<number>) => {
      if (timeBucketIndex == null) {
        setSelectedTimeBucketsIndexes([]);
        return;
      }
      setSelectedTimeBucketsIndexes((selectedTimeBucketsIndexes) => {
        let index = sortedIndex(selectedTimeBucketsIndexes, timeBucketIndex);
        selectedTimeBucketsIndexes = [...selectedTimeBucketsIndexes];
        if (selectedTimeBucketsIndexes[index] === timeBucketIndex) {
          selectedTimeBucketsIndexes.splice(index, 1);
        } else {
          selectedTimeBucketsIndexes.splice(index, 0, timeBucketIndex);
        }
        return selectedTimeBucketsIndexes;
      });
    },
    []
  );
  const selectedTimeBucketsRangesIndexes: [number, number][] = useMemo(() => {
    let cursor = selectedTimeBucketsIndexes[0];
    if (cursor == null) {
      return [];
    }
    const ranges: [number, number][] = [];
    let start = cursor;
    let end = cursor;
    for (let i = 1; i < selectedTimeBucketsIndexes.length; i++) {
      const current = selectedTimeBucketsIndexes[i];
      if (current === cursor + 1) {
        cursor = end = current;
      } else {
        ranges.push([start, end]);
        cursor = start = end = current;
      }
    }
    ranges.push([start, end]);
    return ranges;
  }, [selectedTimeBucketsIndexes]);

  /**
   * on chartTrends change, activate first and second trends if no trends are active
   */
  useEffect(() => {
    initializeChartAxisTrendKeys({
      chartPrimaryAxisTrendKeys: mainChartPrimaryAxisTrendKeys,
      chartSecondaryAxisTrendKeys: mainChartSecondaryAxisTrendKeys,
      trends: mainChartTrends,
      setChartPrimaryAxisTrendKeys: setMainChartPrimaryAxisTrendKeys,
      setChartSecondaryAxisTrendKeys: setMainChartSecondaryAxisTrendKeys,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainChartTrends]);

  useEffect(() => {
    initializeChartAxisTrendKeys({
      chartPrimaryAxisTrendKeys: firstSubChartPrimaryAxisTrendKeys,
      chartSecondaryAxisTrendKeys: firstSubChartSecondaryAxisTrendKeys,
      trends: firstSubChartTrends,
      setChartPrimaryAxisTrendKeys: setFirstSubChartPrimaryAxisTrendKeys,
      setChartSecondaryAxisTrendKeys: setFirstSubChartSecondaryAxisTrendKeys,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstSubChartTrends]);

  useEffect(() => {
    initializeChartAxisTrendKeys({
      chartPrimaryAxisTrendKeys: secondSubChartPrimaryAxisTrendKeys,
      chartSecondaryAxisTrendKeys: secondSubChartSecondaryAxisTrendKeys,
      trends: secondSubChartTrends,
      setChartPrimaryAxisTrendKeys: setSecondSubChartPrimaryAxisTrendKeys,
      setChartSecondaryAxisTrendKeys: setSecondSubChartSecondaryAxisTrendKeys,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondSubChartTrends]);

  /**
   * selected filterType
   */
  const [filterType, setFilterType] = useState<FilterTypeEnum>();

  /**
   * selected min max from occupancyFilter
   */
  const [minFilterValue, setMinFilterValue] = useState<number>(0);
  const [maxFilterValue, setMaxFilterValue] = useState<number>(0);

  /**
   * selected occupancyFilterTrendKey
   */
  const [occupancyFilterTrendKey, setOccupancyFilterTrendKey] =
    useState<string>();

  useEffect(() => {
    const selectedOccupancyChartTrends = mainChartTrends.filter(
      ({ telemetryFilterKey }) =>
        telemetryFilterKey === TelemetryFilterKeyEnum.Occupancy
    );

    setOccupancyFilterTrendKey(selectedOccupancyChartTrends[0]?.key);
  }, [mainChartTrends]);

  /**
   * reset selected time bucket indexed (bar chart) when time period changes
   */
  useEffect(() => {
    setSelectedTimeBucketsIndexes([]);
  }, [fromTs, toTs]);

  const state = useMemo(
    () => ({
      lineChartStartTs,
      lineChartEndTs,
      setChartStartEndTs,
      customChart,
      setCustomChart,
      subCharts,
      addSubChart,
      removeSubChart,
      removeSubCharts,
      mainChartTrends,
      setMainChartTrends,
      mainChartPrimaryAxisTrendKeys,
      mainChartSecondaryAxisTrendKeys,
      getMainChartAxisTrendKeyType,
      addMainChartAxisTrendKey,
      removeMainChartAxisTrendKey,
      firstSubChartTrends,
      setFirstSubChartTrends,
      firstSubChartPrimaryAxisTrendKeys,
      firstSubChartSecondaryAxisTrendKeys,
      getFirstSubChartAxisTrendKeyType,
      addFirstSubChartAxisTrendKey,
      removeFirstSubChartAxisTrendKey,
      secondSubChartTrends,
      setSecondSubChartTrends,
      secondSubChartPrimaryAxisTrendKeys,
      secondSubChartSecondaryAxisTrendKeys,
      getSecondSubChartAxisTrendKeyType,
      addSecondSubChartAxisTrendKey,
      removeSecondSubChartAxisTrendKey,
      chartType,
      setChartType,
      selectedAggregations,
      setSelectedAggregations,
      selectedTimeBucketsIndexes,
      setSelectedTimeBucketsIndexes,
      toggleTimeBucketIndex,
      selectedTimeBucketsRangesIndexes,
      filterType,
      setFilterType,
      minFilterValue,
      maxFilterValue,
      setMinFilterValue,
      setMaxFilterValue,
      occupancyFilterTrendKey,
      setOccupancyFilterTrendKey,
      chartAction,
      setChartAction,
      chartDetails,
      setChartDetails,
    }),
    [
      lineChartStartTs,
      lineChartEndTs,
      customChart,
      subCharts,
      addSubChart,
      removeSubChart,
      removeSubCharts,
      mainChartTrends,
      mainChartPrimaryAxisTrendKeys,
      mainChartSecondaryAxisTrendKeys,
      getMainChartAxisTrendKeyType,
      addMainChartAxisTrendKey,
      removeMainChartAxisTrendKey,
      firstSubChartTrends,
      firstSubChartPrimaryAxisTrendKeys,
      firstSubChartSecondaryAxisTrendKeys,
      getFirstSubChartAxisTrendKeyType,
      addFirstSubChartAxisTrendKey,
      removeFirstSubChartAxisTrendKey,
      secondSubChartTrends,
      secondSubChartPrimaryAxisTrendKeys,
      secondSubChartSecondaryAxisTrendKeys,
      getSecondSubChartAxisTrendKeyType,
      addSecondSubChartAxisTrendKey,
      removeSecondSubChartAxisTrendKey,
      chartType,
      selectedAggregations,
      selectedTimeBucketsIndexes,
      toggleTimeBucketIndex,
      selectedTimeBucketsRangesIndexes,
      filterType,
      minFilterValue,
      maxFilterValue,
      occupancyFilterTrendKey,
      chartAction,
      chartDetails,
      setChartDetails,
    ]
  );

  return (
    <ChartPanelContext.Provider value={state}>
      {children}
    </ChartPanelContext.Provider>
  );
};

const useChartPanelState = <SUB_CHART_KEY extends string>() =>
  useContext<ChartPanelContextValue<SUB_CHART_KEY>>(
    ChartPanelContext as unknown as Context<
      ChartPanelContextValue<SUB_CHART_KEY>
    >
  );

export default ChartPanelProvider;
export { useChartPanelState };
