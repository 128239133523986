import Key from "atomic-components/atoms/Key";
import Section from "atomic-components/atoms/Section";
import UnitValue from "atomic-components/atoms/UnitValue";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import KeyValue from "atomic-components/molecules/KeyValue";
import useBuildingData from "modules/building/hooks/useBuildingData";
import { useTranslate } from "modules/language";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { Info } from "svg-icons";
import LeedIcon from "svg-icons/LeedIcon";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";
import { useTravelEmissions } from "modules/building/hooks/useTravelEmissions";
import { useMergedNotifier } from "helpers/getNotifier";
import getRatio from "helpers/getRatio";
import { useTravelAvgEmissionPerRoute } from "modules/building/hooks/useTravelAvgEmissionPerRoute";

import s from "./TravelDailyEmployeeCommute.module.scss";

const TravelDailyEmployeeCommute = () => {
  const t = useTranslate();
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);
  const { employeeVehiclesEmissions, Notifier: ENotifier } =
    useTravelEmissions();

  const { resolutions, Notifier: TMNotifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        resolutionKey: "1month",
        telemetries: [
          {
            telemetryName: "dailyMaximumUniqueEmployees",
          },
        ],
      },
    ],
    [selectedEntity.id]
  );

  const { avgEmissionPerRoute, Notifier: TANotifier } =
    useTravelAvgEmissionPerRoute();

  const { dailyMaximumUniqueEmployees } =
    resolutions["1month"][selectedEntity.id];

  const dailyMaximumUniqueEmployeesValue =
    dailyMaximumUniqueEmployees.data[0]?.value;

  const Notifier = useMergedNotifier(ENotifier, TMNotifier, TANotifier);

  return (
    <Notifier>
      <Section>
        <Key tag="h4">{t("label.DAILY_EMPLOYEE_COMMUTE")}</Key>
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.AVG_EMPLOYEES_PRESENT")}
            value={
              <>
                <UnitValue
                  value={dailyMaximumUniqueEmployeesValue}
                  unit={unitsByName.person}
                />
                /
                <Displayer object={unitsByName.day} />
              </>
            }
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.AVG_EMISSIONS_OVER_PERS")}
            value={
              <>
                <UnitValue
                  value={getRatio(
                    employeeVehiclesEmissions,
                    dailyMaximumUniqueEmployeesValue
                  )}
                  unit={unitsByName.kgco2e}
                />
                /
                <Displayer object={unitsByName.person} />
              </>
            }
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.AVG_EMISSIONS_OVER_DAY")}
            value={
              <>
                <UnitValue
                  value={getRatio(
                    employeeVehiclesEmissions,
                    dailyMaximumUniqueEmployees.days
                  )}
                  unit={unitsByName.kgco2e}
                />
                /
                <Displayer object={unitsByName.day} />
              </>
            }
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={
              <>
                <LeedIcon iconScale={2} className={s.leedIcon} />
                {t("label.AVG_EMISSIONS_PER_ROUTE")}
              </>
            }
            value={
              <>
                <UnitValue value={avgEmissionPerRoute} unit={unitsByName.lbs} />
                <InfoPopover
                  message={t("tooltip.EVALUATE_COMPLIANCE")}
                  variant="primary"
                  placement="top"
                >
                  <StrippedButton>
                    <Info iconScale={1.2} color="var(--color-primary)" />
                  </StrippedButton>
                </InfoPopover>
              </>
            }
          />
        </WidgetContainer>
      </Section>
    </Notifier>
  );
};

export default TravelDailyEmployeeCommute;
