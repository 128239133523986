import useEvaluationWizardModal from "../../../hooks/useEvaluationWizardModal";
import EvaluationWizardBuildingModalBody from "./EvaluationWizardBuildingModalBody";

const useEvaluationWizardBuildingModal = () => {
  return useEvaluationWizardModal({
    evaluationWizardModalElement: <EvaluationWizardBuildingModalBody />,
  });
};

export { useEvaluationWizardBuildingModal };
