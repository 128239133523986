import { useEditableParamsState } from "../pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import {
  useViewData,
  useViewState,
} from "../providers/ViewProvider/ViewProvider";
import useBuildingData from "./useBuildingData";
import useSelectedEntity from "./useSelectedEntity";
import { useMergedNotifier } from "helpers/getNotifier";
import { useUtilsEmissions } from "./useUtilsEmissions";
import { useMemoedLinkedData } from "./useLinkedData";

const useWasteEmissisons = () => {
  const { buildingData } = useBuildingData();
  const selectedEntity = useSelectedEntity(buildingData);
  const {
    state: { solidWasteEmissionsFactor },
    Notifier: EPNotifier,
  } = useEditableParamsState();

  const { telemetries, Notifier: TNotifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        telemetries: [
          { telemetryName: "monthlyAgriculturalWaste" },
          { telemetryName: "monthlyPaperAndCartonWaste" },
          { telemetryName: "monthlyPlasticWaste" },
          { telemetryName: "monthlyFoodWaste" },
        ],
      },
    ],
    [selectedEntity]
  );

  const {
    monthlyAgriculturalWaste,
    monthlyPaperAndCartonWaste,
    monthlyPlasticWaste,
    monthlyFoodWaste,
  } = telemetries[selectedEntity.id];

  const totalWasteContent =
    (monthlyAgriculturalWaste.data[0]?.value ?? NaN) +
    (monthlyPaperAndCartonWaste.data[0]?.value ?? NaN) +
    (monthlyPlasticWaste.data[0]?.value ?? NaN) +
    (monthlyFoodWaste.data[0]?.value ?? NaN);

  const totalWasteEmissions =
    totalWasteContent * (solidWasteEmissionsFactor.value ?? NaN);

  const Notifier = useMergedNotifier(TNotifier, EPNotifier);

  /* EMISSIONS BY YEAR */

  const { fromTs } = useViewState();
  const {
    transformYearEmissions,
    getValues,
    combineMultipleArrays,
    yearFromTimeStamp,
    calculateFromAndTo,
  } = useUtilsEmissions();

  const yearSelected = yearFromTimeStamp(fromTs);
  const timeSelected = calculateFromAndTo(yearSelected);

  const { telemetries: telemetriesByYear } = useMemoedLinkedData(
    timeSelected.firstDay,
    timeSelected.lastDay,
    () => {
      return [
        {
          entityId: selectedEntity.id,
          telemetries: [
            { telemetryName: "monthlyAgriculturalWaste" },
            { telemetryName: "monthlyPaperAndCartonWaste" },
            { telemetryName: "monthlyPlasticWaste" },
            { telemetryName: "monthlyFoodWaste" },
          ],
        },
      ];
    },
    [selectedEntity.id]
  );

  const agriculturalWasteByYearFinal = transformYearEmissions(
    telemetriesByYear[selectedEntity.id].monthlyAgriculturalWaste.data
  );

  const paperAndCartonWasteByYearFinal = transformYearEmissions(
    telemetriesByYear[selectedEntity.id].monthlyPaperAndCartonWaste.data
  );
  const plasticWasteByYearFinal = transformYearEmissions(
    telemetriesByYear[selectedEntity.id].monthlyPlasticWaste.data
  );
  const foodWasteByYearFinal = transformYearEmissions(
    telemetriesByYear[selectedEntity.id].monthlyFoodWaste.data
  );

  const agriculturalWasteByYearValues = getValues(agriculturalWasteByYearFinal);
  const paperAndCartonWasteByYearValues = getValues(
    paperAndCartonWasteByYearFinal
  );
  const plasticWasteByYearValues = getValues(plasticWasteByYearFinal);
  const foodWasteByYearValues = getValues(foodWasteByYearFinal);
  const totalWasteContentByYear = combineMultipleArrays(
    agriculturalWasteByYearValues,
    paperAndCartonWasteByYearValues,
    plasticWasteByYearValues,
    foodWasteByYearValues
  );

  const totalWasteEmissionsByYear = totalWasteContentByYear.map(
    (item) => item * (solidWasteEmissionsFactor.value ?? NaN)
  );

  return {
    totalWasteContent,
    totalWasteEmissions,
    totalWasteEmissionsByYear,
    Notifier,
  };
};

export { useWasteEmissisons };
