import { fromMillis } from "helpers/dateTimeHelper";
import { range } from "lodash";

const weekdaysRange = range(1, 8);

const getEnabledWeekdays = (
  fixedRangeFromTs: number | undefined,
  fixedRangeToTs: number | undefined,
  timezone: string
) => {
  if (!fixedRangeFromTs || !fixedRangeToTs) {
    return [];
  }

  const fixedRangeFrom = fromMillis(fixedRangeFromTs, {
    zone: timezone,
  });
  const fixedRangeTo = fromMillis(fixedRangeToTs, {
    zone: timezone,
  });

  const fixedRangeDaysDiff = fixedRangeTo.diff(fixedRangeFrom, ["days"]).days;
  const fromWeekdayIndex = fixedRangeFrom.weekday - 1;

  return fixedRangeDaysDiff >= 7
    ? weekdaysRange
    : range(fromWeekdayIndex, fromWeekdayIndex + fixedRangeDaysDiff).map(
        (v) => (v % 7) + 1
      );
};

const isDateWithinRange = (dateTs: number, fromTs: number, toTs: number) => {
  return dateTs >= fromTs && dateTs < toTs;
};

const shortDayToAbbreviationLabel = {
  Mon: "label.MONDAY_ABBR",
  Tue: "label.TUESDAY_ABBR",
  Wed: "label.WEDNESDAY_ABBR",
  Thu: "label.THURSDAY_ABBR",
  Fri: "label.FRIDAY_ABBR",
  Sat: "label.SATURDAY_ABBR",
  Sun: "label.SUNDAY_ABBR",
};

export { getEnabledWeekdays, isDateWithinRange, shortDayToAbbreviationLabel };
