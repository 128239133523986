import { type Dispatch, type SetStateAction, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { useSelectedLanguageState, useTranslate } from "../../../language";
import useFullDateFilterState from "modules/building/hooks/useFullDateFilterState";
import DateSelectorPopover, {
  type DateSelectorPopoverProps,
} from "atomic-components/molecules/DateSelectorPopover";
import { LockFixedRangeType } from "atomic-components/molecules/DateSelectorPopover/DateSelectorPopover";

import s from "./DateSelector.module.scss";

const renderDateSelectorButtonDefault = ({
  onClick,
  ref,
}: {
  onClick: () => void;
  ref: React.RefObject<HTMLButtonElement>;
}) => (
  <button className={s.dateSelectorButton} ref={ref} onClick={onClick}>
    <FontAwesomeIcon icon={faCalendar} />
    &nbsp;
    <FontAwesomeIcon icon={faAngleDown} />
  </button>
);

type DateSelectorProps = {
  className?: string;
  renderDateSelectorButton?: (props: {
    onClick: () => void;
    ref: React.RefObject<HTMLButtonElement>;
    areOptionsShown: boolean;
  }) => React.ReactNode;
  view?: FullDateSelectorPopoverProps["view"];
  lockFixedRange?: LockFixedRangeType;
};

const DateSelector = ({
  className,
  renderDateSelectorButton,
  view,
  lockFixedRange,
}: DateSelectorProps) => {
  const [show, setShow] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className={className}>
      {renderDateSelectorButton
        ? renderDateSelectorButton({
            ref: buttonRef,
            onClick: () => setShow((shown) => !shown),
            areOptionsShown: show,
          })
        : renderDateSelectorButtonDefault({
            ref: buttonRef,
            onClick: () => setShow((shown) => !shown),
          })}
      <FullDateSelectorPopover
        anchorElementRef={buttonRef}
        displayed={show}
        setDisplayed={setShow}
        view={view}
        lockFixedRange={lockFixedRange}
      />
    </div>
  );
};

type FullDateSelectorPopoverProps = {
  anchorElementRef: React.RefObject<HTMLButtonElement>;
  displayed: boolean;
  setDisplayed: Dispatch<SetStateAction<boolean>>;
  view?: DateSelectorPopoverProps["view"];
  lockFixedRange?: LockFixedRangeType;
};

const FullDateSelectorPopover = ({
  anchorElementRef,
  displayed,
  setDisplayed,
  view,
  lockFixedRange,
}: FullDateSelectorPopoverProps) => {
  const t = useTranslate();
  const { languageCode } = useSelectedLanguageState();
  const { fromTs, toTs, setDateFilter, rawDateFilter, timezone } =
    useFullDateFilterState();

  return (
    <DateSelectorPopover
      anchorElementRef={anchorElementRef}
      displayed={displayed}
      setDisplayed={setDisplayed}
      languageCode={languageCode}
      fromTs={fromTs}
      toTs={toTs}
      setDateFilter={setDateFilter}
      rawDateFilter={rawDateFilter}
      timezone={timezone}
      showShortcuts
      todayText={t("dateFilter.DAY")}
      weekText={t("dateFilter.WEEK")}
      monthText={t("dateFilter.MONTH")}
      placement="bottom"
      view={view}
      lockFixedRange={lockFixedRange}
    />
  );
};

export default DateSelector;
export { FullDateSelectorPopover };
export type { DateSelectorProps };
