import React, { useEffect } from "react";
import { v4 as uuidV4 } from "uuid";
import colorizeObject from "../../helpers/colorizeObject";
import revertColorizeObject from "../../helpers/revertColorizeObject";
import { useViewerState } from "../Provider";

/**
 * @typedef {Object} ObjectColorizerProps
 * @property {import('three').Object3D | string} object the Object3D to colorize or its id or its name
 * @property {number} [priority] defaults to 0
 * @property {number} [color] the color as a number e.g. 0xff0000 for red
 * @property {number} [opacity] the opacity (0 <=> hide)
 */

/**
 * @type {React.FunctionComponent<ObjectColorizerProps>} param0
 */
const ObjectColorizer = ({ object, color, opacity, priority = 0 }) => {
  const { objects } = useViewerState();
  useEffect(() => {
    if (!objects) {
      return;
    }
    const key = uuidV4();
    const objectToColorize = objects.find(
      (o) =>
        o === object ||
        o.uuid === object ||
        o.id === object ||
        o.name === object
    );
    if (!objectToColorize) {
      return;
    }
    colorizeObject(objectToColorize, { key, color, opacity, priority });
    return () => {
      revertColorizeObject(objectToColorize, key);
    };
  }, [objects, object, color, opacity]);
  return null;
};

export const MultiObjectsColorizer = React.memo(
  ({ objects, color, opacity, priority = 0 }) => {
    return objects.map((object) => (
      <ObjectColorizer
        key={object.name ?? object}
        object={object}
        color={color}
        opacity={opacity}
        priority={priority}
      />
    ));
  }
);

export default ObjectColorizer;
