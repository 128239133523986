import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import KeyValue from "atomic-components/molecules/KeyValue";
import { useTranslate } from "modules/language";
import { useState, type ReactNode } from "react";
import { Clock, Edit, SaveIcon } from "svg-icons";
import { useAuthenticationState } from "modules/authentication/state/authentication.state";
import DotsLoader from "atomic-components/atoms/DotsLoader";
import TooltipAlert from "atomic-components/molecules/TooltipAlert";

import s from "./EditableParamsContainer.module.scss";

type EditableParamsContainerProps = {
  title: ReactNode;
  isEditEnabled: boolean;
  setIsEditEnabled: (enabled: boolean) => void;
  isHistoryEnabled: boolean;
  setIsHistoryEnabled: (enabled: boolean) => void;
  onSave: () => Promise<boolean>;
  children: ReactNode;
};

const EditableParamsContainer = ({
  title,
  isEditEnabled,
  setIsEditEnabled,
  isHistoryEnabled,
  setIsHistoryEnabled,
  onSave,
  children,
}: EditableParamsContainerProps) => {
  const t = useTranslate();
  const { roles } = useAuthenticationState() as {
    user: { username: string };
    roles: { name: string }[];
  };
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const isAdmin = Boolean(roles?.find(({ name }) => name === "admin"));

  return (
    <form
      className={s.form}
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoading(true);
        setError("");

        onSave()
          .then((result) => {
            if (result) {
              setIsEditEnabled(false);
            }
          })
          .catch(() => {
            setError(t("error.SERVER_ERROR_OCCURRED"));
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
    >
      <KeyValue
        keyTag="h4"
        keyText={title}
        value={
          <div className={s.controls}>
            {error ? <TooltipAlert iconType="error" tooltip={error} /> : null}
            {isLoading ? <DotsLoader /> : null}
            <StrippedButton
              isFlex
              type="button"
              title={
                isHistoryEnabled
                  ? t("action.DISABLE_HISTORY")
                  : t("action.ENABLE_HISTORY")
              }
              onClick={() => {
                setIsHistoryEnabled(!isHistoryEnabled);
              }}
            >
              <Clock
                color={
                  isHistoryEnabled
                    ? "var(--color-primary)"
                    : "var(--gray-medium-color)"
                }
              />
            </StrippedButton>
            {isAdmin ? (
              isEditEnabled ? (
                <StrippedButton isFlex type="submit" title={t("action.SAVE")}>
                  <SaveIcon color="var(--color-primary)" width={10} />
                </StrippedButton>
              ) : (
                <StrippedButton
                  isFlex
                  type="button"
                  title={t("action.EDIT")}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEditEnabled(true);
                  }}
                >
                  <Edit color="var(--color-primary)" />
                </StrippedButton>
              )
            ) : null}
          </div>
        }
      />
      {children}
    </form>
  );
};

export default EditableParamsContainer;
export type { EditableParamsContainerProps };
