import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import FormattedValue from "atomic-components/atoms/FormattedValue";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import UnitValue from "atomic-components/atoms/UnitValue";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import KeyValue from "atomic-components/molecules/KeyValue";
import WidgetGrid from "atomic-components/molecules/WidgetGrid/WidgetGrid";
import WidgetRadioTab from "atomic-components/molecules/WidgetRadioTab/WidgetRadioTab";
import useBuildingData from "modules/building/hooks/useBuildingData";
import ViewPieChartStatusContainer from "atomic-components/organisms/PieChartStatusContainer/PieChartStatusContainer";
import { useTranslate } from "modules/language";
import { useId, useMemo, useState } from "react";
import { Info } from "svg-icons";

enum TrendsEnum {
  TOTAL,
  HVAC,
  LIGHTING,
  OTHER_LOADS,
}

const FloorsElectricityBreakdown = () => {
  const { buildingData } = useBuildingData();
  const { unitsByName, floors } = buildingData;
  const t = useTranslate();
  const elementId = useId();

  const [selectedTrend, setSelectedTrend] = useState<TrendsEnum>(
    TrendsEnum.TOTAL
  );

  const createTabIds = (key: TrendsEnum) => {
    return {
      tabId: `${elementId}-${key}`,
      panelId: `${elementId}-panel-${key}`,
    };
  };

  const trendElements = [
    {
      key: TrendsEnum.TOTAL,
      text: t("label.TOTAL"),
      value: 50,
      unit: unitsByName.kgco2e,
    },
    {
      key: TrendsEnum.HVAC,
      text: t("label.HVAC"),
      value: 10,
      unit: unitsByName.kgco2e,
    },
    {
      key: TrendsEnum.LIGHTING,
      text: t("label.LIGHTING"),
      value: 20,
      unit: unitsByName.kgco2e,
    },
    {
      key: TrendsEnum.OTHER_LOADS,
      text: t("label.OTHER_LOADS"),
      value: 20,
      unit: unitsByName.kgco2e,
    },
  ];

  const chartItems = useMemo(() => {
    return floors.map((floor, i) => {
      const currentValue = 5;

      return {
        legend: floor.name || <Displayer object={floor} />,
        value: currentValue,
        unit: unitsByName.kgco2e,
        color: `hsl(220, ${75 - (50 * i) / (floors.length - 1)}%, ${
          90 - (80 * i) / (floors.length - 1)
        }%)`,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floors, selectedTrend]);

  const selectedTrendTabIds = createTabIds(selectedTrend);

  return (
    <>
      <ViewPieChartStatusContainer
        statusTitle={
          <>
            {t("label.PRESUMED_FLOORS_EMISSIONS")}
            <InfoPopover
              message={t("tooltip.PRESUMED_EMISSIONS")}
              variant="primary"
              placement="top"
            >
              <StrippedButton>
                <Info iconScale={1.2} color="var(--color-primary)" />
              </StrippedButton>
            </InfoPopover>
          </>
        }
        statusElement={
          <>
            {trendElements.map(({ key, text, value }) => {
              const { panelId, tabId } = createTabIds(key);
              return (
                <WidgetRadioTab
                  key={key}
                  id={tabId}
                  controlsElementId={panelId}
                  keyText={text}
                  value={<FormattedValue value={value} />}
                  isSelectable
                  isSelected={selectedTrend === key}
                  onClick={() => setSelectedTrend(key)}
                />
              );
            })}
          </>
        }
        chartItems={chartItems}
        chartProps={{
          role: "tabpanel",
          id: selectedTrendTabIds.panelId,
          "aria-labelledby": selectedTrendTabIds.tabId,
        }}
        chartTooltipUnit={unitsByName.kgco2e}
      />

      <br />

      <WidgetGrid columnCount={1}>
        <WidgetContainer>
          <KeyValue
            keyText={t("label.TOTAL_EMISSIONS")}
            value={<UnitValue value={50} unit={unitsByName.kgco2e} />}
            isLight
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            keyText={t("label.AVG_EMISSIONS_PER_PERSON")}
            value={
              <>
                <UnitValue value={0.5} unit={unitsByName.kgco2e} />
                /
                <Displayer object={unitsByName.person} />
              </>
            }
            isLight
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            keyText={
              <>
                {t("label.AVG_EMISSIONS_PER_M")}
                <sup>2</sup>
              </>
            }
            value={
              <>
                <UnitValue value={0} unit={unitsByName.kgco2e} />
                /
                <Displayer object={unitsByName.m2} />
              </>
            }
            isLight
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            keyText={t("label.AVG_EMISSIONS_PER_DAY")}
            value={
              <>
                <UnitValue value={0} unit={unitsByName.kgco2e} />/
                {t("label.DAY")}
              </>
            }
            isLight
          />
        </WidgetContainer>
      </WidgetGrid>
    </>
  );
};

export default FloorsElectricityBreakdown;
