import withIconProps from "helpers/withIconProps";

const PM10Icon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 13 16"
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 5.04907C0 4.22065 0.671573 3.54907 1.5 3.54907H1.51C2.33843 3.54907 3.01 4.22065 3.01 5.04907C3.01 5.8775 2.33843 6.54907 1.51 6.54907H1.5C0.671573 6.54907 0 5.8775 0 5.04907Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 11.0491C0 10.2206 0.671573 9.54907 1.5 9.54907H1.51C2.33843 9.54907 3.01 10.2206 3.01 11.0491C3.01 11.8775 2.33843 12.5491 1.51 12.5491H1.5C0.671573 12.5491 0 11.8775 0 11.0491Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 2C5 1.17157 5.67157 0.5 6.5 0.5H6.51C7.33843 0.5 8.01 1.17157 8.01 2C8.01 2.82843 7.33843 3.5 6.51 3.5H6.5C5.67157 3.5 5 2.82843 5 2Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 14C5 13.1716 5.67157 12.5 6.5 12.5H6.51C7.33843 12.5 8.01 13.1716 8.01 14C8.01 14.8284 7.33843 15.5 6.51 15.5H6.5C5.67157 15.5 5 14.8284 5 14Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 5.04907C10 4.22065 10.6716 3.54907 11.5 3.54907H11.51C12.3384 3.54907 13.01 4.22065 13.01 5.04907C13.01 5.8775 12.3384 6.54907 11.51 6.54907H11.5C10.6716 6.54907 10 5.8775 10 5.04907Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 11.0491C10 10.2206 10.6716 9.54907 11.5 9.54907H11.51C12.3384 9.54907 13.01 10.2206 13.01 11.0491C13.01 11.8775 12.3384 12.5491 11.51 12.5491H11.5C10.6716 12.5491 10 11.8775 10 11.0491Z"
        fill="currentColor"
      />
      <path
        d="M5.43444 6.36364V10H4.55731V7.18395H4.536L3.72279 7.68111V6.92116L4.61945 6.36364H5.43444ZM7.74223 10.0888C7.425 10.0888 7.15156 10.0136 6.92192 9.86328C6.69228 9.71177 6.51531 9.49455 6.39102 9.21165C6.26673 8.92756 6.20518 8.58606 6.20637 8.18714C6.20755 7.78823 6.26969 7.44969 6.3928 7.17152C6.51709 6.89216 6.69346 6.67969 6.92192 6.53409C7.15156 6.38731 7.425 6.31392 7.74223 6.31392C8.05947 6.31392 8.3329 6.38731 8.56254 6.53409C8.79337 6.67969 8.97093 6.89216 9.09521 7.17152C9.2195 7.45088 9.28106 7.78942 9.27987 8.18714C9.27987 8.58724 9.21773 8.92933 9.09344 9.21342C8.96915 9.49751 8.79218 9.71473 8.56254 9.86506C8.33409 10.0142 8.06065 10.0888 7.74223 10.0888ZM7.74223 9.36967C7.93163 9.36967 8.08492 9.2732 8.2021 9.08026C8.31929 8.88613 8.37729 8.58842 8.37611 8.18714C8.37611 7.92436 8.34948 7.70774 8.29621 7.53729C8.24294 7.36565 8.16896 7.23781 8.07426 7.15376C7.97957 7.06972 7.86889 7.0277 7.74223 7.0277C7.55402 7.0277 7.40191 7.12299 7.28591 7.31357C7.16991 7.50296 7.11131 7.79415 7.11013 8.18714C7.10895 8.45348 7.1344 8.67424 7.18648 8.84943C7.23975 9.02462 7.31432 9.15542 7.4102 9.24183C7.50608 9.32706 7.61676 9.36967 7.74223 9.36967Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default PM10Icon;
