import classNames from "classnames";
import React from "react";

const HeatingCooling = ({
  className,
  heating = false,
  offColor = "#5b5959",
  heatingColor = "#ec3425",
  coolingColor = "#2534ec",
  iconScale = 1,
  icon = true,
}) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      viewBox="0 0 11 11"
    >
      <path
        fill={heating === true || heating === 1 ? heatingColor : offColor}
        d="M5.15,.39c-.19,0-.34,.15-.34,.34V1.86c-.42,.04-.83,.15-1.2,.32l-.57-.98c-.09-.16-.3-.22-.47-.13-.16,.09-.22,.3-.13,.47l.57,.98c-.34,.24-.63,.54-.87,.87l-.98-.57c-.16-.09-.37-.04-.47,.13-.09,.16-.04,.37,.13,.47l.98,.57c-.17,.37-.28,.77-.32,1.2H.34C.15,5.2,0,5.35,0,5.54s.15,.34,.34,.34H1.48c.04,.42,.15,.83,.32,1.2l-.98,.57c-.16,.09-.22,.3-.13,.47,.09,.17,.3,.22,.47,.13l.98-.57c.24,.34,.54,.63,.87,.87l-.57,.98c-.09,.16-.04,.37,.13,.47,.16,.09,.37,.04,.47-.13l.57-.98c.37,.17,.77,.28,1.2,.32v1.13c0,.19,.15,.34,.34,.34s.34-.15,.34-.34v-1.46c0-.19-.15-.34-.34-.34-1.66,0-3-1.35-3-3s1.35-3,3-3c.19,0,.34-.15,.34-.34V.73c0-.19-.15-.34-.34-.34h0Z"
      />
      <path
        fill={heating === false || heating === 0 ? coolingColor : offColor}
        d="M10.5,5.89l-1.94,.45-1.48-.8,1.48-.8,1.94,.45s.05,0,.08,0c.16,0,.3-.11,.33-.26,.04-.18-.07-.37-.26-.41l-1.19-.28,1.12-.6c.17-.09,.23-.3,.14-.46s-.3-.23-.46-.14l-1.14,.62,.45-1.33c.06-.18-.04-.37-.22-.43-.18-.06-.37,.04-.43,.22l-.69,2.03-1.52,.83V2.8l1.62-1.51c.14-.13,.15-.35,.02-.48-.13-.14-.35-.15-.48-.02l-1.16,1.07V.74c0-.19-.15-.34-.34-.34s-.34,.15-.34,.34V10.35c0,.19,.15,.34,.34,.34s.34-.15,.34-.34v-1.12l1.16,1.07c.07,.06,.15,.09,.23,.09,.31,0,.46-.38,.23-.59l-1.62-1.51v-2.17l1.52,.83,.69,2.03c.06,.18,.26,.28,.43,.22,.18-.06,.28-.26,.22-.43l-.45-1.33,1.14,.62c.17,.09,.37,.03,.46-.14,.09-.17,.03-.37-.14-.46l-1.12-.6,1.19-.28c.18-.04,.3-.23,.26-.41s-.23-.3-.41-.26h0Z"
      />
    </svg>
  );
};

export default HeatingCooling;
