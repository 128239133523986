import useBuildingData from "modules/building/hooks/useBuildingData";
import { useTranslate } from "modules/language";
import { useMemo } from "react";
import Section from "atomic-components/atoms/Section";
import UnitValue from "atomic-components/atoms/UnitValue";
import SectionTitle from "atomic-components/atoms/SectionTitle";
import getPercent from "helpers/getPercent";
import PieChartWithTabs from "atomic-components/organisms/PieChartWithTabs";

enum WasteTypesEnum {
  AGRI_WASTE,
  PAPER_WASTE,
  PLASTIC_WASTE,
  FOOD_WASTE,
}

const WasteChart = ({
  totalWasteContent,
  agriWaste,
  paperWaste,
  plasticWaste,
  foodWaste,
}: {
  totalWasteContent: number;
  agriWaste: number;
  paperWaste: number;
  plasticWaste: number;
  foodWaste: number;
}) => {
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;

  const t = useTranslate();

  const getWasteTypePercentage = (wasteValue: number) => {
    return getPercent(wasteValue, totalWasteContent);
  };

  const tabItems = [
    {
      key: WasteTypesEnum.AGRI_WASTE,
      text: t("label.AGRIWASTE"),
      value: (
        <UnitValue
          value={getWasteTypePercentage(agriWaste)}
          unit={unitsByName.percent}
        />
      ),
      rawValue: getWasteTypePercentage(agriWaste),
    },
    {
      key: WasteTypesEnum.PAPER_WASTE,
      text: t("label.PAPER_AND_CARTON"),
      value: (
        <UnitValue
          value={getWasteTypePercentage(paperWaste)}
          unit={unitsByName.percent}
        />
      ),
      rawValue: getWasteTypePercentage(paperWaste),
    },
    {
      key: WasteTypesEnum.PLASTIC_WASTE,
      text: t("label.PLASTIC"),
      value: (
        <UnitValue
          value={getWasteTypePercentage(plasticWaste)}
          unit={unitsByName.percent}
        />
      ),
      rawValue: getWasteTypePercentage(plasticWaste),
    },
    {
      key: WasteTypesEnum.FOOD_WASTE,
      text: t("label.FOOD_WASTE"),
      value: (
        <UnitValue
          value={getWasteTypePercentage(foodWaste)}
          unit={unitsByName.percent}
        />
      ),
      rawValue: getWasteTypePercentage(foodWaste),
    },
  ];

  const chartItems = useMemo(() => {
    return tabItems.map(({ text, rawValue }) => {
      return {
        legend: t(text),
        value: rawValue,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabItems]);

  return (
    <Section>
      <SectionTitle weight="normal">
        {t("label.WASTE_CONTENT_BREAKDOWN")}
      </SectionTitle>
      <PieChartWithTabs
        tabItems={tabItems}
        chartItems={chartItems}
        chartTooltipUnit={unitsByName.percent}
      />
    </Section>
  );
};

export default WasteChart;
