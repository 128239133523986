import React, { useRef, useEffect, useState, useMemo } from "react";
import { pull, uniqBy } from "lodash";
import Loader from "atomic-components/atoms/Loader";
import InfoPopover from "atomic-components/atoms/InfoPopover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslate } from "modules/language";
import { SingleEvent } from "modules/core/helpers/events";

const notifierSetters = [];
const pushNotifierSetter = (setter) => {
  notifierSetters.push(setter);
  return () => pull(notifierSetters, setter);
};
const hideNotifiers = () => {
  notifierSetters.forEach((setter) => setter(false));
  notifierSetters.splice(0, notifierSetters.length);
};

const setNotifierPosition = ({ pre, post } = {}, notifierVerticalPosition) => {
  if (!pre || !post) {
    return;
  }
  const height = post.offsetTop - pre.offsetTop;
  const positioner = pre.firstChild;
  positioner.style.top = `${height * notifierVerticalPosition}px`;
  setTimeout(() => {
    try {
      const height = post.offsetTop - pre.offsetTop;
      const positioner = pre.firstChild;
      positioner.style.top = `${height * notifierVerticalPosition}px`;
    } catch (err) {
      /* empty */
    }
  }, 250);
  setTimeout(() => {
    try {
      const height = post.offsetTop - pre.offsetTop;
      const positioner = pre.firstChild;
      positioner.style.top = `${height * notifierVerticalPosition}px`;
    } catch (err) {
      /* empty */
    }
  }, 500);
  setTimeout(() => {
    try {
      const height = post.offsetTop - pre.offsetTop;
      const positioner = pre.firstChild;
      positioner.style.top = `${height * notifierVerticalPosition}px`;
    } catch (err) {
      /* empty */
    }
  }, 1000);
};

const getNotifier = ({
  loadingTranslationKey: defaultLoadingTranslationKey = "popover.LOADING",
  getErrorTranslationKey = () => "OOPS",
  getWarningTranslationKey = () => "WARNING",
}) => {
  const Notifier = ({
    loading,
    error,
    warning,
    notifierVerticalPosition = 0.5,
    children,
    loadingTranslationKey,
    errorTranslationKey,
    warningTranslationKey,
  }) => {
    const t = useTranslate();
    const ref = useRef({});
    const setRef = (name, element) => {
      ref.current[name] = element;
      setNotifierPosition(ref.current, notifierVerticalPosition);
    };

    const enabled = loading || error || warning;
    const [showNotifier, setShowNotifier] = useState(enabled);
    useEffect(() => {
      setShowNotifier(enabled);
      if (enabled) {
        return pushNotifierSetter(setShowNotifier);
      }
    }, [enabled]);

    if (!showNotifier) {
      return <React.Fragment key="base">{children}</React.Fragment>;
    }

    return (
      <div style={{ height: "100%" }}>
        <div
          key="pre"
          style={{
            position: "relative",
            height: 0,
            zIndex: "var(--z-index-notifier)",
            overflow: "visible",
          }}
          ref={(element) => setRef("pre", element)}
        >
          <InfoPopover
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%,-50%)",
              transitionDuration: "350ms",
              overflow: "visible",
            }}
            addSpaceBefore={false}
            addSpaceAfter={false}
          >
            <div
              style={{
                width: "22px",
                height: "22px",
                borderRadius: "50%",
                backgroundColor: "wheat",
                position: "relative",
                border: "1px solid rgba(0,0,0,.4)",
                boxShadow: "rgb(255 255 255 / 50%) 0px 0px 10px 5px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                {warning ? (
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color="orange"
                  />
                ) : error ? (
                  <FontAwesomeIcon icon={faExclamationTriangle} color="red" />
                ) : (
                  <Loader marginTop={0} size={16} borderWidth={2} />
                )}
              </span>
            </div>
            <div style={{ width: "250px", maxWidth: "50vw" }}>
              {warning ? (
                <span>
                  <div>
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      color="orange"
                    />{" "}
                    {t(
                      warningTranslationKey ?? getWarningTranslationKey(warning)
                    )}
                  </div>
                  <div style={{ textAlign: "end" }}>
                    <button
                      style={{
                        backgroundColor: "lightblue",
                        border: 0,
                        padding: "2px 5px",
                        borderRadius: "6px",
                      }}
                      onClick={() => hideNotifiers()}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} />{" "}
                      {t("action.HIDE_ALL")}
                    </button>
                  </div>
                </span>
              ) : error ? (
                <span>
                  <div>
                    <FontAwesomeIcon icon={faExclamationTriangle} color="red" />{" "}
                    {t(errorTranslationKey ?? getErrorTranslationKey(error))}
                  </div>
                  <div style={{ textAlign: "end" }}>
                    <button
                      style={{
                        backgroundColor: "lightblue",
                        border: 0,
                        padding: "2px 5px",
                        borderRadius: "6px",
                      }}
                      onClick={() => hideNotifiers()}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} />{" "}
                      {t("action.HIDE_ALL")}
                    </button>
                  </div>
                </span>
              ) : (
                <span>
                  <div>
                    {t(loadingTranslationKey ?? defaultLoadingTranslationKey)}
                  </div>
                  <div style={{ textAlign: "end" }}>
                    <button
                      style={{
                        backgroundColor: "lightblue",
                        border: 0,
                        padding: "2px 5px",
                        borderRadius: "6px",
                      }}
                      onClick={() => hideNotifiers()}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} />{" "}
                      {t("action.HIDE_ALL")}
                    </button>
                  </div>
                </span>
              )}
            </div>
          </InfoPopover>
        </div>
        <React.Fragment key="base">{children}</React.Fragment>
        <div
          key="post"
          style={{ visibility: "hidden", height: 0 }}
          ref={(element) => setRef("post", element)}
        ></div>
      </div>
    );
  };

  return Notifier;
};

let linkedNotiferSequenceNumber = 0;
/**
 * @callback GetTranslationKey
 * @param {Object} errorOrWarningObject
 * @param {string} defaultTranslationKey
 * @returns {string} the translation key to use
 *
 * @typedef {Object} GetLinkedNotifierParams
 * @property {import('modules/core/helpers/events').SingleEvent} event
 * @property {Function} initializer
 * @property {number} [defaultNotifierVerticalPosition] between 0 and 1. Defaults to 0.5
 * @property {string} [defaultLoadingTranslationKey]
 * @property {string} [defaultErrorTranslationKey]
 * @property {string} [defaultWarningTranslationKey]
 * @property {GetTranslationKey} [getWarningTranslationKey]
 * @property {GetTranslationKey} [getErrorTranslationKey]
 *
 * @typedef {Object} LinkedNotifierParams
 * @property {import('react').ReactNode} children
 * @property {number} [notifierVerticalPosition] between 0 and 1
 * @property {string} [loadingTranslationKey]
 * @property {string} [errorTranslationKey]
 * @property {string} [warningTranslationKey]
 *
 * @typedef {import('react').FunctionComponent<LinkedNotifierParams>} NotifierComponent
 *
 * @param {GetLinkedNotifierParams} param0
 * @returns {NotifierComponent}
 */
export const getLinkedNotifier = ({
  event,
  initializer = () => ({
    loading: false,
    error: null,
    warning: null,
  }),
  defaultNotifierVerticalPosition = 0.5,
  defaultLoadingTranslationKey = "LOADING",
  defaultErrorTranslationKey = "OOPS",
  defaultWarningTranslationKey = "OOPS",
  getLoadingTranslationKey = () => null,
  getWarningTranslationKey = () => null,
  getErrorTranslationKey = () => null,
}) => {
  /**
   * @param {LinkedNotifierParams} param0
   * @returns {import('react').ReactElement}
   */
  const Notifier = ({
    children,
    notifierVerticalPosition = defaultNotifierVerticalPosition,
    loadingTranslationKey = defaultLoadingTranslationKey,
    errorTranslationKey = defaultErrorTranslationKey,
    warningTranslationKey = defaultWarningTranslationKey,
  }) => {
    const t = useTranslate();
    const ref = useRef({});
    const setRef = (name, element) => {
      ref.current[name] = element;
      setNotifierPosition(ref.current, notifierVerticalPosition);
    };

    const [{ loading, error, warning }, setState] = useState(initializer);
    useEffect(() => {
      const currentState = initializer();
      if (
        currentState.loading !== loading ||
        currentState.error !== error ||
        currentState.warning !== warning
      ) {
        setState(currentState);
      }
      return event.addListener(setState);
    }, []);

    const enabled = loading || error || warning;
    const [showNotifier, setShowNotifier] = useState(enabled);
    useEffect(() => {
      setShowNotifier(enabled);
      if (enabled) {
        return pushNotifierSetter(setShowNotifier);
      }
    }, [enabled]);

    if (!showNotifier) {
      return <React.Fragment key="base">{children}</React.Fragment>;
    }

    return (
      <div style={{ height: "100%" }}>
        <div
          key="pre"
          style={{
            position: "relative",
            height: 0,
            zIndex: "var(--z-index-notifier)",
            overflow: "visible",
          }}
          ref={(element) => setRef("pre", element)}
        >
          <InfoPopover
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%,-50%)",
              transitionDuration: "350ms",
              overflow: "visible",
            }}
            addSpaceBefore={false}
            addSpaceAfter={false}
            portal
          >
            <div
              style={{
                width: "22px",
                height: "22px",
                borderRadius: "50%",
                backgroundColor: "wheat",
                position: "relative",
                border: "1px solid rgba(0,0,0,.4)",
                boxShadow: "rgb(255 255 255 / 50%) 0px 0px 10px 5px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                {warning ? (
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color="orange"
                  />
                ) : error ? (
                  <FontAwesomeIcon icon={faExclamationTriangle} color="red" />
                ) : (
                  <Loader marginTop={0} size={16} borderWidth={2} />
                )}
              </span>
            </div>
            <div style={{ width: "250px", maxWidth: "50vw" }}>
              {warning ? (
                <span>
                  <div>
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      color="orange"
                    />{" "}
                    {t(
                      getWarningTranslationKey(
                        warning,
                        warningTranslationKey
                      ) ?? warningTranslationKey
                    )}
                  </div>
                  <div style={{ textAlign: "end" }}>
                    <button
                      style={{
                        backgroundColor: "lightblue",
                        border: 0,
                        padding: "2px 5px",
                        borderRadius: "6px",
                      }}
                      onClick={() => hideNotifiers()}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} />{" "}
                      {t("action.HIDE_ALL")}
                    </button>
                  </div>
                </span>
              ) : error ? (
                <span>
                  <div>
                    <FontAwesomeIcon icon={faExclamationTriangle} color="red" />{" "}
                    {getErrorTranslationKey(error, errorTranslationKey) ??
                      errorTranslationKey}
                  </div>
                  <div style={{ textAlign: "end" }}>
                    <button
                      style={{
                        backgroundColor: "lightblue",
                        border: 0,
                        padding: "2px 5px",
                        borderRadius: "6px",
                      }}
                      onClick={() => hideNotifiers()}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} />{" "}
                      {t("action.HIDE_ALL")}
                    </button>
                  </div>
                </span>
              ) : (
                <span>
                  <div>
                    {t(
                      getWarningTranslationKey(
                        loading,
                        loadingTranslationKey
                      ) ?? loadingTranslationKey
                    )}
                  </div>
                  <div style={{ textAlign: "end" }}>
                    <button
                      style={{
                        backgroundColor: "lightblue",
                        border: 0,
                        padding: "2px 5px",
                        borderRadius: "6px",
                      }}
                      onClick={() => hideNotifiers()}
                    >
                      <FontAwesomeIcon icon={faEyeSlash} />{" "}
                      {t("action.HIDE_ALL")}
                    </button>
                  </div>
                </span>
              )}
            </div>
          </InfoPopover>
        </div>
        <React.Fragment key="base">{children}</React.Fragment>
        <div
          key="post"
          style={{ visibility: "hidden", height: 0 }}
          ref={(element) => setRef("post", element)}
        ></div>
      </div>
    );
  };

  Notifier.event = event;
  Notifier.initializer = initializer;
  Notifier.id = ++linkedNotiferSequenceNumber;

  Notifier.defaultNotifierVerticalPosition = defaultNotifierVerticalPosition;
  Notifier.defaultLoadingTranslationKey = defaultLoadingTranslationKey;
  Notifier.defaultErrorTranslationKey = defaultErrorTranslationKey;
  Notifier.defaultWarningTranslationKey = defaultWarningTranslationKey;

  Notifier.getLoadingTranslationKey = getLoadingTranslationKey;
  Notifier.getErrorTranslationKey = getErrorTranslationKey;
  Notifier.getWarningTranslationKey = getWarningTranslationKey;

  return Notifier;
};

const IdentityNotifier = ({ children }) => children;

const cachedMergedNotifiers = {};
export const useCofigurableMergedNotifier = (
  {
    defaultNotifierVerticalPosition,
    defaultLoadingTranslationKey,
    defaultErrorTranslationKey,
    defaultWarningTranslationKey,
    getLoadingTranslationKey,
    getErrorTranslationKey,
    getWarningTranslationKey,
  },
  ...Notifiers
) => {
  return useMemo(() => {
    Notifiers = uniqBy(Notifiers, "id");
    if (Notifiers.length === 0) {
      return IdentityNotifier;
    }
    if (Notifiers.length === 1) {
      return Notifiers[0];
    }
    const id = Notifiers.map(({ id }) => id)
      .sort()
      .join();
    if (cachedMergedNotifiers[id]) {
      return cachedMergedNotifiers[id];
    }

    const events = Notifiers.map(({ event }) => event);
    const statuses = Notifiers.map(({ initializer }) => initializer());
    let loadingCount = 0;
    let errorsCount = 0;
    let warningsCount = 0;

    statuses.forEach(({ loading, error, warning }, i) => {
      loadingCount += loading ? 1 : 0;
      errorsCount += error ? 1 : 0;
      warningsCount += warning ? 1 : 0;
    });

    const notifierEvent = new SingleEvent();

    let status;
    const setStatus = () => {
      let error;
      if (errorsCount) {
        if (errorsCount === 1) {
          error = statuses.find(({ error }) => error).error;
        } else {
          error = new Error(`Error loading`);
          error.errors = statuses.map(({ error }) => error).filter(Boolean);
        }
      }
      let warning;
      if (warningsCount) {
        if (warningsCount === 1) {
          warning = statuses.find(({ warning }) => warning).warning;
        } else {
          warning = {
            warnings: statuses.map(({ warning }) => warning).filter(Boolean),
          };
        }
      }
      status = { loading: loadingCount > 0, error, warning };
      return status;
    };
    setStatus();

    const Notifier = getLinkedNotifier({
      event: notifierEvent,
      initializer: () => status,
      defaultNotifierVerticalPosition,
      defaultLoadingTranslationKey: defaultLoadingTranslationKey ?? "LOADING",
      defaultErrorTranslationKey: defaultErrorTranslationKey ?? "ERROR",
      defaultWarningTranslationKey: defaultWarningTranslationKey ?? "WARNING",
      getLoadingTranslationKey:
        getLoadingTranslationKey ??
        (() => {
          const loadingTranslationsKeys = statuses
            .map(({ loading }, i) =>
              loading
                ? Notifiers[i].getLoadingTranslationKey(
                    loading,
                    Notifiers[i].defaultLoadingTranslationKey
                  ) ?? Notifiers[i].defaultLoadingTranslationKey
                : null
            )
            .filter(Boolean);
          if (loadingTranslationsKeys.length) {
            return loadingTranslationsKeys[0];
          }
          return null;
        }),
      getErrorTranslationKey:
        getErrorTranslationKey ??
        (() => {
          const errorsTranslationsKeys = statuses
            .map(({ error }, i) =>
              error
                ? Notifiers[i].getErrorTranslationKey(
                    error,
                    Notifiers[i].defaultErrorTranslationKey
                  ) ?? Notifiers[i].defaultErrorTranslationKey
                : null
            )
            .filter(Boolean);
          if (errorsTranslationsKeys.length) {
            return errorsTranslationsKeys[0];
          }
          return null;
        }),
      getWarningTranslationKey:
        getWarningTranslationKey ??
        (() => {
          const warningsTranslationsKeys = statuses
            .map(({ warning }, i) =>
              warning
                ? Notifiers[i].getWarningTranslationKey(
                    warning,
                    Notifiers[i].defaultWarningTranslationKey
                  ) ?? Notifiers[i].defaultWarningTranslationKey
                : null
            )
            .filter(Boolean);
          if (warningsTranslationsKeys.length) {
            return warningsTranslationsKeys[0];
          }
          return null;
        }),
    });

    events.map((event, i) => {
      event.addListener((status) => {
        const oldStatus = statuses[i];

        let change = 0;

        if (status.loading) {
          if (!oldStatus.loading) {
            loadingCount++;
            change++;
          }
        } else if (oldStatus.loading) {
          loadingCount--;
          change++;
        }

        if (status.error) {
          if (!oldStatus.error) {
            errorsCount++;
          }
        } else if (oldStatus.error) {
          errorsCount--;
        }
        change += oldStatus.error !== status.error ? 1 : 0;

        if (status.warning) {
          if (!oldStatus.warning) {
            warningsCount++;
          }
        } else if (oldStatus.warning) {
          warningsCount--;
        }
        change += oldStatus.warning !== status.warning ? 1 : 0;

        statuses[i] = status;

        if (change) {
          notifierEvent.triggerEvent(setStatus());
        }
      });
    });

    cachedMergedNotifiers[id] = Notifier;
    return Notifier;
  }, Notifiers);
};
export const useMergedNotifier = (...Notifiers) =>
  useCofigurableMergedNotifier({}, ...Notifiers);

export default getNotifier;

export const DefaultNotifier = getNotifier({});
