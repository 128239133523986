const { SingleEvent } = require("modules/core/helpers/events");

const _401Event = new SingleEvent();
const _403Event = new SingleEvent();

const on401 = _401Event.addListener;
const on403 = _403Event.addListener;

const trigger401 = _401Event.triggerEvent;
const trigger403 = _403Event.triggerEvent;

export { on401, on403, trigger401, trigger403 };
