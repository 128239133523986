import type { InputHTMLAttributes } from "react";
import classNames from "classnames";

import s from "./RadioInput.module.scss";

type RadioInputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  labelClassName?: string;
  isReversed?: boolean;
};

const RadioInput = ({
  className,
  isReversed = false,
  labelClassName,
  children,
  ...props
}: RadioInputProps) => {
  return (
    <label
      className={classNames(s.label, labelClassName, {
        [s.reversed]: isReversed,
      })}
    >
      <input
        className={classNames(s.input, className)}
        type="radio"
        {...props}
      />
      {children}
    </label>
  );
};

export default RadioInput;
export type { RadioInputProps };
