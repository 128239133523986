import withIconProps from "helpers/withIconProps";
const GanttIcon = withIconProps((props) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00001 0.75C1.41422 0.75 1.75001 1.08579 1.75001 1.5V15.75H19C19.4142 15.75 19.75 16.0858 19.75 16.5C19.75 16.9142 19.4142 17.25 19 17.25H1.00001C0.585797 17.25 0.25001 16.9142 0.25001 16.5V1.5C0.25001 1.08579 0.585797 0.75 1.00001 0.75Z"
        fill="white"
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5644 5.50612C19.8372 5.81785 19.8056 6.29167 19.4939 6.56443L13.4939 11.8144C13.2272 12.0478 12.8335 12.0626 12.55 11.85L7.03637 7.71477L1.49389 12.5644C1.18216 12.8372 0.708339 12.8056 0.435578 12.4939C0.162816 12.1822 0.194404 11.7083 0.506132 11.4356L6.50613 6.18557C6.77284 5.9522 7.1665 5.93736 7.45001 6.15L12.9637 10.2852L18.5061 5.43557C18.8179 5.16281 19.2917 5.19439 19.5644 5.50612Z"
        fill="white"
        stroke="currentColor"
      />
    </svg>
  );
});

export default GanttIcon;
