import React, { useMemo } from "react";
import flattenWithChildren from "helpers/flattenWithChildren";
import isRealNumber from "helpers/isRealNumber";
import useBuildingData from "modules/building/hooks/useBuildingData";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import useFullSelectedTimestamp from "modules/building/hooks/useFullSelectedTimestamp";
import ColorizeEntity from "../ColorizeEntity";
import { useMemoedCurrentLinkedData } from "modules/building/hooks/useLinkedData";

const Heatmap = ({
  entityType,
  mappingName = "default",
  telemetryName,
  colorTweener,
  opacity = 0.4,
  priority = 0,
}) => {
  const { buildingData } = useBuildingData();
  const selectedEntity = useSelectedEntity(buildingData);

  const heatmapEntities = useMemo(() => {
    return flattenWithChildren(selectedEntity).filter(
      ({ type }) => type === entityType
    );
  }, [selectedEntity]);

  const { telemetries } = useMemoedCurrentLinkedData(
    () =>
      heatmapEntities.map((entity) => ({
        entityId: entity.id,
        telemetries: [telemetryName],
      })),
    [heatmapEntities, telemetryName]
  );

  const { selectedTimestampIndex } = useFullSelectedTimestamp();
  const heatmapData = useMemo(() => {
    return heatmapEntities.map((entity) => {
      const value =
        telemetries[entity.id][telemetryName].data[selectedTimestampIndex]
          .value;
      return {
        entity,
        value,
        color: isRealNumber(value) ? colorTweener(value) : 0xcccccc,
      };
    });
  }, [heatmapEntities, telemetries, telemetryName, selectedTimestampIndex]);

  return heatmapData.map((zoneHeatmapData) => {
    return (
      <ColorizeEntity
        entityId={zoneHeatmapData.entity.id}
        mappingName={mappingName}
        color={zoneHeatmapData.color}
        opacity={opacity}
        priority={priority}
      />
    );
  });
};

export default Heatmap;
