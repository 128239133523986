import classNames from "classnames";

import s from "./WidgetGrid.module.scss";

import { type HTMLAttributes } from "react";

type WidgetGridProps = HTMLAttributes<HTMLDivElement> & {
  columnCount?: number;
};

const WidgetGrid = ({
  columnCount = 2,
  className,
  style,
  ...props
}: WidgetGridProps) => (
  <div
    className={classNames(s.container, className)}
    style={{ ...style, gridTemplateColumns: `repeat(${columnCount}, 1fr)` }}
    {...props}
  />
);

export default WidgetGrid;
export type { WidgetGridProps };
