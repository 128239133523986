import classNames from "classnames";
import Link, { type SearchParams } from "atomic-components/atoms/Link/Link";
import { type PageUrlEnum } from "router/enums";

import s from "./MainMenuSubItem.module.scss";

type MainMenuSubItemProps = {
  pageUrl?: PageUrlEnum;
  searchParams?: SearchParams;
  text: string;
  isActive?: boolean;
  disabled?: boolean;
};

const MainMenuSubItem = ({
  pageUrl,
  searchParams,
  text,
  isActive,
  disabled = false,
}: MainMenuSubItemProps) => {
  const subItem = (
    <div
      className={classNames(s.container, {
        [s.disabled]: disabled,
      })}
      aria-disabled={disabled}
    >
      <h4 className={s.label}>{text}</h4>
    </div>
  );

  if (pageUrl) {
    return (
      <Link
        pageUrl={pageUrl}
        searchParams={searchParams}
        disabled={disabled}
        className={() => {
          return classNames(s.link, {
            [s.linkActive]: isActive,
          });
        }}
      >
        {subItem}
      </Link>
    );
  }
  return subItem;
};

export default MainMenuSubItem;
