import React from "react";
import Loader from "../../../../atomic-components/atoms/Loader";
import "./Loader.css";

const ReactiveViewerLoader = () => (
  <div className="reactive-viewer__loader">
    <Loader />
  </div>
);

export default ReactiveViewerLoader;
