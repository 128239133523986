import classNames from "classnames";
import type { SVGAttributes } from "react";

type SpaceIconProps = SVGAttributes<SVGElement> & {
  icon?: boolean;
  iconScale?: number;
  color?: string;
};

const SpaceIcon = ({
  icon = true,
  iconScale = 1,
  color = "#8393BA",
  className,
  ...props
}: SpaceIconProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, { "para-icon": icon })}
      style={{
        transform: `scale(${iconScale})`,
      }}
      {...props}
    >
      <rect width="32" height="32" rx="6" fill={color} />
      <circle cx="9.87097" cy="10.871" r="3.87097" fill="white" />
      <rect
        x="16.9677"
        y="9.58069"
        width="9.03226"
        height="2.58065"
        fill="white"
      />
      <rect
        x="16.9677"
        y="21.1936"
        width="9.03226"
        height="2.58065"
        fill="white"
      />
      <path
        d="M11.9522 19.8765L11.9521 19.8764L11.9457 19.8828L9.68482 22.1444L9.33156 22.4978L9.68471 22.8513L11.9357 25.1045C11.9452 25.1163 11.9504 25.1311 11.9501 25.1463C11.9498 25.163 11.943 25.179 11.9312 25.1908C11.9194 25.2026 11.9035 25.2094 11.8868 25.2097C11.8716 25.2099 11.8568 25.2048 11.8451 25.1953L9.59275 22.9422L9.23931 22.5887L8.8857 22.9421L6.63176 25.1945C6.62001 25.204 6.60523 25.2092 6.59 25.2089C6.57329 25.2086 6.55735 25.2018 6.54554 25.19C6.53373 25.1782 6.52696 25.1623 6.52667 25.1456C6.5264 25.1304 6.53152 25.1156 6.54104 25.1038L8.79339 22.8515L9.14676 22.4981L8.79356 22.1446L6.53339 19.8821L6.52707 19.8758L6.52052 19.8697C6.51419 19.8638 6.50911 19.8567 6.50558 19.8488C6.50206 19.8409 6.50016 19.8323 6.50001 19.8237C6.49986 19.815 6.50145 19.8064 6.50469 19.7984C6.50794 19.7904 6.51276 19.7831 6.51889 19.7769C6.52501 19.7708 6.5323 19.766 6.54033 19.7627C6.54836 19.7595 6.55696 19.7579 6.56562 19.7581C6.57427 19.7582 6.58281 19.7601 6.59072 19.7636C6.59863 19.7672 6.60575 19.7722 6.61165 19.7786L6.61767 19.785L6.6239 19.7913L8.88558 22.0529L9.23908 22.4064L9.59263 22.053L11.8551 19.7913L11.8551 19.7914L11.861 19.7852C11.867 19.7791 11.8741 19.7742 11.882 19.7708C11.8898 19.7674 11.8983 19.7657 11.9069 19.7656C11.9154 19.7655 11.9239 19.7672 11.9318 19.7704L12.1204 19.3102L11.9318 19.7704C11.9398 19.7736 11.947 19.7784 11.953 19.7845C11.9591 19.7905 11.9638 19.7977 11.9671 19.8057C11.9703 19.8136 11.972 19.8221 11.9719 19.8307C11.9718 19.8392 11.97 19.8477 11.9666 19.8555L12.4259 20.0531L11.9666 19.8555C11.9632 19.8634 11.9583 19.8705 11.9522 19.8765Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};

export default SpaceIcon;
