import {
  ADD_OBJECTS,
  SET_CAMERA_CONTROLS,
  SET_DIMENTIONS,
  SET_INITIALIZED,
} from "./viewer.context-actions";

const viewerContextReducer = (state, action) => {
  switch (action.type) {
    case SET_INITIALIZED: {
      return {
        ...state,
        initialized: true,
        scene: action.scene,
        renderer: action.renderer,
        camera: action.camera,
        canvas: action.canvas,
        canvas2D: action.canvas2D,
      };
    }
    case ADD_OBJECTS: {
      return {
        ...state,
        objects: [...(state.objects ?? []), ...action.objects],
      };
    }
    case SET_DIMENTIONS: {
      return {
        ...state,
        width: action.width,
        height: action.height,
      };
    }
    case SET_CAMERA_CONTROLS: {
      return {
        ...state,
        cameraControls: action.cameraControls,
      };
    }

    default:
      return state;
  }
};

export default viewerContextReducer;
