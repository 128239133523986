import { useMemo, useCallback } from "react";
import { isPlainObject, isString } from "lodash";

import hardcoded from "../../../helpers/hardcoded";
import {
  getDayFromTo,
  getMonthFromTo,
  getWeekFromTo,
  normalizeFromTo,
} from "../helpers/intervals";
import useBuildingData from "./useBuildingData";

const DEFAULT_DATE_FILTER = "week";

/**
 * @param {RawDateFilter} rawDateFilter
 * @param {string} timezone
 * @returns DateRange
 */
const getDateFilter = (
  rawDateFilter = DEFAULT_DATE_FILTER,
  timezone = hardcoded("Africa/Cairo")
) => {
  const todayFromTo = normalizeFromTo(getDayFromTo({ timezone }));
  switch (rawDateFilter) {
    case "today":
      return todayFromTo;
    case "week": {
      return normalizeFromTo(
        getWeekFromTo({ reference: todayFromTo.fromTs - 1, timezone })
      );
    }
    case "month": {
      return normalizeFromTo(
        getMonthFromTo({ reference: todayFromTo.fromTs - 1, timezone })
      );
    }
    default: {
      let [from, to] = rawDateFilter;
      return normalizeFromTo({ from, to, timezone });
    }
  }
};

/**
 * @typedef {import('luxon').DateTime} DateTime
 * @typedef {[DateTime,DateTime]} DateRange
 * @typedef {[string,string] | [number, number]} RawDateRange
 * @typedef {"today" | "week" | "month" | RawDateRange} RawDateFilter
 * @typedef {string|number|Date|DateTime} ParseableDate
 * @typedef {"today" | "week" | "month" | [ParseableDate,ParseableDate] | {from:ParseableDate,to:ParseableDate}} ToSetDateFilter
 */
/**
 * @callback SetRawDateFilter
 * @param {ToSetDateFilter | ParseableDate} dateFilterOrFrom
 * @param {ParseableDate} [to]
 */

/**
 * @callback OnDateFilterChange
 * @param {RawDateFilter} dateFilter
 */

/**
 * @param {RawDateFilter} rawDateFilter
 * @param {OnDateFilterChange} onDateFilterChange
 * @returns {{from: DateTime, to: DateTime, fromTs: number, toTs: number, setDateFilter: SetRawDateFilter, rawDateFilter: RawDateFilter, days: number, timezone: string, startOfWeek: number}}
 */
const useCustomDateFilterState = (rawDateFilter, onDateFilterChange) => {
  const { buildingData } = useBuildingData();
  const { timezone, startOfWeek } = buildingData;

  const setDateFilter = useCallback(
    (...args) => {
      let from, to;
      if (args.length === 1) {
        let dateFilter = args[0];
        if (Array.isArray(dateFilter)) {
          from = dateFilter[0];
          to = dateFilter[1];
        } else if (isPlainObject(dateFilter)) {
          from = dateFilter.from;
          to = dateFilter.to;
        } else if (isString(dateFilter)) {
          onDateFilterChange(dateFilter);
        } else {
          throw new Error(
            `Wrong argument sent to setDateFilter ("${typeof dateFilter}")`
          );
        }
      } else if (args.length === 2) {
        from = args[0];
        to = args[1];
      } else {
        throw new Error(
          `Wrong arguments number sent to setDateFilter (${args.length}) should be 1 or 2 arguments`
        );
      }

      if (from && to) {
        const normalizedTimeRange = normalizeFromTo({ from, to, timezone });
        from = normalizedTimeRange.from;
        to = normalizedTimeRange.to;

        onDateFilterChange([
          from.toFormat("yyyy-MM-dd.HH.mm.ss"),
          to.toFormat("yyyy-MM-dd.HH.mm.ss"),
        ]);
      }
    },
    [onDateFilterChange, timezone]
  );

  return useMemo(() => {
    const normalizedTimeRange = getDateFilter(rawDateFilter, timezone);
    return {
      ...normalizedTimeRange,
      duration: normalizedTimeRange.toTs - normalizedTimeRange.fromTs,
      setDateFilter,
      rawDateFilter,
      timezone,
      startOfWeek,
    };
  }, [rawDateFilter, timezone, setDateFilter, startOfWeek]);
};

export default useCustomDateFilterState;
