import React, { useMemo } from "react";
import Victor from "victor";

import { sum, times } from "lodash";
import { v4 as uuidV4 } from "uuid";
import isRealNumber from "helpers/isRealNumber";

const getRandom = () => {
  const count = Math.ceil(Math.random() * Math.random() * 7) + 3;
  const rands = times(count, () => Math.random());
  const total = sum(rands);
  return rands.map((r) => (100 * r) / total);
};

const useBasePoints = (percentages, initAngle, autoCenterAngle) => {
  percentages = percentages.map((percentage) =>
    isRealNumber(percentage) ? percentage : 0
  );
  return useMemo(() => {
    if (initAngle === "auto") {
      // center first sector around autoCenterAngle
      initAngle = autoCenterAngle - (percentages[0] / 2) * percentToDegrees;
    }
    initAngle = (initAngle + 360) % 360;
    const angles = [];
    for (let i = 0; i < percentages.length; i++) {
      const percent = percentages[i];
      const previousEndAngle = angles[i - 1]?.end ?? initAngle;
      const angle = percent === 100 ? 360 : (percent * percentToDegrees) % 360;
      angles.push({
        start: previousEndAngle,
        angle,
        end: (previousEndAngle + angle) % 360,
      });
    }
    return angles.map(({ start: startAngle, angle, end: endAngle }) => {
      const x1 = Math.cos(degreesToRadian * startAngle);
      const y1 = Math.sin(degreesToRadian * startAngle);
      const x2 = Math.cos(degreesToRadian * endAngle);
      const y2 = Math.sin(degreesToRadian * endAngle);
      const centerAngle = startAngle + angle / 2;
      return {
        start: new Victor(x1, y1),
        end: new Victor(x2, y2),
        angle,
        startAngle,
        endAngle,
        centerAngle,
      };
    });
  }, [percentages, initAngle, autoCenterAngle]);
};

const degreesToRadian = Math.PI / 180;
const percentToDegrees = 3.6;
export const PieChartText = ({
  percentages = getRandom(),
  size = 200,
  innerSize = size * 0.4,
  xCenter = size / 2,
  yCenter = size / 2,
  sidePadding = 1,
  initAngle = "auto",
  autoCenterAngle = -90,
  textRadiusPercent = 50,
  dominantBaseline = "middle",
  textAnchor = "middle",
  lengthAdjust = "spacingAndGlyphs",
  minTextLength,
  getTextProps,
  children,
}) => {
  const basePoints = useBasePoints(percentages, initAngle, autoCenterAngle);

  const textRadius =
    (innerSize * (100 - textRadiusPercent) + size * textRadiusPercent) / 200;
  const paths = useMemo(() => {
    return basePoints
      .map(({ start, end, ...rest }) => ({
        p1: new Victor(
          xCenter + start.x * textRadius,
          yCenter + start.y * textRadius
        ),
        p2: new Victor(
          xCenter + end.x * textRadius,
          yCenter + end.y * textRadius
        ),
        ...rest,
      }))
      .map(({ p1, p2, angle, ...rest }) => {
        const circleCenter = new Victor(xCenter, yCenter);
        const startRadialVector = p1.clone().subtract(circleCenter);
        const endRadialVector = p2.clone().subtract(circleCenter);

        const startTangentVector = new Victor(
          startRadialVector.y,
          -startRadialVector.x
        )
          .normalize()
          .multiplyScalar(sidePadding);
        const endTangentVector = new Victor(
          endRadialVector.y,
          -endRadialVector.x
        )
          .normalize()
          .multiplyScalar(sidePadding);
        return {
          p1: p1.subtract(startTangentVector).toObject(),
          p2: p2.add(endTangentVector).toObject(),
          textLength: degreesToRadian * angle * textRadius - 2 * sidePadding,
          angle,
          ...rest,
        };
      })
      .map(({ p1, p2, textLength, centerAngle, angle, ...rest }) => {
        return {
          d:
            centerAngle > 0 && centerAngle < 180
              ? `M ${p2.x} ${p2.y} A ${textRadius} ${textRadius} 0 ${
                  angle > 180 ? 1 : 0
                } 0 ${p1.x} ${p1.y}`
              : `M ${p1.x} ${p1.y} A ${textRadius} ${textRadius} 0 ${
                  angle > 180 ? 1 : 0
                } 1 ${p2.x} ${p2.y}`,
          textLength,
          centerAngle,
          angle,
          ...rest,
        };
      });
  }, [basePoints, textRadius, size, innerSize, sidePadding]);

  const ids = useMemo(
    () => times(percentages.length, () => uuidV4()),
    [percentages.length]
  );

  return (
    <>
      <defs>
        {paths.map(({ d }, i) => (
          <path key={i} id={ids[i]} strokeWidth={0} d={d} />
        ))}
      </defs>
      {paths.map((path, i) => {
        const textProps =
          getTextProps?.({
            ...basePoints[i],
            index: i,
            angle: path.angle,
            maxLength: path.textLength,
            textRadius,
            textRadiusPercent,
            xCenter,
            yCenter,
            size,
            innerSize,
            radius: size / 2,
            innerRadius: innerSize / 2,
          }) ?? {};
        const {
          show = minTextLength == null
            ? true
            : path.textLength >= minTextLength,
          key = i,
          text = children?.[i] ?? i,
          content = text,
          children: contentNode = content,
          textPath,
          textPathProps = textPath,
          ...props
        } = textProps;
        return (
          <text
            key={key}
            dominantBaseline={dominantBaseline}
            textAnchor={textAnchor}
            lengthAdjust={lengthAdjust}
            {...props}
          >
            <textPath href={`#${ids[i]}`} startOffset="50%" {...textPathProps}>
              {contentNode}
            </textPath>
          </text>
        );
      })}
    </>
  );
};

/**
 * @typedef GetSectorPropsParams
 * @type {object}
 * @property {number} angle
 * @property {number} centerAngle
 * @property {number} count
 * @property {{ x: number, y: number }} end
 * @property {number} endAngle
 * @property {number} index
 * @property {number} innerRadius
 * @property {number} innerSize
 * @property {number} percent
 * @property {number} radius
 * @property {number} size
 * @property {{ x: number, y: number }} start
 * @property {number} startAngle
 * @property {number} xCenter
 * @property {number} yCenter
 */

/**
 * @callback GetSectorProps
 * @param {GetSectorPropsParams} params
 */

/**
 * @param {{percentages?: number[], size?: number, innerSize?: number, xCenter?: number, yCenter?: number, initAngle?: number | "auto", autoCenterAngle?: number, getSectorProps: GetSectorProps }} param0
 * @returns {import("react").ReactNode}
 */
const PieChart = ({
  percentages = getRandom(),
  size = 200,
  innerSize = size * 0.4,
  xCenter = size / 2,
  yCenter = size / 2,
  initAngle = "auto",
  autoCenterAngle = -90,
  getSectorProps,
}) => {
  const basePoints = useBasePoints(percentages, initAngle, autoCenterAngle);

  const paths = useMemo(
    () =>
      basePoints
        .map(({ start, end, angle }) => [
          new Victor(
            xCenter + (start.x * innerSize) / 2,
            yCenter + (start.y * innerSize) / 2
          ),
          new Victor(
            xCenter + (start.x * size) / 2,
            yCenter + (start.y * size) / 2
          ),
          new Victor(
            xCenter + (end.x * size) / 2,
            yCenter + (end.y * size) / 2
          ),
          new Victor(
            xCenter + (end.x * innerSize) / 2,
            yCenter + (end.y * innerSize) / 2
          ),
          angle,
        ])
        .map(([p1, p2, p3, p4, angle]) => {
          const startRadialVector = p2.clone().subtract(p1);
          const endRadialVector = p3.clone().subtract(p4);

          const startTangentVector = new Victor(
            startRadialVector.y,
            -startRadialVector.x
          )
            .normalize()
            .multiplyScalar(0.5);
          const endTangentVector = new Victor(
            endRadialVector.y,
            -endRadialVector.x
          )
            .normalize()
            .multiplyScalar(0.5);
          return [
            p1.subtract(startTangentVector).toObject(),
            p2.subtract(startTangentVector).toObject(),
            p3.add(endTangentVector).toObject(),
            p4.add(endTangentVector).toObject(),
            angle,
          ];
        })
        .map(([p1, p2, p3, p4, angle]) => {
          return `M ${p4.x} ${p4.y} A ${innerSize / 2} ${innerSize / 2} 0 ${
            angle > 180 ? 1 : 0
          } 0 ${p1.x} ${p1.y} L ${p2.x} ${p2.y} A ${size / 2} ${size / 2} 0 ${
            angle > 180 ? 1 : 0
          } 1 ${p3.x} ${p3.y} Z`;
        }),
    [basePoints, size, innerSize, xCenter, yCenter]
  );

  return paths.map((d, i) =>
    isRealNumber(percentages[i]) ? (
      <path
        key={i}
        strokeWidth={0}
        {...getSectorProps?.({
          ...basePoints[i],
          index: i,
          percent: percentages[i],
          count: percentages.length,
          xCenter,
          yCenter,
          size,
          innerSize,
          radius: size / 2,
          innerRadius: innerSize / 2,
        })}
        d={d}
      />
    ) : null
  );
};

export default PieChart;
