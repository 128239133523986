import { AccordionGroupElement } from "atomic-components/atoms/Accordion";
import { useTranslate } from "modules/language";
import { useState } from "react";
import classNames from "classnames";
import { Minus, Plus } from "svg-icons";

import s from "./SingleCertificate.module.scss";

type SingleCertificateProps = {
  certificate: {
    label: string;
    CertificateComponent: React.ComponentType;
    logoSrc: string;
    isInitiallyExpanded: boolean;
  };
};

const SingleCertificate = ({
  certificate: { label, CertificateComponent, logoSrc, isInitiallyExpanded },
}: SingleCertificateProps) => {
  const t = useTranslate();

  const [isExpanded, setIsExpanded] = useState<boolean>(isInitiallyExpanded);

  return (
    <AccordionGroupElement
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      renderAccordionButton={({ isExpanded }: { isExpanded: boolean }) => (
        <div
          key={label}
          className={classNames(s.row, {
            [s.rowExpanded]: isExpanded,
          })}
        >
          <div className={s.certificateLabelContainer}>
            <img
              width={21}
              height={22}
              className={s.certificateLogo}
              src={logoSrc}
              alt="Certificate Logo"
            />
            <h4 className={s.certificateLabel}>{t(label)}</h4>
          </div>
          {isExpanded ? (
            <Minus color="var(--text-color-inverse)" iconScale={2} />
          ) : (
            <Plus color="var(--text-color-inverse)" iconScale={2} />
          )}
        </div>
      )}
    >
      <div className={s.content}>
        <CertificateComponent />
      </div>
    </AccordionGroupElement>
  );
};

export default SingleCertificate;
