import withIconProps from "helpers/withIconProps";

type Props = {
  className?: string;
  fill?: string;
};

const Target = withIconProps(({ fill = "white" }: Props) => {
  return (
    <svg
      viewBox="158.675 165.438 13 13"
      width="13"
      height="13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 165.175 166.62 C 162.238 166.62 159.857 169.001 159.857 171.938 C 159.857 174.876 162.238 177.257 165.175 177.257 C 168.112 177.257 170.493 174.876 170.493 171.938 C 170.493 169.001 168.112 166.62 165.175 166.62 Z M 158.675 171.938 C 158.675 168.348 161.585 165.438 165.175 165.438 C 168.765 165.438 171.675 168.348 171.675 171.938 C 171.675 175.528 168.765 178.438 165.175 178.438 C 161.585 178.438 158.675 175.528 158.675 171.938 Z M 165.175 168.984 C 163.543 168.984 162.22 170.307 162.22 171.938 C 162.22 173.57 163.543 174.893 165.175 174.893 C 166.807 174.893 168.13 173.57 168.13 171.938 C 168.13 170.307 166.807 168.984 165.175 168.984 Z M 161.039 171.938 C 161.039 169.654 162.891 167.802 165.175 167.802 C 167.46 167.802 169.311 169.654 169.311 171.938 C 169.311 174.223 167.46 176.075 165.175 176.075 C 162.891 176.075 161.039 174.223 161.039 171.938 Z M 165.175 171.347 C 164.849 171.347 164.584 171.612 164.584 171.938 C 164.584 172.265 164.849 172.529 165.175 172.529 C 165.501 172.529 165.766 172.265 165.766 171.938 C 165.766 171.612 165.501 171.347 165.175 171.347 Z M 163.402 171.938 C 163.402 170.959 164.196 170.166 165.175 170.166 C 166.154 170.166 166.948 170.959 166.948 171.938 C 166.948 172.917 166.154 173.711 165.175 173.711 C 164.196 173.711 163.402 172.917 163.402 171.938 Z"
        fill={fill}
      />
    </svg>
  );
});

export default Target;
