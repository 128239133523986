import withIconProps from "helpers/withIconProps";

const DragIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill="none"
      {...props}
    >
      <circle cx="1" cy="1.05859" r="1" fill="currentColor" />
      <circle cx="1" cy="9.05859" r="1" fill="currentColor" />
      <circle cx="1" cy="5.05859" r="1" fill="currentColor" />
      <circle cx="5" cy="1.05859" r="1" fill="currentColor" />
      <circle cx="5" cy="9.05859" r="1" fill="currentColor" />
      <circle cx="5" cy="5.05859" r="1" fill="currentColor" />
    </svg>
  );
});

export default DragIcon;
