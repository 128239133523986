export const SET_INITIALIZED = "viewer-context.SET_INITIALIZED";
export const setInitialized = (scene, renderer, camera, canvas, canvas2D) => ({
  type: SET_INITIALIZED,
  scene,
  renderer,
  camera,
  canvas,
  canvas2D,
});

export const SET_DIMENTIONS = "viewer-context.SET_DIMENTIONS";
export const setDimentions = (width, height) => ({
  type: SET_DIMENTIONS,
  width,
  height,
});

export const ADD_OBJECTS = "viewer-context.ADD_OBJECTS";
export const addObjects = (objects) => ({
  type: ADD_OBJECTS,
  objects,
});

export const SET_CAMERA_CONTROLS = "viewer-context.SET_CAMERA_CONTROLS";
export const setCameraControls = (cameraControls) => ({
  type: SET_CAMERA_CONTROLS,
  cameraControls,
});
