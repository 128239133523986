import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { setCameraControls } from "../../state/viewer.context-actions";
import { useViewerDispatcher, useViewerState } from "../Provider/Provider";
import CameraController from "./classes/CameraController";

const CameraControl = forwardRef(
  (
    {
      initCenter = {
        x: 630,
        y: 12.25,
        z: 388,
      },
      initPosition = {
        x: 668,
        y: 44,
        z: 416,
      },
      onCameraMoveStart,
      onCameraMoveEnd,
      onCameraMove,
      onInit,
      rotationSpeed = 1,
      zoomSpeed = 1,
      tweenSpeed = 1,
      enabled = true,
    },
    ref
  ) => {
    const { Vector3, Quaternion } = window.THREE;
    const { initialized, scene, camera, canvas } = useViewerState();
    const dispatchViewerAction = useViewerDispatcher();

    const mutableRef = useRef({});
    mutableRef.current.camera = camera;
    mutableRef.current.initCenter =
      mutableRef.current.initCenter ??
      new Vector3(initCenter.x, initCenter.y, initCenter.z);
    mutableRef.current.initPosition =
      mutableRef.current.initPosition ??
      new Vector3(initPosition.x, initPosition.y, initPosition.z);

    const mutableState = {
      onCameraMoveStart,
      onCameraMoveEnd,
      onCameraMove,
      onInit,
      rotationSpeed,
      zoomSpeed,
      tweenSpeed,
    };
    Object.assign(mutableRef.current, mutableState);
    if (mutableRef.current.cameraController) {
      mutableRef.current.cameraController.reHydrate(mutableState);
    }

    const imperativeAPI = useMemo(() => {
      const setViewpoint = async ({
        quaternion,
        position,
        center,
        animate,
        animationSteps,
        signal,
      }) => {
        /** @type {{cameraController:CameraController}} */
        const { cameraController } = mutableRef.current;
        if (cameraController) {
          const { Quaternion, Vector3 } = window.THREE;
          return cameraController.moveCamera({
            quaternion: new Quaternion(...quaternion),
            position: new Vector3(...position),
            center: center ? new Vector3(...center) : null,
            animate,
            animationSteps,
            signal,
          });
        }
      };
      const getViewpoint = () => {
        /** @type {import('three').PerspectiveCamera} */
        const camera = mutableRef.current.camera;
        const cameraController = mutableRef.current.cameraController;
        const cameraRotations = camera.getWorldQuaternion(new Quaternion());
        const cameraPosition = camera.getWorldPosition(new Vector3());
        return {
          quaternion: cameraRotations.toArray(),
          position: cameraPosition.toArray(),
          center: cameraController.center?.toArray(),
        };
      };
      const disable = () => {
        const cameraController = mutableRef.current.cameraController;
        cameraController.unbind();
      };
      const enable = () => {
        const cameraController = mutableRef.current.cameraController;
        cameraController.bind();
      };
      return {
        change: setViewpoint,
        setViewpoint,
        getViewpoint,
        disable,
        enable,
      };
    }, []);
    useImperativeHandle(ref, () => imperativeAPI, [imperativeAPI]);

    useEffect(() => {
      if (!initialized || !enabled) {
        return;
      }

      const {
        initCenter,
        initPosition,
        onCameraMoveStart,
        onCameraMoveEnd,
        onCameraMove,
        onInit,
        rotationSpeed,
        zoomSpeed,
        tweenSpeed,
        cameraController: oldCameraController,
      } = mutableRef.current;

      const center = oldCameraController?.getCenter() ?? initCenter;

      const cameraController = new CameraController({
        scene,
        camera,
        canvas: canvas.parentElement,
        center,
        onCameraMoveStart,
        onCameraMoveEnd,
        onCameraMove,
        rotationSpeed,
        zoomSpeed,
        tweenSpeed,
      });
      mutableRef.current.cameraController = cameraController;

      cameraController.bind();

      dispatchViewerAction(setCameraControls(cameraController));

      if (!oldCameraController) {
        camera.position.set(initPosition.x, initPosition.y, initPosition.z);
        camera.lookAt(initCenter);
        onInit && onInit({ cameraController: imperativeAPI });
      }

      return () => {
        cameraController.unbind();
      };
    }, [initialized, scene, camera, canvas, enabled]);

    return null;
  }
);

export default CameraControl;
