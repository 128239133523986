import isRealNumber from "helpers/isRealNumber";
import type {
  EditableParamsStoreParamState,
  EditableParamsStoreState,
} from "./types";
import type { ParamName } from "./definitions";

const validateParamValue = (
  paramState: EditableParamsStoreParamState
): paramState is Omit<EditableParamsStoreParamState, "value"> & {
  value: number;
} => {
  return !paramState.error && isRealNumber(paramState.value);
};

const validateParamValues = <PARAM_NAME extends ParamName>(
  state: EditableParamsStoreState,
  paramNames: PARAM_NAME[]
): state is EditableParamsStoreState & {
  [name in PARAM_NAME]: Omit<EditableParamsStoreParamState, "value"> & {
    value: number;
  };
} => {
  return paramNames.every((paramName) => validateParamValue(state[paramName]));
};

export { validateParamValue, validateParamValues };
