import withIconProps from "helpers/withIconProps";

type Props = {
  fill?: string;
  stroke?: string;
};

const Comment = withIconProps(
  ({ fill = "transparent", stroke = "var(--color-primary)" }: Props) => {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 13.0015L2.26667 9.20147C1.66475 7.99799 1.51075 6.61959 1.8322 5.31294C2.15366 4.0063 2.9296 2.85668 4.02117 2.06983C5.11274 1.28297 6.44869 0.910235 7.78995 1.01832C9.13121 1.1264 10.3902 1.70825 11.3417 2.65974C12.2932 3.61122 12.8751 4.87026 12.9832 6.21152C13.0912 7.55278 12.7185 8.88873 11.9316 9.9803C11.1448 11.0719 9.99517 11.8478 8.68853 12.1693C7.38188 12.4907 6.00348 12.3367 4.8 11.7348L1 13.0015Z"
          fill={fill}
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
);

export default Comment;
