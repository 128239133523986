import React, { useRef, useState } from "react";
import { useOnPostrender, useViewerMutableState } from "../Provider";
import isRealNumber from "helpers/isRealNumber";
import { differenceBy, isArray, keyBy, sumBy } from "lodash";
import ElementWithOnResize from "modules/layout/components/ElementWithOnResize/ElementWithOnResize";
import useMultidimentionalRef from "hooks/useMultidimentionalRef";
import { get2IntervalsIntersection } from "helpers/computeIntervalsDuration";
import classNames from "classnames";

import s from "./PointsAnnotations.module.scss";
import { useTranslate } from "modules/language";

const getScreenCoordinatesOf3DPoint = (
  pointCoordinates,
  camera,
  width,
  height
) => {
  const point = new window.THREE.Vector3(...pointCoordinates);
  point.project(camera);
  if (point.z > 1) {
    return null;
  }
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  const x = point.x * halfWidth + halfWidth;
  if (x < 0 || x > width) {
    return null;
  }
  const y = -(point.y * halfHeight) + halfHeight;
  if (y < 0 || y > height) {
    return null;
  }
  return { x, y };
};

const Debouncer = function (mutable, setGroups) {
  const self = this;
  let timer,
    positions = {},
    boxes = [],
    intersectionsGroups = [];

  const draw = () => {
    const { refs } = mutable;
    boxes.forEach((box) => {
      const { x0, y0, element, intersecting } = box;
      if (intersecting) {
        element.style.visibility = `hidden`;
      } else {
        element.style.visibility = `visible`;
        element.style.transform = `translate(${x0}px,${y0}px)`;
      }
    });
    intersectionsGroups.forEach(({ key, x, y }) => {
      const element = refs[key]?.group;
      if (!element) {
        return;
      }
      element.style.visibility = `visible`;
      element.style.transform = `translate(${x}px,${y}px)`;
    });
  };
  self.draw = draw;

  self.clear = () => {
    boxes?.forEach((box) => {
      const { element } = box;
      element.style.left = ``;
      element.style.top = ``;
      element.style.visibility = `hidden`;
    });
  };

  const refresh = () => {
    const { viewerState, annotations } = mutable;
    const { camera, width, height } = viewerState;

    if (!isRealNumber(width) || !isRealNumber(height)) {
      return;
    }

    const newPositions = {};
    let haveChanged = false;
    annotations.forEach((annotation) => {
      const key = annotation?.key;
      if (!key) {
        return;
      }
      const { pointCoordinates } = annotation;
      const position = positions[key];
      const newPosition = pointCoordinates
        ? getScreenCoordinatesOf3DPoint(pointCoordinates, camera, width, height)
        : null;
      if (newPosition) {
        newPosition.x = Math.round(newPosition.x);
        newPosition.y = Math.round(newPosition.y);
      }
      if (
        ((!position || !newPosition) && position != newPosition) ||
        (position &&
          newPosition &&
          Math.abs(newPosition.x + newPosition.y - position.x - position.y) > 1)
      ) {
        haveChanged = true;
        newPositions[key] = newPosition;
      } else {
        newPositions[key] = position;
      }
    });

    const didDelete = Boolean(
      Object.keys(positions).find((key) => positions[key] && !newPositions[key])
    );

    haveChanged = haveChanged || didDelete;

    if (!haveChanged) {
      return false;
    }

    positions = newPositions;
    return true;
  };
  self.refresh = refresh;

  const reflow = () => {
    const { refs, annotations, viewerState } = mutable;
    const { width, height } = viewerState;

    boxes = annotations
      .map((annotation) => {
        const key = annotation?.key;
        if (!key) {
          return;
        }
        const element = refs[key]?.element;
        if (!element) {
          return;
        }
        const { width: elementWidth, height: elementHeight } =
          refs[key]?.size ?? {};
        const position = positions?.[key];
        if (!position || !elementWidth || !elementHeight) {
          element.style.visibility = "hidden";
          return;
        }
        let { x: xCenter, y: yCenter } = position;

        const x0 = Math.max(
          0,
          Math.min(xCenter - elementWidth / 2, width - elementWidth)
        );
        const y0 = Math.max(
          0,
          Math.min(yCenter - elementHeight / 2, height - elementHeight)
        );

        return {
          key,
          x: xCenter,
          y: yCenter,
          x0: x0,
          y0: y0,
          x1: x0 + elementWidth,
          y1: y0 + elementHeight,
          element,
          w: elementWidth,
          h: elementHeight,
          annotation,
        };
      })
      .filter(Boolean);

    const boxesIx = keyBy(boxes, "key");

    const getBoxIntersectionsRaw = (box1, box2) => {
      const intersectionX = get2IntervalsIntersection(
        [box1.x0, box1.x1],
        [box2.x0, box2.x1]
      );
      if (!intersectionX) {
        return null;
      }
      const intersectionY = get2IntervalsIntersection(
        [box1.y0, box1.y1],
        [box2.y0, box2.y1]
      );
      if (!intersectionY) {
        return null;
      }
      return [intersectionX, intersectionY];
    };

    const intersectingBoxesIx = {};
    for (let i = 0; i < boxes.length - 1; i++) {
      const box1 = boxes[i];
      for (let j = i + 1; j < boxes.length; j++) {
        const box2 = boxes[j];
        let intersection = getBoxIntersectionsRaw(box1, box2);
        if (intersection) {
          const box1Intersections =
            intersectingBoxesIx[box1.key] ??
            (intersectingBoxesIx[box1.key] = {});
          const box2Intersections =
            intersectingBoxesIx[box2.key] ??
            (intersectingBoxesIx[box2.key] = {});
          box1Intersections[box1.key] = true;
          box2Intersections[box1.key] = true;
          box1Intersections[box2.key] = true;
          box2Intersections[box2.key] = true;
        }
      }
    }

    while (true) {
      let haveChanged = false;
      for (let i = 0; i < boxes.length; i++) {
        const box = boxes[i];
        const intersections = intersectingBoxesIx[box.key];
        if (!intersections) {
          continue;
        }
        const intersectionsKeys = Object.keys(intersections);
        Object.assign(
          intersections,
          ...intersectionsKeys.map((key) => intersectingBoxesIx[key])
        );
        if (Object.keys(intersections).length !== intersectionsKeys.length) {
          haveChanged = true;
        }
      }
      if (!haveChanged) {
        break;
      }
    }
    const intersectingGroupsIx = Object.keys(intersectingBoxesIx)
      .map((key) => {
        const intersections = intersectingBoxesIx[key];
        if (!intersections) {
          return false;
        }
        Object.keys(intersections).forEach((key) => {
          delete intersectingBoxesIx[key];
        });
        return intersections;
      })
      .filter(Boolean);

    const newIntersectionsGroups = intersectingGroupsIx.map((intersections) => {
      const keys = Object.keys(intersections);
      const boxes = keys.map((key) => boxesIx[key]);
      boxes.forEach((box) => {
        box.intersecting = true;
      });

      return {
        key: keys.join(),
        keys,
        boxes,
        count: keys.length,
        x: sumBy(boxes, (box) => (box.x0 + box.x1) / 2) / boxes.length,
        y: sumBy(boxes, (box) => (box.y0 + box.y1) / 2) / boxes.length,
      };
    });

    if (
      newIntersectionsGroups.length !== intersectionsGroups.length ||
      differenceBy(newIntersectionsGroups, intersectionsGroups, "key").length
    ) {
      intersectionsGroups = newIntersectionsGroups;
      draw();
      setGroups(intersectionsGroups);
    } else {
      intersectionsGroups.splice(
        0,
        intersectionsGroups.length,
        ...newIntersectionsGroups
      );
      draw();
    }
  };
  self.reflow = reflow;

  self.trigger = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      reflow();
    }, 300);
  };
};

const PointsAnnotations = ({
  annotations,
  defaultClassName,
  groupClassName,
  disabled,
}) => {
  const t = useTranslate();
  const mutableViewerState = useViewerMutableState();
  const [refs, getRefSetter] = useMultidimentionalRef(2);

  const [groups, setGroups] = useState([]);

  const mutable = useRef({}).current;
  mutable.viewerState = mutableViewerState.viewerState;
  mutable.refs = refs;

  mutable.annotations = annotations;

  const debouncerRef = useRef();
  const debouncer =
    debouncerRef.current ??
    (debouncerRef.current = new Debouncer(mutable, setGroups));

  useOnPostrender(() => {
    if (debouncer.refresh()) {
      debouncer.reflow();
    }
    return debouncer.clear;
  }, !disabled);

  if (disabled) {
    return null;
  }

  return (
    <>
      {annotations.map((annotation, i) => {
        const key = annotation?.key;
        if (!annotation?.key) {
          return null;
        }
        const { children, className = defaultClassName } = annotation;
        const refSetter = getRefSetter(key, "element");
        const sizeRefSetter = getRefSetter(key, "size");
        return (
          <ElementWithOnResize
            key={key}
            ref={refSetter}
            onResize={({ width, height }) => {
              sizeRefSetter({ width, height });
              debouncer.trigger();
            }}
            className={classNames(className, s.container)}
            onMouseMove={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {children}
          </ElementWithOnResize>
        );
      })}
      {disabled
        ? null
        : groups.map((annotationsGroup) => {
            let title = t("tooltip.X_GROUPED_ANNOTATIONS_ZOOM_TO_REVEAL", {
              x: annotationsGroup.count,
            });
            if (isArray(title)) {
              title = title.join("");
            }
            return (
              <div
                key={annotationsGroup.key}
                ref={getRefSetter(annotationsGroup.key, "group")}
                style={{
                  transform: `translate(${annotationsGroup.x}px,${annotationsGroup.y}px)`,
                }}
                className={classNames(groupClassName, s.groupContainer)}
                title={title}
              >
                {annotationsGroup.count}
              </div>
            );
          })}
    </>
  );
};

export default PointsAnnotations;
