import { cloneDeep } from "lodash";
import { getTelemetryFromEntityTelemetries } from "../hooks/useLinkedData";
import isRealNumber from "helpers/isRealNumber";
import createLinkedComputersFactory, {
  ValuesTree,
} from "./createLinkedComputersFactory";

import type {
  LinkedTelemetry,
  LinkedTelemetriesResponseL2,
  TelemetryRequest,
  Datum,
} from "../hooks/useLinkedData";
import type { BuildingData } from "./postProcessBuildingData";
import { type ComputeFunction } from "./createLinkedComputersFactory";
import type { Nullable } from "types/utils";
import { Buckets, BucketsConfig } from "./createLinkedTelemetriesBucketer";
import { get, set } from "helpers/setGet";
import { SegmentationBucketsParams } from "../hooks/useLinkedData.types";
import {
  AggregationKeys,
  AggregationValue,
  RequestExtraFilter,
  aggregationsConfigs,
  resolveRequestFilter,
} from "./createLinkedTelemetriesAggregator";

type FilterFunctionExtraParams = {
  index: number;
  telemetry: LinkedTelemetry;
  telemetries: LinkedTelemetriesResponseL2;
  buildingData: BuildingData;
};

type FilterFunction = (
  datum: Datum,
  extraParams: FilterFunctionExtraParams
) => boolean;

type SegmentationFunction = ComputeFunction<
  SegmentationParams,
  HydrateParams,
  AggregationValue
>;

export type SegmentationRequest = {
  entityId: string;
  telemetry: TelemetryRequest;
  aggregations: AggregationKeys[];
  bucketsKeys: string;
  buckets: (Omit<SegmentationBucketsParams, "entityId"> &
    BucketsConfig & { entityId: string; keyPath: string[] })[];
} & RequestExtraFilter;

type SegmentationParams = Omit<
  SegmentationRequest,
  "telemetry" | "bucketsKeys" | "aggregations" | "filterFunction" | "filterKey"
> & {
  key: AggregationKeys;
  uniqKeyPath: Nullable<string>[];
  filterFunction: FilterFunction;
  telemetry: LinkedTelemetry;
  computeFunction: SegmentationFunction;
};

const segmentationFunction: SegmentationFunction = (
  segmentationParams,
  segmentationInternalState,
  hydrateParams
) => {
  const { buckets, telemetries } = hydrateParams;
  const { telemetry, key: aggregationKey, filterFunction } = segmentationParams;

  const segmentationsWithBuckets = segmentationParams.buckets.map(
    (bucketsRequest) => {
      return {
        bucketsRequest,
        buckets: get(buckets, bucketsRequest.keyPath) as Buckets,
      };
    }
  );

  const segmentationsBuckets = segmentationsWithBuckets.map(
    ({ buckets }) => buckets
  );

  const previousSegmentationsBuckets =
    segmentationInternalState.segmentationsBuckets as Nullable<Buckets[]>;
  segmentationInternalState.segmentationsBuckets = segmentationsBuckets;

  // force recomute when any of the buckets change or shouldForceRecompute returns true
  const forceRecompute = previousSegmentationsBuckets
    ? previousSegmentationsBuckets.findIndex(
        (buckets, i) => buckets !== segmentationsBuckets[i]
      ) >= 0 ||
      aggregationsConfigs[aggregationKey].shouldForceRecompute?.(
        segmentationParams,
        segmentationInternalState,
        hydrateParams
      )
    : true;

  const bucketsTelemetries = segmentationsWithBuckets.map(
    ({ bucketsRequest }) =>
      bucketsRequest.type === "telemetry"
        ? getTelemetryFromEntityTelemetries(
            telemetries[bucketsRequest.entityId],
            bucketsRequest.telemetry!
          )
        : null
  );
  const bucketsTypes = segmentationsWithBuckets.map(
    ({ bucketsRequest }) => bucketsRequest.type
  );

  const telemetriesUpToDate =
    bucketsTelemetries.findIndex(
      (telemetry) =>
        telemetry &&
        telemetry.lastConsecutiveDatumIndex !== telemetry.data.length - 1
    ) < 0 && telemetry.lastConsecutiveDatumIndex === telemetry.data.length - 1;

  const startIndex = forceRecompute
    ? 0
    : segmentationInternalState.startIndex ?? 0;

  const data = telemetry.data;
  const nothingToDo =
    !forceRecompute &&
    startIndex >= data.length &&
    segmentationInternalState.result;

  if (nothingToDo) {
    return segmentationInternalState.result;
  }

  let segmentationResults;
  if (startIndex === 0) {
    segmentationResults = {};
    const setSegmentation = (
      result: Record<string, any>,
      defaultLeafValue: any,
      startIndex = 0
    ) => {
      if (startIndex >= segmentationsBuckets.length) {
        return;
      }
      const isLeaf = startIndex === segmentationsBuckets.length - 1;
      const buckets = segmentationsBuckets[startIndex] as Buckets;
      buckets.buckets.forEach((bucket) => {
        if (isLeaf) {
          result[bucket.key] = cloneDeep(defaultLeafValue);
        } else {
          const nextValue = {};
          result[bucket.key] = nextValue;
          setSegmentation(nextValue, defaultLeafValue, startIndex + 1);
        }
      });
    };
    const { getInitialValue, getUnit } = aggregationsConfigs[aggregationKey];
    setSegmentation(segmentationResults, {
      value: getInitialValue
        ? getInitialValue(segmentationParams, hydrateParams)
        : 0,
      sum: 0,
      duration: 0,
      count: 0,
      unit: getUnit
        ? getUnit(segmentationParams, hydrateParams)
        : telemetry.unit,
    });
  } else {
    segmentationResults = segmentationInternalState.segmentationResults;
    if (!telemetriesUpToDate) {
      segmentationResults = cloneDeep(segmentationResults);
    }
  }

  for (let index = startIndex; index < data.length; index++) {
    const datum = data[index];
    if (
      !filterFunction(datum, {
        index,
        buildingData: hydrateParams.buildingData,
        telemetries: hydrateParams.telemetries,
        telemetry,
      })
    ) {
      continue;
    }
    const { value, timestamp, coverage = 1 } = datum;
    let keys: Nullable<Array<string>> = [];
    for (let i = 0; i < bucketsTelemetries.length; i++) {
      const segmentationType = bucketsTypes[i];
      if (segmentationType === "telemetry") {
        const segmentationTelemetry = bucketsTelemetries[i]!;
        const buckets = segmentationsBuckets[i];
        const segmentationTelemetryValue =
          segmentationTelemetry.valueAtTimestamp(timestamp);
        if (!isRealNumber(segmentationTelemetryValue)) {
          keys = null;
          break;
        }
        const bucketKey = buckets.getBucketKey(segmentationTelemetryValue);
        if (!bucketKey) {
          keys = null;
          break;
        }
        keys.push(bucketKey);
      } else if (segmentationType === "time") {
        const buckets = segmentationsBuckets[i];
        const bucketKey = buckets.getBucketKey(timestamp);
        if (!bucketKey) {
          keys = null;
          break;
        }
        keys.push(bucketKey);
      } else if (segmentationType === "bucket") {
        const buckets = segmentationsBuckets[i];
        const bucketKey = buckets.getBucketKey(value);
        if (!bucketKey) {
          keys = null;
          break;
        }
        keys.push(bucketKey);
      }
    }
    if (!keys) {
      continue;
    }
    const { valueFilterFunction = isRealNumber, valueAggregator = () => null } =
      aggregationsConfigs[aggregationKey];

    if (!valueFilterFunction(value)) {
      continue;
    }

    keys.push("value");
    set(
      segmentationResults,
      keys,
      valueAggregator(get(segmentationResults, keys), value, coverage)
    );
    keys.pop();
    keys.push("sum");
    const prevSum = get(segmentationResults, keys);
    set(
      segmentationResults,
      keys,
      !isRealNumber(value) ? prevSum : value + prevSum
    );
    keys.pop();
    keys.push("duration");
    set(
      segmentationResults,
      keys,
      get(segmentationResults, keys) + telemetry.interval * coverage
    );
    keys.pop();
    keys.push("count");
    set(segmentationResults, keys, get(segmentationResults, keys) + coverage);
  }

  if (telemetriesUpToDate) {
    segmentationInternalState.segmentationResults = segmentationResults;
    segmentationInternalState.startIndex = data.length;
  } else if (forceRecompute) {
    delete segmentationInternalState.segmentationResults;
    delete segmentationInternalState.startIndex;
  }

  const result = {};
  const setSegmentation = (
    result: Record<string, any>,
    source: Record<string, any>,
    startIndex = 0
  ) => {
    if (startIndex >= segmentationsBuckets.length) {
      return;
    }
    const isLeaf = startIndex === segmentationsBuckets.length - 1;
    const buckets = segmentationsBuckets[startIndex];
    buckets.buckets.forEach((bucket) => {
      if (isLeaf) {
        const state = source[bucket.key];
        const { getValue = (value: Nullable<number>) => value } =
          aggregationsConfigs[aggregationKey];

        result[bucket.key] = {
          value: getValue(state, segmentationParams, hydrateParams),
          unit: state.unit,
        };
      } else {
        const nextValue = {};
        result[bucket.key] = nextValue;
        setSegmentation(nextValue, source[bucket.key], startIndex + 1);
      }
    });
  };
  setSegmentation(result, segmentationResults);
  segmentationInternalState.result = result;

  return result;
};

type HydrateParams = {
  telemetries: LinkedTelemetriesResponseL2;
  buildingData: BuildingData;
  buckets: ValuesTree<Buckets>;
};

const createLinkedTelemetriesSegmentator = createLinkedComputersFactory<
  SegmentationRequest,
  SegmentationParams,
  HydrateParams,
  any
>((request, { telemetries }) => {
  let {
    entityId,
    telemetry: telemetryRequest,
    buckets,
    bucketsKeys,
    aggregations,
  } = request;

  const telemetry = getTelemetryFromEntityTelemetries(
    telemetries[entityId],
    telemetryRequest
  );
  const telemetryKey = telemetry.key;

  const { filterKey, filterFunction } = resolveRequestFilter(request);

  return aggregations.map((aggregationKey) => {
    const uniqKeyPath = [
      entityId,
      telemetryKey,
      filterKey,
      bucketsKeys,
      aggregationKey,
    ];
    return {
      key: aggregationKey,
      uniqKeyPath,
      entityId,
      telemetry,
      filterFunction,
      buckets,
      computeFunction: segmentationFunction,
    };
  });
});

export default createLinkedTelemetriesSegmentator;
