import { useTranslate } from "modules/language";
import useBuildingData from "./useBuildingData";

const useGraphEmissions = () => {
  const t = useTranslate();
  const { buildingData } = useBuildingData();
  const areaBuilding = buildingData.building.area
    ? parseInt(buildingData.building.area)
    : 1;

  const timeBuckets = [
    t("month.january"),
    t("month.february"),
    t("month.march"),
    t("month.april"),
    t("month.may"),
    t("month.june"),
    t("month.july"),
    t("month.august"),
    t("month.september"),
    t("month.october"),
    t("month.november"),
    t("month.december"),
  ];

  const timeAxis = [
    {
      percent: 3.5714285714285716,
      isEven: 1,
    },
    {
      percent: 7.142857142857143,
      isEven: 0,
    },
    {
      percent: 10.714285714285714,
      isEven: 1,
    },
    {
      percent: 14.285714285714286,
      isEven: 0,
    },
    {
      percent: 17.857142857142858,
      isEven: 1,
    },
    {
      percent: 21.428571428571427,
      isEven: 0,
    },
    {
      percent: 25,
      isEven: 1,
    },
    {
      percent: 28.571428571428573,
      isEven: 0,
    },
    {
      percent: 32.142857142857146,
      isEven: 1,
    },
    {
      percent: 35.714285714285715,
      isEven: 0,
    },
    {
      percent: 39.285714285714285,
      isEven: 1,
    },
    {
      percent: 42.857142857142854,
      isEven: 0,
    },
    {
      percent: 46.42857142857143,
      isEven: 1,
    },
    {
      percent: 50,
      isEven: 0,
    },
    {
      percent: 53.57142857142857,
      isEven: 1,
    },
    {
      percent: 57.142857142857146,
      isEven: 0,
    },
    {
      percent: 60.714285714285715,
      isEven: 1,
    },
    {
      percent: 64.28571428571429,
      isEven: 0,
    },
    {
      percent: 67.85714285714286,
      isEven: 1,
    },
    {
      percent: 71.42857142857143,
      isEven: 0,
    },
    {
      percent: 75,
      isEven: 1,
    },
    {
      percent: 78.57142857142857,
      isEven: 0,
    },
    {
      percent: 82.14285714285714,
      isEven: 1,
    },
    {
      percent: 85.71428571428571,
      isEven: 0,
    },
    {
      percent: 89.28571428571429,
      isEven: 1,
    },
    {
      percent: 92.85714285714286,
      isEven: 0,
    },
    {
      percent: 96.42857142857143,
      isEven: 1,
    },
    {
      percent: 100,
      isEven: 0,
    },
  ];

  const anchorTypes = {
    center: "center" as "center",
    start: "start" as "start",
    end: "end" as "end",
  };

  const getColor = (positionStack: number) => {
    if (positionStack === 1) {
      return "var(--color-green)";
    }
    if (positionStack === 2) {
      return "var(--color-yellow-light)";
    }
    return "var(--purple-color)";
  };

  const calculateScopesByGHGEI = (list: number[]) => {
    const scopesByGHGEI = list.map((element) =>
      Number((element / areaBuilding).toFixed(3))
    );
    return scopesByGHGEI;
  };

  const calculateScopesByGHG = (list: number[]) => {
    const scopesByGHG = list.map((element) => Number(element.toFixed(3)));
    return scopesByGHG;
  };

  return {
    areaBuilding,
    timeBuckets,
    timeAxis,
    anchorTypes,
    getColor,
    calculateScopesByGHGEI,
    calculateScopesByGHG,
  };
};

export default useGraphEmissions;
