import { SingleEvent } from "../core/helpers/events";

const swMessages = new SingleEvent();

export const onMessage = swMessages.addListener;

export const registerServiceWorker = (path = "/sw.js") => {
  if (!("serviceWorker" in navigator)) {
    return;
  }

  const swMessage = (event) => {
    const eventData = event.data;
    swMessages.triggerEvent(eventData);
  };
  const swControllerChange = () => {
    setTimeout(() => {
      if (localStorage.getItem("isFirstTime") !== "0") {
        localStorage.setItem("isFirstTime", "n");
        setTimeout(() => localStorage.setItem("isFirstTime", "0"), 500);
        return;
      }
      if (
        window.confirm(
          "A new version now available! You need to reload to activate it. Reload now?"
        )
      ) {
        window.location.reload();
      }
    }, 2500);
  };
  navigator.serviceWorker.addEventListener("message", swMessage);
  navigator.serviceWorker.addEventListener(
    "controllerchange",
    swControllerChange
  );
  navigator.serviceWorker.register(path);
  navigator.serviceWorker
    .getRegistration()
    .then((serviceWorkerRegistration) => {
      if (!serviceWorkerRegistration) {
        return;
      }
      return serviceWorkerRegistration.update();
    });
};
