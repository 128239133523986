import withIconProps from "helpers/withIconProps";

const FullScreenIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M13 3V7H12V4H9V3H13ZM3 3H7V4H4V7H3V3ZM13 13H9V12H12V9H13V13ZM3 13V9H4V12H7V13H3ZM0 1.99406C0 0.892771 0.894514 0 1.99406 0H14.0059C15.1072 0 16 0.894514 16 1.99406V14.0059C16 15.1072 15.1055 16 14.0059 16H1.99406C0.892771 16 0 15.1055 0 14.0059V1.99406ZM1 1.99406V14.0059C1 14.5539 1.44579 15 1.99406 15H14.0059C14.5539 15 15 14.5542 15 14.0059V1.99406C15 1.44606 14.5542 1 14.0059 1H1.99406C1.44606 1 1 1.44579 1 1.99406Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default FullScreenIcon;
