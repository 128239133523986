import React, { useContext, useEffect, useReducer, useRef } from "react";
import { SingleEvent } from "../../../core/helpers/events";
import viewerContextReducer from "../../state/viewer.context-reducer";

/**
 * @typedef {object} ViewerMutableState
 * @property {SingleEvent} preRenderEmmiter
 */

/**
 * @typedef {object} ViewerState
 * @property {import('three').Scene} scene
 * @property {import('three').WebGLRenderer} renderer
 * @property {import('three').PerspectiveCamera} camera
 * @property {number} width
 * @property {number} height
 * @property {boolean} initialized
 * @property {import('three').Object3D[]} objects
 * @property {HTMLCanvasElement} canvas
 */

const defaultState = {
  height: "45vh",
  initialized: false,
  camera: null,
  scene: null,
  objects: [],
};
/** @type {React.Context<ViewerState>} */
const ViewerStateContext = React.createContext(defaultState);

/** @type {React.Context<React.Dispatch<ReducerAction<Reducer<any, any>>>>} */
const ViewerDispatchContext = React.createContext(null);

/** @type {React.Context<ViewerMutableState>} */
const ViewerMutableStateContext = React.createContext({});

const ViewerProvider = ({ children }) => {
  /** @type {[ReducerState<Reducer<any, any>>, Dispatch<ReducerAction<Reducer<any, any>>>]} */
  const [viewerState, viewerStateDispatch] = useReducer(
    viewerContextReducer,
    defaultState
  );

  const mutable = useRef({}).current;
  mutable.preRenderEmmiter = mutable.preRenderEmmiter ?? new SingleEvent();
  mutable.postRenderEmmiter = mutable.postRenderEmmiter ?? new SingleEvent();
  mutable.viewerState = viewerState;

  window.viewer = Object.assign(window.viewer || {}, viewerState, {
    dispatch: viewerStateDispatch,
  });

  return (
    <ViewerMutableStateContext.Provider value={mutable}>
      <ViewerStateContext.Provider value={viewerState}>
        <ViewerDispatchContext.Provider value={viewerStateDispatch}>
          {children}
        </ViewerDispatchContext.Provider>
      </ViewerStateContext.Provider>
    </ViewerMutableStateContext.Provider>
  );
};

/**
 * @returns {ViewerMutableState}
 */
export const useViewerMutableState = () =>
  useContext(ViewerMutableStateContext);
export const useViewerState = () => useContext(ViewerStateContext);
export const useViewerDispatcher = () => useContext(ViewerDispatchContext);

export const useOnPrerender = (callback, enabled = true) => {
  const preRenderEmmiter = useViewerMutableState().preRenderEmmiter;
  const mutable = useRef({}).current;
  mutable.callback = callback;
  useEffect(() => {
    if (!enabled) {
      return;
    }
    const unregisterListener = preRenderEmmiter.on(
      (...args) =>
        (mutable.cleanCallback = mutable.callback && mutable.callback(...args))
    );
    return () => {
      unregisterListener();
      mutable.cleanCallback && mutable.cleanCallback();
    };
  }, [enabled]);
};

export const useOnPostrender = (callback, enabled = true) => {
  const postRenderEmmiter = useViewerMutableState().postRenderEmmiter;
  const mutable = useRef({}).current;
  mutable.callback = callback;
  useEffect(() => {
    if (!enabled) {
      return;
    }
    const unregisterListener = postRenderEmmiter.on(
      (...args) =>
        (mutable.cleanCallback = mutable.callback && mutable.callback(...args))
    );
    return () => {
      unregisterListener();
      mutable.cleanCallback && mutable.cleanCallback();
    };
  }, [enabled]);
};

export const usePrerenderEmmiter = () => {
  const preRenderEmmiter = useViewerMutableState().preRenderEmmiter;
  return preRenderEmmiter.triggerEvent;
};

export const usePostrenderEmmiter = () => {
  const postRenderEmmiter = useViewerMutableState().postRenderEmmiter;
  return postRenderEmmiter.triggerEvent;
};

export default ViewerProvider;
