import StrippedButton from "atomic-components/atoms/Buttons/StrippedButton";
import { useTranslate } from "modules/language";
import { Minus, Plus } from "svg-icons";
import Loader from "atomic-components/atoms/Loader";
import TooltipAlert from "atomic-components/molecules/TooltipAlert";

import s from "./EditableParamHistoryButton.module.scss";

type EditableParamHistoryButtonProps = {
  isEnabled: boolean;
  isLoading: boolean;
  hasError: boolean;
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
};

const EditableParamHistoryButton = ({
  isEnabled,
  isLoading,
  hasError,
  isExpanded,
  setIsExpanded,
}: EditableParamHistoryButtonProps) => {
  const t = useTranslate();

  if (!isEnabled) {
    return null;
  }

  if (isLoading) {
    return <Loader marginTop={0} display="inline-block" size={16} />;
  }

  if (hasError) {
    return (
      <TooltipAlert iconType="error" tooltip={t("error.CANNOT_LOAD_HISTORY")} />
    );
  }

  if (isExpanded) {
    return (
      <StrippedButton
        type="button"
        className={s.controlBtn}
        title={t("action.COLLAPSE_HISTORY")}
        onClick={() => {
          setIsExpanded(false);
        }}
      >
        <Minus color="var(--color-primary)" iconScale={1.4} />
      </StrippedButton>
    );
  }

  return (
    <StrippedButton
      type="button"
      className={s.controlBtn}
      title={t("action.EXPAND_HISTORY")}
      onClick={() => {
        setIsExpanded(true);
      }}
    >
      <Plus color="var(--color-primary)" iconScale={1.4} />
    </StrippedButton>
  );
};

export default EditableParamHistoryButton;
export type { EditableParamHistoryButtonProps };
