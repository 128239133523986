import useFloorFilterState from "./useFloorFilter";

/**
 * @param {import("../helpers/postProcessBuildingData").BuildingData} buildingData
 * @returns {import("../helpers/postProcessBuildingData").Entity}
 */
const useSelectedEntity = (buildingData) => {
  const { zonesByUrl, floorsByUrl, building } = buildingData ?? {};
  const [floorUrl, zoneUrl] = useFloorFilterState();

  const entity = zoneUrl
    ? zonesByUrl?.[zoneUrl]
    : floorUrl
    ? floorsByUrl?.[floorUrl]
    : building;
  return entity;
};

export const useSelectedEntities = (buildingData) => {
  const { zonesByUrl, floorsByUrl, building } = buildingData ?? {};
  const [floorUrl, zoneUrl] = useFloorFilterState();
  return [
    building,
    floorUrl ? floorsByUrl?.[floorUrl] : null,
    zonesByUrl?.[zoneUrl],
  ];
};

export default useSelectedEntity;
