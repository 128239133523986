import { pull as lodashPull } from "lodash";
import { SingleEvent } from "modules/core/helpers/events";
import { useEffect, useRef } from "react";

const getPrioritizer = (prioritizer) => {
  switch (typeof prioritizer) {
    case "string": {
      const type = prioritizer;
      return ({ entity }) => {
        if (entity?.type === type) {
          return 1;
        }
        return -1;
      };
    }
    case "function":
      return prioritizer;
    default:
      return null;
  }
};

const PrioritizerHooker = function (_prioritizer) {
  if (!this || this === window) {
    throw new Error(
      "PrioritizerHooker is a class and must be instantiated with `new`"
    );
  }

  const self = this;

  let previousPrioritizer;
  let mutablePrioritizer;
  const prioritizer = {
    isEnabled: () => Boolean(mutablePrioritizer),
    getPriority: (...args) => mutablePrioritizer?.(...args),
  };
  const rehydrate = (prioritizer, message) => {
    let didChange = false;
    if (prioritizer !== previousPrioritizer) {
      previousPrioritizer = prioritizer;
      const newPrioritizer = getPrioritizer(prioritizer);
      didChange = Boolean(newPrioritizer) !== Boolean(mutablePrioritizer);
      mutablePrioritizer = newPrioritizer;
    }
    if (prioritizer.message !== message) {
      didChange = true;
      prioritizer.message = message;
    }
    if (didChange) {
      _prioritizer.onPrioritizerUpdated(prioritizer);
    }
  };

  const reprioritize = () => _prioritizer.reprioritize(prioritizer);
  const deprioritize = () => _prioritizer.deprioritize(prioritizer);

  const useMountEffect = () =>
    useEffect(() => {
      _prioritizer.push(prioritizer);
      return () => _prioritizer.pull(prioritizer);
    }, []);

  self.state = { reprioritize, deprioritize };
  Object.assign(self, {
    rehydrate,
    reprioritize,
    deprioritize,
    useMountEffect,
  });

  return self;
};

/**
 * @template PRIORITIZER_PARAM0
 *
 * @callback UsePrioritizerHook
 * @param {import('modules/building/components/Model/Model').Prioritizer<PRIORITIZER_PARAM0> | string} prioritizer
 * @param {string} [message]
 * @returns {{reprioritize: Function, deprioritize: Function}}
 */
/**
 * @typedef {Object} PrioritizerOnChangeCallback
 * @param {string} message the message of the prioritizer
 */
/**
 * @callback UsePrioritizerChangeHook
 * @param {PrioritizerOnChangeCallback} onChange
 */

const Prioritizer = function ({ initPrioritizer, initMessage }) {
  if (!this || this === window) {
    throw new Error(
      "Prioritizer is a class and must be instantiated with `new`"
    );
  }

  const self = this;

  initPrioritizer = initPrioritizer
    ? {
        getPriority: getPrioritizer(initPrioritizer),
        isEnabled: () => true,
        message: initMessage,
      }
    : null;

  const priorityStack = initPrioritizer ? [initPrioritizer] : [];

  let lastMessage = priorityStack[priorityStack.length - 1]?.message;

  const prioritizer = (...args) => {
    for (let i = priorityStack.length - 1; i >= 0; i--) {
      const getPriority = priorityStack[i].getPriority;
      const priority = getPriority(...args);
      if (priority != null) {
        return priority;
      }
    }
    return -1;
  };

  const event = new SingleEvent();
  let onChange;
  const rehydrate = ({ onChange: onChangeFromArgs }) => {
    onChange = onChangeFromArgs;
  };
  const removeListener = event.addListener((...args) => onChange?.(...args));

  const setLastMessage = () => {
    const currMessage = lastMessage;
    for (let i = priorityStack.length - 1; i >= 0; i--) {
      if (priorityStack[i].isEnabled()) {
        lastMessage = priorityStack[i].message;
        break;
      }
    }
    if (currMessage !== lastMessage) {
      event.triggerEvent(lastMessage);
    }
  };
  const pull = (prioritizer) => {
    lodashPull(priorityStack, prioritizer);
    setLastMessage();
  };
  const push = (prioritizer) => {
    priorityStack.push(prioritizer);
    setLastMessage();
  };
  const reprioritize = (prioritizer) => {
    lodashPull(priorityStack, prioritizer).push(prioritizer);
    setLastMessage();
  };
  const deprioritize = (prioritizer) => {
    lodashPull(priorityStack, prioritizer);
    if (initPrioritizer) {
      priorityStack.splice(1, 0, prioritizer);
    } else {
      priorityStack.unshift(prioritizer);
    }
    setLastMessage();
  };

  const onPrioritizerUpdated = (prioritizer) => {
    for (let i = priorityStack.length - 1; i >= 0; i--) {
      if (priorityStack[i] === prioritizer) {
        lastMessage = prioritizer.message;
        event.triggerEvent(prioritizer.message);
        break;
      }
      if (priorityStack[i].isEnabled()) {
        break;
      }
    }
  };

  const hookerParams = {
    pull,
    push,
    reprioritize,
    deprioritize,
    onPrioritizerUpdated,
  };
  const usePrioritizer = (prioritizer, message) => {
    const prioritizerHookerRef = useRef();
    const prioritizerHooker =
      prioritizerHookerRef.current ??
      (prioritizerHookerRef.current = new PrioritizerHooker(hookerParams));

    prioritizerHooker.rehydrate(prioritizer, message);
    prioritizerHooker.useMountEffect();
    return prioritizerHooker.state;
  };

  const usePrioritizerChange = (onChange) => {
    const onChangeRef = useRef();
    onChangeRef.current = onChange;
    useEffect(() => {
      return event.addListener((...args) => onChangeRef.current?.(...args));
    }, []);
  };

  const cleanUp = () => {
    removeListener && removeListener();
    event.destroy();
  };
  const useMountEffect = () => useEffect(() => cleanUp);

  Object.assign(self, {
    state: {
      prioritizer,
      usePrioritizer,
      usePrioritizerChange,
    },
    prioritizer,
    usePrioritizer,
    usePrioritizerChange,
    rehydrate,
    cleanUp,
    useMountEffect,
  });

  return self;
};

/**
 * @param {{initPrioritizer: import('modules/building/components/Model/Model').Prioritizer | string, initMessage: string, onChange: PrioritizerOnChangeCallback}} [param0]
 * @returns {{prioritizer: import('modules/building/components/Model/Model').Prioritizer, usePrioritizer: UsePrioritizerHook, usePrioritizerChange: UsePrioritizerChangeHook}}
 */
const usePrioritizerFactory = ({
  initPrioritizer,
  initMessage,
  onChange,
} = {}) => {
  const prioritizerRef = useRef();
  const prioritizer =
    prioritizerRef.current ??
    (prioritizerRef.current = new Prioritizer({
      initPrioritizer,
      initMessage,
    }));

  prioritizer.rehydrate({ onChange });

  prioritizer.useMountEffect();

  return prioritizer.state;
};

export default usePrioritizerFactory;
