import withIconProps from "helpers/withIconProps";

const ChevronsLeftIcon = withIconProps((props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.17947 10.1027L1.0769 6.00015L5.17947 1.89758M10.9231 10.1027L6.82049 6.00015L10.9231 1.89758"
        stroke="#3D5183"
        strokeWidth="2.05128"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default ChevronsLeftIcon;
