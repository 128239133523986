import withIconProps from "helpers/withIconProps";

const ExportIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      {...props}
    >
      <path
        d="M7.50532 3.08085e-05C7.92663 -0.00264978 8.34853 0.169597 8.67075 0.51593L10.6208 2.56545C10.8338 2.79366 10.8338 3.166 10.6208 3.39421C10.4088 3.62242 10.0627 3.62242 9.84975 3.39421L8 1.45109V9.44444C8 9.75127 7.77614 10 7.5 10C7.22386 10 7 9.75127 7 9.44444V1.48313L5.18075 3.39421C4.96775 3.62242 4.62175 3.62242 4.40975 3.39421C4.19675 3.166 4.19675 2.79366 4.40975 2.56545L6.35975 0.51593C6.67149 0.180848 7.07547 0.00924382 7.48174 0.000363522C7.4878 0.000121892 7.49389 5.24667e-08 7.5 5.24667e-08L7.50532 3.08085e-05ZM3.17972 6C2.70743 6 2.3008 6.2944 2.20818 6.7034L1.01924 11.9534C1.00644 12.0099 1 12.0674 1 12.125C1 12.6082 1.44359 13 1.99078 13H13.009C13.0743 13 13.1394 12.9943 13.2033 12.983C13.7399 12.8882 14.0879 12.4273 13.9806 11.9534L12.7916 6.7034C12.699 6.2944 12.2924 6 11.8201 6H3.17972ZM5 5V6H10V5H11.8253C12.771 5 13.5852 5.60563 13.7707 6.44699L14.9611 11.847C15.176 12.8218 14.4792 13.7701 13.4047 13.965C13.2766 13.9883 13.1463 14 13.0156 14H1.98397C0.888255 14 0 13.1941 0 12.2C0 12.0815 0.0129051 11.9632 0.0385274 11.847L1.22891 6.44699C1.41438 5.60563 2.22863 5 3.17436 5H5Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default ExportIcon;
