import { UpdateEditableParamParams } from "modules/building/api/editableParams";
import useEditableParamsMutate from "modules/building/hooks/useEditableParamsMutate";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import { validateParamValues } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/utils";
import { useTranslate } from "modules/language";
import { useState } from "react";
import EditableParamsContainer from "../../../../../editableParams/EditableParamsContainer/EditableParamsContainer";
import EditableParamWidget from "../../../../../editableParams/EditableParamWidget/EditableParamWidget";
import GlobalWarmingPotentialEditable from "../../../../../editableParams/GlobalWarmingingPotentialEditable/GlobalWarmingingPotentialEditable";
import { ParamName } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/definitions";

const RefrigerantAssumptions = () => {
  const t = useTranslate();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isHistoryEnabled, setIsHistoryEnabled] = useState(false);

  const { state, telemetries, Notifier } = useEditableParamsState();

  const { mutateAsync, isLoading } = useEditableParamsMutate();

  const handleEditSave = async () => {
    if (
      !validateParamValues(state, [
        "refrigerantLeakageRate",
        "lifeTimeDirectExpansionUnit",
        "refrigerantMassACUnit",
        "globalWarmingPotentialR22",
        "globalWarmingPotentialR410a",
        "globalWarmingPotentialR407c",
        "endoflifeRefrigerantLoss",
      ])
    ) {
      return false;
    }

    const payload: UpdateEditableParamParams = [];

    (
      [
        "refrigerantLeakageRate",
        "lifeTimeDirectExpansionUnit",
        "refrigerantMassACUnit",
        "globalWarmingPotentialR22",
        "globalWarmingPotentialR410a",
        "globalWarmingPotentialR407c",
        "endoflifeRefrigerantLoss",
      ] as const
    ).forEach((paramName: ParamName) => {
      if (state[paramName].syncInitialValue()) {
        payload.push({
          timeseriesId: telemetries[paramName].timeseriesId,
          value: state[paramName].value!,
        });
      }
    });

    if (payload.length) {
      await mutateAsync(payload);
    }

    return true;
  };

  return (
    <Notifier>
      <EditableParamsContainer
        title={t("label.ASSUMPTIONS")}
        isEditEnabled={isEditEnabled}
        setIsEditEnabled={setIsEditEnabled}
        isHistoryEnabled={isHistoryEnabled}
        setIsHistoryEnabled={setIsHistoryEnabled}
        onSave={handleEditSave}
      >
        <EditableParamWidget
          isHistoryExpanded
          paramName="refrigerantLeakageRate"
          keyText={t("label.REFRIGERANT_LEAKAGE_RATE")}
          historyLabel={t("label.REFRIGERANT_LEAKAGE_RATE_HISTORY")}
          isEditEnabled={isEditEnabled}
          isHistoryEnabled={isHistoryEnabled}
          disabled={isLoading}
          maxValue={100}
          minValue={0}
        />
        <EditableParamWidget
          paramName="lifeTimeDirectExpansionUnit"
          keyText={t("label.LIFE_TIME_DIRECT_EXPANSION_UNIT")}
          historyLabel={t("label.LIFE_TIME_DIRECT_EXPANSION_UNIT_HISTORY")}
          isEditEnabled={isEditEnabled}
          isHistoryEnabled={isHistoryEnabled}
          disabled={isLoading}
          maxValue={100}
          minValue={0}
        />
        <EditableParamWidget
          paramName="refrigerantMassACUnit"
          keyText={t("label.MASS_REFRIGERANT_AC_UNIT_BY_CAPACTIY")}
          historyLabel={t("label.MASS_REFRIGERANT_AC_UNIT_BY_CAPACTIY_HISTORY")}
          isEditEnabled={isEditEnabled}
          isHistoryEnabled={isHistoryEnabled}
          disabled={isLoading}
          maxValue={100}
          minValue={0}
        />

        <EditableParamWidget
          paramName="endoflifeRefrigerantLoss"
          keyText={t("label.END_OF_LIFE_REFRIGERANT_LOSS")}
          historyLabel={t("label.END_OF_LIFE_REFRIGERANT_LOSS_HISTORY")}
          isEditEnabled={isEditEnabled}
          isHistoryEnabled={isHistoryEnabled}
          disabled={isLoading}
          maxValue={100}
          minValue={0}
          tooltipText={t("tooltip.LEAKAGE_AT_END_OF_LIFE")}
        />

        <GlobalWarmingPotentialEditable
          isEditEnabled={isEditEnabled}
          isHistoryEnabled={isHistoryEnabled}
          isHistoryExpanded
          disabled={isLoading}
        />
      </EditableParamsContainer>
    </Notifier>
  );
};

export default RefrigerantAssumptions;
