import type { SVGAttributes } from "react";

type UserComfortProps = SVGAttributes<SVGElement>;

const UserComfort = (props: UserComfortProps) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.1827 13.0577C19.1827 14.0827 18.8787 15.0847 18.3093 15.937C17.7398 16.7893 16.9303 17.4536 15.9833 17.8459C15.0363 18.2381 13.9943 18.3408 12.9889 18.1408C11.9836 17.9408 11.0601 17.4472 10.3353 16.7224C9.61047 15.9976 9.11687 15.0741 8.9169 14.0688C8.71692 13.0634 8.81956 12.0214 9.21182 11.0744C9.60409 10.1273 10.2684 9.31792 11.1207 8.74844C11.9729 8.17896 12.975 7.875 14 7.875C15.3741 7.87655 16.6914 8.42308 17.663 9.39469C18.6346 10.3663 19.1811 11.6836 19.1827 13.0577ZM26.25 14C26.25 16.4228 25.5315 18.7912 24.1855 20.8057C22.8395 22.8202 20.9263 24.3903 18.6879 25.3175C16.4495 26.2447 13.9864 26.4873 11.6101 26.0146C9.23388 25.5419 7.05114 24.3752 5.33795 22.6621C3.62475 20.9489 2.45805 18.7661 1.98539 16.3899C1.51272 14.0136 1.75531 11.5505 2.68248 9.31213C3.60965 7.07373 5.17977 5.16054 7.19427 3.8145C9.20877 2.46845 11.5772 1.75 14 1.75C17.2478 1.75368 20.3615 3.04548 22.658 5.342C24.9545 7.63852 26.2463 10.7522 26.25 14ZM24.3654 14C24.3667 12.3207 23.96 10.6662 23.1803 9.17895C22.4005 7.69167 21.2711 6.41609 19.8892 5.46199C18.5073 4.50788 16.9143 3.90381 15.2472 3.70176C13.5801 3.49971 11.8889 3.70572 10.319 4.30207C8.7492 4.89843 7.34776 5.86726 6.2353 7.12521C5.12285 8.38317 4.33268 9.89257 3.9328 11.5236C3.53291 13.1545 3.53528 14.8583 3.9397 16.4881C4.34412 18.118 5.13848 19.6252 6.25444 20.88C7.01089 19.7841 7.99184 18.8616 9.13214 18.1739C10.4434 19.4262 12.1868 20.125 14 20.125C15.8132 20.125 17.5566 19.4262 18.8679 18.1739C20.0082 18.8616 20.9891 19.7841 21.7455 20.88C23.4347 18.9866 24.3674 16.5374 24.3654 14Z"
        fill="#F2F6FF"
      />
    </svg>
  );
};

export default UserComfort;
export type { UserComfortProps };
