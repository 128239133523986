import KeyValue from "atomic-components/molecules/KeyValue";
import useBuildingData from "modules/building/hooks/useBuildingData";
import CollapsibleContainer from "atomic-components/molecules/CollapsibleContainer/CollapsibleContainer";
import { useTranslate } from "modules/language";
import UnitValue from "atomic-components/atoms/UnitValue";
import FormattedValue from "atomic-components/atoms/FormattedValue";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import WidgetGrid from "atomic-components/molecules/WidgetGrid/WidgetGrid";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import PerformanceMeter from "atomic-components/molecules/PerformanceMeter";
import flattenWithChildren from "helpers/flattenWithChildren";
import { useMemo, useState } from "react";
import getPercent from "helpers/getPercent";
import OccupancyDetails from "./OccupancyDetails/OccupancyDetails";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";
import { LinkedTelemetry } from "modules/building/hooks/useLinkedData";
type MultipleResolutionsType = {
  [key: string]: LinkedTelemetry;
};

const Occupancy = () => {
  const t = useTranslate();
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);

  const [occupancyExpanded, setOccupancyExpanded] = useState(false);

  const { resolutions, Notifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        resolutionKey: "1month",
        telemetries: [
          {
            telemetryName: "employeesCount",
            resolutionAggregator: [
              "count-available",
              "average-occupancy",
              "max-occupancy",
            ],
          },
          {
            telemetryName: "utilizationScore",
          },
          {
            telemetryName: "numberOfOccupiedFloors",
          },
          {
            telemetryName: "numberOfOccupiedHVACZones",
          },
        ],
      },
    ],
    [selectedEntity.id]
  );

  const {
    employeesCount,
    utilizationScore,
    numberOfOccupiedFloors,
    numberOfOccupiedHVACZones,
  } = resolutions["1month"][selectedEntity.id];
  const employeesCountResolutions =
    employeesCount as any as MultipleResolutionsType;

  const hvacZoneCount = useMemo(() => {
    return flattenWithChildren(selectedEntity).filter(
      ({ type }) => type === "zone-1"
    ).length;
  }, [selectedEntity]);

  return (
    <Notifier>
      <CollapsibleContainer
        title={t("label.OCCUPANCY")}
        isExpanded={occupancyExpanded}
        setIsExpanded={setOccupancyExpanded}
      >
        <WidgetGrid>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.UT_SCORE")}
              growValue
              value={
                <PerformanceMeter
                  value={utilizationScore.data[0]?.value / 100}
                />
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.OCCUPIED_DURATION")}
              value={
                <UnitValue
                  value={
                    employeesCountResolutions["count-available"]?.data?.[0]
                      ?.value / 6
                  }
                  unit={unitsByName.hours}
                />
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.AVG_OCCUPANCY")}
              value={
                <FormattedValue
                  value={
                    employeesCountResolutions["average-occupancy"]?.data?.[0]
                      ?.value
                  }
                />
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.MAX_OCCUPANCY")}
              value={
                <FormattedValue
                  value={
                    employeesCountResolutions["max-occupancy"]?.data?.[0]?.value
                  }
                />
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.AVG_OCCUPIED_FLOORS")}
              value={
                <FormattedValue value={numberOfOccupiedFloors.data[0]?.value} />
              }
              isLight
            />
          </WidgetContainer>
          <WidgetContainer>
            <KeyValue
              keyText={t("label.AVG_OCC_ZONES")}
              value={
                <>
                  <FormattedValue
                    value={numberOfOccupiedHVACZones.data[0]?.value}
                  />
                  /
                  <FormattedValue value={hvacZoneCount} /> (
                  <UnitValue
                    value={getPercent(
                      numberOfOccupiedHVACZones.data[0]?.value,
                      hvacZoneCount
                    )}
                    unit={unitsByName.percent}
                  />
                  )
                </>
              }
              isLight
            />
          </WidgetContainer>
        </WidgetGrid>
        {occupancyExpanded ? (
          <OccupancyDetails hvacZoneCount={hvacZoneCount} />
        ) : null}
      </CollapsibleContainer>
    </Notifier>
  );
};

export default Occupancy;
