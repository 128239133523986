import DropDown from "atomic-components/atoms/DropDown";

import s from "./HeaderGraphEmissions.module.scss";
import { useTranslate } from "modules/language";

export enum GreenHouseGas {
  GHG = "Greenhouse Gas (GHG) Emissions (kgCO2e)",
  GHGEI = "Greenhouse Gas Emissions Intensity (GHGEI) (kgCO2e/m2)",
}

type HeaderGraphEmissionsProps = {
  gasType: GreenHouseGas;
  year: number;
  isDeskScreen: boolean;
  onChangeTypeGas: (param: GreenHouseGas) => void;
};

const gasOptions = [
  {
    key: GreenHouseGas.GHG,
    text: GreenHouseGas.GHG,
  },
  {
    key: GreenHouseGas.GHGEI,
    text: GreenHouseGas.GHGEI,
  },
];
const HeaderGraphEmissions = ({
  gasType,
  year,
  isDeskScreen,
  onChangeTypeGas,
}: HeaderGraphEmissionsProps) => {
  const t = useTranslate();
  return (
    <div className={s.container}>
      <div className={s.leftSide}>
        <p
          style={{
            fontSize: isDeskScreen
              ? "var(--font-size-large)"
              : "var(--font-size-regular)",
          }}
        >
          {t("label.TOTAL_EMISSIONS")}
        </p>
        <DropDown
          variant="outline"
          size="small"
          value={gasType}
          options={gasOptions}
          valueField="key"
          textField="text"
          onChange={({ value }) => onChangeTypeGas(value as GreenHouseGas)}
          arrowSize="big"
        />
      </div>
      <p
        style={{
          fontSize: isDeskScreen
            ? "var(--font-size-large)"
            : "var(--font-size-regular)",
        }}
      >
        01/01/{year} - 12/01/{year}
      </p>
    </div>
  );
};

export default HeaderGraphEmissions;
export type { HeaderGraphEmissionsProps };
