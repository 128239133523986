import { SingleEvent } from "../../core/helpers/events";
import {
  login as loginApi,
  refreshSession as refreshSessionApi,
  logout as logoutApi,
} from "../api/authentication.api";
import {
  execute as executeReCaptcha,
  destroy as destroyReCaptcha,
} from "../../core/services/re-captcha.service";
import { useEffect, useState } from "react";
import { on401 } from "../events/authentication.events";

const AUTH_DATA_KEY = "AUTH_DATA";
const SHOW_STAYLOGGEDIN_MODAL_BEFORE_EXPIRES_DURATION = 60 * 1000;

const getAuthStateFromStore = () => localStorage.getItem(AUTH_DATA_KEY);

const authenticationState = (() => {
  const authDataStr = getAuthStateFromStore();
  if (!authDataStr) {
    return {};
  }
  try {
    const authState = JSON.parse(authDataStr);
    if (Date.now() > authState.expires) {
      localStorage.removeItem(AUTH_DATA_KEY);
      return {};
    }
    if (authState.user) {
      authState.loggedIn = true;
      if (
        authState.expires - Date.now() <=
        SHOW_STAYLOGGEDIN_MODAL_BEFORE_EXPIRES_DURATION
      ) {
        authState.showStayLoggedIn = true;
      }
    }
    return authState;
  } catch (err) {
    return {};
  }
})();

const stateChangeEvent = new SingleEvent();
const loginEvent = new SingleEvent();
const logoutEvent = new SingleEvent();
const showStayLoggedInChangeEvent = new SingleEvent();
const sessionRefreshedEvent = new SingleEvent();

const saveState = () => {
  localStorage.setItem(
    AUTH_DATA_KEY,
    JSON.stringify({
      expires: authenticationState.expires,
      csrfToken: authenticationState.csrfToken,
      user: authenticationState.user,
      roles: authenticationState.roles,
    })
  );
};

const updateState = (update) => {
  const nextState = { ...authenticationState, ...update };

  const loggedIn = nextState.user;
  const nowLoggedIn = loggedIn && !authenticationState.user;
  const nowLoggedOut = !loggedIn && authenticationState.user;

  const showStayLoggedIn =
    !nowLoggedIn && // he did not just login
    nextState.user && // he is still logged in
    nextState.showStayLoggedIn;

  const nowShowStayLoggedIn =
    showStayLoggedIn && !authenticationState.showStayLoggedIn;
  const nowHideStayLoggedIn =
    !showStayLoggedIn && authenticationState.showStayLoggedIn;

  const nowRefreshedSession =
    nextState.csrfToken &&
    authenticationState.csrfToken &&
    nextState.csrfToken !== authenticationState.csrfToken;

  const oldUser = authenticationState.user;
  Object.assign(authenticationState, update, { loggedIn, showStayLoggedIn });
  saveState();
  stateChangeEvent.triggerEvent(authenticationState);
  nowLoggedIn && loginEvent.triggerEvent(authenticationState);
  nowLoggedOut && logoutEvent.triggerEvent(oldUser);
  (nowShowStayLoggedIn || nowHideStayLoggedIn) &&
    showStayLoggedInChangeEvent.triggerEvent(authenticationState);
  nowRefreshedSession &&
    sessionRefreshedEvent.triggerEvent(authenticationState);
};

export const login = (username, password) => {
  return executeReCaptcha()
    .then(({ token }) =>
      loginApi({
        username: username,
        password: password,
        captcha: token,
      })
    )
    .then(({ csrfToken, expires, user, roles }) => {
      updateState({
        expires,
        csrfToken,
        user,
        roles,
      });
    })
    .finally(() => destroyReCaptcha());
};

export const logout = () => {
  return logoutApi().then(() => {
    updateState({
      expires: null,
      csrfToken: null,
      user: null,
      roles: null,
    });
  });
};

export const switchRole = (role) => {
  return updateState({
    expires: authenticationState.expires,
    csrfToken: authenticationState.csrfToken,
    user: authenticationState.user,
    roles: [{ name: role }],
  });
};

export const refreshSession = () => {
  return refreshSessionApi().then(({ csrfToken, expires }) => {
    updateState({
      csrfToken,
      expires,
      showStayLoggedIn: false,
    });
  });
};

export const hideStayLoggedIn = () => {
  if (authenticationState.showStayLoggedIn) {
    updateState({
      showStayLoggedIn: false,
    });
  }
};

const showStayLoggedInSetupListener = () => {
  const expires = authenticationState.expires;
  const timer = setTimeout(() => {
    updateState({
      showStayLoggedIn: true,
    });
  }, expires - Date.now() - SHOW_STAYLOGGEDIN_MODAL_BEFORE_EXPIRES_DURATION);
  setTimeout(() => {
    logoutEvent.once(() => {
      clearTimeout(timer);
    });
    sessionRefreshedEvent.once(() => {
      clearTimeout(timer);
    });
  });
};
loginEvent.addListener(showStayLoggedInSetupListener);
sessionRefreshedEvent.addListener(showStayLoggedInSetupListener);

const logoutSetupListener = () => {
  const expires = authenticationState.expires;
  const timer = setTimeout(() => {
    updateState({
      expires: null,
      csrfToken: null,
      user: null,
      roles: null,
    });
  }, expires - Date.now());
  setTimeout(() => {
    logoutEvent.once(() => {
      clearTimeout(timer);
    });
    sessionRefreshedEvent.once(() => {
      clearTimeout(timer);
    });
  });
};
loginEvent.addListener(logoutSetupListener);
sessionRefreshedEvent.addListener(logoutSetupListener);

on401(() => {
  updateState({
    expires: null,
    csrfToken: null,
    user: null,
    roles: null,
  });
});

export const onLogin = loginEvent.addListener;
export const onLogout = logoutEvent.addListener;
export const onSessionRefresh = sessionRefreshedEvent.addListener;
export const onShowStayLoggedInChange = showStayLoggedInChangeEvent.addListener;
export const getAuthenticationState = () => ({ ...authenticationState });

/**
 * @returns {{ csrfToken: string; expires: number; roles: { name: string}[]; user: { id: number; username: string; isAdmin: boolean }; loggedIn?: boolean; showStayLoggedIn?: boolean }}
 */
export const useAuthenticationState = () => {
  const [state, setState] = useState(getAuthenticationState);
  useEffect(() =>
    stateChangeEvent.addListener((authenticationState) => {
      setState({ ...authenticationState });
    })
  );
  return state;
};

export { getAuthStateFromStore };
