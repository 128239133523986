import type { HTMLAttributes } from "react";
import classNames from "classnames";

import s from "./DotsLoader.module.scss";

type DotsLoaderProps = HTMLAttributes<HTMLDivElement> & {
  hasBlockSpace?: boolean;
  isInline?: boolean;
};

const DotsLoader = ({
  hasBlockSpace,
  isInline,
  className,
  ...props
}: DotsLoaderProps) => {
  return (
    <div
      role="progressbar"
      className={classNames(
        s.stage,
        { [s.blockSpace]: hasBlockSpace, [s.inline]: isInline },
        className
      )}
      {...props}
    >
      <div className={s.dot} />
    </div>
  );
};

export default DotsLoader;
export type { DotsLoaderProps };
