import React from "react";

import "./Loader.css";

/**
 * Input props for Loader component.
 * @typedef {Object} LoaderProps
 * @property {number} marginTop
 * @property {'block' | 'inline-block'} display - use: block or inline-block
 * @property {string} [position] - defaults to relative
 * @property {number} [size] - defaults to relative
 * @property {number} [borderWidth] - defaults 1/8 of size maxed at 25
 */

/**
 * @param {LoaderProps} param0
 */
const Loader = ({
  marginTop = 15,
  display = "block",
  position = "relative",
  size = 100,
  borderWidth,
}) => {
  if (!borderWidth) {
    borderWidth = size / 8;
    borderWidth = borderWidth > 25 ? 25 : borderWidth;
  }
  return (
    <div
      className="loader"
      style={{
        marginTop: marginTop + "px",
        width: size - borderWidth + "px",
        height: size - borderWidth + "px",
        position,
        display,
        border: borderWidth + "px solid rgba(128,128,128,0.3)",
        borderTop: borderWidth + "px solid rgba(0,0,0,0.9)",
      }}
    ></div>
  );
};

export default Loader;
