import axios from "axios";
import config from "../../../config";
import { getAuthenticationState } from "modules/authentication/state/authentication.state";

const beApiClient = axios.create({
  baseURL: config.api.baseUrl,
});

beApiClient.interceptors.request.use((config) => {
  const { loggedIn, expires, csrfToken } = getAuthenticationState();

  if (loggedIn && expires > Date.now()) {
    config.headers.Authorization = csrfToken;
  }

  return config;
});

const alarmsApiClient = axios.create({
  baseURL: config.api.baseUrl + "/alarms",
});

alarmsApiClient.interceptors.request.use((config) => {
  return config;
});

export { beApiClient, alarmsApiClient };
