import useBuildingData from "modules/building/hooks/useBuildingData";
import { useSelectedLanguageState, useTranslate } from "modules/language";
import { getMonthOfDayFromTo } from "modules/building/helpers/intervals";
import { DateTime } from "luxon";
import Slider, { type SliderProps } from "atomic-components/atoms/Slider";
import { useViewState } from "modules/building/providers/ViewProvider/ViewProvider";

const MonthsSlider = () => {
  const t = useTranslate();
  const { fromTs, setDateFilter } = useViewState();
  const {
    buildingData: { timezone },
  } = useBuildingData();
  const { languageCode } = useSelectedLanguageState();
  const { month, monthLong, year } = getMonthOfDayFromTo({
    reference: fromTs,
    timezone,
  }).from.setLocale(languageCode);
  const previousMonthDateTime = getMonthOfDayFromTo({
    reference: DateTime.now().minus({ month: 1 }), // get previous month
    timezone,
  }).from;

  const handleChange: SliderProps["setValue"] = (value) => {
    const valueDateTime = DateTime.now().set({
      day: 1,
      month: value,
      year,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const { fromTs, toTs } = getMonthOfDayFromTo({
      reference: valueDateTime,
      timezone,
    });

    setDateFilter([fromTs, toTs]);
  };

  return (
    <Slider
      min={1}
      max={12}
      step={1}
      label={
        <>
          {t("message.DISPLAYED_DATE_FOR")}{" "}
          <strong>
            {monthLong} {year}
          </strong>
        </>
      }
      tooltip={monthLong}
      value={month}
      setValue={handleChange}
      displaySteps
      maxAllowed={
        year >= previousMonthDateTime.year
          ? previousMonthDateTime.month
          : undefined
      }
    />
  );
};

export default MonthsSlider;
