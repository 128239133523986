import { useTranslate } from "modules/language";
import type { SubChart } from "../types";
import RadioInput from "atomic-components/atoms/RadioInput";
import { useId, useState } from "react";
import ModalFooter from "atomic-components/molecules/Modal/ModalFooter";
import type { Setter } from "types/utils";
import { useModalState } from "atomic-components/molecules/Modal";
import Button from "atomic-components/atoms/Buttons/Button";

import s from "./SubGraphSwapModalBody.module.scss";

type SubGraphSwapModalBodyProps = {
  availableSubCharts: SubChart[];
  setSubCharts: Setter<SubChart[]>;

  newSubChart: SubChart;
  isCustomChart?: boolean;
  changeCustomChart?: (data: SubChart) => void;
};

const SubGraphSwapModalBody = ({
  availableSubCharts,
  setSubCharts,
  newSubChart,
  isCustomChart,
  changeCustomChart,
}: SubGraphSwapModalBodyProps) => {
  const t = useTranslate();
  const inputName = useId();
  const { hideModal } = useModalState();
  const [selectedSubChart, setSelectedSubChart] = useState<SubChart>();

  return (
    <form
      className={s.form}
      onSubmit={(e) => {
        e.preventDefault();
        if (selectedSubChart) {
          setSubCharts((prevSubCharts) => {
            const subChartIndex = prevSubCharts.findIndex(
              (subChart) => subChart.key === selectedSubChart.key
            );
            if (subChartIndex > -1) {
              const newSubCharts = [...prevSubCharts];
              newSubCharts[subChartIndex] = newSubChart;

              return newSubCharts;
            }
            if (subChartIndex === -1) {
              changeCustomChart && changeCustomChart(newSubChart);
            }

            return prevSubCharts;
          });
        }
        hideModal();
      }}
    >
      {isCustomChart
        ? t("message.SUB_GRAPH_SWAP_CUSTOM")
        : t("message.SUB_GRAPH_SWAP")}
      {availableSubCharts.map((subChart) => {
        return (
          <RadioInput
            name={inputName}
            key={subChart.key}
            onChange={() => {
              setSelectedSubChart(subChart);
            }}
          >
            {subChart.text}
          </RadioInput>
        );
      })}
      <ModalFooter
        cancel={{
          text: t("action.CANCEL"),
        }}
      >
        <Button type="submit" size="small" skin="primary">
          {t("action.REPLACE")}
        </Button>
      </ModalFooter>
    </form>
  );
};

export default SubGraphSwapModalBody;
export type { SubGraphSwapModalBodyProps };
