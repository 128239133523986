import FormattedValue from "atomic-components/atoms/FormattedValue";
import Key from "atomic-components/atoms/Key";
import Section from "atomic-components/atoms/Section";
import UnitValue from "atomic-components/atoms/UnitValue";
import WidgetContainer from "atomic-components/atoms/WidgetContainer";
import Displayer from "atomic-components/molecules/Displayer/Displayer";
import KeyValue from "atomic-components/molecules/KeyValue";
import HorizontalStackedChart from "atomic-components/organisms/HorizontalStackedChart";
import { useMergedNotifier } from "helpers/getNotifier";
import getPercent from "helpers/getPercent";
import getRatio from "helpers/getRatio";
import isRealNumber from "helpers/isRealNumber";
import useBuildingData from "modules/building/hooks/useBuildingData";
import useSelectedEntity from "modules/building/hooks/useSelectedEntity";
import { useTravelEmissions } from "modules/building/hooks/useTravelEmissions";
import { useEditableParamsState } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/providers/EditableParamsProvider/EditableParamsProvider";
import { useViewData } from "modules/building/providers/ViewProvider/ViewProvider";
import { useTranslate } from "modules/language";

const TravelCompanyOwnedVehicles = () => {
  const t = useTranslate();
  const { buildingData } = useBuildingData();
  const { unitsByName } = buildingData;
  const selectedEntity = useSelectedEntity(buildingData);
  const { state, Notifier: EPNotifier } = useEditableParamsState();

  const {
    companyCarsEmissions,
    companyBusesEmissions,
    totalTravelEmissions,
    Notifier: ENotifier,
  } = useTravelEmissions();

  const { telemetries, Notifier: TNotifier } = useViewData(
    () => [
      {
        entityId: selectedEntity.id,
        telemetries: [
          {
            telemetryName: "monthlyFleetSize",
          },
          {
            telemetryName: "monthlyCompanyBusesCount",
          },
          {
            telemetryName: "monthlyCompanyCarsCount",
          },
          {
            telemetryName: "monthlyFuelTypeSolar",
          },
          {
            telemetryName: "monthlyFuelType95",
          },
          {
            telemetryName: "monthlyFuelType92",
          },
          {
            telemetryName: "monthlyCompanyTotalCarsFuel",
          },
          {
            telemetryName: "monthlyCompanyTotalBusesFuel",
          },
        ],
      },
    ],
    [selectedEntity.id]
  );

  const {
    monthlyFleetSize,
    monthlyCompanyBusesCount,
    monthlyCompanyCarsCount,
    monthlyFuelTypeSolar,
    monthlyFuelType95,
    monthlyFuelType92,
    monthlyCompanyTotalCarsFuel,
    monthlyCompanyTotalBusesFuel,
  } = telemetries[selectedEntity.id];

  const avgCompanyBusesFuelEconomyValue =
    state.avgCompanyBusesFuelEconomy.value;
  const avgCompanyCarsFuelEconomyValue = state.avgCompanyCarsFuelEconomy.value;
  const monthlyFleetSizeValue = monthlyFleetSize.data[0]?.value;
  const monthlyCompanyBusesCountValue = monthlyCompanyBusesCount.data[0]?.value;
  const monthlyCompanyCarsCountValue = monthlyCompanyCarsCount.data[0]?.value;
  const monthlyFuelTypeSolarValue = monthlyFuelTypeSolar.data[0]?.value;
  const monthlyFuelType95Value = monthlyFuelType95.data[0]?.value;
  const monthlyFuelType92Value = monthlyFuelType92.data[0]?.value;
  const monthlyCompanyTotalCarsFuelValue =
    monthlyCompanyTotalCarsFuel.data[0]?.value;
  const monthlyCompanyTotalBusesFuelValue =
    monthlyCompanyTotalBusesFuel.data[0]?.value;
  const days = monthlyFuelType92.days;

  const totalFuelUsage =
    isRealNumber(monthlyFuelTypeSolarValue) &&
    isRealNumber(monthlyFuelType95Value) &&
    isRealNumber(monthlyFuelType92Value)
      ? monthlyFuelTypeSolarValue +
        monthlyFuelType95Value +
        monthlyFuelType92Value
      : null;

  const avgDailyFuelUsagePerCar = getRatio(
    getRatio(monthlyCompanyTotalCarsFuelValue, monthlyCompanyCarsCountValue),
    days
  );
  const avgDailyFuelUsagePerBus = getRatio(
    getRatio(monthlyCompanyTotalBusesFuelValue, monthlyCompanyBusesCountValue),
    days
  );
  const avgCarsTravelDistance = getRatio(
    companyCarsEmissions,
    avgCompanyCarsFuelEconomyValue
  );
  const avgBusesTravelDistance = getRatio(
    companyBusesEmissions,
    avgCompanyBusesFuelEconomyValue
  );

  const avgDailyTravelDistance =
    isRealNumber(avgCarsTravelDistance) && isRealNumber(avgBusesTravelDistance)
      ? getRatio(avgCarsTravelDistance + avgBusesTravelDistance, days)
      : null;

  const Notifier = useMergedNotifier(TNotifier, ENotifier, EPNotifier);

  return (
    <Notifier>
      <Section>
        <Key tag="h4">{t("label.COMPANY_OWNED_VEHICLES")}</Key>
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.FLEET_SIZE")}
            value={<FormattedValue value={monthlyFleetSizeValue} />}
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.AVG_FUEL_USAGE")}
            value={
              <>
                <UnitValue
                  value={getRatio(totalFuelUsage, days)}
                  unit={unitsByName.liter}
                />
                /
                <Displayer object={unitsByName.day} />
              </>
            }
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.TOTAL_FUEL_USAGE")}
            value={
              <UnitValue value={totalFuelUsage} unit={unitsByName.liter} />
            }
          />
        </WidgetContainer>
        <HorizontalStackedChart
          hasExpandText={false}
          data={[
            {
              key: "diesel",
              value: getPercent(monthlyFuelTypeSolarValue, totalFuelUsage),
              legend: t("legend.DIESEL_[VALUE]_[PERCENT]", {
                value: (
                  <UnitValue
                    value={monthlyFuelTypeSolarValue}
                    unit={unitsByName.liter}
                  />
                ),
                percent: (
                  <UnitValue
                    value={getPercent(
                      monthlyFuelTypeSolarValue,
                      totalFuelUsage
                    )}
                    unit={unitsByName.percent}
                  />
                ),
              }),
            },
            {
              key: "petrol95",
              value: getPercent(monthlyFuelType95Value, totalFuelUsage),
              legend: t("legend.PETROL_95_OCTANE_[VALUE]_[PERCENT]", {
                value: (
                  <UnitValue
                    value={monthlyFuelType95Value}
                    unit={unitsByName.liter}
                  />
                ),
                percent: (
                  <UnitValue
                    value={getPercent(monthlyFuelType95Value, totalFuelUsage)}
                    unit={unitsByName.percent}
                  />
                ),
              }),
            },
            {
              key: "petrol92",
              value: getPercent(monthlyFuelType92Value, totalFuelUsage),
              legend: t("legend.PETROL_92_OCTANE_[VALUE]_[PERCENT]", {
                value: (
                  <UnitValue
                    value={monthlyFuelType92Value}
                    unit={unitsByName.liter}
                  />
                ),
                percent: (
                  <UnitValue
                    value={getPercent(monthlyFuelType92Value, totalFuelUsage)}
                    unit={unitsByName.percent}
                  />
                ),
              }),
            },
          ]}
          unit={unitsByName.percent}
        />
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.AVG_EMISSIONS")}
            value={
              <>
                <UnitValue
                  value={getRatio(totalTravelEmissions, days)}
                  unit={unitsByName.kgco2e}
                />
                /
                <Displayer object={unitsByName.day} />
              </>
            }
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.AVG_DAILY_FUEL_USAGE_PER_CAR")}
            value={
              <>
                <UnitValue
                  value={avgDailyFuelUsagePerCar}
                  unit={unitsByName.liter}
                />
                /
                <Displayer object={unitsByName.day} />
              </>
            }
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.AVG_DAILY_FUEL_USAGE_PER_BUS")}
            value={
              <>
                <UnitValue
                  value={avgDailyFuelUsagePerBus}
                  unit={unitsByName.liter}
                />
                /
                <Displayer object={unitsByName.day} />
              </>
            }
          />
        </WidgetContainer>
        <WidgetContainer>
          <KeyValue
            isLight
            keyText={t("label.AVG_KM_TRAVELED")}
            value={
              <>
                <UnitValue
                  value={avgDailyTravelDistance}
                  unit={unitsByName.km}
                />
                /
                <Displayer object={unitsByName.day} />
              </>
            }
          />
        </WidgetContainer>
      </Section>
    </Notifier>
  );
};

export default TravelCompanyOwnedVehicles;
