import withIconProps from "helpers/withIconProps";

const ExternalIcon = withIconProps((props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_7_11195)">
        <path
          d="M17.5 18.3333V20H2.5V18.3333H17.5ZM14.5033 6.58667L18.3333 10.4167L14.5033 14.2467L13.325 13.0683L15.9767 10.4167L13.325 7.765L14.5033 6.58667ZM10 12.5V14.1667H2.5V12.5H10ZM10 6.66667V8.33334H2.5V6.66667H10Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_11195">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default ExternalIcon;
