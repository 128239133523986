import {
  type ReactNode,
  createContext,
  useMemo,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import Modal, { type ModalProps } from "./Modal";
import type { Setter } from "types/utils";

type ModalContextValue = {
  modalChildren?: ReactNode;
  setModalChildren: Setter<ReactNode>;
  setModalProps: Setter<Omit<ModalProps, "children"> | undefined>;
  setModalTimeout: Setter<number | undefined>;
  hideModal: () => void;
  setModal: (
    content: ReactNode,
    props?: Omit<ModalProps, "children">,
    timeout?: number
  ) => void;
};

type ModalProviderProps = {
  children: ReactNode;
};

const ModalContext = createContext<ModalContextValue>({
  modalChildren: undefined,
  setModalChildren: () => {},
  setModalProps: () => {},
  setModalTimeout: () => {},
  hideModal: () => {},
  setModal: () => {},
});

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modalChildren, setModalChildren] = useState<ReactNode>();
  const [modalProps, setModalProps] = useState<Omit<ModalProps, "children">>();
  const [modalTimeout, setModalTimeout] = useState<number>();

  const hideModal = useCallback(() => {
    setModalChildren(undefined);
    setModalProps(undefined);
    setModalTimeout(undefined);
  }, []);

  const setModal: ModalContextValue["setModal"] = useCallback(
    (content, props, timeout) => {
      setModalChildren(content);

      if (props) {
        setModalProps(props);
      }

      if (typeof timeout === "number") {
        setModalTimeout(timeout);
      }
    },
    []
  );

  useEffect(() => {
    if (!modalTimeout) {
      return;
    }

    const timer = setTimeout(() => {
      hideModal();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [hideModal, modalTimeout]);

  const value = useMemo(
    () => ({
      modalChildren,
      setModalChildren,
      setModalProps,
      setModalTimeout,
      hideModal,
      setModal,
    }),
    [hideModal, modalChildren, setModal]
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {modalChildren && (
        <Modal
          {...modalProps}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              hideModal();
            }
          }}
        >
          {modalChildren}
        </Modal>
      )}
    </ModalContext.Provider>
  );
};

const useModalState = () => useContext(ModalContext);

export default ModalProvider;
export { useModalState };
export type { ModalContextValue, ModalProviderProps };
