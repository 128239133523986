import "react-calendar/dist/Calendar.css";
import { createRoot } from "react-dom/client";
import "./styles/index.scss";
import { initialize as initializeReCaptcha } from "./modules/core/services/re-captcha.service";
import App from "App";
import config from "./config";
import { registerServiceWorker } from "./modules/service-worker";

const startUp = () => {
  initializeReCaptcha(config.reCaptcha);

  const root = createRoot(document.getElementById("root"));
  root.render(<App />);
};

startUp();

if (config.isProd) {
  registerServiceWorker("/sw.js");
}
