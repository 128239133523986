import withIconProps from "helpers/withIconProps";

const LayersIcon = withIconProps((props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6648 1.82918C11.8759 1.72361 12.1244 1.72361 12.3356 1.82918L22.3356 6.82918C22.5897 6.95622 22.7502 7.21592 22.7502 7.5C22.7502 7.78408 22.5897 8.04378 22.3356 8.17082L12.3356 13.1708C12.1244 13.2764 11.8759 13.2764 11.6648 13.1708L1.66475 8.17082C1.41067 8.04378 1.25016 7.78408 1.25016 7.5C1.25016 7.21592 1.41067 6.95622 1.66475 6.82918L11.6648 1.82918ZM3.67721 7.5L12.0002 11.6615L20.3231 7.5L12.0002 3.33853L3.67721 7.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.32934 17.1646C1.51459 16.7941 1.96509 16.6439 2.33557 16.8292L12.0002 21.6615L21.6648 16.8292C22.0352 16.6439 22.4857 16.7941 22.671 17.1646C22.8562 17.5351 22.7061 17.9856 22.3356 18.1708L12.3356 23.1708C12.1244 23.2764 11.8759 23.2764 11.6648 23.1708L1.66475 18.1708C1.29427 17.9856 1.1441 17.5351 1.32934 17.1646Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.32934 12.1646C1.51459 11.7941 1.96509 11.6439 2.33557 11.8292L12.0002 16.6615L21.6648 11.8292C22.0352 11.6439 22.4857 11.7941 22.671 12.1646C22.8562 12.5351 22.7061 12.9856 22.3356 13.1708L12.3356 18.1708C12.1244 18.2764 11.8759 18.2764 11.6648 18.1708L1.66475 13.1708C1.29427 12.9856 1.1441 12.5351 1.32934 12.1646Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default LayersIcon;
