import classNames from "classnames";
import type { ButtonHTMLAttributes } from "react";

import s from "./Tab.module.scss";

type TabProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  skin?: "default" | "rounded" | "underlined" | "box" | "sleek";
  size?: "default" | "small";
};

const Tab = ({
  skin = "default",
  size = "default",
  className,
  ...props
}: TabProps) => {
  return (
    <button
      className={classNames(s.tab, s[skin], s[`size${size}`], className)}
      {...props}
    />
  );
};

export default Tab;
export type { TabProps };
