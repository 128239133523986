import classNames from "classnames";

type PersonProps = {
  className?: string;
  iconScale?: number;
  icon?: boolean;
};

const Person = ({ className, iconScale = 1, icon = true }: PersonProps) => {
  return (
    <svg
      className={classNames(className, { "para-icon": icon })}
      style={{
        fontSize: `${iconScale * 100}%`,
      }}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13C1 10.75 4 10.75 5.5 9.25C6.25 8.5 4 8.5 4 4.75C4 2.25025 4.99975 1 7 1C9.00025 1 10 2.25025 10 4.75C10 8.5 7.75 8.5 8.5 9.25C10 10.75 13 10.75 13 13"
        stroke="#304375"
        stroke-width="0.7"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Person;
