import withIconProps from "helpers/withIconProps";

const ChevronUpIcon = withIconProps((props) => {
  return (
    <svg width="13" height="13" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
});

export default ChevronUpIcon;
