import classNames from "classnames";
import type { SVGAttributes } from "react";

type ArrowsProps = SVGAttributes<SVGElement> & {
  icon?: boolean;
  iconScale?: number;
  color?: string;
  strokeWidth?: number;
};

const Arrows = ({
  icon = true,
  iconScale = 1,
  color = "#1F2937",
  strokeWidth = 2,
  className,
  ...props
}: ArrowsProps) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, { "para-icon": icon })}
      style={{
        transform: `scale(${iconScale})`,
      }}
      {...props}
    >
      <path
        d="M13 1L17 5M17 5L13 9M17 5H1M5 19L1 15M1 15L5 11M1 15H17"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Arrows;
