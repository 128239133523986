import withIconProps from "helpers/withIconProps";

const AssetsIcon = withIconProps((props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 4.14286C2.75 3.32403 3.35754 2.75 4 2.75H20C20.6425 2.75 21.25 3.32403 21.25 4.14286V14.8571C21.25 15.676 20.6425 16.25 20 16.25H12H4C3.35754 16.25 2.75 15.676 2.75 14.8571V4.14286ZM20 17.75H12.75V20.25H16C16.4142 20.25 16.75 20.5858 16.75 21C16.75 21.4142 16.4142 21.75 16 21.75H12H8C7.58579 21.75 7.25 21.4142 7.25 21C7.25 20.5858 7.58579 20.25 8 20.25H11.25V17.75H4C2.43332 17.75 1.25 16.4052 1.25 14.8571V4.14286C1.25 2.59475 2.43332 1.25 4 1.25H20C21.5667 1.25 22.75 2.59475 22.75 4.14286V14.8571C22.75 16.4052 21.5667 17.75 20 17.75ZM12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 13C11.25 13.4142 11.5858 13.75 12 13.75C12.4142 13.75 12.75 13.4142 12.75 13L12.75 9ZM15 6.25C15.4142 6.25 15.75 6.58579 15.75 7L15.75 13C15.75 13.4142 15.4142 13.75 15 13.75C14.5858 13.75 14.25 13.4142 14.25 13L14.25 7C14.25 6.58579 14.5858 6.25 15 6.25ZM9.75 12C9.75 11.5858 9.41421 11.25 9 11.25C8.58579 11.25 8.25 11.5858 8.25 12V13C8.25 13.4142 8.58579 13.75 9 13.75C9.41421 13.75 9.75 13.4142 9.75 13V12Z"
        fill="currentcolor"
      />
    </svg>
  );
});

export default AssetsIcon;
