import ModalHeader from "atomic-components/molecules/Modal/ModalHeader";
import { useTranslate } from "modules/language";
import ModalFooter from "atomic-components/molecules/Modal/ModalFooter";
import Button from "atomic-components/atoms/Buttons/Button";
import { useModalState } from "atomic-components/molecules/Modal";
import ScenarioDetailsModal from "../ScenarioDetailsModal";
import TextInput from "atomic-components/atoms/TextInput/TextInput";
import { useEffect, useRef, useState } from "react";
import { type Scenario } from "modules/building/pages/SustainabilityPage/SustainabilityBuildingPage/components/ComplianceTrackingPanel/Certificates/LeedOmCertificate/utils";
import { useCreateScenario } from "modules/building/hooks/useComplianceScenariosMutate";

import s from "./SaveScenarioModal.module.scss";

type SaveScenarioModalProps = {
  isSubmit?: boolean;
  scenarioData: Omit<Scenario, "id" | "title"> & {
    id?: string;
    title?: string;
  };
};

const SaveScenarioModal = ({
  isSubmit,
  scenarioData,
}: SaveScenarioModalProps) => {
  const t = useTranslate();

  const [scenarioName, setScenarioName] = useState<string>(
    scenarioData.title ? `${scenarioData.title} - ${t("label.COPY")}` : ""
  );

  const { mutateAsync: createScenario, isLoading } = useCreateScenario({
    ...scenarioData,
    title: scenarioName,
  });

  const { setModal, hideModal } = useModalState();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const onClickCancel = () => {
    hideModal();
    setModal(<ScenarioDetailsModal />, {
      width: "large",
    });
  };

  const onClickSave = async () => {
    createScenario().then(hideModal);
  };

  return (
    <>
      <ModalHeader
        color="primary"
        cancel={{
          text: t("action.CANCEL"),
        }}
      >
        <h3 className={s.headerText}>
          {t(isSubmit ? "action.SUBMIT" : "action.SAVE_AS")}
        </h3>
      </ModalHeader>
      <div className={s.nameContainer}>
        <p>{t("label.NAME")}</p>
        <TextInput
          ref={inputRef}
          value={scenarioName}
          onChange={setScenarioName}
          placeholder={t("label.SCENARIO_NAME")}
        />
      </div>
      <ModalFooter>
        <Button
          skin="secondary"
          onClick={onClickCancel}
          className={s.actionButton}
          size="large"
        >
          {t("action.CANCEL")}
        </Button>
        <Button
          skin="primary"
          onClick={onClickSave}
          disabled={scenarioName === ""}
          className={s.actionButton}
          isLoading={isLoading}
          size="large"
        >
          {t("action.SAVE")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default SaveScenarioModal;
