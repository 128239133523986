import withIconProps from "helpers/withIconProps";

const ThermalEnergyIcon = withIconProps((props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="sun">
        <path
          id="Vector"
          d="M12 2V4M12 20V22M4.92993 4.93005L6.33993 6.34005M17.6599 17.66L19.0699 19.07M2 12H4M20 12H22M6.33993 17.66L4.92993 19.07M19.0699 4.93005L17.6599 6.34005M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
          stroke="currentcolor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
});

export default ThermalEnergyIcon;
