import { Nullable } from "types/utils";
import FormattedValue from "../FormattedValue";
import { useId, useRef, useState } from "react";
import isRealNumber from "helpers/isRealNumber";
import { Popover } from "../InfoPopover";
import classNames from "classnames";

import s from "./PercentageIndicator.module.scss";

export type PercentageIndicatorProps = {
  value: Nullable<number>;
  rawValue?: Nullable<number>;
  labelId?: string;
  showPercentage?: boolean;
  withTooltip?: boolean;
  variant?: "big";
  tooltipClassName?: string;
  unit?: React.ReactNode | string;
};

const PercentageIndicator = ({
  value,
  labelId,
  showPercentage = true,
  withTooltip = false,
  variant,
  tooltipClassName = "",
  rawValue,
  unit,
}: PercentageIndicatorProps) => {
  const [shownTooltip, setShownTooltip] = useState<{
    display: Element;
    anchorElement: EventTarget;
  } | null>(null);
  const meterRef = useRef<HTMLMeterElement>(null);
  const meterId = useId();
  const formattedValue = (
    <>
      <FormattedValue value={isRealNumber(value) ? value * 100 : null} />%
    </>
  );

  const handleMouseEnter = (
    e: React.MouseEvent<HTMLMeterElement, MouseEvent>
  ) => {
    const anchorElement = e.target;
    const display = formattedValue;

    setShownTooltip({
      display,
      anchorElement,
    });
  };

  return (
    <div className={s.container}>
      <meter
        onMouseEnter={(e) => handleMouseEnter(e)}
        onMouseLeave={() => setShownTooltip(null)}
        ref={meterRef}
        id={meterId}
        min={0}
        max={1}
        value={value || 0}
        aria-labelledby={labelId}
        className={classNames(
          {
            [s.big]: variant === "big",
          },
          s.meter
        )}
      >
        {formattedValue}
      </meter>

      {showPercentage ? (
        <span className={s.indicatorValue} aria-hidden>
          {formattedValue}
        </span>
      ) : null}
      {withTooltip && shownTooltip ? (
        <Popover
          anchorElement={meterRef.current as HTMLElement}
          placement="top"
          offset={5}
          className={classNames(s.tooltip, tooltipClassName)}
        >
          <FormattedValue value={rawValue} /> {unit}
        </Popover>
      ) : null}
    </div>
  );
};

export default PercentageIndicator;
