import SectionTitle from "atomic-components/atoms/SectionTitle";
import { useTranslate } from "modules/language";
import { useState } from "react";
import AddScenarioButton from "./AddScenarioButton/AddScenarioButton";
import ScenariosList from "./ScenariosList/ScenariosList";
import ScenarioFilters, {
  Filters,
  SortByOptions,
} from "./ScenarioFilters/ScenarioFilters";
import { useComplianceScenarios } from "modules/building/hooks/useComplianceScenarios";
import { useComplianceScoreValues } from "modules/building/hooks/useComplianceScoreValues";

const Scenarios = () => {
  const t = useTranslate();
  const [selectedFilter, setSelectedFilter] = useState<Filters>(
    Filters.ALL_SCENARIOS
  );
  const [selectedSortBy, setSelectedSortBy] = useState<SortByOptions>(
    SortByOptions.ASCENDING
  );

  const { data } = useComplianceScoreValues();

  const { scenarios } = useComplianceScenarios();

  return (
    <section>
      <SectionTitle>{t("title.GOAL_ACHIEVEMENT_SCENARIOS")}</SectionTitle>
      <AddScenarioButton />
      <ScenarioFilters
        numberOfScenarios={scenarios?.length}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        selectedSortBy={selectedSortBy}
        setSelectedSortBy={setSelectedSortBy}
      />
      <ScenariosList
        scenarios={scenarios}
        targetScore={data.target}
        selectedFilter={selectedFilter}
        selectedSortBy={selectedSortBy}
      />
    </section>
  );
};

export default Scenarios;
