import withIconProps from "helpers/withIconProps";

const AvatarAddIcon = withIconProps((props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <rect x="1" y="1.05859" width="14" height="14" rx="7" fill="white" />
      <path
        d="M5.19995 8.05856H10.8001M8.00003 5.25848V10.8586"
        stroke="currentColor"
        strokeWidth="0.798022"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1"
        y="1.05859"
        width="14"
        height="14"
        rx="7"
        stroke="currentColor"
        strokeWidth="0.600016"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0.6 1.8"
      />
    </svg>
  );
});

export default AvatarAddIcon;
