import useCustomDateFilterState, {
  type RawDateFilter,
} from "modules/building/hooks/useCustomDateFilterState";
import useFullDateFilterState, {
  getAppRawDateFilter,
  appDateFilterChangeHandler,
} from "modules/building/hooks/useFullDateFilterState";
import { useMappedSelectedTimestamp } from "modules/building/hooks/useFullSelectedTimestamp";
import { filterInsightsAtTimestamp } from "modules/building/helpers/insightsUtils";
import {
  type GetDataRequestsCallback,
  useMemoedCurrentLinkedData,
  useMemoedLinkedData,
} from "modules/building/hooks/useLinkedData";
import { useSearchParams } from "react-router-dom";
import {
  type ReactNode,
  createContext,
  useContext,
  useState,
  useMemo,
  type Dispatch,
  type SetStateAction,
} from "react";
import type { Setter } from "types/utils";
import { ShownBottomPanel } from "modules/building/components/BottomPanel/BottomPanel";

enum DataTypeEnum {
  INSTANT = "instant",
  CUMULATIVE = "cumulative",
}

type UseCustomDateFilterStateReturn = ReturnType<
  typeof useCustomDateFilterState
>;

type ViewContextValue = {
  fromTs: UseCustomDateFilterStateReturn["fromTs"];
  toTs: UseCustomDateFilterStateReturn["toTs"];
  days: UseCustomDateFilterStateReturn["days"];
  setDateFilter: UseCustomDateFilterStateReturn["setDateFilter"];
  rawDateFilter: UseCustomDateFilterStateReturn["rawDateFilter"];
  timezone: UseCustomDateFilterStateReturn["timezone"];
  startOfWeek: UseCustomDateFilterStateReturn["startOfWeek"];
  isMainView?: boolean;
  dataType: DataTypeEnum;
  setDataType: Setter<DataTypeEnum>;
  isInstantDataType: boolean;
};

type ViewProviderProps = {
  children: ReactNode;
  dateFilter: RawDateFilter;
  setDateFilter: Dispatch<SetStateAction<RawDateFilter>>;
  isMainView?: boolean;
};

type MainViewProviderProps = {
  children: ReactNode;
};

type CompareViewProviderProps = {
  children: ReactNode;
};

const ViewContext = createContext<ViewContextValue | null>(null);

const ViewProvider = ({
  dateFilter,
  setDateFilter: setDateFilterFromProps,
  isMainView,
  children,
}: ViewProviderProps) => {
  const {
    fromTs,
    toTs,
    rawDateFilter,
    setDateFilter,
    timezone,
    startOfWeek,
    days,
  } = useCustomDateFilterState(dateFilter, setDateFilterFromProps);
  const [dataType, setDataType] = useState(DataTypeEnum.INSTANT);

  const value = useMemo(
    () => ({
      fromTs,
      toTs,
      days,
      rawDateFilter,
      setDateFilter,
      timezone,
      startOfWeek,
      isMainView,
      dataType,
      setDataType,
      isInstantDataType: dataType === DataTypeEnum.INSTANT,
    }),
    [
      fromTs,
      toTs,
      days,
      rawDateFilter,
      setDateFilter,
      timezone,
      startOfWeek,
      isMainView,
      dataType,
    ]
  );

  return <ViewContext.Provider value={value}>{children}</ViewContext.Provider>;
};

const MainViewProvider = ({ children }: MainViewProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <ViewProvider
      dateFilter={getAppRawDateFilter(searchParams)}
      setDateFilter={(dateFilter) =>
        appDateFilterChangeHandler(dateFilter, setSearchParams)
      }
      isMainView
    >
      {children}
    </ViewProvider>
  );
};

const CompareViewProvider = ({ children }: CompareViewProviderProps) => {
  const { fromTs, toTs } = useFullDateFilterState();
  const [dateFilter, setDateFilter] = useState<RawDateFilter>([fromTs, toTs]);

  return (
    <ViewProvider dateFilter={dateFilter} setDateFilter={setDateFilter}>
      {children}
    </ViewProvider>
  );
};

const useViewState = () => {
  const context = useContext(ViewContext);

  if (!context) {
    throw new Error(
      "useViewState hook needs to be called within ViewProvider child components"
    );
  }

  return context;
};

const useViewData = (
  ...args: Parameters<typeof useMemoedCurrentLinkedData>
) => {
  const { fromTs, toTs } = useViewState();

  return useMemoedLinkedData(fromTs, toTs, ...args);
};

const useViewSelectedTimestamp = () => {
  const { fromTs, toTs } = useViewState();

  return useMappedSelectedTimestamp(fromTs, toTs);
};

const useViewDataAtSelectedTimestamp = (
  getRequestsCallback: GetDataRequestsCallback,
  getRequestsDependencies: any[]
) => {
  const { telemetries, insights, ...rest } = useViewData(
    getRequestsCallback,
    getRequestsDependencies
  );
  const { selectedTimestamp } = useViewSelectedTimestamp();

  const { telemetriesAtTimestamp, insightsAtTimestamp } = useMemo(() => {
    return {
      telemetriesAtTimestamp: Object.fromEntries(
        Object.entries(telemetries).map(([entityId, entityTelemetries]) => {
          return [
            entityId,
            Object.fromEntries(
              Object.entries(entityTelemetries).map(
                ([telemetryKey, telemetry]) => {
                  return [
                    telemetryKey,
                    {
                      value: telemetry.valueAtTimestamp(selectedTimestamp),
                      unit: telemetry.unit,
                    },
                  ];
                }
              )
            ),
          ];
        })
      ),
      insightsAtTimestamp: Object.fromEntries(
        Object.entries(insights).map(([entityId, entityInsights]) => {
          return [
            entityId,
            Object.fromEntries(
              Object.entries(entityInsights).map(([insightsKey, insights]) => {
                return [
                  insightsKey,
                  filterInsightsAtTimestamp(insights, selectedTimestamp),
                ];
              })
            ),
          ];
        })
      ),
    };
  }, [selectedTimestamp, telemetries, insights]);

  return {
    telemetries,
    insights,
    telemetriesAtTimestamp,
    insightsAtTimestamp,
    ...rest,
  };
};

export {
  MainViewProvider,
  CompareViewProvider,
  useViewState,
  useViewSelectedTimestamp,
  useViewData,
  useViewDataAtSelectedTimestamp,
  DataTypeEnum,
};
