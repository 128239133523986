import {
  flattenDeep,
  isArray,
  maxBy,
  minBy,
  omit,
  orderBy,
  pull,
  some,
  sortedLastIndexBy,
  uniq,
  uniqBy,
} from "lodash";
import { DateTime } from "luxon";
import hardcoded from "../../../helpers/hardcoded";
import { loadEntitiesTelemetries as loadEntitiesTelemetriesFromServer } from "../api/building.api";
import { v4 as uuidV4 } from "uuid";

/** @type {import('dexie').default & { telemetries: import('dexie').Table }} */
import db from "../../../db";

const ONE_MINUTE = 60 * 1000;
const correctTo = (to) => {
  const min = Date.now() - 5 * ONE_MINUTE;
  const snapped = Math.min(to, min);
  return snapped - (snapped % (10 * ONE_MINUTE));
};

let telemetries;
let initPromise = db.cache
  .where("key")
  .equals("telemetries")
  .first()
  .then((cachedTelemetries) => {
    telemetries = cachedTelemetries?.value ?? {};
    initPromise = null;
  });

let savePromise;
const saveState = () => {
  const telemetriesEntries = Object.entries(telemetries)
    .map(([entityId, entityTelemetries]) => {
      const entityTelemetriesEntries = Object.entries(entityTelemetries)
        .map(([telemetryName, ranges]) => {
          const rangesToSave = ranges.filter(({ loading }) => !loading);
          if (!rangesToSave.length) {
            return null;
          }
          return [telemetryName, rangesToSave];
        })
        .filter(Boolean);
      if (entityTelemetriesEntries.length) {
        return [entityId, Object.fromEntries(entityTelemetriesEntries)];
      }
      return null;
    })
    .filter(Boolean);
  (savePromise ?? Promise.resolve()).then(() => {
    return db.cache
      .put({
        key: "telemetries",
        value: Object.fromEntries(telemetriesEntries),
      })
      .catch(() => {
        return db.cache.delete("telemetries");
      })
      .then(() => {
        savePromise = null;
      });
  });
};

const loadingRangesListeners = new Map();
const listenToLoadedEvent = (range, listener) => {
  let listeners = loadingRangesListeners.get(range);
  if (!listeners) {
    listeners = [];
    loadingRangesListeners.set(range, listeners);
  }
  listeners.push(listener);
};
const fireLoadedEvent = (range, success, wasTrimmedFromServer = false) => {
  const listeners = loadingRangesListeners.get(range);
  if (listeners) {
    loadingRangesListeners.delete(range);
    for (let i = 0; i < listeners.length; i++) {
      listeners[i]({ range, success, wasTrimmedFromServer });
    }
  }
};

const loadEntitiesTelemetries = (
  rootEntityId,
  requests,
  retries = 0,
  maxRetries = 2
) => {
  return loadEntitiesTelemetriesFromServer(rootEntityId, requests)
    .catch((err) => {
      if (retries < maxRetries) {
        return loadEntitiesTelemetries(
          rootEntityId,
          requests,
          retries + 1,
          maxRetries
        );
      }
      throw err;
    })
    .then((results) => {
      results.forEach((result) => {
        result.telemetries.forEach((telemetry) => {
          if (isArray(telemetry)) {
            telemetry.forEach((telemetry) => {
              telemetry.data.forEach((datum) => {
                datum.timestamp += 10 * ONE_MINUTE;
              });
            });
          } else {
            telemetry.data.forEach((datum) => {
              datum.timestamp += 10 * ONE_MINUTE;
            });
          }
        });
      });
      return results;
    });
};

const processRangeLoaded = (
  entityId,
  loadedRange,
  loadedTelemetry,
  lastImport
) => {
  let wasTrimmedFromServer = false;

  lastImport = lastImport ?? 0;

  const telemetryName = loadedRange.telemetryName;

  delete loadedRange.loading;
  loadedRange.previousDatum = loadedTelemetry.previousDatum;
  loadedRange.data = loadedTelemetry.data;
  loadedRange.nextDatum = loadedTelemetry.nextDatum;
  loadedRange.telemetryTypeId = loadedTelemetry.telemetryTypeId;

  if (lastImport < loadedRange.to) {
    console.error(
      `OOPS lastImport < currentToLoadRange.to (${lastImport} < ${loadedRange.to})`
    );

    wasTrimmedFromServer = true;

    const noDataForRange = lastImport <= loadedRange.from;
    const replacementRange = noDataForRange
      ? null
      : {
          ...loadedRange,
          to: lastImport,
        };

    const index = telemetries[entityId][telemetryName].indexOf(loadedRange);
    if (index >= 0) {
      if (noDataForRange) {
        telemetries[entityId][telemetryName].splice(index, 1);
      } else {
        telemetries[entityId][telemetryName][index] = replacementRange;
      }
    }
  }

  return wasTrimmedFromServer;
};

/**
 * #TODO extract retry logic to a different funtion
 * @param {string} rootEntityId
 * @param {array} entitiesTelemetriesRequest
 * @param {number} [retries]
 * @param {number} [maxRetries]
 */
const loadInternal = async (
  rootEntityId,
  entitiesTelemetriesRequest,
  retries = 0,
  maxRetries = 3
) => {
  if (initPromise) {
    await initPromise;
  }
  sort();

  const toLoadRanges = [];
  const loadingRanges = [];
  const requests = entitiesTelemetriesRequest
    .map((telemetriesRequest) => {
      const dateFilter = {
        from: telemetriesRequest.from,
        to: telemetriesRequest.to,
      };
      const { from, to } = dateFilter;
      const entityId = telemetriesRequest.entityId;
      const entitiesIds = telemetriesRequest.entitiesIds ?? [entityId];

      const requestToLoadRanges = [];

      entitiesIds.forEach((entityId) => {
        const entityTelemetries =
          telemetries[entityId] ?? (telemetries[entityId] = {});

        const currentToLoadRanges = [];
        requestToLoadRanges.push(currentToLoadRanges);

        telemetriesRequest.telemetries.forEach((telemetryName) => {
          const telemetryRanges =
            entityTelemetries[telemetryName] ??
            (entityTelemetries[telemetryName] = []);

          let loadFrom,
            loadTo,
            index,
            toAwait = [];
          for (let r = 0; r < telemetryRanges.length; r++) {
            let range = telemetryRanges[r];
            if (range.from <= dateFilter.from && dateFilter.from < range.to) {
              let maxTo = range.to;
              while (true) {
                if (range.loading) {
                  toAwait.push(range);
                }
                if (telemetryRanges[r + 1]?.from <= maxTo) {
                  range = telemetryRanges[++r];
                  maxTo = Math.max(maxTo, range.to);
                } else {
                  break;
                }
              }
              loadFrom = maxTo;
              index = r;
            }
            if (range.from < dateFilter.to && dateFilter.to <= range.to) {
              let minFrom = range.from;
              while (true) {
                if (range.loading) {
                  toAwait.push(range);
                }
                if (telemetryRanges[r - 1]?.to >= minFrom) {
                  range = telemetryRanges[--r];
                  minFrom = Math.min(minFrom, range.from);
                } else {
                  break;
                }
              }
              loadTo = minFrom;
              break;
            }
          }
          loadFrom = loadFrom ?? from;
          loadTo = loadTo ?? to;
          loadingRanges.push(...toAwait);

          const newRange = {
            from: loadFrom,
            to: loadTo,
            telemetryName,
            entityId,
            loading: true,
          };

          currentToLoadRanges.push(newRange);

          if (loadFrom >= loadTo) {
            return null;
          }

          telemetryRanges.splice(index, 0, newRange);
        });
      });

      const currentToLoadRanges = [].concat(...requestToLoadRanges);
      const telemetriesToLoad = uniq(
        currentToLoadRanges.map((r) => r.telemetryName)
      );

      sort();
      if (telemetriesToLoad.length) {
        const realFrom = minBy(currentToLoadRanges, "from").from;
        const realTo = maxBy(currentToLoadRanges, "to").to;
        if (realFrom >= realTo) {
          return null;
        }

        currentToLoadRanges.forEach((r) => {
          r.from = realFrom;
          r.to = realTo;
        });
        if (entityId) {
          toLoadRanges.push(requestToLoadRanges[0]);
        } else {
          toLoadRanges.push(requestToLoadRanges);
        }
        return {
          from: realFrom,
          to: realTo,
          entityId,
          entitiesIds: entityId == null ? entitiesIds : undefined,
          telemetries: telemetriesToLoad,
        };
      }

      return null;
    })
    .filter(Boolean);

  const results = requests.length
    ? await loadEntitiesTelemetries(
        rootEntityId,
        requests,
        retries,
        maxRetries
      ).catch((err) => {
        for (let i = 0; i < requests.length; i++) {
          const currentToLoadRanges = toLoadRanges[i];

          if (requests[i].entityId) {
            currentToLoadRanges.forEach((currentToLoadRange) => {
              pull(
                telemetries[requests[i].entityId][
                  currentToLoadRange.telemetryName
                ],
                currentToLoadRange
              );
            });
          } else {
            requests[i].entitiesIds.forEach((entityId, i) => {
              currentToLoadRanges[i].forEach((currentToLoadRange) => {
                pull(
                  telemetries[entityId][currentToLoadRange.telemetryName],
                  currentToLoadRange
                );
              });
            });
          }
          const flatToLoadRanges = flattenDeep(currentToLoadRanges);
          for (let j = 0; j < flatToLoadRanges.length; j++) {
            const currentToLoadRange = flatToLoadRanges[j];
            fireLoadedEvent(currentToLoadRange, false);
          }
        }
        throw err;
      })
    : [];

  const loadEventsToFire = [];
  let wasTrimmedFromServer = false;
  for (let i = 0; i < results.length; i++) {
    const currentRequest = requests[i];

    const currentToLoadRanges = toLoadRanges[i];
    const result = results[i];

    if (currentRequest.entityId) {
      const lastImport = result.lastImport;
      const loadedTelemetries = result.telemetries;
      const entityId = currentRequest.entityId;

      for (let j = 0; j < loadedTelemetries.length; j++) {
        const loadedTelemetry = loadedTelemetries[j];
        const currentToLoadRange = currentToLoadRanges[j];

        const currentTrimmedFromServer = processRangeLoaded(
          entityId,
          currentToLoadRange,
          loadedTelemetry,
          lastImport
        );
        loadEventsToFire.push({
          range: currentToLoadRange,
          wasTrimmedFromServer: currentTrimmedFromServer,
        });
        wasTrimmedFromServer = wasTrimmedFromServer || currentTrimmedFromServer;
      }
    } else {
      const lastImports = result.lastImports ?? [];
      currentRequest.entitiesIds.forEach((entityId, i) => {
        const loadedTelemetries = result.telemetries[i];

        for (let j = 0; j < loadedTelemetries.length; j++) {
          const loadedTelemetry = loadedTelemetries[j];
          const currentToLoadRange = currentToLoadRanges[i][j];

          const currentTrimmedFromServer = processRangeLoaded(
            entityId,
            currentToLoadRange,
            loadedTelemetry,
            lastImports[i]
          );
          loadEventsToFire.push({
            range: currentToLoadRange,
            wasTrimmedFromServer: currentTrimmedFromServer,
          });
          wasTrimmedFromServer =
            wasTrimmedFromServer || currentTrimmedFromServer;
        }
      });
    }
  }

  if (wasTrimmedFromServer) {
    sort();
  }
  for (let i = 0; i < loadEventsToFire.length; i++) {
    fireLoadedEvent(
      loadEventsToFire[i].range,
      true,
      loadEventsToFire[i].wasTrimmedFromServer
    );
  }

  let success = true;
  if (loadingRanges.length) {
    await Promise.all(
      loadingRanges.map((range) => {
        if (range.loading) {
          return new Promise((resolve) => {
            listenToLoadedEvent(range, (e) => {
              if (!e.success) {
                success = false;
              }
              if (e.wasTrimmedFromServer) {
                wasTrimmedFromServer = true;
              }
              resolve();
            });
          });
        }
      })
    );
  }

  if (!success) {
    if (retries < maxRetries) {
      return (
        (await loadInternal(
          rootEntityId,
          entitiesTelemetriesRequest,
          retries + 1,
          maxRetries
        )) ||
        requests.length > 0 ||
        loadingRanges.length > 0
      );
    }
    throw new Error("Max retries");
  }

  clean();
  saveState(); // asynchronously saves the state

  return {
    didLoadFromServer: requests.length > 0 || loadingRanges.length > 0,
    wasTrimmedFromServer,
  };
};

const clean = () => {
  let didMerge = false;
  sort();
  Object.entries(telemetries).forEach(([entityId, entityTelemetries]) => {
    Object.entries(entityTelemetries).forEach(
      ([telemetryName, telemetryRanges]) => {
        const newRanges = [];
        for (let i = 0; i < telemetryRanges.length; i++) {
          if (
            telemetryRanges[i].loading ||
            !telemetryRanges[i + 1] ||
            telemetryRanges[i + 1].loading
          ) {
            newRanges.push(telemetryRanges[i]);
          } else if (telemetryRanges[i].to < telemetryRanges[i + 1].from) {
            newRanges.push(telemetryRanges[i]);
          } else {
            didMerge = true;
            const rangesToMerge = [telemetryRanges[i], telemetryRanges[i + 1]];
            i++;
            while (
              telemetryRanges[i + 1] &&
              !telemetryRanges[i + 1].loading &&
              telemetryRanges[i].to >= telemetryRanges[i + 1].from
            ) {
              i++;
              rangesToMerge.push(telemetryRanges[i]);
            }
            newRanges.push(mergeRanges(rangesToMerge));
          }
        }
        telemetryRanges.splice(0, telemetryRanges.length, ...newRanges);
      }
    );
  });
  if (didMerge) {
    sort();
    clean();
  }
};

const sort = () => {
  Object.entries(telemetries).forEach(([entityId, entityTelemetries]) => {
    Object.entries(entityTelemetries).forEach(
      ([telemetryName, telemetryRanges]) => {
        telemetryRanges.splice(
          0,
          telemetryRanges.length,
          ...orderBy(telemetryRanges, ["from", "to"], ["asc", "desc"])
        );
      }
    );
  });
};

const mergeRanges = (ranges) => {
  const min = minBy(ranges, "from");
  const max = maxBy(ranges, "to");
  const allRangesHaveData = !ranges.find((range) => !range.data);
  return {
    entityId: min.entityId,
    telemetryTypeId: min.telemetryTypeId,
    from: min.from,
    to: max.to,
    previousDatum: min.previousDatum,
    nextDatum: max.nextDatum,
    data: allRangesHaveData
      ? orderBy(
          uniqBy([].concat(...ranges.map((r) => r.data)), "timestamp"),
          ["timestamp"],
          ["asc"]
        )
      : undefined,
  };
};

const getFromLocal = (entitiesTelemetriesRequest, sync) => {
  if (!sync && initPromise) {
    return initPromise.then(() => getFromLocal(entitiesTelemetriesRequest));
  }

  const get = (from, to, entityId, telemetriesToLoad) => {
    return {
      entityId,
      from,
      to,
      telemetries: telemetriesToLoad.map((telemetryName) => {
        const telemetryRanges = telemetries?.[entityId]?.[telemetryName];
        if (!telemetryRanges) {
          return {
            telemetryName,
            coverage: 0,
            data: [],
          };
        }

        let data = [],
          previousDatum,
          nextDatum;
        const updatePreviousNextDatum = (candidatePrevious, candidateNext) => {
          if (
            !previousDatum ||
            (candidatePrevious &&
              candidatePrevious.timestamp > previousDatum.timestamp)
          ) {
            previousDatum = candidatePrevious;
          }
          if (
            !nextDatum ||
            (candidateNext && candidateNext.timestamp < nextDatum.timestamp)
          ) {
            nextDatum = candidateNext;
          }
        };

        const duration = to - from;
        let coverage = 0;
        for (let i = 0; i < telemetryRanges.length; i++) {
          const range = telemetryRanges[i];
          if (range.loading) {
            continue;
          }
          if (range.to <= from || range.from >= to) {
            continue;
          }

          let inRangeFrom = Math.max(from, range.from);
          let inRangeTo = Math.min(to, range.to);
          if (inRangeTo <= inRangeFrom) {
            console.error("Not in range?!? Should be!", {
              range,
              from,
              to,
              maxFrom: inRangeFrom,
              minTo: inRangeTo,
            });
            debugger;
            throw new Error("Not in range?!? Should be!");
          }

          const currentCoverage = (inRangeTo - inRangeFrom) / duration;
          coverage += currentCoverage;

          const previousIndex =
            sortedLastIndexBy(
              range.data,
              { timestamp: from },
              ({ timestamp }) => timestamp
            ) - 1;
          const nextIndex = sortedLastIndexBy(
            range.data,
            { timestamp: to },
            ({ timestamp }) => timestamp
          );

          updatePreviousNextDatum(
            range.data[previousIndex] ?? range.previousDatum,
            range.data[nextIndex] ?? range.nextDatum
          );

          const firstIndex = previousIndex + 1;
          const lastIndex = nextIndex - 1;
          if (lastIndex < 0 || lastIndex < firstIndex) {
            continue;
          }

          data.push(range.data.slice(firstIndex, lastIndex + 1));
        }
        if (coverage > 1) {
          console.error("Coverage > 1?!? Shouldnt be!", { coverage });
          debugger;
          // throw new Error("Coverage > 1?!? Shouldnt be!");
        }

        data = orderBy(
          uniqBy([].concat(...data), "timestamp"),
          ["timestamp"],
          ["asc"]
        );

        return {
          telemetryName,
          coverage,
          nextDatum,
          previousDatum,
          data,
        };
      }),
    };
  };
  return entitiesTelemetriesRequest.map((entityTelemetriesRequest) => {
    const {
      from,
      to,
      entityId,
      entitiesIds,
      telemetries: telemetriesToLoad,
    } = entityTelemetriesRequest;
    if (entityId) {
      return get(from, to, entityId, telemetriesToLoad);
    }
    return entitiesIds.map((entityId) =>
      get(from, to, entityId, telemetriesToLoad)
    );
  });
};

const getFromLocalAsync = async (entitiesTelemetriesRequest) =>
  getFromLocal(entitiesTelemetriesRequest, false);

const getFromLocalSync = (entitiesTelemetriesRequest) =>
  getFromLocal(entitiesTelemetriesRequest, true);

let batchedEntitiesTelemetriesRequest = {},
  timer;

const optimizeLoading = (entitiesTelemetriesRequest) => {
  return orderBy(entitiesTelemetriesRequest, ["from", "to"], ["asc", "desc"]);
};

const getTimerDuration = () => {
  const now = Date.now();
  const min = now - 5 * ONE_MINUTE;
  const next = min - (min % (10 * ONE_MINUTE)) + 15 * ONE_MINUTE;
  return next - now;
};

/**
 * @typedef {object} LoadParams
 * @property {string} rootEntityId
 * @property {array} entitiesTelemetriesRequest
 */

const canceledBatches = {};
const batchesTimers = {};

const resolveCurrentRequests = (entitiesTelemetriesRequest) =>
  entitiesTelemetriesRequest.map((request) => {
    const originalToDate = request.to;
    const toDate = correctTo(originalToDate);
    return {
      ...request,
      trimmed: originalToDate - toDate > hardcoded(10 * ONE_MINUTE),
      to: toDate,
    };
  });

/**
 * @param {string} id
 * @param {LoadParams} originalRequests
 * @param {Function} callback
 */
const loadBatch = (
  id,
  originalRequests,
  callback,
  { once = false, loadInitially = true, loadFromServer = loadInitially } = {}
) => {
  const { rootEntityId, entitiesTelemetriesRequest } = originalRequests;
  const batch =
    batchedEntitiesTelemetriesRequest[rootEntityId] ??
    (batchedEntitiesTelemetriesRequest[rootEntityId] = []);

  const myBatch = {
    id,
    doLoad: loadFromServer,
    originalRequests,
    entitiesTelemetriesRequest: resolveCurrentRequests(
      entitiesTelemetriesRequest
    ),
    callback,
  };
  batch.push(myBatch);

  if (loadInitially) {
    getFromLocalAsync(myBatch.entitiesTelemetriesRequest).then((results) => {
      if (canceledBatches[id]) {
        return;
      }
      callback(null, results, true);
    });
  }

  if (timer) {
    clearTimeout(timer);
  }
  timer = window.setTimeout(() => {
    Object.entries(batchedEntitiesTelemetriesRequest).forEach(
      async ([rootEntityId, batch]) => {
        const entitiesTelemetriesRequest = optimizeLoading(
          [].concat(
            ...batch
              .filter(({ id, doLoad }) => !canceledBatches[id] && doLoad)
              .map((b) => b.entitiesTelemetriesRequest)
          )
        );

        const { success, didLoadFromServer, wasTrimmedFromServer, error } =
          entitiesTelemetriesRequest.length
            ? await loadInternal(rootEntityId, entitiesTelemetriesRequest)
                .then(({ didLoadFromServer, wasTrimmedFromServer }) => ({
                  success: true,
                  didLoadFromServer,
                  wasTrimmedFromServer,
                }))
                .catch((error) => ({ success: false, error }))
            : {
                success: true,
                didLoadFromServer: false,
                wasTrimmedFromServer: false,
              };

        batch.forEach(
          async ({
            id,
            doLoad,
            originalRequests,
            entitiesTelemetriesRequest,
            callback,
          }) => {
            if (canceledBatches[id]) {
              return;
            }
            if (success) {
              if (doLoad && didLoadFromServer) {
                const localData = getFromLocalSync(entitiesTelemetriesRequest);
                const data = localData.map((result, i) => {
                  const currentRequest = entitiesTelemetriesRequest[i];
                  if (currentRequest.entityId) {
                    return {
                      ...currentRequest,
                      ...result,
                      wasTrimmedFromServer,
                    };
                  }
                  return result.map((result, i) => {
                    return {
                      ...omit(currentRequest, "entitiesIds"),
                      entityId: currentRequest.entitiesIds[i],
                      ...result,
                      wasTrimmedFromServer,
                    };
                  });
                });
                callback(null, data, false);
              } else {
                callback(null, null, false);
              }
              if (once) {
                return;
              }
              const isTrimmed = some(
                entitiesTelemetriesRequest,
                ({ trimmed }) => trimmed
              );
              if (isTrimmed) {
                batchesTimers[id] = setTimeout(() => {
                  loadBatch(id, originalRequests, callback, {
                    loadInitially: false,
                    loadFromServer: true,
                  });
                }, getTimerDuration());
              }
            } else {
              callback(
                error || new Error("Could not load telemetries"),
                null,
                false
              );
              if (once) {
                return;
              }
              batchesTimers[id] = setTimeout(() => {
                loadBatch(id, originalRequests, callback, {
                  loadInitially: false,
                  loadFromServer: true,
                });
              }, getTimerDuration());
            }
          }
        );
      }
    );
    batchedEntitiesTelemetriesRequest = {};
  }, 50);
};

/**
 * @param {LoadParams} originalRequests
 * @param {Function} callback
 */
const load = (
  originalRequests,
  callback,
  { loadInitially = true, loadFromServer = loadInitially }
) => {
  console.warn(
    `Telemetries loader is deprecated. Use timeseriesLoader instead`
  );
  const id = uuidV4();
  loadBatch(id, originalRequests, callback, {
    once: false,
    loadInitially,
    loadFromServer,
  });
  return {
    cancel: () => {
      canceledBatches[id] = true;
      if (batchesTimers[id]) {
        clearTimeout(batchesTimers[id]);
        delete batchesTimers[id];
      }
    },
    reload: () => {
      // beware of multi loading
      loadBatch(id, originalRequests, callback, {
        loadInitially: false,
        loadFromServer: true,
      });
    },
  };
};

/**
 * @param {LoadParams} originalRequests
 */
export const loadFromLocalAsync = (originalRequests) => {
  console.warn(
    `Telemetries loader is deprecated. Use timeseriesLoader instead`
  );
  return getFromLocalAsync(
    resolveCurrentRequests(originalRequests.entitiesTelemetriesRequest)
  );
};

/**
 * @param {LoadParams} originalRequests
 */
export const loadFromLocalSync = (originalRequests) => {
  console.warn(
    `Telemetries loader is deprecated. Use timeseriesLoader instead`
  );
  return getFromLocalSync(
    resolveCurrentRequests(originalRequests.entitiesTelemetriesRequest)
  );
};

export default load;

export const clearCache = () => {
  console.warn(
    `Telemetries loader is deprecated. Use timeseriesLoader instead`
  );
  Object.keys(telemetries).forEach((key) => {
    delete telemetries[key];
  });
  return db.cache.where("key").equals("telemetries").delete();
};

window.telemetriesLoader = {
  load,
  get telemetries() {
    return telemetries;
  },
};
window.DateTime = DateTime;
